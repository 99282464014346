import { ReactNode } from 'react';
import { Box, Link, Stack, StackProps, styled } from '@mui/material';

import {
  TwitterSVG,
  InstagramSVG,
  TikTokSVG,
  FacebookSVG,
  YoutubeSVG,
  WebsiteSVG,
  SpotifySVG,
} from '../../icons';
import prependHttp from 'prepend-http';

const StyledLink = styled(Link)(({ theme }) => ({
  padding: theme.spacing(1),
  cursor: 'pointer',
}));

export type SocialTypes =
  | 'facebook'
  | 'tiktok'
  | 'youtube'
  | 'instagram'
  | 'spotify'
  | 'twitter'
  | 'website';

export type SocialLinksProps = {
  socials: {
    type: SocialTypes;
    href: string;
  }[];
  iconSize?: number;
};

const SOCIAL_ICONS: Record<SocialTypes, ReactNode> = {
  spotify: <SpotifySVG width={'inherit'} height={'inherit'} />,
  facebook: <FacebookSVG width={'inherit'} height={'inherit'} />,
  tiktok: <TikTokSVG width={'inherit'} height={'inherit'} />,
  youtube: <YoutubeSVG width={'inherit'} height={'inherit'} />,
  instagram: <InstagramSVG width={'inherit'} height={'inherit'} />,
  twitter: <TwitterSVG width={'inherit'} height={'inherit'} />,
  website: <WebsiteSVG width={'inherit'} height={'inherit'} />,
};

function SocialLinks({
  socials,
  iconSize,
  ...reset
}: SocialLinksProps & StackProps) {
  return (
    <Stack
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      flexWrap={{
        xs: 'wrap',
        lg: 'nowrap',
      }}
      {...reset}
    >
      {socials.map((record) => (
        <StyledLink
          key={record.type}
          href={prependHttp(record.href)}
          aria-label={`${record.type}`}
          target="_blank"
        >
          <Box
            sx={{
              width: iconSize ?? 40,
              height: iconSize ?? 40,
            }}
          >
            {SOCIAL_ICONS[record.type]}
          </Box>
        </StyledLink>
      ))}
    </Stack>
  );
}

export default SocialLinks;
