import { SVGProps } from 'react';

const TrophyGold = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="27"
    height="30"
    viewBox="0 0 27 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      // eslint-disable-next-line max-len
      d="M8.85089 14.6042C8.85089 14.6042 8.83591 14.6222 8.82991 14.6282C8.69802 14.7781 8.55115 14.889 8.39228 14.9639C8.24841 15.0299 8.09553 15.0658 7.93967 15.0658C7.86173 15.0658 7.7868 15.0569 7.70886 15.0419L8.23941 14.1097C8.57812 13.723 8.725 12.9047 8.54515 12.3861C8.41326 12.0084 8.17647 11.9635 8.09853 11.9515L8.06556 11.9455C6.58182 11.5438 5.24196 10.7915 4.11492 9.78434C3.93807 9.62547 3.77022 9.46061 3.60236 9.29275C3.42251 9.10691 3.24566 8.91208 3.07781 8.71125C2.70013 8.25863 2.35542 7.77305 2.05568 7.25749C1.21339 5.82771 0.676849 4.17611 0.535969 2.41361L0.5 1.98797L0.709822 1.85309L5.14605 1.98797L5.12806 2.39862C5.12806 2.39862 5.09209 3.51967 5.73954 4.20608C5.88642 4.36195 6.06027 4.48784 6.2611 4.57777C6.50989 4.68867 6.80064 4.75162 7.12736 4.75761L7.33718 5.61488C7.03144 5.60589 6.51888 5.49499 6.2611 5.41706C5.82946 5.28517 5.46078 5.06036 5.16103 4.73963C4.57054 4.11016 4.40268 3.26788 4.35772 2.7733H1.36327C1.47717 3.7205 1.71397 4.63172 2.05867 5.48C2.33444 6.16342 2.67915 6.80787 3.0808 7.40436C3.24566 7.64715 3.41952 7.88395 3.60536 8.10876C3.76722 8.30959 3.93807 8.50443 4.11792 8.69027C5.25096 9.87126 6.66575 10.7465 8.26039 11.1811C8.32634 11.1931 8.39228 11.2141 8.45223 11.2351C8.8419 11.373 9.13565 11.6847 9.29152 12.1313C9.56129 12.9047 9.36346 14.0077 8.85389 14.6102L8.85089 14.6042Z"
      fill="url(#paint0_linear_3052_7200)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M4.6964 2.51249L4.35469 2.77027H1.42918L1.30029 2.30566L4.6964 2.51249Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M8.85077 14.6042C8.85077 14.6042 8.83578 14.6222 8.82979 14.6282C8.6979 14.778 8.55102 14.8889 8.39216 14.9639C8.24828 15.0298 8.09541 15.0658 7.93954 15.0658C7.86161 15.0658 7.78667 15.0568 7.70874 15.0418L7.91856 14.9069L8.85077 14.6042Z"
        fill="#808080"
      />
    </g>
    <path
      // eslint-disable-next-line max-len
      d="M9.03656 14.4933C8.90467 14.6432 8.7548 14.7541 8.59894 14.8291C8.45506 14.895 8.30219 14.931 8.14632 14.931C8.06839 14.931 7.99345 14.922 7.91552 14.907L8.44607 13.9748C8.78478 13.5881 8.93165 12.7698 8.75181 12.2512C8.61992 11.8736 8.38312 11.8286 8.30519 11.8136L8.27521 11.8076C6.79147 11.406 5.45161 10.6536 4.32457 9.64647C4.14772 9.4876 3.97687 9.32274 3.81201 9.15488C3.63216 8.96904 3.45531 8.77421 3.28746 8.57338C2.90978 8.12076 2.56507 7.63517 2.26533 7.11961C1.42304 5.68983 0.889496 4.03823 0.745619 2.27573L0.712646 1.8501H5.35869L5.34071 2.26075C5.34071 2.26075 5.30774 3.3818 5.95219 4.06821C6.09906 4.22408 6.27291 4.34697 6.47374 4.43989C6.72253 4.5508 7.01328 4.61375 7.34001 4.61974L7.32202 5.40807C7.01628 5.39908 6.73452 5.35711 6.47374 5.27918C6.04211 5.14729 5.67343 4.92249 5.37368 4.60176C4.78318 3.97229 4.61533 3.13001 4.57036 2.63543H1.57591C1.68982 3.58263 1.92661 4.49385 2.27132 5.34213C2.54709 6.02555 2.89179 6.67 3.29345 7.26649C3.45831 7.50928 3.63216 7.74608 3.818 7.97089C3.97987 8.17172 4.15072 8.36655 4.33057 8.55239C5.4636 9.73339 6.8784 10.6086 8.47304 11.0433C8.53899 11.0553 8.60493 11.0762 8.66488 11.0972C9.05455 11.2351 9.35129 11.5468 9.50716 11.9935C9.77993 12.7788 9.57311 13.8998 9.04855 14.4963L9.03656 14.4933Z"
      fill="url(#paint1_linear_3052_7200)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M9.03675 14.4933C8.90486 14.6432 8.75499 14.7541 8.59912 14.8291C9.41143 13.3753 8.96781 11.8436 8.65607 11.0942C9.04574 11.2321 9.34249 11.5439 9.49836 11.9905C9.77112 12.7758 9.5643 13.8969 9.03975 14.4933H9.03675Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M6.46777 4.43988C6.71656 4.55079 7.00731 4.61373 7.33404 4.61973L7.31605 5.40806C7.01031 5.39907 6.72855 5.3571 6.46777 5.27917V4.43988Z"
        fill="#808080"
      />
    </g>
    <path
      // eslint-disable-next-line max-len
      d="M26.1852 1.98494L26.1492 2.41058C26.0233 3.99023 25.5767 5.47996 24.8813 6.80184C24.7404 7.06561 24.5935 7.32339 24.4347 7.57218C24.2788 7.81797 24.111 8.05777 23.9371 8.29157C23.6523 8.66625 23.3466 9.01995 23.0199 9.35267C21.8089 10.5786 20.3042 11.4868 18.6196 11.9425L18.5867 11.9485C18.5087 11.9634 18.2689 12.0054 18.14 12.3831C17.9932 12.8117 18.0651 13.4472 18.2869 13.8728C18.3349 13.9597 18.3888 14.0407 18.4458 14.1066L19.2851 14.9039L18.9763 15.0388C18.8984 15.0538 18.8205 15.0628 18.7455 15.0628C18.6286 15.0628 18.5147 15.0448 18.4038 15.0059C18.2929 14.9669 18.185 14.9099 18.0861 14.835C18.0052 14.7781 17.9302 14.7061 17.8583 14.6252C17.3337 14.0287 17.1269 12.9076 17.3997 12.1223C17.5645 11.6487 17.8853 11.328 18.3109 11.2021C18.3499 11.1901 18.3918 11.1811 18.4338 11.1721C20.2472 10.6775 21.8299 9.61644 23.0259 8.17767C23.3616 7.77301 23.6703 7.33838 23.9431 6.87677C24.1229 6.57103 24.2908 6.2563 24.4407 5.92658C24.6115 5.5579 24.7584 5.17422 24.8873 4.77856C25.0941 4.13411 25.244 3.45968 25.3279 2.76727H22.3305C22.2855 3.26185 22.1177 4.10414 21.5272 4.7336C21.2244 5.06032 20.8497 5.28813 20.4091 5.41702C20.1543 5.49195 19.8786 5.53392 19.5818 5.53991L19.5638 4.75158C19.8846 4.74259 20.1663 4.68564 20.4121 4.57773C20.6219 4.48781 20.8048 4.35892 20.9576 4.19406C21.5961 3.51364 21.5661 2.39859 21.5661 2.38959L21.5481 1.97894L21.8569 1.84406L26.1912 1.97894L26.1852 1.98494Z"
      fill="url(#paint2_linear_3052_7200)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M25.6906 2.59046L25.3219 2.77031H22.3455V2.39563L25.6906 2.59046Z"
        fill="#808080"
      />
    </g>
    <path
      // eslint-disable-next-line max-len
      d="M26.4939 1.84706L26.4579 2.27269C26.332 3.85235 25.8854 5.34208 25.19 6.66395C25.0491 6.92773 24.9022 7.18551 24.7433 7.4343C24.5845 7.68309 24.4196 7.91988 24.2458 8.15369C23.964 8.52837 23.6553 8.88207 23.3285 9.21479C22.1176 10.4407 20.6129 11.349 18.9283 11.8046L18.8953 11.8106C18.8174 11.8256 18.5806 11.8675 18.4487 12.2482C18.3018 12.6768 18.3738 13.3123 18.5956 13.7379C18.6435 13.8249 18.6975 13.9058 18.7544 13.9717L19.285 14.9039C19.2071 14.9189 19.1321 14.9279 19.0542 14.9279C18.9373 14.9279 18.8234 14.9099 18.7095 14.871C18.5986 14.832 18.4907 14.7751 18.3917 14.7001C18.3108 14.6432 18.2359 14.5712 18.1639 14.4903C17.6394 13.8938 17.4326 12.7698 17.7053 11.9874C17.8702 11.5138 18.1909 11.1931 18.6166 11.0672C18.6555 11.0552 18.6975 11.0462 18.7365 11.0372C20.5499 10.5427 22.1326 9.48156 23.3285 8.04278C23.6643 7.63813 23.973 7.2035 24.2458 6.74189C24.4256 6.43615 24.5935 6.12142 24.7433 5.7917C24.9142 5.42301 25.0641 5.03634 25.19 4.64368C25.3968 3.99922 25.5467 3.3248 25.6306 2.63239H22.6361C22.5912 3.12696 22.4233 3.96925 21.8328 4.59871C21.5301 4.92543 21.1554 5.15324 20.7148 5.28213C20.46 5.35707 20.1842 5.39903 19.8875 5.40503L19.8695 4.6167C20.1902 4.6107 20.472 4.55076 20.7178 4.44285C20.9276 4.35292 21.1104 4.22403 21.2633 4.05917C21.9018 3.37875 21.8718 2.2637 21.8718 2.25471L21.8538 1.84406H26.4998L26.4939 1.84706Z"
      fill="url(#paint3_linear_3052_7200)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M18.3947 14.7032C18.3137 14.6462 18.2388 14.5743 18.1669 14.4933C17.6423 13.8968 17.4355 12.7728 17.7083 11.9905C17.8731 11.5169 18.1938 11.1961 18.6195 11.0703C18.2748 11.7297 17.7023 13.1954 18.3947 14.7032Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M20.7147 4.44587V5.28215C20.4599 5.35709 20.1841 5.39905 19.8874 5.40505L19.8694 4.61672C20.1901 4.61072 20.4719 4.55078 20.7177 4.44287L20.7147 4.44587Z"
        fill="#808080"
      />
    </g>
    <path
      d="M20.2444 1.32556C20.2444 1.65828 20.2354 2.00898 20.2234 2.37467C20.2174 2.52754 20.2084 2.6864 20.1994 2.84827C20.0645 5.12932 19.6359 7.85401 18.8296 10.21C17.7775 13.2854 16.0779 15.7313 13.5541 15.7313C9.03093 15.7313 7.16351 7.88698 6.89674 2.61746C6.89075 2.53653 6.88775 2.4556 6.88475 2.37767C6.86976 2.01198 6.86377 1.66128 6.86377 1.32856H20.2444V1.32556Z"
      fill="url(#paint4_linear_3052_7200)"
    />
    <path
      d="M12.7177 10.114V9.46955H13.0174V5.5399H12.6577V4.84149H14.5101V9.46955H14.8099V10.114H12.7147H12.7177Z"
      fill="#FF9D09"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M14.5101 9.46955V4.84149H12.6577V5.08728H14.2943V9.71834H14.5941V10.117H14.8099V9.47255H14.5101V9.46955Z"
        fill="#767676"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M14.2974 5.08433L14.5102 4.85052V9.44561L14.2974 9.71538V5.08433Z"
        fill="#767676"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M14.81 9.4696V10.1141H14.5972V9.74537L14.81 9.4696Z"
        fill="#767676"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M18.8296 10.207C18.0682 12.4281 16.9742 14.3195 15.4724 15.2008C14.8939 15.5395 14.2555 15.7283 13.5541 15.7283C9.03093 15.7283 7.16351 7.88398 6.89674 2.61447C6.89075 2.53353 6.88775 2.4526 6.88475 2.37467C6.86976 2.00898 6.86377 1.65828 6.86377 1.32556H11.0932L10.8923 2.08692C10.8923 2.08692 11.5158 17.0412 18.8266 10.21L18.8296 10.207Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M15.613 15.1168C10.3555 13.1865 8.52702 4.86255 7.97849 1.32556H7.2561C7.2561 1.65828 7.2651 2.01198 7.27709 2.37467C7.28008 2.4526 7.28308 2.53353 7.28908 2.61447C7.55285 7.80904 9.36931 15.5035 13.7516 15.7223L15.613 15.1138V15.1168Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M20.2441 1.32556C20.2441 1.65828 20.2351 2.00898 20.2231 2.37467C20.2171 2.52754 20.2081 2.6864 20.1991 2.84827C18.9911 2.70739 15.7179 2.37467 11.3027 2.37467C8.51805 2.37467 7.35804 2.49157 6.89943 2.61447C6.89343 2.53353 6.89043 2.4526 6.88744 2.37467C6.87245 2.00898 6.86646 1.65828 6.86646 1.32556H20.2471H20.2441Z"
        fill="#808080"
      />
    </g>
    <path
      d="M21.0836 0.932861L20.9727 1.30455L20.7689 1.98197H6.33915L6.02441 0.932861H21.0836Z"
      fill="url(#paint5_linear_3052_7200)"
    />
    <path
      d="M15.8081 16.1869C15.8081 16.1869 15.7602 16.2258 15.6852 16.2918C15.5594 16.4027 15.3585 16.5885 15.2087 16.7774C15.0918 16.9272 15.0048 17.0741 15.0048 17.191C15.0048 17.6526 14.9419 18.0872 14.831 18.4679C14.789 18.6178 14.7381 18.7557 14.6811 18.8846C14.6721 18.9026 14.6661 18.9235 14.6571 18.9415C14.6331 18.9955 14.6062 19.0464 14.5792 19.0974C14.3184 19.586 13.9557 19.8887 13.5571 19.8887C13.3412 19.8887 13.1374 19.8018 12.9516 19.6429C12.7597 19.4781 12.5919 19.2413 12.457 18.9445C12.2382 18.4739 12.1063 17.8624 12.1063 17.194C12.1063 16.8373 11.303 16.1899 11.303 16.1899L11.309 16.1719L11.6177 15.1917H15.4934L15.6493 15.6893L15.7632 16.049L15.8022 16.1719L15.8081 16.1899V16.1869Z"
      fill="#FFBD26"
    />
    <path
      d="M15.802 16.1659H11.3088L11.6176 15.1887H15.4933L15.6491 15.6863L15.76 16.043L15.802 16.1659Z"
      fill="url(#paint6_linear_3052_7200)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M12.9513 19.6399C12.4537 19.2143 12.109 18.2791 12.109 17.191C12.109 16.8343 11.3057 16.1869 11.3057 16.1869L11.6204 15.1887H15.4961C15.4961 15.1887 11.5634 15.3656 12.4537 16.2978C12.5136 16.4536 13.1431 16.4956 13.1431 16.4956C13.1641 16.4776 12.5526 18.7767 13.3409 18.9445C13.7156 19.0255 13.3949 19.3372 12.9573 19.6429L12.9513 19.6399Z"
        fill="#808080"
      />
    </g>
    <path
      d="M14.9177 19.8857H12.187L12.4508 18.9415H14.6509L14.7169 19.1843L14.8727 19.7598L14.9147 19.8857H14.9177Z"
      fill="url(#paint7_linear_3052_7200)"
    />
    <path
      d="M15.5502 26.6H11.5605L12.19 19.8857H14.9177L14.9327 20.0176L15.5502 26.6Z"
      fill="#FFBD26"
    />
    <path
      d="M16.6922 26.9687C16.3295 26.9417 15.9458 26.9208 15.5472 26.9028H15.5442C15.0496 26.8818 14.534 26.8698 14.0005 26.8638C13.8566 26.8638 13.7097 26.8638 13.5628 26.8638C13.3081 26.8638 13.0563 26.8638 12.8105 26.8698C12.262 26.8788 11.7344 26.8968 11.2338 26.9208C10.9521 26.9357 10.6793 26.9507 10.4155 26.9687L10.5114 26.6H16.5963L16.6202 26.699L16.6922 26.9687Z"
      fill="url(#paint8_linear_3052_7200)"
    />
    <path
      d="M20.5321 27.808H6.57593V29.067H20.5321V27.808Z"
      fill="url(#paint9_linear_3052_7200)"
    />
    <path
      d="M20.5321 27.808H6.57593C6.67484 27.4423 8.21253 27.1246 10.4157 26.9687C11.147 26.9177 11.9504 26.8818 12.8016 26.8668C13.0504 26.8638 13.3052 26.8608 13.563 26.8608C14.687 26.8608 15.7511 26.8968 16.6923 26.9657C17.7954 27.0436 18.7306 27.1605 19.402 27.3044C19.6298 27.3524 19.8277 27.4033 19.9895 27.4573C20.3102 27.5652 20.4991 27.6821 20.5321 27.802V27.808Z"
      fill="#FFBD26"
    />
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M15.808 16.1869C15.808 16.1869 15.7601 16.2258 15.6851 16.2918C14.8548 16.3038 13.6918 16.2408 13.2842 16.2348C13.521 16.2258 13.7488 16.2018 13.9616 16.1689C14.696 16.052 15.2835 15.8182 15.6462 15.6863L15.7601 16.046L15.799 16.1689L15.805 16.1869H15.808Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M14.9327 20.0176C14.4411 19.9846 13.0713 19.8857 13.0713 19.8857L14.8758 19.7598L14.9177 19.8857L14.9297 20.0176H14.9327Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M16.6233 26.699L15.7361 26.6H16.5964L16.6233 26.699Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M21.0835 0.932861L20.9725 1.30455L9.84302 0.932861H21.0835Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M13.3352 18.9415L13.1044 19.8857H12.1902L12.454 18.9415H13.3352Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M13.1042 19.8857L12.7715 26.6H12.6996H11.5605L12.19 19.8857H13.1042Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M12.5648 1.98197H6.33915L6.02441 0.932861H8.69814C8.41038 1.1247 7.57709 1.88905 12.5648 1.98197Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M7.24138 1.98197H6.33915L6.02441 0.932861H6.78277L7.24138 1.98197Z"
        fill="#808080"
      />
    </g>
    <path
      style={{ mixBlendMode: 'soft-light' }}
      opacity="0.8"
      d="M11.4347 9.40966C11.4347 9.40966 10.0199 6.14244 10.2957 2.99512H8.9978C8.9978 2.99512 9.33052 7.28446 11.4377 9.40966H11.4347Z"
      fill="url(#paint10_linear_3052_7200)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M12.8105 26.8668C12.262 26.8758 11.7345 26.8938 11.2339 26.9178L11.5606 26.6H12.7716L12.8105 26.8668Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M11.2339 26.9208C11.2339 26.9208 9.20459 27.4753 9.20459 27.808H11.7045C11.7045 27.808 12.1271 26.9717 12.8135 26.8698L11.2369 26.9208H11.2339Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M11.6955 27.808H9.20166V29.0669H11.6955V27.808Z"
        fill="#808080"
      />
    </g>
    <path
      style={{ mixBlendMode: 'soft-light' }}
      opacity="0.8"
      d="M15.9429 11.0222C15.9429 11.0222 15.9519 10.9833 15.9549 10.9203C16.0118 9.99711 15.9429 2.99207 15.9429 2.99207H19.6537C19.6537 2.99207 19.7197 10.4287 15.9429 11.0192V11.0222Z"
      fill="url(#paint11_linear_3052_7200)"
    />
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M15.814 11.1391C15.8709 10.2159 15.802 3.21088 15.802 3.21088H17.2767C17.2708 4.84149 17.1329 9.19378 15.814 11.1391Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M16.3506 0.932861H13.554V1.98197H16.3506V0.932861Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M17.7683 0.932861H17.0339V1.98197H17.7683V0.932861Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M24.2488 1.84711H23.3315V2.63544H24.2488V1.84711Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M24.2488 6.74493V8.15673C23.967 8.53142 23.6583 8.88511 23.3315 9.21783V8.04883C23.6673 7.64417 23.976 7.20954 24.2488 6.74793V6.74493Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path d="M25.193 1.84711H24.7463V2.63544H25.193V1.84711Z" fill="white" />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M25.193 4.64673V6.66401C25.0521 6.92779 24.9052 7.18557 24.7463 7.43436V5.79475C24.9172 5.42607 25.0671 5.03939 25.193 4.64673Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M3.28458 1.84711H2.26245V2.63544H3.28458V1.84711Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M3.28751 7.26646V8.57335C2.90983 8.12073 2.56513 7.63514 2.26538 7.11958V5.34509C2.54115 6.02851 2.88585 6.67296 3.28751 7.26945V7.26646Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M4.32165 1.84711H3.80908V2.63544H4.32165V1.84711Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M4.32165 8.5494V9.64646C4.1448 9.4876 3.97394 9.32274 3.80908 9.15488V7.97089C3.97094 8.17172 4.1418 8.36655 4.32165 8.55239V8.5494Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M19.2851 14.9069C19.2072 14.9219 19.1322 14.9309 19.0543 14.9309C18.9374 14.9309 18.8235 14.9129 18.7096 14.8739C18.6287 14.4903 18.6017 14.0796 18.5957 13.7379C18.6437 13.8248 18.6976 13.9058 18.7546 13.9717L19.2851 14.9039V14.9069Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M15.2025 26.6H14.0005L14.2942 21.3545L15.2025 26.6Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path d="M13.6617 26.6H13.4758V21.3545L13.6617 26.6Z" fill="white" />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path
        d="M15.5501 26.9028H15.5471C15.0525 26.8818 14.537 26.8698 14.0034 26.8638V26.6H15.2054L15.5501 26.9028Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path
        d="M13.6618 26.6L13.7668 26.8638H13.425L13.476 26.6H13.6618Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.4">
      <path
        d="M15.5501 26.9027C15.5501 26.9027 17.0638 27.2265 17.4924 27.808H15.0435C15.0435 27.808 14.7018 27.1305 14.0034 26.8638L15.5501 26.9027Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path
        d="M13.7668 26.8638C13.7668 26.8638 14.3872 27.1845 14.4232 27.823C14.4232 27.808 13.8717 27.808 13.8717 27.808C13.8717 27.808 13.8837 27.3014 13.425 26.8638H13.7668Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path d="M14.4231 27.808H13.8716V29.0669H14.4231V27.808Z" fill="white" />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path d="M17.4924 27.808H15.0405V29.0669H17.4924V27.808Z" fill="white" />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path
        d="M20.5321 27.808V28.5814C20.4152 28.1048 18.8415 27.808 18.7036 27.808C19.372 27.7571 19.7677 27.5982 19.9895 27.4633C20.3102 27.5712 20.4991 27.6881 20.5321 27.808Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M20.5321 29.0669H6.57593V27.808H10.3048C10.3048 27.808 6.68383 29.0669 20.5321 29.0669Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M20.5321 27.808H6.57593C6.67484 27.4423 8.21253 27.1246 10.4157 26.9687C11.147 26.9178 11.9504 26.8818 12.8016 26.8668C12.7537 27.1066 13.2602 27.796 20.5321 27.805V27.808Z"
        fill="#808080"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M13.7577 16.9842C14.1324 17.176 14.8937 18.0093 14.1504 18.8606C14.0365 18.8935 13.9076 18.9235 13.7577 18.9415C13.8536 18.9415 14.2703 18.9415 14.576 19.0974C14.627 19.1214 14.6779 19.1513 14.7199 19.1843L14.6539 18.9415C14.6629 18.9235 14.6689 18.9025 14.6779 18.8846C14.6899 18.8576 14.6989 18.8276 14.7109 18.8006C14.7259 18.7676 14.7379 18.7347 14.7499 18.6987C14.7768 18.6238 14.8038 18.5488 14.8248 18.4679C14.9357 18.0872 14.9986 17.6526 14.9986 17.191C14.9986 17.0741 15.0856 16.9242 15.2025 16.7773C14.8188 16.8912 14.3062 16.9962 13.7517 16.9872L13.7577 16.9842Z"
        fill="#808080"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3052_7200"
        x1="8.68603"
        y1="7.97987"
        x2="-3.31575"
        y2="9.29875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E07022" />
        <stop offset="1" stopColor="#FFBB26" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3052_7200"
        x1="6.76749"
        y1="8.81617"
        x2="-0.669177"
        y2="-2.24442"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" stopColor="#FFA11F" />
        <stop offset="1" stopColor="#FFE226" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3052_7200"
        x1="17.2768"
        y1="8.45643"
        x2="26.1852"
        y2="8.45643"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E07022" />
        <stop offset="1" stopColor="#FFBB26" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3052_7200"
        x1="17.5854"
        y1="8.39048"
        x2="26.4939"
        y2="8.39048"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" stopColor="#FFA11F" />
        <stop offset="1" stopColor="#FFE226" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3052_7200"
        x1="6.86377"
        y1="8.52543"
        x2="20.2444"
        y2="8.52543"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFAD39" />
        <stop offset="0.44" stopColor="#FFBD26" />
        <stop offset="1" stopColor="#FFE226" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3052_7200"
        x1="6.02441"
        y1="1.45741"
        x2="21.0836"
        y2="1.45741"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFAD39" />
        <stop offset="0.44" stopColor="#FFBD26" />
        <stop offset="1" stopColor="#FFE226" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3052_7200"
        x1="11.3088"
        y1="15.6773"
        x2="15.802"
        y2="15.6773"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8C28" />
        <stop offset="0.44" stopColor="#FFBD26" />
        <stop offset="1" stopColor="#FFE226" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_3052_7200"
        x1="12.2979"
        y1="19.4331"
        x2="16.3385"
        y2="19.3792"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9130" />
        <stop offset="0.44" stopColor="#FFBD26" />
        <stop offset="1" stopColor="#FFE226" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_3052_7200"
        x1="10.4155"
        y1="26.7859"
        x2="16.6922"
        y2="26.7859"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA526" />
        <stop offset="0.44" stopColor="#FFBD26" />
        <stop offset="1" stopColor="#FFE226" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_3052_7200"
        x1="6.57593"
        y1="28.4375"
        x2="20.532"
        y2="28.4375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9130" />
        <stop offset="0.44" stopColor="#FFBD26" />
        <stop offset="1" stopColor="#FFE226" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_3052_7200"
        x1="8.99481"
        y1="6.20239"
        x2="11.4347"
        y2="6.20239"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_3052_7200"
        x1="15.9429"
        y1="7.00865"
        x2="19.6537"
        y2="7.00865"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

export default TrophyGold;
