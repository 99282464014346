import React, { useRef } from 'react';
import { Stack, styled } from '@mui/material';
import { strictValidArray } from '~utils/commonUtils';

import ArchivedVideoCardItem from './archived-video-card-item';
import { Event } from '~types/event';

const StyledRoot = styled(Stack)(({ theme }) => ({
  maxWidth: '100%',
  overflowX: 'auto',
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 'calc(100% - 32px)',
    marginLeft: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: 300,
  },
  '&::-webkit-scrollbar': {
    width: 16,
    height: 8,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.primary.main,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.warning.main,
    borderRadius: theme.spacing(1),
  },
}));

type ArchivedVideoCardsPropList = {
  events: Event[];
  artistImage?: string;
  direction?: 'row' | 'column';
};

const ArchivedVideoCardList = ({
  events,
  artistImage,
  direction = 'row',
}: ArchivedVideoCardsPropList) => {
  const eventCardRef = useRef<HTMLDivElement | null>(null);

  return (
    <StyledRoot
      ref={eventCardRef}
      direction={{
        xs: 'column',
        md: direction,
      }}
      spacing={2}
      mt={'auto'}
      justifyContent={'start'}
      overflow={{
        xs: 'scroll',
      }}
    >
      {strictValidArray(events) &&
        events.map((item) => (
          <ArchivedVideoCardItem
            key={item.id}
            isDragging={false}
            event={item}
            artistImage={artistImage}
            maxWidth={300}
          />
        ))}
    </StyledRoot>
  );
};

export default ArchivedVideoCardList;
