import { Dispatch } from 'react';
import { WEB_SOCKET_RECONNECT_INTERVAL } from '~utils';
import { SocketConnection } from '~socket/socket-management/SocketConnection';

export class SocketManagement {
  socket: SocketConnection | null = null;
  triggerFn: () => void = () => undefined;
  reconnectFn: NodeJS.Timer | null = null;

  constructor() {
    this.createNewSocket = this.createNewSocket.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.getSnapShot = this.getSnapShot.bind(this);
    this.closePreviousSocket = this.closePreviousSocket.bind(this);
  }

  createNewSocket(dispatch: Dispatch<unknown>, userId: string | null) {
    this.closePreviousSocket();
    this.socket = new SocketConnection(dispatch, userId);
    this.socket.init(this.triggerFn);
    this.reconnectFn = setInterval(() => {
      if (this.socket?.isConnecting()) {
        return;
      }
      if (this.socket?.isClose()) {
        this.createNewSocket(dispatch, userId);
      }
    }, WEB_SOCKET_RECONNECT_INTERVAL);
  }
  subscribe(triggerFn: () => void): () => void {
    this.triggerFn = triggerFn;
    return () => {
      this.triggerFn = () => undefined;
    };
  }
  getSnapShot() {
    return this.socket;
  }

  closePreviousSocket() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
      this.triggerFn();
    }
    if (this.reconnectFn) {
      clearInterval(this.reconnectFn);
      this.reconnectFn = null;
    }
  }
}
