import React from 'react';
import { ModalWindow, Button } from '~components/atoms';
import { PAYOUT_MODAL_WINDOWS, ModalWindowFunctions } from '../types';
import { styled, Typography, Box, Stack } from '@mui/material';
import { ThinCrossSVG } from '~components/icons';
import { formatMoney } from '~utils/formatters';
import { UnionPayoutMethods } from '../../payout-method/types';
import MethodLabel from '~components/manage-profile/tabs/payout/payout-method/method-label';

interface Props extends Omit<ModalWindowFunctions, 'windowType'> {
  open: boolean;
  withdrawalAmount: number;
  fee: number;
  activeMethod: UnionPayoutMethods;
}

const SummaryModalWindow = ({
  open,
  withdrawalAmount,
  onOpen,
  onClose,
  fee, // 25
  activeMethod,
}: Props) => {
  const ursaLiveFeeAmount = (withdrawalAmount * fee) / 100;
  const totalPayout = withdrawalAmount - ursaLiveFeeAmount;

  const handleClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose();
  };

  return (
    <CustomModalWindow open={open} onClose={handleClose}>
      <>
        <Row sx={{ pb: 4, alignItems: 'center' }}>
          <Title>Your summary</Title>
          <ThinCrossSVG onClick={onClose} />
        </Row>
        <Stack spacing={2.5}>
          {activeMethod && (
            <Row>
              <TopicName>Payout method:</TopicName>
              <MethodLabel methodType={activeMethod} />
            </Row>
          )}
          <Row>
            <TopicName>Withdrawal Amount:</TopicName>
            <TopicAmount>{formatMoney(withdrawalAmount)}</TopicAmount>
          </Row>
          <Row>
            <TopicName>Ursa Live fee 25%:</TopicName>
            <TopicAmount>{formatMoney(ursaLiveFeeAmount)}</TopicAmount>
          </Row>
          <Divider />
          <Row>
            <TopicName>Total Payout:</TopicName>
            <TopicAmount>{formatMoney(totalPayout)}</TopicAmount>
          </Row>
          <Buttons>
            <Button
              onClick={onClose}
              typeStyles={'cancelWithoutBorder'}
              sx={{ width: ' 50%' }}
            >
              Cancel
            </Button>
            <Button
              sx={{ width: ' 50%' }}
              onClick={() => onOpen(PAYOUT_MODAL_WINDOWS.CONFIRMATION)}
            >
              Payout
            </Button>
          </Buttons>
        </Stack>
      </>
    </CustomModalWindow>
  );
};

export default SummaryModalWindow;

const CustomModalWindow = styled(ModalWindow)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: 24,
  borderRadius: 20,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.up('xs')]: {
    width: 400,
  },
}));

const Row = styled(Stack)(() => ({}));
Row.defaultProps = {
  direction: 'row',
  justifyContent: 'space-between',
};

const Divider = styled(Box)(({ theme }) => ({
  height: 2,
  width: '100%',
  background: theme.palette.secondary.light,
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

Title.defaultProps = {
  variant: 'h5',
};

const TopicName = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.text.primary,
}));
TopicName.defaultProps = {
  fontSize: {
    xs: 14,
  },
};

const TopicAmount = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.text.primary,
}));

const Buttons = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
}));
