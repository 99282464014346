import { styled, Typography } from '@mui/material';

export const Description = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.primary,
  fontSize: theme.typography.subtitle2.fontSize,
  textAlign: 'center',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    paddingBottom: 15,
  },
}));
