import {
  Button,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import BasePopup from '../../base-popup';
import { useEffect, useState } from 'react';
import { FormProvider, RHFTextField } from '~components/molecules';
import { useForm } from 'react-hook-form';
import {
  addOrRemoveArchivedVideoProfile,
  setDefaultShownUp,
  setOrUnsetArchivedVideoAsDefault,
  updateVideoTitle,
} from '~api/archived-videos';
import { useDispatch } from 'react-redux';
import { RootDispatch, RootState, select } from '~stores';
import { useSelector } from 'react-redux';
import { Event } from '~types';
import { useGlobalDispatch } from '~utils/container';

const StyledAddVideoToProfileModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],

  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  },
  [theme.breakpoints.up('sm')]: {
    width: 638,
  },
}));

const StyledCloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: -12,
  top: -12,
}));

const enum ArtistAddVideoStep {
  ADD_VIDEO,
  REVIEW_VIDEO,
  SET_VIDEO_TITLE,
}

type VideoOptions = {
  isDefault: boolean;
  isAddToProfile: boolean;
  isShownPopup: boolean;
};

const AddVideoToProfileModal = ({
  showAddVideoToProfilePopup,
  onNext,
  eventId,
}: any) => {
  const dispatch = useDispatch<RootDispatch>();
  const globalDispatch = useGlobalDispatch();
  const videos: any = useSelector<RootState>((state) =>
    select.archivedVideos.getArchivedVideos(state),
  );
  const [modalStep, setModalStep] = useState<ArtistAddVideoStep>(
    ArtistAddVideoStep.ADD_VIDEO,
  );
  useEffect(() => {
    if (showAddVideoToProfilePopup) {
      setModalStep(ArtistAddVideoStep.ADD_VIDEO);
    } else {
      setModalStep(ArtistAddVideoStep.SET_VIDEO_TITLE);
    }
  }, [showAddVideoToProfilePopup]);
  const onCloseModal = () => {
    onNext();
  };
  const goToSetTitle = () => setModalStep(ArtistAddVideoStep.SET_VIDEO_TITLE);
  // State handling videos options
  const [videoOptions, setVideoOptions] = useState<VideoOptions>({
    isDefault: true,
    isAddToProfile: true,
    isShownPopup: true,
  });
  const setAddToProfile = (value: any) => {
    setVideoOptions({ ...videoOptions, isAddToProfile: value });
  };
  const toggleDefaultOption = () => {
    setVideoOptions({ ...videoOptions, isDefault: !videoOptions.isDefault });
  };
  const toggleAlwaysDefaultOption = () => {
    setVideoOptions({
      ...videoOptions,
      isShownPopup: !videoOptions.isShownPopup,
    });
  };

  const onSubmitVideoOptions = async () => {
    try {
      if (videoOptions.isAddToProfile) {
        await addOrRemoveArchivedVideoProfile(eventId, true);
      }
      if (videoOptions.isDefault) {
        await setOrUnsetArchivedVideoAsDefault(eventId, true);
      }
      if (!videoOptions.isShownPopup) {
        await setOrUnsetArchivedVideoAsDefault(eventId, true);
        await setDefaultShownUp(false);
        globalDispatch({
          type: 'user',
          payload: { showAddVideoToProfilePopup: false },
        });
      }
      goToSetTitle();
    } catch (e) {
      // TODO: handle exception
    }
  };

  // Form handling video titles
  const methods = useForm<any>({
    defaultValues: {
      liveCastTitle: '',
    },
  });
  const { handleSubmit } = methods;
  const updateVideosState = (event: Event) => {
    dispatch.archivedVideos.updateAnEventInList({
      currentList: videos,
      event: event,
    });
  };
  const onSubmit = async (values: any) => {
    try {
      const updatedEvent = (
        await updateVideoTitle(values.liveCastTitle, eventId)
      ).data;
      updateVideosState(updatedEvent);
      onNext();
    } catch (e) {
      // TODO: handle exception
    }
  };
  return (
    <StyledAddVideoToProfileModal
      onClose={onCloseModal}
      closeBtn={
        <StyledCloseButton onClick={onCloseModal}>
          <CloseIcon
            sx={{
              color: 'white',
            }}
          />
        </StyledCloseButton>
      }
      open={true}
    >
      {modalStep === ArtistAddVideoStep.ADD_VIDEO && (
        <Container spacing={3} alignItems={'center'}>
          <PopupTitle textTransform={'uppercase'}>
            ADD VIDEO TO YOUR PROFILE
          </PopupTitle>
          <SubTitle>
            If you choose not to add it now, you have 2 weeks to add it from
            your MANAGE PROFILE Videos tab.
          </SubTitle>
          <EnhancedRadioGroup
            value={videoOptions.isAddToProfile}
            onChange={(value) => setAddToProfile(value.target.value)}
            row
            aria-label="position"
            name="position"
          >
            <FormControlLabel
              value={true}
              control={<CustomRadio />}
              label="YES"
              labelPlacement="start"
              defaultChecked={true}
            />
            <FormControlLabel
              value={false}
              control={<CustomRadio />}
              label="NO"
              labelPlacement="start"
            />
          </EnhancedRadioGroup>
          <Stack alignItems={'left'} spacing={1}>
            <Description
              sx={{ fontWeight: 500, textAlign: 'center' }}
              alignSelf={'center'}
            >
              Save your video so that fans can watch your livecasts on demand
            </Description>
            <Stack direction={'row'} alignItems={'top'} spacing={1}>
              <GradientCheckbox
                value={videoOptions.isDefault}
                onChange={toggleDefaultOption}
              />
              <Description>
                Set this video as the default video displayed on your profile,
                you can change this at any time under MANAGE PROFILE.
              </Description>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <GradientCheckbox
                value={videoOptions.isShownPopup}
                onChange={toggleAlwaysDefaultOption}
              />
              <Description>
                Always make the latest livecast the default video and don't show
                this pop-up again
              </Description>
            </Stack>
          </Stack>
          <Stack sx={{ width: '100%' }} direction={'row'} spacing={2}>
            <Button
              sx={{ height: 50, textTransform: 'capitalize' }}
              fullWidth
              type="submit"
              color="warning"
              variant="contained"
              onClick={onSubmitVideoOptions}
            >
              Next
            </Button>
          </Stack>
        </Container>
      )}
      {modalStep === ArtistAddVideoStep.SET_VIDEO_TITLE && (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Container
            spacing={3}
            alignItems={'center'}
            sx={{ width: '100%', height: '100%' }}
          >
            <PopupTitle textTransform={'uppercase'}>VIDEO TITLE</PopupTitle>
            <RHFTextField
              name={'liveCastTitle'}
              type="text"
              placeholder="Enter your live cast title"
            />
            <Button
              sx={{ height: 50, textTransform: 'capitalize' }}
              fullWidth
              type="submit"
              color="warning"
              variant="outlined"
            >
              Next
            </Button>
          </Container>
        </FormProvider>
      )}
    </StyledAddVideoToProfileModal>
  );
};

export default AddVideoToProfileModal;

const CheckedIcon = styled('span')({
  width: 25,
  height: 25,
  background: 'linear-gradient(180deg, #CE7CAC 0%, #7473B6 100%)',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    content: '""',
    display: 'block',
    width: '80%',
    height: '80%',
    mask: 'url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27white%27%3E%3Cpath d=%27M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z%27/%3E%3C/svg%3E") no-repeat center',
    backgroundColor: 'white',
  },
});

const UncheckedIcon = styled('span')({
  width: 25,
  height: 25,
  background: 'linear-gradient(180deg, #CE7CAC 0%, #7473B6 100%)',
  borderRadius: '4px',
});

const GradientCheckbox = (props: CheckboxProps) => (
  <Checkbox {...props} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
);

const Description = styled(Typography)(({ theme }) => ({
  color: theme.typography.subtitle2.color,
  fontSize: theme.typography.subtitle2.fontSize,
  font: theme.typography.subtitle2.font,
  fontWeight: 400,
  display: 'inline',
}));

const PopupTitle = styled(Typography)(({ theme }) => ({
  color: theme.typography.h2.color,
  font: theme.typography.h2.font,
  fontSize: 13,
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h2.fontSize,
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.typography.h4.color,
  font: theme.typography.h4.font,
  fontWeight: 600,
  fontSize: 13,
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h6.fontSize,
  },
}));

const EnhancedRadioGroup = styled(RadioGroup)(() => ({
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '8px',
  width: '100%',
  '& label': {
    margin: 0,
    width: '100%',
    position: 'relative',
  },
  '& label:first-child': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  '& label .MuiTypography-root': {
    position: 'absolute',
    left: '15px',
    fontSize: '16px',
    lineHeight: '19px',
  },
}));

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.7)',
  '&.Mui-checked': {
    color: theme.palette.action.active,
  },
}));

const Container = styled(Stack)(({ theme }) => ({
  paddingX: 1,
  [theme.breakpoints.up('sm')]: {
    paddingX: 5,
  },
}));
