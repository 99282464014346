import * as React from 'react';
import { alpha, Stack, styled, Tooltip, Typography } from '@mui/material';

import { getImageURL } from '~utils/awsUtils';
import AvatarMui from '@mui/material/Avatar';

export interface ArtistInfoProps {
  name: string;
  imageUrl?: string;
  genre?: string;
  onClick?: () => void;
}

const Container = styled(Stack)(() => ({
  height: 50,
  paddingTop: 5,
  paddingBottom: 5,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'gray',
  },
}));

const StyledArtistName = styled(Typography)(() => ({
  fontSize: '14px !important',
  maxWidth: 120,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 120,
  marginTop: 2,
  fontWeight: '500',
  padding: ` ${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
  backgroundColor: alpha(theme.palette.text.primary, 0.21),
  borderRadius: theme.shape.borderRadius,
  fontSize: '12px !important',
  '&:hover': {
    cursor: 'pointer',
  },
}));
const ArtistInfo = ({ onClick, name, imageUrl, genre }: ArtistInfoProps) => {
  return (
    <Container onClick={onClick}>
      <Stack paddingX="10px">
        <AvatarMui
          sx={{ width: 30, height: 30 }}
          src={imageUrl ? getImageURL(imageUrl) : ''}
        />
      </Stack>
      <Stack flex={1} justifyItems="start" alignItems="start" height="100%">
        <Tooltip
          enterDelay={2000}
          title={name}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -5],
                  },
                },
              ],
            },
          }}
        >
          <StyledArtistName
            variant="body1"
            lineHeight={'14px'}
            fontWeight={'500'}
          >
            {name}
          </StyledArtistName>
        </Tooltip>
        {genre && (
          <Tooltip
            enterDelay={2000}
            title={genre}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
          >
            <StyledLabel variant="caption">{genre}</StyledLabel>
          </Tooltip>
        )}
      </Stack>
    </Container>
  );
};

export default ArtistInfo;
