import { LandingPageResponse } from '~types/landing-page';
import { getRequest } from '~utils';

export const getDataInLandingPage = async (): Promise<LandingPageResponse> => {
  return await getRequest({
    path: 'users/landing-page',
    config: {
      params: {
        is_featured: true,
      },
    },
  });
};
