import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '~stores/store';
import { useLiveCoinOptions } from '~utils/paymentUtils';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { LiveCoinOptionType } from '~types';
import { Box, Grid, Stack, Typography, styled } from '@mui/material';

interface LcTilesProps {
  updateLivecoinPopupFlag?: () => void;
}

const StyledLiveCoinItem = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
  borderRadius: theme.spacing(1),

  cursor: 'pointer',
  '&> .MuiTypography-root': {
    cursor: 'pointer',
  },
}));

const LcTiles = ({ updateLivecoinPopupFlag }: LcTilesProps) => {
  const { showPaymentMethodsPopup } = useDispatchPopup();
  const dispatch = useDispatch<RootDispatch>();
  const handleSBuyCoin = useCallback(
    async (livecoinOption: LiveCoinOptionType) => {
      dispatch.payment.setSelectedLiveCoin(livecoinOption);
      showPaymentMethodsPopup();
      if (updateLivecoinPopupFlag) {
        await updateLivecoinPopupFlag();
      }
    },
    [dispatch.payment, showPaymentMethodsPopup, updateLivecoinPopupFlag],
  );
  const LIVECOIN_OPTIONS = useLiveCoinOptions();

  return (
    <Box mx={{ xs: 6, md: 10 }} my={2.5} px={{ xs: 2, md: 0 }}>
      <Grid container rowSpacing={3} columnSpacing={0.75}>
        {LIVECOIN_OPTIONS.map((livecoinOption) => {
          const { stripeProductId, live_coins, amount } = livecoinOption;
          return (
            <Grid item xs={6} md={3} key={stripeProductId}>
              <StyledLiveCoinItem
                justifyContent={'center'}
                alignItems={'center'}
                onClick={() => handleSBuyCoin(livecoinOption)}
              >
                <Typography
                  color={'primary.main'}
                  fontWeight={'700'}
                  variant="body2"
                  lineHeight={'14px'}
                  paddingBottom={0.5}
                >
                  ${amount}
                </Typography>
                <Typography color={'primary.main'} variant="caption">
                  ({live_coins} lc)
                </Typography>
              </StyledLiveCoinItem>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default LcTiles;
