import React from 'react';
import { Modal, ModalProps, Box, styled } from '@mui/material';

const ModalWindow = ({
  open,
  onClose,
  children,
  className,
  ...rest
}: React.PropsWithChildren<ModalProps>) => {
  return (
    <CustomModalWindow open={open} onClose={onClose} {...rest}>
      <ContentWrapper className={className}>{children}</ContentWrapper>
    </CustomModalWindow>
  );
};

export default ModalWindow;

const CustomModalWindow = styled(Modal)(() => ({
  display: 'flex',
  overflow: 'auto',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '90%',
  backgroundColor: theme.palette.background.default,
  borderRadius: 16,
  margin: 'auto',
  padding: 16,
  overflow: 'auto',
  '::-webkit-scrollbar': {
    display: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: '420px',
  },
}));
