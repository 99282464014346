import { useEffect, ReactNode } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//
import { RootDispatch, RootState, select } from '~stores';
import { AxiosError } from 'axios';
//
import { RoutesPages } from '../../router/types';

type FetchArtistInfoProps = {
  children: ReactNode;
};

const FetchArtistInfo = ({ children }: FetchArtistInfoProps) => {
  const { username: artistName } = useParams();
  const isFetchedArtistInfo = useSelector((state: RootState) =>
    select.roomInfo.isFetchedArtistInfo(state),
  );
  const dispatch = useDispatch<RootDispatch>();

  const navigate = useNavigate();

  useEffect(() => {
    const handleFetchArtistInfo = async () => {
      try {
        await dispatch.roomInfo?.fetchArtistInfo(artistName as string);
      } catch (error) {
        const { response } = error as AxiosError<{
          code: number;
          message: string;
        }>;
        if (response?.data.code === 403) {
          navigate(RoutesPages.ACCESS_DENIED);
          return;
        }
        navigate(RoutesPages.NOT_FOUND);
      }
    };
    if (!isFetchedArtistInfo) {
      handleFetchArtistInfo();
    }
  }, [artistName, dispatch.roomInfo, isFetchedArtistInfo, navigate]);

  if (isFetchedArtistInfo) {
    return <>{children}</>;
  }
  return <></>;
};

export default FetchArtistInfo;
