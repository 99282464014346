import { Modal, Box } from '@mui/material';
import popupStyles from '~components/popup/popupStyles';

interface PrivacyPolicyModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const styles = {
  popup: [
    popupStyles.popup,
    {
      width: { xs: '90%', sm: '90%' },
      overflow: 'auto',
      '& *': { all: 'revert' },
    },
  ],
};

const PrivacyPolicyModal = ({ show, onClose }: PrivacyPolicyModalProps) => {
  return (
    <Modal open={show} onClose={onClose}>
      <Box sx={styles.popup}>
        <div>
          <div>PRIVACY POLICY&nbsp;</div>

          <div>Effective Date: January 1st, 2023</div>

          <div>
            <ol
              start={1}
              style={{
                listStylePosition: 'inside',
                paddingInlineStart: 0,
              }}
            >
              <br />
              <li>
                Introduction and Overview.
                <br />
                <br />
                <div>
                  This Privacy Policy provides a comprehensive description of
                  how&nbsp;Ursa Live&nbsp;(&ldquo;Ursa,&rdquo; &ldquo;we,&rdquo;
                  &ldquo;our,&rdquo; or &ldquo;us&rdquo;) collects, uses, and
                  discloses information about you as well as your rights and
                  choices regarding such information. It applies to any online
                  location controlled by us that links to this Privacy Policy
                  (the &ldquo;Service&rdquo;).&nbsp;Capitalized terms not
                  defined in this Privacy Policy have the meaning given those
                  terms in the Terms of&nbsp;Use.&nbsp;
                </div>
                <br />
                <div>
                  By using the Service, you agree to our&nbsp;
                  <a href="#">Terms of Use</a>
                  &nbsp;and to our collection, use,&nbsp;and disclosure
                  practices, and other activities as described in this Privacy
                  Policy. If you do not agree, discontinue use of the
                  Service.&nbsp;
                </div>
                <br />
                <div>
                  If you have any questions or wish to exercise your rights and
                  choices, please contact us as set out in the &ldquo;Contact
                  Us&rdquo; section.&nbsp;&nbsp;
                </div>
                <div>
                  You may have additional rights based on your jurisdiction.
                  Please click the relevant&nbsp;link&nbsp;below for more
                  information:&nbsp;
                </div>
                <br />
                <div>
                  <ul>
                    <li>
                      <a href="#">California</a>
                    </li>
                    <li>
                      <a href="#">Nevada</a>
                    </li>
                    <li>
                      <a href="#">Virginia, Colorado, and Connecticut</a>
                    </li>
                  </ul>
                </div>
                <br />
              </li>
              <li>
                Information Collection
                <br />
                <br />
                <div>
                  <div>
                    <ol
                      start={1}
                      style={{
                        listStyleType: 'upper-alpha',
                      }}
                    >
                      <li>
                        Information You Provide
                        <br />
                        <br />
                        <div>
                          We collect information about you when you use the
                          Service,&nbsp;including information you provide when
                          you access our content.&nbsp;The categories of
                          information we collect include:
                        </div>
                        <br />
                        <div>
                          <ul>
                            <li>
                              Transaction Data&nbsp;relating to the minting,
                              purchase, sale, and transfer&nbsp;of non-fungible
                              tokens (&ldquo;NFTs&rdquo;), including information
                              regarding your cryptocurrency wallet and the
                              purchase price, date,&nbsp;and location of the
                              transaction.
                            </li>
                            <li>
                              Contact Data, including your&nbsp;first and last
                              name,&nbsp;email address, postal address, and
                              phone number.
                            </li>
                            <li>
                              Payment information, including your payment
                              instrument number (such as a credit or debit card
                              number), expiration date, and security code, and
                              bank account information, as necessary to process
                              your payments and for artist payout. We may also
                              collect your Venmo handle to process artist
                              payouts. This information is processed by our
                              payment processors. Specifically, we use Stripe,
                              Venmo, and PayPal as payment processors. We
                              recommend reviewing their privacy policies at
                              <ul>
                                <li>
                                  Stripe:{' '}
                                  <a
                                    href="https://stripe.com/privacy"
                                    target="_blank"
                                  >
                                    https://stripe.com/privacy
                                  </a>
                                </li>
                                <li>
                                  Venmo:{' '}
                                  <a
                                    href="https://venmo.com/legal/us-privacy-policy/"
                                    target="_blank"
                                  >
                                    https://venmo.com/legal/us-privacy-policy
                                  </a>
                                </li>
                                <li>
                                  PayPal:{' '}
                                  <a
                                    href="https://www.paypal.com/us/legalhub/privacy-full"
                                    target="_blank"
                                  >
                                    https://www.paypal.com/us/legalhub/privacy-full
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <br />
                        <div>
                          You may choose to voluntarily provide other
                          information to us that we do not request, and, in such
                          instances, you are solely responsible for such
                          information.
                        </div>
                        <br />
                      </li>
                      <li>
                        Information Collected Automatically
                        <br />
                        <br />
                        <div>
                          In addition, we automatically collect information when
                          you use the Service.&nbsp;The categories of
                          information we automatically collect include:
                        </div>
                        <br />
                        <div>
                          <ul>
                            <li>
                              Service Use Data, including data about features
                              you use, pages you visit, the time of day you
                              browse, and your referring and exiting
                              pages.&nbsp;
                            </li>
                            <li>
                              Device Data, including data about the type of
                              device or browser you use, your device&rsquo;s
                              operating software, your internet service
                              provider, your device&rsquo;s regional and
                              language settings, and device identifiers such as
                              IP address.&nbsp;&nbsp;
                            </li>
                            <li>
                              Location Data, including non-precise location data
                              (such as location derived from an IP address or
                              data that indicates a city or postal code
                              level).&nbsp;
                            </li>
                          </ul>
                          <br />
                          <div>
                            The types of tracking technologies we use to
                            automatically collect this information
                            include:&nbsp;
                          </div>
                          <br />
                          <div>
                            <ul>
                              <li>
                                Cookies, which are&nbsp;small data files stored
                                on your browser that save data about your visit.
                                Cookies often include a unique identifier (e.g.,
                                cookie #123). We use two types of cookies:
                                session cookies and persistent cookies. Session
                                cookies make it easier for you to navigate our
                                website and expire when you close your browser.
                                Persistent cookies help with personalizing your
                                experience, remembering your preferences, and
                                supporting security features. Persistent cookies
                                may remain on your device for extended periods
                                of time, and expire on a set expiration date or
                                when they are deleted manually.&nbsp;&nbsp;
                              </li>
                              <li>
                                Pixels&nbsp;(also known as web beacons), which
                                is code&nbsp;embedded in a service that sends
                                information about your use to a server. There
                                are various types of pixels, including image
                                pixels (which are small graphic images) and
                                JavaScript pixels (which contains JavaScript
                                code). When you access a service that contains a
                                pixel, the pixel may permit us or a separate
                                entity to drop or read cookies on your browser,
                                or collect other information about your
                                visit.&nbsp;&nbsp;&nbsp;&nbsp;
                              </li>
                              <li>
                                Device Fingerprinting, which is the process of
                                analyzing and combining sets of data elements
                                from your device&rsquo;s browser, such as
                                JavaScript objects and installed fonts, to
                                create a &ldquo;fingerprint&rdquo; of your
                                device and uniquely identify your browser and
                                device.&nbsp;
                              </li>
                            </ul>
                            <br />
                            <div>
                              For further information on how we use tracking
                              technologies for analytics, and your rights and
                              choices regarding them, see the
                              &ldquo;Analytics&rdquo; and &ldquo;Your Rights and
                              Choices&rdquo; sections below.&nbsp;
                            </div>
                            <br />
                          </div>
                        </div>
                      </li>
                      <li>
                        Information from Other Sources
                        <br />
                        <br />
                        <div>
                          We also collect information from other sources. The
                          categories of sources from which we collect
                          information include:&nbsp;&nbsp;
                        </div>
                        <br />
                        <ul>
                          <li>
                            Social media platforms&nbsp;with which you interact,
                            including our Discord server.&nbsp;
                          </li>
                          <li>
                            Publicly-available sources, including data in the
                            public domain.&nbsp;&nbsp;
                          </li>
                          <li>
                            Our community,&nbsp;including information relating
                            to your participation in our Discord server, any
                            events&nbsp;you attend,&nbsp;and
                            the&nbsp;rewards&nbsp;you access, if any.&nbsp;
                          </li>
                        </ul>
                        <br />
                      </li>
                      <li>
                        Information We Infer
                        <br />
                        <br />
                        <div>
                          We infer new information from other information we
                          collect to generate information about your likely
                          preferences or other characteristics.
                        </div>
                        <br />
                      </li>
                      <li>
                        Sensitive Information
                        <br />
                        <br />
                        <div>
                          To the extent any categories of information we collect
                          are sensitive categories of information under
                          applicable law, we process such information only for
                          the limited purposes permitted by applicable law. We
                          do not sell or use sensitive categories of information
                          for purposes of targeted advertising or to make
                          inferences.
                        </div>
                        <br />
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
              <li>
                Use of Information
                <br />
                <br />
                <div>
                  We collect and use information in accordance with the
                  practices described in this Privacy Policy. Our purposes for
                  collecting and using&nbsp;information include:&nbsp;
                </div>
                <br />
                <ul>
                  <li>Providing the Service&nbsp;to you.&nbsp;</li>
                  <li>
                    Preventing and addressing fraud, breach of policies or
                    terms, or threats or harm.&nbsp;&nbsp;
                  </li>
                  <li>
                    Understanding trends, usage, and activities, including
                    through tracking technologies or surveys, to make business
                    and marketing decisions.&nbsp;
                  </li>
                  <li>
                    Communicating with you about updates, security alerts,
                    changes to policies, and other transactional messages.&nbsp;
                  </li>
                  <li>
                    Personalizing your experience to show you content we believe
                    you will find interesting.&nbsp;&nbsp;
                  </li>
                  <li>
                    Engaging in direct marketing, promotional communications,
                    and non-personalized advertising.&nbsp;&nbsp;
                  </li>
                  <li>Fulfilling any purpose at your direction.&nbsp;</li>
                  <li>With notice to you and your consent.&nbsp;</li>
                </ul>
                <br />
                <div>
                  Notwithstanding the above, we may use publicly available
                  information (as that term is defined by applicable law) or
                  information that does not identify you (including information
                  that has been aggregated or de-identified as those terms are
                  defined by applicable law) for any purpose to the extent
                  permitted by applicable law. For information on your rights
                  and choices regarding how we use information about you, please
                  see the &ldquo;Your Rights and Choices&rdquo; section
                  below.&nbsp;
                </div>
                <br />
              </li>
              <li>
                Disclosure of Information
                <br />
                <br />
                <div>
                  We disclose the information we collect in accordance with the
                  practices described in this Privacy Policy. The categories of
                  persons to whom we disclose information include:&nbsp;&nbsp;
                </div>
                <br />
                <ul>
                  <li>
                    Service Providers and Contractors. We disclose information
                    to service providers and contractors that process
                    information on our behalf. Service providers and contractors
                    assist us&nbsp;with services such as payment processing,
                    data analytics, marketing and advertising, website hosting,
                    and technical support. To the extent required by law, we
                    contractually prohibit our service providers and contractors
                    from retaining, using, or disclosing information about you
                    for any purpose other than performing the services for us,
                    although we may permit them&nbsp;to&nbsp;use publicly
                    available information (as that term is defined by applicable
                    law) or information that does not identify you (including
                    information that has&nbsp;been aggregated or de-identified
                    as those terms are defined by applicable law) to the extent
                    permitted by applicable law.&nbsp;
                  </li>
                  <li>
                    Affiliates.&nbsp;We disclose information to our affiliates
                    and related entities, including where they act as our
                    service providers or for their own internal purposes.&nbsp;
                  </li>
                  <li>
                    Partners. We disclose information to our partners in
                    connection with offering co-branded services, selling or
                    distributing our products, or engaging in joint marketing
                    activities.&nbsp;&nbsp;
                  </li>
                  <li>
                    Public Forums.&nbsp;We disclose information you make public
                    through the Service, such as information that you post on
                    our Discord server. Please think carefully before making
                    information public as you are solely responsible for any
                    information you make public. Once you have posted
                    information, you may not be able to edit or delete such
                    information, subject to additional rights set out in the
                    &ldquo;Your Rights and Choices&rdquo; section below.&nbsp;
                  </li>
                  <li>
                    Merger or Acquisition. We disclose information in connection
                    with, or during negotiations of, any proposed or actual
                    merger, purchase, sale or any other type of acquisition or
                    business combination of all or any portion of our assets, or
                    transfer of all or a portion of our business to another
                    business.&nbsp;&nbsp;
                  </li>
                  <li>
                    Security and Compelled Disclosure.&nbsp;We disclose
                    information to comply with the law or other legal process,
                    and where required, in response to lawful requests by public
                    authorities, including to meet national security or law
                    enforcement requirements. We also disclose information to
                    protect the rights, property, life, health, security and
                    safety of us, the Service or anyone else.&nbsp;&nbsp;
                  </li>
                  <li>
                    Facilitating Requests.&nbsp;We disclose information where
                    you direct us to disclose the information.&nbsp;&nbsp;
                  </li>
                  <li>
                    Consent.&nbsp;We disclose information with notice to you and
                    your consent.&nbsp;&nbsp;
                  </li>
                </ul>
                <br />
                <div>
                  Notwithstanding the above, we may disclose publicly available
                  information (as that term is defined by applicable law) or
                  information that does not identify you (including information
                  that has been aggregated or de-identified as those terms are
                  defined by applicable law) to the extent permitted by
                  applicable law.&nbsp;For information on your rights and
                  choices regarding how we disclose information about you,
                  please see the &ldquo;Your Rights and Choices&rdquo; section
                  below.&nbsp;
                </div>
                <br />
              </li>
              <li>
                Other Parties
                <br />
                <br />
                <div>
                  We offer parts of our Service through websites, platforms, and
                  services operated or controlled by other parties. In addition,
                  we integrate technologies operated or controlled by other
                  parties into parts of our Service.&nbsp;&nbsp;
                </div>
                <br />
                <div>Some examples include:&nbsp;</div>
                <br />
                <ul>
                  <li>
                    Links.&nbsp;Our Service includes links that hyperlink to
                    websites, platforms, and other services not operated or
                    controlled by us.&nbsp;&nbsp;
                  </li>
                  <li>
                    Brand Pages and Messaging. We may offer our content through
                    social media. Any information you provide to us when you
                    engage with our content (such as through our brand pages or
                    our Discord server) is treated in accordance with this
                    Privacy Policy. Also, if you publicly reference our Service
                    on social media (e.g., by using a hashtag associated
                    with&nbsp;Ursa&nbsp;in a tweet or post), we may use your
                    reference on or in connection with our Service.&nbsp;
                  </li>
                </ul>
                <br />
                <div>
                  Please note that when you interact with other parties,
                  including when you leave our Service, those parties may
                  independently collect information about you and solicit
                  information from you.&nbsp;The information collected and
                  stored by those&nbsp;parties&nbsp;remains subject to their own
                  policies and practices, including what information they share
                  with us, your rights and choices on their services and
                  devices, and whether they store information in the U.S. or
                  elsewhere. We encourage you to familiarize yourself with and
                  consult their privacy policies and terms of use.&nbsp;
                </div>
                <br />
              </li>
              <li>
                Analytics
                <br />
                <br />
                <div>
                  We use analytics services,&nbsp;such as Google Analytics, to
                  help us understand how users access and use the
                  Service.&nbsp;&nbsp;
                </div>
                <br />
                <div>
                  As indicated above, vendors and other parties may act as our
                  service providers, or in certain contexts, independently
                  decide how to process your information. We encourage you to
                  familiarize yourself with and consult their privacy policies
                  and terms of use.&nbsp;
                </div>
                <br />
                <div>
                  For further information on the types of tracking technologies
                  we use on the Service and your rights and choices regarding
                  analytics, please see the &ldquo;Information Collected
                  Automatically&rdquo; and &ldquo;Your Rights and Choices&rdquo;
                  sections.&nbsp;
                </div>
                <br />
              </li>
              <li>
                Your Rights and Choices.
                <br />
                <br />
                <div>
                  In addition to the rights and choices below, you may have
                  additional rights based on your jurisdiction.
                  Please&nbsp;click the relevant link at the top of this Privacy
                  Policy or visit the applicable section below for more
                  information.&nbsp;
                </div>
                <br />
                <ol
                  start={1}
                  style={{
                    listStyleType: 'upper-alpha',
                  }}
                >
                  <li>
                    Tracking Technology Management
                    <br />
                    <br />
                    <ul>
                      <li>
                        Cookies.&nbsp;Most browsers accept cookies by default.
                        You can instruct your browser, by changing its settings,
                        to decline or delete cookies. If you use multiple
                        browsers on your device, you will need to instruct each
                        browser separately. Your ability to limit cookies is
                        subject to your browser settings and
                        limitations.&nbsp;&nbsp;
                      </li>
                      <li>
                        Do Not Track.&nbsp;Your browser settings may allow you
                        to automatically transmit a &ldquo;Do Not Track&rdquo;
                        signal to online services you visit. Note,
                        however,&nbsp;there is no industry consensus as to what
                        site and app operators should do with regard to these
                        signals. Accordingly, unless&nbsp;and until the law is
                        interpreted to require us to do so, we do not monitor or
                        take action with respect to &ldquo;Do Not Track&rdquo;
                        signals. &nbsp;For more information on &ldquo;Do Not
                        Track,&rdquo; visit&nbsp;
                        <a
                          href="http://www.allaboutdnt.com/"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          http://www.allaboutdnt.com
                        </a>
                        .
                      </li>
                    </ul>
                    <br />
                    <div>
                      Please be aware that if you disable or remove tracking
                      technologies some parts of the Service may not function
                      correctly.&nbsp;&nbsp;
                    </div>
                    <br />
                  </li>
                  <li>
                    Analytics Management.
                    <br />
                    <br />
                    <div>
                      Google provides tools to allow you to opt out of the use
                      of certain information collected by Google Analytics
                      at&nbsp;
                      <a
                        href="https://tools.google.com/dlpage/gaoptout"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        https://tools.google.com/dlpage/gaoptout
                      </a>
                      .
                    </div>
                    <br />
                    <div>
                      Please note that the above opt outs only apply to the
                      specific browser from which you opt out. We are not
                      responsible for the effectiveness of any opt outs offered
                      by other entities.&nbsp;&nbsp;
                    </div>
                    <br />
                  </li>
                  <li>
                    Communications.
                    <br />
                    <br />
                    <div>
                      You can unsubscribe from receiving promotional emails by
                      following the unsubscribe instructions at the bottom
                      of&nbsp;the&nbsp;email, or emailing us at{' '}
                      <a href="mailto:support@ursalive.com">
                        support@ursalive.com
                      </a>{' '}
                      with the word UNSUBSCRIBE in the subject field of the
                      email. Please note that you cannot opt out of
                      transactional messages.&nbsp;
                    </div>
                    <br />
                    <div>
                      Please note that your opt out is limited to the email
                      address used and will not affect subsequent subscriptions.
                      &nbsp;&nbsp;
                    </div>
                    <br />
                  </li>

                  <li>
                    Data Subject Requests
                    <br />
                    <br />
                    <div>
                      Where applicable, in your region you may have specific
                      rights under applicable law with respect to your
                      information. See your region-specific terms for details.
                    </div>
                    <br />
                  </li>
                </ol>
              </li>
              <li>
                Children.
                <br />
                <br />
                <div>
                  The Service is not directed toward children under 13 years
                  old, and we do not knowingly collect personal information (as
                  that term is defined by the U.S. Children&rsquo;s Privacy
                  Protection Act, or &ldquo;COPPA&rdquo;) from children. If you
                  are a parent or guardian and believe we have collected
                  personal information from children, please contact us at&nbsp;
                  <a href="mailto:support@ursalive.com">support@ursalive.com</a>
                  . We will delete the personal information in accordance with
                  COPPA.&nbsp;&nbsp;
                </div>
                <br />
              </li>
              <li>
                Data Security.
                <br />
                <br />{' '}
                <div>
                  We implement and maintain reasonable administrative, physical,
                  and technical security safeguards to help&nbsp;protect
                  information about you from loss, theft, misuse and
                  unauthorized access, disclosure, alteration and destruction.
                  Nevertheless, transmission via the internet is not completely
                  secure and we cannot guarantee the security of information
                  about you.&nbsp;
                </div>
                <br />
              </li>
              <li>
                International Transfer.
                <br />
                <br />
                <div>
                  We are based in the U.S. and the information we collect is
                  governed by U.S. law. If you are accessing the Service from
                  outside of the U.S., please be aware that information
                  collected through the Service may be transferred to,
                  processed, stored, and used in the U.S. and other
                  jurisdictions. Data protection laws in the U.S. and other
                  jurisdictions may be different from those of your
                  country&nbsp;of residence. Your use of the Service or
                  provision of any information therefore constitutes your
                  consent to the transfer to and from, processing, usage,
                  disclosure, and storage of information about you in the U.S.
                  and other jurisdictions as set out in this Privacy
                  Policy.&nbsp;&nbsp;
                </div>
                <br />
              </li>
              <li>
                Changes to this Privacy Policy.
                <br />
                <br />
                <div>
                  We reserve the right to revise and reissue this Privacy Policy
                  at any time. Any changes will be effective immediately upon
                  posting of the revised Privacy Policy. Your continued use of
                  our Service indicates your consent to the Privacy Policy then
                  posted. If the changes are material, we may provide you
                  additional notice to your email address. &nbsp;&nbsp;
                </div>
                <br />
              </li>
              <li>
                Contact Us.
                <br />
                <br />
                <div>
                  If you have any questions about our data practices, or
                  experience any difficulty accessing the information in this
                  Privacy Policy, please contact us:&nbsp;
                </div>
                <br />
                <div>By email:&</div>
                <div>support@ursalive.com</div>
                <br />
                <div>By mail:</div>
                <div>Ursa Live Inc.</div>
                <div>370 Clermont Ave.</div>
                <div>Brooklyn, NY 11238</div>
                <br />
              </li>
              <li>
                California.
                <br />
                <br />
                <div>
                  The California Consumer Privacy Act (&ldquo;CCPA&rdquo;), and
                  its replacement the California Privacy Rights Act
                  (&ldquo;CPRA&rdquo;), provide additional rights to California
                  residents. This section addresses those rights and applies
                  only to California residents.
                </div>
                <br />
                <ol
                  start={1}
                  style={{
                    listStyleType: 'upper-alpha',
                  }}
                >
                  <li>
                    Notice of Collection.
                    <br />
                    <br />
                    <div>
                      We have collected the following categories of personal
                      information (as described in the CCPA/CPRA) in the past 12
                      months. For further details on the personal information we
                      collect and how we obtain this information, please review
                      the &ldquo;Information Collection&rdquo; section
                      above.&nbsp;
                    </div>
                    <br />
                    <ul>
                      <li>
                        Identifiers, including email address, and online
                        identifiers (such as IP address). &nbsp;&nbsp;
                      </li>
                      <li>
                        Commercial or transactions information, including
                        records of products or services purchased, obtained, or
                        considered.&nbsp;
                      </li>
                      <li>
                        Internet activity, including browsing history, search
                        history, and interactions with a website, email,
                        application, or advertisement.&nbsp;&nbsp;
                      </li>
                      <li>
                        Payment information, including your payment instrument
                        number (such as a credit or debit card number),
                        expiration date, and security code as necessary to
                        process your payments.
                      </li>
                      <li>
                        Non-precise geolocation data,&nbsp;including location
                        derived from an IP address.&nbsp;
                      </li>
                      <li>
                        Inferences drawn&nbsp;from any of the information
                        identified in this section.&nbsp;&nbsp;
                      </li>
                    </ul>
                    <br />
                    <div>
                      We collect and use this personal information for the
                      business purposes set out in the &ldquo;Use of
                      Information&rdquo; section above.&nbsp;&nbsp;
                    </div>
                    <br />
                    <div>
                      We disclose this personal information to the categories of
                      persons set out in the &ldquo;Disclosure of
                      Information&rdquo; section above.&nbsp;&nbsp;
                    </div>
                    <br />
                    <div>
                      We do not &ldquo;sell&rdquo; or &ldquo;share&rdquo; your
                      personal information as those terms as defined by the
                      CPRA.&nbsp;&nbsp;
                    </div>
                    <br />
                    <div>
                      We do not knowingly sell or share the personal information
                      of minors under 16 years old who are California
                      residents.&nbsp;&nbsp;
                    </div>
                    <br />
                    <div>
                      Some of the personal information we collect may be
                      considered sensitive personal information under CPRA. We
                      collect, use, and disclose such sensitive personal
                      information only for the permissible business purposes for
                      sensitive personal information under the CPRA or without
                      the purpose of inferring characteristics about consumers.
                      We do not sell or share sensitive personal information.
                    </div>
                    <br />
                  </li>
                  <li>
                    Retention.
                    <br />
                    <br />
                    <div>
                      We retain each category of personal information, for the
                      length of time that is reasonably necessary for the
                      purpose for which it was collected, and as necessary to
                      comply with our legal obligations, resolve disputes,
                      prevent fraud, and enforce our agreements.&nbsp;
                    </div>
                    <br />
                  </li>
                  <li>
                    Right to Know, Correct, and Delete.
                    <br />
                    <br />
                    <div>
                      You have the right to know certain details about our data
                      practices. In particular, you may request the following
                      from us:&nbsp;
                    </div>
                    <br />
                    <ul>
                      <li>
                        The categories of personal information we have collected
                        about you; &nbsp;&nbsp;
                      </li>
                      <li>
                        The categories of sources from which the personal
                        information was collected;&nbsp;&nbsp;
                      </li>
                      <li>
                        The categories of personal information about you we
                        disclosed for a business purpose or sold or
                        shared;&nbsp;&nbsp;
                      </li>
                      <li>
                        The categories of persons to whom the personal
                        information was disclosed for a business purpose or sold
                        or shared;&nbsp;&nbsp;
                      </li>
                      <li>
                        The business or commercial purpose for collecting or
                        selling or sharing the personal information;
                        and&nbsp;&nbsp;
                      </li>
                      <li>
                        The specific pieces of personal information we have
                        collected about you.&nbsp;
                      </li>
                    </ul>
                    <br />
                    <div>
                      In addition, you have the right to correct or delete the
                      personal information we have collected from you.
                    </div>
                    <br />
                    <div>
                      To exercise any of these rights, please email us at{' '}
                      <a href="mailto:support@ursalive.com">
                        support@ursalive.com
                      </a>{' '}
                      In the request, please specify which right you are seeking
                      to exercise and the scope of the request. We will confirm
                      receipt of&nbsp;your request within 10 days.&nbsp;We may
                      require specific information from you to help us verify
                      your identity and process your request. If we are unable
                      to verify your identity, we may deny your
                      request.&nbsp;&nbsp;
                    </div>
                    <br />
                  </li>
                  <li>
                    Authorized Agent.
                    <br />
                    <br />
                    <div>
                      You can designate an authorized agent to submit requests
                      on your behalf. However, we will require written proof of
                      the agent&rsquo;s permission to do so and verify your
                      identity directly.&nbsp;&nbsp;
                    </div>
                    <br />
                  </li>
                  <li>
                    Right to Non-Discrimination.
                    <br />
                    <br />
                    <div>
                      You have the right not to receive discriminatory treatment
                      by us for the exercise of any your rights.&nbsp;&nbsp;
                    </div>
                    <br />
                  </li>
                  <li>
                    Shine the Light.
                    <br />
                    <br />
                    <div>
                      Customers who are residents of California may request (i)
                      a list of the categories of personal information (as that
                      term is defined by Shine the Light) disclosed by us to
                      third parties during the immediately preceding calendar
                      year for those third parties&rsquo; own direct marketing
                      purposes; and (ii) a list of the categories of third
                      parties to whom we disclosed such information. To exercise
                      a request, please write us at the email or postal address
                      set out in &ldquo;Contact Us&rdquo; above and specify that
                      you are making a &ldquo;California Shine the Light
                      Request.&rdquo; We may require additional information from
                      you to allow us to verify your identity and are only
                      required to respond to requests once during any calendar
                      year.&nbsp;&nbsp;
                    </div>
                    <br />
                  </li>
                </ol>
              </li>
              <li>
                Nevada
                <br />
                <br />
                <div>
                  We do not sell and will not sell your covered information (as
                  those terms are defined by NRS 603A.340).&nbsp;&nbsp;
                </div>
                <br />
              </li>
              <li>
                Virginia, Colorado, and Connecticut.
                <br />
                <br />
                <div>
                  These additional rights and disclosures apply only to
                  residents of Virginia, Colorado, and Connecticut. Terms have
                  the meaning ascribed to them in the Colorado Privacy Act
                  (“CPA”), the Connecticut Data Privacy Act (“CTDPA”), and the
                  Virginia Consumer Data Protection Act (“VCDPA”), as
                  applicable.
                </div>
                <br />
                <div>
                  You have the following rights under applicable law in your
                  region:
                </div>
                <br />
                <ul>
                  <li>
                    To confirm whether or not we are processing your personal
                    data&nbsp;
                  </li>
                  <li>To access your personal data&nbsp;</li>
                  <li>To correct inaccuracies in your personal data&nbsp;</li>
                  <li>To delete your personal data&nbsp;</li>
                  <li>
                    To obtain a copy of your personal data that you previously
                    provided to us in a portable and readily usable format&nbsp;
                  </li>
                </ul>
                <br />
                <div>
                  To exercise any of these rights, please email us at{' '}
                  <a href="mailto:support@ursalive.com">support@ursalive.com</a>{' '}
                  and specify which right you are seeking to exercise, along
                  with your region. We will respond to your request within 45
                  days. We may require specific information from you to help us
                  confirm your identity and process your request.
                </div>
                <br />
                <div>
                  You can designate an authorized agent to submit requests on
                  your behalf. Requests must be submitted through the designated
                  methods listed above. Except for opt-out requests, we will
                  require written proof of the agent’s permission to do so and
                  may verify your identity directly.
                </div>
                <br />
                <div>
                  If we refuse to take action on a request, you may appeal our
                  decision within a reasonable period time by contacting us at{' '}
                  <a href="mailto:support@ursalive.com">support@ursalive.com</a>{' '}
                  and specifying you wish to appeal. Within 60 days of our
                  receipt of your appeal, we will inform you in writing of any
                  action taken or not taken in response to the appeal, including
                  a written explanation of the reasons for the decisions. If the
                  appeal is denied, you may submit a complaint as follows:
                  <ul>
                    <li>
                      For Virginia residents, to the AG at{' '}
                      <a
                        href="https://www.oag.state.va.us/consumercomplaintform"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        https://www.oag.state.va.us/consumercomplaintform
                      </a>
                    </li>
                    <li>
                      For Colorado residents, to the Colorado AG at{' '}
                      <a
                        href="https://coag.gov/file-complaint/"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        https://coag.gov/file-complaint/
                      </a>
                    </li>
                    <li>
                      For Connecticut residents, to the Connecticut AG at{' '}
                      <a
                        href="https://www.dir.ct.gov/ag/complaint/"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        https://www.dir.ct.gov/ag/complaint/
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PrivacyPolicyModal;
