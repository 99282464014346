import React, { useEffect } from 'react';

import { useGlobalDispatch, useGlobalState } from '~utils/container';
import Avatar from '~components/organisms/avatar';
import { Box, Stack, Typography, alpha, styled } from '@mui/material';

const StyledRoot = styled(Stack)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.grey[800], 0.7),
  borderRadius: theme.spacing(5),
  padding: `${theme.spacing(1.25)} ${theme.spacing(2.5)}`,
  position: 'absolute',
  bottom: 8,
}));

const SteamAllowOnStage = () => {
  const { app: { fan_data_allow_on_stage = {} } = {} } =
    // TODO need to check state data properties, remove as any to see detail error.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useGlobalState() as any;
  const dispatch = useGlobalDispatch();
  const { displayUsername, username } = fan_data_allow_on_stage;
  // This is old code from the former team
  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: 'app',
        payload: { popup: '', fan_data_allow_on_stage: undefined },
      });
    }, 3000);
  }, [dispatch]);

  return (
    <StyledRoot spacing={1.5} direction="row" alignItems={'center'}>
      <Box sx={{ width: 32, height: 32 }}>
        <Avatar username={username ?? ''} />
      </Box>
      <Typography
        variant="body2"
        fontWeight={'500'}
        lineHeight={'17px'}
        width={220}
      >
        {displayUsername ?? ''} has a question and wants to be pulled on stage
        to meet you!
      </Typography>
    </StyledRoot>
  );
};

export default SteamAllowOnStage;
