import React from 'react';
import { useEffect, useState } from 'react';

import { useGlobalDispatch } from '~utils/container';
import { activateAccount } from '~api/user';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { inMemoryJWTService, ON_BOARDING_INFO_STEP, USER_TYPE } from '~utils';
import { Stack, styled } from '@mui/material';
import ResendEmail from './resend-email';
import { activateAccountSuccessPixelEvent } from '~utils/metaPixelEvents';

export default function ActivateAccount() {
  const { key } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const redirectUrl = searchParams.get('redirect_url');
  const dispatch = useGlobalDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    activateAccount(key || '')
      .then((data) => {
        const { user, accessToken, expiresIn, refreshToken } = data;
        const { displayUsername, email, id, name, type, username } = user;
        if (accessToken) {
          dispatch({
            type: 'user',
            payload: {
              name,
              type,
              originalType: type,
              displayUsername,
              username,
              id,
              email,
              showLiveCoinPopup: true,
              onBoardingInfoStep: ON_BOARDING_INFO_STEP.PROFILE,
            },
          });
          inMemoryJWTService.setToken(accessToken, expiresIn);
          inMemoryJWTService.setRefreshToken(refreshToken);
          setIsSuccess(true);
          activateAccountSuccessPixelEvent();
          setTimeout(() => {
            if (redirectUrl) {
              navigate(redirectUrl);
            } else {
              if (type === USER_TYPE.ARTIST) {
                navigate(`/${username}`);
              } else {
                navigate('/');
              }
            }
          }, 3000);
        } else {
          setIsExpired(true);
          setUsername(username);
        }
      })
      .catch((e) => {
        const errorMessage =
          e?.response?.data?.message ?? 'Account Activation failed';
        setErrorMessage(errorMessage);
      });
  }, [dispatch, key, navigate, redirectUrl]);

  if (errorMessage) {
    return (
      <Container>
        <h4>{errorMessage}</h4>
      </Container>
    );
  }

  return (
    <Container>
      {isExpired && <ResendEmail username={username} />}
      {isSuccess && <h4>Your account has been successfully activated!</h4>}
    </Container>
  );
}

const Container = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}));
