import Report from '~components/manage-profile/tabs/reports/report';
import React from 'react';

export const InitialReportsForLoading = () => {
  return (
    <>
      {new Array(3).fill('').map(() => (
        <Report
          name={''}
          startedAt={new Date().toISOString()}
          endedAt={new Date().toISOString()}
          number={0}
          tipEarned={0}
          totalAttendees={0}
          totalFollowers={0}
        />
      ))}
    </>
  );
};
