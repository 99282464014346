import { SVGProps } from 'react';

const VisaCardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="58"
    height="36"
    viewBox="0 0 58 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_795_7776)">
      <g clipPath="url(#clip1_795_7776)">
        <path d="M0 0H57.9404V36H0V0Z" fill="#0E4595" />
        <path
          // eslint-disable-next-line max-len
          d="M21.7801 25.1084L24.2582 11.0137H28.222L25.742 25.1084H21.7801ZM40.0618 11.3176C39.2766 11.032 38.046 10.7256 36.5094 10.7256C32.5929 10.7256 29.834 12.6372 29.8106 15.377C29.7884 17.4024 31.7802 18.5322 33.2835 19.2064C34.8264 19.8972 35.345 20.3378 35.3377 20.9548C35.328 21.8995 34.1057 22.3311 32.9665 22.3311C31.38 22.3311 30.5372 22.1175 29.2355 21.5912L28.7247 21.3673L28.1684 24.5226C29.0942 24.916 30.8062 25.2568 32.5836 25.2745C36.7501 25.2745 39.4548 23.3848 39.4857 20.459C39.5004 18.8556 38.4445 17.6354 36.1577 16.6294C34.7723 15.9775 33.9239 15.5423 33.9329 14.8821C33.9329 14.2963 34.651 13.6697 36.2027 13.6697C37.4989 13.6503 38.4379 13.9243 39.1694 14.2097L39.5246 14.3725L40.0618 11.3176ZM50.2615 11.0135H47.1987C46.2499 11.0135 45.5398 11.2646 45.1232 12.1824L39.2367 25.0992H43.3989C43.3989 25.0992 44.0793 23.3623 44.2332 22.9811C44.6882 22.9811 48.7314 22.9871 49.3095 22.9871C49.4281 23.4805 49.7916 25.0992 49.7916 25.0992H53.4697L50.2615 11.0131V11.0135ZM45.4021 20.115C45.7299 19.3029 46.9814 16.175 46.9814 16.175C46.9579 16.2126 47.3066 15.3589 47.5068 14.8297L47.7746 16.045C47.7746 16.045 48.5337 19.4095 48.6923 20.115H45.4021ZM18.4152 11.0135L14.5347 20.6255L14.1212 18.6722C13.3988 16.4205 11.148 13.981 8.63184 12.7597L12.18 25.0861L16.3737 25.0814L22.6138 11.0134H18.4152"
          fill="white"
        />
        <path
          d="M10.9146 11.013H4.52324L4.47266 11.3062C9.44499 12.4729 12.7351 15.2924 14.1012 18.6798L12.7113 12.2026C12.4714 11.3102 11.7756 11.0439 10.9147 11.0127"
          fill="#F2AE14"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_795_7776">
        <rect width="57.6" height="36" rx="4" fill="white" />
      </clipPath>
      <clipPath id="clip1_795_7776">
        <rect width="57.9404" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default VisaCardIcon;
