import React, { useCallback, useState } from 'react';
import LiveCastOptionsModal from '~components/organisms/popup/livecast-options-modal';
import PartnershipProgramModal from '~components/organisms/popup/partnership-program-modal';
import ArtistSettingDashboardModal from '~components/organisms/popup/setting-dashboard-modal/artist-setting-dashboard-modal';
import { StartingLiveModal, AddingThumbnailPictureLiveModal } from './popup';
import { useGlobalState } from '~utils';
import GoLiveOptionsModal from '~components/organisms/popup/go-live-options-modal';
import ShareProfileStartLiveModal from '~components/organisms/popup/share-profile-start-live-modal';
import { useBoolean } from 'react-use';
import { sendSmsNotifyStartLivestream } from '~api/notifications';
import { showErrorToaster } from '~utils/toasterNotification';

const enum POPUP_NAME {
  NONE,
  PARTNER_SHIP,
  SETTING_DASHBOARD,
  STARTING_LIVE,
  ADDING_THUMBNAIL_PICTURE,
  GO_LIVE_OPTIONS,
  RECORD_VIDEO_DASHBOARD,
  CHECK_MEDIA_SETTING,
}

interface LiveCastOptionsProps {
  onClose: () => void;
  onStartLiveCast: (
    isClearChat: boolean,
    isClearQuestion: boolean,
    isNotifyFollowers: boolean,
    thumbnail?: string,
  ) => void;
  isPartnerShip: boolean;
}

const LiveCastOptions = ({
  onClose,
  onStartLiveCast,
  isPartnerShip,
}: LiveCastOptionsProps) => {
  const {
    app: { questionsList, chatList },
    artist: { name },
  } = useGlobalState();
  const [popup, setPopup] = useState<POPUP_NAME>(POPUP_NAME.NONE);
  const [openLivecastOption, setOpenLivecastOption] = useState(true);
  const [isClearChat, setIsClearChat] = useState(false);
  const [isClearQuestion, setIsClearQuestion] = useState(false);
  const [isNotifyFollowers, setIsNotifyFollowers] = useState(true);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [showShareProfileLiveModal, setShowShareProfileLiveModal] =
    useBoolean(false);

  const onOpenShareProfile = () => {
    setOpenLivecastOption(false);
    setShowShareProfileLiveModal(true);
  };

  const onBackShareProfile = () => {
    setOpenLivecastOption(true);
    setContacts([]);
    setShowShareProfileLiveModal(false);
  };

  const onLiveShareProfile = async (contact: IContact[]) => {
    setShowShareProfileLiveModal(false);
    setIsNotifyFollowers(true);
    setContacts(contact);
    await handleNextGoLiveOptionsScreen();
  };

  const handleStartLiveCast = useCallback(
    async (
      isClearChat: boolean,
      isClearQuestion: boolean,
      isNotifyFollowers: boolean,
      thumbnail?: string,
    ) => {
      if (contacts?.length) {
        try {
          await sendSmsNotifyStartLivestream(contacts.map((c) => c.tel[0]));
        } catch (e) {
          showErrorToaster();
        }
      }
      onStartLiveCast(
        isClearChat,
        isClearQuestion,
        isNotifyFollowers,
        thumbnail,
      );
    },
    [contacts, onStartLiveCast],
  );
  const onOpenPartnershipModal = () => {
    setOpenLivecastOption(false);
    setPopup(POPUP_NAME.PARTNER_SHIP);
  };

  const onOpenShowStartLivePopup = () => {
    setOpenLivecastOption(false);
    setPopup(POPUP_NAME.STARTING_LIVE);
  };

  const onOpenSettingDashboardPopup = () => {
    setPopup(POPUP_NAME.SETTING_DASHBOARD);
  };

  const onOpenCheckSettingPopup = () => {
    setPopup(POPUP_NAME.CHECK_MEDIA_SETTING);
  };

  const onCloseSettingDashboardPopup = () => {
    setPopup(POPUP_NAME.NONE);
    if (!openLivecastOption) {
      onClose();
    }
  };

  const onOpenRecordVideoDashboardPopup = () => {
    setPopup(POPUP_NAME.RECORD_VIDEO_DASHBOARD);
  };

  const onCloseRecordVideoDashboardPopup = () => {
    onCloseSettingDashboardPopup();
  };

  const handleNextGoLiveOptionsScreen = async () => {
    if (!chatList?.length && !questionsList?.length) {
      if (isPartnerShip && isNotifyFollowers) {
        setPopup(POPUP_NAME.ADDING_THUMBNAIL_PICTURE);
      } else {
        await handleStartLiveCast(false, false, isNotifyFollowers);
      }
    } else {
      onOpenShowStartLivePopup();
    }
  };

  const onGoLive = () => {
    setPopup(POPUP_NAME.GO_LIVE_OPTIONS);
  };

  const handleClear = async () => {
    if (isPartnerShip) {
      setPopup(POPUP_NAME.ADDING_THUMBNAIL_PICTURE);
    } else {
      setOpenLivecastOption(false);
      await handleStartLiveCast(
        isClearChat,
        isClearQuestion,
        isNotifyFollowers,
      );
    }
  };
  const handleSkip = async () => {
    if (isPartnerShip) {
      setPopup(POPUP_NAME.ADDING_THUMBNAIL_PICTURE);
      setIsClearChat(false);
      setIsClearQuestion(false);
    } else {
      setOpenLivecastOption(false);
      await handleStartLiveCast(false, false, isNotifyFollowers);
    }
  };

  const onSubmit = async (thumbnail?: string) => {
    setOpenLivecastOption(false);
    await handleStartLiveCast(
      isClearChat,
      isClearQuestion,
      isNotifyFollowers,
      thumbnail,
    );
  };

  return (
    <>
      {openLivecastOption && (
        <LiveCastOptionsModal
          onOpenShareProfile={onOpenShareProfile}
          onClose={onClose}
          open={true}
          onOpenPartnershipModal={onOpenPartnershipModal}
          onOpenSettingDashboardPopup={onOpenSettingDashboardPopup}
          onGoLive={onGoLive}
          isPartnerShip={isPartnerShip}
        />
      )}
      {showShareProfileLiveModal && (
        <ShareProfileStartLiveModal
          onClose={onClose}
          isArtist={false}
          artistName={name}
          onBack={onBackShareProfile}
          onLive={onLiveShareProfile}
        />
      )}
      {popup === POPUP_NAME.PARTNER_SHIP && (
        <PartnershipProgramModal
          onClose={onClose}
          open={true}
          onOpenSettingDashboardPopup={onOpenRecordVideoDashboardPopup}
        />
      )}
      {popup === POPUP_NAME.SETTING_DASHBOARD && (
        <ArtistSettingDashboardModal
          isPartnerShip={isPartnerShip}
          onClose={onCloseSettingDashboardPopup}
          open={true}
          title="PLEASE REVIEW YOUR AUDIO/VIDEO SETTINGS"
        />
      )}
      {popup === POPUP_NAME.RECORD_VIDEO_DASHBOARD && (
        <ArtistSettingDashboardModal
          isPartnerShip={isPartnerShip}
          onClose={onCloseRecordVideoDashboardPopup}
          open={true}
          hideSaveButton={true}
          title="APPLY TO BECOME A PARTNER"
        />
      )}
      {popup === POPUP_NAME.GO_LIVE_OPTIONS && (
        <GoLiveOptionsModal
          isNotifyFollowers={isNotifyFollowers}
          setIsNotifyFollowers={setIsNotifyFollowers}
          onClose={onClose}
          open={true}
          onNext={onOpenCheckSettingPopup}
        />
      )}
      {popup === POPUP_NAME.STARTING_LIVE && (
        <StartingLiveModal
          isClearChat={isClearChat}
          isClearQuestion={isClearQuestion}
          setIsClearChat={setIsClearChat}
          setIsClearQuestion={setIsClearQuestion}
          onClose={onClose}
          open={true}
          onClear={handleClear}
          onSkip={handleSkip}
        />
      )}
      {popup === POPUP_NAME.ADDING_THUMBNAIL_PICTURE && (
        <AddingThumbnailPictureLiveModal
          onClose={onClose}
          open={true}
          onSubmit={onSubmit}
        />
      )}
      {popup === POPUP_NAME.CHECK_MEDIA_SETTING && (
        <ArtistSettingDashboardModal
          isPartnerShip={isPartnerShip}
          onClose={onCloseSettingDashboardPopup}
          onNext={handleNextGoLiveOptionsScreen}
          open={true}
          isGoLiveLayout={true}
          title="AUDIO/VIDEO CHECK"
        />
      )}
    </>
  );
};

export default LiveCastOptions;
