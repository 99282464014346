import { Box, BoxProps } from '@mui/material';
import { Link } from 'react-router-dom';
import useBreakpoints from '~hooks/useBreakpoints';

const Logo = (props: BoxProps) => {
  const { isLg } = useBreakpoints();

  return (
    <Box
      sx={{
        width: {
          xs: 30,
          lg: 120,
        },
      }}
      {...props}
    >
      <Link to="/" reloadDocument>
        <Box
          component="img"
          sx={{ maxWidth: '100%' }}
          width="120px"
          height="36px"
          src={isLg ? '/assets/images/logo.png' : '/assets/images/xs-logo.png'}
          alt="logo_desktop"
        />
      </Link>
    </Box>
  );
};

export default Logo;
