import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { useGlobalDispatch } from '~utils/container';
import { Button, Stack, Typography, styled } from '@mui/material';
import BasePopup from './base-popup';

const StyledPurchaseThankModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
  },
  [theme.breakpoints.up('lg')]: {
    width: 340,
  },
}));

const PurchaseThankModal = () => {
  const { hidePopup } = useDispatchPopup();
  const dispatch = useGlobalDispatch();

  const handleTip = () => {
    hidePopup();
    dispatch({
      type: 'app',
      payload: { popup: 'tip' },
    });
  };

  return (
    <StyledPurchaseThankModal open={true} onClose={hidePopup} closeBtn={true}>
      <Typography variant="subtitle1" fontWeight={'700'} fontSize={18} mb={1.5}>
        You now have livecoin in your wallet but you haven't tipped yet.
      </Typography>
      <Typography variant="subtitle1" fontWeight={'700'} fontSize={18}>
        Would you like to TIP NOW?
      </Typography>
      <Stack
        mt={3}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={1}
        flex={1}
      >
        <Button
          fullWidth
          variant="outlined"
          color="warning"
          sx={{ flexGrow: 1 }}
          onClick={hidePopup}
        >
          Cancel
        </Button>
        <Button
          color="warning"
          variant="contained"
          sx={{ flexGrow: 1 }}
          onClick={handleTip}
          fullWidth
        >
          Tip
        </Button>
      </Stack>
    </StyledPurchaseThankModal>
  );
};

export default PurchaseThankModal;
