export enum RoutesPages {
  HOME = '/',
  NOT_FOUND = '/not-found',
  ACCESS_DENIED = '/access-denied',
  FOR_ARTISTS = '/for-artists',
  FOLLOWING_ARTIST = '/following-artist',
  UNBLOCK_USERS = '/unblock-users',
  COLLECTIBLES = '/collectibles',
  ABOUT = '/about',
  PRIVACY_POLICY = '/privacy-policy',
  CONTACT = '/contact',
  FAQ = '/FAQ',
  FORMAT_IDEAS = '/format-ideas',
  PROMOTION_TIP = '/tips-for-promoting-your-livecast',
  PROMOTION_ARTIST = '/artist-promotional-requirements',
  PROMOTION_THEME = '/theme-night-promo-requirements',
  CONFIRM_UPDATE_EMAIL = '/confirm_change_email/:key',
  ARTIST_PAGE = '/:username/:featureName?/:extraData?',
}

export type PagesPath = (typeof RoutesPages)[keyof typeof RoutesPages];
