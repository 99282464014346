import { Stack, Typography, styled, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { Button } from '~components/atoms';
import BasePopup from '../base-popup';
import React from 'react';
import { getBookingProducerLink } from '~utils/configUtils';

const StyledCloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: -12,
  top: -12,
}));

const StyledBuyLiveCoinModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  [theme.breakpoints.up('xs')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
    width: 'calc(100% - 32px)',
  },
  [theme.breakpoints.up('md')]: {
    width: 466,
  },
}));

const BookingProducerModal = () => {
  const { hidePopup, showBecomePartnershipPopup } = useDispatchPopup();

  const onClickBookNow = () => {
    window.open(getBookingProducerLink(), '_blank');
    hidePopup();
  };

  const onClickLearnMore = () => {
    showBecomePartnershipPopup();
  };

  return (
    <StyledBuyLiveCoinModal
      open={true}
      onClose={hidePopup}
      closeBtn={
        <StyledCloseButton onClick={hidePopup}>
          <CloseIcon
            sx={{
              color: 'white',
            }}
          />
        </StyledCloseButton>
      }
    >
      <Stack alignItems={'center'}>
        <Typography padding={'20px 10px 30px'} textAlign={'center'}>
          Would you like to book a FREE one-on-one with an Ursa Live Producer to
          get your audio/video setup working and sounding great? You could also
          be eligible to become an Ursa Live Partner.
        </Typography>
      </Stack>

      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={1}
        justifyContent="center"
      >
        <Button
          typeStyles={'bigWithoutBorder'}
          variant="outlined"
          onClick={onClickLearnMore}
          sx={{
            textTransform: 'none',
            margin: '0',
          }}
        >
          LEARN MORE
        </Button>
        <Button
          sx={{ textTransform: 'uppercase', margin: '0' }}
          typeStyles={'big'}
          onClick={onClickBookNow}
        >
          BOOK A FREE PRODUCER
        </Button>
      </Stack>
    </StyledBuyLiveCoinModal>
  );
};

export default BookingProducerModal;
