import React from 'react';

import { Box, Typography } from '@mui/material';

const CheckSettingNote = () => {
  return (
    <Box component="span" marginBottom={1}>
      <Typography color={'red'} textAlign={'center'}>
        Please check your Audio/Video by recording a quick clip before you go
        live
      </Typography>
    </Box>
  );
};

export default CheckSettingNote;
