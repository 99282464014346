import React from 'react';

import HandCoins from '../../../images/decorations-landing-page/hand-coins.png';
import { Title } from './title';
import { Description } from './description';
import BigSphere from '~images/decorations-landing-page/big-sphere.png';
import { Stack, styled, TypographyProps } from '@mui/material';

const New = () => {
  return (
    <Container>
      <Sphere src={BigSphere} alt="sphere" />
      <Stack
        sx={{
          alignItems: { xs: 'center', md: 'flex-start' },
          width: { md: '50%' },
          justifyContent: 'center',
        }}
      >
        <Title sx={{ textAlign: { md: 'left' }, maxWidth: 550 }}>
          ENJOY 30 DAYS OF COMMISSION FREE LIVE STREAMING.
        </Title>
        <LocalDescription
          sx={{
            mt: 1.8,
            display: { xs: 'none', md: 'block' },
            maxWidth: 550,
            textAlign: 'left',
            margin: 0,
          }}
        />
      </Stack>
      <HandCoinsImage src={HandCoins} alt="hand-coins" />
      <LocalDescription sx={{ display: { md: 'none' } }} />
    </Container>
  );
};

const LocalDescription = (props: TypographyProps) => (
  <Description sx={{ zIndex: 2 }} {...props}>
    After 30 days you'll make 75% of all tips generated. Ursa Live is proud to
    offer the lowest commission of all music livestream platforms
  </Description>
);

export default New;

const Container = styled(Stack)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  padding: 20,
  gap: 20,
  background: theme.palette.grey['900'],
  [theme.breakpoints.up('md')]: {
    padding: '120px 20px',
    gap: 24,
    flexDirection: 'row-reverse',
  },
}));
const Sphere = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
  display: 'none',
  zIndex: 1,
  opacity: 0.2,
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

const HandCoinsImage = styled('img')(() => ({
  maxWidth: 290,
  margin: '0 auto',
}));
