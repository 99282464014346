import { createContext, Dispatch, ReactNode, useMemo } from 'react';
import { WebSocketEvent } from '~socket/types';
import { useSelector } from 'react-redux';
import { RootState } from '~stores';
import { useRematchDispatch } from '~components/custom-hook/useRematchDispatch';
import { useTriggerTimeout } from '~components/custom-hook';
import { ASocketConnection } from '~socket/types/SocketConnection';

export type BringToStageType = {
  bringToStage: (
    username: string,
    eventId: string,
    userId: string,
    artistId: string,
    callback?: () => void,
  ) => void;
  removeFromStage: (
    username: string,
    eventId: string,
    artistId: string,
  ) => void;
  isAllowBringToStage: boolean;
  enableBringToStage: () => void;
};

const defaultProvider: BringToStageType = {
  bringToStage: () => undefined,
  removeFromStage: () => undefined,
  isAllowBringToStage: true,
  enableBringToStage: () => undefined,
};

export const BringToStageContext =
  createContext<BringToStageType>(defaultProvider);

interface ManageProfileProviderProps {
  children: ReactNode;
  dispatch: Dispatch<any>;
  socket: ASocketConnection | null;
}

const DEBOUNCING_TIME = 15 * 1000;

export const BringToStageProvider = ({
  children,
  dispatch,
  socket,
}: ManageProfileProviderProps) => {
  const { inviteToStageTimestamp } = useSelector(
    (state: RootState) => state.common,
  );
  const { setInviteToStageTimestamp } = useRematchDispatch(
    (dispatch) => dispatch.common,
  );

  const { startTimeout, stopTimeout } = useTriggerTimeout(
    () => setInviteToStageTimestamp(0),
    DEBOUNCING_TIME,
  );

  const bringToStage = (
    username: string,
    eventId: string,
    userId: string,
    artistId: string,
    callback?: () => void,
  ) => {
    socket?.sendPayload({
      event: WebSocketEvent.SEND_STAGE_INVITE,
      username,
      event_id: eventId,
      fan_id: userId,
      artist_id: artistId,
    });
    dispatch({
      type: 'app',
      payload: {
        stageFanId: userId,
        stageFanUsername: username,
      },
    });
    setInviteToStageTimestamp(Date.now());
    startTimeout();
    callback && callback();
  };
  const removeFromStage = (
    username: string,
    eventId: string,
    artistId: string,
  ) => {
    socket?.sendPayload({
      event: WebSocketEvent.REMOVE_FROM_STAGE_REQUEST,
      username: username,
      event_id: eventId,
      remove_from_stage: true,
      artist_id: artistId,
    });
    // setFanUsername('');
    dispatch({
      type: 'app',
      payload: { stageFanId: '', stageFanUsername: '' },
    });
  };

  const isAllowBringToStage = useMemo(() => {
    if (inviteToStageTimestamp === 0) return true;
    const diffSeconds = Date.now() - inviteToStageTimestamp;
    return diffSeconds >= DEBOUNCING_TIME;
  }, [inviteToStageTimestamp]);

  const enableBringToStage = () => {
    stopTimeout();
    setInviteToStageTimestamp(0);
  };

  return (
    <BringToStageContext.Provider
      value={{
        bringToStage,
        removeFromStage,
        isAllowBringToStage,
        enableBringToStage,
      }}
    >
      {children}
    </BringToStageContext.Provider>
  );
};
