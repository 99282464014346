import { styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { createRewardCampaign } from '~api/reward';
import { Button } from '~components/atoms';
import { WrapperBlock } from '~components/manage-profile/components';
import { RewardCampaign, RewardWinner } from '~types/reward';
import { axiosRequest } from '~api/singleton-axios';
import HerePopover from './components/here-popover';
import CampaignForm from './components/campaign-form';
import { validateRewardCampaign } from './campaign-validation';
import { NotificationModalData } from './type';

const getDefaultCampaign = () => {
  const DEFAULT_REWARD_LIST: Partial<RewardWinner>[] = [
    {
      orderNumber: 1,
      rewardName: '',
    },
    {
      orderNumber: 2,
      rewardName: '',
    },
    {
      orderNumber: 3,
      rewardName: '',
    },
    {
      orderNumber: 4,
      rewardName: '',
      isRandom: true,
    },
  ];

  return {
    rewards: DEFAULT_REWARD_LIST,
    startedAt: null,
    endedAt: null,
  } as RewardCampaign;
};

interface CreateCampaignProps {
  putToUpcoming: (campaign: RewardCampaign) => void;
  showNotificationModal: (data: NotificationModalData) => void;
  refetchReward: () => void;
  refetchCurrentUpcomingPage: () => void;
}

const CreateCampaign = (props: CreateCampaignProps) => {
  const [campaign, setCampaign] = useState(getDefaultCampaign());
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const handlePropertyChange =
    (field: string, index?: number) => (value: string | Date) => {
      let newUpdate = {};
      if (field === 'rewards') {
        if (index !== undefined && campaign.rewards) {
          campaign.rewards[index].rewardName = value as string;
          newUpdate = { [field]: campaign.rewards };
        }
      } else {
        newUpdate = { [field]: value };
      }

      setCampaign((prevState) => ({
        ...prevState,
        ...newUpdate,
      }));
    };

  const deleteReward = (index: number) => {
    const newRewards = campaign.rewards?.filter(
      (reward) => reward.orderNumber !== index,
    );
    newRewards?.forEach((reward) => {
      if (reward.orderNumber > index) {
        reward.orderNumber -= 1;
      }
    });

    setCampaign((prevState) => ({
      ...prevState,
      ['rewards']: newRewards ?? [],
    }));
  };

  const isValidCampaign = () => {
    const { errorFields, errors } = validateRewardCampaign(campaign);

    setErrorFields(errorFields);
    setErrorMessages(errors);
    return errors.length === 0;
  };

  const saveCampaign = async () => {
    if (isValidCampaign()) {
      try {
        const result = await createRewardCampaign(campaign);
        setCampaign(getDefaultCampaign());
        props.refetchCurrentUpcomingPage();
        props.putToUpcoming(result.data);
        props.showNotificationModal({
          icon: undefined,
          open: true,
          title: 'Campaign added!',
          description: '',
        });
      } catch (err: unknown) {
        setErrorMessages([
          (axiosRequest.isAxiosError(err) && err.response?.data?.message) ||
            'Something went wrong. Try Again.',
        ]);
      }
    }
  };

  return (
    <WrapperBlock
      title={'Add Rewards Campaign'}
      description={
        'You can enter the same reward for each, or make them unique. Need some recommendations? Tap'
      }
      sx={{ '.MuiBox-root': { border: 'none !important' } }}
      endDescAdornment={<HerePopover />}
    >
      {errorMessages.map((message, index) => {
        return <ErrorBox key={index}>{message}</ErrorBox>;
      })}
      <CampaignForm
        campaign={campaign}
        errorFields={errorFields}
        deleteReward={deleteReward}
        handleChange={handlePropertyChange}
      />
      <CustomNote>
        *Note: Please bear in mind that you are solely responsible for
        fulfilling your rewards, including any physical rewards that require
        shipping. We will share the email addresses of your winners with you
        after each livecast. It's your responsibility to email them for their
        physical addresses if needed.
      </CustomNote>
      <Button
        sx={{ textTransform: 'uppercase', mt: '40px' }}
        typeStyles={'big'}
        onClick={saveCampaign}
      >
        ADD CAMPAIGN
      </Button>
    </WrapperBlock>
  );
};

export default CreateCampaign;

const CustomNote = styled(Typography)(({ theme }) => ({
  marginTop: 8,
  color: theme.palette.darkText.main,
}));

const ErrorBox = styled(Typography)(() => ({
  color: 'red',
}));
