import React, { useState } from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import {
  Box,
  FormControlLabel,
  Stack,
  styled,
  Switch,
  SwitchProps,
} from '@mui/material';
import { toastConfig, useGlobalState } from '~utils';
import { useRematchDispatch } from '~components/custom-hook/useRematchDispatch';
import { RootDispatch, RootState } from '~stores';
import { useSelector } from 'react-redux';
import { Button as CustomButton } from '~components/atoms';
import { toast, ToastOptions } from 'react-toastify';
import { useUpdateCameraAndMicrophone } from '~hooks/useCameraAndMicrophone';
import SettingDeviceSection from '~components/organisms/popup/setting-dashboard-modal/setting-device-section';
import PreviewController from '~components/organisms/popup/setting-dashboard-modal/preview-controller';
import IconPopover from '~components/molecules/icon-popover';
import HelpIcon from '@mui/icons-material/Help';

interface SettingDashboardModalProps {
  onClose: () => void;
  open: boolean;
  title: string;
}

const SettingDashboardWindow = styled(BasePopup)(({ theme }) => ({
  margin: `${theme.spacing(2)} auto 0`,
  backgroundColor: theme.palette.primary.light,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
    height: 'fit-content',
  },
  [theme.breakpoints.up('lg')]: {
    margin: `${theme.spacing(4.25)} auto 0`,
    padding: `${theme.spacing(4)} ${theme.spacing(7.5)}`,
    width: 1120,
  },
}));

const RecordingContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'black',
  backgroundSize: '100% 100%',
  height: 300,
  position: 'relative',
  borderRadius: 8,
  overflow: 'hidden',
  '& video': {
    objectFit: 'contain !important',
  },
  [theme.breakpoints.up('lg')]: {
    height: 562,
  },
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const StackContainer = styled(Stack)(() => ({
  marginTop: `10px !important`,
}));

const LabelContainer = styled(Stack)(() => ({
  fontSize: `12px !important`,
}));

const SettingContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2.5),
}));

const FanSettingDashboardModal = ({
  onClose,
  open,
  title,
}: SettingDashboardModalProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config: { localVideoTrack, localAudioTrack },
  } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const { cameraConfigs, microphoneConfigs } = useSelector(
    (state: RootState) => state.settings,
  );

  const { setSelectedMicrophoneConfig, setSelectedCameraConfig } =
    useRematchDispatch((dispatch: RootDispatch) => dispatch.settings);

  const {
    videoContainer,
    newSelectedMicrophoneConfig,
    newSelectedCameraConfig,
    setNewSelectedMicrophoneConfig,
    setNewSelectedCameraConfig,
    playAudio,
    setPlayAudio,
  } = useUpdateCameraAndMicrophone(true, false);

  const handleChangeMonitorAudio = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPlayAudio(event.target.checked);
  };
  const onSave = async () => {
    try {
      setLoading(true);
      if (newSelectedCameraConfig) {
        setSelectedCameraConfig(newSelectedCameraConfig);
        if (localVideoTrack) {
          await localVideoTrack.setDevice(newSelectedCameraConfig.deviceId);
        }
      }

      if (newSelectedMicrophoneConfig) {
        setSelectedMicrophoneConfig(newSelectedMicrophoneConfig);
        if (localAudioTrack) {
          await localAudioTrack.setDevice(newSelectedMicrophoneConfig.deviceId);
        }
      }
      onClose();
    } catch (err) {
      if (err instanceof Error) {
        toast(err.message, toastConfig as ToastOptions);
        return;
      }
      toast("Can't update device setting", toastConfig as ToastOptions);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingDashboardWindow open={open} onClose={onClose} title={title}>
      <Stack sx={{ marginTop: 2 }}>
        <RecordingContainer ref={videoContainer}>
          <PreviewController />
        </RecordingContainer>
        <SettingContainer>
          <SettingDeviceSection
            setSelectedCameraConfig={setNewSelectedCameraConfig}
            setSelectedMicrophoneConfig={setNewSelectedMicrophoneConfig}
            selectedCameraConfig={newSelectedCameraConfig}
            selectedMicrophoneConfig={newSelectedMicrophoneConfig}
            microphoneConfigs={microphoneConfigs}
            cameraConfigs={cameraConfigs}
            hideVolumeSection={true}
          >
            <StackContainer direction="column" alignItems="start">
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={playAudio}
                    onChange={handleChangeMonitorAudio}
                    sx={{ m: 1 }}
                  />
                }
                label={
                  <LabelContainer direction="row" alignItems="center">
                    Audio monitoring
                    <IconPopover text="Headphones only">
                      <HelpIcon
                        fontSize="small"
                        sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                      />
                    </IconPopover>
                  </LabelContainer>
                }
              />
            </StackContainer>
          </SettingDeviceSection>
        </SettingContainer>
        <CustomButton
          sx={{ textTransform: 'uppercase' }}
          typeStyles={'big'}
          onClick={onSave}
          disabled={loading}
        >
          Save
        </CustomButton>
      </Stack>
    </SettingDashboardWindow>
  );
};

export default FanSettingDashboardModal;
