// components
import { Container } from '~components/atoms';
import SEO from '~components/atoms/seo';
import LandingPageWrapper from '~components/wrapppers/landing-page-wrapper';
import ForArtists from '~components/pages/for-artists';

const ForArtistsPage = () => {
  return (
    <>
      <SEO
        title="Ursa Live | For Artist"
        imgUrl="https://ursalive.com/assets/images/logo-bg.png"
        imgAlt="Ursa Live"
        url="https://ursalive.com/for-artists"
        description="Easy To Set Up, Highly Interactive, Revenue-Producing Livecasts. From Your Home, Direct To Your Fans."
      />
      <Container>
        <LandingPageWrapper hideContact>
          <ForArtists />
        </LandingPageWrapper>
      </Container>
    </>
  );
};

export default ForArtistsPage;
