import React, { useCallback, useEffect, useRef } from 'react';
import { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
// @mui
import { alpha, Box, BoxProps, Stack, styled, Typography } from '@mui/material';
// utils
import { generateChannelName, useGlobalState } from '~utils';
import { loginToAgoraVideo } from '~utils/agoraFunctions';
// components
import {
  SlideRatioContainer,
  SlideWrapper,
  SlideBorder,
} from '~components/pages/home/styles';
import { ThumbNail } from '~components/pages/home/ThumbNail';
import { PlaySVG } from '~components/icons';
// types
import { AgoraClient } from '~types/agora';
import { LandingPageEvent } from '~types/landing-page';
import useBreakpoints from '~hooks/useBreakpoints';
import useGa4EventSender from '~hooks/useGa4EventSender';

type Props = {
  index: number;
  isDragging: boolean;
  agoraClient: IAgoraRTCClient;
  videoTrack: any;
  currentPlayEventId: string;
  agoraName: string;
  addAgoraClient: (agoraClient: { [key: string]: IAgoraRTCClient }) => void;
  addVideoTrack: (videoTrack: { [key: string]: any }) => void;
  setCurrentPlayEventId: (value: string) => void;
} & Pick<
  LandingPageEvent,
  | 'id'
  | 'imageUrl'
  | 'artistUserName'
  | 'genre'
  | 'artistDisplayUserName'
  | 'userName'
  | 'currentAttendees'
  | 'artist_id'
> &
  BoxProps;

export const ItemInSlide = ({
  id,
  index,
  imageUrl,
  genre,
  artistUserName,
  isDragging,
  addAgoraClient,
  currentPlayEventId,
  setCurrentPlayEventId,
  agoraClient,
  artistDisplayUserName,
  userName,
  agoraName,
  videoTrack,
  addVideoTrack,
  currentAttendees,
  ...boxProps
}: Props) => {
  const { isSm } = useBreakpoints();
  const globalState = useGlobalState();
  const { user } = globalState;
  const ga4Sender = useGa4EventSender();
  const videoContainer = useRef(null);

  const handleClick = async () => {
    if (!isDragging) {
      ga4Sender.sendClickEventToCurrentLiveCast(
        artistUserName,
        user.username,
        generateChannelName(artistDisplayUserName, id),
      );
      window.location.href = `/${artistUserName}`;
    }
  };

  const handlePlayVideo = async () => {
    if (!agoraClient) {
      // start loading when the video track is not exist
      const AgoraClient = (await import(
        'agora-rtc-sdk-ng'
      )) as unknown as AgoraClient;
      const agoraClientVideo = AgoraClient.createClient({
        codec: 'h264',
        mode: 'live',
      });
      const channel = generateChannelName(artistDisplayUserName, id);

      await agoraClientVideo.setClientRole('audience');
      await loginToAgoraVideo(agoraClientVideo, channel, agoraName);
      addAgoraClient({ [id]: agoraClientVideo });
    }
  };

  const handleMouseEnter = () => {
    if (!isDragging) {
      setCurrentPlayEventId(id);
      handlePlayVideo();
    }
  };

  const handleMouseLeave = () => {
    setCurrentPlayEventId('');
  };

  const handleUserPublished = useCallback(
    async (user: any, mediaType: any) => {
      if (user.uid === artistUserName) {
        await agoraClient?.subscribe(user, mediaType);
        addVideoTrack({ [id]: user.videoTrack });
      }
    },

    [addVideoTrack, agoraClient, artistUserName, id],
  );

  const handleUserUnpublished = useCallback(
    async (user: any) => {
      if (user.uid === artistUserName) {
        await agoraClient?.unsubscribe(user);
        user.videoTrack?.stop();
      }
    },
    [agoraClient, artistUserName],
  );

  useEffect(() => {
    if (!agoraClient) return;

    agoraClient.on('user-published', handleUserPublished);
    agoraClient.on('user-unpublished', handleUserUnpublished);

    return () => {
      agoraClient?.off('user-published', handleUserPublished);
      agoraClient?.off('user-unpublished', handleUserUnpublished);
    };
  }, [handleUserPublished, handleUserUnpublished, agoraClient]);

  useEffect(() => {
    if (!currentPlayEventId && index < 2) {
      handlePlayVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agoraClient, currentPlayEventId, videoTrack, id, index]);

  useEffect(() => {
    if (!videoContainer.current) return;
    if (id === currentPlayEventId || (!currentPlayEventId && index < 2)) {
      videoTrack?.play(videoContainer.current, {
        mirror: true,
      });
    } else {
      videoTrack?.stop();
    }
  }, [agoraClient, videoTrack, id, index, currentPlayEventId]);

  return (
    <SlideWrapper onClick={handleClick} {...boxProps}>
      <SlideBorder>
        <SlideRatioContainer sx={{ paddingTop: '100%' }}>
          <ThumbNail src={imageUrl} />
          <SliderVideoContent
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <VideoBox ref={videoContainer} />
            {index > 2 && id !== currentPlayEventId && (
              <PlaySVG
                style={{ position: 'absolute', top: 'calc(50% - 20px)' }}
              />
            )}
          </SliderVideoContent>
        </SlideRatioContainer>
        <SliderContent>
          {isSm ? (
            <>
              <UserInfoContainer>
                <Stack
                  flex="1"
                  flexDirection="row"
                  alignItems="center"
                  sx={{
                    paddingLeft: '10px',
                    maxWidth: 'calc(100% - 55px)',
                  }}
                >
                  <UserName>{userName || artistUserName}</UserName>
                  {genre && <GenreWithPoint>{genre}</GenreWithPoint>}
                </Stack>
                <Stack alignItems="center" justifyContent="center">
                  <Live>Live</Live>
                </Stack>
              </UserInfoContainer>
              <Stack alignItems="start" sx={{ width: '100%' }}>
                <Viewer>{`${currentAttendees} ${
                  currentAttendees <= 1 ? 'viewer' : 'viewers'
                }`}</Viewer>
              </Stack>
            </>
          ) : (
            <>
              <Stack
                sx={{
                  paddingLeft: '5px',
                }}
              >
                <UserName>{userName || artistUserName}</UserName>
                {genre ? <Genre>{genre}</Genre> : <Genre>&nbsp;</Genre>}
              </Stack>
              <Stack
                justifyItems="center"
                sx={{ width: '100%' }}
                flexDirection="row"
                mt="2px"
              >
                <Viewer flex={1}>{`${currentAttendees} ${
                  currentAttendees <= 1 ? 'viewer' : 'viewers'
                }`}</Viewer>
                <Stack alignItems="center" justifyContent="center">
                  <Live>Live</Live>
                </Stack>
              </Stack>
            </>
          )}
        </SliderContent>
      </SlideBorder>
    </SlideWrapper>
  );
};

const SliderContent = styled(Box)(() => ({
  background: '#151515',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 8,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  alignItems: 'flex-start',
}));

const UserInfoContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
}));

const Viewer = styled(Typography)(({ theme }) => ({
  color: 'white',
  padding: '2px 10px',
  fontSize: '12px !important',
  borderRadius: 4,
  [theme.breakpoints.down('sm')]: {
    padding: '2px 5px',
  },
}));

const VideoBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  '& video': {
    objectFit: 'contain !important',
  },
}));

const SliderVideoContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}));

const Genre = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
  lineHeight: '15px',
  position: 'relative',
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
const GenreWithPoint = styled(Genre)(({ theme }) => ({
  paddingLeft: 16,
  ':before': {
    content: '""',
    height: 4,
    width: 4,
    backgroundColor: alpha(theme.palette.text.primary, 0.4),
    borderRadius: '50%',
    position: 'absolute',
    top: 'calc(50% - 2px)',
    left: 7,
  },
}));

const Live = styled(Box)(({ theme }) => ({
  color: alpha(theme.palette.text.primary, 0.6),
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: '15px',
  textDecoration: 'uppercase',
  position: 'relative',
  paddingLeft: 16,
  paddingRight: 10,
  fontWeight: 500,
  ':before': {
    content: '""',
    height: 8,
    width: 8,
    backgroundColor: '#E62948',
    borderRadius: '50%',
    position: 'absolute',
    bottom: 'calc(50% - 4px)',
    left: 2,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSize,
  },
}));

const UserName = styled(Typography)(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontSize: theme.typography.fontSize,
  fontWeight: 500,
  color: theme.palette.action.active,
}));
