import { ReactNode } from 'react';

import {
  FullScreen as FullScreenLib,
  useFullScreenHandle,
} from 'react-full-screen';
import { isBrowser } from 'react-device-detect';

import styles from './full-screen.module.scss';
import { IconButton, Stack } from '@mui/material';
import { ExitFullscreenIcon, FullscreenIcon } from '~components/icons';

interface FullScreenProps {
  children: ReactNode;
  hide?: boolean;
}
const FullScreen = ({ children, hide = false }: FullScreenProps) => {
  const handle = useFullScreenHandle();
  const isEnableFullscreen = isBrowser;
  if (!isEnableFullscreen || hide) {
    return <>{children}</>;
  }
  return (
    <FullScreenLib handle={handle} className={`${styles.fullscreenVideo}`}>
      <Stack direction={'row'} spacing={1} height={'100%'}>
        {children}
        <Stack
          direction={'row'}
          alignItems={'center'}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            p: 2,
          }}
        >
          {handle.active ? (
            <IconButton onClick={handle.exit}>
              <ExitFullscreenIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handle.enter}>
              <FullscreenIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </FullScreenLib>
  );
};
export default FullScreen;
