import {
  Box,
  Paper,
  Radio,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { RewardWinner } from '~types/reward';
import AvatarMui from '@mui/material/Avatar';
import { getImageURL } from '~utils/awsUtils';

const DEFAULT_PLACE_LABEL_LIST = [
  '1st place',
  '2nd place',
  '3rd place',
  'Random',
];

const getPlaceLabel = (winner: RewardWinner) => {
  return winner.isRandom
    ? DEFAULT_PLACE_LABEL_LIST[3]
    : DEFAULT_PLACE_LABEL_LIST[winner.orderNumber - 1];
};

const ACTIVE_COLUMNS = [
  {
    field: 'place',
    headerName: 'Place',
    clickable: false,
    valueGetter: getPlaceLabel,
  },
  {
    field: 'imageUrl',
    headerName: 'Current winners',
    renderCell: (data: RewardWinner) =>
      data.winnerName ? (
        <WrapAvatar>
          <AvatarMui src={getImageURL(data.winnerImageUrl)} />
          <Box component={'span'}>{data.winnerName}</Box>
        </WrapAvatar>
      ) : (
        'N/A'
      ),
  },
  {
    field: 'rewardName',
    headerName: 'Reward',
  },
];

const PREVIOUS_COLUMNS = [
  {
    field: 'place',
    headerName: 'Place',
    clickable: false,
    valueGetter: getPlaceLabel,
  },
  {
    field: 'imageUrl',
    headerName: 'User',
    renderCell: (data: RewardWinner) =>
      data.winnerName ? (
        <WrapAvatar>
          <AvatarMui src={getImageURL(data.winnerImageUrl)} />
          <Box component={'span'}>{data.winnerName}</Box>
        </WrapAvatar>
      ) : (
        'N/A'
      ),
  },
  {
    field: 'winnerEmail',
    headerName: 'Email',
  },
  {
    field: 'rewardName',
    headerName: 'Reward',
  },
  {
    field: 'deliveredAt',
    headerName: 'Reward delivered',
    clickable: true,
    renderCell: (data: RewardWinner) => (
      <DeliveredRadio checked={!!data.deliveredAt} disabled />
    ),
  },
];

interface CampaignItemProps {
  isActive: boolean;
  rewards: RewardWinner[];
  markAsDelivered?: (orderNumber: number) => void;
}

const CampaignItem = (props: CampaignItemProps) => {
  const { isActive, rewards, markAsDelivered } = props;

  const handleCellClick = (field: string, row: RewardWinner) => {
    if (field === 'deliveredAt' && !row.deliveredAt) {
      markAsDelivered && markAsDelivered(row.orderNumber);
    }
  };

  const columns = isActive ? ACTIVE_COLUMNS : PREVIOUS_COLUMNS;

  return (
    <Stack mb={1}>
      <TableWrapper component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Reward table">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell key={index} width={index === 0 ? 248 : undefined}>
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rewards
              .sort((a, b) => a.orderNumber - b.orderNumber)
              .map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td': { border: 0, pb: 0 } }}
                >
                  {columns.map((item, key) => (
                    <TableCell
                      key={key}
                      onClick={
                        item.clickable
                          ? () => handleCellClick(item.field, row)
                          : undefined
                      }
                    >
                      {item.renderCell
                        ? item.renderCell(row)
                        : item.valueGetter
                        ? item.valueGetter(row)
                        : row[item.field as keyof RewardWinner]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Stack>
  );
};

export default CampaignItem;

const WrapAvatar = styled(Box)(() => ({
  display: 'inline-flex',

  '& > .MuiAvatar-root': {
    marginRight: '16px',
  },
  '& > span': {
    display: 'flex',
    alignItems: 'center',
  },
}));

const TableWrapper = styled(TableContainer)<{ component: React.ElementType }>(
  ({ theme }) => ({
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    boxShadow: 'none',
    overflowX: 'auto',
    overflowY: 'hidden',

    '& th': {
      color: theme.palette.darkText.main,
    },
    '& tr > th, & tr > td': {
      padding: '12px',
      paddingLeft: 0,
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    },
  }),
);

const DeliveredRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: `${theme.palette.action.active} !important`,
  },
}));
