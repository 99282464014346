import React, { useEffect, useMemo, useState } from 'react';
import { Box, styled } from '@mui/material';

import {
  CustomSlider,
  NextArrowButtons,
  PrevArrowButtons,
  Title,
  Wrapper,
  ArrowContainer,
  SlideWrapper,
} from '../styles';

import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { getOngoingEvents } from '~api/event';

import { generateArrayOfInitialSlides } from '../utils';
import DarkSphere from '~images/decorations-landing-page/dark-sphere.png';
import useBreakpoints from '~hooks/useBreakpoints';
import { ItemInSlide } from './item-in-slide';
import { getImageURL } from '~utils/awsUtils';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '~stores';
import { useRematchDispatch } from '~components/custom-hook/useRematchDispatch';
import { CarouselDots } from '~components/molecules';

export const SLIDES_IN_ROW_IN_RESPONSE = {
  xs: 2,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 3,
};

export const AMOUNT_ROWS = {
  xs: 30,
  sm: 30,
  md: 30,
  lg: 20,
  xl: 20,
};

export const RESPONSIVE = [
  {
    breakpoint: 576,
    settings: {
      arrows: false,
      slidesPerRow: SLIDES_IN_ROW_IN_RESPONSE.xs,
    },
  },
  {
    breakpoint: 768,
    settings: {
      arrows: false,
      slidesPerRow: SLIDES_IN_ROW_IN_RESPONSE.md,
    },
  },
  {
    breakpoint: 992,
    settings: {
      dots: true,
      slidesPerRow: SLIDES_IN_ROW_IN_RESPONSE.md,
    },
  },
];
interface Props {
  total: number;
  agoraName: string;
}

const ArtistsLiveBoard = ({ total, agoraName }: Props) => {
  const breakpoints = useBreakpoints();
  const { agoraClients, videoTracks, currentPlayEventId } = useSelector(
    (state: RootState) => state.liveBoard,
  );

  const { addAgoraClient, addVideoTrack, setCurrentPlayEventId } =
    useRematchDispatch((dispatch: RootDispatch) => dispatch.liveBoard);
  const [limit, setLimit] = useState(
    SLIDES_IN_ROW_IN_RESPONSE[breakpoints.active] *
      AMOUNT_ROWS[breakpoints.active],
  );
  const [offset, setOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const { data: ongoingEvents, refetch } = useQuery({
    queryKey: [QUERY_KEYS.LANDING_EVENTS_ONGOING, limit, offset],
    queryFn: () => getOngoingEvents({ limit, offset, isPartner: true }),
    refetchOnWindowFocus: false,
  });

  const renderData = useMemo(() => {
    const emptySlides = generateArrayOfInitialSlides(total);
    if (!ongoingEvents) return emptySlides;
    const sortedOngoingEvents = ongoingEvents.sort(
      (a, b) => b.currentAttendees - a.currentAttendees,
    );
    return [
      ...emptySlides.slice(0, offset),
      ...sortedOngoingEvents,
      ...emptySlides.slice(offset + limit),
    ];
  }, [limit, offset, ongoingEvents, total]);

  useEffect(() => {
    setLimit(
      SLIDES_IN_ROW_IN_RESPONSE[breakpoints.active] *
        AMOUNT_ROWS[breakpoints.active],
    );
  }, [breakpoints.active]);

  useEffect(() => {
    if (total < offset) {
      setOffset(Math.ceil(total / limit) * limit - limit);
    }
    refetch();
  }, [limit, offset, refetch, total]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: SLIDES_IN_ROW_IN_RESPONSE[breakpoints.active],
    rows: AMOUNT_ROWS[breakpoints.active],
    beforeChange: (oldIndex: number, newIndex: number) => {
      setIsDragging(true);
      setTimeout(() => {
        setIsDragging(false);
      }, 500);
      setOffset(newIndex * limit);
    },
    prevArrow: (
      <ArrowContainer>
        <PrevArrowButtons />
      </ArrowContainer>
    ),
    nextArrow: (
      <ArrowContainer>
        <NextArrowButtons />
      </ArrowContainer>
    ),
    responsive: RESPONSIVE,
    ...CarouselDots({
      sx: {
        mt: 1,
        color: 'white',
      },
    }),
  };

  return (
    <Wrapper>
      <SphereContainer>
        <Sphere src={DarkSphere} alt="sphere" />
      </SphereContainer>
      <Title sx={{ pt: 4 }}>Live Now</Title>
      <Box sx={{ mt: 4, zIndex: 2 }}>
        <CustomSlider
          // initialSlide={offset / SLIDES_IN_ROW_IN_RESPONSE.xl}
          {...settings}
        >
          {renderData.map(
            (
              {
                id,
                artistUserName,
                artistDisplayUserName,
                userName,
                genre,
                imageUrl,
                thumbnail,
                currentAttendees,
                artist_id,
              },
              index,
            ) =>
              offset > index || index >= offset + limit ? (
                <SlideWrapper key={id} />
              ) : (
                <ItemInSlide
                  key={id}
                  id={id}
                  index={index}
                  setCurrentPlayEventId={setCurrentPlayEventId}
                  artistDisplayUserName={artistDisplayUserName}
                  userName={userName}
                  agoraName={agoraName}
                  addAgoraClient={addAgoraClient}
                  currentPlayEventId={currentPlayEventId}
                  agoraClient={agoraClients[id]}
                  addVideoTrack={addVideoTrack}
                  videoTrack={videoTracks[id]}
                  imageUrl={getImageURL(thumbnail || imageUrl)}
                  artistUserName={artistUserName}
                  genre={genre}
                  isDragging={isDragging}
                  artist_id={artist_id}
                  currentAttendees={currentAttendees}
                />
              ),
          )}
        </CustomSlider>
      </Box>
    </Wrapper>
  );
};

export default ArtistsLiveBoard;

const SphereContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  overflow: 'hidden',
}));

const Sphere = styled('img')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: '-300px',
  width: '50%',
  height: '1000px',
  display: 'none',
  transform: 'rotate(180deg)',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));
