import React from 'react';
import ReactHlsPlayer from 'react-hls-player';

interface VideoPlayerProps extends React.HTMLAttributes<HTMLDivElement> {
  url: string;
  autoPlay?: boolean;
}
export const VideoPlayer = ({ url, autoPlay }: VideoPlayerProps) => {
  const playerRef = React.useRef<HTMLVideoElement>(null);

  return url?.endsWith('.m3u8') ? (
    <ReactHlsPlayer
      src={`${url}`}
      autoPlay={autoPlay}
      controls={true}
      playerRef={playerRef}
      style={{ width: '100%', height: '100%' }}
    />
  ) : (
    <video
      src={`${url}#t=2`}
      controls
      autoPlay={autoPlay}
      style={{ width: '100%', height: '100%' }}
    />
  );
};
