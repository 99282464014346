import * as React from 'react';
import { SVGProps } from 'react';

const PayPalSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.402 4.134c.027-.22.041-.445.041-.672A3.462 3.462 0 0 0 9.981 0H4.196a.745.745 0 0 0-.735.622l-2.132 12.67a.745.745 0 0 0 .735.87H4.18c.364 0 .68-.263.74-.622l.01-.059-.294 1.753c-.068.4.241.766.648.766h1.85c.322 0 .595-.232.649-.548l.526-3.126c.09-.538.557-.933 1.103-.933h.486a4.783 4.783 0 0 0 4.783-4.783 3.034 3.034 0 0 0-1.279-2.476Z"
      fill="#002987"
    />
    <path
      d="M13.403 4.134a5.451 5.451 0 0 1-5.41 4.778H6.38a.81.81 0 0 0-.778.586l-.965 5.736c-.067.4.242.766.648.766h1.851c.321 0 .595-.232.648-.548l.526-3.126c.09-.538.557-.933 1.103-.933H9.9a4.783 4.783 0 0 0 4.783-4.783 3.034 3.034 0 0 0-1.28-2.476Z"
      fill="#0085CC"
    />
    <path
      d="M6.378 8.912h1.615a5.45 5.45 0 0 0 5.409-4.778 3.023 3.023 0 0 0-1.76-.562H7.43c-.48 0-.89.346-.97.82l-.858 5.106a.81.81 0 0 1 .777-.586Z"
      fill="#00186A"
    />
  </svg>
);

export default PayPalSvg;
