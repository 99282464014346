import { Box, Stack, Typography, styled, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import BasePopup from '../base-popup';
import LiveCoinList from './live-coin-list';
import { formatMoney } from '~utils/formatters';
import useAccountBalances from '~hooks/useAccountBalances';

const StyledCloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: -12,
  top: -12,
}));

const StyledBuyLiveCoinModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  [theme.breakpoints.up('xs')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
    width: 'calc(100% - 32px)',
  },
  [theme.breakpoints.up('md')]: {
    width: 466,
  },
}));

const StyledHighlightAmount = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
}));

const BuyLiveCoinModal = () => {
  const { hidePopup } = useDispatchPopup();
  const { walletBalance, walletBalanceInUsd } = useAccountBalances();

  return (
    <StyledBuyLiveCoinModal
      open={true}
      onClose={hidePopup}
      closeBtn={
        <StyledCloseButton onClick={hidePopup}>
          <CloseIcon
            sx={{
              color: 'white',
            }}
          />
        </StyledCloseButton>
      }
    >
      <Stack alignItems={'center'}>
        <Typography
          textTransform={'uppercase'}
          fontWeight={'700'}
          fontSize={20}
          variant="subtitle1"
        >
          BUY LIVECOINS
        </Typography>
        <Typography variant="body2" fontWeight={300}>
          10 livecoins (lc) = 1 dollar
        </Typography>
      </Stack>
      <Box my={2.5} px={{ xs: 2, md: 0 }}>
        <LiveCoinList />
      </Box>

      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <Typography variant="body2" fontWeight={'500'} fontSize={{ sm: 12 }}>
          In your wallet{' '}
        </Typography>
        <StyledHighlightAmount flexGrow={1}>
          {formatMoney(walletBalanceInUsd)} (or {walletBalance} LC)
        </StyledHighlightAmount>
      </Stack>
    </StyledBuyLiveCoinModal>
  );
};

export default BuyLiveCoinModal;
