import { delRequest, getRequest, postRequest, Response } from '~utils';
import {
  ArtistGenresResponse,
  ArtistResponse,
  ArtistSearchResponse,
  BlockedUser,
  BlockUserResponse,
  LatestPartnership,
  QuestionListingResponse,
  UnblockUserResponse,
  UserUploadVideoResponse,
} from '~types/artist';

export const deleteQuestions = async (): Promise<
  Response<QuestionListingResponse>
> => {
  return await delRequest({
    path: 'artists/questions',
  });
};

export const getArtistInfo = async (id: string): Promise<ArtistResponse> => {
  return await getRequest({
    path: 'artists/by-id',
    config: {
      params: {
        id,
      },
    },
  });
};

export const getActiveGenres = async (): Promise<ArtistGenresResponse> => {
  return await getRequest({
    path: 'artists/genres',
  });
};

export const uploadPartnerVideo = async (
  videoPath: string,
): Promise<Response<UserUploadVideoResponse>> => {
  return await postRequest({
    path: 'artists/partnership-video',
    data: {
      video_path: videoPath,
    },
  });
};

export const getLatestPartnership = async (): Promise<LatestPartnership> => {
  return await getRequest({
    path: 'artists/latest-partnership',
  });
};

export const getBlockedUsers = async (
  params: Record<string, string | number | boolean>,
): Promise<Response<{ data: BlockedUser[]; count: number }>> => {
  return await postRequest({
    path: `artists/block-user/list`,
    data: params,
  });
};

export const unblockUser = async (
  blockedUserId: string,
): Promise<Response<UnblockUserResponse>> => {
  return await postRequest({
    path: 'artists/unblock-user',
    data: {
      userId: blockedUserId,
    },
  });
};

export const blockUser = async (
  blockedUserId: string,
): Promise<Response<BlockUserResponse>> => {
  return await postRequest({
    path: 'artists/block-user',
    data: {
      userId: blockedUserId,
    },
  });
};

export const searchArist = async (
  search: string,
  offset: number,
  limit: number,
): Promise<ArtistSearchResponse> => {
  return await getRequest({
    path: 'artists/search',
    config: {
      params: {
        search,
        limit,
        offset,
      },
    },
  });
};
