import React from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { ReportView } from '~types/event';

interface TitleDateProps extends Pick<ReportView, 'startedAt' | 'endedAt'> {
  number: number;
}

const TitleDate = ({ number, startedAt, endedAt }: TitleDateProps) => {
  const formatDate = (inputDate: string) => {
    const utcDate: Date = new Date(inputDate);

    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'America/New_York', // EST timezone
      timeZoneName: 'short',
    };

    return utcDate.toLocaleString('en-US', options);
  };

  return (
    <Title>
      <Livecast>{`Livecast ${number}`}</Livecast>
      <DateBox>{formatDate(startedAt)}</DateBox>
      <To>TO</To>
      <DateBox>{formatDate(endedAt)}</DateBox>
    </Title>
  );
};

export default TitleDate;

const Title = styled(Stack)(() => ({
  paddingBottom: 12,
}));

Title.defaultProps = {
  direction: {
    xs: 'column',
    md: 'row',
  },
  spacing: 1.5,
  alignItems: {
    md: 'center',
  },
};

const Livecast = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.active,
}));
Livecast.defaultProps = {
  variant: 'subtitle1',
  fontSize: {
    xs: 18,
    md: 16,
  },
};

const DateBox = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: '500',
}));

DateBox.defaultProps = {
  variant: 'body2',
  fontSize: {
    xs: 14,
    md: 12,
  },
};

const To = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

To.defaultProps = {
  variant: 'caption',
  fontSize: {
    xs: 12,
  },
};
