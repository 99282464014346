import React from 'react';
import { PayoutMethods, UnionPayoutMethods } from '../payout-method/types';
import { BankIcon, PaypalColorfulIcon, VenmoIcon } from '~components/icons';
import { Box, styled, Typography } from '@mui/material';

interface MethodLabelProps {
  methodType: UnionPayoutMethods;
}

const relationsBtwMethodsAndMethodData = {
  [PayoutMethods.STRIPE]: {
    icon: <BankIcon />,
    text: 'Bank Account',
  },
  [PayoutMethods.PAYPAL]: {
    icon: <PaypalColorfulIcon />,
    text: 'PayPal',
  },
  [PayoutMethods.VENMO]: {
    icon: <VenmoIcon />,
    text: 'Venmo',
  },
};

const MethodLabel = ({ methodType }: MethodLabelProps) => {
  if (methodType === false) {
    return <Typography>'Method not selected'</Typography>;
  }
  return (
    <Box sx={{ display: 'flex', gap: 1.25, alignItems: 'center' }}>
      {relationsBtwMethodsAndMethodData[methodType].icon}
      <AccordionTitle>
        {relationsBtwMethodsAndMethodData[methodType].text}
      </AccordionTitle>
    </Box>
  );
};

export default MethodLabel;

const AccordionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
  fontSize: theme.typography.fontSize,
}));
