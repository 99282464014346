import * as yup from 'yup';
import { BankAccountFormData, PaypalVenmoType } from './types';
import 'yup-phone-lite';

const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/i;
const lettersReg = /^[a-zA-Z-&]+$/;
const positiveIntegerReg = /^0*?[1-9]\d*$/;

// Bank Account
export const bankSchema = yup.object<BankAccountFormData>({
  firstName: yup
    .string()
    .matches(lettersReg, 'Please enter valid First name')
    .required('Please enter valid First name')
    .typeError('Please enter valid First name'),
  lastName: yup
    .string()
    .matches(lettersReg, 'Please enter valid Last name')
    .required('Please enter valid Last name')
    .typeError('Please enter valid Last name'),
  routingNumber: yup
    .string()
    .min(9, 'Please enter valid Routing Number with 9 digits')
    .max(9, 'Please enter valid Routing Number with 9 digits')
    .matches(
      positiveIntegerReg,
      'Please enter valid Routing Number with 9 digits',
    )
    .required('Please enter valid Routing Number')
    .typeError('Please enter valid Routing Number'),
  accountNumber: yup
    .string()
    .min(6, 'Please enter at least 6 digits, maximum is 17 digits')
    .max(17, 'Please enter at least 6 digits, maximum is 17 digits')
    .matches(
      positiveIntegerReg,
      'Please enter at least 6 digits, maximum is 17 digits',
    )
    .required('Please enter valid Account Number')
    .typeError('Please enter valid Account Number'),
});

// PayPal/Venmo
// TODO yup.email() has an issue with version 1.0.2, waiting for it to be fixed, then we will using it
export const emailSchema = yup.object({
  [PaypalVenmoType.EMAIL]: yup
    .string()
    .max(255, 'Please enter a valid Email')
    .nullable()
    .matches(emailReg, 'Please enter a valid Email')
    .required('Please enter a valid Email')
    .typeError('Please enter a valid Email'),
});

// PayPal/Venmo
export const phoneHandleSchema = yup.object({
  [PaypalVenmoType.PHONE]: yup
    .string()
    .phone(undefined, 'Please enter a valid phone')
    .required('Please enter a valid phone')
    .typeError('Please enter a valid phone'),
});

// PayPal/Venmo
export const usernameSchema = yup.object({
  [PaypalVenmoType.USERNAME]: yup
    .string()
    .matches(/^\S*$/, 'Please enter a valid Username')
    .max(255, 'Please enter a valid Username')
    .required('Please enter a valid Username')
    .typeError('Please enter a valid Username'),
});
