import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Link, Stack, StackProps, useTheme } from '@mui/material';
// components
import { NAV_ITEMS } from '~components/organisms/header-landing-page/constants';
import useTikTokEvent from '~hooks/useTiktokPixel';

const NavItem = ({ path, selected, label }: any) => {
  const theme = useTheme();
  const tiktokPixel = useTikTokEvent();
  return (
    <Link
      to={path}
      component={RouterLink}
      onClick={() => {
        tiktokPixel.trackClickEvent(`click_header_nav_${label}`);
      }}
      sx={{
        fontSize: theme.typography.fontSize,
        position: 'relative',
        fontWeight: 500,
        textTransform: 'uppercase',
        textDecoration: 'none',
        color: selected
          ? theme.palette.text.primary
          : theme.palette.text.disabled,

        ':hover': {
          textDecoration: 'none',
        },
        [theme.breakpoints.up('lg')]: {
          ':before': {
            content: selected ? "''" : null,
            height: 4,
            width: '100%',
            position: 'absolute',
            bottom: -6,
            left: 0,
            backgroundColor: theme.palette.action.active,
          },
        },
      }}
    >
      {label}
    </Link>
  );
};

const HomeNavbar = ({ ...rest }: StackProps) => {
  const location = useLocation();

  return (
    <Stack
      flex={1}
      justifyContent="center"
      direction="row"
      spacing={5}
      {...rest}
    >
      {NAV_ITEMS.map(({ name, route }) => (
        <NavItem
          key={name}
          selected={route === location.pathname}
          path={route}
          label={name}
        />
      ))}
    </Stack>
  );
};

export default HomeNavbar;
