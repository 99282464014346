import { IMessageEvent } from 'websocket';

import { Event as ArtistEvent } from './event';
import { ON_BOARDING_INFO_STEP } from '~utils/constants';

export type Event = ArtistEvent & {
  calendar_invite?: string;
};

export type GlobalState = {
  user: {
    type: string;
    originalType: string;
    id: string;
    username: string;
    wallet_balance_fan: number;
    onBoardingInfoStep: typeof ON_BOARDING_INFO_STEP;
  };
  artist: {
    id: string;
    username: string;
    name: string;
    events: Event[];
    currentEvent: unknown;
    rewardsContent: '';
    displayUsername: string;
  };
  config: unknown;
  app: unknown;
  rtn_tip_list: unknown;
  rtn_tip: unknown;
  socket_event: IMessageEvent;
};

export type AuthProviderType = {
  login: (
    username: string,
    password: string,
    otp?: string,
  ) => Promise<{ redirectTo?: string | boolean } | void | any>;
  logout: () => Promise<void | false | string>;
  checkAuth: () => Promise<void>;
  getToken: () => string | null;
};

export enum NotificationType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  NO_THANKS = 'NONE',
}

export enum GoLiveNotifyType {
  NONE = 'NONE',
  NOTIFY = 'NOTIFY',
}

export enum PaymentMethodEnum {
  googlePay = 'googlePay',
  applePay = 'applePay',
  card = 'card',
  paypal = 'paypal',
  stripe = 'stripe',
}

export enum PaymentType {
  STRIPE = 'STRIPE',
  PAYPAL = 'PAYPAL',
}
export type PaymentInfoType = {
  appFee: number;
  paymentFee: number;
  computedAmount: number;
  displayAmount: string;
  productId?: string;
};

export type LiveCoinOptionType = {
  [key in PaymentType]: PaymentInfoType;
} & { id: number; amount: number; live_coins: number; stripeProductId: string };

export enum PaymentMethod {
  CARD = 'CARD',
  PAYPAL = 'PAYPAL',
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
}

export enum PartnershipStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum ScreenRatioEnum {
  RATIO_4_3 = '720p_6',
  RATIO_16_9 = '720p_3',
}
