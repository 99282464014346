import React, {
  useState,
  useImperativeHandle,
  ForwardRefRenderFunction,
  useCallback,
  forwardRef,
} from 'react';
import { WrapperBlock } from '~components/manage-profile/components';
import CampaignForm from '~components/manage-profile/tabs/rewards/components/campaign-form';
import CampaignTitle from '~components/manage-profile/tabs/rewards/components/campaign-title';
import { NotificationModalData } from '~components/manage-profile/tabs/rewards/type';
import { RewardCampaign } from '~types/reward';
import { WrapperCampaign } from './components/common';
import {
  deleteRewardCampaign,
  getUpComingCampaigns,
  updateRewardCampaign,
} from '~api/reward';
import { Button, DeleteModalWindow } from '~components/atoms';
import { TrashIcon } from '~components/icons';
import { Pagination, styled, Typography, useTheme } from '@mui/material';
import { axiosRequest } from '~api/singleton-axios';
import { validateRewardCampaign } from '~components/manage-profile/tabs/rewards/campaign-validation';

const DEFAULT_PAGE_SIZE = 3;

export type UpcomingCampaignsRefProps = {
  refetchCurrentPage: () => void;
};

interface UpcomingCampaignsProps {
  initCampaigns: RewardCampaign[];
  total: number;
  showNotificationModal: (data: NotificationModalData) => void;
  refetchReward: () => void;
}

const UpcomingCampaigns: ForwardRefRenderFunction<
  UpcomingCampaignsRefProps,
  UpcomingCampaignsProps
> = (props: UpcomingCampaignsProps, ref) => {
  const theme = useTheme();
  const { showNotificationModal, refetchReward } = props;
  const [page, setPage] = useState(1);
  const [paginationCampaigns, setPaginationCampaigns] = useState<{
    [key: number]: RewardCampaign[];
  }>({
    [page]: props.initCampaigns,
  });
  const campaigns = paginationCampaigns[page] || [];
  const totalPage = Math.ceil(props.total / DEFAULT_PAGE_SIZE);
  const [deleteCampaignId, setDeleteCampaignId] = useState<string | null>(null);
  const [errorMessagesDict, setErrorMessagesDict] = useState<{
    [key: string]: string[];
  }>({});
  const [errorFieldsDict, setErrorFieldsDict] = useState<{
    [key: string]: string[];
  }>({});
  const [changedDict, setChangedDict] = useState<{ [key: string]: boolean }>(
    {},
  );

  const loadCampaignsInPage = async (page: number) => {
    const newPaginationCampaigns = {
      ...paginationCampaigns,
    };
    const { data } = await getUpComingCampaigns(
      DEFAULT_PAGE_SIZE,
      (page - 1) * DEFAULT_PAGE_SIZE,
    );
    newPaginationCampaigns[page] = data;
    setPaginationCampaigns(newPaginationCampaigns);
    setPage(page);
  };

  const setErrorMessages = (campaignId: string, messages: string[]) => {
    setErrorMessagesDict((prevState) => ({
      ...prevState,
      [campaignId]: messages,
    }));
  };

  const updateChangedDict = (campaignId: string, changed: boolean) => {
    setChangedDict((prevState) => ({
      ...prevState,
      [campaignId]: changed,
    }));
  };

  const handlePropertyChange =
    (id: string, field: string, index?: number) =>
    async (value: string | Date) => {
      const campaign = campaigns.find((item) => item.id === id);
      if (campaign) {
        if (field === 'rewards') {
          if (index !== undefined && campaign.rewards) {
            campaign.rewards[index].rewardName = value as string;
          }
        } else {
          campaign[field] = value;
        }
        updateChangedDict(campaign.id as string, true);
      }
    };

  const deleteReward = async (id: string, index: number) => {
    for (const campaign of campaigns) {
      if (campaign.id === id) {
        campaign.rewards = campaign.rewards?.filter(
          (reward) => reward.orderNumber !== index,
        );
        campaign.rewards?.forEach((reward) => {
          if (reward.orderNumber > index) {
            reward.orderNumber -= 1;
          }
        });
        updateChangedDict(campaign.id as string, true);
      }
    }
  };

  const deleteCampaign = async () => {
    if (deleteCampaignId) {
      await deleteRewardCampaign(deleteCampaignId);
      setDeleteCampaignId(null);

      let loadPage = page;
      const newTotalPage = Math.ceil((props.total - 1) / DEFAULT_PAGE_SIZE);
      if (page > newTotalPage && newTotalPage > 0) {
        loadPage = newTotalPage;
      }
      await loadCampaignsInPage(loadPage);
      await refetchReward();
      showNotificationModal({
        icon: (
          <TrashIcon
            fill={theme.palette.success.main}
            width={'56'}
            height={'56'}
          />
        ),
        open: true,
        title: 'Campaign Deleted',
        description: '',
      });
    }
  };

  const isValidCampaign = (campaign: RewardCampaign) => {
    const { errorFields, errors } = validateRewardCampaign(campaign);

    setErrorFieldsDict((prevState) => ({
      ...prevState,
      [campaign.id as string]: errorFields,
    }));
    setErrorMessages(campaign.id as string, errors);
    return errors.length === 0;
  };

  const saveCampaign = async (id: string) => {
    const campaign = campaigns.find((item) => item.id === id);
    if (campaign && isValidCampaign(campaign)) {
      try {
        await updateRewardCampaign(campaign);
        updateChangedDict(id, false);
        showNotificationModal({
          icon: undefined,
          open: true,
          title: 'Changes saved!',
          description: '',
        });
      } catch (err: unknown) {
        setErrorMessages(id, [
          (axiosRequest.isAxiosError(err) && err.response?.data?.message) ||
            'Something went wrong. Try Again.',
        ]);
      }
    }
  };

  const handleRefreshPage = useCallback(async () => {
    refetchReward();
    await loadCampaignsInPage(page);
  }, [loadCampaignsInPage, page, refetchReward]);

  useImperativeHandle(
    ref,
    () => {
      return {
        refetchCurrentPage: handleRefreshPage,
      };
    },
    [handleRefreshPage],
  );

  return (
    <WrapperBlock title={'Upcoming Campaigns'}>
      {campaigns.map((campaign, index) => {
        return (
          <WrapperCampaign key={index}>
            <CampaignTitle
              title={`Campaign ${campaign.orderNumber}`}
              startAt={new Date(campaign.startedAt as string)}
              endAt={new Date(campaign.endedAt as string)}
              handleDelete={() => setDeleteCampaignId(campaign.id as string)}
            />
            <CampaignForm
              campaign={campaign}
              errorFields={errorFieldsDict[campaign.id as string] || []}
              deleteReward={(index: number) =>
                deleteReward(campaign.id as string, index)
              }
              handleChange={(field, index?: number) =>
                handlePropertyChange(campaign.id as string, field, index)
              }
            />
            {errorMessagesDict[campaign.id as string]
              ? errorMessagesDict[campaign.id as string].map(
                  (message, index) => {
                    return <ErrorBox key={index}>{message}</ErrorBox>;
                  },
                )
              : ''}
            {changedDict[campaign.id as string] ? (
              <Button
                sx={{ display: 'block', marginTop: '4px' }}
                typeStyles={'big'}
                onClick={() => saveCampaign(campaign.id as string)}
              >
                Save
              </Button>
            ) : (
              ''
            )}
          </WrapperCampaign>
        );
      })}
      <CustomPagination
        page={page}
        count={totalPage}
        onChange={(_, page) => loadCampaignsInPage(page)}
      />
      <DeleteModalWindow
        open={!!deleteCampaignId}
        onClose={() => setDeleteCampaignId(null)}
        title={'Are you sure you want to\ndelete this rewards campaign?'}
        onDelete={deleteCampaign}
      />
    </WrapperBlock>
  );
};

export default forwardRef(UpcomingCampaigns);

const ErrorBox = styled(Typography)(() => ({
  color: 'red',
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  margin: theme.spacing(2.5),
  justifyContent: 'center',
  display: 'flex',
}));
