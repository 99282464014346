import React from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';

interface WrapperBlockProps extends BoxProps {
  title: string;
  description?: string;
  errorMessage?: string;
  endDescAdornment?: React.ReactNode;
}

const WrapperBlock = ({
  children,
  title,
  errorMessage,
  description,
  endDescAdornment,
  sx,
  ...rest
}: React.PropsWithChildren<WrapperBlockProps>) => {
  return (
    <Container sx={{ ...sx, rest }}>
      <WrapperTitle>
        <Title>{title}</Title>
        {errorMessage && <ErrorBox>{errorMessage}</ErrorBox>}
        {description && (
          <TitleDescription>
            {description}
            {endDescAdornment ? endDescAdornment : ''}
          </TitleDescription>
        )}
      </WrapperTitle>
      <Content>{children}</Content>
    </Container>
  );
};

export default WrapperBlock;

const Container = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));
const WrapperTitle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

const Title = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  fontSize: theme.typography.h4.fontSize,
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.h3.fontSize,
  },
}));
const TitleDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
}));

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `2px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
  marginTop: 16,
}));

export const InternalIndentBlock = styled(Box)(({ theme }) => ({
  padding: 10,
  [theme.breakpoints.up('sm')]: {
    padding: 20,
  },
}));

const ErrorBox = styled(Typography)(() => ({
  color: 'red',
}));
