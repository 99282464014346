import React, { useContext } from 'react';
import {
  Stack,
  alpha,
  styled,
  IconButton,
  Collapse,
  ListItemButton,
  List,
  Box,
} from '@mui/material';
import { Logo } from '~components/atoms';
import HomeNavbar from './home-navbar';
import useBreakpoints from '~hooks/useBreakpoints';
import Account from '~components/organisms/account';
import { FEATURE_FLAGS_TYPE, useGlobalDispatch } from '~utils';
import ReorderIcon from '@mui/icons-material/Reorder';
import { AuthContext } from '~providers/AuthProvider';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { NAV_ITEMS } from '~components/organisms/header-landing-page/constants';
import { useNavigate } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import { ClickAwayListener } from '@mui/base';
import SearchArtist from '~components/organisms/search-artist';
import { useFeatureFlag } from '~hooks/useFeatureFlag';

const StyledHeader = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(2.5)} ${theme.spacing(3)}`,
  backgroundColor: alpha(theme.palette.common.black, 0.5),
  position: 'sticky',
  zIndex: 10,
  top: 0,
  [theme.breakpoints.up('lg')]: {
    position: 'relative',
  },
}));

const Header = () => {
  const navigate = useNavigate();
  const { isLg } = useBreakpoints();
  const { logout } = useContext(AuthContext);
  const dispatch = useGlobalDispatch();
  const [showMenu, setShowMenu] = React.useState(false);

  const { isEnable } = useFeatureFlag();
  const isEnableSearchArtist = isEnable(
    FEATURE_FLAGS_TYPE.SEARCH_ARTIST_FUNCTIONS,
  );
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const userLogout = async () => {
    await logout();
    dispatch({ type: 'reset' });
  };

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Box>
        <StyledHeader
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Logo />
          {isLg && <HomeNavbar />}
          {isEnableSearchArtist ? (
            isLg ? (
              <SearchArtist key={0} />
            ) : (
              <Stack flex={1} justifyContent="end" direction="row">
                <SearchArtist key={1} />
              </Stack>
            )
          ) : null}
          <Stack flexDirection="row">
            {!isLg && (
              <IconButton onClick={toggleMenu} aria-label="toggle-menu">
                {showMenu ? (
                  <CloseOutlinedIcon
                    sx={{
                      width: 30,
                      height: 30,
                      color: (theme) => theme.palette.common.white,
                    }}
                  />
                ) : (
                  <ReorderIcon
                    sx={{
                      width: 30,
                      height: 30,
                      color: (theme) => theme.palette.common.white,
                    }}
                  />
                )}
              </IconButton>
            )}
            <Account isArtistPage={false} onLogout={userLogout} />
          </Stack>
        </StyledHeader>
        {!isLg && (
          <StyledCollapseContainer sx={{ position: 'relative' }}>
            <StyledCollapse in={showMenu}>
              <StyledList disablePadding>
                {NAV_ITEMS.map(({ name, route }) => (
                  <ListItemButtonStyled
                    key={name}
                    onClick={() => navigate(route)}
                    disabled={route === location.pathname}
                  >
                    <Stack>
                      <TextItem
                        selected={route === location.pathname}
                        primary={name}
                      />
                    </Stack>
                  </ListItemButtonStyled>
                ))}
              </StyledList>
            </StyledCollapse>
          </StyledCollapseContainer>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default Header;
const StyledCollapseContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.light,
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.primary.light,
  top: 0,
  left: 0,
  right: 0,
  zIndex: 11,
}));

const StyledList = styled(List)(() => ({}));

const TextItem = styled(ListItemText)<{ selected: boolean }>(
  ({ selected, theme }) => ({
    '& .MuiTypography-root': {
      fontWeight: 900,
      color: selected
        ? theme.palette.text.primary
        : theme.palette.text.disabled,
    },
    textTransform: 'uppercase',
    position: 'relative',
    margin: 0,
    ':before': {
      content: selected ? "''" : null,
      height: 4,
      width: '100%',
      position: 'absolute',
      bottom: -6,
      left: 0,
      backgroundColor: theme.palette.action.active,
    },
  }),
);

const ListItemButtonStyled = styled(ListItemButton)(() => ({
  '&.Mui-disabled': {
    opacity: 1,
  },
}));
