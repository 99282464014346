// @mui
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack, { StackProps } from '@mui/material/Stack';

// ----------------------------------------------------------------------

export function ArtistFollowingItemSkeleton({ sx, ...other }: StackProps) {
  return (
    <Stack
      direction="row"
      alignItems={'center'}
      sx={{
        background: '#151515',
        height: 100,
        ...sx,
      }}
      {...other}
    >
      <Box>
        <Skeleton variant="rectangular" width={100} height={100} />
      </Box>
      <Stack
        width={1}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        spacing={{ md: 2 }}
        justifyContent={'space-between'}
        px={2}
        py={{ xs: 1, md: 0 }}
      >
        <Stack spacing={0.5}>
          <Skeleton width={180} height={20} />
          <Skeleton
            sx={{
              width: { xs: 0, md: 120 },
              height: { xs: 0, md: 12 },
            }}
          />
        </Stack>
        <Stack spacing={2}>
          <Skeleton width={180} height={24} />
        </Stack>
        <Stack
          direction="row"
          spacing={1.5}
          justifyContent={'space-between'}
          width={{ xs: '1', md: 'unset' }}
        >
          <Skeleton
            variant="rectangular"
            width={156}
            height={36}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton variant="circular" width={36} height={36} />
        </Stack>
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------
