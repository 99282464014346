import React from 'react';
import { Title } from '~components/pages/about/title';
import { Stack, styled, Box } from '@mui/material';
import { Description } from './description';
import Monitor from '~images/decorations-landing-page/monitor.png';

const DESCRIPTION_1 =
  'Ursa Live’s Fan Spotlight feature allows artists to see their fans during their livecast, while their fans can see each other as well!';

const DESCRIPTION_2 =
  'Artists can also pull fans “onstage” to say hi or answer a question.';

const Community = () => {
  return (
    <Container>
      <TextWrapper>
        <Title sx={{ textAlign: { md: 'left' } }}>
          Building A Stronger Sense Of Community
        </Title>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Description sx={{ textAlign: { md: 'left' } }}>
            {DESCRIPTION_1}
          </Description>
          <Description sx={{ textAlign: { md: 'left' } }}>
            {DESCRIPTION_2}
          </Description>
        </Box>
      </TextWrapper>
      <MonitorImage
        src={Monitor}
        alt="sphere"
        sx={{ width: { xs: '100%', md: '50%' } }}
      />
      <Box sx={{ display: { md: 'none' } }}>
        <Description>{DESCRIPTION_1}</Description>
        <Description>{DESCRIPTION_2}</Description>
      </Box>
    </Container>
  );
};

export default Community;

const Container = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 20px',
  gap: 20,
  background: theme.palette.grey[900],
  [theme.breakpoints.up('md')]: {
    padding: '100px 40px 80px',
    gap: 40,
    flexDirection: 'row-reverse',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '100px 80px 80px',
  },
}));

const TextWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    width: '40%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '30%',
  },
}));

const MonitorImage = styled('img')(({ theme }) => ({
  maxWidth: 370,
  width: '100%',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: 770,
  },
}));
