import {
  useState,
  useEffect,
  ReactNode,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useFormContext } from 'react-hook-form';
import { CustomRadioButton, DialogConfirm } from '~components/molecules';
import { showErrorToaster } from '~utils/toasterNotification';
import { updateStripeCustomerId } from '~components/action';
import {
  createCustomer,
  detachPaymentMethod,
  getPaymentMethods,
} from '~api/payment';
import {
  useGlobalDispatch,
  useGlobalState,
  validObjectWithParameterKeys,
} from '~utils/index';
import { PaymentMethodEnum } from '~types';
import { PaymentMethodListResponse, PaymentCard } from '~types/payment';
import {
  AmericanExpressIcon,
  DinersClubCardIcon,
  DiscoverCardIcon,
  JBCCardIcon,
  MasterCardIcon,
  VisaCardIcon,
  UnionPayCardIcon,
} from '~components/icons';
import PaymentCardForm from './payment-card-form';

const PaymentMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  minHeight: 62,
  padding: `${theme.spacing(1.5)}  ${theme.spacing(2.5)}`,

  '&.MuiButtonBase-root': {
    '&.MuiMenuItem-root.Mui-selected': {
      backgroundColor: alpha(theme.palette.common.white, 0.11),
    },
  },
}));

const IconWrapper = styled(Stack)(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  '& svg': {
    width: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(7),
    },
  },
}));

const RotateArrowIcon = ({ rotate }: { rotate: boolean }) => (
  <KeyboardArrowDownIcon
    sx={{
      color: 'white',
      transition: 'transform 0.2s',
      transform: `rotate(${rotate ? 180 : 0}deg)`,
    }}
  />
);

const paymentMethodSx = (active: boolean): SxProps<Theme> => ({
  borderRadius: (theme) => theme.spacing(1),
  border: (theme) =>
    `1px solid ${
      active ? alpha(theme.palette.common.white, 0.1) : 'transparent'
    }`,
  backgroundColor: (theme) =>
    alpha(theme.palette.primary.dark, active ? 1 : 0.58),
  minHeight: {
    xs: 'auto',
    md: 78,
  },
});

type Props = {
  currentMethod: string | null;
  onMethodChange: (value: string) => void;
  latestCardId?: string;
};

const PaymentMethod = ({
  currentMethod,
  onMethodChange,
  latestCardId,
}: Props) => {
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const [isInitializing, setIsInitializing] = useState(false);
  const { user } = globalState as Record<string, any>;
  const { customer_id, email }: { customer_id: string; email: string } = user;
  const [toggleCardForm, setToggleCardForm] = useState(false);
  const [cardOptions, setCardOptions] = useState<PaymentCard[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const anchorElOffsetWidth = useRef<null | number>(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteCard, setDeleteCard] = useState<PaymentCard | null>(null);
  const { setValue } = useFormContext();

  const cardOptionsLength = cardOptions.length;
  const isEmptyCards = cardOptionsLength === 0;
  const isCardMethod =
    currentMethod !== null && currentMethod === PaymentMethodEnum.card;
  const menuOpen = Boolean(anchorEl);

  const currentCard = !isEmptyCards ? cardOptions[selectedIndex] : null;

  const PAYMENT_ICONS: Record<string, { icon: ReactNode; label: string }> =
    useMemo(
      () => ({
        visa: {
          label: 'Visa',
          icon: <VisaCardIcon />,
        },
        mastercard: {
          label: 'Mastercard',
          icon: <MasterCardIcon />,
        },
        amex: {
          label: 'American Express',
          icon: <AmericanExpressIcon width={32} />,
        },
        discover: {
          label: 'Discover Card',
          icon: <DiscoverCardIcon size="1x" />,
        },
        diners: {
          label: 'Diners Club International',
          icon: <DinersClubCardIcon size="1x" />,
        },
        jcb: {
          label: 'Japan Credit Bureau',
          icon: <JBCCardIcon size="1x" />,
        },
        unionpay: {
          label: 'UnionPay',
          icon: <UnionPayCardIcon width={32} />,
        },
        unknown: {
          label: 'Other',
          icon: <VisaCardIcon />,
        },
      }),
      [],
    );

  const getCardValue = useCallback(() => {
    let last4 = 'Credit card';
    let expireDate = '';
    let icons = PAYMENT_ICONS['mastercard'].icon;

    if (currentCard) {
      last4 = `${currentCard?.card?.brand ?? ''} **** ${
        currentCard?.card?.last4 ?? ''
      }`;
      expireDate = `Expires on ${currentCard?.card?.expMonth ?? ''}/${
        currentCard?.card?.expYear ?? ''
      }`;
      icons =
        PAYMENT_ICONS[currentCard.card.brand].icon ||
        PAYMENT_ICONS['visa'].icon;
    }

    if (toggleCardForm) {
      last4 = 'New credit card';
      expireDate = '';
      icons = (
        <>
          {PAYMENT_ICONS['mastercard'].icon}
          {PAYMENT_ICONS['visa'].icon}
          <AmericanExpressIcon />
        </>
      );
    }

    return {
      last4,
      expireDate,
      icons,
    };
  }, [currentCard, toggleCardForm]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorElOffsetWidth.current === null) {
      anchorElOffsetWidth.current = event.currentTarget.offsetWidth;
    }
    setAnchorEl(event.currentTarget);

    onMethodChange(PaymentMethodEnum.card);
  };

  const handleClickMenuItem = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setAnchorEl(null);
    setSelectedIndex(index);
    setToggleCardForm(false);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddCard = useCallback(() => {
    handleClose();
    setValue('selectedCard', null);
    setSelectedIndex(-1);
    setToggleCardForm(true);
  }, [setValue]);

  const handleRemoveCard = async (option: PaymentCard) => {
    if (!validObjectWithParameterKeys(option, ['id'])) return;
    try {
      const response = await detachPaymentMethod(option.id);
      if (response.status === 200) {
        const nextCardOptions = cardOptions.filter(
          ({ id }) => id !== option.id,
        );
        setCardOptions(nextCardOptions);
        // Don't have any method available
        if (nextCardOptions.length === 0) {
          handleAddCard();
          return;
        }
        setValue('selectedCard', null);
        setSelectedIndex(0);
        setDeleteCard(null);
      }
    } catch (error) {
      showErrorToaster(error);
      setDeleteCard(null);
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleConfirmRemoveCard = () => {
    handleRemoveCard(deleteCard as PaymentCard);
    handleCloseConfirm();
  };

  const handleCancelRemoveCard = () => {
    handleCloseConfirm();
    setDeleteCard(null);
  };

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        if (!customer_id) {
          const res = await createCustomer(email);
          const { customerId } = res.data;
          dispatch({ type: 'user', payload: { customer_id: customerId } });
          updateStripeCustomerId(customerId);
          return;
        }

        const { methods }: PaymentMethodListResponse = await getPaymentMethods(
          customer_id,
          100,
        );
        setCardOptions(methods);
        if (methods?.length) {
          const index = methods.findIndex((item) => item.id === latestCardId);
          setToggleCardForm(!(index > -1));
          setSelectedIndex(index);
        }
        setIsInitializing(true);
      } catch (error) {
        showErrorToaster(error);
      }
    };

    fetchPaymentMethods();
  }, [customer_id, dispatch, email, isCardMethod, latestCardId]);

  useEffect(() => {
    if (isInitializing) {
      if (!isCardMethod) {
        setToggleCardForm(false);
        setSelectedIndex(-1);
      } else {
        if (!cardOptions?.length) {
          handleAddCard();
        }
      }
    }
  }, [cardOptions, handleAddCard, isCardMethod, isInitializing]);

  useEffect(() => {
    if (!isEmptyCards && isCardMethod && selectedIndex !== -1) {
      setValue('selectedCard', cardOptions[selectedIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardOptions, isEmptyCards, selectedIndex, isCardMethod]);

  const { icons, last4, expireDate } = getCardValue();

  return (
    <>
      <Box>
        <List
          disablePadding
          sx={{
            ...paymentMethodSx(currentMethod === PaymentMethodEnum.card),
            ...(toggleCardForm && {
              borderBottom: 0,
              borderColor: (theme) => alpha(theme.palette.common.white, 0.1),
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }),
          }}
        >
          <ListItem
            id="card-button"
            aria-haspopup="listbox"
            aria-expanded={menuOpen ? 'true' : undefined}
            onClick={handleClickListItem}
            sx={{
              minHeight: {
                xs: 'auto',
                md: 78,
              },
            }}
            secondaryAction={
              <RotateArrowIcon rotate={toggleCardForm || menuOpen} />
            }
          >
            <ListItemIcon sx={{ pr: 1.5 }}>
              <CustomRadioButton
                name="payment-method"
                value={PaymentMethodEnum.card}
                checked={currentMethod === PaymentMethodEnum.card}
                onChange={() => {
                  onMethodChange(PaymentMethodEnum.card);
                }}
              />
              <IconWrapper direction={'row'} spacing={1.5}>
                {icons}
              </IconWrapper>
            </ListItemIcon>
            <ListItemText
              primary={last4}
              secondary={expireDate}
              primaryTypographyProps={{
                fontWeight: '500',
              }}
              secondaryTypographyProps={{
                color: (theme) => alpha(theme.palette.common.white, 0.3),
                variant: 'caption',
                fontSize: {
                  lg: 12,
                },
              }}
            />
          </ListItem>
        </List>
        <Collapse in={toggleCardForm}>
          <PaymentCardForm />
        </Collapse>
      </Box>
      {!!cardOptions?.length && (
        <Menu
          id="card-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'card-button',
            role: 'listbox',
            sx: {
              backgroundColor: (theme) => theme.palette.primary.dark,
            },
          }}
          PaperProps={{
            sx: {
              mt: '5px',
              width: anchorElOffsetWidth.current ?? 120,
              maxWidth: anchorElOffsetWidth.current ?? 120,
            },
          }}
        >
          {cardOptions.map((option, index) => {
            const selected = index === selectedIndex;
            return (
              <PaymentMenuItem
                key={option.id}
                selected={selected}
                onClick={(event) => handleClickMenuItem(event, index)}
              >
                <Box>{PAYMENT_ICONS[option.card.brand].icon}</Box>
                <Stack
                  ml={1.5}
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  flex={1}
                >
                  <Box>
                    <Typography fontWeight={'500'}>
                      {`${option.card.brand} **** ${option.card.last4}`}
                    </Typography>
                    <Typography
                      variant="caption"
                      fontSize={12}
                      color={(theme) => alpha(theme.palette.common.white, 0.3)}
                    >
                      {`Expires on ${option.card.expMonth}/${option.card.expYear}`}
                    </Typography>
                  </Box>

                  <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    {selected && (
                      <CheckCircleIcon
                        sx={{ color: (theme) => theme.palette.warning.main }}
                      />
                    )}
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteCard(option);
                        setOpenConfirm(true);
                      }}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </PaymentMenuItem>
            );
          })}
          <PaymentMenuItem onClick={handleAddCard}>
            <Typography
              variant="caption"
              textAlign={'center'}
              width={'100%'}
              fontSize={12}
              fontWeight={'500'}
              lineHeight={'14px'}
              sx={{ verticalAlign: 'middle' }}
            >
              <AddIcon sx={{ fontSize: 20, mt: '-2px' }} />
              {'Add Credit Card'}
            </Typography>
          </PaymentMenuItem>
        </Menu>
      )}
      <DialogConfirm
        open={openConfirm}
        title="Confirmation"
        message="Are you sure you want to delete the credit card?"
        onClose={handleCloseConfirm}
        onCancel={handleCancelRemoveCard}
        onConfirm={handleConfirmRemoveCard}
      />
    </>
  );
};

export default PaymentMethod;
