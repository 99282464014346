import * as yup from 'yup';
import {
  emailSchema,
  usernameSchema,
  phoneHandleSchema,
} from './validation-schemes';

export const PayoutMethods = {
  STRIPE: 'STRIPE',
  PAYPAL: 'PAYPAL',
  VENMO: 'VENMO',
  NONE: false,
} as const;

export type UnionPayoutMethods =
  (typeof PayoutMethods)[keyof typeof PayoutMethods];

export interface BankAccountFormData {
  firstName: string;
  lastName: string;
  routingNumber: string;
  accountNumber: string;
}

export interface BankAccountField {
  type: keyof BankAccountFormData;
  label: string;
  placeholder: string;
}

export enum PaypalVenmoType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  USERNAME = 'USER_HANDLE',
}

export type UnionPaypalVenmoType =
  (typeof PaypalVenmoType)[keyof typeof PaypalVenmoType];

export type PayPalVenmoFormData = yup.InferType<
  typeof emailSchema & typeof usernameSchema & typeof phoneHandleSchema
>;
