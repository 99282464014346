import * as React from 'react';
import { SVGProps } from 'react';

const InstagramSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="16.5" cy="16" r="16" fill="url(#paint0_linear_5508_1127)" />
    <path
      d="M20.811 5.33301H12.1883C8.68399 5.33301 5.83301 8.18399 5.83301 11.6883V20.311C5.83301 23.8154 8.68399 26.6663 12.1883 26.6663H20.811C24.3154 26.6663 27.1663 23.8154 27.1663 20.311V11.6883C27.1663 8.18399 24.3153 5.33301 20.811 5.33301ZM25.0202 20.311C25.0202 22.6357 23.1357 24.5202 20.811 24.5202H12.1883C9.86368 24.5202 7.97914 22.6357 7.97914 20.311V11.6883C7.97914 9.36364 9.86368 7.47914 12.1883 7.47914H20.811C23.1357 7.47914 25.0202 9.36364 25.0202 11.6883V20.311Z"
      fill="white"
    />
    <path
      d="M16.5 10.4824C13.4576 10.4824 10.9824 12.9576 10.9824 15.9999C10.9824 19.0423 13.4576 21.5175 16.5 21.5175C19.5424 21.5175 22.0175 19.0423 22.0175 15.9999C22.0175 12.9576 19.5424 10.4824 16.5 10.4824ZM16.5 19.3714C14.638 19.3714 13.1286 17.862 13.1286 16C13.1286 14.138 14.638 12.6286 16.5 12.6286C18.362 12.6286 19.8714 14.138 19.8714 16C19.8714 17.8619 18.3619 19.3714 16.5 19.3714Z"
      fill="white"
    />
    <path
      d="M22.0282 11.8454C22.7584 11.8454 23.3503 11.2535 23.3503 10.5233C23.3503 9.79311 22.7584 9.20117 22.0282 9.20117C21.298 9.20117 20.7061 9.79311 20.7061 10.5233C20.7061 11.2535 21.298 11.8454 22.0282 11.8454Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5508_1127"
        x1="22.5"
        y1="29.6667"
        x2="6.16667"
        y2="2.33333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E09B3D" />
        <stop offset="0.3" stopColor="#C74C4D" />
        <stop offset="0.6" stopColor="#C21975" />
        <stop offset="1" stopColor="#7024C4" />
      </linearGradient>
    </defs>
  </svg>
);

export default InstagramSVG;
