import { ReactNode } from 'react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  alpha,
  styled,
} from '@mui/material';

type AccountMenuItemProps = MenuItemProps & {
  icon?: ReactNode;
  text: string | ReactNode;
  hidden?: boolean;
};

const StyledMenuItem = styled(MenuItem)(() => ({
  paddingBottom: 4,
  paddingTop: 4,
  '& svg': {
    height: 21,
    width: 21,
  },
  '&.MuiMenuItem-root': {
    minHeight: 'auto',
  },
  '& .MuiListItemIcon-root': {
    minWidth: 24,
  },
}));

const AccountMenuItem = ({
  hidden,
  icon,
  text,
  ...rest
}: AccountMenuItemProps) => {
  if (hidden) return null;
  return (
    <StyledMenuItem disableGutters {...rest}>
      <ListItemIcon
        sx={{
          color: (theme) => alpha(theme.palette.common.white, 0.7),
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          textTransform: 'capitalize',
          fontWeight: '500',
          fontSize: {
            xs: 16,
          },
          lineHeight: '14px',
          ml: 1,
          sx: {
            cursor: 'pointer',
          },
        }}
      >
        {text}
      </ListItemText>
    </StyledMenuItem>
  );
};

export default AccountMenuItem;
