import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';
//
import { joinEvent } from '~api/event';
//
import { RoutesPages } from '../router/types';

const ATTENDEE_TOKEN = 'attendee_token';

export const inMemoryAttendeeManager = () => {
  // page reload, attendeeToken in memory lost, get it from localstorage
  let attendeeToken: string | null = localStorage.getItem(ATTENDEE_TOKEN) ?? '';

  const getToken = () => attendeeToken;

  const setToken = (token: string) => {
    attendeeToken = token;
    localStorage.setItem(ATTENDEE_TOKEN, token);
  };

  const eraseToken = () => {
    attendeeToken = null;
    localStorage.removeItem(ATTENDEE_TOKEN);
  };

  // The method makes a call to the events-join endpoint
  // If there is no token exist in localStorage, add new one and make a call
  // the endpoint will return success with status 200.
  // If the call not work eraseToken()
  const storeAttendeeToken = (
    eventId: string,
    socketSessionId: string,
    isRecording?: boolean,
  ) => {
    // attendeeToken doesn't exist in localStorage, generate new one
    if (!attendeeToken) {
      setToken(uuidv4());
    }

    if (attendeeToken) {
      joinEvent(eventId, socketSessionId, attendeeToken, isRecording)
        .then((response) => response)
        .catch((error: AxiosError<{ code: number; message: string }>) => {
          eraseToken();
          if (error.response?.data?.code === 400) {
            // handle member got blocked by artist
            window.location.href = RoutesPages.ACCESS_DENIED;
          }
        });
    }
  };

  return {
    storeAttendeeToken,
    getToken,
    setToken,
  };
};

export const inMemoryAttendeeTokenService = inMemoryAttendeeManager();
