import { Link } from '@mui/material';
import Linkify from 'linkify-react';

interface LinkifyTextProps {
  children?: React.ReactNode;
  options?: import('linkifyjs').Opts;
  as?: any;
}

function LinkifyText({ children, options, ...rest }: LinkifyTextProps) {
  return (
    <Linkify
      options={{
        rel: 'nofollow',
        target: '_blank',
        render: {
          url: ({ attributes, content }) => {
            return (
              <Link {...attributes} color={'info.main'}>
                {content}
              </Link>
            );
          },
        },
        ...options,
      }}
      {...rest}
    >
      {children}
    </Linkify>
  );
}

export default LinkifyText;
