import axios from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';

const initialConfig: IAxiosRetryConfig = {
  retries: 3,
  onRetry: () => null,
  retryDelay: () => 0,
  retryCondition: () => false,
  shouldResetTimeout: false,
};

axiosRetry(axios, initialConfig);

export const axiosRequest = axios;
