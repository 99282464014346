import * as React from 'react';
import { SVGProps } from 'react';

const BookingScheduleSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.925 16.5C11.2417 16.5 10.6667 16.2667 10.2 15.8C9.73333 15.3333 9.5 14.7583 9.5 14.075C9.5 13.3917 9.73333 12.8167 10.2 12.35C10.6667 11.8833 11.2417 11.65 11.925 11.65C12.6083 11.65 13.1833 11.8833 13.65 12.35C14.1167 12.8167 14.35 13.3917 14.35 14.075C14.35 14.7583 14.1167 15.3333 13.65 15.8C13.1833 16.2667 12.6083 16.5 11.925 16.5V16.5ZM1.5 20C1.1 20 0.75 19.85 0.45 19.55C0.15 19.25 0 18.9 0 18.5V3C0 2.6 0.15 2.25 0.45 1.95C0.75 1.65 1.1 1.5 1.5 1.5H3.125V0H4.75V1.5H13.25V0H14.875V1.5H16.5C16.9 1.5 17.25 1.65 17.55 1.95C17.85 2.25 18 2.6 18 3V18.5C18 18.9 17.85 19.25 17.55 19.55C17.25 19.85 16.9 20 16.5 20H1.5ZM1.5 18.5H16.5V7.75H1.5V18.5Z"
      fill="white"
    />
  </svg>
);

export default BookingScheduleSVG;
