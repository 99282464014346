import { Button, ButtonProps, Stack, Typography } from '@mui/material';
import { PaymentReceipt } from '~components/molecules';

type Props = {
  title: string;
  data: {
    name: string;
    date: string;
    item: string;
    price: number;
    tax: number;
    fee: number;
    total: string;
  };
  btnText: string;
  onClick: () => void;
  btnProps?: ButtonProps;
};

const PaymentReview = ({ title, data, btnText, onClick, btnProps }: Props) => {
  return (
    <>
      <Typography
        fontSize={{
          xs: 20,
          lg: 24,
        }}
        fontWeight={700}
        textTransform={'uppercase'}
      >
        {title}
      </Typography>
      <Stack spacing={{ xs: 2, sm: 4 }} mt={4}>
        <PaymentReceipt
          title={'RECEIPT'}
          data={data}
          titleProps={{
            sx: { bgcolor: '#4F5567' },
          }}
          sx={{
            backgroundColor: '#0E1016',
          }}
        />
        <Button
          size="large"
          variant="contained"
          color="warning"
          fullWidth
          sx={{ height: '100%', fontSize: 14 }}
          onClick={onClick}
          {...btnProps}
        >
          {btnText}
        </Button>
      </Stack>
    </>
  );
};

export default PaymentReview;
