import React from 'react';
import { Box, Stack, styled, TypographyProps } from '@mui/material';
import AudioVideoDashboard from '~images/decorations-landing-page/audio-video-dashboard.png';
import { Title } from './title';
import { Description } from './description';
import DarkSphere from '~images/decorations-landing-page/dark-sphere.png';

const Help = () => {
  return (
    <Container>
      <Sphere src={DarkSphere} alt="sphere" />
      <WrapperText>
        <Title sx={{ textAlign: { md: 'left' } }}>
          NEED HELP WITH YOUR AUDIO/VIDEO SETUP?
        </Title>
        <LocalDescription
          sx={{
            display: { xs: 'none', md: 'block' },
            textAlign: { md: 'left' },
          }}
        />
      </WrapperText>
      <PictureBg>
        <img src={AudioVideoDashboard} alt="Dashboard" />
      </PictureBg>
      <LocalDescription sx={{ display: { md: 'none' } }} />
    </Container>
  );
};

const LocalDescription = (props: TypographyProps) => (
  <Description sx={{ zIndex: 2 }} {...props}>
    No problem. We can help! Book a one-on-one with an Ursa Live producer to get
    it all sorted out.
  </Description>
);

export default Help;

const Container = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  padding: '35px 20px 40px',
  gap: 20,
  background: '#151820',
  [theme.breakpoints.up('md')]: {
    padding: '60px 20px 0',
    gap: 24,
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '60px 80px 0',
  },
}));

const WrapperText = styled(Box)(({ theme }) => ({
  zIndex: 2,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '50%',
    gap: 24,
  },
  [theme.breakpoints.up('xl')]: {
    width: '40%',
  },
}));

const PictureBg = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  zIndex: 2,
  backgroundColor: theme.palette.common.black,
  padding: '15px 22px',
  width: 'fit-content',
  margin: '0 auto',
  img: {
    height: 285,
  },
  [theme.breakpoints.up('md')]: {
    padding: '50px 50px 0',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    img: {
      height: 450,
    },
  },
  [theme.breakpoints.up('lg')]: {
    padding: '73px 100px 0',
  },
}));

const Sphere = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
  display: 'none',
  transform: 'rotate(180deg)',
  zIndex: 1,
  opacity: 0.3,
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));
