import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';

import BasePopup from '../base-popup';
import { Genre } from '~types/artist';
import { getActiveGenres } from '~api/artist';
import { Select } from '~components/atoms';
import { updateMusicGenre } from '~api/user';

const SelectGenre = styled(Select)(({ theme }) => ({
  '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.action.active}`,
  },
}));

const StyledGenreSelectModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],

  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  },
  [theme.breakpoints.up('sm')]: {
    width: 638,
  },
}));

const DEFAULT_GENRE_SELECTION = {
  value: 'none',
  label: 'Choose Your Music Genre',
};

const SelectGenreModal = () => {
  const [genre, setGenre] = useState<any>();
  const [error, setError] = useState<string>('');
  const [selected, setSelected] = useState<any>('none');
  const [activeGenres, setActiveGenres] = useState<Genre[]>([]);
  const { showUploadAvatarPopup } = useDispatchPopup();
  const genreSelectList = useMemo(() => {
    const list = activeGenres.map((item) => {
      return { value: item.id, label: item.title };
    });
    list.unshift(DEFAULT_GENRE_SELECTION);
    return list;
  }, [activeGenres]);

  const onChangeGenre = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value;
    const genre = activeGenres.find((item) => item.id === value);
    setGenre(genre ?? null);
    setSelected(value);
  };

  const fetchData = async () => {
    const { genres } = await getActiveGenres();
    setActiveGenres(genres);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = () => {
    updateMusicGenre(genre.id)
      .then((resp) => {
        if (resp && resp.status === 200) {
          showUploadAvatarPopup();
        }
      })
      .catch(() => {
        setError('Cannot update genre, please try again!!!');
      });
  };

  return (
    <StyledGenreSelectModal hideCloseButton={true} open={true}>
      <Stack spacing={3} alignItems={'center'}>
        <Typography
          textTransform={'uppercase'}
          mb={3}
          fontWeight={'700'}
          fontSize={{ xs: 20 }}
        >
          CHOOSE YOUR MUSIC GENRE
        </Typography>
        <Box sx={{ width: '100%' }}>
          <SelectGenre
            fullWidth
            value={selected}
            items={genreSelectList}
            onChange={onChangeGenre}
          />
        </Box>
        {error && <Typography color="error">{error}</Typography>}
        <Stack sx={{ width: '50%' }} direction={'row'} spacing={3}>
          <Button
            fullWidth
            type="submit"
            color="warning"
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            onClick={onSubmit}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </StyledGenreSelectModal>
  );
};

export default SelectGenreModal;
