import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, select } from '~stores';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  createPaymentIntent,
  handlePostPayment,
} from '~utils/newStripePayment';
import { PaymentType, PaymentMethod } from '~types';
import _ from 'lodash';
import { calculateTax } from '~api/payment';

const useStripePayment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const selectedLiveCoin = useSelector((state: RootState) =>
    select.payment.getSelectedLiveCoin(state),
  );
  const { user = {} } = globalState;
  const [isLoading, setIsLoading] = useState(true);
  const [isShowApplePay, setIsShowApplePay] = useState(false);
  const [isShowGooglePay, setIsShowGooglePay] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [paymentRequest, setPaymentRequest] = useState<any>(undefined);
  const paymentMethod = useRef<PaymentMethod | null>(null);

  useEffect(() => {
    if (!selectedLiveCoin || !stripe || !elements) {
      return;
    }
    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'payment',
        amount: selectedLiveCoin?.[PaymentType.STRIPE].computedAmount || 0,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestShipping: true,
    });

    // Check the availability of the Payment Request API.
    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(paymentRequest);
        setIsShowApplePay(result.applePay);
        setIsShowGooglePay(result.googlePay);
      }
      setIsLoading(false);
    });

    paymentRequest.on('paymentmethod', async (event) => {
      if (!paymentMethod.current) {
        return;
      }
      let clientSecret = '';
      const address = event.shippingAddress;
      const line1 = address?.addressLine ? address.addressLine[0] : '';

      const taxAmount = await calculateTax({
        line1: line1,
        city: address?.city,
        state: address?.region,
        postalCode: address?.postalCode,
        country: address?.country,
        amount: selectedLiveCoin?.[PaymentType.STRIPE].computedAmount || 0,
      }).then((resp) => resp.data.taxAmount);
      try {
        const res = await createPaymentIntent(
          selectedLiveCoin?.stripeProductId,
          taxAmount,
          {
            line1: line1,
            city: address?.city,
            state: address?.region,
            postal_code: address?.postalCode,
            country: address?.country,
          },
        );
        clientSecret = res.clientSecret;
      } catch (err) {
        setErrorMessage('Something went wrong. Try Again.');
        return;
      }

      const { error: confirmError, paymentIntent } =
        await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: event.paymentMethod.id,
          },
          { handleActions: false },
        );

      if (confirmError) {
        event.complete('fail');
        setErrorMessage(confirmError.message);
        return;
      }

      if (paymentIntent?.status === 'requires_action') {
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          setErrorMessage(error.message);
        } else {
          await handlePostPayment(
            paymentIntent,
            selectedLiveCoin,
            dispatch,
            user,
            paymentMethod.current,
            undefined,
            (taxAmount / 100).toFixed(2),
          );
        }
      } else {
        await handlePostPayment(
          paymentIntent,
          selectedLiveCoin,
          dispatch,
          user,
          paymentMethod.current,
          undefined,
          (taxAmount / 100).toFixed(2),
        );
      }
      event.complete('success');
    });

    paymentRequest.on('shippingaddresschange', async (event: any) => {
      if (!paymentMethod.current) {
        return;
      }

      const updateWith = event.updateWith;
      const address = event.shippingAddress;
      // // handle shippingaddresschange event
      const subTotal =
        selectedLiveCoin?.[PaymentType.STRIPE].computedAmount || 0;
      const taxAmount = await calculateTax({
        line1: '',
        city: address?.city,
        state: address?.region,
        postalCode: address?.postalCode,
        country: address?.country,
        amount: subTotal,
      }).then((resp) => resp.data.taxAmount);
      // // call event.updateWith within 30 seconds
      const updateDetails = {
        total: {
          label: 'Demo total',
          amount: taxAmount + subTotal,
        },
        displayItems: [
          {
            label: 'price',
            amount: subTotal,
          },
          {
            label: 'tax',
            amount: taxAmount,
          },
        ],
        shippingOptions: [
          {
            id: _.uniqueId(),
            label: 'Shipping method',
            detail: 'None',
            amount: 0,
          },
        ],
        status: 'success',
      };

      updateWith(updateDetails);
    });
  }, [
    dispatch,
    elements,
    globalState,
    selectedLiveCoin,
    setPaymentRequest,
    stripe,
    user,
  ]);

  const showPaymentRequest = useCallback(
    (payment: PaymentMethod) => {
      paymentMethod.current = payment;
      paymentRequest.show();
    },
    [paymentRequest],
  );

  return {
    isLoading,
    isShowApplePay,
    isShowGooglePay,
    paymentRequest,
    errorMessage,
    showPaymentRequest,
  };
};
export default useStripePayment;
