import React from 'react';
import { Box, Stack, styled } from '@mui/material';
import { Title } from './components';
import { Button } from '~components/atoms';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';

interface PhoneNumberInfoProps {
  phone: string | null;
}

const PhoneNumberInfo = (props: PhoneNumberInfoProps) => {
  const { phone } = props;
  const { showUpdatePhoneNumberPopup } = useDispatchPopup();

  const handleClickUpdatePhoneNumber = () => {
    showUpdatePhoneNumberPopup(true);
  };
  return (
    <Box>
      <Wrapper>
        <Stack direction={'row'} alignItems={'center '} spacing={2}>
          <Title>Phone Number</Title>
          {phone ? (
            <>
              <Title>{phone}</Title>
              <UpdatePictureBtn
                width={60}
                onClick={handleClickUpdatePhoneNumber}
              >
                Edit
              </UpdatePictureBtn>
            </>
          ) : (
            <UpdatePictureBtn onClick={handleClickUpdatePhoneNumber}>
              Add Phone Number
            </UpdatePictureBtn>
          )}
        </Stack>
      </Wrapper>
    </Box>
  );
};

export default PhoneNumberInfo;

const UpdatePictureBtn = styled(Button)(({ theme }) => ({
  margin: '0 auto',
  fontSize: theme.typography.fontSize,
  padding: '7px 30px',
  color: theme.palette.text.secondary,
  backgroundColor: '#ffffff',

  [theme.breakpoints.up('xs')]: {
    width: '32px',
  },

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  border: `2px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
  marginTop: 16,
  padding: '20px',
}));
