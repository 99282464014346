import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react';
import PaypalVenmoForm, { PaypalVenmoFormRefProps } from './paypal-venmo-form';
import { PayoutInfoResponse } from '~types/payment';
import { PayoutMethods } from './types';
import { ModalWindowTypes } from '~components/manage-profile/tabs/payout/payout-method/index';

interface Props {
  data: PayoutInfoResponse['metadata']['venmo'] | undefined;
  openModalWindow: (type: ModalWindowTypes) => void;
  changePayoutId: (id: string | null) => void;
  changeStatusIsDataSaved: (status: boolean) => void;
  hideSaveButton?: boolean;
  onNext?: () => void;
}

export type VenmoRefProps = {
  save: () => void;
};
const Venmo: ForwardRefRenderFunction<VenmoRefProps, Props> = (
  {
    data,
    openModalWindow,
    changePayoutId,
    changeStatusIsDataSaved,
    hideSaveButton = false,
    onNext,
  },
  ref,
) => {
  const paypalVenmoFormRef = useRef<PaypalVenmoFormRefProps>(null);
  useImperativeHandle(
    ref,
    () => {
      return {
        save: () =>
          paypalVenmoFormRef?.current?.save &&
          paypalVenmoFormRef.current.save(),
      };
    },
    [],
  );
  return (
    <PaypalVenmoForm
      ref={paypalVenmoFormRef}
      email={data?.email}
      phone={data?.phone}
      username={data?.userHandle}
      methodType={PayoutMethods.VENMO}
      openModalWindow={openModalWindow}
      changePayoutId={changePayoutId}
      changeStatusIsDataSaved={changeStatusIsDataSaved}
      hideSaveButton={hideSaveButton}
      onNext={onNext}
    />
  );
};

export default forwardRef(Venmo);
