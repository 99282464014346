import { useEffect, ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalState } from '~utils';
import { USER_TYPE } from '~utils/constants';
import { isTwoStringSame } from '~utils/commonUtils';
import { useGlobalDispatch } from '~utils/container';

type UpdateUserTypeProps = {
  children: ReactNode;
};

const UpdateUserType = ({ children }: UpdateUserTypeProps) => {
  const { username: artistName } = useParams();
  const [isUpdatedUserType, setIsUpdatedUserType] = useState(false);
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const {
    user: { type, username, wallet_balance_fan },
  } = globalState;

  useEffect(() => {
    if (type === USER_TYPE.ARTIST && isTwoStringSame(username, artistName)) {
      dispatch({
        type: 'user',
        payload: {
          type: USER_TYPE.ARTIST,
          wallet_balance_fan: wallet_balance_fan,
        },
      });
    } else {
      dispatch({
        type: 'user',
        payload: {
          type: USER_TYPE.FAN,
          wallet_balance_fan: 0,
        },
      });
    }
    setIsUpdatedUserType(true);
  }, [
    artistName,
    dispatch,
    isUpdatedUserType,
    type,
    username,
    wallet_balance_fan,
  ]);

  if (isUpdatedUserType) {
    return <>{children}</>;
  }
  return <></>;
};

export default UpdateUserType;
