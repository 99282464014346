import { SVGProps } from 'react';

const SMS = (rest: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect
      width="30"
      height="30"
      rx="15"
      fill="url(#paint0_linear_11922_12660)"
    />
    <g clipPath="url(#clip0_11922_12660)">
      <path
        d={`M8.65716 19.894H14.5341L19.5383 24V19.894H21.3453C22.2571 19.894 22.9986 19.1394 22.9986 
        18.2114V9.68254C22.9986 8.75462 22.2571 8 21.3453 8H8.65716C7.74537 8 7.00391 
        8.75462 7.00391 9.68254V18.2114C7.00391 19.1393 7.74537 19.894 8.65716 19.894ZM18.8842 14.3401C18.2409 14.1234 
        17.9924 13.7734 17.9988 13.4039C17.9988 12.8245 18.4957 12.3852 19.2659 12.3852C19.6291 12.3852 19.9537 12.4677 
        20.145 12.5634L19.9732 13.2321C19.8331 13.1555 19.5656 13.0535 19.2981 13.0535C19.0624 13.0535 18.9286 13.1491 
        18.9286 13.3083C18.9286 13.4547 19.0497 13.5313 19.4319 13.6651C20.024 13.8686 20.2724 14.1683 20.2788 
        14.6267C20.2788 15.2061 19.8204 15.6327 18.9286 15.6327C18.5211 15.6327 18.1579 15.5438 17.9226 
        15.4165L18.0945 14.7224C18.2726 14.8307 18.6421 14.9581 18.9287 14.9581C19.2215 14.9581 19.3426 
        14.8561 19.3426 14.697C19.3426 14.5374 19.2469 14.4612 18.8842 14.3401ZM13.4149 12.4551L13.4593 
        12.8753H13.4783C13.612 12.6781 13.8859 12.3853 14.4209 12.3853C14.8221 12.3853 15.1403 12.5888 15.2741 
        12.9134H15.2868C15.4015 12.7543 15.5416 12.6269 15.688 12.5381C15.8603 12.4361 16.0512 12.3853 16.2806 
        12.3853C16.879 12.3853 17.3315 12.8056 17.3315 13.735V15.5693H16.389V13.8756C16.389 13.4231 16.2421 13.1619 
        15.9302 13.1619C15.7076 13.1619 15.548 13.3147 15.4845 13.4997C15.4591 13.5695 15.4464 13.6715 15.4464 
        13.7481V15.5693H14.5039V13.8243C14.5039 13.4294 14.3638 13.1619 14.0577 13.1619C13.8098 13.1619 13.6628 
        13.3533 13.6057 13.5124C13.574 13.5886 13.5677 13.6779 13.5677 13.7545V15.5694H12.6251V13.4485C12.6251 13.0604 
        12.6124 12.729 12.5997 12.4552H13.4149V12.4551ZM10.6453 14.3401C10.0019 14.1234 9.75348 13.7734 9.75982 
        13.4039C9.75982 12.8245 10.2567 12.3852 11.027 12.3852C11.3902 12.3852 11.7152 12.4677 11.9061 12.5634L11.7342 
        13.2321C11.5941 13.1555 11.3267 13.0535 11.0592 13.0535C10.8234 13.0535 10.6897 13.1491 10.6897 13.3083C10.6897 
        13.4547 10.8107 13.5313 11.1929 13.6651C11.785 13.8686 12.0334 14.1683 12.0398 14.6267C12.0398 15.2061 11.5815 
        15.6327 10.6897 15.6327C10.2821 15.6327 9.91891 15.5438 9.68366 15.4165L9.85549 14.7224C10.0337 14.8307 
        10.4032 14.9581 10.6897 14.9581C10.9826 14.9581 11.1036 14.8561 11.1036 14.697C11.1036 14.5374 11.008 14.4612 
        10.6453 14.3401Z`}
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_11922_12660"
        x1="15"
        y1="0"
        x2="15"
        y2="30"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CE7CAC" />
        <stop offset="1" stop-color="#7473B6" />
      </linearGradient>
      <clipPath id="clip0_11922_12660">
        <rect width="16" height="16" fill="white" transform="translate(7 8)" />
      </clipPath>
    </defs>
  </svg>
);

export default SMS;
