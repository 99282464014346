import {
  Box,
  Divider,
  LinearProgress,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import get from 'lodash/get';
import useBreakpoints from '~hooks/useBreakpoints';
import { numberFormat } from '~utils/commonUtils';

const StyledRoot = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
  backgroundColor: theme.palette.primary.dark,
}));

const Title = styled(Typography)<{ optimized?: boolean }>(
  ({ optimized, theme }) => ({
    padding: `${theme.spacing(optimized ? 1.2 : 1.75)} 0`,
    [theme.breakpoints.up('xs')]: {
      backgroundColor: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#171a26',
    },
    backgroundColor: '#171a26',
    textTransform: 'uppercase',
  }),
);

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  },
}));

const LabelText = styled(Typography)<{ fontSize?: number }>(
  ({ fontSize = 14, theme }) => ({
    [theme.breakpoints.up('lg')]: {
      fontSize: fontSize,
    },
    lineHeight: '22px',
    letterSpacing: 0.4,
    fontWeight: 300,
  }),
);

const DashedDivider = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 1,
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.light,
    top: 0,
    transform: 'translateY(-50%)',
  },
  '&::before': {
    left: -40,
  },
  '&::after': {
    right: -40,
  },
}));

const USER_KEYS: { label: string; value: string }[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Date',
    value: 'date',
  },
];

const ITEM_KEYS: { label: string; value: string }[] = [
  {
    label: 'Item',
    value: 'item',
  },
  {
    label: 'Price',
    value: 'price',
  },
  {
    label: 'Tax',
    value: 'tax',
  },
];

type Props = StackProps & {
  data: {
    name: string;
    date: string;
    item: string;
    price: number;
    tax?: number;
    fee?: number;
    total: string;
    totalAmount?: number;
  };
  title: string;
  titleProps?: TypographyProps;
  optimizedSpace?: boolean;
};

const NewPaymentReceipt = ({
  optimizedSpace = false,
  data,
  title,
  titleProps,
  ...rest
}: Props) => {
  const { isXs, isSm } = useBreakpoints();
  const renderKeys = (
    keys: { label: string; value: string }[],
    valueProps?: TypographyProps,
  ) => {
    return keys.map((item) => {
      const value = get(data, item.value, '');
      let displayValue = value;

      if (typeof value === 'number') {
        displayValue = `$${numberFormat(value || 0)}`;
      }

      if (Number(value) == -99) {
        return <></>;
      }
      const isLoading = value == null;
      return (
        <Stack
          key={item.value}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <LabelText fontSize={14}>{item.label}</LabelText>
          {isLoading ? (
            <LinearProgress
              sx={{
                minWidth: 30,
                minHeight: 5,
              }}
            />
          ) : (
            <Typography
              fontSize={{ lg: 14 }}
              lineHeight={'21px'}
              fontWeight={700}
              {...valueProps}
            >
              {displayValue}
            </Typography>
          )}
        </Stack>
      );
    });
  };

  const totalAmount = numberFormat((data['totalAmount'] ?? data['total']) || 0);

  const isInvalid = data['totalAmount'] === 0;

  return (
    <StyledRoot {...rest}>
      <Title
        optimized={optimizedSpace}
        variant="subtitle1"
        textAlign={'center'}
        {...titleProps}
      >
        {title}
      </Title>
      {isXs && !isSm && <DashedDivider />}
      <Content>
        <Stack spacing={optimizedSpace ? 0.6 : 1.5}>
          {renderKeys(USER_KEYS)}
        </Stack>
        <DashedDivider sx={{ my: optimizedSpace ? 1 : 3.5 }} />
        <Stack spacing={optimizedSpace ? 0.6 : 1.5}>
          {renderKeys(ITEM_KEYS, { fontWeight: '400' })}
        </Stack>
        <Divider sx={{ my: optimizedSpace ? 0.6 : 1.5 }} />
        {isInvalid ? (
          <LinearProgress />
        ) : (
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <LabelText fontSize={18}>TOTAL</LabelText>
            <Typography
              fontSize={{ lg: 20 }}
              lineHeight={'30px'}
              fontWeight={700}
            >
              {totalAmount}
            </Typography>
          </Stack>
        )}
      </Content>
    </StyledRoot>
  );
};

export default NewPaymentReceipt;
