import React, { useCallback, useEffect, useState } from 'react';

import { Button, Stack, styled, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import useMediaRecorderSupport from '~hooks/useMediaRecorderSupport';
import { convertSecondsToMS } from '~utils/dateTimeUtils';
import { useCountdown } from '~components/custom-hook/useCountdown';

const Container = styled(Stack)(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  paddingTop: '4px',
  paddingBottom: '4px',
  zIndex: 1,
  flexDirection: 'row',
  alignItems: 'center',
}));

const StartRecordingIcon = styled(RadioButtonCheckedIcon)(() => ({
  fontSize: 8,
  color: '#E62948',
}));
const StopRecordingIcon = styled(StopCircleOutlinedIcon)(() => ({
  fontSize: 8,
  color: '#E62948',
}));
const PreviewIcon = styled(FiberManualRecordIcon)(() => ({
  fontSize: 8,
  color: 'white',
  marginRight: 8,
}));

const PreviewTypography = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 'normal',
  color: 'rgba(255, 255, 255, 0.6)',
}));

const RecordingButton = styled(Button)(() => ({
  fontSize: 16,
  color: 'white',
  textTransform: 'none',
  fontWeight: 'normal',
}));

const WarningContainer = styled(Stack)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  paddingTop: 4,
  paddingBottom: 4,
  paddingLeft: 8,
  zIndex: 1,
  flexDirection: 'row',
  alignItems: 'center',
}));

const WarningLabel = styled(Typography)(() => ({
  color: 'red',
}));

interface RecordingFooterBarProps {
  onStartRecording: () => void;
  onStopRecording: (recordingTime: number) => void;
}

const MAX_TIME_FOR_RECORDING = 60;

const RecordingController = ({
  onStartRecording,
  onStopRecording,
}: RecordingFooterBarProps) => {
  const isSupported = useMediaRecorderSupport();
  const [isRecording, setIsRecording] = useState(false);
  const { count, startCountdown, stopCountdown } = useCountdown(
    MAX_TIME_FOR_RECORDING,
  );
  const handleStartRecording = async () => {
    await startCountdown();
    setIsRecording(true);
    await onStartRecording();
  };

  const handleStopRecording = useCallback(async () => {
    setIsRecording(false);
    onStopRecording(MAX_TIME_FOR_RECORDING - count);
    await stopCountdown();
  }, [count, onStopRecording, stopCountdown]);

  useEffect(() => {
    if (count <= 0 && isRecording) {
      handleStopRecording();
    }
  }, [count, handleStopRecording, isRecording]);

  return (
    <>
      {!isSupported && (
        <WarningContainer>
          <WarningLabel>
            This browser does not support video recording.
          </WarningLabel>
        </WarningContainer>
      )}
      <Container>
        <Stack flex={1} alignItems="center" marginLeft={2} direction="row">
          {isRecording ? (
            <RecordingButton
              variant="text"
              startIcon={<StopRecordingIcon />}
              onClick={handleStopRecording}
              disabled={!isSupported}
            >
              Stop
            </RecordingButton>
          ) : (
            <RecordingButton
              variant="text"
              startIcon={<StartRecordingIcon />}
              onClick={handleStartRecording}
              disabled={!isSupported}
            >
              Start record
            </RecordingButton>
          )}
        </Stack>
        <Stack
          flex={1}
          alignItems="center"
          justifyContent="end"
          marginRight={2}
          direction="row"
        >
          {isRecording ? (
            <>
              <PreviewIcon sx={{ color: '#E62948' }} />
              <PreviewTypography>
                {convertSecondsToMS(MAX_TIME_FOR_RECORDING - count)}
              </PreviewTypography>
            </>
          ) : (
            <>
              <PreviewIcon />
              <PreviewTypography>PREVIEW</PreviewTypography>
            </>
          )}
        </Stack>
      </Container>
    </>
  );
};

export default RecordingController;
