import { Button, Typography } from '@mui/material';
import useTimeCountdown from '~hooks/useTimeCountdown';
import { DateTime, getCurrentTime, isSameOrAfter } from '~utils/dateTimeUtils';
import { POPUP_TYPE, useGlobalDispatch, useGlobalState } from '~utils/index';
import { useMemo } from 'react';

const ArtistTipRewardsCountDown = () => {
  const globalState = useGlobalState();
  const dispatch = useGlobalDispatch();
  const {
    app: { activeCampaign, previousCampaigns, upcomingCampaigns },
  }: Record<string, any> = globalState;

  const rewardData = useMemo(() => {
    return activeCampaign || previousCampaigns?.[0] || upcomingCampaigns?.[0];
  }, [activeCampaign, previousCampaigns, upcomingCampaigns]);

  const [days, hours, minutes, seconds] = useTimeCountdown(
    rewardData?.endedAt as string,
  );

  const isEnded = !isSameOrAfter(
    DateTime.fromISO(rewardData?.endedAt as string),
    getCurrentTime(),
  );

  const handleOpenPopup = () => {
    dispatch({ type: 'app', payload: { popup: POPUP_TYPE.REWARDS } });
  };

  return (
    <Button
      fullWidth
      color="warning"
      variant="outlined"
      size="large"
      sx={{ textTransform: 'unset' }}
      onClick={handleOpenPopup}
    >
      {isEnded ? (
        'Rewards'
      ) : (
        <Typography
          sx={{ cursor: 'inherit' }}
          variant="body2"
          fontWeight={'500'}
          lineHeight={'21px'}
        >
          Rewards Campaign ends in {days} {parseInt(days) > 1 ? 'days' : 'day'}{' '}
          {hours}:{minutes}:{seconds}
        </Typography>
      )}
    </Button>
  );
};

export default ArtistTipRewardsCountDown;
