import { getRequest, postRequest, putRequest, Response } from '~utils';
import {
  ChatAddMessageResponse,
  ChatAllowOnStageResponse,
  ChatBlockFanResponse,
  ChatCreateGroupResponse,
  ChatGroupResponse,
  ChatRetrieveActivityResponse,
} from '~types/chats';

export enum ChatType {
  USER = 'USER',
  SYSTEM = 'SYSTEM',
}

export const createGroupChat = async (): Promise<
  Response<ChatCreateGroupResponse>
> => {
  return await postRequest({
    path: 'chats/create-group',
  });
};

export const sendMessage = async (
  message: string,
  groupId: string,
  type: string = ChatType.USER,
): Promise<Response<ChatAddMessageResponse>> => {
  return await postRequest({
    path: 'chats/add-message',
    data: {
      message,
      group_id: groupId,
      type: type,
    },
  });
};

export const getChatHistory = async (
  groupId: string,
  cursorMessageId?: string,
  limit?: number,
): Promise<ChatGroupResponse> => {
  return await getRequest({
    path: `chats/by-group-id`,
    config: {
      params: {
        group_id: groupId,
        old_message_id: cursorMessageId,
        limit,
      },
    },
  });
};

export const getActivity = async (
  username: string,
  groupId: string,
): Promise<ChatRetrieveActivityResponse> => {
  return await getRequest({
    path: 'chats/activity',
    config: {
      params: {
        username,
        group_id: groupId,
      },
    },
  });
};

export const updateAllowOnStage = async (
  groupId: string,
  allowOnStage: boolean,
): Promise<Response<ChatAllowOnStageResponse>> => {
  return await putRequest({
    path: 'chats/allow-on-stage',
    data: {
      group_id: groupId,
      allow_on_stage: allowOnStage,
    },
  });
};

export const blockChat = async (
  username: string,
  groupId: string,
): Promise<Response<ChatBlockFanResponse>> => {
  return await putRequest({
    path: 'chats/block-fan',
    data: {
      username,
      group_id: groupId,
      blocked: true,
    },
  });
};

export const joinChat = async (
  groupId: string,
): Promise<Response<ChatAddMessageResponse>> => {
  return await postRequest({
    path: `chats/join-chat`,
    data: {
      group_id: groupId,
    },
  });
};
