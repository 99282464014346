import React from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import { Button, Stack, Typography, styled } from '@mui/material';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 600,
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onReRegister: () => void;
}

const ReRegisterNotificationModal = ({
  open,
  onClose,
  onReRegister,
}: Props) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      closeBtn={true}
      keepMounted={false}
    >
      <Stack spacing={3}>
        <Typography
          fontWeight={'700'}
          variant="subtitle1"
          lineHeight={'27px'}
          align="center"
        >
          We've launched a new and improved version of Ursa Live. In order to
          bring you more amazing live stream experiences, we need to ask you to
          sign up again. Our apologies for the inconvenience. We're super
          excited about what's ahead and hope you will enjoy all the new
          features we have in store.
        </Typography>
        <Stack direction="row" spacing={1.5} flex={1} justifyContent="center">
          <Button variant="contained" color="warning" onClick={onReRegister}>
            SIGN UP
          </Button>
          <Button variant="outlined" color="warning" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </StyledModal>
  );
};

export default ReRegisterNotificationModal;
