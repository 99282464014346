import { useState, useContext, useEffect } from 'react';
import {
  AgoraVideoContext,
  useGlobalDispatch,
  useGlobalState,
  SocketContext,
  POPUP_TYPE,
} from '~utils';
import { useTriggerTimeout } from '../../custom-hook';
import { WebSocketEvent } from '~socket/types';
import { useCameraTrack } from '../../../hooks/useCameraAndMicrophone';
import { updateAllowOnStage } from '~api/chats';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { StageIcon } from '~components/icons';
import CloseIcon from '@mui/icons-material/Close';
import BasePopup from './base-popup';

const StyledStageModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
    padding: theme.spacing(4),
  },
  [theme.breakpoints.up('sm')]: {
    width: 518,
    marginBottom: 'auto',
    marginTop: 'auto',
  },
}));

const StyledCloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: -20,
  top: -24,
}));

const StageModal = () => {
  const [allowOnStage, setAllowOnStage] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const dispatch = useGlobalDispatch();
  const {
    user: { displayUsername: userName, username: accountUsername },
    config: { chatGroupId, isArtistLive },
    artist: { id: artist_id, currentEvent, username },
  } = useGlobalState();

  const handleClose = () => {
    dispatch({ type: 'app', payload: { popup: POPUP_TYPE.NONE } });
  };

  const { startTimeout, stopTimeout } = useTriggerTimeout(handleClose, 10000);

  const agoraVideo = useContext(AgoraVideoContext);
  const socket = useContext(SocketContext);

  const { cameraTrack } = useCameraTrack();

  const handleAllowOnStage = async (isAllowOnStage: boolean) => {
    setDisableButton(true);
    if (!cameraTrack) {
      dispatch({ type: 'app', payload: { popup: POPUP_TYPE.NONE } });
      setAllowOnStage(false);
      return;
    }
    await updateAllowOnStage(chatGroupId, isAllowOnStage);

    setAllowOnStage(isAllowOnStage);
    if (!isAllowOnStage)
      dispatch({ type: 'app', payload: { popup: POPUP_TYPE.NONE } });
    await agoraVideo?.setClientRole('host');

    // create local audio and video tracks
    await agoraVideo?.publish(cameraTrack);

    socket?.sendPayload({
      event: WebSocketEvent.ALLOW_ON_STAGE_INVITE,
      username,
      allow_on_stage: isAllowOnStage,
      artist_id: artist_id,
      event_id: currentEvent,
      display_username: userName,
      fan_username: accountUsername,
    });

    dispatch({
      type: 'config',
      payload: {
        isFanLive: true,
        localVideoTrack: cameraTrack,
      },
    });
  };

  useEffect(() => {
    if (!isArtistLive) {
      dispatch({
        type: 'app',
        payload: { popup: POPUP_TYPE.NONE },
      });
    }
    return () => {
      // Cleanup state when unmount
      setAllowOnStage(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArtistLive]);

  return (
    <StyledStageModal
      open={true}
      onClose={handleClose}
      closeBtn={
        <StyledCloseButton
          onClick={() => {
            dispatch({
              type: 'app',
              payload: { popup: '' },
            });
            stopTimeout();
          }}
        >
          <CloseIcon
            sx={{
              color: 'white',
            }}
          />
        </StyledCloseButton>
      }
    >
      <Stack justifyContent={'center'} alignItems={'center'} spacing={4}>
        <StageIcon width={120} height={120} />

        <Box>
          {allowOnStage ? (
            <Stack spacing={3}>
              <Typography
                variant="body1"
                fontSize={{
                  lg: 14,
                }}
                fontWeight={'500'}
                textAlign={'center'}
              >
                Great! We can&apos;t guarantee that the artist will pull you on
                stage but you are now eligible.
              </Typography>
              <Typography
                variant="body1"
                fontWeight={'700'}
                fontSize={{ lg: 18 }}
                textAlign={'center'}
                textTransform={'uppercase'}
              >
                We suggest having a question ready!
              </Typography>
            </Stack>
          ) : (
            <Typography
              variant="subtitle1"
              fontSize={18}
              fontWeight={'700'}
              textAlign={'center'}
              px={{ xs: 0, md: 4 }}
            >
              Would you like to be eligible to appear on stage to meet the
              artist during this livecast?
            </Typography>
          )}
        </Box>
        {!allowOnStage && (
          <>
            <Stack spacing={2.5} width={'100%'}>
              <Button
                variant="contained"
                size="large"
                color="warning"
                fullWidth
                disabled={disableButton}
                onClick={() => {
                  handleAllowOnStage(true);
                  startTimeout();
                }}
              >
                YES PLEASE
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="warning"
                fullWidth
                disabled={disableButton}
                onClick={() => handleAllowOnStage(false)}
              >
                NO THANKS
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </StyledStageModal>
  );
};

export default StageModal;
