import React from 'react';
import TotalInfo from '~components/manage-profile/tabs/reports/total-info';
import { Stack } from '@mui/material';
import { formatLcAndUsd } from '~utils/formatters';

interface Props {
  revenuePerEvent: number;
  totalRevenue: number;
  attendeesPerEvent: number;
  totalAttendees: number;
  totalFollowers: number | null | undefined;
}

const InformationData = ({
  revenuePerEvent,
  attendeesPerEvent,
  totalRevenue,
  totalAttendees,
  totalFollowers,
}: Props) => {
  return (
    <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
      <TotalInfo
        title={'Total Revenue to date '}
        value={totalRevenue}
        averageText={'Average Tips Per Event:'}
        averageValue={revenuePerEvent}
        valueSuffix={`LC (or ${formatLcAndUsd(totalRevenue).usdText})`}
        averageSuffix={`LC (or ${formatLcAndUsd(revenuePerEvent).usdText})`}
      />
      <TotalInfo
        title={'Total Attendees to date '}
        value={totalAttendees}
        averageText={'Average Attendees Per Event:'}
        averageValue={attendeesPerEvent}
      />
      <TotalInfo title={'Total Followers'} value={totalFollowers} />
    </Stack>
  );
};

export default InformationData;
