import React, { useRef } from 'react';
import { Stack, styled } from '@mui/material';
import { strictValidArray } from '~utils/commonUtils';
import { ArtistEvent } from '~types/event';
import { EventCardItem } from '~components/molecules';

const StyledRoot = styled(Stack)(({ theme }) => ({
  maxWidth: '100%',
  overflowX: 'auto',
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 'calc(100% - 32px)',
    marginLeft: theme.spacing(2),
  },
  '&::-webkit-scrollbar': {
    width: 16,
    height: 8,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.primary.main,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.warning.main,
    borderRadius: theme.spacing(1),
  },
}));

type UpcomingEventCardsPropList = {
  events: ArtistEvent[];
};

const UpcomingEventCardList = ({ events }: UpcomingEventCardsPropList) => {
  const eventCardRef = useRef<HTMLDivElement | null>(null);

  return (
    <StyledRoot
      ref={eventCardRef}
      direction={'row'}
      spacing={2}
      mt={'auto'}
      justifyContent="flex-start"
    >
      {strictValidArray(events) &&
        events.map((item) => (
          <EventCardItem key={item.event?.id} data={item} />
        ))}
    </StyledRoot>
  );
};

export default UpcomingEventCardList;
