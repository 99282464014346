import {
  delRequest,
  getRequest,
  postRequest,
  rawGetRequest,
  Response,
} from '~utils';
import {
  BankAccountInfoParams,
  MakePayoutMoneyParams,
  PaymentCreateCustomerResponse,
  PaymentCreateIntentResponse,
  PaymentDetachPaymentMethodResponse,
  PaymentLatestPayResponse,
  PaymentMethodListResponse,
  PaymentPayResponse,
  PayoutInfoResponse,
  PayPalVenmoInfoParams,
  StripeCheckoutCreateSessionResponse,
  TaxCalculationRequest,
} from '~types/payment';
import {
  PayoutMethods,
  PaypalVenmoType,
} from '~components/manage-profile/tabs/payout/payout-method/types';

export const createCustomer = async (
  email: string,
): Promise<Response<PaymentCreateCustomerResponse>> => {
  return await postRequest({
    path: 'payment/create-customer',
    data: {
      email,
    },
  });
};

export const createIntent = async (
  paymentMethod: string,
  product: string,
): Promise<Response<PaymentCreateIntentResponse>> => {
  return await postRequest({
    path: 'payment/create-intent',
    data: {
      payment_method: paymentMethod,
      product,
    },
  });
};

export const detachPaymentMethod = async (
  paymentMethodId: string,
): Promise<Response<PaymentDetachPaymentMethodResponse>> => {
  return await postRequest({
    path: 'payment/detach-payment-method',
    data: {
      payment_method_id: paymentMethodId,
    },
  });
};

export const getPaymentMethods = async (
  customerId: string,
  limit: number,
): Promise<PaymentMethodListResponse> => {
  return await getRequest({
    path: 'payment/payment-methods',
    config: {
      params: {
        customer_id: customerId,
        limit,
      },
    },
  });
};

export const pay = async (
  customerId: string,
  paymentMethod: string,
  product: string,
  captchaToken?: string | null,
): Promise<Response<PaymentPayResponse>> => {
  return await postRequest({
    path: 'payment/pay',
    data: {
      customer_id: customerId,
      product: product,
      payment_method_id: paymentMethod,
      captcha_token: captchaToken,
    },
  });
};

export const getPayoutInfo = async (
  artistId: string,
): Promise<PayoutInfoResponse> => {
  return await getRequest({
    path: 'payout',
    config: {
      params: {
        id: artistId,
      },
    },
  });
};

export const saveBankAccountInfo = async ({
  firstName,
  lastName,
  routingNumber,
  accountNumber,
}: BankAccountInfoParams): Promise<Response<{ payoutId: string }>> => {
  return await postRequest({
    path: 'payout/tracking-payout-method',
    data: {
      payment_gateway: PayoutMethods.STRIPE,
      stripe: {
        receive_type: 'ACCOUNT',
        first_name: firstName,
        last_name: lastName,
        routing_number: routingNumber,
        account_number: accountNumber,
      },
    },
  });
};

export const savePayPalVenmoInfo = async ({
  receive_type,
  value,
  method,
}: PayPalVenmoInfoParams): Promise<Response<{ payoutId: string }>> => {
  // it's important to send clean phone number without spaces, -, +, ()
  const finalValue =
    receive_type === PaypalVenmoType.PHONE
      ? value.replace(/[- )(]/g, '')
      : value;
  return await postRequest({
    path: 'payout/tracking-payout-method',
    data: {
      payment_gateway: method,
      [method.toLowerCase()]: {
        receive_type,
        value: finalValue,
      },
    },
  });
};

export const makePayoutMoney = async ({
  payoutId,
  withdrawalAmount,
}: MakePayoutMoneyParams): Promise<Response<{ payoutId: string }>> => {
  return await postRequest({
    path: 'payout',
    data: {
      payout_id: payoutId,
      withdrawal_amount: withdrawalAmount,
    },
  });
};

export const getLastPaymentInfo =
  async (): Promise<PaymentLatestPayResponse> => {
    return await getRequest({
      path: 'payment/latest',
    });
  };

export const createCheckoutSession = async (
  productId: string,
): Promise<Response<StripeCheckoutCreateSessionResponse>> => {
  return await postRequest({
    path: 'payment/checkout-session',
    data: {
      product_id: productId,
    },
  });
};

export const calculateTax = async (
  data: TaxCalculationRequest,
): Promise<Response<any>> => {
  return await postRequest({
    path: 'payment/calculate-tax',
    data: {
      ...data,
    },
  });
};

export const getAddresses = async (): Promise<Response<any>> => {
  return await getRequest({
    path: 'payment/address',
  });
};

export const updateAddresses = async (data: any): Promise<Response<any>> => {
  return await postRequest({
    path: 'payment/address',
    data: data,
  });
};

export const deleteAddress = async (
  addressId: string,
): Promise<Response<any>> => {
  return await delRequest({
    path: `payment/address?address_id=${addressId}`,
  });
};

export const payWithTax = async (
  customerId: string,
  paymentMethod: string,
  product: string,
  taxAmount: number,
  paymentAddress?: any,
  captchaToken?: string | null,
): Promise<Response<PaymentPayResponse>> => {
  return await postRequest({
    path: 'payment/pay',
    data: {
      customer_id: customerId,
      product: product,
      payment_method_id: paymentMethod,
      tax_amount: taxAmount,
      payment_address: paymentAddress,
      captcha_token: captchaToken,
    },
  });
};

export const createIntentWithTax = async (
  paymentMethod: string,
  product: string,
  tax: number,
  paymentAddress?: any,
): Promise<Response<PaymentCreateIntentResponse>> => {
  return await postRequest({
    path: 'payment/create-intent',
    data: {
      payment_method: paymentMethod,
      product,
      tax_amount: tax,
      payment_address: paymentAddress,
    },
  });
};

export const checkPostalCode = async (
  postalCode: string,
  country: string,
): Promise<any> => {
  return await rawGetRequest({
    path: `https://api.zippopotam.us/${country}/${postalCode}`,
  });
};
