import React from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import { styled } from '@mui/material';

import ShareProfile from '~components/organisms/share-profile';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 600,
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  isArtist?: boolean;
  artistName: string;
  showHeader?: boolean;
}

const ShareProfileModal = ({
  open,
  onClose,
  artistName,
  isArtist = true,
  showHeader = false,
}: Props) => {
  return (
    <StyledModal open={open} onClose={onClose} keepMounted={false}>
      <ShareProfile
        onClose={onClose}
        artistName={artistName}
        isArtist={isArtist}
        showHeader={showHeader}
      />
    </StyledModal>
  );
};

export default ShareProfileModal;
