import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { IconProps } from '~components/icons';

const Info = (props: IconProps & Omit<FontAwesomeIconProps, 'icon'>) => (
  <FontAwesomeIcon {...props} icon={faCircleInfo as IconProp} />
);

export default Info;
