import React, { useState, useEffect, useMemo } from 'react';
// @mui
import {
  Badge,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  alpha,
} from '@mui/material';
import { pxToRem } from '../../../styles/theme/typography';
// utils
import { useGlobalState } from '~utils/container';
import { USER_TYPE } from '~utils/constants';
import { strictValidArrayWithLength } from '~utils/commonUtils';
import { dateStringToRelativeTime } from '~utils/dateTimeUtils';
// components
import Avatar from '~components/organisms/avatar';
import { LinkifyText, TextEllipsis } from '~components/atoms';
// types
import { Chat } from '~types/chats';
import ArtistMoreActions from './artist-more-menu';
import { ChatType } from '~api/chats';

type Props = {
  item: Chat;
};

const ArtistChatItem = ({ item }: Props) => {
  const { message, username, createdAt, userId, type: chatType } = item;
  const isSystemChat = chatType === ChatType.SYSTEM;
  const [systemMessage, setSystemMessage] = useState<any>();

  const {
    config: { remoteUsersDetails },
    user: { type },
    artist: { name: artistName, username: artistUsername },
  }: Record<string, any> = useGlobalState();
  const [showAllowOnStage, setAllowOnStage] = useState(false);
  const sender = useMemo(
    () =>
      isSystemChat
        ? 'Ursa-live System'
        : username === artistUsername
        ? artistName
        : username,
    [artistUsername, username, artistName],
  );

  useEffect(() => {
    if (strictValidArrayWithLength(remoteUsersDetails)) {
      const detail = remoteUsersDetails.find(
        ({ userId: fanId }: { userId: string }) => fanId === userId,
      );
      if (detail) {
        const { allowOnStage = false } = detail;
        setAllowOnStage(allowOnStage);
      }
    } else {
      setAllowOnStage(false);
    }
  }, [remoteUsersDetails, userId]);

  useEffect(() => {
    if (isSystemChat) {
      const removeIconMessage = message
        .replace('✍️', '')
        .replace('🙌', '')
        .replace('🎉', '')
        .trim();
      const senderName = removeIconMessage.split(' ')[0];
      const content = removeIconMessage.substring(senderName.length);
      setSystemMessage({
        sender: senderName,
        content: content,
        originalMessage: removeIconMessage,
      });
    }
  }, []);

  return (
    <ListItem disablePadding alignItems="flex-start">
      <ListItemAvatar sx={{ minWidth: 36 }}>
        <Badge
          invisible={!(type === USER_TYPE.ARTIST && showAllowOnStage)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          color="success"
          variant="dot"
          overlap="circular"
        >
          <Box sx={{ width: 24, height: 24 }}>
            <Avatar username={username} />
          </Box>
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            position={'relative'}
          >
            <TextEllipsis
              text={isSystemChat ? systemMessage?.sender : sender}
              variant="body1"
              fontSize={{ xs: 14 }}
              fontWeight={'700'}
              maxWidth={180}
            />
            <Typography
              variant="caption"
              sx={{
                color: (theme) => alpha(theme.palette.text.primary, 0.6),
              }}
            >
              {dateStringToRelativeTime(createdAt)}
            </Typography>

            <ArtistMoreActions
              userId={item.userId}
              username={item.username}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            />
          </Stack>
        }
        primaryTypographyProps={{
          sx: { mb: (theme) => theme.spacing(0.75) },
          color: (theme) => theme.palette.grey[100],
          component: 'div',
        }}
        secondaryTypographyProps={{
          variant: 'body2',
          fontWeight: '500',
          fontSize: pxToRem(12),
          color: (theme) => theme.palette.grey[100],
          sx: {
            wordBreak: 'break-word',
          },
        }}
        secondary={
          isSystemChat ? (
            <LinkifyText> {systemMessage?.originalMessage ?? ''} </LinkifyText>
          ) : (
            <LinkifyText> {message ?? ''} </LinkifyText>
          )
        }
      />
    </ListItem>
  );
};

export default ArtistChatItem;
