import { Box, styled, Typography } from '@mui/material';
import * as React from 'react';
import LoginTab from '~components/popup/login/tabs/LoginTab';
import SignUpTab from '~components/popup/login/tabs/SignUpTab';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { ModalWindow, Tab } from '~components/atoms';
import { ThinCrossSVG } from '~components/icons';
import Tabs from '../../atoms/tabs';
import { useEffect } from 'react';
import { useUserInfoState } from '~utils/container';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const loginTitle = 'login';
const signupSelectionTitle = 'would you like to sign up as an artist or a fan?';

interface Props {
  openSignUpTab?: boolean;
}

const Popup = ({ openSignUpTab = false }: Props) => {
  const [value, setValue] = React.useState(0);
  const [title, setTitle] = React.useState('login');
  const [signUpTitle, setSignUpTitle] = React.useState(signupSelectionTitle);
  const { hidePopup } = useDispatchPopup();
  const { isFanUser } = useUserInfoState();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const newTitleValue = newValue === 1 ? signUpTitle : loginTitle;
    setValue(newValue);
    setTitle(newTitleValue);
  };

  const handleCancel = () => {
    hidePopup();
  };

  const changeSignUpTitle = (newTitle: string) => {
    setSignUpTitle(newTitle);
    setTitle(newTitle);
  };

  const openSignupTab = () => {
    setValue(1);
    setTitle('Sign Up');
  };
  useEffect(() => {
    if (openSignUpTab) {
      openSignupTab();
    }
  }, [openSignUpTab]);
  useEffect(() => {
    if (isFanUser) {
      openSignupTab();
    }
  }, [isFanUser]);
  return (
    <CustomModalWindow open={true} onClose={handleCancel}>
      <>
        <Row sx={{ alignItems: 'center', position: 'relative' }}>
          <Title>{title}</Title>
          <CloseBtn onClick={handleCancel}>
            Close <ThinCrossSVG width={10} />
          </CloseBtn>
        </Row>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                padding: '10px 0',
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#FAC8A3',
                },
              }}
            >
              <Tab sx={{ ml: 0 }} label="Login" {...a11yProps(0)} />
              <Tab sx={{ m: 0 }} label="Sign Up" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <LoginTab value={value} index={0} openSignupTab={openSignupTab} />
          <SignUpTab value={value} index={1} changeTitle={changeSignUpTitle} />
        </Box>
      </>
    </CustomModalWindow>
  );
};

export default Popup;

const CustomModalWindow = styled(ModalWindow)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: 24,
  borderRadius: 20,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
  },
  [theme.breakpoints.up('sm')]: {
    width: 630,
  },
}));

const Row = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const Title = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontWeight: 900,
  fontSize: theme.typography.subtitle1.fontSize,
  padding: '50px 0 0',
  color: theme.palette.text.primary,
  textAlign: 'center',
  textTransform: 'uppercase',
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.h2.fontSize,
    padding: '8px 50px 0',
  },
  [theme.breakpoints.up('xs')]: {
    fontSize: '20px',
    padding: '30px 0 0',
  },
}));

const CloseBtn = styled(Box)(({ theme }) => ({
  position: 'absolute',
  fontSize: theme.typography.subtitle1.fontSize,
  right: 0,
  top: '10px',
  cursor: 'pointer',
}));
