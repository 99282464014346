export type ColorSchema =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }

  // TODO: Uncomment this when we have darker and lighter hex color
  interface SimplePaletteColorOptions {
    lighter?: string;
    // darker: string;
  }
  interface PaletteColor {
    lighter?: string;
    // darker: string;
  }
}

/**
 * * FYI: https://www.colorhexa.com
 */
const GREY = {
  0: '#FFFFFF',
  100: '#EAEAEA',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#343B50',
  700: '#292E42',
  800: '#161A27',
  900: '#181C27',
};

const PRIMARY = {
  lighter: '#1C2132',
  light: '#1B202B',
  main: '#1D212D',
  dark: '#161A27',
};

const SECONDARY = {
  main: '#343B50',
  light: '#ffffff1a',
  dark: '#0071BD',
};
// TODO: add color
// const INFO = {};

const SUCCESS = {
  lighter: '#6FCF97',
  light: '#55BA3C',
  main: '#22AF55',
};

const WARNING = {
  main: '#FAC8A3',
};

const ERROR = {
  light: '#EB5757',
  main: '#FF0000',
};

const HYPERLINK = {
  main: '#0071BD',
};
const DARKTEXT = {
  main: 'rgba(255, 255, 255, 0.3)',
};

const FOOTER = {
  main: '#2D3340',
  light: 'rgba(191, 198, 214, 0.75)',
};

const COMMON = {
  common: { black: '#000000', white: GREY[0] },
  primary: PRIMARY,
  secondary: SECONDARY,
  success: SUCCESS,
  error: ERROR,
  hyperlink: HYPERLINK,
  footer: FOOTER,
  warning: WARNING,
  grey: GREY,
  action: {
    active: '#FAC8A3',
  },
  darkText: DARKTEXT,
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    background: { paper: GREY[0], default: GREY[0], neutral: GREY[100] },
  } as const;

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: GREY[0],
      default: PRIMARY.light,
      secondary: GREY[900],
      disabled: '#ffffffa1',
    },
    background: {
      default: GREY[900],
      paper: 'rgba(0, 0, 0, 0.89)',
    },
  } as const;

  return themeMode === 'light' ? light : dark;
}
