import React from 'react';
import { SVGProps } from 'react';

const ExclamationMarkSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.83317 9.83341H8.1665V4.83341H9.83317M9.83317 13.1667H8.1665V11.5001H9.83317M8.99984 0.666748C7.90549 0.666748 6.82185 0.882296 5.81081 1.30109C4.79976 1.71987 3.8811 2.3337 3.10728 3.10752C1.54448 4.67033 0.666504 6.78994 0.666504 9.00008C0.666504 11.2102 1.54448 13.3298 3.10728 14.8926C3.8811 15.6665 4.79976 16.2803 5.81081 16.6991C6.82185 17.1179 7.90549 17.3334 8.99984 17.3334C11.21 17.3334 13.3296 16.4554 14.8924 14.8926C16.4552 13.3298 17.3332 11.2102 17.3332 9.00008C17.3332 7.90573 17.1176 6.8221 16.6988 5.81105C16.28 4.80001 15.6662 3.88135 14.8924 3.10752C14.1186 2.3337 13.1999 1.71987 12.1889 1.30109C11.1778 0.882296 10.0942 0.666748 8.99984 0.666748Z"
      fill="#EB5757"
    />
  </svg>
);

export default ExclamationMarkSVG;
