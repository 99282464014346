import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import type { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// @mui
import { SxProps } from '@mui/material';
// context
import { BringToStageContext } from '~providers/BringToStageProvider';
// hooks
import { useFeatureFlag } from '~hooks/useFeatureFlag';
// api
import { blockUser, unblockUser } from '~api/artist';
import { blockFan } from '~api/event';
import { blockChat } from '~api/chats';
// utils
import { AgoraVideoContext, useGlobalState } from '~utils/container';
import { FEATURE_FLAGS_TYPE, USER_TYPE } from '~utils/constants';
import { showErrorToaster } from '~utils/toasterNotification';
import {
  strictValidArrayWithLength,
  strictValidArray,
  isTwoStringSame,
} from '~utils/commonUtils';
import { fetchChatUserDetail } from '~utils/chatUtils';
// components
import { MoreMenu } from '~components/molecules';
import { QUERY_KEYS } from '~constants/query-keys';
import { ConfirmationModal } from '~components/organisms';

type Props = {
  userId: string;
  username: string;
  isBlocked?: boolean;
  sx?: SxProps;
};

const ArtistMoreMenu = ({ userId, username, isBlocked = false, sx }: Props) => {
  const { isEnable } = useFeatureFlag();

  const isEnableBlockUser = isEnable(FEATURE_FLAGS_TYPE.BLOCKED_USER);

  const client = useQueryClient();

  const agoraVideo = useContext(AgoraVideoContext);

  const { bringToStage, isAllowBringToStage } = useContext(BringToStageContext);

  const {
    config: { remoteUsers, remoteUsersDetails, isArtistLive, chatGroupId },
    user: { type, username: currentUsername, id: currentUserId },
    artist: { currentEvent, id: artistId },
  }: Record<string, any> = useGlobalState();

  const [showAllowOnStage, setAllowOnStage] = useState(false);
  const [openBlockConfirmationModal, setOpenBlockConfirmationModal] =
    useState(false);

  const { mutate: addUserToBlockListMutate } = useMutation({
    mutationFn: (id: string) => blockUser(id),
    onSuccess: () => {
      client.refetchQueries({
        queryKey: [QUERY_KEYS.ARTIST_BLOCKED_USERS_LIST, currentUserId],
        type: 'active',
      });
    },
  });

  const { mutate: removeUserFromBlockListMutate } = useMutation({
    mutationFn: (id: string) => unblockUser(id),
    onSuccess: () => {
      client.refetchQueries({
        queryKey: [QUERY_KEYS.ARTIST_BLOCKED_USERS_LIST, currentUserId],
        type: 'active',
      });
    },
  });

  const hiddenMenu = isEnableBlockUser
    ? !(type === USER_TYPE.ARTIST && currentUsername !== username)
    : !(
        type === USER_TYPE.ARTIST &&
        isArtistLive &&
        currentUsername !== username &&
        currentEvent
      );

  const allowEligibleToStage = useMemo(() => {
    let isEligibleToStage = false;
    if (strictValidArray(remoteUsers)) {
      const isPresentInSpotLight = remoteUsers.some((user: { uid: string }) =>
        isTwoStringSame(user.uid, username),
      );

      if (isPresentInSpotLight) {
        /**
         * * Check is stage empty
         */
        isEligibleToStage = !remoteUsers.some(
          (user: { audioTrack: boolean }) => user.audioTrack,
        );
      }
    }
    return isEligibleToStage;
  }, [remoteUsers, username]);

  const handleBlock = useCallback(async () => {
    try {
      const userVideo =
        strictValidArrayWithLength(remoteUsers) &&
        remoteUsers.find(({ uid }: { uid: string }) => uid === username);

      if (userVideo) {
        await (agoraVideo as IAgoraRTCClient).unsubscribe(userVideo);
      }

      if (isEnableBlockUser) {
        addUserToBlockListMutate(userId);
        setOpenBlockConfirmationModal(false);
      } else {
        await blockFan(userId, currentEvent);
        await blockChat(username, chatGroupId);
      }
    } catch (error) {
      showErrorToaster(error);
    }
  }, [
    remoteUsers,
    isEnableBlockUser,
    username,
    agoraVideo,
    addUserToBlockListMutate,
    userId,
    currentEvent,
    chatGroupId,
  ]);

  const handleUnblock = useCallback(async () => {
    try {
      removeUserFromBlockListMutate(userId);
      setOpenBlockConfirmationModal(false);
    } catch (error) {
      showErrorToaster(error);
    }
  }, [removeUserFromBlockListMutate, userId]);

  const handleBringToStage = useCallback(() => {
    bringToStage(username, currentEvent, userId, artistId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, currentEvent, userId, artistId]);

  const handleMoreMenu = useCallback(async () => {
    if (allowEligibleToStage) {
      const userData = await fetchChatUserDetail(username, chatGroupId);
      const { allowOnStage = false } = userData || {};
      setAllowOnStage(allowOnStage);
      return;
    }
    setAllowOnStage(false);
  }, [allowEligibleToStage, chatGroupId, username]);

  useEffect(() => {
    if (strictValidArrayWithLength(remoteUsersDetails)) {
      const detail = remoteUsersDetails.find(
        ({ userId: fanId }: { userId: string }) => fanId === userId,
      );
      if (detail) {
        const { allowOnStage = false } = detail;
        setAllowOnStage(allowOnStage);
      }
    } else {
      setAllowOnStage(false);
    }
  }, [remoteUsersDetails, userId]);

  return (
    <>
      <MoreMenu
        hidden={hiddenMenu}
        sx={sx}
        options={[
          {
            label: 'Bring to stage',
            onClick: handleBringToStage,
            visible: showAllowOnStage,
            disabled: !isAllowBringToStage,
          },
          {
            label: 'Block',
            onClick: () => {
              isEnableBlockUser
                ? setOpenBlockConfirmationModal(true)
                : handleBlock;
            },
            visible: !isBlocked,
            disabled: isBlocked,
          },
          {
            label: 'Unblock',
            onClick: () => setOpenBlockConfirmationModal(true),
            visible: isBlocked,
            disabled: !isBlocked,
          },
        ]}
        onClick={handleMoreMenu}
        menuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
      />
      <ConfirmationModal
        open={openBlockConfirmationModal}
        onClose={() => setOpenBlockConfirmationModal(false)}
        title={`Are you sure you want to ${
          isBlocked ? 'unblock' : 'block'
        } this user?`}
        confirmAction={isBlocked ? handleUnblock : handleBlock}
      />
    </>
  );
};

export default ArtistMoreMenu;
