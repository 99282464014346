import { createModel } from '@rematch/core';
import type { RootModel } from '.';
import { Event } from '~types/event';
import { getArchivedVideosByArtistId } from '~api/archived-videos';
import moment from 'moment';

export interface ArchivedVideoState {
  archivedVideos: Event[];
  currentVideo?: Event;
}

export interface UpdateEventInListProps {
  currentList: Event[];
  event: Event;
}

const initState: ArchivedVideoState = {
  archivedVideos: [],
  currentVideo: undefined,
};

const DURATION_CONDITION_FILTER = 60 * 15;

export const archivedVideos = createModel<RootModel>()({
  state: initState,
  selectors: (slice) => ({
    getArchivedVideos: () => {
      return slice((state: ArchivedVideoState) => {
        return state.archivedVideos;
      });
    },
    getCurrentVideo: () => {
      return slice((state: ArchivedVideoState) => {
        return state.currentVideo;
      });
    },
  }),
  reducers: {
    setArchivedVideos: (state, payload: Event[]) => {
      state.archivedVideos = payload;
    },
    setCurrentVideo: (state, payload?: Event) => {
      state.currentVideo = payload;
    },
  },
  effects: (dispatch) => ({
    async fetchArchivedVideos(artistId): Promise<Event[]> {
      const { videos } = await getArchivedVideosByArtistId(
        /* TODO: Will improve the total here to the right query on the next PR - use query all for accomplish the time line */
        artistId,
        1000,
        0,
      );
      dispatch.archivedVideos.setArchivedVideos(
        videos?.filter(
          (video) =>
            Math.abs(moment(video.endedAt).diff(video.startedAt, 'seconds')) >=
            DURATION_CONDITION_FILTER,
        ),
      );
      dispatch.archivedVideos.setCurrentVideo(
        videos.find((e) => e.defaultOnProfile),
      );
      return videos;
    },
    updateAnEventInList(data: UpdateEventInListProps) {
      const currentList: Event[] = data.currentList;
      const event: Event = data.event;

      const updatedList = currentList.map((e) => {
        if (e.id === event.id) {
          return {
            ...event,
          };
        } else {
          // Only 1 default video
          if (event.defaultOnProfile) {
            return {
              ...e,
              defaultOnProfile: false,
            };
          }
          return e;
        }
      });

      dispatch.archivedVideos.setArchivedVideos(updatedList);
    },
  }),
});
