import { FC, useEffect, useState } from 'react';
// @mui
import {
  Badge,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  alpha,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { pxToRem } from '../../../styles/theme/typography';
// lodash
import get from 'lodash/get';
// api
import { answerQuestion, voteQuestion } from '~api/event';
// utils
import { strictValidArrayWithLength } from '~utils/commonUtils';
import { USER_TYPE } from '~utils/constants';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import { dateStringToRelativeTime } from '~utils/dateTimeUtils';
// components
import Avatar from '~components/organisms/avatar';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { LinkifyText, TextEllipsis } from '~components/atoms';
// types
import { FanQuestion } from '~types/event';
//
import ArtistMoreMenu from './artist-more-menu';

type Props = {
  item: FanQuestion;
};
const ArtistQuestionItem: FC<Props> = ({ item }) => {
  const {
    question: { id, title, votes, myVotes, createdAt, answered },
    fan: { displayUsername = '', id: fanId, username = '' } = {},
  } = item;

  const [showAllowOnStage, setAllowOnStage] = useState(false);
  const {
    app: { questionsList },
    config: { remoteUsersDetails },
    user: { type, id: userId },
  }: Record<string, any> = useGlobalState();
  const dispatch = useGlobalDispatch();
  const { showLoginPopup } = useDispatchPopup();

  useEffect(() => {
    if (strictValidArrayWithLength(remoteUsersDetails)) {
      const detail = remoteUsersDetails.find(
        ({ userId: fan_id }: { userId: string }) => fan_id === fanId,
      );
      if (detail) {
        const { allowOnStage = false } = detail;
        setAllowOnStage(allowOnStage);
      }
    } else {
      setAllowOnStage(false);
    }
  }, [fanId, remoteUsersDetails]);

  // TODO need to check state data properties, remove as any to see detail error.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleVote = async (questionId: string, prevVote: any) => {
    if (!type || !userId) {
      showLoginPopup();
      return;
    }

    let vote = 0;
    if (!parseInt(prevVote, 10)) vote = 1;
    try {
      const res = await voteQuestion(questionId, Boolean(vote));
      if (res) {
        // TODO need to check state data properties, remove as any to see detail error.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updatedQuestions = questionsList.map((item: any) => {
          if (item.question.id === id) {
            const updatedQuestion = {
              ...item,
              question: { ...item.question, myVotes: vote },
            };
            return updatedQuestion;
          }
          return item;
        });
        dispatch({ type: 'app', payload: { questionsList: updatedQuestions } });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // TODO need to check state data properties, remove as any to see detail error.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAnswer = async (questionId: string, evt: any) => {
    const answer = evt.target.checked ? 1 : 0;
    try {
      const res = await answerQuestion(questionId, evt.target.checked);
      if (res) {
        const updatedQuestions = questionsList.map(
          (item: { question: any; fan: any }) => {
            if (item.question.id === id) {
              const updatedQuestion = {
                ...item,
                question: { ...item.question, answered: answer },
              };
              return updatedQuestion;
            }
            return item;
          },
        );
        dispatch({ type: 'app', payload: { questionsList: updatedQuestions } });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ListItem disablePadding alignItems="flex-start">
      <ListItemAvatar sx={{ minWidth: 36 }}>
        <Badge
          invisible={!(type === USER_TYPE.ARTIST && showAllowOnStage)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          color="success"
          variant="dot"
          overlap="circular"
        >
          <Box sx={{ width: 24, height: 24 }}>
            <Avatar username={username} />
          </Box>
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            position={'relative'}
          >
            <TextEllipsis
              text={displayUsername}
              variant="body1"
              fontSize={{ xs: 14 }}
              fontWeight={'700'}
              maxWidth={180}
            />
            <Typography
              variant="caption"
              sx={{
                color: (theme) => alpha(theme.palette.text.primary, 0.6),
              }}
            >
              {dateStringToRelativeTime(createdAt)}
            </Typography>
            <ArtistMoreMenu
              userId={get(item, 'fan.id', '')}
              username={get(item, 'fan.username', '')}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            />
          </Stack>
        }
        primaryTypographyProps={{
          mb: 0.5,
          component: 'div',
        }}
        secondaryTypographyProps={{
          variant: 'body2',
          fontWeight: '500',
          fontSize: pxToRem(12),
          color: (theme) => theme.palette.grey[100],
          sx: {
            wordBreak: 'break-word',
          },
        }}
        secondary={
          <>
            <Box mb={0.5}>
              <LinkifyText>{title}</LinkifyText>
            </Box>
            <Stack>
              {type === USER_TYPE.ARTIST && (
                <FormControlLabel
                  checked={Boolean(answered)}
                  onChange={(evt) => handleAnswer(id, evt)}
                  control={
                    <Checkbox
                      sx={{
                        py: 0.5,
                        pr: 0.5,
                        color: (theme) => theme.palette.action.active,
                        '&.Mui-checked': {
                          color: (theme) => theme.palette.success.lighter,
                        },
                      }}
                      size="small"
                      disableRipple
                      checkedIcon={<CheckBoxOutlinedIcon />}
                    />
                  }
                  sx={{ mb: 0, mr: 0 }}
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        color: (theme) =>
                          answered
                            ? theme.palette.success.lighter
                            : theme.palette.action.active,
                      }}
                    >
                      {answered ? 'Answered' : 'Unanswered'}
                    </Typography>
                  }
                />
              )}
              {type === USER_TYPE.FAN && (
                <Stack direction={'row'} alignItems={'center'}>
                  <Box>
                    <IconButton
                      sx={{ p: 0 }}
                      disableRipple
                      size="small"
                      onClick={() => handleVote(id, myVotes)}
                    >
                      <Box>
                        <img
                          style={{
                            filter: !myVotes ? 'grayscale(1)' : 'none',
                          }}
                          src="/assets/images/circle-top-arrow.svg"
                          alt=""
                        />
                      </Box>
                    </IconButton>
                  </Box>
                  <Typography variant="subtitle2" fontWeight={500} ml={1}>
                    {votes} upvotes
                  </Typography>
                </Stack>
              )}
            </Stack>
          </>
        }
      />
    </ListItem>
  );
};

export default ArtistQuestionItem;
