import React from 'react';
import ModalWindow from './index';
import { Box, ModalProps, styled, Typography } from '@mui/material';
import { TrashIcon } from '~components/icons';
import { Button } from '~components/atoms';

interface DeleteModalWindowProps extends Omit<ModalProps, 'children'> {
  onClose: () => void;
  onDelete: () => void;
  title: string;
  disabledButton?: boolean;
}

const DeleteModalWindow = ({
  open,
  onClose,
  onDelete,
  title,
  disabledButton = false,
}: DeleteModalWindowProps) => {
  return (
    <ModalWindow open={open} onClose={onClose}>
      <Wrapper>
        <Title>{title}</Title>
        <WrapperTrash>
          <TrashIcon width={'56'} height={'56'} />
        </WrapperTrash>
        <Button sx={{ mb: 2.5 }} disabled={disabledButton} onClick={onDelete}>
          DELETE
        </Button>
        <Button typeStyles={'cancel'} onClick={onClose}>
          CANCEL
        </Button>
      </Wrapper>
    </ModalWindow>
  );
};

export default DeleteModalWindow;

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textAlign: 'center',
  textTransform: 'uppercase',
  whiteSpace: 'pre-line',
  fontSize: theme.typography.fontSize,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h4.fontSize,
  },
}));

const WrapperTrash = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 30,
  margin: '30px auto',
  border: `4px solid ${theme.palette.action.active}`,
  borderRadius: '50%',
  aspectRatio: '1/1',
  path: {
    fill: theme.palette.action.active,
  },
}));
