import { Box, Grid, Stack, Typography, styled } from '@mui/material';

import applauseIcon from '~images/applause-icon.png';
import rockonIcon from '~images/rockon-icon.png';
import fireIcon from '~images/fire-icon.png';
import diskIcon from '~images/disk-icon.png';
import trophyIcon from '~images/trophy-icon.png';

const ItemLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

type TipItemProps = {
  active: boolean;
};
const TipItem = styled(Stack)<TipItemProps>(({ theme, active }) => ({
  paddingTop: 16,
  paddingBottom: 16,
  border: `1px solid ${theme.palette.action.active}`,
  borderRadius: 8,
  boxShadow: active ? `0 0 15px ${theme.palette.action.active}` : '',
  '&:hover': {
    boxShadow: `0 0 15px ${theme.palette.action.active}`,
    cursor: 'pointer',
  },
}));

TipItem.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
  direction: {
    md: 'row',
  },
};

const AMOUNT_OPTIONS = [
  { src: applauseIcon, amount: '50', value: '5' },
  { src: rockonIcon, amount: '100', value: '10' },
  { src: fireIcon, amount: '200', value: '20' },
  { src: diskIcon, amount: '500', value: '50' },
  { src: trophyIcon, amount: '1000', value: '100' },
];

export type TipOption = { src: string; amount: string; value: string };

type Props = {
  options?: TipOption[];
  selected: string;
  onChange: (value: string) => void;
  availableAmount?: number;
};

const TipOptions = ({ options, selected, onChange }: Props) => (
  <Grid container columns={{ xs: 3, sm: 3, md: 10, lg: 10 }} spacing={1.75}>
    {(options ?? AMOUNT_OPTIONS).map(({ amount: amountLC, src, value }) => {
      return (
        <Grid item xs={1} md={2} lg={2} key={amountLC}>
          <TipItem
            key={amountLC}
            onClick={() => {
              onChange(amountLC);
            }}
            active={amountLC === selected}
          >
            <Box>
              <img src={src} alt={`${amountLC} LC`} />
            </Box>
            <Box textAlign={'center'} ml={0.5}>
              <ItemLabel
                variant="body1"
                fontWeight={'700'}
                fontSize={{ lg: 14 }}
              >
                ${value}
              </ItemLabel>
              <ItemLabel fontSize={{ xs: 10 }}>{amountLC} LC</ItemLabel>
            </Box>
          </TipItem>
        </Grid>
      );
    })}
  </Grid>
);

export default TipOptions;
