import React from 'react';
import {
  Box,
  MenuItem,
  Select as SelectMUI,
  SelectProps as SelectPropsMUI,
  styled,
} from '@mui/material';
import { Label } from '~components/atoms';
import { TriangleArrowSVG } from '~components/icons';

export type SelectItem = { value: string; label: string };

interface SelectProps extends SelectPropsMUI {
  items: SelectItem[];
}

const Select = ({ items, label, className, ...rest }: SelectProps) => {
  return (
    <Box className={className} sx={{ height: 'fit-content' }}>
      {label && <Label required>{label}</Label>}
      <CustomSelect IconComponent={TriangleArrowSVG} {...rest}>
        {items.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  );
};

export default Select;

const CustomSelect = styled(SelectMUI)(({ theme }) => ({
  height: '100%',
  width: '100%',
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.primary,
  background: theme.palette.primary.main,
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '.MuiSelect-select': { padding: '10px 20px' },
  '.MuiSelect-icon': {
    width: 25,
    height: 7,
    marginTop: 2,
  },
}));
