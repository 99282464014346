import { useEffect, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Stack, Typography, alpha, styled } from '@mui/material';
import { showErrorToaster } from '~utils/toasterNotification';
import {
  useGlobalState,
  useGlobalDispatch,
  SocketContext,
  AgoraVideoContext,
  POPUP_TYPE,
} from '~utils';
import { createLocalAudioTrack } from '~utils/agoraFunctions';
import { WebSocketEvent } from '~socket/types';
import { SocketConnection } from '~socket/socket-connection';
import BasePopup from '~components/organisms/popup/base-popup';

const StyledStageInviteModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: 'transparent',
  [theme.breakpoints.up('lg')]: {
    backgroundColor: alpha(theme.palette.common.black, 0.5),
    width: '100%',
    height: '100%',
  },
}));

const StyledDescription = styled(Typography)(() => ({
  wordBreak: 'break-word',
  textTransform: 'uppercase',
  fontFamily: 'Work Sans',
  fontWeight: '900',
  maxWidth: 655,
}));

const StageInviteModal = () => {
  const {
    artist: { currentEvent, name, id: artist_id, username },
    user: { imageUrl, username: accountUsername, id: accountId },
  } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const socket = useContext(SocketContext);
  const agoraVideo = useContext(AgoraVideoContext);

  const { selectedMicrophoneConfig } = useSelector(
    (state: { settings: { selectedMicrophoneConfig: any } }) => state.settings,
  );

  const handleClose = () => {
    dispatch({
      type: 'app',
      payload: { popup: POPUP_TYPE.NONE },
    });
  };

  const handleDecline = useCallback(() => {
    (socket as SocketConnection).sendPayload({
      event: WebSocketEvent.ACCEPT_DECLINE_INVITE,
      username,
      event_id: currentEvent,
      accept: false,
      artist_id,
      profile_pic: imageUrl,
      fan_username: accountUsername,
      fan_id: accountId,
    });
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountUsername,
    artist_id,
    currentEvent,
    imageUrl,
    socket,
    username,
    accountId,
  ]);

  const handleAccept = async () => {
    handleClose();

    if (!agoraVideo) return;

    const microphoneConfig: { microphoneId?: string; AEC: boolean } = {
      AEC: true,
    };
    if (selectedMicrophoneConfig) {
      microphoneConfig.microphoneId = selectedMicrophoneConfig.deviceId;
    }
    const microphoneTrack = await createLocalAudioTrack(microphoneConfig);
    // If camera and audio mic is working fine . then we go further otherwise return
    if (!microphoneTrack) {
      microphoneTrack && (await microphoneTrack.close());
      handleDecline();
      return;
    }

    try {
      await agoraVideo.publish([microphoneTrack]);

      (socket as SocketConnection).sendPayload({
        event: WebSocketEvent.ACCEPT_DECLINE_INVITE,
        username,
        event_id: currentEvent,
        accept: true,
        artist_id,
        profile_pic: imageUrl,
        fan_username: accountUsername,
        fan_id: accountId,
      });

      dispatch({
        type: 'config',
        payload: {
          localAudioTrack: microphoneTrack,
        },
      });
      dispatch({
        type: 'app',
        payload: {
          stageFanId: accountId,
          stageFanUsername: accountUsername,
        },
      });
    } catch (error) {
      handleDecline();
      showErrorToaster(error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleDecline();
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <StyledStageInviteModal open={true} onClose={handleClose} closeBtn={true}>
      <Stack spacing={2} margin={'auto'} direction={'row'}>
        <Box>
          <Box sx={{ width: 60, height: 60 }}>
            <Box
              component={'img'}
              src="/assets/images/stage-icon.png"
              alt="icon"
              sx={{ maxWidth: '100%', height: 'auto' }}
            />
          </Box>
        </Box>
        <Stack spacing={1}>
          <StyledDescription variant="h2">
            {`${name} is inviting you on stage`}
          </StyledDescription>
          <Typography variant="body1">
            Your camera and mic will be on while onstage.
          </Typography>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            spacing={2}
            pt={1}
          >
            <Button variant="contained" color="warning" onClick={handleAccept}>
              ACCEPT
            </Button>
            <Button variant="outlined" color="warning" onClick={handleDecline}>
              DECLINE
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </StyledStageInviteModal>
  );
};

export default StageInviteModal;
