import { useCallback } from 'react';
import classNames from 'classnames/bind';
import popupStyles from './../popup.module.scss';
import { useGlobalDispatch } from '~utils/container';
import { updateLivecoinPopupFlag } from '../../action';
import LcTiles from '../../lc-tiles';
import { POPUP_TYPE } from '~utils';

let cx = classNames.bind(popupStyles);

export default function WelcomePopup() {
  const dispatch = useGlobalDispatch();

  const updateLivecoinPopup = useCallback(() => {
    dispatch({
      type: 'user',
      payload: {
        showLiveCoinPopup: false,
      },
    });
  }, [dispatch]);

  const handleSkip = useCallback(async () => {
    dispatch({
      type: 'app',
      payload: { popup: POPUP_TYPE.WELCOME_NOTIFICATION },
    });
    await updateLivecoinPopupFlag(updateLivecoinPopup);
  }, [dispatch, updateLivecoinPopup]);

  const handleUpdateLivecoinPopupFlag = useCallback(async () => {
    await updateLivecoinPopupFlag(updateLivecoinPopup);
  }, [updateLivecoinPopup]);

  return (
    <div className={cx('popup', 'show')}>
      <div className={cx('backoverlay', 'show')} />
      <div className={cx('popup-dailog')}>
        <div className={cx('inner-wrap')}>
          <div className={cx('sec-head')}>
            <h3 className={cx('title')}>WELCOME TO </h3>
          </div>
          <div className={cx('ursa-logo')}>
            <img
              src="/assets/images/ursa-logo-big.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className={cx('buy-livecoin')}>
            <p>
              Would you like to buy some livecoins to support your favorite
              artists?
            </p>
            <LcTiles updateLivecoinPopupFlag={handleUpdateLivecoinPopupFlag} />
          </div>

          <div className={cx('skip')}>
            <span className={cx('make-link')} onClick={handleSkip}>
              Skip
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
