import React from 'react';
import { Stack, styled } from '@mui/material';

import BgCoins from '../../../images/decorations-landing-page/bg-coins.png';
import { Title } from './title';
import { Description } from './description';

const Coins = () => {
  return (
    <Container>
      <Title>DON'T LEAVE MONEY ON THE TABLE</Title>
      <Description sx={{ width: { xs: '100%', sm: '70%', lg: '50%' } }}>
        If you're not livestreaming, you're not taking advantage of a powerful
        revenue stream available to artists. Plus all artists on Ursa Live have
        an opportunity to win a monthly cash bonus of $400, $300, or $200.
      </Description>
    </Container>
  );
};

export default Coins;

const Container = styled(Stack)(({ theme }) => ({
  padding: '64px 20px',
  gap: 4,
  background: `url(${BgCoins}) center / cover no-repeat`,
  [theme.breakpoints.up('md')]: {
    padding: '220px 0 265px',
    gap: 24,
  },
}));
