import { getRequest } from '~utils';
import { AgoraTokenResponse } from '~types/agora';

export const registerChatAccount = async (
  username: string,
): Promise<AgoraTokenResponse> => {
  return await getRequest({
    path: 'agora/chat-access-token',
    config: {
      params: {
        username,
      },
    },
  });
};

export const getAgoraVideoToken = async (
  channel: string,
  uid: string,
): Promise<AgoraTokenResponse> => {
  return await getRequest({
    path: 'agora/access-token',
    config: {
      params: {
        channel,
        uid,
      },
    },
  });
};
