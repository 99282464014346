import React from 'react';
import { alpha, Stack, styled, Typography } from '@mui/material';

interface LineProps {
  title: string;
  value: number | string;
  measurement: string;
  hideBorder?: boolean;
  caption?: string;
}

const Line = ({
  title,
  caption,
  value,
  measurement,
  hideBorder = false,
}: LineProps) => {
  return (
    <Wrapper
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={'space-between'}
      spacing={1}
      hideborder={hideBorder}
    >
      <Stack>
        <Title>{title}</Title>
        {caption && <Typography variant="caption">{caption}</Typography>}
      </Stack>
      <Stack direction={'row'} spacing={1} alignItems={'center'} flexGrow={1}>
        <Value>{value}</Value>
        {measurement && <Unit>{measurement}</Unit>}
      </Stack>
    </Wrapper>
  );
};

export default Line;

const Wrapper = styled(Stack)<{ hideborder: boolean }>(
  ({ theme, hideborder }) => ({
    padding: 20,
    borderBottom: hideborder
      ? 'none'
      : `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
    [theme.breakpoints.up('sm')]: {
      padding: 20,
    },
  }),
);
const Title = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-line',
  fontSize: theme.spacing(1.5),
  fontWeight: 500,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.spacing(2),
    fontWeight: 700,
    width: 160,
  },
}));

const Unit = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.common.white, 0.6),
  lineHeight: '19.5px',
  width: 74,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.spacing(1.75),
  },
}));
Unit.defaultProps = {
  variant: 'body1',
};

const Value = styled(Stack)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  flex: 1,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.warning.main,
  fontSize: theme.spacing(2),
  fontWeight: '700',
  justifyContent: 'flex-start',
  padding: `${theme.spacing(0.75)} ${theme.spacing(1)}`,
  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
    flexGrow: 1,
  },
}));

Value.defaultProps = {
  direction: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};
