import * as React from 'react';
import { SVGProps } from 'react';

const NoPhotoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M41.75 32.8124L37.5833 28.6458V5.41659H14.3541L10.1875 1.24992H37.5833C38.7291 1.24992 39.7104 1.65825 40.527 2.47492C41.3437 3.29159 41.7514 4.27214 41.75 5.41659V32.8124ZM39.25 42.0833L35.9166 38.7499H8.41663C7.27079 38.7499 6.28954 38.3416 5.47288 37.5249C4.65621 36.7083 4.24857 35.7277 4.24996 34.5833V7.08325L0.916626 3.74992L3.83329 0.833252L42.1666 39.1666L39.25 42.0833ZM10.5 30.4166L16.75 22.0833L21.4375 28.3333L23.1562 26.0416L8.41663 11.302V34.5833H31.6979L27.5312 30.4166H10.5Z"
      fill="#6F6F6F"
    />
  </svg>
);

export default NoPhotoSVG;
