import { Box, Stack, StackProps, Typography, styled } from '@mui/material';
import { TextEllipsis } from '~components/atoms';

const StyledTextEllipsis = styled(TextEllipsis)<{ success: string }>(
  ({ success, theme }) => ({
    textTransform: 'none',
    alignSelf: 'center',
    fontWeight: '700',
    lineHeight: '32px',
    position: 'relative',
    paddingRight: theme.spacing(1),
    '&::after': success === 'true' && {
      content: "'!'",
      position: 'absolute',
      right: 0,
      zIndex: 2,
    },
  }),
);

type Props = { title: string; text: string; success: boolean } & StackProps;

const EndLiveTitle = ({ title, text, success, ...rest }: Props) => (
  <Stack
    direction={'row'}
    justifyContent={'center'}
    alignItems={'center'}
    spacing={{ xs: 0.5, sm: 1 }}
    {...rest}
  >
    <Typography
      fontWeight={'700'}
      fontSize={{ xs: 20, sm: 28 }}
      variant="subtitle1"
      lineHeight={'32px'}
      textAlign={'center'}
    >
      {title}
    </Typography>
    <Box overflow={'hidden'}>
      <StyledTextEllipsis
        success={success.toString()}
        fontSize={{ xs: 20, sm: 28 }}
        variant="subtitle1"
        text={text}
        maxWidth={'auto'}
      />
    </Box>
  </Stack>
);

export default EndLiveTitle;
