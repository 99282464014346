import { Box, Stack, styled } from '@mui/material';
// components
import { TextEllipsis } from '~components/atoms';
// constants
import { USER_TYPE } from '~utils/constants';

const StyledFanStatus = styled(Box)(({ theme }) => ({
  width: 8,
  height: 8,
  backgroundColor: theme.palette.success.light,
  borderRadius: '50%',
}));

type Props = {
  type?: string;
  name: string;
  allowOnStage: boolean;
};

export default function FanName({ type, name, allowOnStage }: Props) {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={0.75}
      overflow={'hidden'} // When working with flex-item you should add this for ellipsis
      sx={{
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 2,
        padding: 0.5,
      }}
    >
      {type === USER_TYPE.ARTIST && allowOnStage && (
        <Box>
          <StyledFanStatus />
        </Box>
      )}
      <TextEllipsis
        variant="body2"
        fontSize={(theme) => theme.typography.subtitle1.fontSize}
        text={name}
        sx={{
          fontWeight: '500',
          lineHeight: '16px',
          position: 'relative',
          maxWidth: 'none',
        }}
      />
    </Stack>
  );
}
