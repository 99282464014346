import { Button, Stack, styled, Typography } from '@mui/material';
import alertIcon from '~images/alert-icon.svg';
import BasePopup from './base-popup';

const StyledGenreSelectModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],

  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  },
  [theme.breakpoints.up('sm')]: {
    width: 638,
  },
}));

const RemoveArchivedVideoConfirmationModal = ({
  open,
  onConfirm,
  onCancelRemove,
}: any) => {
  return (
    <StyledGenreSelectModal hideCloseButton={true} open={open}>
      <Stack spacing={4} alignItems={'center'}>
        <Typography
          textTransform={'uppercase'}
          mb={3}
          fontWeight={'700'}
          fontSize={{ xs: 20 }}
        >
          ALERT
        </Typography>
        <img src={alertIcon} className="img-fluid" alt="Back" />
        <Typography>
          When you remove this video from profile, it will be deleted in 2
          weeks.
        </Typography>
        <Stack sx={{ width: '100%' }} direction={'row'} spacing={3}>
          <Button
            fullWidth
            type="submit"
            color="warning"
            variant="outlined"
            sx={{ textTransform: 'capitalize' }}
            onClick={onCancelRemove}
          >
            Cancel
          </Button>

          <Button
            fullWidth
            type="submit"
            color="warning"
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            onClick={onConfirm}
          >
            Yes, Remove now
          </Button>
        </Stack>
      </Stack>
    </StyledGenreSelectModal>
  );
};

export default RemoveArchivedVideoConfirmationModal;
