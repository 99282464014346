import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
// auth
import { AuthProvider } from '~providers/AuthProvider';
// store
import { store } from '~stores';
// utils
import ContainerProvider from '~utils/container';
import { initLog } from '~utils/logUtils';
import { toastConfig } from '~utils';
import { DateAdapter } from '~utils/dateTimeUtils';
// theme
import ThemeProvider from '~styles/theme';
// components
import MetaPixel from '~components/atoms/meta-pixel';
import Router from './router';
import './components/icons';
//
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
//
import 'react-toastify/dist/ReactToastify.css';
//
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
//
import 'react-phone-input-2/lib/style.css';
//
import './styles/globals.css';
import './styles/main.scss';
import './styles/components/add-to-calendar-button.css';
import ReactGA from 'react-ga4';
import { UTMProvider } from '~providers/UtmProvider';
import { useEffect } from 'react';
import { loadTikTokPixel } from '~services/tiktokPixel';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
);

const paypalOptions = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
};

initLog();

const queryClient = new QueryClient();

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID as string, {
  gtagOptions: {
    send_page_view: false,
  },
});

function App() {
  useEffect(() => {
    loadTikTokPixel();
  }, []);

  const elementsOptions: any = {
    appearance: {
      theme: 'night',
      variables: {
        colorText: 'white',
        spacingUnit: '3.6px',
        colorPrimary: '#FAC8A3',
        fontSizeBase: '14px',
      },
      rules: {
        '.Label': {
          '-webkit-text-fill-color': '#white',
          fontSize: '12px',
          marginTop: '20px',
          marginBottom: '5px',
          fontWeight: 500,
        },
        '.Input': {
          backgroundColor: '#1d212d',
          border: 'none',
          boxShadow: 'none',
          height: 18,
          fontSize: '14px',
        },
        '.Input::placeholder': {
          color: '#aab7c4',
          fontSize: '14px',
        },
        '.Input:focus': {
          boxShadow: 'none',
        },
      },
    },
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <MetaPixel />
        <ContainerProvider>
          <ThemeProvider>
            <PayPalScriptProvider options={paypalOptions}>
              <Elements stripe={stripePromise} options={elementsOptions}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <UTMProvider>
                    <AuthProvider>
                      <Router />
                    </AuthProvider>
                  </UTMProvider>
                </LocalizationProvider>
              </Elements>
            </PayPalScriptProvider>
          </ThemeProvider>
          <ToastContainer position="top-right" {...toastConfig} />
        </ContainerProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
