import React from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import { Stack, styled } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import Webcam from 'react-webcam';
import { Button } from '~components/atoms';

const videoConstraints = {
  facingMode: 'user',
};

const IMAGE_TYPE = 'image/jpeg';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 600,
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onTakePicture: (imageSrc: string | null) => void;
}

const WebcamModal = ({ open, onClose, onTakePicture }: Props) => {
  const webcamRef = React.useRef<Webcam>(null);

  const capture = React.useCallback(async () => {
    if (!webcamRef?.current) return;
    const imageSrc = webcamRef.current.getScreenshot();

    onTakePicture(imageSrc);
    onClose();
  }, [onClose, onTakePicture]);

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      closeBtn={true}
      keepMounted={false}
    >
      <Stack spacing={3}>
        <Webcam
          ref={webcamRef}
          audio={false}
          height={'100%'}
          screenshotFormat={IMAGE_TYPE}
          width={'100%'}
          videoConstraints={videoConstraints}
        />
        <Button onClick={capture} startIcon={<PhotoCamera />}>
          Take Photo
        </Button>
      </Stack>
    </StyledModal>
  );
};

export default WebcamModal;
