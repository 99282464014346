import * as React from 'react';
import { SVGProps } from 'react';

const ShareProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="30"
      height="30"
      rx="15"
      fill="url(#paint0_linear_11922_12916)"
    />
    <path
      d={`M22.7331 19.6665C22.7331 19.8433 22.6628 20.0129 22.5378 20.1379C22.4128 20.2629 22.2432 20.3332 22.0664 
      20.3332H14.0664C13.8896 20.3332 13.72 20.2629 13.595 20.1379C13.47 20.0129 13.3997 19.8433 13.3997 
      19.6665C13.3997 18.6056 13.8212 17.5882 14.5713 16.8381C15.3215 16.0879 16.3389 15.6665 17.3997 
      15.6665H18.7331C19.7939 15.6665 20.8114 16.0879 21.5615 16.8381C22.3116 17.5882 22.7331 18.6056 
      22.7331 19.6665ZM18.0664 9.6665C17.539 9.6665 17.0234 9.8229 16.5849 10.1159C16.1464 10.4089 
      15.8046 10.8254 15.6027 11.3127C15.4009 11.8 15.3481 12.3361 15.451 12.8534C15.5539 13.3707 
      15.8078 13.8458 16.1808 14.2188C16.5537 14.5917 17.0289 14.8457 17.5462 14.9486C18.0634 15.0515 
      18.5996 14.9987 19.0869 14.7968C19.5742 14.595 19.9906 14.2532 20.2837 13.8147C20.5767 13.3762 
      20.7331 12.8606 20.7331 12.3332C20.7331 11.6259 20.4521 10.9476 19.952 10.4476C19.4519 9.94746 
      18.7737 9.6665 18.0664 9.6665ZM12.0664 9.6665C11.539 9.6665 11.0234 9.8229 10.5849 
      10.1159C10.1464 10.4089 9.80456 10.8254 9.60273 11.3127C9.40089 11.8 9.34809 12.3361 
      9.45098 12.8534C9.55387 13.3707 9.80785 13.8458 10.1808 14.2188C10.5537 14.5917 11.0289 14.8457 11.5462 
      14.9486C12.0634 15.0515 12.5996 14.9987 13.0869 14.7968C13.5742 14.595 13.9906 14.2532 14.2837 13.8147C14.5767 
      13.3762 14.7331 12.8606 14.7331 12.3332C14.7331 11.6259 14.4521 10.9476 13.952 10.4476C13.4519 9.94746 
      12.7737 9.6665 12.0664 9.6665ZM12.0664 19.6665C12.0654 18.9662 12.2034 18.2727 12.4723 17.6262C12.7412 
      16.9796 13.1358 16.3928 13.6331 15.8998C13.2261 15.7462 12.7948 15.6671 12.3597 15.6665H11.7731C10.7905 
      15.6683 9.84876 16.0594 9.15401 16.7541C8.45926 17.4489 8.06817 18.3906 8.06641 19.3732V19.6665C8.06641 
      19.8433 8.13664 20.0129 8.26167 20.1379C8.38669 20.2629 8.55626 20.3332 8.73307 20.3332H12.1864C12.1088 
      20.1194 12.0682 19.8939 12.0664 19.6665Z`}
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_11922_12916"
        x1="15"
        y1="0"
        x2="15"
        y2="30"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CE7CAC" />
        <stop offset="1" stop-color="#7473B6" />
      </linearGradient>
    </defs>
  </svg>
);

export default ShareProfile;
