import * as React from 'react';
import { SVGProps } from 'react';

const LinkedInSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16Z"
      fill="#0077B5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.046 9.8818C12.046 10.9279 11.2586 11.7649 9.99426 11.7649H9.97108C8.75377 11.7649 7.9668 10.9279 7.9668 9.8818C7.9668 8.8136 8.77774 8 10.0182 8C11.2586 8 12.0224 8.8136 12.046 9.8818ZM11.8076 13.252V24.1464H8.18164V13.252H11.8076ZM24.8838 24.1464L24.884 17.8998C24.884 14.5535 23.0952 12.9961 20.7091 12.9961C18.784 12.9961 17.9221 14.0535 17.4408 14.7953V13.2522H13.8145C13.8622 14.2745 13.8145 24.1467 13.8145 24.1467H17.4408V18.0623C17.4408 17.7367 17.4644 17.4119 17.5602 17.1788C17.8223 16.5283 18.419 15.8548 19.4207 15.8548C20.7333 15.8548 21.258 16.8538 21.258 18.3178V24.1464H24.8838Z"
      fill="white"
    />
  </svg>
);

export default LinkedInSVG;
