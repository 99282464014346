import React from 'react';
import { Title } from './title';
import { Description } from './description';
import { Box, Stack, styled } from '@mui/material';

import Monitor from '~images/decorations-landing-page/monitor.png';

const interactions = [
  {
    title: 'FAN SPOTLIGHT',
    description:
      'You see your fans while you perform and your fans see each other, offering a sense of community not available on any other live streaming platform.',
  },
  {
    title: 'ONSTAGE INTERACTIONS',
    description: 'Pull a fan onstage to say hi or ask a question.',
  },
  {
    title: 'DEDICATED QUESTIONS TAB',
    description:
      'No more scrolling through the chat looking for a good question. Fans upvote their favorite questions and the best questions go to the top.',
  },
  {
    title: 'REWARDS',
    description:
      'You choose your rewards and your fans get something special in return for supporting you.',
  },
  {
    title: 'LEADER BOARD',
    description:
      'Updated in real time, your leader board shows you your top supporter as the tips come in.',
  },
  {
    title: 'FAN NOTIFICATIONS',
    description:
      'Your followers receive notifications with calendar invitations when you add an upcoming livecast, OR when you go live!',
  },
];

const Interaction = () => {
  return (
    <Container>
      <Title sx={{ whiteSpace: 'pre-line' }}>
        {'INTERACT WITH YOUR FANS IN NEW AND\n EXCITING WAYS!'}
      </Title>
      <MonitorImage src={Monitor} alt="sphere" />
      <Cards>
        {interactions.map(({ title, description }) => (
          <Card key={title}>
            <Title
              sx={(theme) => ({
                fontSize: { md: theme.typography.h3.fontSize },
              })}
            >
              {title}
            </Title>
            <Description>{description}</Description>
          </Card>
        ))}
      </Cards>
    </Container>
  );
};

export default Interaction;

const Container = styled(Stack)(({ theme }) => ({
  display: 'flex',
  padding: '40px 20px',
  gap: 20,
  background: '#151820',
  [theme.breakpoints.up('md')]: {
    padding: '100px 40px 80px',
    gap: 40,
  },
  [theme.breakpoints.up('lg')]: {
    padding: '100px 80px 80px',
  },
}));

const MonitorImage = styled('img')(({ theme }) => ({
  maxWidth: 370,
  width: '100%',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: 770,
  },
}));

const Cards = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 16,
}));

const Card = styled(Stack)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '30%',
    padding: 15,
  },
  [theme.breakpoints.up('xl')]: {
    padding: 30,
  },
}));
