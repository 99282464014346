import { Theme } from '@mui/material/styles';
//

import Tabs from './Tabs';
import Input from './Input';
import Backdrop from './Backdrop';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return { ...Input(theme), ...Tabs(theme), ...Backdrop(theme) };
}
