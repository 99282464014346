import * as React from 'react';
import { SVGProps } from 'react';

const FacebookSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.5 16C32.5 7.16253 25.3375 0 16.5 0C7.66253 0 0.5 7.16253 0.5 16C0.5 23.9875 6.35 30.6063 14 31.8063V20.625H9.93747V16H14V12.475C14 8.4656 16.3875 6.25 20.0437 6.25C21.7937 6.25 23.625 6.5625 23.625 6.5625V10.5H21.6063C19.6187 10.5 19 11.7344 19 13V16H23.4375L22.7281 20.625H19V31.8063C26.65 30.6063 32.5 23.9875 32.5 16Z"
      fill="#1877F2"
    />
    <path
      d="M22.7282 20.625L23.4375 16H19V13C19 11.7344 19.6188 10.5 21.6063 10.5H23.625V6.5625C23.625 6.5625 21.7938 6.25 20.0438 6.25C16.3876 6.25 14 8.4656 14 12.475V16H9.9375V20.625H14V31.8063C14.8156 31.9344 15.65 32 16.5 32C17.35 32 18.1844 31.9344 19 31.8063V20.625H22.7282Z"
      fill="white"
    />
  </svg>
);

export default FacebookSVG;
