import * as React from 'react';
import { SVGProps } from 'react';

const MinusSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="4"
    viewBox="0 0 28 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 3.5V0.5H28V3.5H0Z" fill="white" />
  </svg>
);

export default MinusSVG;
