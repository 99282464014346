import { Stack, Typography } from '@mui/material';
// components
import { Logo } from '~components/atoms';

export default function NotFoundPage() {
  return (
    <Stack
      width={1}
      sx={{ height: '100vh' }}
      alignItems="center"
      justifyContent="center"
    >
      <Logo />
      <Typography sx={{ typography: { md: 'h3' }, mt: 3 }}>
        404 - Page Not Found
      </Typography>
    </Stack>
  );
}
