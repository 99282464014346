import React from 'react';
import AudioVideoDashboard from '~images/decorations-landing-page/audio-video-dashboard.png';
import { Box, Stack, styled } from '@mui/material';
import { Title } from './title';
import { Description } from './description';

const TechSupport = () => {
  return (
    <Container>
      <Title order={1} padding={{ md: '30px 0 20px' }}>
        One-On-One Tech Support
      </Title>
      <PictureBg>
        <img src={AudioVideoDashboard} alt="Dashboard" />
      </PictureBg>
      <Description width={{ sm: '70%', md: '40%' }} order={3}>
        We can even offer guidance on how best to promote, as well as make
        suggestions on successful livecast formats.
      </Description>
    </Container>
  );
};

export default TechSupport;

const Container = styled(Stack)(() => ({
  background: '#151820',
  padding: 20,
}));

const PictureBg = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  zIndex: 2,
  backgroundColor: theme.palette.common.black,
  padding: '15px 22px',
  width: 'fit-content',
  margin: '28px auto',
  order: 2,
  img: {
    height: 285,
  },
  [theme.breakpoints.up('md')]: {
    padding: '50px 50px 0',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    order: 4,
    margin: '30px auto 0',
    img: {
      height: 450,
    },
  },
  [theme.breakpoints.up('lg')]: {
    padding: '73px 100px 0',
  },
}));
