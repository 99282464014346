import React from 'react';
import { styled, Box, Stack, alpha } from '@mui/material';
import { MicrophoneSliderImage } from '~images';
import { Button } from '~components/atoms';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';

interface Props {
  title: React.ReactNode;
  subTitle: React.ReactNode;
  showSignUpButton?: boolean;
}

const HeroLandingPage = ({
  title,
  subTitle,
  showSignUpButton = false,
}: Props) => {
  const { showSignUpPopup } = useDispatchPopup();
  return (
    <Wrapper>
      <SlidePicture src={MicrophoneSliderImage} alt="Microphone" />
      <Texts>
        {showSignUpButton && (
          <Stack flex="1" alignItems="center" justifyContent="center">
            <CustomButton onClick={showSignUpPopup}>SIGN UP NOW</CustomButton>
          </Stack>
        )}
        {title}
        {subTitle}
      </Texts>
    </Wrapper>
  );
};

export default HeroLandingPage;

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 222,
  width: '100%',
  img: {
    padding: 0,
  },
  [theme.breakpoints.up('md')]: {
    height: '100%',
    img: {
      padding: 0,
    },
  },
}));

const Texts = styled(Stack)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: alpha(theme.palette.primary.dark, 0.73),
  position: 'absolute',
  left: 0,
  top: 0,
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('md')]: {
    padding: '20px 80px',
  },
}));

const SlidePicture = styled('img')(() => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
}));

const CustomButton = styled(Button)(({ theme }) => ({
  background: theme.palette.action.active,
  color: theme.palette.text.secondary,
  fontWeight: 700,
  textTransform: 'uppercase',
  zIndex: 2,
}));
