import { useEffect, useState } from 'react';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { WrapperBlock } from '~components/manage-profile/components';
import { DatePicker, Select } from '~components/atoms';
import VideoList from './VideoList';
import dayjs from 'dayjs';
import { useGlobalState } from '~utils/container';

const Container = styled(Stack)(() => ({
  width: '100%',
}));

Container.defaultProps = {
  spacing: {
    xs: 4,
    sm: 40,
  },
};

type Filter = {
  timeFrom: dayjs.Dayjs;
  timeTo: dayjs.Dayjs;
  sort: 'DESC' | 'ASC';
};

const ArchivedVideos = () => {
  const { user } = useGlobalState();
  const now = dayjs(new Date());
  const [error, setError] = useState<string>('');
  const [filter, setFilter] = useState<Filter>({
    timeFrom: now.subtract(1, 'year'),
    timeTo: now,
    sort: 'DESC',
  });

  const setTimeTo = (timeTo: any) => {
    setFilter({
      ...filter,
      timeTo: timeTo,
    });
  };
  const setTimeFrom = (timeFrom: any) => {
    setFilter({
      ...filter,
      timeFrom: timeFrom,
    });
  };
  const setSort = (sort: any) => {
    setFilter({
      ...filter,
      sort: sort,
    });
  };
  useEffect(() => {
    if (filter.timeTo.isBefore(filter.timeFrom)) {
      setError('Your input is wrong please set time from smaller then time to');
    } else {
      setError('');
    }
  }, [filter]);

  return (
    <Stack
      width={'100%'}
      spacing={{
        xs: 0.5,
        sm: 5,
      }}
    >
      <WrapperBlock
        title={'Archived Videos'}
        description={
          'IMPORTANT: We only save videos for 2 weeks unless you choose to add them to your profile.'
        }
        sx={{ '.MuiBox-root': { border: 'none !important' } }}
      >
        {/* {errorMessages.map((message, index) => {
        return <ErrorBox key={index}>{message}</ErrorBox>;
      })} */}
        <Wrapper>
          <Grid container>
            <FormRow container item>
              <Grid item xs={12} sm={1}>
                <CustomLabel>Time</CustomLabel>
              </Grid>
              <Grid container item xs={12} sm={11} spacing={1}>
                <FilterWrapper item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <CustomLabel
                    sx={{ mr: '8px', color: 'rgba(255, 255, 255, 0.6)' }}
                  >
                    From
                  </CustomLabel>
                  <DatePicker
                    value={filter.timeFrom}
                    preventTyping
                    onChange={(value) => setTimeFrom(value)}
                    placeholder="Select Start Date"
                  />
                </FilterWrapper>
                <FilterWrapper item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <CustomLabel
                    sx={{ mr: '8px', color: 'rgba(255, 255, 255, 0.6)' }}
                  >
                    To
                  </CustomLabel>
                  <DatePicker
                    value={filter.timeTo}
                    preventTyping
                    onChange={(value) => setTimeTo(value)}
                    placeholder="Select End Date"
                  />
                </FilterWrapper>
                <FilterWrapper item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <SortByOption
                    value={filter.sort}
                    defaultValue={'DESC'}
                    onChange={(event) => setSort(event.target.value)}
                    items={[
                      {
                        label: 'Sort by: Most Recent',
                        value: 'DESC',
                      },
                      {
                        label: 'Sort by: Oldest',
                        value: 'ASC',
                      },
                    ]}
                  />
                </FilterWrapper>
              </Grid>
            </FormRow>
          </Grid>
          {error && (
            <Typography color={'red'} fontWeight={600}>
              {error}
            </Typography>
          )}
          {/* TODO: Will improve the total here to the right query on the next PR - use query all for accomplish the time line */}
          <VideoList artistImage={user.imageUrl} filter={filter} total={1000} />
        </Wrapper>
      </WrapperBlock>
    </Stack>
  );
};

export default ArchivedVideos;

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 2,
}));

const FormRow = styled(Grid)(() => ({
  padding: '8px 0',
}));

const FilterWrapper = styled(Grid)(() => ({
  display: 'inline-flex',

  '& > .MuiBox-root:nth-of-type(1)': {
    marginRight: '8px',
  },
}));

const CustomLabel = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));

const SortByOption = styled(Select)(({ theme }) => ({
  '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.action.active}`,
  },
}));
