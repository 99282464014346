import React from 'react';
import { Typography, Divider, styled, Stack } from '@mui/material';

interface TotalInfoProps {
  title: string;
  value: number | null | undefined;
  valueSuffix?: string;
  averageText?: string;
  averageValue?: number;
  averageSuffix?: string;
}

const TotalInfo = ({
  title,
  value,
  valueSuffix,
  averageText,
  averageValue,
  averageSuffix,
}: TotalInfoProps) => {
  const formatNumberWithCommas = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  return (
    <Container spacing={2} alignItems={'center'}>
      <TitleText>{title}</TitleText>
      <Value>
        {typeof value === 'number'
          ? `${formatNumberWithCommas(value)} ${valueSuffix ?? ''}`
          : 'N/A'}
      </Value>
      <Divider sx={{ width: '100%' }} />
      <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
        {averageText && <SubTitleText>{averageText}</SubTitleText>}
        {typeof averageValue === 'number' && (
          <SubValue>
            {formatNumberWithCommas(averageValue)} {averageSuffix}
          </SubValue>
        )}
      </Stack>
    </Container>
  );
};

export default TotalInfo;

const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  padding: '10px 20px',
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

TitleText.defaultProps = {
  variant: 'body1',
  fontSize: {
    sm: 14,
  },
};

const SubTitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.subtitle2.fontSize,
}));
SubTitleText.defaultProps = {
  variant: 'caption',
  fontSize: {
    sm: 12,
  },
};

const SubValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontSize: theme.typography.subtitle2.fontSize,
}));
SubValue.defaultProps = {
  variant: 'caption',
  fontSize: {
    sm: 12,
  },
};

const Value = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.active,
  fontSize: theme.typography.h4.fontSize,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h3.fontSize,
  },
}));
