import { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';

const useUserAgent = () => {
  const [userAgent, setUserAgent] = useState<any>(null);

  useEffect(() => {
    const parser = new UAParser();
    const uaResult = parser.getResult();

    setUserAgent({
      device: uaResult.device.type || 'Desktop',
      browser: uaResult.browser.name || 'Unknown',
      os: uaResult.os.name || 'Unknown',
    });
  }, []);

  return userAgent;
};

export default useUserAgent;
