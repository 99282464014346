import { useState } from 'react';
// @mui
import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  MenuProps,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export type MoreMenuProps = IconButtonProps & {
  options: {
    label: string;
    onClick: () => void;
    visible?: boolean;
    disabled?: boolean;
  }[];
  onClick?: () => void;
  iconSize?: number;
  menuProps?: Omit<MenuProps, 'open'>;
};

const MoreMenu = ({
  options,
  onClick,
  iconSize = 16,
  menuProps,
  ...rest
}: MoreMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="more-menu-button"
        aria-controls={open ? 'more-artist-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          onClick && onClick();
        }}
        size="small"
        {...rest}
      >
        <MoreHorizIcon
          sx={{
            width: iconSize,
            height: iconSize,
            color: (theme) => theme.palette.common.white,
          }}
        />
      </IconButton>
      <Menu
        id="more-artist-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'more-menu-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        {...menuProps}
      >
        {options
          .filter(
            (option) => option.visible === undefined || option.visible === true,
          )
          .map((option, index) => (
            <MenuItem
              key={`${option.label}-${index}`}
              onClick={() => {
                option.onClick();
                handleCloseMenu();
              }}
              disabled={option?.disabled === true}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
export default MoreMenu;
