import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';

const styles = {
  button: {
    borderRadius: 2,
    backgroundColor: '#FAC8A3',
    border: '1px solid',
    borderColor: '#FAC8A3',
    color: 'black',
    fontWeight: 700,
    '&:active': {
      backgroundColor: 'black',
      color: '#FAC8A3',
    },
    '&:hover': {
      backgroundColor: 'black',
      color: '#FAC8A3',
    },
    '&.Mui-disabled': {
      color: '#181C27',
      opacity: 0.7,
    },
  },
};

const PrimaryButton = ({ children, sx, ...props }: ButtonProps) => {
  return (
    <Button sx={{ ...styles.button, ...sx }} {...props}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
