import { Dispatch } from 'react';
import { PaymentIntent } from '@stripe/stripe-js';
import { POPUP_TYPE } from '~utils';
import { getFanDetails, saveTransactionWithTax } from '~components';
import { LiveCoinOptionType, PaymentType, PaymentMethod } from '~types';
import { createIntentWithTax } from '~api';

const createPaymentIntent = async (
  productId: string,
  tax: number,
  paymentAddress?: any,
) => {
  const response = await createIntentWithTax(
    'card',
    productId,
    tax,
    paymentAddress,
  );
  return response.data;
};

const handlePostPayment = async (
  paymentIntent: PaymentIntent,
  selectedLiveCoin: LiveCoinOptionType,
  dispatch: Dispatch<object>,
  user: object,
  paymentMethod: PaymentMethod,
  cardId?: string,
  tax?: string,
) => {
  await saveTransactionWithTax(
    paymentIntent.id,
    selectedLiveCoin.live_coins,
    PaymentType.STRIPE,
    selectedLiveCoin?.stripeProductId,
    paymentMethod,
    cardId,
    tax,
  );
  await getFanDetails(dispatch, { user });
  dispatch({
    type: 'app',
    payload: { popup: POPUP_TYPE.THANK_POPUP },
  });
};

export { createPaymentIntent, handlePostPayment };
