import React, { useEffect } from 'react';
import { useGlobalState } from '~utils/container';
import { Box, Stack, Typography, alpha } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { USER_TYPE } from '~utils/constants';
import { getEmojiAssociateToAmount } from '~utils/commonUtils';

import Avatar from '../avatar';
import { useBoolean } from 'react-use';
import { useTriggerTimeout } from '~components/custom-hook';

interface Props {
  tipData: unknown;
  onStart?: () => void;
}

const SHOWING_TIP_DURATION = 30000;
const StreamTipNotification = ({
  tipData,
  onStart = () => undefined,
}: Props) => {
  const [show, setShow] = useBoolean(false);
  const { startTimeout } = useTriggerTimeout(
    () => setShow(false),
    SHOWING_TIP_DURATION,
  );
  const { user, artist } = useGlobalState();
  const { name, username: artistUsername } = artist;
  const { type } = user;
  const {
    username,
    amount,
    display_username: displayUsername,
    fan_id,
  } = tipData as {
    username: string;
    amount: number;
    display_username: string;
    fan_id: string;
  };

  const isFan = type === USER_TYPE.FAN && fan_id === user.id;

  useEffect(() => {
    if (tipData) {
      onStart();
      setShow(true);
      startTimeout();
    }
  }, [onStart, setShow, startTimeout, tipData]);

  if (!show) {
    return <></>;
  }

  return (
    <Stack direction="row">
      <Stack
        direction="row"
        spacing={1}
        sx={{
          backgroundColor: (theme) => alpha(theme.palette.grey[800], 0.7),
          borderRadius: (theme) => theme.spacing(5),
          py: (theme) => theme.spacing(1.25),
          px: (theme) => theme.spacing(2.5),
          marginTop: '10px',
        }}
      >
        {isFan ? (
          <Stack spacing={1} direction="row" alignItems={'center'}>
            <CheckCircleIcon sx={{ color: '#169E43' }} />
            <Typography variant="body2" noWrap>
              Thank you for supporting{' '}
              <Typography component={'span'} fontWeight={'500'}>
                {name || artistUsername || ''}
              </Typography>
            </Typography>
          </Stack>
        ) : (
          <>
            <Box sx={{ width: 32, height: 32 }}>
              <Avatar username={username} />
            </Box>
            <Stack spacing={1} direction="row" alignItems={'center'}>
              <Typography variant="body2" noWrap fontWeight={'500'}>
                {displayUsername}
                {' tipped '}
                <Typography component={'span'} color={'warning.main'}>
                  {amount} lc
                </Typography>
              </Typography>
              <Box>
                <img
                  src={getEmojiAssociateToAmount(amount)}
                  className="img-fluid"
                  alt="emoji"
                />
              </Box>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default StreamTipNotification;
