import { useCallback, useRef, useState } from 'react';
// mui
import { Container } from '@mui/material';
// service
import { useMutation, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { followArtist, getArtistFollowing, unfollowArtist } from '~api/fan';
// lodash
import get from 'lodash/get';
// components
import {
  ArtistFollowingList,
  ArtistFollowingToolbar,
} from '~components/organisms';
import { FollowPopover } from '~components/organisms';
import { usePopover } from '~components/atoms/custom-popover';
// utils
import { showErrorToaster } from '~utils/toasterNotification';
// types
import { FanResponse } from '~types/fan';
import { NotificationType } from '~types';
import { useGlobalState } from '~utils/container';

const ArtistFollowing = () => {
  const popoverFollow = usePopover();

  const cachedArtistFollowing = useRef<FanResponse[]>([]);
  const {
    config: { chatGroupId },
  } = useGlobalState();

  const [artistFollowing, setArtistFollowing] = useState<{
    data: FanResponse[];
    count: number;
  }>({
    data: [],
    count: 0,
  });

  const [currentArtist, setCurrentArtist] = useState<FanResponse | null>(null);

  const [params, setParams] = useState<{
    search?: string;
    page: number;
    pageSize: number;
  }>({
    page: 1,
    pageSize: 8,
  });

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.ARTIST_FOLLOWING_LIST, params.page, params.search],
    queryFn: () => getArtistFollowing(params),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      //
      cachedArtistFollowing.current = [...cachedArtistFollowing.current].concat(
        get(data, 'data.data', []).map((item) => ({
          ...item,
          isFollowing: true,
        })),
      );

      setArtistFollowing({
        data: cachedArtistFollowing.current,
        count: data.data.count,
      });
    },
  });

  const { mutate: unFollowArtistMutate, isLoading: isUnFollowing } =
    useMutation({
      mutationFn: (id: string) => unfollowArtist(id),
      onSuccess: async (_, id) => {
        setCurrentArtist(null);
        const nextList = [
          ...(cachedArtistFollowing.current as FanResponse[]),
        ].map((item) => ({
          ...item,
          isFollowing: item.id === id ? false : item.isFollowing,
        }));
        cachedArtistFollowing.current = nextList;
        setArtistFollowing({
          data: nextList,
          count: artistFollowing.count,
        });
      },
    });

  const { mutate: followArtistMutate, isLoading: isFollowing } = useMutation({
    mutationFn: (id: string) =>
      followArtist(id, NotificationType.SMS, chatGroupId),
    onSuccess: async (_, id) => {
      const nextList = [
        ...(cachedArtistFollowing.current as FanResponse[]),
      ].map((item) => ({
        ...item,
        isFollowing: item.id === id ? true : item.isFollowing,
      }));
      setCurrentArtist(null);
      cachedArtistFollowing.current = nextList;
      setArtistFollowing({
        data: nextList,
        count: artistFollowing.count,
      });
    },
  });

  const artistFollowingLength = artistFollowing.data.length;

  const hasMore = artistFollowingLength < (artistFollowing.count as number);

  const isEmpty = !(isLoading || isFetching) && !artistFollowingLength;

  const handleFollow = useCallback(async (artist: FanResponse) => {
    try {
      setCurrentArtist(artist);
      if (artist.isFollowing) {
        await unFollowArtistMutate(artist.id);
        return;
      }
      await followArtistMutate(artist.id);
    } catch (error) {
      showErrorToaster(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFollowCallback = (updatedRecord: FanResponse) => {
    if (!currentArtist) {
      setParams({
        page: 1,
        pageSize: 8,
      });
      setArtistFollowing({
        data: [],
        count: 0,
      });
      cachedArtistFollowing.current = [];
      refetch();
      return;
    }
    const nextList = [...(cachedArtistFollowing.current as FanResponse[])];
    const index = nextList.findIndex(
      (record) => record.id === updatedRecord.id,
    );
    nextList[index] = updatedRecord;
    cachedArtistFollowing.current = nextList;
    setArtistFollowing({
      data: nextList,
      count: artistFollowing.count,
    });
  };

  const handleLoadMore = useCallback(() => {
    setParams((prev) => ({ ...prev, page: prev.page + 1 }));
  }, []);

  return (
    <Container maxWidth="lg" sx={{ pt: 5, pb: '100px' }}>
      <ArtistFollowingToolbar
        length={artistFollowingLength}
        onSearch={(value) => {
          if (params.search === value) return;
          setParams({
            page: 1,
            pageSize: 8,
            ...(value && value !== '-1' && { search: value }),
          });
          if (value || value === '-1') {
            setArtistFollowing({
              data: [],
              count: 0,
            });
            cachedArtistFollowing.current = [];
            refetch();
          }
        }}
        onNotifyAll={(event) => {
          setCurrentArtist(null);
          popoverFollow.onOpen(event);
        }}
      />
      <ArtistFollowingList
        hasMore={hasMore}
        isEmpty={isEmpty}
        loading={isLoading || isFetching}
        processing={isUnFollowing || isFollowing}
        list={artistFollowing?.data as FanResponse[]}
        currentArtist={currentArtist}
        onLoadMore={handleLoadMore}
        onFollow={handleFollow}
        onNotify={(event, artist) => {
          setCurrentArtist(artist);
          popoverFollow.onOpen(event);
        }}
      />
      <FollowPopover
        currentArtist={currentArtist}
        open={popoverFollow.open}
        onClose={popoverFollow.onClose}
        onFollowCallback={handleFollowCallback}
      />
    </Container>
  );
};

export default ArtistFollowing;
