import React from 'react';

import { Box } from '@mui/material';

import PartnershipProgramModal from '~components/organisms/popup/partnership-program-modal';
import { useBoolean } from '~hooks/useBoolean';

const ArtistNote = () => {
  const { value, onFalse, onTrue } = useBoolean(false);
  return (
    <>
      <Box component="span">
        To become an Ursa Live Artist Partner, please submit video of yourself
        performing 30-60 seconds of a song. If you have backing tracks they must
        be run through a DAW. Alternatively, you can book a FREE session with an
        Ursa Live Producer to be approved or to receive help with your setup.
        <Box
          component="span"
          onClick={onTrue}
          sx={(theme) => ({
            color: theme.palette.action.active,
            cursor: 'pointer',
          })}
        >
          (more info)
        </Box>
      </Box>
      {value && (
        <PartnershipProgramModal
          onClose={onFalse}
          open={true}
          showApplySection={false}
        />
      )}
    </>
  );
};

export default ArtistNote;
