import { styled } from '@mui/material';
import Avatar from '~components/organisms/avatar';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  border: `6px solid ${theme.palette.warning.main}`,
  width: 156,
  height: 156,
  [theme.breakpoints.up('sm')]: {
    width: 252,
    height: 252,
  },
}));

const EndLiveAvatar = ({ username }: { username: string }) => (
  <StyledAvatar username={username} />
);

export default EndLiveAvatar;
