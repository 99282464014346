import Avatar from '../avatar';
import { alpha, Box, Stack, styled, Typography } from '@mui/material';

const StyledRoot = styled(Box)(() => ({
  position: 'absolute',
  left: 8,
  bottom: 16,
}));

const StyledContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.grey[800], 0.7),
  borderRadius: theme.spacing(5),
  padding: `${theme.spacing(1.25)} ${theme.spacing(2.5)}`,
}));

type Props = {
  open?: boolean;
  username: string;
};

const StreamDeclineInvite = ({ open, username }: Props) => {
  if (!open) return null;
  return (
    <StyledRoot>
      <StyledContainer spacing={1} direction="row" alignItems={'center'}>
        <Box sx={{ width: 32, height: 32 }}>
          <Avatar username={username} />
        </Box>
        <Typography>
          {username}{' '}
          <Typography component={'span'} color={'error.main'}>
            declined
          </Typography>
        </Typography>
      </StyledContainer>
    </StyledRoot>
  );
};

export default StreamDeclineInvite;
