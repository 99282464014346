import FetchArtistInfo from '~components/HOC/FetchArtistInfo';
import UserLogin from '~components/pages/user/login';
import UpdateUserType from '~components/HOC/UpdateUserType';

const UserLoginPage = () => {
  return (
    <FetchArtistInfo>
      <UpdateUserType>
        <UserLogin />
      </UpdateUserType>
    </FetchArtistInfo>
  );
};

export default UserLoginPage;
