import React from 'react';
import { Link, styled } from '@mui/material';
import { AxiosError } from 'axios';
import get from 'lodash/get';
import { ModalWindowFunctions, PAYOUT_MODAL_WINDOWS } from '../types';
import SummaryModalWindow from './summary-modal-window';
import ConfirmationModalWindow from './confirmation-modal-window';
import { QueryStatus } from '@tanstack/react-query';
import { NotificationModalWindow, ErrorModalWindow } from '~components/atoms';
import { UnionPayoutMethods } from '../../payout-method/types';

export interface ModalWindowsProps extends ModalWindowFunctions {
  withdrawalAmount: number;
  makePayment: () => void;
  statusOfWithdrawal: QueryStatus;
  fee: number;
  activeMethod: UnionPayoutMethods;
  loading?: boolean;
  error?: AxiosError;
}

const ModalWindows = ({
  windowType,
  onClose,
  onOpen,
  withdrawalAmount,
  makePayment,
  fee,
  activeMethod,
  loading,
  error,
}: ModalWindowsProps) => {
  return (
    <>
      <SummaryModalWindow
        open={windowType === PAYOUT_MODAL_WINDOWS.SUMMARY}
        onOpen={onOpen}
        onClose={onClose}
        withdrawalAmount={withdrawalAmount}
        fee={fee}
        activeMethod={activeMethod}
      />
      <ConfirmationModalWindow
        open={windowType === PAYOUT_MODAL_WINDOWS.CONFIRMATION}
        onClose={onClose}
        makePayment={makePayment}
        loading={loading}
        withdrawalAmount={withdrawalAmount}
      />
      <NotificationModalWindow
        open={windowType === PAYOUT_MODAL_WINDOWS.SUCCESS_PAYMENT}
        onClose={onClose}
        titleOverImage={'Payout Request Submitted!'}
        description={
          <>
            {
              'Thank you for submitting your payout request.\nOur admin team will review your transaction shortly.\nPlease allow 1-3 business days for this process and contact'
            }
            <CustomLink href="mailto:support@ursalive.com">
              {' support@ursalive.com '}
            </CustomLink>
            {'if you have any questions.'}
          </>
        }
      />
      <ErrorModalWindow
        open={windowType === PAYOUT_MODAL_WINDOWS.ERROR_PAYMENT}
        onClose={onClose}
        title={'Payout Failed'}
        description={get(
          error,
          'response.data.message',
          'Something went wrong',
        )}
      />
    </>
  );
};

export default ModalWindows;

const CustomLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
