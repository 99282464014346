// components
import SEO from '~components/atoms/seo';
import UserView from '~components/pages/user';
import FetchArtistInfo from '~components/HOC/FetchArtistInfo';
import UpdateUserType from '~components/HOC/UpdateUserType';

const UserPage = () => {
  return (
    <>
      <SEO
        title="Ursa Live | Artist Live"
        imgUrl="https://ursalive.com/assets/images/logo-bg.png"
        imgAlt="Ursa Live"
        url="https://ursalive.com"
        description="Easy To Set Up, Highly Interactive, Revenue-Producing Livecasts. From Your Home, Direct To Your Fans."
      />
      <FetchArtistInfo>
        <UpdateUserType>
          <UserView />
        </UpdateUserType>
      </FetchArtistInfo>
    </>
  );
};
export default UserPage;
