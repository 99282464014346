export default {
  popup: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: 600 },
    border: '1px solid',
    borderColor: 'white',
    backgroundColor: 'black',
    p: 4,
    maxHeight: '80%',
    borderRadius: 5,
  },
};
