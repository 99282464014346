import { createContext, ReactNode, useEffect, useState } from 'react';
import { AuthProviderType } from '~types';
import { authProvider } from './dataAuthProvider';
import { useGlobalDispatch } from '~utils/container';
import { getUserInfo } from '~api';
import { USER_TYPE } from '~utils/constants';

const defaultProvider: AuthProviderType = {
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  checkAuth: () => Promise.resolve(),
  getToken: () => null,
};

export const AuthContext = createContext<AuthProviderType>(defaultProvider);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isCheckedAuth, setIsCheckedAuth] = useState(false);
  const dispatch = useGlobalDispatch();
  useEffect(() => {
    authProvider
      .checkAuth()
      .then(async () => {
        const userInfo = await getUserInfo();
        const {
          name,
          type,
          displayUsername,
          imageUrl,
          username,
          attendees,
          id,
          walletBalance = 0,
          cashIn = 0,
          cashOut = 0,
          showLiveCoinPopup,
          customerId = '',
          email,
          phone,
          genre,
          description,
          outsideLinks,
          isPartner,
          onboardingInfoStep,
          showAddVideoToProfilePopup,
        } = userInfo;
        if (type === USER_TYPE.ARTIST) {
          dispatch({
            type: 'config',
            payload: {
              current_attendies: attendees,
            },
          });
        }

        dispatch({
          type: 'user',
          payload: {
            name,
            type,
            originalType: type,
            displayUsername,
            imageUrl,
            username,
            attendees,
            id,
            is_following: false,
            walletBalanceFan: walletBalance,
            walletBalanceInUsd: walletBalance / 10,
            cashIn,
            cashOut,
            customer_id: customerId,
            email,
            showLiveCoinPopup,
            phone,
            genre,
            description,
            outsideLinks,
            isPartner,
            onBoardingInfoStep: onboardingInfoStep,
            showAddVideoToProfilePopup,
          },
        });
      })
      .catch(() => {
        dispatch({ type: 'reset' });
      })
      .finally(() => setIsCheckedAuth(true));
  }, [dispatch]);
  if (!isCheckedAuth) {
    return null;
  }

  return (
    <AuthContext.Provider value={authProvider}>{children}</AuthContext.Provider>
  );
};
