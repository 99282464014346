import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { EventBanner } from '~types/banner-events';

interface SliderContentProps {
  eventBanner: EventBanner;
}

const SliderContent = ({
  eventBanner: { header, subHeader, timeInfo, description },
}: SliderContentProps) => {
  return (
    <Wrapper>
      <Label>{header}</Label>
      <Title>{subHeader}</Title>
      {timeInfo && <Date>{timeInfo}</Date>}
      {description && <Description>{description}</Description>}
    </Wrapper>
  );
};

export default SliderContent;

const Wrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  color: 'black',
  padding: '24px 20px',
  [theme.breakpoints.up('md')]: {
    padding: '24px 80px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '24px 150px',
  },
}));

const Date = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  textShadow:
    '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,1px 1px 0 #000',
  fontSize: 10,
  lineHeight: '14px',
  color: theme.palette.text.primary,
  padding: '12px 0',
  [theme.breakpoints.up('md')]: {
    padding: '24px 0',
    width: '50%',
    fontSize: 14,
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  textShadow:
    '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,1px 1px 0 #000',
  fontSize: 10,
  color: theme.palette.text.primary,
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
}));

const Label = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  background: 'linear-gradient(180deg, #CE7CAC 0%, #7473B6 100%)',
  fontSize: 12,
  lineHeight: '14px',
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
  fontWeight: 700,
  padding: '4px 8px',
  borderRadius: 8,
  marginBottom: 8,
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    padding: '0 27px',
    fontSize: 48,
    lineHeight: '56px',
    marginBottom: 16,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: 14,
  lineHeight: '16px',
  color: theme.palette.text.primary,
  textShadow:
    '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,1px 1px 0 #000',
  [theme.breakpoints.up('md')]: {
    fontSize: 43,
    width: '85%',
    lineHeight: '50px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '85%',
  },
}));
