import { useEffect, useRef, useState } from 'react';
//@mui
import {
  styled,
  Stack,
  Typography,
  Button,
  Box,
  IconButton,
  Grow,
  TextField,
  InputAdornment,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// hooks
import { useBoolean } from '~hooks/useBoolean';
import useBreakpoints from '~hooks/useBreakpoints';

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  position: 'relative',
  textAlign: 'center',
  ':after': {
    content: "''",
    position: 'absolute',
    bottom: -8,
    left: 0,
    height: 4,
    width: 100,
    backgroundColor: theme.palette.action.active,
    [theme.breakpoints.up('md')]: {
      left: 0,
      bottom: -14,
      height: 8,
    },
  },
  [theme.breakpoints.up('xs')]: {
    fontSize: theme.typography.h3.fontSize,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.h3.fontSize,
  },
}));

type Props = {
  length: number;
  onSearch: (search: string) => void;
  onNotifyAll: (event: React.MouseEvent<HTMLElement>) => void;
};

const ArtistFollowingToolbar = ({ onNotifyAll, onSearch, length }: Props) => {
  const { value, onToggle } = useBoolean();

  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const { isMd } = useBreakpoints();

  const handleKeyDown = async (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault();
      if (searchValue !== '') {
        onSearch(searchValue as string);
      }
    }
  };

  useEffect(() => {
    if (value) {
      inputRef.current?.focus();
    }
  }, [value]);

  return (
    <Box mb={2}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={3}
      >
        <Title>Artists I Follow</Title>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            onClick={() => {
              onToggle();
              if (value) {
                onSearch('-1');
                setSearchValue('');
              }
            }}
          >
            {value ? (
              <CloseIcon />
            ) : (
              <SearchIcon
                sx={{
                  color: 'grey.500',
                }}
              />
            )}
          </IconButton>
          <Box>
            {isMd ? (
              <Button
                variant="contained"
                color="warning"
                startIcon={<NotificationsIcon />}
                sx={{ textTransform: 'unset', py: 1.25, px: 2.75 }}
                onClick={onNotifyAll}
                disabled={length < 1}
              >
                Set All Notification Preferences
              </Button>
            ) : (
              <IconButton
                sx={{
                  bgcolor: (theme) =>
                    `${theme.palette.warning.main} !important`,
                  borderRadius: 2,
                }}
                onClick={onNotifyAll}
                disabled={length < 1}
              >
                <NotificationsIcon sx={{ color: 'black', fontSize: 22 }} />
              </IconButton>
            )}
          </Box>
        </Stack>
      </Stack>
      <Grow in={value}>
        <TextField
          ref={inputRef}
          size="small"
          fullWidth
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter an artist name..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  onClick={() => {
                    if (searchValue !== '') {
                      onSearch(searchValue as string);
                    }
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ paddingRight: 0 }}
        />
      </Grow>
    </Box>
  );
};

export default ArtistFollowingToolbar;
