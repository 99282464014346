import { useCallback, useState } from 'react';
import { Area, Point } from 'react-easy-crop/types';
import canvasSize from 'canvas-size';
const initialState = {
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 16 / 9,
  rotation: 0,
  imageSrc: '',
  fileName: '',
};

interface CropperProps {
  aspect: number;
}

const useCropper = (cropperProps?: CropperProps) => {
  const [initialCroppedAreaPixels] = useState({});
  const [state, setState] = useState({ ...initialState, ...cropperProps });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const { rotation, imageSrc, fileName } = state;

  const resetState = () => {
    setState(initialState);
  };

  const onCropChange = (value: Point) => {
    setState((prevState) => ({ ...prevState, crop: value }));
  };

  const onCropComplete = useCallback(
    (_croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const onZoomChange = (value: number) => {
    setState((prevState) => ({ ...prevState, zoom: value }));
  };

  const createImage = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'same-origin'); // need
      image.src = url;
    });
  };

  const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
  };

  const getCroppedImg = async (): Promise<File> => {
    if (!croppedAreaPixels) return Promise.reject();
    const image = await createImage(imageSrc);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return Promise.reject();

    const maxSize = Math.max(image.width, image.height);
    let safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
    let maxHeight = 0;

    if (window.innerWidth <= 900) {
      maxHeight = 4096;
    } else {
      canvasSize.maxArea({
        onError: () => console.log('Error on Canvas Test'),
        onSuccess(_width, height) {
          maxHeight = height;
        },
      });
    }

    if (maxHeight > 0 && safeArea > maxHeight) {
      safeArea *= maxHeight / safeArea;
    }

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - croppedAreaPixels.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - croppedAreaPixels.y),
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        if (!file) {
          reject(new Error('Canvas is empty'));
          return;
        }
        const newFile = new File([file], fileName, {
          lastModified: Date.now(),
          type: 'image/jpeg',
        });
        resolve(newFile);
      }, 'image/jpeg');
    });
  };

  return {
    resetState,
    initialCroppedAreaPixels,
    state,
    croppedAreaPixels,
    getCroppedImg,
    onCropChange,
    onCropComplete,
    onZoomChange,
    setState,
  };
};
export default useCropper;
