import React, { useState } from 'react';

import { useGlobalState } from '~utils';
import TipEndLiveModal from './tip-end-live-modal';
import SeeYouSoonModal from './see-you-soon-modal';
import FollowEndLiveModal from './follow-end-live-modal';
import SurveyEndLiveModal from '../survey-end-live-modal';
import { getFanFeedbackUrl } from '~utils/configUtils';

const enum ArtistEndLiveStep {
  SEE_YOU_SOON,
  TIP_END_LIVE,
  FOLLOW,
  SURVEY,
}

interface Props {
  onClose: () => void;
}

const ArtistEndLiveModal = ({ onClose }: Props) => {
  const globalState = useGlobalState();
  const {
    artist: { name, displayUsername, username } = {},
    user: { is_following },
  } = globalState;

  const artistName = name || displayUsername || username || '';

  const [step, setStep] = useState<ArtistEndLiveStep>(
    ArtistEndLiveStep.SEE_YOU_SOON,
  );
  const goToTip = () => setStep(ArtistEndLiveStep.TIP_END_LIVE);
  const goToFollow = () => setStep(ArtistEndLiveStep.FOLLOW);
  const goFanSurvey = () => setStep(ArtistEndLiveStep.SURVEY);

  const onTipNext = () => {
    if (is_following) {
      goFanSurvey();
    } else {
      goToFollow();
    }
  };

  const onFollow = async (callback?: () => void) => {
    callback && callback();
  };

  const onSurvey = async () => {
    const feedbackUrl = getFanFeedbackUrl();
    window.open(feedbackUrl, '_blank');
    onClose();
  };

  return (
    <>
      {step === ArtistEndLiveStep.SEE_YOU_SOON && (
        <SeeYouSoonModal
          open={true}
          onClose={() => undefined}
          onNext={goToTip}
        />
      )}
      {step === ArtistEndLiveStep.TIP_END_LIVE && (
        <TipEndLiveModal
          open={true}
          onClose={onClose}
          onNext={onTipNext}
          artistName={artistName}
          artistUserName={username || ''}
        />
      )}
      {step === ArtistEndLiveStep.FOLLOW && (
        <FollowEndLiveModal
          open={true}
          onClose={onClose}
          onNext={goFanSurvey}
          artistName={artistName}
          artistUserName={username || ''}
          onFollow={onFollow}
        />
      )}
      {step === ArtistEndLiveStep.SURVEY && (
        <SurveyEndLiveModal
          title={'FAN SURVEY'}
          open={true}
          onSkip={onClose}
          onSurvey={onSurvey}
        />
      )}
    </>
  );
};

export default ArtistEndLiveModal;
