import { getActivity } from '~api/chats';
import { ChatRetrieveActivityResponse } from '~types/chats';

const fetchChatUserDetail = async (username: string, groupId: string) => {
  try {
    const res: ChatRetrieveActivityResponse = await getActivity(
      username,
      groupId,
    );
    return {
      ...res,
    };
  } catch (error) {
    console.log('fetchUserDetail error', error);
    const err = `Error in fetching ${username}'s details. `;
    console.log(err);
    // showErrorToaster(err);
    return { allowOnStage: false };
  }
};

export { fetchChatUserDetail };
