import React from 'react';
import { Button, LoadingButton, ModalWindow } from '~components/atoms';
import { ModalWindowFunctions } from '../types';
import { Box, styled, Typography } from '@mui/material';
import { ThinCrossSVG } from '~components/icons';
import { formatMoney } from '~utils/formatters';

interface Props extends Omit<ModalWindowFunctions, 'windowType' | 'onOpen'> {
  open: boolean;
  withdrawalAmount: number;
  makePayment: () => void;
  loading?: boolean;
}

const ConfirmationModalWindow = ({
  open,
  loading,
  withdrawalAmount,
  makePayment,
  onClose,
}: Props) => {
  const handleClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose();
  };

  return (
    <CustomModalWindow open={open} onClose={handleClose}>
      <>
        <Row sx={{ pb: 3, alignItems: 'center' }}>
          <Title>Confirm payout</Title>
          <ThinCrossSVG onClick={onClose} />
        </Row>
        <Wrapper>
          <ConfirmationQuestion>
            Are you sure you want to withdraw {formatMoney(withdrawalAmount)}{' '}
            from your account?
          </ConfirmationQuestion>
          <Buttons>
            <Button
              onClick={onClose}
              typeStyles={'cancelWithoutBorder'}
              sx={{ width: ' 50%' }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              sx={{ width: ' 50%' }}
              onClick={makePayment}
            >
              Confirm
            </LoadingButton>
          </Buttons>
        </Wrapper>
      </>
    </CustomModalWindow>
  );
};

export default ConfirmationModalWindow;

const CustomModalWindow = styled(ModalWindow)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: 24,
  borderRadius: 20,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.up('xs')]: {
    width: 400,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

Title.defaultProps = {
  variant: 'h5',
};

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

const Buttons = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
}));

const Row = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const ConfirmationQuestion = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.primary,
}));
