import * as yup from 'yup';
export const MINIMAL_VALUE_TO_WITHDRAWAL = 20;
const positiveNumber = /^[+]?([.]\d+|\d+([.]\d+)?)$/;
const twoDigitNumberAfterComma = /^\d+(\.\d{0,2})?$/;

export const withdrawalAmountSchema = yup.object({
  withdrawalAmount: yup
    .string()
    .matches(positiveNumber, {
      message: `Please enter at least $${MINIMAL_VALUE_TO_WITHDRAWAL}`,
    })
    .matches(twoDigitNumberAfterComma, {
      message: `Only 2 decimal places are allowed`,
    })
    .required('Withdraw amount is a required field')
    .typeError('Please enter a number'),
});
