import { SVGProps } from 'react';

function SocialYoutube(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 20C0.5 8.9543 9.4543 0 20.5 0C31.5457 0 40.5 8.9543 40.5 20C40.5 31.0457 31.5457 40 20.5 40C9.4543 40 0.5 31.0457 0.5 20Z"
        fill="#FF0000"
      />
      <path
        d="M30.721 15.061C30.4757 14.1185 29.7528 13.3763 28.8348 13.1244C27.1713 12.6666 20.5002 12.6666 20.5002 12.6666C20.5002 12.6666 13.829 12.6666 12.1654 13.1244C11.2474 13.3763 10.5245 14.1185 10.2792 15.061C9.8335 16.7692 9.8335 20.3333 9.8335 20.3333C9.8335 20.3333 9.8335 23.8973 10.2792 25.6056C10.5245 26.5481 11.2474 27.2903 12.1654 27.5423C13.829 28 20.5002 28 20.5002 28C20.5002 28 27.1713 28 28.8348 27.5423C29.7528 27.2903 30.4757 26.5481 30.721 25.6056C31.1668 23.8973 31.1668 20.3333 31.1668 20.3333C31.1668 20.3333 31.1668 16.7692 30.721 15.061Z"
        fill="white"
      />
      <path d="M18.5 24V17.3334L23.8333 20.6668L18.5 24Z" fill="#FF0000" />
    </svg>
  );
}

export default SocialYoutube;
