import * as React from 'react';
import { SVGProps } from 'react';

const CalendarSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={`M2.24999 15.583C1.87533 15.583 1.55933 15.4513 1.30199 15.188C1.04533 14.924 0.916992 14.6113 0.916992
       14.25V3.75001C0.916992 3.38868 1.04533 3.07635 1.30199 2.81301C1.55933 2.54901 1.87533 2.41701 2.24999
       2.41701H4.08299V0.312012H5.18799V2.41701H9.83299V0.312012H10.917V2.41701H12.75C13.1247 2.41701 13.4407 2.54901
       13.698 2.81301C13.9547 3.07635 14.083 3.38868 14.083 3.75001V14.25C14.083 14.6113 13.9547 14.924 13.698 15.188C13.4407
       15.4513 13.1247 15.583 12.75 15.583H2.24999ZM2.24999 14.5H12.75C12.8053 14.5 12.861 14.4723 12.917 14.417C12.9723 14.361
       13 14.3053 13 14.25V7.25001H1.99999V14.25C1.99999 14.3053 2.02766 14.361 2.08299 14.417C2.13899 14.4723 2.19466 14.5
       2.24999 14.5ZM1.99999 6.16701H13V3.75001C13 3.69468 12.9723 3.63901 12.917 3.58301C12.861 3.52768 12.8053 3.50001
       12.75 3.50001H2.24999C2.19466 3.50001 2.13899 3.52768 2.08299 3.58301C2.02766 3.63901 1.99999 3.69468 1.99999
       3.75001V6.16701ZM1.99999 6.16701V3.50001V6.16701ZM7.49999 10.062C7.31933 10.062 7.16666 9.99968 7.04199
       9.87501C6.91666 9.75035 6.85399 9.59768 6.85399 9.41701C6.85399 9.23634 6.91666 9.08001 7.04199 8.94801C7.16666
       8.81601 7.31933 8.75001 7.49999 8.75001C7.68066 8.75001 7.83333 8.81601 7.95799 8.94801C8.08333 9.08001 8.14599
       9.22934 8.14599 9.39601C8.14599 9.57668 8.08333 9.73301 7.95799 9.86501C7.83333 9.99634 7.68066 10.062 7.49999
       10.062ZM4.24999 10.062C4.06933 10.062 3.91666 9.99968 3.79199 9.87501C3.66666 9.75035 3.60399 9.59768 3.60399
       9.41701C3.60399 9.23634 3.66666 9.08001 3.79199 8.94801C3.91666 8.81601 4.06933 8.75001 4.24999 8.75001C4.43066
       8.75001 4.58333 8.81601 4.70799 8.94801C4.83333 9.08001 4.89599 9.22934 4.89599 9.39601C4.89599 9.57668 4.83333 
       9.73301 4.70799 9.86501C4.58333 9.99634 4.43066 10.062 4.24999 10.062ZM10.75 10.062C10.5693 10.062 10.4167 9.99968 
       10.292 9.87501C10.1667 9.75035 10.104 9.59768 10.104 9.41701C10.104 9.23634 10.1667 9.08001 10.292 8.94801C10.4167 
       8.81601 10.5693 8.75001 10.75 8.75001C10.9307 8.75001 11.0833 8.81601 11.208 8.94801C11.3333 9.08001 11.396 9.22934 
       11.396 9.39601C11.396 9.57668 11.3333 9.73301 11.208 9.86501C11.0833 9.99634 10.9307 10.062 10.75 10.062ZM7.49999 13C7.31933 
       13 7.16666 12.9373 7.04199 12.812C6.91666 12.6873 6.85399 12.5347 6.85399 12.354C6.85399 12.1733 6.91666 12.017 7.04199 
       11.885C7.16666 11.7537 7.31933 11.688 7.49999 11.688C7.68066 11.688 7.83333 11.7537 7.95799 11.885C8.08333 12.017 8.14599 
       12.1663 8.14599 12.333C8.14599 12.5137 8.08333 12.67 7.95799 12.802C7.83333 12.934 7.68066 13 7.49999 13ZM4.24999 13C4.06933 
       13 3.91666 12.9373 3.79199 12.812C3.66666 12.6873 3.60399 12.5347 3.60399 12.354C3.60399 12.1733 3.66666 12.017 3.79199 
       11.885C3.91666 11.7537 4.06933 11.688 4.24999 11.688C4.43066 11.688 4.58333 11.7537 4.70799 11.885C4.83333 12.017 4.89599 
       12.1663 4.89599 12.333C4.89599 12.5137 4.83333 12.67 4.70799 12.802C4.58333 12.934 4.43066 13 4.24999 13ZM10.75 13C10.5693 
       13 10.4167 12.9373 10.292 12.812C10.1667 12.6873 10.104 12.5347 10.104 12.354C10.104 12.1733 10.1667 12.017 10.292 11.885C10.4167
        11.7537 10.5693 11.688 10.75 11.688C10.9307 11.688 11.0833 11.7537 11.208 11.885C11.3333 12.017 11.396 12.1663 11.396 
        12.333C11.396 12.5137 11.3333 12.67 11.208 12.802C11.0833 12.934 10.9307 13 10.75 13Z`}
      fill="white"
    />
  </svg>
);

export default CalendarSVG;
