import React from 'react';
import {
  Box,
  Typography,
  InputBase,
  InputBaseProps,
  styled,
} from '@mui/material';
import { Label } from '~components/atoms';
import { ExclamationMarkSVG } from '~components/icons';

interface InputProps extends InputBaseProps {
  label?: React.ReactNode;
  description?: React.ReactNode;
  errorMessage?: string;
  required?: boolean;
}

const Input = ({
  label,
  description,
  required = false,
  errorMessage,
  className,
  ...rest
}: InputProps) => {
  return (
    <Box sx={{ width: '100%' }} className={className}>
      {label && <Label required={required}> {label}</Label>}
      <CustomInput {...rest}></CustomInput>
      {!errorMessage && description && (
        <CustomDescription> {description}</CustomDescription>
      )}
      {errorMessage && (
        <ErrorMessage>
          <ExclamationMarkSVG />
          {errorMessage}
        </ErrorMessage>
      )}
    </Box>
  );
};

export default Input;

const CustomInput = styled(InputBase)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: 4,
  color: theme.palette.text.primary,
  width: '100%',
  fontSize: theme.typography.subtitle1.fontSize,
  '.MuiInputBase-input': {
    padding: '10px 20px',
  },
  '&.Mui-error': {
    border: `2px solid ${theme.palette.error.main}`,
  },
}));

const CustomDescription = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.subtitle2.fontSize,
  color: theme.palette.text.primary,
  paddingTop: 4,
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  marginTop: 8,
  display: 'flex',
  gap: 6,
  color: theme.palette.error.main,
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 500,
}));
