import React, { useEffect, useCallback } from 'react';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import { Stack, Typography, Button, alpha } from '@mui/material';

import { POPUP_TYPE, PopupTypeValues } from '~utils/constants';
import { useBoolean } from '~hooks/useBoolean';
import { useTriggerTimeout } from '~components/custom-hook';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { checkTipInEvent } from '~api/transaction';

interface Props {
  event: string;
}

const SHOWING_TIP_REMINDER_DURATION = 30000;
const SHOWING_TIP_TRIGGER_DURATION = 7 * 60000;
const StreamTipReminder = ({ event }: Props) => {
  const { onFalse, onTrue, value } = useBoolean(false);

  const { startTimeout } = useTriggerTimeout(() => {
    onFalse();
  }, SHOWING_TIP_REMINDER_DURATION);
  const { user, artist } = useGlobalState();

  const { name, username: artistUsername } = artist;
  const { walletBalanceFan } = user;

  const { showBuyLiveCoinPopup } = useDispatchPopup();
  const dispatch = useGlobalDispatch();

  const handleTogglePopup = (popup: PopupTypeValues) => {
    dispatch({
      type: 'app',
      payload: { popup },
    });
  };

  const checkIfUserTippedInEvent = useCallback(async () => {
    if (!user.id) {
      return false;
    }
    const { tipped } = await checkTipInEvent(event, user.id);
    if (tipped) {
      dispatch({
        type: 'add_tipped_event',
        payload: event,
      });
    }
    return tipped;
  }, [dispatch, event, user.id]);

  const handleTip = async () => {
    if (!user.id) {
      handleTogglePopup(POPUP_TYPE.LOGIN);
      return;
    }

    if (walletBalanceFan > 0) {
      handleTogglePopup(POPUP_TYPE.TIP);
    } else {
      showBuyLiveCoinPopup();
    }
  };

  // Run on first fetch
  useEffect(() => {
    checkIfUserTippedInEvent();
  }, [checkIfUserTippedInEvent]);

  useEffect(() => {
    const interval = setInterval(() => {
      onTrue();
      startTimeout();
    }, SHOWING_TIP_TRIGGER_DURATION);
    return () => clearInterval(interval);
  }, [onTrue, startTimeout]);

  if (!value) {
    return <></>;
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems={'center'}
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.grey[800], 0.7),
        borderRadius: (theme) => theme.spacing(5),
        py: (theme) => theme.spacing(1.25),
        px: (theme) => theme.spacing(2.5),
      }}
    >
      <Stack spacing={1} direction="row" alignItems={'center'}>
        <Typography variant="body2">
          Show{' '}
          <Typography component={'span'} fontWeight={'500'}>
            {name || artistUsername || ''}
          </Typography>{' '}
          some love with a tip!
        </Typography>
        <Button
          variant="outlined"
          color="warning"
          onClick={handleTip}
          size="small"
        >
          TIP
        </Button>
      </Stack>
    </Stack>
  );
};

export default StreamTipReminder;
