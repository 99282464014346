import { Stack, Typography } from '@mui/material';
import {
  DateTime,
  formatDateTime,
  getCurrentTime,
  isSameOrAfter,
} from '~utils/dateTimeUtils';
import { SHORT_DATE_TIME_WITH_TIME_ZONE } from '~utils/constants';
import useTimeCountdown from '~hooks/useTimeCountdown';

type Props = {
  orderNumber: number;
  endedAt: string;
};

const RewardCountDown = ({ orderNumber, endedAt }: Props) => {
  const [days, hours, minutes, seconds] = useTimeCountdown(endedAt);

  const isEnded = !isSameOrAfter(DateTime.fromISO(endedAt), getCurrentTime());

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={'space-between'}
      alignItems={'center'}
      spacing={{ xs: 0.5, md: 0 }}
    >
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        spacing={{ xs: 0, sm: 1 }}
        alignItems={'baseline'}
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
      >
        <Typography
          fontWeight={'500'}
          width={{ xs: '100%', sm: 'auto' }}
          color={'warning.main'}
          textAlign={'center'}
        >
          Campaign {orderNumber}
        </Typography>
        {endedAt && (
          <>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={isEnded ? 'error.main' : 'text.disabled'}
              pr={{ xs: 1, sm: 0 }}
            >
              This campaign {isEnded ? 'ended' : 'ends'} on
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={'500'}
              color={isEnded ? 'error.main' : 'white'}
            >
              {formatDateTime(endedAt, SHORT_DATE_TIME_WITH_TIME_ZONE)}
            </Typography>
          </>
        )}
      </Stack>
      {!isEnded && (
        <Typography fontWeight={'500'}>
          {days} {parseInt(days) > 1 ? 'days' : 'day'} {hours}:{minutes}:
          {seconds}
        </Typography>
      )}
    </Stack>
  );
};

export default RewardCountDown;
