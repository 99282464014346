import { useGlobalState } from '~utils/index';

export default function useIsBlocked(username: string) {
  const globalState = useGlobalState();

  const {
    app: { blockedUsername },
  } = globalState;

  const blocked = ((blockedUsername ?? []) as string[]).indexOf(username) > -1;

  return {
    blocked,
  };
}
