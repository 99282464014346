import React from 'react';
import { Container } from '~components/atoms';
import LandingPageWrapper from '~components/wrapppers/landing-page-wrapper';

const Collectibles = () => {
  return (
    <Container>
      <LandingPageWrapper>Collectibles</LandingPageWrapper>
    </Container>
  );
};

export default Collectibles;
