import { useEffect, useRef, useState } from 'react';
import { getNonce, requestAuthPhone } from '~api/user';
import { showErrorToaster } from '~utils/toasterNotification';

export const useGetOTP = () => {
  const RESEND_OTP_DELAY = 60;
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [resendOTPCountDown, setResendOTPCountDown] =
    useState(RESEND_OTP_DELAY);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (resendOTPCountDown === 0) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      setResendOTPCountDown(RESEND_OTP_DELAY);
    }
  }, [resendOTPCountDown]);

  const handleGetOTP = async (phone: string) => {
    try {
      const nonceResp = await getNonce();
      const { nonce } = nonceResp.data;
      await requestAuthPhone(phone, nonce);

      intervalRef.current = setInterval(() => {
        setResendOTPCountDown((prevState) => prevState - 1);
      }, 1000);

      setShowOTPInput(true);
    } catch (err: unknown) {
      showErrorToaster();
    }
  };

  return {
    handleGetOTP,
    showOTPInput,
    resendOTPCountDown,
    intervalRef,
    RESEND_OTP_DELAY,
  };
};
