import React from 'react';
import { SVGProps } from 'react';

const CircleExclamationMarkSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="32" cy="30.5" r="26.5" stroke="#EB5757" strokeWidth="4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5 18C34.5 16.6193 33.3807 15.5 32 15.5C30.6193 15.5 29.5 16.6193 29.5 18L29.5 34C29.5 35.3807 30.6193 36.5 32 36.5C33.3807 36.5 34.5 35.3807 34.5 34L34.5 18ZM29.5 43C29.5 44.3807 30.6193 45.5 32 45.5C33.3807 45.5 34.5 44.3807 34.5 43V42.5C34.5 41.1193 33.3807 40 32 40C30.6193 40 29.5 41.1193 29.5 42.5V43Z"
      fill="#EB5757"
    />
  </svg>
);

export default CircleExclamationMarkSVG;
