import {
  Container as ContainerMUI,
  styled,
  ContainerProps,
} from '@mui/material';
import React from 'react';

const Container = React.forwardRef(
  (
    { children, ...rest }: React.PropsWithChildren<ContainerProps>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <CustomContainer ref={ref} maxWidth={false} {...rest}>
        {children}
      </CustomContainer>
    );
  },
);
export default Container;

const CustomContainer = styled(ContainerMUI)(({ theme }) => ({
  width: '100%',
  maxWidth: 1440,
  minHeight: '100vh',
  background: theme.palette.grey[900],
  padding: 0,
  [theme.breakpoints.up('xs')]: {
    padding: 0,
  },
}));
