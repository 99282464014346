// utils
import { USER_TYPE, useGlobalState } from '~utils/index';
// types
import { ReportView } from '~types/event';
//
import FanEndLiveModal from './fan-end-live-modal';
import ArtistEndLiveModal from './artist-end-live-modal';

interface Props {
  reportData?: ReportView;
  onClose: () => void;
  onClear: (
    isClearChat: boolean,
    isClearQuestion: boolean,
    callback: () => void,
  ) => Promise<void>;
}

const EndLiveModal = ({ reportData, onClose, onClear }: Props) => {
  const {
    user: { type },
  } = useGlobalState();

  const isFan = type === USER_TYPE.FAN;

  return isFan ? (
    <FanEndLiveModal onClose={onClose} />
  ) : (
    <ArtistEndLiveModal
      reportData={reportData}
      onClose={onClose}
      onClear={onClear}
    />
  );
};

export default EndLiveModal;
