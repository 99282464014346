import React, { useState } from 'react';

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import BasePopup from '~components/organisms/popup/base-popup';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '~components/molecules';
import * as yup from 'yup';
import { VALID_PASSWORD } from '~utils/regex';
import { messages } from '~language/en';
import { CircleSuccessTickSVG, InfoIcon } from '~components/icons';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useMutation } from '@tanstack/react-query';
import { changePassword, ChangPasswordErrorCode } from '~api';
import { showErrorToaster } from '~utils/toasterNotification';
import IconPopover from '~components/molecules/icon-popover';
import { axiosRequest } from '~api/singleton-axios';

const enum FIELDS_NAME {
  PASSWORD = 'password',
  NEW_PASSWORD = 'newPassword',
  CONFIRM_PASSWORD = 'confirmPassword',
}

export type ChangePasswordInputs = {
  [key in FIELDS_NAME]: string;
};

const schema = yup.object({
  password: yup.string().required('Please enter your current password'),
  newPassword: yup
    .string()
    .required('Please enter your password')
    .matches(VALID_PASSWORD, messages.VALIDATIONS.INVALID_PASSWORD),
  confirmPassword: yup
    .string()
    .required('Please confirm your new password')
    .oneOf(
      [yup.ref('newPassword')],
      messages.VALIDATIONS.INVALID_CONFIRM_PASSWORD_NOT_MATCH,
    ),
});

const ChangePasswordModal = () => {
  const { hidePopup } = useDispatchPopup();
  const [successMsg, setSuccessMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const methods = useForm<ChangePasswordInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
  });
  const { handleSubmit, setError } = methods;

  const { isLoading, mutate: handleChangeEmail } = useMutation({
    mutationFn: (data: ChangePasswordInputs) =>
      changePassword(data.password, data.newPassword),
    onSuccess: () => {
      setSuccessMsg('Your password has been updated!');
      setTimeout(hidePopup, 3000);
    },
    onError: (error) => {
      let errorCode = '';
      let errorMessage = '';
      if (axiosRequest.isAxiosError(error)) {
        errorCode = error?.response?.data?.code;
        errorMessage = error?.response?.data?.message;
      }
      switch (errorCode) {
        case ChangPasswordErrorCode.ERR_CHANGE_PASSWORD_INCORRECT_CURRENT_PASSWORD:
          setError(FIELDS_NAME.PASSWORD, {
            type: 'custom',
            message: errorMessage,
          });
          break;
        default:
          showErrorToaster(error);
          return;
      }
    },
  });

  const onSubmit = async (data: ChangePasswordInputs) => {
    await handleChangeEmail(data);
  };

  return (
    <ChangePasswordModalWindow
      open={true}
      onClose={hidePopup}
      title="Change Password"
    >
      <Box sx={{ marginTop: 2 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          {successMsg ? (
            <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
              <CircleSuccessTickSVG />
              <Typography variant="body1">{successMsg}</Typography>
            </Stack>
          ) : (
            <>
              <Stack spacing={2}>
                <RHFTextField
                  labelColor="white"
                  label="Current Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your password"
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconPopover text="Must contain 1 uppercase, lowercase and number. Must have minimum 8 characters.">
                          <InfoIcon />
                        </IconPopover>
                        <IconButton
                          edge="end"
                          onClick={toggleShowPassword}
                          onMouseDown={toggleShowPassword}
                        >
                          {showPassword ? (
                            <Visibility
                              sx={{ width: '20px', color: 'white' }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{
                                width: '20px',
                                color: 'rgba(255, 255, 255, 0.3)',
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <RHFTextField
                  labelColor="white"
                  label="New Password"
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  placeholder="Enter your new password"
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={toggleShowNewPassword}
                          onMouseDown={toggleShowNewPassword}
                        >
                          {showNewPassword ? (
                            <Visibility
                              sx={{ width: '20px', color: 'white' }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{
                                width: '20px',
                                color: 'rgba(255, 255, 255, 0.3)',
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <RHFTextField
                  labelColor="white"
                  label="Confirm New Password"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Retype new password"
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={toggleShowConfirmPassword}
                          onMouseDown={toggleShowConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <Visibility
                              sx={{ width: '20px', color: 'white' }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{
                                width: '20px',
                                color: 'rgba(255, 255, 255, 0.3)',
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                  <Button
                    fullWidth
                    type="submit"
                    color="warning"
                    variant="contained"
                    disabled={isLoading}
                    sx={{ padding: 1.5, marginTop: 2 }}
                  >
                    SAVE NEW PASSWORD
                  </Button>
                </Stack>
              </Stack>
            </>
          )}
        </FormProvider>
      </Box>
    </ChangePasswordModalWindow>
  );
};

export default ChangePasswordModal;

const ChangePasswordModalWindow = styled(BasePopup)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '90%',
    height: 'fit-content',
  },
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(7.5)}`,
    width: 650,
  },
}));
