import { useState, useRef, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Stack, styled } from '@mui/material';
import { NotificationModalWindow } from '~components/atoms';
import { QUERY_KEYS } from '~constants/query-keys';
import { RewardCampaign } from '~types/reward';
import { useGlobalDispatch, useGlobalState, USER_TYPE } from '~utils/index';
import { getRewardPageData } from '~api/reward';
import ActiveCampaign from './active-campaign';
import CreateCampaign from './create-campaign';
import PreviousCampaigns from './previous-campaigns';
import UpcomingCampaigns, {
  UpcomingCampaignsRefProps,
} from './upcoming-campaigns';
import { NotificationModalData } from './type';

const initialModal = {
  icon: undefined,
  open: false,
  title: '',
  description: '',
} as NotificationModalData;

const Container = styled(Stack)(() => ({
  width: '100%',
}));

Container.defaultProps = {
  spacing: {
    xs: 4,
    sm: 40,
  },
};

const Rewards = () => {
  const dispatch = useGlobalDispatch();
  const upcomingCampaignsRef = useRef<UpcomingCampaignsRefProps>(null);
  const [modalData, setModalData] = useState(initialModal);
  const globalState = useGlobalState();
  const {
    artist: { id: artistId },
    user: { id: userId, type },
    app: {
      activeCampaign,
      upcomingCampaigns,
      previousCampaigns,
      totalPreviousCampaign,
      totalUpcomingCampaign,
    },
  } = globalState as {
    app: Record<string, any>;
    artist: { id: string };
    user: { id: string; type: string };
  };

  const fetchingArtistId = useMemo(() => {
    return type === USER_TYPE.ARTIST ? userId : artistId;
  }, [artistId, type, userId]);

  const { refetch: refetchReward } = useQuery({
    queryKey: [QUERY_KEYS.ARTIST_REWARDS],
    queryFn: () => getRewardPageData(fetchingArtistId),
    enabled: !!fetchingArtistId,
    onSuccess: (data) => {
      dispatch({
        type: 'app',
        payload: {
          activeCampaign: data.activeCampaign,
          upcomingCampaigns: data.upcomingCampaigns ?? [],
          previousCampaigns: data.previousCampaigns ?? [],
          totalPreviousCampaign: data.totalPreviousCampaign,
          totalUpcomingCampaign: data.totalUpcomingCampaign,
        },
      });
    },
  });

  const putToUpcomingCampaigns = (campaign: RewardCampaign) => {
    const upcoming = [...upcomingCampaigns];
    upcoming.push(campaign);

    dispatch({
      type: 'app',
      payload: {
        upcomingCampaigns: upcoming,
      },
    });
  };

  const refetchCurrentUpcomingPage = async () => {
    if (upcomingCampaignsRef.current) {
      await upcomingCampaignsRef.current.refetchCurrentPage();
    } else {
      refetchReward();
    }
  };

  return (
    <Stack
      width={'100%'}
      spacing={{
        xs: 0.5,
        sm: 5,
      }}
    >
      <CreateCampaign
        refetchReward={refetchReward}
        putToUpcoming={putToUpcomingCampaigns}
        showNotificationModal={setModalData}
        refetchCurrentUpcomingPage={refetchCurrentUpcomingPage}
      />
      {activeCampaign && (
        <ActiveCampaign
          refetchReward={refetchReward}
          campaign={activeCampaign}
          showNotificationModal={setModalData}
        />
      )}

      {upcomingCampaigns?.length > 0 && (
        <UpcomingCampaigns
          ref={upcomingCampaignsRef}
          initCampaigns={upcomingCampaigns}
          showNotificationModal={setModalData}
          total={totalUpcomingCampaign}
          refetchReward={refetchReward}
        />
      )}

      {(previousCampaigns ?? []).length > 0 && (
        <PreviousCampaigns
          campaigns={previousCampaigns as RewardCampaign[]}
          total={totalPreviousCampaign}
        />
      )}

      <NotificationModalWindow
        {...modalData}
        onClose={() => setModalData(initialModal)}
      />
    </Stack>
  );
};

export default Rewards;
