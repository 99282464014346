import React from 'react';
import {
  Button as ButtonBaseMUI,
  ButtonProps as ButtonPropsMUI,
  styled,
} from '@mui/material';

type TypeStyles =
  | 'big'
  | 'cancel'
  | 'cancelWithoutBorder'
  | 'bigWithoutBorder'
  | 'icon';

interface ButtonProps extends ButtonPropsMUI {
  children: React.ReactNode;
  width?: React.CSSProperties['width'];
  typeStyles?: TypeStyles;
}

const Button = ({
  children,
  typeStyles,
  width = 'auto',
  ...props
}: ButtonProps) => {
  return (
    <CustomButton width={width} typestyles={typeStyles} {...props}>
      {children}
    </CustomButton>
  );
};

export default Button;

const CustomButton = styled(ButtonBaseMUI)<{
  width: React.CSSProperties['width'];
  typestyles: TypeStyles | undefined;
}>(({ theme, width, typestyles }) => {
  const TYPE_STYLES: Record<
    TypeStyles,
    React.CSSProperties | Record<string, unknown>
  > = {
    icon: {
      border: `1px solid ${theme.palette.action.active}`,
      background: 'transparent',
      color: theme.palette.action.active,
      padding: '4px 0px',
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        padding: '14px 20px',
      },
      ':hover': {
        border: `1px solid ${theme.palette.action.active}`,
      },
    },
    big: {
      padding: '8px 32px',
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        padding: '18px 40px',
      },
    },
    bigWithoutBorder: {
      border: `1px solid ${theme.palette.action.active}`,
      background: 'transparent',
      color: theme.palette.action.active,
      padding: '6px 32px',
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        padding: '16px 40px',
      },
      ':hover': {
        border: `1px solid ${theme.palette.action.active}`,
      },
    },
    cancel: {
      border: `4px solid ${theme.palette.action.active}`,
      background: 'transparent',
      color: theme.palette.action.active,
      ':hover': {
        background: 'transparent',
      },
    },
    cancelWithoutBorder: {
      background: 'transparent',
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: 500,
      color: theme.palette.text.primary,
      ':hover': {
        background: 'transparent',
      },
    },
  };

  const defaultStyles = {
    padding: '12px 44px',
    ':hover': {
      background: theme.palette.action.active,
    },
  };

  return {
    width,
    height: 'fit-content',
    background: theme.palette.action.active,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.subtitle2.fontSize,
    textTransform: 'capitalize',
    position: 'static',
    ...defaultStyles,
    ...(typestyles ? TYPE_STYLES[typestyles] : {}),
    '&.Mui-disabled': {
      color: theme.palette.text.secondary,
      opacity: 0.7,
    },
  };
});
