import { useFeatureFlag } from '~hooks/useFeatureFlag';
import { LiveCoinOptionType, PaymentType } from '~types';
import { FEATURE_FLAGS_TYPE } from './constants';

// Note- computed_amountIncluded Paypal fee (US - Domestic ) - 3.49% + 49¢ ,unit is dollar
// Note- computed_amountIncluded stripe fee - 2.9% + 30¢ ,unit is cent
export const APP_FEE = 0.99;

const OLD_LIVECOIN_OPTIONS: LiveCoinOptionType[] = [
  {
    id: 1,
    stripeProductId: 'LIVE_COIN_50',
    amount: 5,
    live_coins: 50,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 0.49,
      computedAmount: 648,
      displayAmount: '6.48',
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 0.72,
      computedAmount: 6.71,
      displayAmount: '6.71',
    },
  },
  {
    id: 2,
    stripeProductId: 'LIVE_COIN_100',
    amount: 10,
    live_coins: 100,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 0.64,
      computedAmount: 1163,
      displayAmount: '11.63',
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 0.91,
      computedAmount: 11.9,
      displayAmount: '11.90',
    },
  },
  {
    id: 3,
    stripeProductId: 'LIVE_COIN_200',
    amount: 20,
    live_coins: 200,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 0.94,
      computedAmount: 2193,
      displayAmount: '21.93',
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 1.27,
      computedAmount: 22.26,
      displayAmount: '22.26',
    },
  },
  {
    id: 4,
    stripeProductId: 'LIVE_COIN_300',
    amount: 30,
    live_coins: 300,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 1.23,
      computedAmount: 3222,
      displayAmount: '32.22',
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 1.63,
      computedAmount: 32.62,
      displayAmount: '32.62',
    },
  },
  {
    id: 5,
    stripeProductId: 'LIVE_COIN_500',
    amount: 50,
    live_coins: 500,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 1.83,
      computedAmount: 5282,
      displayAmount: '52.82',
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 2.35,
      computedAmount: 53.34,
      displayAmount: '53.34',
    },
  },
  {
    id: 6,
    stripeProductId: 'LIVE_COIN_1000',
    amount: 100,
    live_coins: 1000,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 3.33,
      computedAmount: 10432,
      displayAmount: '104.32',
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 4.16,
      computedAmount: 105.15,
      displayAmount: '105.15',
    },
  },
  {
    id: 7,
    stripeProductId: 'LIVE_COIN_2000',
    amount: 200,
    live_coins: 2000,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 6.31,
      computedAmount: 20730,
      displayAmount: '207.30',
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 7.78,
      computedAmount: 208.77,
      displayAmount: '208.77',
    },
  },
  {
    id: 8,
    stripeProductId: 'LIVE_COIN_3000',
    amount: 300,
    live_coins: 3000,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 9.3,
      computedAmount: 31029,
      displayAmount: '310.29',
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 11.39,
      computedAmount: 312.38,
      displayAmount: '312.38',
    },
  },
];

export const NEW_LIVECOIN_OPTIONS: LiveCoinOptionType[] = [
  {
    id: 1,
    stripeProductId: 'LIVE_COIN_50',
    amount: 5,
    live_coins: 50,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 0.49,
      computedAmount: 500,
      displayAmount: '5.00',
      productId: process.env.REACT_APP_PRODUCT_ID_LIVE_COIN_50,
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 0.72,
      computedAmount: 5,
      displayAmount: '5.00',
    },
  },
  {
    id: 2,
    stripeProductId: 'LIVE_COIN_100',
    amount: 10,
    live_coins: 100,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 0.64,
      computedAmount: 1000,
      displayAmount: '10.00',
      productId: process.env.REACT_APP_PRODUCT_ID_LIVE_COIN_100,
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 0.91,
      computedAmount: 10,
      displayAmount: '10.00',
    },
  },
  {
    id: 3,
    stripeProductId: 'LIVE_COIN_200',
    amount: 20,
    live_coins: 200,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 0.94,
      computedAmount: 2000,
      displayAmount: '20.00',
      productId: process.env.REACT_APP_PRODUCT_ID_LIVE_COIN_200,
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 1.27,
      computedAmount: 20,
      displayAmount: '20.00',
    },
  },
  {
    id: 4,
    stripeProductId: 'LIVE_COIN_300',
    amount: 30,
    live_coins: 300,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 1.23,
      computedAmount: 3000,
      displayAmount: '30.00',
      productId: process.env.REACT_APP_PRODUCT_ID_LIVE_COIN_300,
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 1.63,
      computedAmount: 30,
      displayAmount: '30.00',
    },
  },
  {
    id: 5,
    stripeProductId: 'LIVE_COIN_500',
    amount: 50,
    live_coins: 500,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 1.83,
      computedAmount: 5000,
      displayAmount: '50.00',
      productId: process.env.REACT_APP_PRODUCT_ID_LIVE_COIN_500,
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 2.35,
      computedAmount: 50,
      displayAmount: '50.00',
    },
  },
  {
    id: 6,
    stripeProductId: 'LIVE_COIN_1000',
    amount: 100,
    live_coins: 1000,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 3.33,
      computedAmount: 10000,
      displayAmount: '100.00',
      productId: process.env.REACT_APP_PRODUCT_ID_LIVE_COIN_1000,
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 4.16,
      computedAmount: 100,
      displayAmount: '100.00',
    },
  },
  {
    id: 7,
    stripeProductId: 'LIVE_COIN_2000',
    amount: 200,
    live_coins: 2000,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 6.31,
      computedAmount: 20000,
      displayAmount: '200.00',
      productId: process.env.REACT_APP_PRODUCT_ID_LIVE_COIN_2000,
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 7.78,
      computedAmount: 200,
      displayAmount: '200.00',
    },
  },
  {
    id: 8,
    stripeProductId: 'LIVE_COIN_3000',
    amount: 300,
    live_coins: 3000,
    [PaymentType.STRIPE]: {
      appFee: APP_FEE,
      paymentFee: 9.3,
      computedAmount: 30000,
      displayAmount: '300.00',
      productId: process.env.REACT_APP_PRODUCT_ID_LIVE_COIN_3000,
    },
    [PaymentType.PAYPAL]: {
      appFee: APP_FEE,
      paymentFee: 11.39,
      computedAmount: 300,
      displayAmount: '300.00',
    },
  },
];

export const useLiveCoinOptions: () => LiveCoinOptionType[] = () => {
  const { isEnable } = useFeatureFlag();
  const isEnableNewPayment = isEnable(
    FEATURE_FLAGS_TYPE.NEW_PAYMENT_FLOW_FOR_TAX,
  );
  return isEnableNewPayment ? NEW_LIVECOIN_OPTIONS : OLD_LIVECOIN_OPTIONS;
};
