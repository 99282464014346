import React, { useMemo } from 'react';
import {
  MobileTimePicker as TimePickerMui,
  MobileTimePickerProps as TimePickerMuiProps,
  PickersActionBar,
  PickersActionBarProps,
} from '@mui/x-date-pickers';
import { Box, styled, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import { Label } from '~components/atoms';
import { Theme } from '@mui/material/styles/createTheme';

interface DatePickerProps extends TimePickerMuiProps<Dayjs> {
  errorMessage?: string | null;
  showTimeZone?: boolean;
  placeholder?: string | null;
}

const TimePicker = ({
  label,
  errorMessage,
  placeholder,
  showTimeZone = false,
  value,
  ...rest
}: DatePickerProps) => {
  const formatTimeZone = useMemo(() => {
    if (!showTimeZone || !value) return;
    const date = new Date(value?.toISOString());
    const timeZoneOffsetInMinutes = date.getTimezoneOffset();
    const timeZoneOffsetInHours = timeZoneOffsetInMinutes / 60;
    return `GMT${timeZoneOffsetInHours < 0 ? '+' : '-'}${Math.abs(
      timeZoneOffsetInHours,
    )}`;
  }, [showTimeZone, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Wrapper>
        {label && <Label>{label}</Label>}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <TimePickerMui
            slots={{
              textField: CustomTimePicker,
              actionBar: CustomActionBar,
            }}
            slotProps={{
              textField: {
                placeholder: placeholder || 'Select time',
                error: !!errorMessage,
                helperText: errorMessage,
              },
              layout: {
                sx: CustomTimePickerWindow,
              },
            }}
            format={'hh:mm a'}
            value={value}
            {...rest}
          />
          {showTimeZone && formatTimeZone && (
            <TimeZone>{formatTimeZone}</TimeZone>
          )}
        </Box>
      </Wrapper>
    </LocalizationProvider>
  );
};

export default TimePicker;

const Wrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const CustomTimePickerWindow = (theme: Theme) => ({
  background: theme.palette.background.default,
  '.MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  '.MuiTimePickerToolbar-hourMinuteLabel': {
    'button span': {
      color: theme.palette.action.active,
    },
    span: {
      color: theme.palette.action.active,
    },
  },
  '.MuiTimePickerToolbar-ampmSelection': {
    '.Mui-selected': {
      color: theme.palette.action.active,
    },
  },
  '.MuiClock-clock': {
    fill: theme.palette.text.primary,
    '.Mui-selected': {
      color: theme.palette.text.secondary,
    },
  },
  '.MuiClockPointer-root': {
    backgroundColor: theme.palette.action.active,
  },
  '.MuiClockPointer-thumb': {
    backgroundColor: theme.palette.action.active,
    border: `16px solid ${theme.palette.action.active}`,
  },
  '.MuiClock-pin': {
    backgroundColor: theme.palette.action.active,
  },
  '.MuiTypography-overline': {
    display: 'none',
  },
  '.MuiTimePickerToolbar-ampmLabel': {
    '&:hover': {
      cursor: 'pointer',
    },
    '&.Mui-selected': {
      padding: '0px 5px',
      border: `1px solid ${theme.palette.action.active}`,
    },
  },
});

const CustomActionBar = styled((props: PickersActionBarProps) => (
  <PickersActionBar {...props} />
))(({ theme }) => ({
  '.MuiButton-root': {
    color: theme.palette.action.active,
  },
}));

const CustomTimePicker = styled(TextField)(({ theme }) => ({
  width: '100%',
  '.MuiInputBase-input': {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    background: theme.palette.primary.main,
    padding: '4px 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '8px 10px',
    },
  },
  '.MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
}));

const TimeZone = styled(Box)(({ theme }) => ({
  width: 100,
  background: theme.palette.primary.main,
  height: '100%',
  padding: '4px 10px',
  marginLeft: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  [theme.breakpoints.up('sm')]: {
    padding: '0 8px',
  },
}));
