import React from 'react';
import { DeleteModalWindow, NotificationModalWindow } from '~components/atoms';
import { TrashIcon } from '~components/icons';
import { ModalWindowType } from './type';
import { useTheme } from '@mui/material';

interface Props {
  windowType: ModalWindowType;
  closeModalWindow: () => void;
  deleteLivecast: () => void;
  disabledActionButton?: boolean;
}

const ModalWindowsLivecasts = ({
  windowType,
  closeModalWindow,
  deleteLivecast,
  disabledActionButton = false,
}: Props) => {
  const theme = useTheme();

  return (
    <>
      <NotificationModalWindow
        open={windowType === 'add'}
        onClose={closeModalWindow}
        title={'Livecast Added!'}
      />
      <NotificationModalWindow
        open={windowType === 'save'}
        onClose={closeModalWindow}
        title={'Changes saved!'}
        autoHideDuration={5000}
      />
      <NotificationModalWindow
        open={windowType === 'delete'}
        onClose={closeModalWindow}
        title={'Livecast Deleted'}
        icon={
          <TrashIcon
            fill={theme.palette.success.main}
            width={'56'}
            height={'56'}
          />
        }
      />
      <DeleteModalWindow
        open={windowType === 'deleteQuestion'}
        onClose={closeModalWindow}
        title={'Are you sure you want to\ndelete this livecast?'}
        onDelete={deleteLivecast}
        disabledButton={disabledActionButton}
      />
    </>
  );
};

export default ModalWindowsLivecasts;
