import * as React from 'react';
import { SVGProps } from 'react';

const TikTokSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="32"
    viewBox="0 0 29 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5104 12.6002V11.3521C11.0772 11.2908 10.6404 11.2589 10.2029 11.2568C4.85271 11.2568 0.5 15.6103 0.5 20.9605C0.5 24.2424 2.14023 27.1476 4.64237 28.9044C2.96698 27.1127 2.03555 24.751 2.0369 22.298C2.0369 17.024 6.26573 12.7234 11.5104 12.6002"
      fill="#00F2EA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7394 26.7289C14.1265 26.7289 16.0738 24.83 16.1625 22.4635L16.1705 1.33755H20.03C19.9476 0.896453 19.9059 0.448723 19.9054 0H14.6344L14.6256 21.1267C14.5377 23.4925 12.5896 25.3907 10.2033 25.3907C9.48679 25.3909 8.78113 25.2164 8.14746 24.8821C8.55659 25.4529 9.09579 25.9181 9.72039 26.2393C10.345 26.5605 11.0371 26.7283 11.7394 26.7289V26.7289ZM27.2396 8.50827V7.33413C25.8212 7.33547 24.4333 6.92287 23.246 6.14685C24.2869 7.3454 25.6885 8.17393 27.2403 8.50827"
      fill="#00F2EA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={`M23.2454 6.14623C22.0818 4.81488 21.441 3.10653 21.4417 1.33838H20.0294C20.2123 2.31567 20.5916 3.24568 21.1444 
      4.07209C21.6972 4.89851 22.4119 5.60413 23.2454 6.14623V6.14623ZM10.2026 16.5285C9.02782 16.5299 7.90155 16.9972 
      7.07082 17.8279C6.24015 18.6586 5.77288 19.7848 5.77148 20.9596C5.77228 21.7678 5.99388 22.5604 6.41242 23.2517C6.83095 
      23.943 7.43042 24.5068 8.14608 24.8822C7.60115 24.1307 7.30775 23.2262 7.30768 22.298C7.30882 21.123 7.77602 19.9966 
      8.60675 19.1657C9.43748 18.3348 10.5639 17.8674 11.7388 17.8661C12.1954 17.8661 12.633 17.9416 13.0463 
      18.0713V12.6896C12.6132 12.6282 12.1763 12.5964 11.7388 12.5943C11.6618 12.5943 11.5864 12.5987 11.5102 
      12.6002V16.7338C11.0873 16.5992 10.6464 16.53 10.2026 16.5285`}
      fill="#FF004F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.2395 8.50806V12.605C24.5057 12.605 21.9735 11.7307 19.9053 10.2465V20.9594C19.9053 26.3095 15.5533 30.6623 10.2031 30.6623C8.1356 30.6623 6.21833 30.01 4.64258 28.9033C5.54853 29.8803 6.64646 30.6596 7.86766 31.1925C9.08886 31.7253 10.4069 32.0001 11.7393 31.9998C17.0895 31.9998 21.4422 27.6471 21.4422 22.2977V11.5848C23.5788 13.1212 26.1447 13.9463 28.7763 13.9433V8.67079C28.2487 8.67079 27.7356 8.61359 27.2395 8.50732"
      fill="#FF004F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={`M19.9053 20.9596V10.2468C22.0418 11.7834 24.6077 12.6085 27.2395 12.6053V8.50837C25.6878 8.1737 24.2865 7.3449
        23.2459 6.14623C22.4124 5.60413 21.6976 4.89851 21.1448 4.07209C20.5921 3.24568 20.2127 2.31567 20.0299
        1.33838H16.1704L16.1623 22.4643C16.0737 24.8301 14.1263 26.7291 11.7393 26.7291C11.0369 26.7284 10.3449 26.5604 9.72027
        26.2393C9.09574 25.9182 8.55654 25.453 8.14734 24.8822C7.43154 24.5069 6.83187 23.9432 6.4132 23.2518C5.99454 22.5605
        5.7728 21.7679 5.772 20.9596C5.77334 19.7848 6.2406 18.6586 7.07134 17.8279C7.902 16.9972 9.02834 16.5299 10.2031
        16.5285C10.659 16.5285 11.0965 16.6033 11.5106 16.7338V12.6002C6.26594 12.7233 2.03711 17.024 2.03711 22.298C2.03711
        24.8484 3.028 27.1703 4.64258 28.9043C6.27027 30.0502 8.2126 30.6643 10.2031 30.6626C15.5533 30.6626 19.9053 26.3098
        19.9053 20.9596`}
      fill="white"
    />
  </svg>
);

export default TikTokSVG;
