import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useMemo, useState } from 'react';
import { useDebouncedCallback } from '~hooks/useDebouncedCallback';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { searchArist } from '~api';
import { Artist, ArtistSearchResponse } from '~types/artist';
import ArtistInfo from '~components/organisms/search-artist/artist';
import { Typography } from '@mui/material';
import ArtistsSearchModal from '~components/pages/home/artists-search-modal';

interface Option {
  value: string;
  label?: string;
  data?: Artist | undefined;
  onClick?: () => void;
  id?: string;
}

const LIMIT_ARTIST = 10;
const SearchArtist = () => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Artist[]>([]);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [showSearchPopup, setShowSearchPopup] = useState(false);

  const wrapperOptions: Option[] = useMemo(() => {
    let wrapperOptions: Option[] = options.map((option) => ({
      data: option,
      onClick: () => {
        window.location.href = `/${option.username}`;
      },
      value: inputValue,
    }));
    if (total >= LIMIT_ARTIST) {
      wrapperOptions = [
        ...wrapperOptions,
        {
          id: '00000',
          data: undefined,
          onClick: () => setShowSearchPopup(true),
          value: inputValue,
          label: 'View All Results',
        },
      ];
    }
    return wrapperOptions;
  }, [inputValue, options, total]);

  const onCloseSearchPopup = () => {
    setShowSearchPopup(false);
  };

  const { isFetching } = useQuery({
    queryKey: [QUERY_KEYS.LANDING_PAGE_BANNER_EVENTS, inputValue],
    queryFn: () => searchArist(inputValue, 0, LIMIT_ARTIST),
    onSuccess: (response: ArtistSearchResponse) => {
      const { data, total } = response;
      setOptions(data);
      setTotal(total);
    },
    enabled: !!inputValue,
  });

  const handleSearchArtist = useDebouncedCallback((inputValue: string) => {
    setInputValue(inputValue);

    // only open when inputValue is not empty after the user typed something
    if (inputValue) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    if (!inputValue) {
      setOptions([]);
      return;
    }
  });

  return (
    <>
      <Autocomplete
        loadingText={
          <Typography sx={{ color: 'white' }}>Loading...</Typography>
        }
        loading={isFetching}
        autoComplete={false}
        forcePopupIcon={false}
        open={open}
        onOpen={() => {
          if (inputValue) {
            setOpen(true);
          }
        }}
        onClose={() => {
          setOpen(false);
          setTotal(0);
        }}
        sx={{
          width: '100%',
          maxWidth: 200,
          '& .MuiInputBase-root': {
            padding: 0,
          },
          '& .MuiAutocomplete-noOptions': {
            color: 'white',
          },
          '& .MuiAutocomplete-popper .MuiAutocomplete-listbox': {
            maxHeight: '80vh !important',
          },
        }}
        ListboxProps={{
          style: {
            maxHeight: '80vh',
          },
        }}
        options={wrapperOptions}
        noOptionsText={
          <Typography sx={{ color: 'white' }}>Sorry! No results</Typography>
        }
        onInputChange={(_, newInputValue) => {
          handleSearchArtist(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder="search artists" fullWidth />
        )}
        getOptionLabel={(option) => option?.value ?? ''}
        renderOption={(props, option) => {
          const { data, onClick, label, id } = option;
          return data ? (
            <ArtistInfo
              onClick={onClick}
              key={data.username}
              name={data.name || data.displayUsername || data.username}
              imageUrl={data.imageUrl}
              genre={data.genre?.title}
            />
          ) : (
            <Typography
              key={id}
              onClick={onClick}
              sx={{
                color: 'white',
                cursor: onClick ? 'pointer' : '',
                paddingLeft: '5px',
              }}
            >
              {label}
            </Typography>
          );
        }}
      />
      {showSearchPopup && (
        <ArtistsSearchModal
          show={true}
          onClose={onCloseSearchPopup}
          search={inputValue}
        />
      )}
    </>
  );
};

export default SearchArtist;
