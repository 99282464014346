import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import BasePopup from '~components/organisms/popup/base-popup';
import {
  Button,
  Stack,
  Typography,
  styled,
  Box,
  Divider,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import WebcamModal from '~components/organisms/popup/webcam-modal';
import Photo from '~components/icons/photo';
import useCropper from '../../../hooks/useCropper';
import Cropper from 'react-easy-crop';
import { uploadImage } from '~utils/awsUtils';
import { strictValidString } from '~utils';
import { showErrorToaster } from '~utils/toasterNotification';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 600,
  },
}));

const Wrapper = styled(Box)(() => ({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  border: '1px dashed rgba(255, 255, 255, 0.3)',
  borderRadius: 8,
  alignItems: 'center',
  paddingTop: 40,
  paddingBottom: 40,
}));
const ImageWrapper = styled(Wrapper)(() => ({
  minHeight: 350,
  padding: 0,
  position: 'relative',
}));

const CircularIcon = styled(Stack)(() => ({
  borderRadius: '50%',
  backgroundColor: '#464952',
  width: 80,
  height: 80,
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
  marginBottom: 10,
}));

const LineDivider = styled(Divider)(() => ({
  width: 25,
  marginLeft: 5,
  marginRight: 5,
}));

const OrLabel = styled(Typography)(() => ({
  fontSize: 12,
  color: 'rgba(255, 255, 255, 0.3)',
}));

const UploadButton = styled(Button)(() => ({
  fontSize: 13,
  color: '#0E0E10',
  backgroundColor: '#F2F2F2',
})) as typeof Button;

const DeleteImageButton = styled(IconButton)(() => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (thumbnail?: string) => void;
}

const AddingThumbnailPictureLiveModal = ({
  open,
  onSubmit,
  onClose,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const {
    state,
    setState,
    initialCroppedAreaPixels,
    onCropChange,
    onCropComplete,
    onZoomChange,
    resetState,
    getCroppedImg,
  } = useCropper();
  const { crop, zoom, aspect, rotation, imageSrc } = state;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      const [file] = event.target.files;
      const reader = new FileReader();
      reader.onload = (e) => {
        setState((prevState) => ({
          ...prevState,
          imageSrc: e.target?.result as string,
          crop: { x: 0, y: 0 },
          fileName: file.name,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const onTakePicture = (imageSrc: string | null) => {
    if (!imageSrc) return;
    setState((prevState) => ({
      ...prevState,
      imageSrc,
      crop: { x: 0, y: 0 },
      fileName: uuidv4(),
    }));
  };

  const handleNext = async () => {
    setIsSubmitting(true);
    try {
      const blobImage = await getCroppedImg();
      let thumbnail;
      try {
        thumbnail = await uploadImage(blobImage);
      } catch (err) {
        setIsSubmitting(false);
        console.log('err upload file', err);
        const ee = strictValidString(err)
          ? err
          : 'Error in uploading the thumbnail';
        showErrorToaster(ee);
        return;
      }
      onSubmit(thumbnail);
      setIsSubmitting(false);
    } catch (error) {
      showErrorToaster();
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <StyledModal
        open={open}
        onClose={onClose}
        closeBtn={true}
        keepMounted={false}
      >
        <Stack spacing={3}>
          <Typography
            fontWeight={'700'}
            fontSize={{ xs: 16, sm: 28 }}
            variant="subtitle1"
            lineHeight={'27px'}
            align="center"
          >
            Add a Thumbnail picture for the live board
          </Typography>

          {imageSrc ? (
            <ImageWrapper>
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={aspect}
                showGrid
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                {...initialCroppedAreaPixels}
              />
              <DeleteImageButton size="small" onClick={resetState}>
                <DeleteIcon />
              </DeleteImageButton>
            </ImageWrapper>
          ) : (
            <Wrapper>
              <CircularIcon onClick={handleOpenModal}>
                <Photo />
              </CircularIcon>
              <Typography sx={{ fontSize: '16px' }}>TAKE A PICTURE</Typography>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ marginTop: 2, marginBottom: 2 }}
              >
                <LineDivider />
                <OrLabel>Or</OrLabel>
                <LineDivider />
              </Stack>
              <UploadButton variant="contained" component="label">
                Upload from your computer
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleChangeFile}
                />
              </UploadButton>
            </Wrapper>
          )}

          <Stack direction="column" spacing={1.5} flex={1}>
            <Button
              variant="contained"
              color="warning"
              onClick={handleNext}
              sx={{ flexGrow: 1 }}
              disabled={isSubmitting || !imageSrc}
            >
              NEXT
            </Button>

            <Button
              variant="outlined"
              color="warning"
              onClick={() => onSubmit()}
              sx={{ flexGrow: 1, textTransform: 'none' }}
              disabled={isSubmitting}
            >
              Skip
            </Button>
          </Stack>
        </Stack>
      </StyledModal>
      <WebcamModal
        open={openModal}
        onClose={handleCloseModal}
        onTakePicture={onTakePicture}
      />
    </>
  );
};

export default AddingThumbnailPictureLiveModal;
