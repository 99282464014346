import React from 'react';
import { styled, Typography } from '@mui/material';
import Coins from '~components/pages/for-artists/coins';
import Help from '~components/pages/for-artists/help';
import New from '~components/pages/for-artists/new';
import Interaction from '~components/pages/for-artists/interaction';
import FooterSignUp from '~components/pages/for-artists/footer-sign-up';
import { getImageURL } from '~utils/awsUtils';
import ArtistEventHeader from '~components/organisms/artist-event-header';
import { useUserInfoState } from '~utils/container';

const FOR_ARTIST_PAGE_HEADER_VIDEO = 'web_videos/summer-uplifting-audio.mp4';

const ForArtists = () => {
  const { isArtistUser } = useUserInfoState();
  return (
    <div>
      <ArtistEventHeader
        showSignUpButton={!isArtistUser}
        title={<Title>Ursa Live</Title>}
        subTitle={
          <SubTitle
            sx={{ width: { sm: '90%', md: '80%', lg: '70%', xl: '60%' } }}
          >
            Easy To Set Up, Highly Interactive, Revenue-Producing Livecasts.
            From Your Home, Direct To Your Fans.
          </SubTitle>
        }
      />
      <Video controls={true} autoPlay muted={true} playsInline loop>
        <source
          src={getImageURL(FOR_ARTIST_PAGE_HEADER_VIDEO)}
          type="video/mp4"
        />
      </Video>
      <Coins />
      <Help />
      <New />
      <Interaction />
      <FooterSignUp />
    </div>
  );
};

export default ForArtists;

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  color: 'transparent',
  fontWeight: 800,
  marginTop: -5,
  WebkitTextStroke: `0.9px ${theme.palette.text.primary}`,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h1.fontSize,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 60,
    marginTop: -15,
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: 700,
  color: theme.palette.text.primary,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h5.fontSize,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.h3.fontSize,
  },
}));

const Video = styled('video')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));
