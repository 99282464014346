import {
  Box,
  Stack,
  StackProps,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { FC } from 'react';
import { TextEllipsis } from '~components/atoms';
import Avatar from '~components/organisms/avatar';

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: ` ${theme.spacing(0.25)} ${theme.spacing(1)}`,
  backgroundColor: theme.palette.warning.main,
  borderRadius: theme.shape.borderRadius,
}));

const StyledEmail = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  textTransform: 'lowercase',
  color: alpha(theme.palette.common.white, 0.8),
  fontWeight: '300',
}));

type Props = StackProps & {
  userName: string;
  avatarKey: string; // using username
  userEmail?: string;
  avatarSize?: number;
  type?: string;
  maxWidth?: number | string | Record<string, number>;
};

const AccountAvatar: FC<Props> = (props) => {
  const {
    userEmail,
    userName,
    avatarKey,
    avatarSize = 40,
    type,
    maxWidth,
    ...rest
  } = props;

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'} {...rest}>
      <Box>
        <Box sx={{ width: avatarSize, height: avatarSize }}>
          <Avatar
            username={avatarKey}
            sx={{
              maxWidth: '100%',
            }}
          />
        </Box>
      </Box>
      <Box overflow={'hidden'}>
        <TextEllipsis
          text={userName}
          variant="body1"
          fontSize={{ xs: 16 }}
          disabled={!userEmail}
          sx={{
            textTransform: 'initial',
            cursor: 'inherit',
            fontWeight: '500',
            lineHeight: 1.2,
            maxWidth,
          }}
        />
        {userEmail && (
          <StyledEmail variant="body2" color="text.disabled" fontWeight={'300'}>
            {userEmail}
          </StyledEmail>
        )}
        {type && <StyledLabel variant="caption">{type}</StyledLabel>}
      </Box>
    </Stack>
  );
};

export default AccountAvatar;
