import { Modal, Box } from '@mui/material';
import popupStyles from '~components/popup/popupStyles';

interface TermsServiceModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const styles = {
  popup: [
    popupStyles.popup,
    {
      width: { xs: '90%', sm: '90%' },
      overflow: 'auto',
      '& *': { all: 'revert' },
    },
  ],
};

const TermsServiceModal = ({ show, onClose }: TermsServiceModalProps) => {
  return (
    <Modal open={show} onClose={onClose}>
      <Box sx={styles.popup}>
        <p>TERMS OF SERVICE AGREEMENT</p>
        <p>
          This Terms of Service ("Agreement") is a legal agreement between you
          (referred to herein as "you" or "your") and Ursa Live ("we", "our", or
          "us") for access to and use of our website (the "Website") and other
          related software, interactive features, platforms, or downloads
          operated by us and that are available through the Website (whether
          accessed directly or through any software Website) (collectively, the
          "Service").
        </p>
        <p>
          BY ACCESSING OR USING THE SERVICE, YOU AGREE TO BE BOUND BY THE TERMS
          AND CONDITIONS OF THIS AGREEMENT, WHETHER OR NOT YOU ARE A REGISTERED
          USER OF OUR SERVICE. IF ANY OF THESE TERMS ARE UNACCEPTABLE TO YOU OR
          IN THE EVENT THAT ANY FUTURE CHANGES ARE UNACCEPTABLE TO YOU, DO NOT
          USE THE SERVICE. YOUR CONTINUED USE OF THE SERVICE NOW, OR FOLLOWING
          THE POSTING OF ANY CHANGES IN THIS AGREEMENT, WILL INDICATE ACCEPTANCE
          AND AGREEMENT BY YOU OF SUCH CHANGES.
        </p>
        <p>
          <span style={{ textDecoration: 'underline' }}>
            ARBITRATION NOTICE:
          </span>{' '}
          YOU AGREE THAT DISPUTES ARISING OUT OF THE ACTIVITIES HEREUNDER WILL
          BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION.
        </p>
        <p>
          <span style={{ textDecoration: 'underline' }}>
            CLASS ACTION NOTICE:
          </span>{' '}
          YOU WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR
          CLASS-WIDE ARBITRATION.
        </p>
        <p>
          <span style={{ textDecoration: 'underline' }}>AGE REQUIREMENT:</span>{' '}
          You must be at least 13 years old to use the Service. When you create
          an account, you must provide us with accurate information, in good
          faith, and you agree to keep your information updated if it changes.
          To create an account or otherwise use the Service, you must be at
          least 13 years old and you must also be old enough to consent to the
          processing of your personal data in your country. You must be at least
          18 years old or have your parent’s or legal guardian’s permission to
          have an Artist Page or to purchase an offering or subscription on the
          Website.
        </p>
        <p>
          {' '}
          <span style={{ textDecoration: 'underline' }}>
            Use of the Service by You
          </span>
        </p>
        <p>
          The Service may contain links to other Websites or services maintained
          by third parties. We do not operate or control, in any respect, or
          necessarily endorse the content found on third-party Websites or
          services. You assume sole responsibility for your use of third-party
          links, Websites, products and services.
        </p>
        <p>
          You acknowledge and agree that your use of the Service, including,
          without limitation, the storage of any data, files, information and/or
          other materials on a server owned or under our control or in any way
          connected to the Service, shall be at your sole risk and
          responsibility and we shall have no obligation to back-up such data,
          files, information and/or other materials. We expressly reserve the
          right to limit storage capacity and to remove and/or delete any data,
          files, and/or other information stored or used in connection with the
          Service for any reason including, without limitation, if we deem, in
          our sole discretion, such data to be in violation of this Agreement
          and/or any rule or policy of ours and/or any local, state, or federal
          law or regulation.
        </p>
        <p>
          We cannot always foresee or anticipate technical or other difficulties
          which may result in failure to obtain data or loss of data,
          personalization settings, or other interruptions. We cannot assume
          responsibility for the timeliness, accuracy, deletion, non-delivery or
          failure to store any user data, communications or settings.
        </p>
        <p>
          You agree that you will abide by any third-party company policies and
          terms necessary in using our Website or Service (such as a third-party
          publisher terms of use or Facebook terms of use).
        </p>
        <p>You represent, warrant, and agree that you will not:</p>
        <p>
          use the Service in any unlawful manner, for any unlawful purpose, or
          in any manner inconsistent with this Agreement and/or any and all
          applicable local, state, national and international laws and
          regulations and treaties;
        </p>
        <p>
          permit or otherwise enable unauthorized users to access and/or use the
          Service;
        </p>
        <p>
          use the Service to export software or data in violation of applicable
          U.S. laws or regulations;
        </p>
        <p>
          sell, copy, duplicate, rent, lease, loan, distribute, transfer, or
          sublicense the Service, or otherwise permit any third party to use or
          have access to the Service for any purpose (except as expressly
          permitted by us in writing) or decompile, reverse engineer,
          disassemble, modify, create a derivative work of, display in human
          readable form, attempt to discover any source code, or otherwise use
          any software that enables or comprises any part of the Service;
        </p>
        <p>
          remove any copyright, trademark, patent or other proprietary notices
          from the Service or any Content on the Service;
        </p>
        <p>
          distribute, publish, exhibit, or otherwise use the Service, in any
          manner and for any purpose not expressly permitted under this
          Agreement;
        </p>
        <p>
          exploit the Service or collect any data incorporated in the Service in
          any automated manner through the use of bots, metaspiders, crawlers or
          any other automated means;
        </p>
        <p>
          register as a user of the Service by providing false, inaccurate, or
          misleading information;
        </p>
        <p>
          attempt to gain unauthorized access to our computer systems (including
          any non-public areas of the Service) or engage in any activity that
          disrupts, diminishes the quality of, probes for vulnerability,
          interferes with the performance of, or impairs the functionality of,
          the Service (or the servers and networks which are connected to the
          Service); or
        </p>
        <p>
          access or use the Service if you have been previously removed from the
          Service by us.
        </p>
        <p>
          <span style={{ textDecoration: 'underline' }}>User Terms</span>
        </p>
        <p style={{ textDecoration: 'underline' }}>
          As a user of the Service (“User”), you may purchase virtual currency
          from us (“Livecoin”). Except as permitted by our refund policy (refund
          policy located at the footer of this page), you may not convert
          Livecoin to fiat currency. Livecoin may be used to give Artists
          “tips”, which are gifts from you to the Artist. Once you have used
          Livecoin to give a tip, such Livecoin can no longer be refunded. You
          agree and acknowledge that Livecoin can not be traded with other Users
          or Artists, and you may only use Livecoin as expressly permitted by
          these terms.
        </p>
        <p style={{ textDecoration: 'underline' }}>
          As Users of the Service, you receive a non-exclusive,
          non-transferable, non-sublicensable, revocable, limited license to
          access and view such Content for your own private, personal,
          non-promotional, and non-commercial use. As a User, you agree that:
          (i) you will use the Service solely for your own, non-commercial,
          personal use in accordance with this Agreement and in accordance with
          any specific rules or usage provisions specified by us on the Service;
          and (ii) all information supplied by you to us will be true, accurate,
          current and complete. We retain the right at our sole discretion to
          deny or suspend access to the Service to anyone, at any time and for
          any reason, without liability.
        </p>
        <p style={{ textDecoration: 'underline' }}>
          If you are located in a jurisdiction in which we are required to
          charge and collect tax (e.g., VAT or sales tax), then this tax is
          added to the total charge. Except in limited circumstances, this tax
          is shown to you when making a purchase. We will remit all tax
          collected to the applicable taxing authority. As tax is largely
          dependent on your location, you agree to keep your location
          information complete and up to date in order to ensure accurate
          collection of taxes.
        </p>
        <p style={{ textDecoration: 'underline' }}>
          If you purchase Livecoin in a currency that is different from the
          currency associated with your payment instrument, then the financial
          institution associated with that payment instrument may charge you a
          foreign transaction fee for that payment. We are not applying this fee
          and have no control over it, have no knowledge of whether the fee is
          applied, and do not receive any of the funds from that fee.
        </p>
        <p style={{ textDecoration: 'underline' }}>
          Artists may offer, from time to time, promotions, Rewards, or other
          benefits (“Artist Promises”) to you. All Artist Promises are between
          you and Artist only, and we are not a party to these agreements. We
          offer no guarantees with respect to Artist Promises, nor shall we be
          liable for you or Artist’s breach of any Artist Promises.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>Artist Terms</span>
        </p>
        <p>
          “Artists” are creators who create a page on the Website (a “Artist
          Page”) to engage with users who wish to purchase subscriptions to the
          Artist’s works, donate to the Artist, or purchase or participate in
          various offerings provided by the Artist. All of the terms in this
          “Artist Terms” section apply to Artists only.
        </p>
        <p>As an Artists, you represent and warrant that:</p>
        <p>
          (i) all content you upload to the Service shall be original creations
          by you;
        </p>
        <p>
          (ii) none of the content you upload to the Service shall cause us to
          be subject to any intellectual property infringement claims; and
        </p>
        <p>
          (iii) you shall not use our trade names, logos, or brands in your
          content without our express written permission.
        </p>
        <p>
          You acknowledge and agree that any agreements between you and any
          other user of the Service, whether oral or written, are expressly
          between you and such user, and we will not be liable to you or any
          such user for your failure to adhere to any such oral or written
          contracts. Such oral or written contracts include, but are not limited
          to, participation in the “Rewards” feature offered on the Service—any
          rewards promised via such a feature are promised solely by you and not
          us. You shall defend, indemnify, and hold us harmless against any and
          all third party claims against us arising out of your agreements with
          other users of the Service.
        </p>
        <p>
          You acknowledge and agree that using any part of the Service which
          requires you to input personal information, such as bank account
          information (e.g., receiving payouts), is contingent upon the accuracy
          of such information. We are not responsible for, and we will not
          confirm, or be required to confirm, the accuracy of any such
          information, nor will we be liable or responsible for any harm you
          suffer as a result of inaccurate information you provide.
        </p>
        <p>
          You acknowledge and agree that we charge you a commission fee in
          exchange for permitting your use of the Services. Such commission fee,
          which shall be set by us in our sole discretion, shall be deducted
          from your tips upon withdrawal. We may, from time to time, offer
          promotions and and referral bonuses for Artists. The terms of these
          promotions and bonuses can be found here. We reverse the right to
          modify the terms of these promotions and bonuses in our sole
          discretion.
        </p>
        <p style={{ textDecoration: 'underline' }}>
          As an Artist, you may receive tips, in the form of Livecoin, from
          Users. All such Livecoin shall have a fixed conversion value to USD of
          [conversion rate]. The monetary value of all Livecoin attributable to
          your account (the “Revenue”) shall be held by us. You may, by using
          the information you have supplied us, withdraw Revenue once such
          Revenue is made available to you, provided such Revenue exceeds $20
          USD in value. We will handle payments and tipping issues such as fraud
          and software bugs. These issues may cause delays in withdrawing
          Revenue.
        </p>
        <p style={{ textDecoration: 'underline' }}>
          We may also block or withhold access to Revenue for violations of our
          terms or policies or for compliance reasons, including collecting tax
          reporting information. Sometimes, refunds, chargebacks, and Fees can
          put your account balance into the negative. If your balance becomes
          negative, we reserve the right to recover those funds from future
          Revenue.
        </p>
        <p style={{ textDecoration: 'underline' }}>
          You may be invited to the Ursa Live Partner Program. As a member of
          the program (a “Member”), you agree to: [insert obligations on the
          Artist, if any]. Members shall receive the following benefits:
        </p>
        <p style={{ textDecoration: 'underline' }}>
          (i) appearing on the Website’s “Artist Live Board” whenever you go
          live; and
        </p>
        <p style={{ textDecoration: 'underline' }}>
          (ii) appearing on the Website’s “Upcoming Events” section whenever you
          have scheduled an upcoming livecast.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>
            Provision of the Service by Us
          </span>
        </p>
        <p>
          You acknowledge and agree that the form and nature of the Service
          which we provide may change from time to time without prior notice to
          you. You acknowledge and agree that we may decline to provide you
          access to the Service or stop (permanently or temporarily) providing
          the Service (or any features or programs or Content within the
          Service) to you or to users generally at our sole discretion, without
          liability or prior notice to you. If you are an Artist, any Revenue
          you have not withdrawn will remain yours even if we revoke your access
          to the Service. If we choose to delete your account with us, upon
          deletion, we will remit to you any outstanding Revenue to the payment
          information you have on file with us.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>
            Access to the Service; Reservation of Rights
          </span>
        </p>
        <p>
          Subject to your compliance with this Agreement, we hereby give you a
          personal, revocable, worldwide, non-assignable and non-exclusive right
          to access and use the Service in the manner and for the purposes
          expressly permitted by the Agreement and our associated policies.
        </p>
        <p>
          We reserve all right, title and interest in and to the Service not
          expressly granted to you under this Agreement. There are no implied
          licenses under this Agreement.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>
            Content in the Service
          </span>
        </p>
        <p>
          You understand that all information and materials (including, without
          limitation, data files, written text, computer software, music, audio
          files or other sounds, photographs, videos or other images) accessible
          as part of, or through the use of, the Service are the sole
          responsibility of the person from which such information originated.
          All such information is referred to as "Content".
        </p>
        <p>
          You acknowledge that Content presented to you as part of the Service
          may be protected by intellectual property rights which are owned by
          the persons and/or entities that provide that Content to us (or by
          other persons or companies on their behalf). You may not modify, rent,
          lease, loan, sell, distribute or create derivative works based on this
          Content (either in whole or in part) unless you have been specifically
          told that you may do so by us or by the owners of that Content, in
          writing.
        </p>
        <p>
          We reserve the right (but shall have no obligation) to pre-screen,
          review, flag, filter, modify, refuse or remove any or all Content.
        </p>
        <p>
          You understand that by using the Service you may be exposed to Content
          that you may find offensive, indecent or objectionable and that, in
          this respect, you use the Service at your own risk.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>
            Intellectual Property
          </span>
        </p>
        <p>
          You acknowledge and agree that we (or our licensors) own all legal
          right, title and interest in and to the Service, including, without
          limitation, any intellectual property rights which subsist in the
          Service (whether those rights happen to be registered or not, and
          wherever in the world those rights may exist).
        </p>
        <p>
          You agree that you shall not remove, obscure, or alter any proprietary
          rights notices (including copyright and trademark notices) which may
          be affixed to or contained within the Service.
        </p>
        <p>
          You agree that in using the Service, you will not use any trademark,
          service mark, trade name, logo of any company or organization in a way
          that is likely or intended to cause confusion about the owner or
          authorized user of such marks, names or logos.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>Grant of Rights</span>
        </p>
        <p>
          You grant us a worldwide, sublicensable, irrevocable license to use
          any Content you upload to the Service as part of the Service. You
          grant us a right to use your name, likeness, and image for purposes of
          hosting, advertising, and promoting the Service, you, and your
          Content. You grant us a perpetual right to archive, for internal
          purposes only, your Content, which shall survive termination of this
          Agreement.
        </p>
        <p>
          You agree that we may use your feedback, suggestions, or ideas in any
          way, including, without limitation, in future modifications of the
          Service, other products or services, advertising or marketing
          materials. You grant us a perpetual, worldwide, fully transferable,
          sublicensable, irrevocable, fully-paid up, royalty free license to use
          the feedback, suggestions, and ideas you provide to us in any way.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>Copyright Agent</span>
        </p>
        <p>
          The Digital Millennium Copyright Act of 1998 (the "DMCA") provides
          recourse for copyright owners who believe that material appearing on
          the Internet infringes their rights under U.S. copyright law. It is
          our policy, in appropriate circumstances and at our discretion, to
          disable and/or terminate the accounts of users who repeatedly infringe
          or are repeatedly charged with infringing the copyrights or other
          intellectual property rights of others. If you believe that your work
          has been copied and posted on the Service in a way that constitutes
          copyright infringement, please provide our copyright agent with the
          following information: an electronic or physical signature of the
          copyright owner or of the person authorized to act on behalf of the
          owner of the copyright interest; a description of the copyrighted work
          that you claim has been infringed; a description of where the material
          that you claim is infringing is located on the Service; your address,
          telephone number, and e-mail address; a written statement by you that
          you have a good faith belief that the disputed use is not authorized
          by the copyright owner, its agent, or the law; a statement by you,
          made under penalty of perjury, that the above information in your
          notice is accurate and that you are the copyright owner or are
          authorized to act on the copyright owner's behalf. Any notification by
          a copyright owner or a person authorized to act on such copyright
          owner’s behalf that fails to comply with requirements of the DMCA
          shall not be considered sufficient notice and shall not be deemed to
          confer upon us actual knowledge of facts or circumstances from which
          infringing material or acts are evident. Our copyright agent for
          notice of claims of copyright infringement can be reached as follows:
        </p>
        <p>Email: admin@ursalive.com</p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>Privacy Policy</span>
        </p>
        <p>
          For information about our data protection practices, please read our
          privacy policy available at{' '}
          <a href="https://www.ursalive.com/privacy-policy" target="_blank">
            www.ursalive.com
          </a>
          . This policy explains how we treat your personal information, and how
          we protect your privacy when you use the Service. You agree to the use
          of your data in accordance with our privacy policy.
        </p>
        <p>
          <span style={{ textDecoration: 'underline' }}>NO WARRANTIES</span>
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL WE BE LIABLE
          TO YOU FOR ANY LOSS, DAMAGE, OR INJURY OF ANY KIND INCLUDING ANY
          DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, OR
          PUNITIVE LOSSES OR DAMAGES, OR DAMAGES FOR SYSTEM FAILURE OR
          MALFUNCTION OR LOSS OF PROFITS, DATA, USE, BUSINESS OR GOOD-WILL OR
          OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH THESE
          TERMS. IN NO EVENT WILL OUR CUMULATIVE LIABILITY TO YOU OR ANY OTHER
          USER, FROM ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY, EXCEED
          THE TOTAL AMOUNTS PAID BY YOU TO US IN CONNECTION WITH THE APPLICABLE
          PRODUCT OR SERVICE.
        </p>
        <p>
          THIS LIMITATION OF LIABILITY IS INTENDED TO APPLY WITHOUT REGARD TO
          WHETHER OTHER PROVISIONS OF THESE TERMS HAVE BEEN BREACHED OR HAVE
          PROVEN INEFFECTIVE. THE LIMITATIONS SET FORTH IN THIS SECTION SHALL
          APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER THE ASSERTED LIABILITY
          OR DAMAGES ARE BASED ON CONTRACT, INDEMNIFICATION, TORT, STRICT
          LIABILITY, STATUTE, OR ANY OTHER LEGAL OR EQUITABLE THEORY AND WHETHER
          OR NOT THE URSA LIVE PARTIES HAVE BEEN INFORMED OF THE POSSIBILITY OF
          ANY SUCH DAMAGE
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
          WARRANTIES AND LIABILITIES PROVIDED IN THIS SECTION, SO SOME OF THE
          ABOVE LIMITATIONS AND DISCLAIMERS MAY NOT APPLY TO YOU. TO THE EXTENT
          APPLICABLE LAW DOES NOT PERMIT US TO DISCLAIM CERTAIN WARRANTIES OR
          LIMIT CERTAIN LIABILITIES, THE EXTENT OF OUR LIABILITY AND THE SCOPE
          OF ANY SUCH WARRANTIES WILL BE TO THE FURTHEST EXTENT AS PERMITTED
          UNDER APPLICABLE LAW.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>
            Release and Indemnification
          </span>
        </p>
        <p>
          You agree to release, indemnify and hold harmless us, our parents,
          subsidiaries, affiliates, directors, members, officers, employees,
          agents and third party licensors, third-party publishers, necessary
          third-party platform providers, from and against any and all
          liabilities, losses, damages, claims and expenses, including, without
          limitation, attorneys’ fees, with respect to: (i) your access, use or
          misuse of, or reliance upon, the Service; (ii) any allegations that
          your Content infringes, misappropriates, or violate any third party
          intellectual property rights; (iii) your actual or alleged violation
          or breach of this Agreement or rights of another; and/or (iv) your
          acts or omissions. You agree to cooperate fully with us in the defense
          of any claim that is the subject of your obligations hereunder. For
          the avoidance of doubt, this section shall survive the termination of
          this Agreement.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>
            Term and Termination
          </span>
        </p>
        <p>
          This Agreement is effective until terminated by us or you. We shall
          have the right to terminate this Agreement including, without
          limitation, your right to access and use the Service, at any time in
          our sole discretion and without advance notice to you. The licenses
          granted herein by us shall automatically terminate without advance
          notice if you fail to comply with any material provision of this
          Agreement. You may terminate this Agreement at any time by reaching
          out to us at support@ursalive.com to delete your user account on the
          Service and discontinuing use of any and all parts of the Service.
          Upon termination of this Agreement for any reason, you shall
          immediately cease using the Service.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>Disputes</span>
        </p>
        <p>
          Prior to commencing any legal proceeding of any kind against any us,
          including an arbitration as set forth below, you agree to attempt to
          resolve any dispute, claim, or controversy arising out of or relating
          to this Agreement (each, a “Dispute” and, collectively, “Disputes”) by
          engaging in good faith negotiations. Such good faith negotiations
          require, at a minimum, that the aggrieved party provide a written
          notice to the other party specifying the nature and details of the
          Dispute. The party receiving such notice shall have (30) days to
          respond to the notice. Within sixty (60) days after the aggrieved
          party sent the initial notice, the parties shall meet and confer in
          good faith by videoconference or by telephone to try to resolve the
          Dispute. If the parties are unable to resolve the Dispute within
          ninety (90) days after the aggrieved party sent the initial notice,
          the parties may agree to mediate their Dispute, or either party may
          submit the Dispute to arbitration as set forth below.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>Governing Law</span>
        </p>
        <p>
          This Agreement will be governed by the laws of the State of New York,
          without regard to conflict of law provisions. You agree that any claim
          or dispute must be resolved exclusively by a state or federal court or
          arbitration located in New York, NY, except as described in the
          agreement to arbitrate below or as otherwise mutually agreed by the
          parties.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>
            Agreement to Arbitrate
          </span>
        </p>
        <p>
          Except as provided below, you agree that any Dispute that cannot be
          resolved through the procedures set forth above will be resolved
          through binding arbitration on an individual basis. Class actions and
          class arbitrations are not allowed. You may bring a claim only on
          behalf of yourself and cannot seek relief that would affect other
          users of the Service. If there is a final judicial ruling that any
          particular claim (or a request for particular relief) cannot be
          arbitrated in accordance with this Agreement, then only that
          particular claim or request for relief may be brought in court. All
          other claims (or requests for relief) remain subject to this
          provision. Questions of arbitrability—i.e., whether a particular claim
          is subject to arbitration—shall be resolved in arbitration.
        </p>
        <p>
          Instead of commencing an arbitration, you may also bring claims in
          your local “small claims” court if the rules applicable to that court
          allow it. Otherwise, the claims must be resolved by binding,
          individual arbitration.
        </p>
        <p>
          If you are in the United States, the arbitration will be conducted by
          the American Arbitration Association (AAA) under its Consumer
          Arbitration Rules. If you are outside of the United States, the
          arbitration will be conducted by the International Centre for Dispute
          Resolution in accordance with its International Expedited Procedures.
          The arbitration will be decided by a sole arbitrator. The arbitrator
          will have experience adjudicating matters involving Internet
          technology, software applications, financial transactions and,
          ideally, blockchain technology. The costs and fees of arbitration
          shall be allocated in accordance with the arbitration provider's
          rules, including rules regarding frivolous or improper claims. If
          travelling to New York is a burden, you may participate in the
          arbitration by phone, Internet, or via document submission to the
          fullest extent allowable by the arbitrator. Each party will bear their
          own costs of arbitration unless the arbitrator directs that bearing
          such costs would be an undue burden and in that case, we will pay for
          your portion of the arbitration administrative costs (but not your
          attorneys’ fees).
        </p>
        <p>The arbitration will be governed by the Federal Arbitration Act.</p>
        <p>
          The prevailing party will be entitled to an award of their reasonable
          attorney’s fees and costs. Except as may be required by law, neither a
          party nor its representatives may disclose the existence, content, or
          results of any arbitration hereunder without the prior written consent
          of (all/both) parties.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>
            Limitation of Actions
          </span>
        </p>
        <p>
          You agree that regardless of any statute or law to the contrary, any
          claim or cause of action arising from or relating to this this
          Agreement must be filed within one (1) year after such claim or cause
          of action arose, or will be forever barred.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>No Class Actions</span>
        </p>
        <p>
          You agree to resolve any disputes related to this Agreement as an
          individual and not as a class or join any class. You understand that,
          in return for agreement to this provision and the dispute provision
          above, we are able to offer the Service at the terms designated, and
          that your assent is an indispensable consideration to this Agreement.
        </p>
        <p>
          You also acknowledge and understand that, with respect to any dispute
          with us, our officers, directors, employees, agents or affiliates,
          arising out of or relating to your use of the Service or this
          Agreement:
        </p>
        <p>YOU ARE GIVING UP YOUR RIGHT TO HAVE A TRIAL BY JURY; and</p>
        <p>
          YOU ARE GIVING UP YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A
          PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, OR
          TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT
          INVOLVING ANY SUCH DISPUTE.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>Severability</span>
        </p>
        <p>
          If any provision in this Agreement is invalid or unenforceable or
          contrary to applicable law, such provision shall be construed,
          limited, or altered, as necessary, to eliminate the invalidity or
          unenforceability or the conflict with applicable law, and all other
          provisions of this Agreement shall remain in effect.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>
            No Assignment, Sublicense or Transfer
          </span>
        </p>
        <p>
          You may not assign, sublicense, or transfer this Agreement or any
          rights or obligations hereunder without our prior written consent. Any
          such attempted assignment, sublicense, or transfer will be null and
          void and we, in our sole discretion, shall have the right to
          immediately terminate this Agreement.
        </p>
        {/* -------- */}
        <p>
          <span style={{ textDecoration: 'underline' }}>Entire Agreement</span>
        </p>
        <p>
          This Agreement sets forth the entire understanding and agreement
          between the parties relating to its subject matter. All provisions
          that should by their nature survive the termination of this Agreement
          shall survive the expiration of this Agreement including, without
          limitation, the rights and licenses you have granted hereunder,
          indemnities, releases, disclaimers, limitations on liability,
          provisions related to choice of law, no class action, and no trial by
          jury. Any waiver of or promise not to enforce any right under this
          Agreement shall not be enforceable unless evidenced by a writing
          signed by the party making said waiver or promise.
        </p>
      </Box>
    </Modal>
  );
};

export default TermsServiceModal;
