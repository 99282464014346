import {
  Box,
  Grid,
  OutlinedInput,
  styled,
  TextareaAutosize,
  Typography,
} from '@mui/material';

const WrapperTitle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.h4.fontSize,
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
  marginTop: 16,
}));

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '4px',
  height: 40,
  fontSize: theme.typography.subtitle1.fontSize,
  padding: '5px 0px',

  color: 'white',
  '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.action.active,
  },
}));

const CustomTextarea = styled(TextareaAutosize)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '4px',
  width: '100%',
  fontSize: theme.typography.subtitle1.fontSize,
  padding: '5px 10px',
  paddingLeft: '20px',
  color: 'white',

  '&:focus': {
    border: `1px solid ${theme.palette.action.active}`,
  },
}));

const FormRow = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
  padding: '20px',
}));

export { Title, Wrapper, WrapperTitle, CustomInput, CustomTextarea, FormRow };
