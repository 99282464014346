import React, { useState } from 'react';

import { Box, Button, Stack, styled, Typography } from '@mui/material';

import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import BasePopup from '~components/organisms/popup/base-popup';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '~components/molecules';
import * as yup from 'yup';
import { messages } from '~language/en';
import { CircleSuccessTickSVG } from '~components/icons';
import { VALID_EMAIL } from '~utils/regex';
import { useMutation } from '@tanstack/react-query';
import { changeEmail, ChangEmailErrorCode } from '~api';
import { showErrorToaster } from '~utils/toasterNotification';
import { axiosRequest } from '~api/singleton-axios';

const enum FIELDS_NAME {
  EMAIL = 'email',
}

export type ChangeUsernameInputs = {
  [key in FIELDS_NAME]: string;
};

const schema = yup.object({
  email: yup
    .string()
    .required(messages.VALIDATIONS.INVALID_EMAIL_ADDRESS)
    .matches(VALID_EMAIL, messages.VALIDATIONS.EMAIL_ADDRESS_NOT_VALID),
});

const ChangeEmailModal = () => {
  const { hidePopup } = useDispatchPopup();
  const [successMsg, setSuccessMsg] = useState('');

  const methods = useForm<ChangeUsernameInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });
  const { handleSubmit, setError } = methods;

  const { isLoading, mutate: handleChangeEmail } = useMutation({
    mutationFn: (data: ChangeUsernameInputs) => changeEmail(data.email),
    onSuccess: () => {
      setSuccessMsg(
        'An email has been sent to your new email address. Please check your inbox to confirm your new email address.',
      );
      setTimeout(hidePopup, 3000);
    },
    onError: (error) => {
      let errorCode = '';
      let errorMessage = '';
      if (axiosRequest.isAxiosError(error)) {
        errorCode = error?.response?.data?.code;
        errorMessage = error?.response?.data?.message;
      }
      switch (errorCode) {
        case ChangEmailErrorCode.ERR_CHANGE_EMAIL_INVALID:
        case ChangEmailErrorCode.ERR_CHANGE_EMAIL_ALREADY_TAKEN:
          setError(FIELDS_NAME.EMAIL, {
            type: 'custom',
            message: errorMessage,
          });
          break;
        default:
          showErrorToaster(error);
          return;
      }
    },
  });

  const onSubmit = async (data: ChangeUsernameInputs) => {
    await handleChangeEmail(data);
  };

  return (
    <ChangeEmailModalWindow
      open={true}
      onClose={hidePopup}
      title="Update email"
    >
      <Box sx={{ marginTop: 2 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          {successMsg ? (
            <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
              <CircleSuccessTickSVG />
              <Typography variant="body1">{successMsg}</Typography>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <RHFTextField
                labelColor="white"
                label="Email"
                name="email"
                type="text"
                placeholder="Enter your email"
                required={true}
              />
              <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                <Button
                  fullWidth
                  type="submit"
                  color="warning"
                  variant="contained"
                  disabled={isLoading}
                  sx={{ padding: 1.5 }}
                >
                  SAVE
                </Button>
              </Stack>
            </Stack>
          )}
        </FormProvider>
      </Box>
    </ChangeEmailModalWindow>
  );
};

export default ChangeEmailModal;

const ChangeEmailModalWindow = styled(BasePopup)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '90%',
    height: 'fit-content',
  },
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(7.5)}`,
    width: 650,
  },
}));
