import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { ScrollBar } from '~components/atoms';
import { useFilterUpcomingLiveCast } from '~components/custom-hook';
import { strictValidArrayWithLength } from '~utils/commonUtils';
import UpcomingEventCardList from './UpcomingEventList';

const StyledScrollBar = styled(ScrollBar)(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  overflowY: 'auto',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const StyledDivider = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  bottom: -4,
  width: 92,
  height: 4,
  backgroundColor: theme.palette.warning.main,
  [theme.breakpoints.up('md')]: {
    height: 8,
    bottom: -16,
  },
}));

const UpcomingEventsList = () => {
  const filterEvents = useFilterUpcomingLiveCast();
  const isEmpty = !strictValidArrayWithLength(filterEvents);

  return (
    <StyledScrollBar
      pl={{
        xs: 2,
        md: 0,
      }}
    >
      {!isEmpty && (
        <Box>
          <Box position={'relative'} mt={'auto'} mb={3}>
            <Typography
              variant="subtitle1"
              fontSize={{
                xs: 20,
                md: 30,
              }}
              textTransform={{
                xs: 'capitalize',
                md: 'uppercase',
              }}
              lineHeight={'39px'}
              fontWeight={'400'}
              noWrap
            >
              Upcoming Events
            </Typography>
            <StyledDivider />
          </Box>
          <UpcomingEventCardList events={filterEvents} />
        </Box>
      )}
    </StyledScrollBar>
  );
};
export default UpcomingEventsList;
