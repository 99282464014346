import React, { useRef, useState } from 'react';

import { Box, Button, Stack, styled, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { convertSecondsToMS } from '~utils/dateTimeUtils';
import { useCountdown } from '~components/custom-hook/useCountdown';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { getLatestPartnership } from '~api';
import { PartnershipStatus } from '~types';
import PartnershipProgramModal from '~components/organisms/popup/partnership-program-modal';
import { useBoolean } from 'react-use';

const Container = styled(Stack)(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  paddingTop: '4px',
  paddingBottom: '4px',
  zIndex: 1,
  flexDirection: 'row',
  alignItems: 'center',
}));

const PlayVideoIcon = styled(PlayCircleIcon)(() => ({
  fontSize: 8,
  color: 'white',
}));

// const StartRecordingIcon = styled(RadioButtonCheckedIcon)(() => ({
//   fontSize: 8,
//   color: '#E62948',
// }));

const SubmitVideoIcon = styled(CheckCircleIcon)(() => ({
  fontSize: 8,
  color: 'white',
}));

const StopRecordingIcon = styled(StopCircleOutlinedIcon)(() => ({
  fontSize: 8,
  color: '#E62948',
}));

const RecordAgainIcon = styled(ReplayCircleFilledIcon)(() => ({
  fontSize: 8,
  color: '#E62948',
}));

const PreviewIcon = styled(FiberManualRecordIcon)(() => ({
  fontSize: 8,
  color: 'white',
  marginRight: 8,
}));

const PreviewTypography = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 'normal',
  color: 'rgba(255, 255, 255, 0.6)',
}));

const RecordingButton = styled(Button)(() => ({
  fontSize: 16,
  color: 'white',
  textTransform: 'none',
  fontWeight: 'normal',
}));

const SubmitVideoButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  color: 'white',
  fontWeight: 'normal',
  backgroundColor: '#27AE60',
  marginLeft: 8,
  minWidth: 130,
  ':hover': {
    backgroundColor: '#27AE60',
    opacity: 0.7,
  },
  [theme.breakpoints.down('md')]: {
    '&.MuiButton-root': {
      fontSize: 12,
    },
  },
}));

const Header = styled(Stack)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  padding: '4px 8px',
  zIndex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}));

const HeaderLabel = styled('span')(({ theme }) => ({
  color: 'white',
  fontSize: 16,
  fontWeight: 900,
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

const YellowLabel = styled('span')(({ theme }) => ({
  color: theme.palette.action.active,
  '&:hover': {
    cursor: 'pointer',
  },
}));

interface RecordingFooterBarProps {
  onStartRecodingAgain: () => void;
  onStopPlayingRecoding: () => void;
  onPlayRecoding: () => void;
  recordingFile: Blob;
  recordingTime: number;
  onSubmitVideo: () => void;
  isPartnerShip: boolean;
  mirror?: boolean;
}

const PlayRecordingController = ({
  onStartRecodingAgain,
  onStopPlayingRecoding,
  onPlayRecoding,
  recordingFile,
  recordingTime,
  onSubmitVideo,
  isPartnerShip,
  mirror = false,
}: RecordingFooterBarProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { count, startCountdown } = useCountdown(recordingTime);
  const [showPartnershipProgramModal, togglePartnershipProgramModal] =
    useBoolean(false);

  const handleStartRecodingAgain = () => {
    onStartRecodingAgain();
  };

  const handleStopPlayingRecoding = () => {
    setIsPlaying(false);
    onStopPlayingRecoding();
    videoRef?.current?.pause();
  };
  const handleEndRecoding = () => {
    setIsPlaying(false);
    onStopPlayingRecoding();
  };

  const handlePlayRecoding = async () => {
    setIsPlaying(true);
    await startCountdown();
    onPlayRecoding();
    await videoRef?.current?.load();
    await videoRef?.current?.play();
  };

  const { data } = useQuery({
    queryKey: [QUERY_KEYS.LATEST_PARTNERSHIP_PROGRAM],
    queryFn: () => getLatestPartnership(),
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const isHasSubmittedVideo = data?.status === PartnershipStatus.PENDING;

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        {!isPartnerShip && (
          <Header>
            <HeaderLabel>
              Apply to be an Ursa Live Partner{' '}
              <YellowLabel onClick={togglePartnershipProgramModal}>
                (more info)
              </YellowLabel>
            </HeaderLabel>
            <SubmitVideoButton
              variant="text"
              startIcon={<SubmitVideoIcon />}
              onClick={onSubmitVideo}
              disabled={isHasSubmittedVideo}
            >
              {isHasSubmittedVideo ? 'VIDEO IN REVIEW' : 'SUBMIT VIDEO'}
            </SubmitVideoButton>
          </Header>
        )}
        <video
          width="100%"
          height="100%"
          style={{
            transform: `rotateY(${mirror ? 180 : 0}deg)`,
            objectFit: 'contain',
          }}
          ref={videoRef}
          onEnded={handleEndRecoding}
        >
          <source src={URL.createObjectURL(recordingFile)} type="video/mp4" />
        </video>
      </Box>
      <Container>
        <Stack flex={1} alignItems="center" marginLeft={2} direction="row">
          {isPlaying ? (
            <RecordingButton
              variant="text"
              startIcon={<StopRecordingIcon />}
              onClick={handleStopPlayingRecoding}
            >
              Stop
            </RecordingButton>
          ) : (
            <RecordingButton
              variant="text"
              startIcon={<RecordAgainIcon />}
              onClick={handleStartRecodingAgain}
            >
              Record again
            </RecordingButton>
          )}
        </Stack>
        <Stack
          flex={1}
          alignItems="center"
          justifyContent="end"
          marginRight={2}
          direction="row"
        >
          {isPlaying ? (
            <>
              <PreviewIcon sx={{ color: '#E62948' }} />
              <PreviewTypography>{convertSecondsToMS(count)}</PreviewTypography>
            </>
          ) : (
            <>
              <RecordingButton
                variant="text"
                endIcon={<PlayVideoIcon />}
                onClick={handlePlayRecoding}
              >
                Play recording
              </RecordingButton>
            </>
          )}
        </Stack>
      </Container>
      {showPartnershipProgramModal && (
        <PartnershipProgramModal
          onClose={togglePartnershipProgramModal}
          open={true}
          showApplySection={false}
        />
      )}
    </>
  );
};

export default PlayRecordingController;
