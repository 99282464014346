import { useState } from 'react';
import { ToastContent, toast } from 'react-toastify';
// @mui
import { Button, Stack, Typography, alpha, styled } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
// service
import { followArtist } from '~api/fan';
// utils
import { strictValidString } from '~utils/commonUtils';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
// components
import { CustomRadioGroup } from '~components/molecules/custom-input';
// types
import { NotificationType } from '~types';
//
import VerifyPhoneModal from './verify-otp-modal';
import BasePopup from './base-popup';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import { FEATURE_FLAGS_TYPE } from '~utils/constants';

const StyledFollowModal = styled(BasePopup)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  backgroundColor: theme.palette.grey[900],
  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
  },
  [theme.breakpoints.up('sm')]: {
    width: 360,
    margin: 'auto',
  },
}));

interface Props {
  onClose: () => void;
  onFollowCallback?: () => void;
}

const FollowModal = ({ onClose, onFollowCallback }: Props) => {
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const {
    artist: { id: artistId, currentEvent },
    user: { phone },
    config: { chatGroupId },
  } = globalState;
  const [value, setValue] = useState<string>(NotificationType.SMS);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);

  const { isEnable } = useFeatureFlag();
  const isEnableSystemChat = isEnable(
    FEATURE_FLAGS_TYPE.SYSTEM_CHAT_ON_TIP_QUESTION_FOLLOW,
  );

  const followingOptions: {
    label: string;
    name: string;
    icon?: React.ReactNode;
    value: NotificationType;
  }[] = [
    {
      label: 'SMS/Text',
      name: 'sms',
      icon: (
        <TextsmsOutlinedIcon
          sx={{
            width: 24,
            height: 24,
            color: (theme) => alpha(theme.palette.common.white, 0.7),
          }}
        />
      ),
      value: NotificationType.SMS,
    },
    {
      label: 'Email',
      name: 'email',
      icon: (
        <EmailOutlinedIcon
          sx={{
            width: 24,
            height: 24,
            color: (theme) => alpha(theme.palette.common.white, 0.7),
          }}
        />
      ),
      value: NotificationType.EMAIL,
    },
    /**
     * * Temporary remove follow client's feedback
     */
    // { label: 'No, thanks', name: 'nothing', value: NotificationType.NO_THANKS },
  ];

  const closeVerifyPhone = () => setShowVerifyPhone(false);

  const handleClose = () => {
    onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError('');
    setValue((event.target as HTMLInputElement).value);
  };

  const handleSubmit = async () => {
    if (value === NotificationType.SMS && !phone) {
      setShowVerifyPhone(true);
    } else {
      await onHandleFollow();
    }
  };

  const onHandleFollow = async (newPhone?: string) => {
    setLoading(true);

    try {
      await followArtist(
        artistId,
        value,
        isEnableSystemChat ? chatGroupId : undefined,
        currentEvent,
      );
      dispatch({
        type: 'user',
        payload: {
          is_following: true,
          notification_type: value,
          phone: newPhone ?? phone,
        },
      });
      handleClose();
      onFollowCallback && onFollowCallback();
    } catch (err) {
      toast(err as ToastContent<unknown>);
    }
    setLoading(false);
  };

  return (
    <>
      <StyledFollowModal open={true} onClose={handleClose} closeBtn={true}>
        <Stack spacing={2.5}>
          {strictValidString(error) && (
            <Typography color="error.primary">{error}</Typography>
          )}

          <Typography
            fontWeight={'700'}
            fontSize={{ xs: 20 }}
            lineHeight={'30px'}
          >
            How would you like to be notified when this artist goes live?
          </Typography>
          <FormControl>
            <CustomRadioGroup
              name="follow-radio-group"
              value={value}
              onChange={handleChange}
              color="warning"
              options={followingOptions}
            />
          </FormControl>
          <Typography
            lineHeight={'18px'}
            variant="caption"
            fontWeight={'200'}
            fontStyle={'italic'}
            fontSize={12}
            color={(theme) => alpha(theme.palette.common.white, 0.7)}
          >
            Eventually you will be able to update these preferences for each
            artist you follow.
          </Typography>
          <Stack direction={'row'} spacing={1}>
            <Button
              fullWidth
              variant="contained"
              color="warning"
              onClick={handleSubmit}
              disabled={loading}
            >
              Done
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="warning"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </StyledFollowModal>

      {showVerifyPhone && (
        <VerifyPhoneModal
          onFollow={onHandleFollow}
          onClose={closeVerifyPhone}
          open={true}
        />
      )}
    </>
  );
};

export default FollowModal;
