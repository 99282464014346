import React from 'react';

import { Stack, styled, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Container = styled(Stack)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  paddingTop: 4,
  paddingBottom: 4,
  paddingLeft: 8,
  zIndex: 1,
  flexDirection: 'row',
  alignItems: 'center',
}));

const PreviewTypography = styled(Typography)(() => ({
  fontSize: 12,
  color: 'rgba(255, 255, 255, 0.6)',
}));

const PreviewIcon = styled(FiberManualRecordIcon)(() => ({
  fontSize: 8,
  color: '#E62948',
  marginRight: 8,
}));

const PreviewController = () => {
  return (
    <Container>
      <PreviewIcon />
      <PreviewTypography>PREVIEW</PreviewTypography>
    </Container>
  );
};

export default PreviewController;
