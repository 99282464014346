import WithAvatar from '~components/HOC/WithAvatar';
import AvatarMui, { AvatarProps as AvatarMuiProps } from '@mui/material/Avatar';
interface AvatarProps extends AvatarMuiProps {
  username: string;
}

const Avatar = ({ sx, username, ...rest }: AvatarProps) => {
  return (
    <WithAvatar username={username}>
      <AvatarMui
        sx={{
          width: '100%',
          height: '100%',
          '& .MuiAvatar-fallback': {
            width: '100%',
            height: '100%',
          },
          ...sx,
        }}
        {...rest}
      />
    </WithAvatar>
  );
};

export default Avatar;
