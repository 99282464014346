import * as React from 'react';
import { SVGProps } from 'react';

const Follow = ({ fill = '#FAC8A3', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d={`M12.4 7.80002V5.73335H10.3333V4.53335H12.4V2.46668H13.6V4.53335H15.6667V5.73335H13.6V7.80002H12.4ZM6.08332 
      6.76669C5.21665 6.76669 4.5111 6.49446 3.96665 5.95002C3.42221 5.40557 3.14998 4.70557 3.14998 3.85002C3.14998 
      2.99446 3.42221 2.29446 3.96665 1.75002C4.5111 1.20557 5.21665 0.93335 6.08332 0.93335C6.93887 0.93335 7.63888 
      1.20557 8.18332 1.75002C8.72776 2.29446 8.99999 2.99446 8.99999 3.85002C8.99999 4.70557 8.72776 5.40557 8.18332 
      5.95002C7.63888 6.49446 6.93887 6.76669 6.08332 6.76669ZM0.31665 12.9667V10.95C0.31665 10.4834 0.438873 10.0611 
      0.683317 9.68335C0.927762 9.30558 1.2611 9.01669 1.68332 8.81669C2.53887 8.43891 3.30832 8.17224 3.99165 
      8.01669C4.67499 7.86113 5.37221 7.78335 6.08332 7.78335C6.79443 7.78335 7.49165 7.86113 8.17499 8.01669C8.85832 
      8.17224 9.61665 8.43891 10.45 8.81669C10.8722 9.01669 11.2083 9.3028 11.4583 9.67502C11.7083 10.0472 11.8333 
      10.4722 11.8333 10.95V12.9667H0.31665Z`}
      fill={fill}
    />
  </svg>
);

export default Follow;
