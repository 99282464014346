import React from 'react';
import { Box } from '@mui/material';

import HeaderLandingPage from '../organisms/header-landing-page';
import FooterLandingPage from '../organisms/footer-landing-page';
import MenuPopupsContainer from '~components/organisms/account/menu-popups-container';

interface Props {
  hideContact?: boolean;
  showApplySeriesNightButton?: boolean;
}

const LandingPageWrapper = ({
  children,
  hideContact = false,
  showApplySeriesNightButton = false,
}: React.PropsWithChildren<Props>) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <HeaderLandingPage />
      {children}
      <FooterLandingPage
        hideContact={hideContact}
        showApplySeriesNightButton={showApplySeriesNightButton}
      />
      <MenuPopupsContainer />
    </Box>
  );
};

export default LandingPageWrapper;
