import React from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { BookingScheduleIcon, CameraIcon } from '~components/icons';
import { getBookingProducerLink } from '~utils/configUtils';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { getLatestPartnership } from '~api';
import { PartnershipStatus } from '~types';

interface PartnershipProgramModalProps {
  onClose: () => void;
  open: boolean;
  onOpenSettingDashboardPopup?: () => void;
  showApplySection?: boolean;
}

const PartnershipProgramWindow = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('lg')]: {
    width: 900,
  },
}));

const PartnershipProgramModal = ({
  onClose,
  open,
  onOpenSettingDashboardPopup = () => undefined,
  showApplySection = true,
}: PartnershipProgramModalProps) => {
  const { data } = useQuery({
    queryKey: [QUERY_KEYS.LATEST_PARTNERSHIP_PROGRAM],
    queryFn: () => getLatestPartnership(),
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const isHasSubmittedVideo = data?.status === PartnershipStatus.PENDING;

  const onClickBookOneOnOne = () => {
    window.open(getBookingProducerLink(), '_blank');
  };

  return (
    <PartnershipProgramWindow
      open={open}
      onClose={onClose}
      title="Partnership Program"
    >
      <Stack>
        <Content>
          <BigNote>BECOME A PARTNER</BigNote>
          <Note>
            Apply here to become an Ursa Live Partner! ALL Ursa Live Partners
            receive the following benefits:
          </Note>
          <Note>
            • You'll appear on our home page's "Artist Live Board" whenever you
            go live.
          </Note>
          <Note>
            • You'll appear on our home page's Featured Upcoming Events section
            when you schedule a livecast.
          </Note>
        </Content>
        {showApplySection && (
          <>
            <SubTitle>
              Choose one of the two option below to APPLY NOW!
            </SubTitle>
            <BigButtonIcon
              variant="outlined"
              startIcon={
                <CircularIcon>
                  <CameraIcon color="#FF0000" />
                </CircularIcon>
              }
              onClick={onOpenSettingDashboardPopup}
            >
              RECORD A VIDEO
            </BigButtonIcon>
            <BigButtonIcon
              onClick={onClickBookOneOnOne}
              disabled={isHasSubmittedVideo}
            >
              <Stack direction="column">
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularIcon>
                    <BookingScheduleIcon />
                  </CircularIcon>
                  <Typography sx={{ marginLeft: 2 }}>
                    BOOK A FREE PRODUCER
                  </Typography>
                </Stack>
                <DarkNote>
                  An Ursa Live producer can help with your audio set up and make
                  suggestions for a more high quality audio/video stream
                </DarkNote>
              </Stack>
            </BigButtonIcon>
          </>
        )}
      </Stack>
    </PartnershipProgramWindow>
  );
};

export default PartnershipProgramModal;

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `2px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
  padding: 16,
  marginTop: 16,
  marginBottom: 16,
}));
const BigButtonIcon = styled(Button)(({ theme }) => ({
  border: `2px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
  paddingTop: 16,
  paddingBottom: 16,
  color: theme.palette.text.primary,
  marginBottom: 16,
}));
const CircularIcon = styled(Stack)(() => ({
  borderRadius: '50%',
  backgroundColor: '#464952',
  width: 40,
  height: 40,
  alignItems: 'center',
  justifyContent: 'center',
}));

const Note = styled(Typography)(() => ({
  marginTop: 16,
  textAlign: 'center',
}));
const BigNote = styled(Note)(() => ({
  fontSize: 20,
}));

const DarkNote = styled(Typography)(({ theme }) => ({
  marginTop: 16,
  textAlign: 'center',
  color: theme.palette.darkText.main,
  textTransform: 'none',
  marginBottom: 16,
  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
}));

const SubTitle = styled(Typography)(() => ({
  textAlign: 'center',
  marginBottom: 16,
}));
