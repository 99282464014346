import * as React from 'react';
import { SVGProps } from 'react';

const TwitterSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16Z"
      fill="#55ACEE"
    />
    <path
      d={`M16.0208 13.0051L16.0544 13.5587L15.4948 13.4909C13.4579 13.2311 11.6784 12.3498 10.1676 10.8696L9.42891 10.1352L9.23865
      10.6776C8.83575 11.8865 9.09316 13.1633 9.93253 14.022C10.3802 14.4965 10.2795 14.5643 9.50725 14.2819C9.23865 14.1915
      9.00363 14.1237 8.98124 14.1576C8.9029 14.2367 9.1715 15.2648 9.38414 15.6716C9.67513 16.2365 10.2683 16.7902 10.9174
      17.1178L11.4658 17.3777L10.8167 17.389C10.1899 17.389 10.1676 17.4003 10.2347 17.6376C10.4585 18.372 11.3427 19.1516
      12.3276 19.4905L13.0214 19.7278L12.4171 20.0894C11.5218 20.6091 10.4697 20.9029 9.41772 20.9255C8.91409 20.9368 8.5
      20.982 8.5 21.0159C8.5 21.1289 9.86538 21.7616 10.66 22.0102C13.0438 22.7446 15.8753 22.4282 18.0017 21.1741C19.5126
      20.2815 21.0235 18.5076 21.7286 16.7902C22.1091 15.875 22.4896 14.2028 22.4896 13.4006C22.4896 12.8808 22.5232 12.813
      23.1499 12.1916C23.5192 11.83 23.8662 11.4346 23.9333 11.3216C24.0452 11.1069 24.034 11.1069 23.4633 11.299C22.512
      11.638 22.3777 11.5928 22.8477 11.0843C23.1947 10.7228 23.6088 10.0674 23.6088 9.87536C23.6088 9.84146 23.4409 9.89796
      23.2506 9.99964C23.0492 10.1126 22.6015 10.2821 22.2658 10.3838L21.6614 10.5759L21.113 10.203C20.8108 9.99964 20.3856
      9.77367 20.1617 9.70588C19.5909 9.5477 18.718 9.57029 18.2032 9.75107C16.8042 10.2595 15.9201 11.5702 16.0208 13.0051Z`}
      fill="white"
    />
  </svg>
);

export default TwitterSVG;
