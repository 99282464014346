import { config, SizeProp } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

export interface IconProps {
  className?: string;
  size?: SizeProp;
  onClick?: () => void;
}

export { default as SendMessageIcon } from './send-message';
export { default as CreditCardIcon } from './credit-card';
export { default as TrashIcon } from './trash';
export { default as InfoIcon } from './info';
export { default as PaypalIcon } from './paypal';
export { default as PaypalColorfulIcon } from './paypal-colorful';
export { default as BankIcon } from './bank';
export { default as VenmoIcon } from './venmo';
export { default as CircleSuccessTickSVG } from './ticks/circle-success-tick';
export { default as ThinCrossSVG } from './crosses/thin-cross';
export { default as CalendarSVG } from './calendar';
export { default as TriangleArrowSVG } from './arrows/triangle-arrow';
export { default as ThinTriangleArrowSVG } from './arrows/thin-triangle-arrow';
export { default as ExclamationMarkSVG } from './warning/exclamation-mark';
export { default as CircleExclamationMarkSVG } from './warning/circle-exclamation-mark';
export { default as MuteIcon } from './mute';
export { default as PlaySVG } from './play';
export { default as CopyIcon } from './copy';
export { default as CameraIcon } from './camera';
export { default as ShakeHandIcon } from './shake-hand';
export { default as StarIcon } from './star';
export { default as GoLiveIcon } from './go-live';
export { default as BookingScheduleIcon } from './booking-schedule';
export { default as StageIcon } from './stage';
export { default as MinusSVG } from './minus';
export { default as PlusSVG } from './plus';
export { default as NoPhoto } from './no-photo';
export { default as photoIcon } from './photo';
export { default as FollowIcon } from './follow';
export { default as KeyIcon } from './key';
export { default as TipIcon } from './tip';
export { default as SurveyIcon } from './survey';
export { default as FullscreenIcon } from './fullscreen';
export { default as ExitFullscreenIcon } from './exit-fullscreen';
export { default as ShareProfileIcon } from './share-profile';
export { default as SMS } from './sms';
export * from './social-networks';
export * from './badge';
export * from './cards';
export * from './google';
export * from './apple';
export * from './trophy';
