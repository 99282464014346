import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUserEvents } from '~api/event';
import { useGlobalDispatch } from '~utils/container';
import { RootState, select } from '~stores';

const useArtistEvent = () => {
  const dispatch = useGlobalDispatch();
  const artistId = useSelector((state: RootState) =>
    select.roomInfo.getArtistId(state),
  );

  const fetchUpComingEvent = useCallback(async () => {
    if (artistId) {
      const { upcoming: events } = await getUserEvents(artistId);
      dispatch({ type: 'artist', payload: { events } });
    }
  }, [artistId, dispatch]);

  return { fetchUpComingEvent };
};

export default useArtistEvent;
