import { useEffect, useRef, useState } from 'react';

export const useCountdown = (countStart: number) => {
  const [count, setCount] = useState(0);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (count <= 0) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [count]);

  const startCountdown = async () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setCount((prevState) => prevState - 1);
    }, 1000);
    setCount(countStart);
  };
  const stopCountdown = async () => {
    setCount(0);
  };

  return {
    count,
    startCountdown,
    stopCountdown,
  };
};
