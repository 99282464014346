import React from 'react';
import { styled } from '@mui/material';
import TitleDate from './title-date';
import { InternalIndentBlock } from '../../components';

import AtomReport from '~components/molecules/report';
import { ReportView } from '~types/event';

interface ReportProps extends Omit<ReportView, 'id'> {
  number: number;
}

const Report = (props: ReportProps) => {
  const { number, startedAt, endedAt } = props;

  return (
    <Container>
      <TitleDate number={number} startedAt={startedAt} endedAt={endedAt} />
      <AtomReport {...props} />
    </Container>
  );
};

export default Report;

const Container = styled(InternalIndentBlock)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
  padding: 20,
}));
