import * as React from 'react';
import { SVGProps } from 'react';

const ThinTriangleArrowSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="33"
    height="60"
    viewBox="0 0 33 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.75 59.9167L32.75 30L2.75 0L0.0833367 2.66667L27.4167 30L0.0833367 57.25L2.75 59.9167Z"
      fill="white"
      fillOpacity="0.6"
    />
  </svg>
);

export default ThinTriangleArrowSVG;
