import * as React from 'react';
import { SVGProps } from 'react';

const PhotoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="42"
    height="38"
    viewBox="0 0 42 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M21.0001 29.8959C23.5001 29.8959 25.6008 29.0452 27.3021 27.3438C29.0035 25.6424 29.8542 23.5417 29.8542 
      21.0417C29.8542 18.507 29.0035 16.3976 27.3021 14.7136C25.6008 13.0295 23.5001 12.1875 21.0001 12.1875C18.4653 
      12.1875 16.356 13.0295 14.6719 14.7136C12.9879 16.3976 12.1459 18.507 12.1459 21.0417C12.1459 23.5417 12.9879 
      25.6424 14.6719 27.3438C16.356 29.0452 18.4653 29.8959 21.0001 29.8959ZM3.29175 37.5C2.45841 37.5 1.72925 
      37.1875 1.10425 36.5625C0.479248 35.9375 0.166748 35.2084 0.166748 34.375V7.65628C0.166748 6.85767 0.479248 
      6.13718 1.10425 5.49482C1.72925 4.85246 2.45841 4.53128 3.29175 4.53128H10.948L14.7501 3.05176e-05H27.2501L31.0521 
      4.53128H38.7084C39.507 4.53128 40.2275 4.85246 40.8699 5.49482C41.5122 6.13718 41.8334 6.85767 41.8334 
      7.65628V34.375C41.8334 35.2084 41.5122 35.9375 40.8699 36.5625C40.2275 37.1875 39.507 37.5 38.7084 37.5H3.29175Z`}
      fill="white"
    />
  </svg>
);

export default PhotoSVG;
