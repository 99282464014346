import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  StackProps,
  Typography,
  styled,
} from '@mui/material';

const StyledRoot = styled(Stack)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'black',
  minWidth: 120,
  minHeight: 32,
}));

type Props = StackProps & {
  title: string;
  text: string;
  isDropdownOpened?: boolean;
  onDropdownClicked?: (event: React.MouseEvent<HTMLElement>) => void;
};

const HighlightText = ({
  title,
  text,
  isDropdownOpened,
  onDropdownClicked,
  ...rest
}: Props) => {
  return (
    <Box>
      <StyledRoot
        direction={'row'}
        alignItems={'center'}
        py={0.5}
        px={2}
        spacing={1.5}
        onClick={onDropdownClicked}
        {...rest}
      >
        <Typography
          variant="body2"
          fontSize={{ lg: 12 }}
          fontWeight={'300'}
          lineHeight={'21px'}
        >
          {title}{' '}
        </Typography>
        <Typography
          component={'span'}
          color={(theme) => theme.palette.warning.main}
          variant="h4"
          fontWeight={400}
          lineHeight={'24px'}
        >
          {text}
        </Typography>
        {onDropdownClicked && (
          <IconButton
            aria-label="dropdown-button"
            sx={{ padding: '0px', marginLeft: '5px' }}
          >
            {isDropdownOpened ? (
              <ArrowDropUp fontSize="small" />
            ) : (
              <ArrowDropDown fontSize="small" />
            )}
          </IconButton>
        )}
      </StyledRoot>
    </Box>
  );
};

export default HighlightText;
