export abstract class ASocketConnection {
  abstract sendPayload(payload: Record<string, unknown>): void;

  abstract currentSession(): string;

  abstract getUserId(): string | null;

  abstract isConnecting(): boolean;
  abstract isClose(): boolean;

  abstract close(): void;

  abstract ping(): void;
}
