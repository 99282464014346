import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { LinkifyText, ScrollBar } from '~components/atoms';
import { clipText, validObjectWithParameterKeys } from '~utils/commonUtils';
import { USER_TYPE } from '~utils/constants';
import { useGlobalState } from '~utils/container';
import { ManageProfileContext } from '~providers/ManageProfileProvider';
import { useNavigate, useParams } from 'react-router-dom';
import SocialLinks, { SocialTypes } from '~components/atoms/social-links';
import Avatar from '../avatar';
import { ArrowBack, ArrowForward, TurnRight } from '@mui/icons-material';
import { RoutesPages } from '../../../router/types';
import { ArchivedVideoCardList } from '~components/molecules/archived-video-card';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState, select } from '~stores';
import useBreakpoints from '~hooks/useBreakpoints';
import { useBoolean } from 'react-use';
import ShareProfileModal from '../popup/share-profile-modal';
import { getArchivedVideoById } from '~api/archived-videos';
import { Event } from '~types';
import { VideoPlayer } from '../VideoPlayer';
import { getCdnURL } from '~utils/awsUtils';

const StreamArtistDetailWithArchivedVideos = () => {
  const { featureName: eventId } = useParams();
  const [showShareProfileModal, setShowShareProfileModal] = useBoolean(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const { isMd } = useBreakpoints();
  const globalState = useGlobalState();
  const { openManageProfile } = useContext(ManageProfileContext);
  const [showedAll, setShowedAll] = useState(false);
  const [isShowPastFeatured, setIsShowPastFeatured] = useState(false);
  const dispatch = useDispatch<RootDispatch>();

  const archivedVideos = useSelector((state: RootState) =>
    select.archivedVideos
      .getArchivedVideos(state)
      .filter((video) => video.addToProfile),
  );
  const toSelectVideo = useSelector((state: RootState) =>
    select.archivedVideos.getCurrentVideo(state),
  );
  const [currentVideo, setCurrentVideo] = useState<Event>();
  useEffect(() => {
    if ((eventId && !isShowPastFeatured) || (!toSelectVideo && eventId)) {
      getArchivedVideoById(eventId).then((resp) => {
        setIsShowPastFeatured(true);
        setCurrentVideo(resp);
      });
    } else {
      setCurrentVideo(toSelectVideo);
    }
  }, [eventId, toSelectVideo]);

  const { username } = useParams();
  const navigate = useNavigate();
  const {
    artist,
    user: { type, name } = {},
  }: {
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    artist: Record<string, any>;
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user: Record<string, any>;
  } = globalState;
  const { description, genre, outsideLinks } = artist;

  const isFan = type === USER_TYPE.FAN;

  useEffect(() => {
    if (artist.id) {
      dispatch.archivedVideos.fetchArchivedVideos(artist.id);
    }
  }, [artist.id]);

  const navigateToArtist = () => {
    navigate(RoutesPages.PROMOTION_TIP);
  };

  const socials = useMemo(() => {
    if (!outsideLinks) return [];
    return (
      [
        'facebook',
        'tiktok',
        'youtube',
        'instagram',
        'twitter',
        'website',
        'spotify',
      ] as SocialTypes[]
    ).reduce(
      (
        prevValue: { type: SocialTypes; href: string }[],
        currentValue: SocialTypes,
      ) => {
        if (outsideLinks[currentValue]) {
          return [
            ...prevValue,
            {
              type: currentValue,
              href: outsideLinks[currentValue],
            },
          ];
        }
        return [...prevValue];
      },
      [],
    );
  }, [outsideLinks]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderBio = () => {
    const hasMore = description && description.length > BIO_LENGTH;
    const content = (
      <>
        {clipText(description, showedAll ? undefined : BIO_LENGTH)}
        &nbsp;
        {hasMore && (
          <Typography
            component={'span'}
            sx={{ cursor: 'pointer' }}
            variant="subtitle2"
            onClick={() => setShowedAll((prevState) => !prevState)}
          >
            {showedAll ? 'View Less' : 'View More'}
          </Typography>
        )}
      </>
    );

    if (!showedAll && hasMore) return <p>{content}</p>;

    return <LinkifyText as="p">{content}</LinkifyText>;
  };

  return currentVideo ? (
    <VideoContainer>
      {currentVideo.videoTitle && (
        <TitleOverlay mt={1} ml={3} isExpanded={isExpanded}>
          <TitleText isExpanded={isExpanded}>
            {currentVideo.videoTitle}
          </TitleText>
          <IconButton onClick={toggleExpand}>
            {isExpanded ? (
              <ArrowBack sx={{ fontSize: 16 }} />
            ) : (
              <ArrowForward sx={{ fontSize: 16 }} />
            )}
          </IconButton>
        </TitleOverlay>
      )}
      {currentVideo.defaultOnProfile && (
        <LabelOverlay mt={2} mr={3}>
          <Typography fontSize={11}>Archived Video</Typography>
        </LabelOverlay>
      )}
      <VideoPlayer autoPlay={true} url={getCdnURL(currentVideo.videoUrl)} />
    </VideoContainer>
  ) : (
    <StyledScrollBar>
      <Stack padding={2} spacing={3}>
        <Box>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={1.5}
          >
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Box
                sx={{
                  minWidth: 56,
                  minHeight: 56,
                  maxWidth: 112,
                  maxHeight: 112,
                }}
              >
                <Avatar username={username as string} />
              </Box>
              <Box>
                <Stack
                  justifyContent={'space-between'}
                  direction={{
                    md: 'row',
                    xs: 'column',
                  }}
                >
                  <Stack gap={2} alignItems={'center'} direction={'row'}>
                    <StyledArtistName variant="h3" fontWeight={'500'}>
                      {artist?.name ?? artist?.displayUsername ?? username}
                    </StyledArtistName>
                    <Button
                      variant="outlined"
                      color="warning"
                      size="small"
                      endIcon={<TurnRight fontSize="small" />}
                      sx={{ fontSize: 12, height: 25 }}
                      onClick={() => setShowShareProfileModal(true)}
                    >
                      Share
                    </Button>
                  </Stack>

                  <Box mt={0.25}>
                    {!isFan && (
                      <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        sx={{ fontSize: 10 }}
                        onClick={() => openManageProfile()}
                      >
                        Manage Profile
                      </Button>
                    )}
                  </Box>
                </Stack>
                {validObjectWithParameterKeys(genre, ['title']) && (
                  <StyledLabel variant="caption">
                    {genre?.title || ''}
                  </StyledLabel>
                )}
                {!isFan ? (
                  <>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      lineHeight={'17px'}
                      color={(theme) => theme.palette.common.white}
                    >
                      Tap MANAGE PROFILE to add upcoming livecasts and calendar
                      invitations.
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      lineHeight={'17px'}
                      color={(theme) => theme.palette.common.white}
                      fontSize={{ lg: 16 }}
                      my={{ xs: 2, md: 'auto' }}
                      textAlign={'left'}
                    >
                      Add an upcoming Livecast then promote your livecast on
                      your socials. You can review promo guidelines{' '}
                      <YellowLabel onClick={navigateToArtist}>HERE</YellowLabel>
                      .
                    </Typography>
                  </>
                ) : (
                  <>
                    {description && (
                      <Typography variant="subtitle2" mb={0} fontWeight={300}>
                        {renderBio()}
                      </Typography>
                    )}
                    {socials?.length && isFan && (
                      <Box
                        maxWidth={{ sx: '100%' }}
                        display={'flex'}
                        justifyItems={'start'}
                      >
                        <SocialLinks
                          iconSize={isMd ? 40 : 20}
                          socials={socials}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Stack>
          </Stack>
        </Box>
        {archivedVideos.length > 0 && isMd && (
          <Box>
            <Typography
              variant="body1"
              textTransform={'uppercase'}
              fontWeight={500}
              lineHeight={'20px'}
              mt={2}
              mb={1}
              paddingLeft={2}
            >
              Livecast Videos
            </Typography>
            <ArchivedVideoCardList
              events={archivedVideos}
              artistImage={artist.imageUrl}
            />
          </Box>
        )}
      </Stack>
      <ShareProfileModal
        open={showShareProfileModal}
        onClose={() => setShowShareProfileModal(false)}
        isArtist={type === USER_TYPE.ARTIST}
        artistName={name || ''}
      />
    </StyledScrollBar>
  );
};
export default StreamArtistDetailWithArchivedVideos;

const StyledScrollBar = styled(ScrollBar)(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  overflowY: 'auto',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const BIO_LENGTH = 170;

const StyledArtistName = styled(Typography)(({ theme }) => ({
  maxWidth: 120,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('md')]: {
    maxWidth: 180,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 'initial',
  },
}));
const YellowLabel = styled('span')(({ theme }) => ({
  color: theme.palette.action.active,
  '&:hover': {
    cursor: 'pointer',
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  padding: ` ${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
  backgroundColor: alpha(theme.palette.text.primary, 0.21),
  borderRadius: theme.shape.borderRadius,
}));

const VideoContainer = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}));

const TitleOverlay = styled(Box)<{ isExpanded: boolean }>(({ isExpanded }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  transition: 'width 0.5s ease',
  width: isExpanded ? 'auto' : '60px',
}));

const LabelOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(0,0,0,0.2)',
  padding: 2,
  borderRadius: 6,
}));

// Text title with transition effect
const TitleText = styled(Typography)<{ isExpanded: boolean }>(
  ({ isExpanded }) => ({
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: isExpanded ? '200px' : '20px',
    transition: 'max-width 0.5s ease',
    paddingRight: isExpanded ? '8px' : '0',
  }),
);
