import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Button } from '~components/atoms';
import { alpha } from '@mui/material/styles';
import { POPUP_TYPE, useGlobalDispatch } from '~utils';
import sphere from '~images/decorations-landing-page/sphere.png';

const FooterSignUp = () => {
  const dispatch = useGlobalDispatch();
  return (
    <Wrapper>
      <Title>Don't leave money on the table. </Title>
      <Description sx={{ width: { xs: '90%', sm: '80%', md: '70%' } }}>
        Join the growing community of artists earning meaningful revenue right
        from the comfort of their own homes.
      </Description>
      <CustomButton
        onClick={() => {
          dispatch({
            type: 'app',
            payload: {
              popup: POPUP_TYPE.SIGN_UP,
            },
          });
        }}
      >
        SIGN UP NOW
      </CustomButton>
      <CustomButton
        sx={{ marginTop: 4 }}
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_APPLY_SERIES_NIGHT_FORM_URL}`;
        }}
      >
        Apply to participate in a Series Night
      </CustomButton>

      <SphereImage src={sphere} alt="Sphere" />
    </Wrapper>
  );
};

export default FooterSignUp;

const Wrapper = styled(Box)(({ theme }) => ({
  background:
    'linear-gradient(94.44deg, #F85711 -15.89%, #E877AE 55.16%, #539C43 112.81%)',
  padding: '54px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    padding: '180px 0',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.h6.fontSize,
  fontWeight: 700,
  zIndex: 2,
  [theme.breakpoints.up('md')]: {
    fontSize: 32,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.text.primary, 0.8),
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: 700,
  padding: 12,
  zIndex: 2,
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.h3.fontSize,
    padding: '16px 0 32px',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  background: theme.palette.action.active,
  color: theme.palette.text.secondary,
  fontWeight: 700,
  textTransform: 'uppercase',
  zIndex: 2,
}));

const SphereImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  right: -230,
  top: -130,
  transform: 'scale(0.5)',
  zIndex: 1,
  [theme.breakpoints.up('sm')]: {
    right: -100,
    transform: 'scale(1)',
    top: 22,
  },
}));
