import * as React from 'react';
import { SVGProps } from 'react';

const TriangleArrowSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="5.5"
    viewBox="0 0 9 5.5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor={'pointer'}
    {...props}
  >
    <path
      d="M4.99999 5.79199L0.291992 1.06199L1.06199 0.291992L4.99999 4.22899L8.93799 0.291992L9.70799 1.06199L4.99999 5.79199Z"
      fill="white"
    />
  </svg>
);

export default TriangleArrowSVG;
