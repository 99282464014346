import {
  delRequest,
  getRequest,
  postRequest,
  putRequest,
  Response,
} from '~utils';
import {
  RewardCampaign,
  RewardPageResponse,
  RewardWinner,
} from '~types/reward';

export const getRewardPageData = async (
  artistId: string,
): Promise<RewardPageResponse> => {
  return await getRequest({
    path: `rewards/${artistId}`,
  });
};

export const getUpComingCampaigns = async (
  limit: number,
  offset: number,
): Promise<Response<RewardCampaign[]>> => {
  return await getRequest({
    path: 'rewards/upcoming-campaigns',
    config: {
      params: { limit, offset },
    },
  });
};

export const getPreviousRewardCampaigns = async (
  limit: number,
  offset: number,
): Promise<Response<RewardCampaign[]>> => {
  return await getRequest({
    path: 'rewards/ended-campaigns',
    config: {
      params: { limit, offset },
    },
  });
};

export const getLatestCampaign = async (
  artistId: string,
): Promise<{ campaign: RewardCampaign }> => {
  return await getRequest({
    path: `rewards/latest/${artistId}`,
  });
};

export const createRewardCampaign = async (
  campaign: RewardCampaign,
): Promise<Response<RewardCampaign>> => {
  return await postRequest({
    path: 'rewards/create-campaign',
    data: {
      startedAt: campaign.startedAt,
      endedAt: campaign.endedAt,
      rewards: campaign.rewards,
    },
  });
};

export const updateRewardCampaign = async (
  campaign: RewardCampaign,
): Promise<Response<RewardCampaign>> => {
  return await putRequest({
    path: `rewards/${campaign.id}`,
    data: {
      startedAt: campaign.startedAt,
      endedAt: campaign.endedAt,
      rewards: campaign.rewards,
    },
  });
};

export const deleteRewardCampaign = async (
  campaignId: string,
): Promise<void> => {
  await delRequest({
    path: `rewards/${campaignId}`,
  });
};

export const setRewardDelivered = async (
  rewardId: string,
): Promise<Response<RewardWinner>> => {
  return await putRequest({
    path: `rewards/delivery/${rewardId}`,
  });
};
