import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import {
  default as MuiPhoneNumberMUI,
  MuiPhoneNumberProps,
} from 'material-ui-phone-number';
import { ExclamationMarkSVG } from '~components/icons';

interface Props {
  errorMessage?: string;
  value: string;
}

const MuiPhoneNumber = ({
  className,
  error,
  value,
  errorMessage,
  ...rest
}: MuiPhoneNumberProps & Props) => {
  return (
    <Box sx={{ width: '100%' }} className={className}>
      <CustomMuiPhoneNumber
        defaultCountry={'us'}
        preferredCountries={['us', 'vn', 'ua']}
        disableAreaCodes={true}
        variant="outlined"
        fullWidth
        error={!!error}
        value={value}
        placeholder={'+12087444178'}
        disableCountryCode={value.includes('+') && value.length === 1}
        {...rest}
      />
      {errorMessage && (
        <ErrorMessage>
          <ExclamationMarkSVG />
          {errorMessage}
        </ErrorMessage>
      )}
    </Box>
  );
};

export default MuiPhoneNumber;

const CustomMuiPhoneNumber = styled(MuiPhoneNumberMUI)<{ error: boolean }>(
  ({ theme, error }) => ({
    backgroundColor: 'white',
    height: 38,
    fontSize: 12,
    color: 'black',
    '.MuiFormControl-root': {
      borderRadius: 4,
    },
    '.MuiInputBase-input': {
      borderRadius: 4,
      color: theme.palette.text.primary,
      paddingTop: 8.5,
      paddingBottom: 8.5,
      '::placeholder': {
        color: theme.palette.text.primary,
        opacity: 0.5,
      },
    },
    '&.MuiTextField-root': {
      height: '100%',
      border: error ? `2px solid ${theme.palette.error.main}` : '',
      background: theme.palette.primary.main,
    },
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '& svg': { height: 20 },
  }),
);

const ErrorMessage = styled(Typography)(({ theme }) => ({
  marginTop: 8,
  display: 'flex',
  gap: 6,
  color: theme.palette.error.main,
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 500,
}));
