import * as React from 'react';
import { SVGProps } from 'react';

const BankSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M.333 14h15.333v1.667H.333V14Z" fill="#6FABE6" />
    <path d="M15.666 14v1H2.333a1 1 0 0 1-1-1h14.333Z" fill="#82BCF4" />
    <path d="M1.333 12.667h13.333V14H1.333v-1.333Z" fill="#6FABE6" />
    <path
      d="M14.666 12.667v.666H3a.667.667 0 0 1-.667-.666h12.333Z"
      fill="#82BCF4"
    />
    <path
      d="M6.666 11.333h2.667v1.334H6.666v-1.334ZM6.666 6h2.667v1.333H6.666V6ZM7.333 7.333h1.333v4H7.333v-4ZM11 11.333h2.667v1.334H11v-1.334ZM11 6h2.667v1.333H11V6ZM11.666 7.333h1.333v4h-1.333v-4ZM5 12.667H2.333v-1.334H5v1.334ZM2.333 6H5v1.333H2.333V6Z"
      fill="#DAD7E5"
    />
    <path
      d="M5 6v.667H3.667A.667.667 0 0 1 3 6h2ZM5 11.333V12H3.667A.666.666 0 0 1 3 11.333h2ZM9.333 6v.667H8A.667.667 0 0 1 7.333 6h2ZM9.333 11.333V12H8a.666.666 0 0 1-.667-.667h2ZM13.666 6v.667h-1.333A.667.667 0 0 1 11.666 6h2ZM13.666 11.333V12h-1.333a.666.666 0 0 1-.667-.667h2Z"
      fill="#EDEBF2"
    />
    <path d="M3 7.333h1.333v4H3v-4Z" fill="#DAD7E5" />
    <path
      d="M4.333 7.333c0 4.214-.034 4 0 4a.667.667 0 0 1-.667-.666V7.333h.667ZM8.667 7.333c0 4.214-.034 4 0 4A.667.667 0 0 1 8 10.667V7.333h.667ZM13 7.333c0 4.214-.034 4 0 4a.667.667 0 0 1-.667-.666V7.333H13Z"
      fill="#EDEBF2"
    />
    <path d="M15 4.333V6H1V4.333l7-4 7 4Z" fill="#6FABE6" />
    <path d="M15 4.333v1H3a1 1 0 0 1-1-1L8.5.62 15 4.333Z" fill="#82BCF4" />
    <path d="M9 3.667c0 1.333-2 1.306-2 0a1 1 0 1 1 2 0Z" fill="#DAD7E5" />
    <path
      d="M9 3.667a.353.353 0 0 1 0 .083 1 1 0 0 1-1.667-.83c0-.04.24-.17.373-.21A1 1 0 0 1 9 3.667Z"
      fill="#EDEBF2"
    />
  </svg>
);

export default BankSVG;
