import React from 'react';

import {
  // MobileDatePicker as MUIDatePicker,
  DatePicker as MUIDatePicker,
  // MobileDatePickerProps as MUIDatePickerProps,
  DatePickerToolbar,
  DatePickerToolbarProps,
  PickersActionBar,
  PickersActionBarProps,
  MobileDatePickerProps,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import { alpha, Box, styled, TextField } from '@mui/material';
import { Label } from '~components/atoms';
import { Theme } from '@mui/material/styles/createTheme';

interface DatePickerProps extends MobileDatePickerProps<Dayjs> {
  errorMessage?: string | null;
  placeholder?: string | null;
  preventTyping?: boolean;
}

const DatePicker = ({
  label,
  errorMessage,
  placeholder,
  preventTyping,
  ...rest
}: DatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Wrapper>
        {label && <Label>{label}</Label>}
        <MUIDatePicker
          slots={{
            textField: CustomTextField,
            toolbar: CustomToolbar,
            actionBar: CustomActionBar,
          }}
          slotProps={{
            textField: {
              placeholder: placeholder || 'Select date',
              error: !!errorMessage,
              helperText: errorMessage,
              InputProps: preventTyping
                ? { onKeyDown: (e) => e.preventDefault() }
                : {},
            },
            layout: {
              sx: CustomDatePickerWindow,
            },
          }}
          format={'MMMM DD, YYYY'}
          {...rest}
        />
      </Wrapper>
    </LocalizationProvider>
  );
};

export default DatePicker;

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& svg.MuiSvgIcon-root': {
    color: theme.palette.darkText.main,
  },
}));

const CustomToolbar = styled((props: DatePickerToolbarProps<Dayjs>) => (
  <DatePickerToolbar {...props} />
))(({ theme }) => ({
  background: theme.palette.background.default,
  '.MuiTypography-overline': {
    display: 'none',
  },
  '.MuiDatePickerToolbar-title': {
    fontWeight: 400,
    fontSize: 34,
  },
}));

const CustomDatePickerWindow = (theme: Theme) => ({
  background: theme.palette.background.default,
  '.MuiDayCalendar-weekDayLabel': {
    color: alpha(theme.palette.text.primary, 0.6),
  },
  // Todo: Remove important
  '.Mui-selected': {
    background: `${theme.palette.action.active} !important`,
    color: `${theme.palette.text.secondary} !important`,
  },
  '.Mui-disabled': {
    color: `${theme.palette.text.disabled} !important`,
  },
  '.MuiSvgIcon-root': {
    fill: theme.palette.text.primary,
  },
});

const CustomActionBar = styled((props: PickersActionBarProps) => (
  <PickersActionBar {...props} />
))(({ theme }) => ({
  '.MuiButton-root': {
    color: theme.palette.action.active,
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '.MuiInputBase-input': {
    background: theme.palette.primary.main,
    borderRadius: 4,
    padding: '4px 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '8px 10px',
    },
  },
  '.MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '.MuiInputLabel-root': {
    color: theme.palette.text.primary,
  },
}));
