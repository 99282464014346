import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import useUserAgent from './useUserAgent';
import { useGlobalState } from '~utils/container';

export interface PostLiveCastMetric {
  livecastId: string;
  totalView: number;
  averageTimeWatched: number;
  totalHoursWatched: number;
  artistId: string;
  duration: number;
}
const useGa4EventSender = () => {
  const userAgent = useUserAgent();
  const {
    user: { type },
  } = useGlobalState();

  const sendStartLiveCastEvent = useCallback(
    (userId: string, livecastId: string, createdDate: string) => {
      if (type) {
        ReactGA.event('live_cast_start', {
          time: createdDate,
          artist_id: userId,
          livecast_id: livecastId,
          account_type: type,
        });
      }
    },
    [type],
  );

  const sendClickEventToCurrentLiveCast = useCallback(
    (artistId: string, fanId: string, livecastId: string) => {
      if (type) {
        ReactGA.event('live_cast_clicked_from_homepage', {
          artist_id: artistId,
          fan_id: fanId,
          livecast_id: livecastId,
          account_type: fanId ? type : 'ANONYMOUS',
        });
      }
    },
    [type],
  );

  const sendSingleUserDataWatchingLivecast = useCallback(
    (artistId: string, fanId: string, livecastId: string) => {
      if (userAgent && type) {
        ReactGA.event('viewer_data_watching_livecast', {
          artist_id: artistId,
          fan_id: fanId,
          livecast_id: livecastId,
          device_information: userAgent.device,
          browser: userAgent.browser,
          os: userAgent.os,
          account_type: fanId ? type : 'ANONYMOUS',
        });
      }
    },
    [userAgent],
  );

  const sendCurrentAttendeeOnLiveCast = useCallback(
    (artistId: string, livecastId: string, attendees: number) => {
      if (type) {
        ReactGA.event('live_cast_current_attendee', {
          event_category: 'livecast',
          event_label: 'live_cast_current_attendee',
          value: attendees,
          artist_id: artistId,
          livecast_id: livecastId,
          attendees: attendees,
          account_type: type,
        });
      }
    },
    [type],
  );

  const sendMetricWhenLiveCastEnd = useCallback(
    (postLivecastMetric: PostLiveCastMetric) => {
      if (type) {
        ReactGA.event(`live_cast_end_metric`, {
          artist_id: postLivecastMetric.artistId,
          livecast_id: postLivecastMetric.livecastId,
          everage_time_watched: postLivecastMetric.averageTimeWatched,
          total_hour_watch: postLivecastMetric.totalHoursWatched,
          total_view: postLivecastMetric.totalView,
          duration: postLivecastMetric.duration,
          account_type: type,
        });
      }
    },
    [type],
  );

  const sendDownloadCalendarClickEvent = useCallback(
    (artistId: string, eventId: string, calendarType: string) => {
      ReactGA.event('download_calendar_click_event', {
        artist_id: artistId,
        ursalive_event_id: eventId,
        calendar_type: calendarType,
      });
    },
    [type],
  );

  return {
    sendStartLiveCastEvent,
    sendClickEventToCurrentLiveCast,
    sendCurrentAttendeeOnLiveCast,
    sendMetricWhenLiveCastEnd,
    sendSingleUserDataWatchingLivecast,
    sendDownloadCalendarClickEvent,
  };
};

export default useGa4EventSender;
