import React from 'react';
import { Button, ModalWindow } from '~components/atoms';
import { Box, ModalProps, styled, Typography } from '@mui/material';

interface Props extends Omit<ModalProps, 'children'> {
  onClose: () => void;
  confirmAction: () => void;
  title: string;
}

const ConfirmationModal = ({ open, onClose, confirmAction, title }: Props) => {
  return (
    <ModalWindow open={open} onClose={onClose}>
      <Wrapper>
        <Title>{title}</Title>
        <Box
          sx={{
            display: 'flex',
            gap: 2.5,
            alignItems: 'stretch',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={(theme) => ({
              border: `1px solid ${theme.palette.action.active}`,
            })}
            typeStyles={'cancel'}
            onClick={confirmAction}
          >
            Yes
          </Button>
          <Button onClick={onClose}>No</Button>
        </Box>
      </Wrapper>
    </ModalWindow>
  );
};
const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
}));

export default ConfirmationModal;
const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: theme.typography.fontSize,
  fontWeight: 700,
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h4.fontSize,
  },
}));
