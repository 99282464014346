import { useSelector } from 'react-redux';
import { RootState } from '~stores';
import { useCallback } from 'react';

export const useFeatureFlag = () => {
  const { featureFlags } = useSelector((state: RootState) => state.common);

  const isEnable = useCallback(
    (key: string) => {
      if (key in featureFlags) {
        return featureFlags[key].enabled;
      }
      return false;
    },
    [featureFlags],
  );
  return { isEnable };
};
