import * as React from 'react';
import { SVGProps } from 'react';

const ShakeHandSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M10.8837 20.3457C11.0732 20.3457 11.2499 20.3198 11.4136 20.2681C11.5774 20.2164 11.7368 20.113 11.8919
       19.9579L20.7074 11.1424C21.0866 10.746 21.3968 10.2892 21.6381 9.77221C21.8794 9.25517 22 8.69504 22 8.09183C22 
       7.43691 21.888 6.85094 21.6639 6.3339C21.4399 5.81686 21.121 5.3429 20.7074 4.91204L16.8555 1.0601C16.5108 
       0.73264 16.123 0.47412 15.6921 0.284539C15.2613 0.0949584 14.8045 0.000167847 14.322 0.000167847C13.7705 
       0.000167847 13.2879 0.0906497 12.8743 0.271613C12.4606 0.452577 12.0901 0.706788 11.7626 1.03425L11.3749 
       1.44788L15.1751 5.24811C15.4681 5.5411 15.6921 5.81686 15.8472 6.07538C16.0023 6.3339 16.0799 6.66135 16.0799 
       7.05775C16.0799 7.7299 15.8731 8.26849 15.4595 8.6735C15.0458 9.07851 14.5029 9.28102 13.8308 9.28102C13.3655 
       9.28102 12.9949 9.19915 12.7192 9.03542C12.4434 8.8717 12.159 8.64334 11.866 8.35035L9.3584 5.84271L3.77438 
       11.4267C3.63651 11.5646 3.52448 11.7283 3.43831 11.9179C3.35213 12.1075 3.30905 12.2971 3.30905 12.4867C3.30905 
       12.7624 3.41676 13.0037 3.6322 13.2105C3.84763 13.4173 4.10184 13.5207 4.39483 13.5207C4.60164 13.5207 4.78692 
       13.4949 4.95065 13.4432C5.11438 13.3915 5.25656 13.2967 5.3772 13.1588L9.74618 8.76398L10.2891 9.30687L5.89424 
       13.7017C5.7736 13.8396 5.67881 13.9861 5.60987 14.1412C5.54093 14.2963 5.50646 14.4686 5.50646 14.6582C5.50646 
       14.9512 5.61418 15.2054 5.82961 15.4209C6.04504 15.6363 6.29064 15.744 6.56639 15.744C6.77321 15.744 6.96279 
       15.7095 7.13513 15.6406C7.30748 15.5717 7.45398 15.4769 7.57462 15.3562L11.9177 11.0131L12.4606 11.556L7.98825 
       16.0542C7.83314 16.1921 7.72973 16.3386 7.67803 16.4937C7.62632 16.6488 7.60047 16.8298 7.60047 17.0366C7.60047 
       17.3296 7.70819 17.5795 7.92362 17.7863C8.13905 17.9931 8.38465 18.0965 8.6604 18.0965C8.84998 18.0965 9.02664 
       18.0707 9.19036 18.019C9.35409 17.9673 9.5049 17.8725 9.64277 17.7346L14.1669 13.2105L14.7098 13.7534L10.1857 
       18.2516C10.0478 18.4068 9.94438 18.5662 9.87544 18.7299C9.8065 18.8936 9.77203 19.0703 9.77203 19.2599C9.77203 
       19.5529 9.88406 19.8071 10.1081 20.0225C10.3322 20.2379 10.5907 20.3457 10.8837 20.3457ZM2.61105 11.4526L9.3584 
       4.73108L12.4348 7.80746C12.6588 8.04874 12.8915 8.22971 13.1328 8.35035C13.3741 8.47099 13.5981 8.53131 13.8049 
       8.53131C14.2358 8.53131 14.5891 8.38913 14.8649 8.10476C15.1406 7.82038 15.2785 7.47138 15.2785 7.05775C15.2785 
       6.86817 15.2268 6.66997 15.1234 6.46316C15.02 6.25634 14.8476 6.03229 14.6063 5.79101L9.84959 1.0601C9.47043 
       0.698171 9.07403 0.431034 8.6604 0.258687C8.24677 0.086341 7.74696 0.000167847 7.16099 0.000167847C6.64395 
       0.000167847 6.18723 0.0992672 5.79083 0.297465C5.39444 0.495664 5.04113 0.749875 4.7309 1.0601L1.03408 
       4.78278C0.70662 5.11024 0.452409 5.49371 0.271445 5.93319C0.0904818 6.37267 0 6.86817 0 7.41968C0 7.95395 
       0.0947904 8.4279 0.284371 8.84153C0.473952 9.25517 0.723854 9.60848 1.03408 9.90147L2.61105 11.4526Z`}
      fill="white"
    />
  </svg>
);

export default ShakeHandSVG;
