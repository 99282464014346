export const QUERY_KEYS = {
  ARTIST_PAYOUT_INFO: 'artist-payout-info',
  ARTIST_EVENTS: 'artists-events',
  ARTIST_INFO: 'artist-info',
  ARTIST_REWARDS: 'artist-rewards',
  ARTIST_QUESTIONS: 'artists-questions',
  ARTIST_TIPS: 'artist-tips',
  ARTIST_TIP_HISTORY: 'artist-tip-history',
  CHAT_MESSAGE_HISTORY: 'chat-message-history',
  ARTIST_FOLLOWING_LIST: 'artist-following-list',
  ARTIST_BLOCKED_USERS_LIST: 'artist-blocked-user-list',
  LANDING_PAGE_DATE: 'landing_page_date',
  LANDING_PAGE_BANNER_EVENTS: 'landing_page_banner_events',
  EVENT_PAGE_EVENT: 'landing_page_banner_events',
  LANDING_EVENTS_ONGOING: 'landing_events_ongoing',
  LANDING_EVENTS_UPCOMING: 'landing_events_upcoming',
  LANDING_EVENTS_ENDED: 'landing_events_ended',
  REPORTS_DATA: 'reports_data',
  REPORTS_MORE: 'reports_more',
  REWARDS_LATEST_CAMPAIGN: 'rewards_latest_campaign',
  LATEST_PAYMENT_INFO: 'latest_payment_info',
  LATEST_PARTNERSHIP_PROGRAM: 'latest_partnership_program',
  PAST_EVENT_ID: 'past_event_id',
  LANDING_PAGE_SEARCH_ARTIST: 'landing_page_search_artist',
  SENDGRID_UNSUBSCRIBE_CONTACT_ID: 'sendgrid_unsubscribe_contact_id',
  CUSTOMER_ADDRESS: 'customer_address',
  PURCHASE_HISTORY: 'purchase_history',
  BANNER_UI: 'banner_ui',
} as const;
