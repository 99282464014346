import React, { useEffect, useMemo, useState } from 'react';
import { Divider, List, Stack, Typography, styled } from '@mui/material';
import {
  mappingTipData,
  strictValidArray,
  strictValidArrayWithLength,
} from '~utils/commonUtils';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import { USER_TYPE } from '~utils/constants';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { ScrollBar } from '~components/atoms';
import { pxToRem } from '../../../styles/theme/typography';
import { Tipper } from '~types/artist';
import ArtistTipItem from './artist-tip-item';
import ArtistTipRewardsCountDown from './artist-tip-rewards';
import { useQuery } from '@tanstack/react-query';
import { getTipsList } from '~api/transaction';
import { QUERY_KEYS } from '~constants/query-keys';
import { formatMoney } from '~utils/formatters';
import { getRewardPageData } from '~api/reward';
import { TransactionTipView } from '~types/transaction';

const StyledTipContainer = styled(ScrollBar)(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100% - 80px)',
  marginTop: theme.spacing(1.5),
  paddingRight: theme.spacing(1),
}));

const ViewMore = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  fontWeight: '500',
  textAlign: 'center',
  marginBottom: theme.spacing(1.5),
}));

ViewMore.defaultProps = {
  variant: 'body1',
  fontSize: {
    xs: pxToRem(12),
  },
};

const ArtistTip = () => {
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();

  const [showAll, setShowAll] = useState<boolean>(false);

  const [sanitizedTips, setSanitizedTips] = useState<Tipper[]>([]);

  const {
    artist: {
      currentEvent,
      id,
      tips_chronologically,
      top_tips,
      total_earned_in_event,
    },
    user: { type, id: userId },
  } = globalState;

  useQuery({
    queryKey: [QUERY_KEYS.ARTIST_REWARDS],
    queryFn: () => getRewardPageData(id),
    enabled: !!id,
    onSuccess: (data) => {
      dispatch({
        type: 'app',
        payload: {
          activeCampaign: data.activeCampaign,
          upcomingCampaigns: data.upcomingCampaigns ?? [],
          previousCampaigns: data.previousCampaigns ?? [],
          totalPreviousCampaign: data.totalPreviousCampaign,
          totalUpcomingCampaign: data.totalUpcomingCampaign,
        },
      });
    },
  });

  useQuery({
    queryKey: [QUERY_KEYS.ARTIST_TIPS],
    queryFn: () => getTipsList(id, currentEvent),
    refetchOnWindowFocus: false,
    onSuccess: (data: { transactions: Tipper[]; totalEarned: number }) => {
      dispatch({
        type: 'artist',
        payload: {
          top_tips: data.transactions,
          total_earned_in_event: data.totalEarned,
        },
      });
    },
  });

  const topTipRecords = useMemo((): {
    totalEarned: number;
    topTips: TransactionTipView[];
    hasMore: boolean;
  } => {
    return {
      totalEarned: total_earned_in_event ?? 0,
      topTips: top_tips ?? [],
      hasMore: (top_tips ?? []).length > 3,
    };
  }, [top_tips, total_earned_in_event]);

  useEffect(() => {
    if (tips_chronologically?.length >= 0) {
      const nextTips = orderBy(
        uniqBy((tips_chronologically as Tipper[]).filter(Boolean), 'createdAt'),
        ['createdAt'],
        ['desc'],
      );
      setSanitizedTips(nextTips);
    }
  }, [tips_chronologically]);

  return (
    <Stack height={'100%'} pt={1}>
      <ArtistTipRewardsCountDown />
      <StyledTipContainer>
        {type === USER_TYPE.ARTIST && (
          <Typography variant="subtitle2">
            {topTipRecords.totalEarned > 0
              ? `Total earned = ${
                  topTipRecords?.totalEarned
                } lc (or ${formatMoney(
                  (topTipRecords?.totalEarned as number) / 10,
                )})`
              : `There are no tips submitted yet. Please encourage your fans to tip.`}
          </Typography>
        )}
        {!strictValidArrayWithLength(topTipRecords.topTips) &&
          (type === USER_TYPE.FAN || (!type && !userId)) && (
            <Typography variant="subtitle2">
              Please tap TIP to support this artist.
            </Typography>
          )}
        {strictValidArray(topTipRecords.topTips) && (
          <>
            <List dense>
              {topTipRecords.topTips
                .slice(0, showAll ? 10 : 3)
                .map((item, index: number) => (
                  <ArtistTipItem
                    key={`${item.displayUsername}_${index}`}
                    item={mappingTipData(item)}
                    index={index}
                    isTopTip
                  />
                ))}
            </List>
            {topTipRecords.hasMore && (
              <ViewMore onClick={() => setShowAll((prev) => !prev)}>
                {showAll ? 'View less' : 'View more'}
              </ViewMore>
            )}
            <Divider sx={{ my: 1.5 }} />
          </>
        )}

        <List dense>
          {sanitizedTips.map((item, index) => (
            <ArtistTipItem
              key={`${item.fan_id}_${index}`}
              index={index}
              item={{
                ...item,
                fanId: item.fan_id,
                displayUsername: item.display_username,
              }}
            />
          ))}
        </List>
      </StyledTipContainer>
    </Stack>
  );
};

export default ArtistTip;
