import { useParams } from 'react-router-dom';
import { Modal, Stack, Typography } from '@mui/material';
import PrimaryButton from '~components/atoms/primary-button';
import popupStyles from '~components/popup/popupStyles';

interface ChangePasswordDialogProps {
  passwordKey: string;
  show: boolean;
  onClose: () => void;
}
const styles = {
  popup: [popupStyles.popup, { width: { xs: '90%', sm: 400 } }],
  buttonsContainer: {
    p: 2,
  },
};

const ChangePasswordDialog = ({
  show,
  onClose,
  passwordKey,
}: ChangePasswordDialogProps) => {
  const { username } = useParams();

  const changePassword = () => {
    window.location.href = `/reset_password/${passwordKey}?redirect_url=/${username}`;
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Stack sx={styles.popup} direction="column">
        <Typography variant="h6">Please change your password</Typography>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
          sx={styles.buttonsContainer}
        >
          <PrimaryButton onClick={changePassword}>
            Change Password
          </PrimaryButton>
          <PrimaryButton onClick={onClose}>Cancel</PrimaryButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ChangePasswordDialog;
