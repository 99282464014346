import { Dispatch } from 'react';

export class MessagingClient {
  dispatch: Dispatch<unknown>;

  constructor(dispatch: Dispatch<unknown>) {
    this.dispatch = dispatch;
  }
  processMessage = (data: any) => {
    try {
      this.dispatch({
        type: 'socket_event_received',
        payload: data,
      });
    } catch (error) {
      // FIXME: Integrate the FE app with Datadog to consume the error log.
      console.error('Error parsing message');
    }
  };
}
