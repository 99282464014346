import { useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Button, IconButton, Stack, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { resetPassword } from '~api/user';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { CircleSuccessTickSVG } from '~components/icons';
import { FormProvider, RHFTextField } from '~components/molecules';
import BasePopup from '../base-popup';
import { ResetPasswordInputs, resetPasswordSchema } from './validation-schemes';

const StyledResetPasswordModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  },
  [theme.breakpoints.up('sm')]: {
    width: 518,
  },
}));

const StyledCloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: -12,
  top: -8,
}));

const ResetPasswordModal = () => {
  const { key } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [searchParams] = useSearchParams();
  const { hidePopup } = useDispatchPopup();
  const navigate = useNavigate();
  const redirectUrl = searchParams.get('redirect_url');

  const methods = useForm<ResetPasswordInputs>({
    defaultValues: {
      password: '',
      confirm_password: '',
    },
    resolver: yupResolver(resetPasswordSchema),
  });
  const { handleSubmit, reset } = methods;

  const handleClose = () => {
    hidePopup();
    navigate(redirectUrl || '/');
  };

  const onSubmit = async (values: ResetPasswordInputs) => {
    try {
      setSubmitting(true);
      const response = await resetPassword(key as string, values.password);
      if (response && response.status === 200) {
        setSuccessMsg('Password successfully changed.');
        setTimeout(handleClose, 3000);
      }
    } catch (err) {
      reset();
      setSubmitting(false);
    }
  };

  return (
    <StyledResetPasswordModal
      open={true}
      onClose={handleClose}
      closeBtn={
        <StyledCloseButton onClick={handleClose}>
          <CloseIcon
            sx={{
              color: 'white',
            }}
          />
        </StyledCloseButton>
      }
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography
          textTransform={'uppercase'}
          mb={3}
          fontWeight={'700'}
          fontSize={{ xs: 20 }}
        >
          Reset password
        </Typography>

        {successMsg ? (
          <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
            <CircleSuccessTickSVG />
            <Typography variant="body1">{successMsg}</Typography>
          </Stack>
        ) : (
          <Stack spacing={3}>
            <RHFTextField
              label="New password"
              name={'password'}
              type="password"
              placeholder="Enter your password"
            />
            <RHFTextField
              label="Confirm New password"
              name={'confirm_password'}
              type="password"
              placeholder="Enter your new password"
            />
            <Stack direction={'row'} spacing={2} justifyContent={'center'}>
              <Button
                color="warning"
                variant="outlined"
                disabled={isSubmitting}
                onClick={handleClose}
                sx={{ textTransform: 'capitalize' }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="warning"
                variant="contained"
                disabled={isSubmitting}
                sx={{ textTransform: 'capitalize' }}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        )}
      </FormProvider>
    </StyledResetPasswordModal>
  );
};

export default ResetPasswordModal;
