import React, { useEffect, useState } from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import {
  Box,
  FormControlLabel,
  FormLabel,
  Stack,
  styled,
  Switch,
  SwitchProps,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import RecordingController from '~components/organisms/popup/setting-dashboard-modal/recording-controller';
import { toastConfig, useGlobalState } from '~utils';
import { RootDispatch, RootState } from '~stores';
import { useSelector } from 'react-redux';
import {
  Button as CustomButton,
  NotificationModalWindow,
} from '~components/atoms';
import { toast, ToastOptions } from 'react-toastify';
import { useUpdateCameraAndMicrophone } from '~hooks/useCameraAndMicrophone';
import SettingDeviceSection from '~components/organisms/popup/setting-dashboard-modal/setting-device-section';
import { useRematchDispatch } from '~components/custom-hook/useRematchDispatch';
import { StreamRecorder } from '~utils/StreamRecorder';
import PlayRecordingController from '~components/organisms/popup/setting-dashboard-modal/play-recording-controller';
import IconPopover from '~components/molecules/icon-popover';
import { INPUT_LEVEL_CONFIG_DEFAULT_VALUE } from '~stores/models/settings';
import SubmitPartnershipProgramModal from '~components/organisms/popup/submit-partnership-program-modal';
import { uploadPartnerVideo } from '~utils/awsUtils';
import { uploadPartnerVideo as submitPartnerVideo } from '~api';
import { showErrorToaster } from '~utils/toasterNotification';
import { CustomRadioGroup } from '~components/molecules';
import { ScreenRatioEnum } from '~types';
import ArtistNote from '~components/organisms/popup/setting-dashboard-modal/artist-notel';
import CheckSettingNote from './check-setting-note';
import { GoLiveIcon } from '~components/icons';
import { GoLiveButton } from '../go-live-options-modal';

const SettingDashboardWindow = styled(BasePopup)(({ theme }) => ({
  margin: `${theme.spacing(2)} auto 0`,
  backgroundColor: theme.palette.primary.light,
  maxWidth: 900,
  width: '90%',
  [theme.breakpoints.up('xs')]: {
    height: 'fit-content',
    width: '90%',
  },
  [theme.breakpoints.up('lg')]: {
    margin: `${theme.spacing(4.25)} auto 0`,
    padding: `${theme.spacing(4)} ${theme.spacing(7.5)}`,
    width: '90%',
  },
}));

const RecordingContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'black',
  backgroundSize: '100% 100%',
  height: 300,
  position: 'relative',
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
  '& video': {
    objectFit: 'contain !important',
  },
  [theme.breakpoints.up('sm')]: {
    height: 400,
  },
}));

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: 'white',
  fontWeight: '700',
  '&.Mui-focused': {
    color: 'white',
  },
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.error.main,
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: theme.spacing(1.5),
  },
}));

const SettingContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2.5),
}));

const StackContainer = styled(Stack)(() => ({
  marginTop: `10px !important`,
}));

const LabelContainer = styled(Stack)(() => ({
  fontSize: `12px !important`,
}));

interface SettingDashboardModalProps {
  onClose: () => void;
  onNext?: VoidFunction;
  open: boolean;
  title: string;
  enableAEC?: boolean;
  enableRatio?: boolean;
  isPartnerShip: boolean;
  hideSaveButton?: boolean;
  isGoLiveLayout?: boolean;
}

const SCREENS_OPTIONS = [
  {
    label: '4:3',
    name: 'ratio-method',
    value: ScreenRatioEnum.RATIO_4_3,
  },
  {
    label: '16:9',
    name: 'ratio',
    value: ScreenRatioEnum.RATIO_16_9,
  },
];

const ArtistSettingDashboardModal = ({
  onClose,
  open,
  title,
  enableAEC = true,
  enableRatio = true,
  isPartnerShip,
  hideSaveButton = false,
  isGoLiveLayout = false,
  onNext = () => ({}),
}: SettingDashboardModalProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config: { localVideoTrack, localAudioTrack },
  } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [
    showSubmitPartnershipProgramModal,
    setShowSubmitPartnershipProgramModal,
  ] = useState(false);
  const [showSuccessNotificationModal, setShowSuccessNotificationModal] =
    useState(false);

  const [streamRecorder, setStreamRecorder] = useState<StreamRecorder | null>();
  const [recordingTime, setRecordingTime] = useState(0);

  const [recordingFile, setRecordingFile] = useState<Blob | null>(null);

  const {
    cameraConfigs,
    microphoneConfigs,
    selectedMicrophoneConfig,
    selectedCameraConfig,
    inputLevelConfig = INPUT_LEVEL_CONFIG_DEFAULT_VALUE,
    enableAECConfig,
    selectedScreenRatio,
  } = useSelector((state: RootState) => state.settings);
  const {
    setSelectedMicrophoneConfig,
    setSelectedCameraConfig,
    setInputLevelConfig,
    setEnableAECConfig,
    setSelectedScreenRatio,
  } = useRematchDispatch((dispatch: RootDispatch) => dispatch.settings);

  const {
    videoContainer,
    newSelectedMicrophoneConfig,
    newSelectedCameraConfig,
    vUMeterConfig,
    newInputLevelConfig,
    newSelectedScreenRatio,
    setNewSelectedMicrophoneConfig,
    setNewSelectedCameraConfig,
    setNewInputLevelConfig,
    onChangeNewSelectedScreenRatio,
    cameraTrack,
    microphoneTrack,
    newEnableAEC,
    setNewEnableAEC,
    playAudio,
    setPlayAudio,
  } = useUpdateCameraAndMicrophone(enableAECConfig);

  const handleChangeRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeNewSelectedScreenRatio(event.target.value as ScreenRatioEnum);
  };

  const handleChangeMonitorAudio = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPlayAudio(event.target.checked);
  };
  const handleChangeAEC = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewEnableAEC(event.target.checked);
  };

  const hideSubmitPartnershipProgramModal = () => {
    setShowSubmitPartnershipProgramModal(false);
  };
  const openSubmitPartnershipProgramModal = () => {
    setShowSubmitPartnershipProgramModal(true);
  };

  const handleStartRecordingAgain = () => {
    setRecordingFile(null);
    cameraTrack?.stop();
    microphoneTrack?.stop();
    if (videoContainer.current) {
      cameraTrack?.play(videoContainer.current, {
        mirror: true,
      });
      microphoneTrack?.play();
    }
  };
  const handleStopPlayingRecoding = () => undefined;
  const handlePlayRecoding = async () => undefined;

  const handleStartRecording = async () => {
    streamRecorder?.start();
  };
  const handleStopRecording = (recordingTime: number) => {
    setRecordingTime(recordingTime);
    streamRecorder?.stop();
    cameraTrack?.stop();
    microphoneTrack?.stop();
  };

  const onSave = async () => {
    try {
      setLoading(true);
      if (
        newSelectedCameraConfig &&
        newSelectedCameraConfig != selectedCameraConfig
      ) {
        setSelectedCameraConfig(newSelectedCameraConfig);
        if (localVideoTrack) {
          await localVideoTrack.setDevice(newSelectedCameraConfig.deviceId);
        }
      }

      if (
        newSelectedMicrophoneConfig &&
        newSelectedMicrophoneConfig !== selectedMicrophoneConfig
      ) {
        setSelectedMicrophoneConfig(newSelectedMicrophoneConfig);
        if (localAudioTrack) {
          await localAudioTrack.setDevice(newSelectedMicrophoneConfig.deviceId);
        }
      }
      if (newInputLevelConfig != inputLevelConfig) {
        setInputLevelConfig(newInputLevelConfig);
        if (localAudioTrack) {
          await localAudioTrack.setVolume(newInputLevelConfig);
        }
      }
      if (newEnableAEC != enableAECConfig) {
        // only can change this if there is no broadcast
        setEnableAECConfig(newEnableAEC);
      }
      if (newSelectedScreenRatio !== selectedScreenRatio) {
        setSelectedScreenRatio(newSelectedScreenRatio);
      }
      onClose();
      onNext();
    } catch (err) {
      if (err instanceof Error) {
        toast(err.message, toastConfig as ToastOptions);
        return;
      }
      toast("Can't update device setting", toastConfig as ToastOptions);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadVideo = async () => {
    try {
      const videoPath = await uploadPartnerVideo(recordingFile);
      await submitPartnerVideo(videoPath);
      setShowSuccessNotificationModal(true);
    } catch (err) {
      showErrorToaster(err);
    }
  };

  useEffect(() => {
    if (cameraTrack && microphoneTrack) {
      const streamRecorder = new StreamRecorder([cameraTrack, microphoneTrack]);
      streamRecorder.addCallback(() => {
        setRecordingFile(streamRecorder.getBlob());
      });
      setStreamRecorder((prevState) => {
        prevState?.stop();
        return streamRecorder;
      });
    }
  }, [cameraTrack, microphoneTrack]);

  const renderSaveButton = isGoLiveLayout ? (
    <GoLiveButton onClick={onSave} startIcon={<GoLiveIcon />}>
      GO LIVE
    </GoLiveButton>
  ) : (
    <CustomButton
      sx={{ textTransform: 'uppercase' }}
      typeStyles={'big'}
      onClick={onSave}
      disabled={loading}
    >
      Save
    </CustomButton>
  );

  return (
    <SettingDashboardWindow open={open} onClose={onClose} title={title}>
      <Stack sx={{ marginTop: 2 }}>
        {isGoLiveLayout && <CheckSettingNote />}
        <RecordingContainer ref={videoContainer}>
          {recordingFile ? (
            <PlayRecordingController
              mirror={true}
              onStartRecodingAgain={handleStartRecordingAgain}
              onStopPlayingRecoding={handleStopPlayingRecoding}
              onPlayRecoding={handlePlayRecoding}
              recordingFile={recordingFile}
              recordingTime={recordingTime}
              onSubmitVideo={openSubmitPartnershipProgramModal}
              isPartnerShip={isPartnerShip}
            />
          ) : (
            <RecordingController
              onStartRecording={handleStartRecording}
              onStopRecording={handleStopRecording}
            />
          )}
        </RecordingContainer>

        {!isPartnerShip && <ArtistNote />}
        <SettingContainer spacing={2}>
          <SettingDeviceSection
            setSelectedCameraConfig={setNewSelectedCameraConfig}
            setSelectedMicrophoneConfig={setNewSelectedMicrophoneConfig}
            selectedCameraConfig={newSelectedCameraConfig}
            selectedMicrophoneConfig={newSelectedMicrophoneConfig}
            microphoneConfigs={microphoneConfigs}
            cameraConfigs={cameraConfigs}
            vUMeter={vUMeterConfig}
            turnOffVUMeter={!!recordingFile}
            inputLevel={newInputLevelConfig}
            setInputLeve={setNewInputLevelConfig}
          >
            <>
              <StackContainer alignItems="start">
                <StyledFormLabel>Ratio</StyledFormLabel>
                <CustomRadioGroup
                  disabled={!enableRatio}
                  row={true}
                  name="follow-radio-group"
                  sxLabel={() => ({
                    marginBottom: '0 !important',
                  })}
                  sxOptionLabel={{
                    fontSize: '12px !important',
                  }}
                  value={newSelectedScreenRatio}
                  onChange={handleChangeRatio}
                  color="warning"
                  options={SCREENS_OPTIONS}
                />
              </StackContainer>
              <StackContainer
                alignItems="start"
                direction={{ xs: 'column', sm: 'row' }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  flex={1}
                  justifyContent="center"
                >
                  <FormControlLabel
                    sx={{ flex: 1, p: 0 }}
                    control={
                      <IOSSwitch
                        checked={playAudio}
                        onChange={handleChangeMonitorAudio}
                        sx={{ m: 1 }}
                      />
                    }
                    label={
                      <LabelContainer direction="row" alignItems="center">
                        Audio monitoring
                        <IconPopover text="Headphones only">
                          <HelpIcon
                            fontSize="small"
                            sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                          />
                        </IconPopover>
                      </LabelContainer>
                    }
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  flex={1}
                  justifyContent="center"
                >
                  <FormControlLabel
                    sx={{ flex: 1, p: 0 }}
                    control={
                      <IOSSwitch
                        checked={newEnableAEC}
                        onChange={handleChangeAEC}
                        sx={{ m: 1 }}
                        disabled={!enableAEC}
                      />
                    }
                    label={
                      <LabelContainer direction="row" alignItems="center">
                        Acoustic echo cancellation (AEC)
                        <IconPopover text="If you encounter an issue with echo, you can enable this option to mitigate it, but please note that it does not support stereo audio.">
                          <HelpIcon
                            fontSize="small"
                            sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                          />
                        </IconPopover>
                      </LabelContainer>
                    }
                  />
                </Stack>
              </StackContainer>
            </>
          </SettingDeviceSection>
        </SettingContainer>

        {!hideSaveButton && renderSaveButton}
      </Stack>
      {showSubmitPartnershipProgramModal && (
        <SubmitPartnershipProgramModal
          onClose={hideSubmitPartnershipProgramModal}
          open={true}
          onSubmit={handleUploadVideo}
        />
      )}
      <NotificationModalWindow
        titleOverImage="THANK YOU!"
        open={showSuccessNotificationModal}
        onClose={onClose}
        description={
          "Your video has been submitted for review. We'll be in touch soon!"
        }
      />
    </SettingDashboardWindow>
  );
};

export default ArtistSettingDashboardModal;
