import { alpha, styled, Typography } from '@mui/material';

export const Description = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  textAlign: 'center',
  margin: '0 auto',
  color: alpha(theme.palette.text.primary, 0.9),
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h4.fontSize,
  },
}));
