import * as React from 'react';
import { SVGProps } from 'react';

interface CameraSVGProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const CameraSVG = ({ color = 'white', ...rest }: CameraSVGProps) => (
  <svg
    {...rest}
    width="20"
    height="15"
    viewBox="0 0 20 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 15C1.1 15 0.75 14.8594 0.45 14.5781C0.15 14.2969 0 13.9688 0 13.5938V1.40625C0 1.03125 0.15 0.703125 0.45 0.421875C0.75 0.140625 1.1 0 1.5 0H14.5C14.9 0 15.25 0.140625 15.55 0.421875C15.85 0.703125 16 1.03125 16 1.40625V6.44531L20 2.69531V12.3047L16 8.55469V13.5938C16 13.9688 15.85 14.2969 15.55 14.5781C15.25 14.8594 14.9 15 14.5 15H1.5Z"
      fill={color}
    />
  </svg>
);

export default CameraSVG;
