import React, { useState } from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import {
  Box,
  Button,
  FormGroup,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { CustomRadioGroup } from '~components/molecules';
import { GoLiveNotifyType } from '~types';
import { GoLiveIcon } from '~components/icons';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 600,
  },
}));

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 15px',
}));

interface Props {
  open: boolean;
  onClose: () => void;
  setIsNotifyFollowers: (isClearChat: boolean) => void;
  isNotifyFollowers: boolean;
  onNext: () => void;
}

const followingOptions: {
  label: string;
  name: string;
  value: GoLiveNotifyType;
}[] = [
  {
    label: 'Go LIVE and notify my followers!',
    name: 'notifying',
    value: GoLiveNotifyType.NOTIFY,
  },
  {
    label: "DON'T notify my followers",
    name: 'withoutNotifying',
    value: GoLiveNotifyType.NONE,
  },
];

const GoLiveOptionsModal = ({
  open,
  onNext,
  onClose,
  isNotifyFollowers,
  setIsNotifyFollowers,
}: Props) => {
  const [value, setValue] = useState<string>(
    isNotifyFollowers ? GoLiveNotifyType.NOTIFY : GoLiveNotifyType.NONE,
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setValue(value);
    setIsNotifyFollowers(value === GoLiveNotifyType.NOTIFY);
  };
  return (
    <StyledModal open={open} onClose={onClose} keepMounted={false}>
      <Stack spacing={3}>
        <Typography
          fontWeight={'700'}
          fontSize={{ xs: 16, sm: 28 }}
          variant="subtitle1"
          lineHeight={'27px'}
          align="center"
          width="90%"
        >
          Livecast Options
        </Typography>
        <Wrapper>
          <FormGroup>
            <CustomRadioGroup
              name="follow-radio-group"
              value={value}
              onChange={handleChange}
              color="warning"
              options={followingOptions}
            />
          </FormGroup>
        </Wrapper>
        <Stack direction="row" spacing={1.5} flex={1} justifyContent="center">
          <GoLiveButton onClick={onNext} startIcon={<GoLiveIcon />}>
            GO LIVE
          </GoLiveButton>
        </Stack>
      </Stack>
    </StyledModal>
  );
};

export default GoLiveOptionsModal;

export const GoLiveButton = styled(Button)(({ theme }) => ({
  border: `2px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
  paddingTop: 16,
  paddingBottom: 16,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.error.main,
  width: '100%',
}));
