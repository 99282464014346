import { FC, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Typography, styled } from '@mui/material';
import { POPUP_TYPE, useGlobalDispatch, useGlobalState } from '~utils/index';
import AccountMenu from './account-menu';
import Avatar from '~components/organisms/avatar';
import useTikTokEvent from '~hooks/useTiktokPixel';

const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: 8,
  top: 8,
}));

type Props = {
  isArtistPage?: boolean;
  disabledLiveBtn?: boolean;
  onLogout: () => void;
  onLeaveEvent: () => void;
  onStartLive: () => void;
  onOpenSettingDashboard: () => void;
  avatarKey: string;
};

const AccountDrawer: FC<Props> = ({
  onLogout,
  isArtistPage = true,
  avatarKey,
  ...rest
}) => {
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const {
    user: { id: userId },
    app: { popup },
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = globalState as Record<string, any>;
  const isGuest = !userId && !popup;
  const tiktokPixel = useTikTokEvent();

  const handleToggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (isGuest) {
        tiktokPixel.trackClickEvent('click_sign_up_btn');
        dispatch({
          type: 'app',
          payload: { popup: POPUP_TYPE.LOGIN },
        });
        return;
      }

      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setToggleDrawer(open);
    };

  return (
    <Box
      display={{
        xs: 'block',
        lg: 'none',
      }}
    >
      <IconButton
        size="small"
        sx={{ p: 0 }}
        disableTouchRipple
        disableRipple
        onClick={handleToggleDrawer(true)}
      >
        {isGuest ? (
          <Typography
            variant="body2"
            fontWeight={'500'}
            color="text.primary"
            sx={{
              textTransform: 'capitalize',
              cursor: 'pointer',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Log In | Sign Up
          </Typography>
        ) : (
          <Avatar
            username={avatarKey}
            sx={{
              marginLeft: 1.5,
              width: 30,
              height: 30,
            }}
          />
        )}
      </IconButton>
      <Drawer
        anchor={'right'}
        open={toggleDrawer}
        onClose={handleToggleDrawer(false)}
      >
        <StyledIconButton
          size="small"
          sx={{ p: 0 }}
          disableTouchRipple
          disableRipple
          onClick={() => setToggleDrawer(false)}
        >
          <CloseOutlinedIcon
            sx={{
              width: 24,
              height: 24,
              color: (theme) => theme.palette.common.white,
            }}
          />
        </StyledIconButton>
        <AccountMenu
          isArtistPage={isArtistPage}
          {...rest}
          flex={1}
          onLogout={onLogout}
          onClose={() => setToggleDrawer(false)}
        />
      </Drawer>
    </Box>
  );
};

export default AccountDrawer;
