import React from 'react';
import HeroLandingPage from '~components/organisms/hero-landing-page';
import Accordions from './accordions';
import { accordionsForArtists, accordionsForFans } from './constant';
import { Box, styled, Typography } from '@mui/material';

const FaqView = () => {
  return (
    <Container>
      <HeroLandingPage
        title={<UrsaLive>URSA LIVE</UrsaLive>}
        subTitle={<FAQs>FAQs</FAQs>}
      />
      <Accordions
        sx={{ mt: { xs: 2.5, md: 6.25 } }}
        title={'For Artists'}
        accordions={accordionsForArtists}
      />
      <Accordions title={'For Fans'} accordions={accordionsForFans} />
    </Container>
  );
};

export default FaqView;

const Container = styled(Box)(({ theme }) => ({
  paddingBottom: 30,
  [theme.breakpoints.up('md')]: {
    paddingBottom: 60,
  },
}));

const UrsaLive = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  color: theme.palette.text.primary,
  fontWeight: 700,
  lineHeight: 1,
  [theme.breakpoints.up('sm')]: {
    fontSize: 40,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 60,
  },
}));
const FAQs = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  color: 'transparent',
  fontWeight: 700,
  WebkitTextStroke: `1px ${theme.palette.text.primary}`,
  lineHeight: 1,
  [theme.breakpoints.up('sm')]: {
    fontSize: 40,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 60,
  },
}));
