import React from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import { Button, Stack, Typography, styled, Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { GoLiveIcon } from '~components/icons';
const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 600,
  },
}));

const GoLiveButton = styled(Button)(({ theme }) => ({
  border: `2px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
  paddingTop: 16,
  paddingBottom: 16,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.error.main,
  width: '100%',
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  onLive: () => void;
  link: string;
  artistName: string;
}

const ConfirmShareProfileStartingLiveModal = ({
  open,
  onClose,
  onBack,
  onLive,
  link,
  artistName,
}: Props) => {
  const handleGoLive = () => {
    onLive();
  };
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      keepMounted={false}
      title="SEND NOTIFICATION"
      hideBackButton={false}
      onBack={onBack}
    >
      <Stack spacing={3}>
        <Typography
          fontWeight={'500'}
          fontSize={{ xs: '16px', sm: '20px' }}
          variant="subtitle1"
          lineHeight={{ xs: '24px', sm: '30px' }}
          align="center"
          pt={4}
        >
          The Contacts you selected will be notified with the following message
          when you go LIVE!
        </Typography>

        <Box
          sx={{
            backgroundColor: '#272C3A',
            padding: '20px',
            borderRadius: '5px',
          }}
          py={4}
        >
          <Typography
            fontWeight={'500'}
            fontSize={'14px'}
            variant="subtitle1"
            lineHeight={'20px'}
            align="center"
          >
            {`${artistName} is going LIVE on Ursa Live right now! Tap the link below
          to join! ${link}`}
          </Typography>
        </Box>

        <Stack direction="row" alignItems="center" justifyContent="center">
          <ErrorIcon sx={{ fontSize: '14px', color: '#FFFFFF80' }} />
          <Typography
            fontWeight={'400 '}
            fontSize={'14px'}
            variant="subtitle1"
            lineHeight={'16px'}
            align="left"
            color={'#FFFFFF80'}
            component={'i'}
            paddingLeft="5px"
          >
            Message will be sent out via SMS only to contacts that have phone
            number.
          </Typography>
        </Stack>

        <Stack direction="column" spacing={1.5} flex={1}>
          <GoLiveButton onClick={handleGoLive} startIcon={<GoLiveIcon />}>
            NOTIFY AND GO LIVE!
          </GoLiveButton>
        </Stack>
      </Stack>
    </StyledModal>
  );
};

export default ConfirmShareProfileStartingLiveModal;
