import React, { useContext, useMemo } from 'react';
import { useState } from 'react';
import { ToastContent, ToastOptions, toast } from 'react-toastify';

import { useGlobalDispatch, useGlobalState } from '~utils/container';
import { validObjectWithParameterKeys, clipText } from '~utils/commonUtils';
import {
  POPUP_TYPE,
  PopupTypeValues,
  USER_TYPE,
  toastConfig,
} from '~utils/constants';
import { useFilterUpcomingLiveCast } from '../../custom-hook';
import { unfollowArtist } from '~api/fan';
import { EventCardItem } from '~components/molecules';
import { useParams } from 'react-router-dom';
import Avatar from '~components/organisms/avatar';
import { LinkifyText, ScrollBar, SocialLinks } from '~components/atoms';
import { SocialTypes } from '~components/atoms/social-links';
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { ArtistEvent } from '~types/event';
import { ManageProfileContext } from '~providers/ManageProfileProvider';

const BIO_LENGTH = 170;

const StyledArtistName = styled(Typography)(({ theme }) => ({
  maxWidth: 120,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('md')]: {
    maxWidth: 180,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 'initial',
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  padding: ` ${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
  backgroundColor: alpha(theme.palette.text.primary, 0.21),
  borderRadius: theme.shape.borderRadius,
}));

const ArtistProfile = () => {
  const globalState = useGlobalState();
  const dispatch = useGlobalDispatch();
  const { openManageProfile } = useContext(ManageProfileContext);
  const [showedAll, setShowedAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const { username } = useParams();

  const {
    artist,
    user: { id: userId, type, is_following } = {},
  }: {
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    artist: Record<string, any>;
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user: Record<string, any>;
  } = globalState;
  const { id: artistId, description, genre, outsideLinks } = artist;

  const isFan = type === USER_TYPE.FAN;

  const filterEvents = useFilterUpcomingLiveCast();
  const isEmptyEvent = filterEvents?.length === 0;

  const socials = useMemo(() => {
    if (!outsideLinks) return [];
    return (
      [
        'facebook',
        'tiktok',
        'youtube',
        'instagram',
        'twitter',
        'website',
        'spotify',
      ] as SocialTypes[]
    ).reduce(
      (
        prevValue: { type: SocialTypes; href: string }[],
        currentValue: SocialTypes,
      ) => {
        if (outsideLinks[currentValue]) {
          return [
            ...prevValue,
            {
              type: currentValue,
              href: outsideLinks[currentValue],
            },
          ];
        }
        return [...prevValue];
      },
      [],
    );
  }, [outsideLinks]);

  const handleTogglePopup = (popup: PopupTypeValues) => {
    dispatch({
      type: 'app',
      payload: { popup },
    });
  };

  const handleUnFollow = async () => {
    try {
      await unfollowArtist(artistId);
      dispatch({ type: 'user', payload: { is_following: false } });
    } catch (err) {
      // TODO need to define data type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      toast(err as ToastContent<unknown>, toastConfig as ToastOptions);
    }
  };

  const handleFollow = async () => {
    if (!userId) {
      handleTogglePopup(POPUP_TYPE.LOGIN);
      return;
    }
    setLoading(true);
    if (is_following) {
      handleUnFollow();
    } else {
      handleTogglePopup(POPUP_TYPE.FOLLOW);
    }
    setLoading(false);
  };

  const renderBio = () => {
    const hasMore = description && description.length > BIO_LENGTH;
    const content = (
      <>
        {clipText(description, showedAll ? undefined : BIO_LENGTH)}
        &nbsp;
        {hasMore && (
          <Typography
            component={'span'}
            sx={{ cursor: 'pointer' }}
            variant="subtitle2"
            onClick={() => setShowedAll((prevState) => !prevState)}
          >
            {showedAll ? 'View Less' : 'View More'}
          </Typography>
        )}
      </>
    );

    if (!showedAll && hasMore) return <p>{content}</p>;

    return <LinkifyText as="p">{content}</LinkifyText>;
  };

  return (
    <ScrollBar
      sx={{
        py: 1,
        overflowX: 'hidden',
        overflowY: 'auto',
        height: 'calc(100% - 32px)',
      }}
    >
      <Stack
        sx={{
          mr: 1,
        }}
        divider={<Divider />}
        spacing={3}
      >
        <Box>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={1.5}
          >
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <Box sx={{ width: 56, height: 56 }}>
                <Avatar username={username as string} />
              </Box>
              <Box>
                <StyledArtistName
                  variant="body1"
                  lineHeight={'14px'}
                  fontWeight={'500'}
                >
                  {artist?.name ?? artist?.displayUsername ?? username}
                </StyledArtistName>
                {validObjectWithParameterKeys(genre, ['title']) && (
                  <StyledLabel variant="caption">
                    {genre?.title || ''}
                  </StyledLabel>
                )}
                <Box mt={0.25}>
                  {isFan ? (
                    <Button
                      variant="contained"
                      color="warning"
                      size="small"
                      onClick={handleFollow}
                      disabled={loading}
                      sx={{ fontSize: 10 }}
                    >
                      {is_following ? 'FOLLOWING' : 'FOLLOW'}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="warning"
                      size="small"
                      sx={{ fontSize: 10 }}
                      onClick={() => openManageProfile()}
                    >
                      Manage Profile
                    </Button>
                  )}
                </Box>
              </Box>
            </Stack>
          </Stack>
          {description && (
            <Typography variant="subtitle2" mb={0} fontWeight={300}>
              {renderBio()}
            </Typography>
          )}
        </Box>
        {!isEmptyEvent && (
          <Box>
            <Typography
              mb={1}
              variant="body1"
              textTransform={'uppercase'}
              fontWeight={500}
              textAlign={'center'}
            >
              Upcoming livecasts
            </Typography>
            <Grid
              container
              spacing={1}
              justifyContent={'center'}
              direction="row"
            >
              {filterEvents &&
                filterEvents.map((item: ArtistEvent) => (
                  <Grid item xs={12} sm={6} md={4} lg={6} key={item.event?.id}>
                    <EventCardItem
                      data={item}
                      key={item.event?.id}
                      calendarBtnGroupProps={{
                        sx: (theme) => ({
                          [theme.breakpoints.between('lg', 1440)]: {
                            flexDirection: 'column',
                            '& >:not(style)+:not(style)': {
                              margin: 0,
                              marginTop: theme.spacing(1.5),
                            },
                          },
                          [theme.breakpoints.between('lg', 'xl')]: {
                            flexDirection: 'column',
                            '& >:not(style)+:not(style)': {
                              margin: 0,
                              marginTop: theme.spacing(1.5),
                            },
                          },
                        }),
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
        {socials?.length && (
          <Box>
            <Typography
              variant="body1"
              fontSize={{ lg: 14 }}
              fontWeight={'500'}
              textAlign={'center'}
              textTransform={'uppercase'}
              mb={2.5}
            >
              Artist Links
            </Typography>
            <SocialLinks socials={socials} />
          </Box>
        )}
      </Stack>
    </ScrollBar>
  );
};

export default ArtistProfile;
