import { SVGProps } from 'react';

const TrophySilver = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="27"
    height="30"
    viewBox="0 0 27 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      // eslint-disable-next-line max-len
      d="M8.84901 14.6044C8.84901 14.6044 8.83403 14.6224 8.82803 14.6284C8.69618 14.7782 8.54933 14.8891 8.3905 14.964C8.24666 15.0299 8.09382 15.0659 7.93799 15.0659C7.86008 15.0659 7.78515 15.0569 7.70724 15.0419L8.23767 14.1099C8.5763 13.7234 8.72315 12.9052 8.54334 12.3868C8.41148 12.0092 8.17474 11.9642 8.09682 11.9523L8.06386 11.9463C6.58045 11.5447 5.24089 10.7925 4.11411 9.7856C3.9373 9.62677 3.76948 9.46195 3.60166 9.29413C3.42185 9.10833 3.24505 8.91354 3.07723 8.71275C2.69963 8.26024 2.358 7.77477 2.05533 7.25932C1.21323 5.82986 0.676813 4.17864 0.535964 2.41654L0.5 1.99099L0.709778 1.85614L5.145 1.99099L5.12702 2.40155C5.12702 2.40155 5.09106 3.52234 5.73836 4.2086C5.8852 4.36444 6.05901 4.4903 6.2598 4.5802C6.50853 4.69109 6.79922 4.75401 7.12586 4.76001L7.33564 5.61709C7.02997 5.6081 6.51752 5.49722 6.2598 5.4193C5.82826 5.28744 5.45966 5.06268 5.15998 4.74203C4.56962 4.11271 4.4018 3.27061 4.35685 2.77615H1.36307C1.47695 3.72313 1.7137 4.63415 2.05833 5.48223C2.33403 6.1655 2.67865 6.8098 3.08022 7.40616C3.24504 7.6489 3.41886 7.88564 3.60466 8.1104C3.76648 8.31119 3.9373 8.50598 4.11711 8.69178C5.24989 9.87251 6.66436 10.7476 8.25864 11.1821C8.32457 11.1941 8.3905 11.2151 8.45044 11.236C8.84002 11.3739 9.1337 11.6856 9.28953 12.1321C9.55924 12.9052 9.36146 14.0081 8.85201 14.6104L8.84901 14.6044Z"
      fill="url(#paint0_linear_3052_7531)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M4.69557 2.51543L4.35693 2.77316H1.43208L1.30322 2.30865L4.69557 2.51543Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M8.84904 14.6044C8.84904 14.6044 8.83406 14.6224 8.82807 14.6284C8.69621 14.7782 8.54937 14.8891 8.39054 14.964C8.24669 15.03 8.09386 15.0659 7.93803 15.0659C7.86011 15.0659 7.78519 15.0569 7.70728 15.042L7.91705 14.9071L8.84904 14.6044Z"
        fill="#777777"
      />
    </g>
    <path
      // eslint-disable-next-line max-len
      d="M9.03774 14.4935C8.90589 14.6434 8.75904 14.7543 8.60021 14.8292C8.45637 14.8951 8.30354 14.9311 8.1477 14.9311C8.06979 14.9311 7.99487 14.9221 7.91695 14.9071L8.44738 13.9751C8.78602 13.5885 8.93286 12.7704 8.75305 12.2519C8.62119 11.8744 8.38445 11.8294 8.30653 11.8144L8.27657 11.8084C6.79316 11.4069 5.4536 10.6547 4.32682 9.64776C4.15001 9.48893 3.97919 9.32411 3.81437 9.15629C3.63456 8.97049 3.45775 8.7757 3.28993 8.57491C2.91234 8.1224 2.56771 7.63692 2.26504 7.12148C1.42294 5.69202 0.889522 4.04079 0.745677 2.27869L0.709717 1.85315H5.35471L5.33673 2.26371C5.33673 2.26371 5.30377 3.3845 5.94807 4.07076C6.09492 4.2266 6.26872 4.34946 6.46951 4.44236C6.71824 4.55324 7.00893 4.61618 7.33558 4.62217L7.3176 5.41032C7.01193 5.40133 6.73023 5.35937 6.46951 5.28146C6.03797 5.1496 5.66937 4.92484 5.36969 4.60419C4.77933 3.97487 4.61151 3.13277 4.56656 2.63831H1.57278C1.68666 3.58529 1.92341 4.49631 2.26804 5.34439C2.54374 6.02766 2.88837 6.67196 3.29293 7.26832C3.45776 7.51106 3.63157 7.7478 3.81737 7.97256C3.97919 8.17334 4.15001 8.36813 4.32981 8.55393C5.46259 9.73466 6.87707 10.6097 8.47135 11.0442C8.53728 11.0562 8.60321 11.0772 8.66315 11.0982C9.05273 11.236 9.34941 11.5477 9.50524 11.9942C9.77795 12.7794 9.57117 13.9002 9.04673 14.4965L9.03774 14.4935Z"
      fill="url(#paint1_linear_3052_7531)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M9.03787 14.4936C8.90601 14.6434 8.75917 14.7543 8.60034 14.8292C9.41247 13.3758 8.96895 11.8444 8.65728 11.0952C9.04686 11.2331 9.34354 11.5447 9.49937 11.9913C9.77208 12.7764 9.5653 13.8972 9.04087 14.4936H9.03787Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M6.46948 4.44238C6.71821 4.55326 7.0089 4.6162 7.33555 4.62219L7.31757 5.41034C7.0119 5.40135 6.7302 5.3594 6.46948 5.28148V4.44238Z"
        fill="#777777"
      />
    </g>
    <path
      // eslint-disable-next-line max-len
      d="M26.1793 1.98802L26.1433 2.41356C26.0174 3.99286 25.5709 5.48226 24.8757 6.80383C24.7348 7.06755 24.588 7.32527 24.4292 7.574C24.2733 7.81974 24.1055 8.05948 23.9317 8.29323C23.647 8.66782 23.3413 9.02144 23.0147 9.35408C21.804 10.5798 20.2996 11.4878 18.6154 11.9433L18.5825 11.9493C18.5045 11.9643 18.2648 12.0062 18.1359 12.3838C17.9891 12.8124 18.061 13.4477 18.2828 13.8732C18.3307 13.9601 18.3847 14.041 18.4416 14.107L19.2807 14.9041L18.972 15.039C18.8941 15.0539 18.8192 15.0629 18.7413 15.0629C18.6244 15.0629 18.5105 15.045 18.3997 15.006C18.2888 14.967 18.1809 14.9101 18.082 14.8352C18.0011 14.7782 17.9262 14.7063 17.8542 14.6254C17.3298 14.0291 17.123 12.9083 17.3957 12.1231C17.5606 11.6496 17.8812 11.329 18.3068 11.2031C18.3457 11.1911 18.3877 11.1821 18.4296 11.1731C20.2427 10.6787 21.825 9.6178 23.0207 8.17935C23.3563 7.77479 23.665 7.34025 23.9377 6.87875C24.1175 6.57308 24.2853 6.25842 24.4352 5.92877C24.606 5.56017 24.7558 5.17658 24.8817 4.78101C25.0885 4.1367 25.2383 3.46243 25.3222 2.77017H22.3254C22.2805 3.26464 22.1127 4.10674 21.5223 4.73606C21.2196 5.06271 20.845 5.29046 20.4045 5.41932C20.1498 5.49424 19.8741 5.5362 19.5774 5.54219L19.5594 4.75404C19.8771 4.74505 20.1588 4.68811 20.4075 4.58023C20.6173 4.49032 20.8001 4.36146 20.9529 4.19664C21.5912 3.51637 21.5612 2.40157 21.5612 2.39258L21.5433 1.98203L21.8519 1.84717L26.1853 1.98203L26.1793 1.98802Z"
      fill="url(#paint2_linear_3052_7531)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M25.6879 2.59335L25.3193 2.77316H22.3435V2.39856L25.6879 2.59335Z"
        fill="#777777"
      />
    </g>
    <path
      // eslint-disable-next-line max-len
      d="M26.491 1.85017L26.4551 2.27571C26.3292 3.85501 25.8827 5.3444 25.1874 6.66598C25.0466 6.92969 24.8998 7.18742 24.7409 7.43615C24.5821 7.68488 24.4173 7.92163 24.2435 8.15538C23.9588 8.52997 23.6531 8.88359 23.3265 9.21624C22.1158 10.4419 20.6114 11.3499 18.9272 11.8054L18.8942 11.8114C18.8163 11.8264 18.5796 11.8684 18.4477 12.249C18.2979 12.6775 18.3728 13.3128 18.5945 13.7384C18.6425 13.8253 18.6964 13.9062 18.7534 13.9721L19.2838 14.9041C19.2059 14.9191 19.131 14.9281 19.0531 14.9281C18.9362 14.9281 18.8223 14.9101 18.7114 14.8711C18.6005 14.8322 18.4957 14.7752 18.3938 14.7003C18.3129 14.6434 18.2379 14.5715 18.166 14.4906C17.6416 13.8942 17.4348 12.7704 17.7075 11.9882C17.8723 11.5148 18.193 11.1941 18.6185 11.0682C18.6575 11.0563 18.6994 11.0473 18.7384 11.0383C20.5514 10.5438 22.1337 9.48295 23.3294 8.0445C23.6651 7.63993 23.9738 7.2054 24.2465 6.7439C24.4263 6.43823 24.5941 6.12357 24.7439 5.79392C24.9147 5.42532 25.0646 5.03873 25.1904 4.64616C25.3972 4.00185 25.5471 3.32758 25.631 2.63532H22.6372C22.5922 3.12979 22.4244 3.97188 21.8341 4.6012C21.5314 4.92785 21.1568 5.15561 20.7163 5.28447C20.4615 5.35939 20.1858 5.40134 19.8892 5.40734L19.8712 4.61918C20.1918 4.61319 20.4735 4.55326 20.7193 4.44537C20.929 4.35547 21.1118 4.22661 21.2647 4.06179C21.903 3.38152 21.873 2.26672 21.873 2.25773L21.855 1.84717H26.5L26.491 1.85017Z"
      fill="url(#paint3_linear_3052_7531)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M18.3906 14.7034C18.3097 14.6464 18.2348 14.5745 18.1628 14.4936C17.6384 13.8972 17.4316 12.7735 17.7043 11.9913C17.8692 11.5178 18.1898 11.1972 18.6154 11.0713C18.2737 11.7306 17.6983 13.196 18.3906 14.7034Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M20.7103 4.44837V5.28447C20.4556 5.35939 20.1799 5.40134 19.8832 5.40733L19.8652 4.61918C20.1859 4.61319 20.4676 4.55326 20.7133 4.44537L20.7103 4.44837Z"
        fill="#777777"
      />
    </g>
    <path
      d="M20.2428 1.32874C20.2428 1.66138 20.2338 2.012 20.2218 2.37761C20.2159 2.53044 20.2069 2.68927 20.1979 2.8511C20.063 5.13164 19.6345 7.85571 18.8284 10.2112C17.7765 13.2859 16.0773 15.7312 13.554 15.7312C9.0319 15.7312 7.16491 7.88867 6.8982 2.62034C6.89221 2.53943 6.88921 2.45852 6.88621 2.38061C6.87123 2.015 6.86523 1.66438 6.86523 1.33174H20.2428V1.32874Z"
      fill="url(#paint4_linear_3052_7531)"
    />
    <path
      // eslint-disable-next-line max-len
      d="M15.6008 8.43711C15.6008 8.70982 15.5799 9.0095 15.5529 9.26722C15.5319 9.26722 15.433 9.44703 15.3971 9.46501C15.3971 9.46501 15.3941 9.46501 15.3911 9.46501C15.0644 9.46501 13.632 9.45901 13.1165 9.45901H12.2295C12.1605 9.27022 12.0916 9.04846 12.0407 8.85067C12.0347 8.82969 12.2085 8.67386 12.2025 8.65288C12.676 8.30226 12.9427 8.09548 13.2424 7.81378C13.4582 7.61899 13.6619 7.4272 13.8327 7.23241C14.0335 6.9597 14.1624 6.67501 14.1624 6.36634C14.1624 6.1296 14.1055 5.97377 14.0006 5.82992C13.9107 5.71005 13.7488 5.63513 13.575 5.63513C13.4312 5.63513 13.3083 5.6621 13.2154 5.69806L13.0716 6.32139C13.0506 6.32139 12.9307 6.52217 12.9097 6.52217C12.9097 6.52217 12.9037 6.52217 12.9008 6.52217C12.658 6.52217 12.4093 6.45924 12.1905 6.44726C12.2115 6.02771 12.2385 5.60816 12.2654 5.1976C12.2654 5.17063 12.4243 5.02679 12.4273 4.99682C12.7569 4.85597 13.3203 4.76007 13.7878 4.76007C14.4291 4.76007 14.8157 4.88594 15.1004 5.13767C15.3791 5.37441 15.5349 5.73702 15.5349 6.09963C15.5349 6.57012 15.2952 6.98368 14.9925 7.32531C14.6928 7.72988 14.2823 8.05353 13.9976 8.27529C13.8957 8.3592 13.8088 8.42513 13.7219 8.48506C14.2313 8.47607 15.1663 8.43112 15.5769 8.42513H15.5829L15.5978 8.44011L15.6008 8.43711Z"
      fill="#8B8B8B"
    />
    <path
      // eslint-disable-next-line max-len
      d="M15.5857 8.42812H15.5797C15.1721 8.42812 14.2341 8.47607 13.7247 8.48506C13.6947 8.48506 13.6648 8.48506 13.6378 8.48506V8.45809C13.8176 8.33223 13.9644 8.23633 14.1622 8.07451C14.399 7.8917 14.7226 7.63698 14.9953 7.32531C15.295 6.98368 15.5377 6.57012 15.5377 6.09963C15.5377 5.73702 15.3849 5.37441 15.1032 5.13767C14.8155 4.88594 14.4319 4.76007 13.7906 4.76007C13.3231 4.76007 12.7567 4.85897 12.4301 4.99682C12.4301 5.02379 12.2713 5.17063 12.2683 5.1976C12.2413 5.58419 12.3731 5.85689 12.3522 6.24647C12.49 6.25546 12.5949 6.37533 12.7178 6.45625C12.7747 6.49221 12.8317 6.51918 12.9006 6.52217C12.9006 6.52217 12.9066 6.52217 12.9096 6.52217C12.9305 6.52217 13.0504 6.32139 13.0714 6.32139L13.2152 5.69806L13.2332 5.62314C13.2332 5.62314 13.2362 5.60217 13.2392 5.59617C13.2452 5.58119 13.2512 5.5692 13.2662 5.55422C13.3651 5.49129 13.5239 5.43434 13.7337 5.43434C13.9075 5.43434 14.0693 5.51226 14.1592 5.62914C14.2641 5.77598 14.321 5.92881 14.321 6.16556C14.321 6.55214 14.1233 6.89677 13.8296 7.23241C13.6588 7.4302 13.455 7.62199 13.2392 7.81378C12.9395 8.09248 12.6728 8.30226 12.1993 8.65288C12.2053 8.67386 12.0315 8.82969 12.0375 8.85067C12.0855 9.03047 12.3252 9.09041 12.3881 9.25823H13.2752C13.7577 9.25823 14.9534 9.46501 15.3909 9.46501C15.3909 9.46501 15.3939 9.46501 15.3969 9.46501C15.4329 9.44703 15.5317 9.26722 15.5527 9.26722C15.5797 9.0095 15.6007 8.70982 15.6007 8.43711L15.5857 8.42812Z"
      fill="#424242"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M13.2424 5.59617C13.2424 5.59617 13.2364 5.61415 13.2364 5.62314L13.2184 5.69806L13.0746 6.32139C13.0536 6.32139 12.9337 6.52217 12.9127 6.52217C12.9127 6.52217 12.9067 6.52217 12.9038 6.52217C12.8348 6.51917 12.7749 6.4892 12.7209 6.45624C12.7239 6.38132 12.7329 6.31839 12.7359 6.29142C12.7719 6.10562 12.8648 5.93181 12.9817 5.78497C13.0146 5.74301 13.1525 5.55721 13.2274 5.58718C13.2334 5.58718 13.2394 5.59317 13.2424 5.59617Z"
        fill="#7E7E7E"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M15.6008 8.43709C15.6008 8.7098 15.5799 9.00948 15.5529 9.2672C15.5319 9.2672 15.433 9.44701 15.3971 9.46499H15.3911L15.3491 9.447L15.3941 8.66784L15.424 8.62888L15.5829 8.4281L15.5979 8.43709H15.6008Z"
        fill="#7E7E7E"
      />
    </g>
    <path
      // eslint-disable-next-line max-len
      d="M15.427 8.62888L15.418 8.62288C14.9865 8.62887 13.9586 8.67982 13.4761 8.68581V8.65884C13.563 8.59891 13.6439 8.54497 13.7248 8.48503C13.8117 8.4221 13.8986 8.35617 14.0005 8.27526C14.2852 8.0535 14.6988 7.73284 14.9955 7.32528C15.2172 7.0256 15.376 6.68097 15.376 6.29739C15.376 5.9138 15.2232 5.57217 14.9415 5.33243C14.6568 5.0807 14.2702 4.95483 13.6289 4.95483C13.1614 4.95483 12.595 5.05373 12.2684 5.19158C12.2414 5.60213 12.2115 6.02168 12.1935 6.44123C12.4152 6.45621 12.664 6.51615 12.9037 6.51615H12.9127L13.0745 5.8179C13.0805 5.78194 13.0895 5.76995 13.1105 5.74898C13.1405 5.731 13.1764 5.71002 13.2214 5.69204C13.3143 5.65608 13.4341 5.62911 13.581 5.62911C13.7548 5.62911 13.9166 5.70702 14.0065 5.8239C14.1114 5.97074 14.1683 6.12358 14.1683 6.36032C14.1683 6.66899 14.0395 6.95368 13.8387 7.22639C13.6409 7.4931 13.3742 7.75082 13.0865 8.00855C12.7868 8.28725 12.5201 8.49702 12.0466 8.84464C12.0946 9.03943 12.1665 9.26419 12.2354 9.45299H13.1225C13.6379 9.45299 15.0704 9.45898 15.397 9.45898C15.397 9.45898 15.397 9.45898 15.397 9.45599C15.424 9.19826 15.445 8.89858 15.445 8.62888L15.433 8.61989L15.427 8.62888Z"
      fill="#63686E"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M18.8283 10.2082C18.0672 12.4288 16.9733 14.3197 15.472 15.2008C14.8936 15.5394 14.2583 15.7282 13.554 15.7282C9.0319 15.7282 7.16491 7.88567 6.8982 2.61735C6.89221 2.53644 6.88921 2.45552 6.88621 2.37761C6.87123 2.012 6.86523 1.66138 6.86523 1.32874H11.0937L10.8929 2.08992C10.8929 2.08992 11.5162 17.0408 18.8283 10.2112V10.2082Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M15.6096 15.1169C10.3533 13.187 8.52527 4.86493 7.97686 1.32874H7.25464C7.25464 1.66138 7.26363 2.015 7.27561 2.37761C7.27861 2.45552 7.28161 2.53644 7.2876 2.61735C7.55132 7.81076 9.36736 15.5035 13.7486 15.7222L15.6096 15.1139V15.1169Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M20.2428 1.32874C20.2428 1.66138 20.2338 2.012 20.2218 2.37761C20.2158 2.53044 20.2068 2.68927 20.1978 2.8511C18.9901 2.71025 15.7176 2.37761 11.3034 2.37761C8.51939 2.37761 7.36263 2.49448 6.90113 2.61735C6.89514 2.53644 6.89214 2.45552 6.88914 2.37761C6.87416 2.012 6.86816 1.66138 6.86816 1.32874H20.2458H20.2428Z"
        fill="#777777"
      />
    </g>
    <path
      d="M21.0819 0.936157L20.968 1.30776L20.7672 1.98503H6.34079L6.02612 0.936157H21.0819Z"
      fill="url(#paint5_linear_3052_7531)"
    />
    <path
      d="M15.8046 16.1868C15.8046 16.1868 15.7567 16.2257 15.6818 16.2916C15.5559 16.4025 15.3551 16.5883 15.2053 16.7771C15.0884 16.927 15.0015 17.0738 15.0015 17.1907C15.0015 17.6522 14.9385 18.0867 14.8277 18.4673C14.7857 18.6171 14.7348 18.755 14.6778 18.8839C14.6688 18.9018 14.6629 18.9228 14.6539 18.9408C14.6299 18.9947 14.6029 19.0457 14.5759 19.0966C14.3152 19.5851 13.9526 19.8878 13.554 19.8878C13.3383 19.8878 13.1345 19.8009 12.9487 19.642C12.7569 19.4772 12.5891 19.2405 12.4542 18.9438C12.2355 18.4733 12.1066 17.862 12.1066 17.1937C12.1066 16.8371 11.3035 16.1898 11.3035 16.1898L11.3095 16.1718L11.6181 15.1918H15.493L15.6488 15.6893L15.7627 16.0489L15.8016 16.1718L15.8076 16.1898L15.8046 16.1868Z"
      fill="#878C98"
    />
    <path
      d="M15.7986 16.1658H11.3064L11.6151 15.1888H15.4899L15.6457 15.6863L15.7596 16.0429L15.7986 16.1658Z"
      fill="url(#paint6_linear_3052_7531)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M12.9487 19.6391C12.4512 19.2135 12.1066 18.2785 12.1066 17.1907C12.1066 16.8341 11.3035 16.1868 11.3035 16.1868L11.6181 15.1888H15.493C15.493 15.1888 11.5612 15.3657 12.4512 16.2976C12.5112 16.4535 13.1405 16.4954 13.1405 16.4954C13.1615 16.4775 12.5501 18.776 13.3383 18.9438C13.7159 19.0247 13.3922 19.3364 12.9547 19.642L12.9487 19.6391Z"
        fill="#777777"
      />
    </g>
    <path
      d="M14.9176 19.8847H12.1875L12.4512 18.9407H14.6508L14.7168 19.1835L14.8726 19.7589L14.9146 19.8847H14.9176Z"
      fill="url(#paint7_linear_3052_7531)"
    />
    <path
      d="M15.5468 26.5975H11.5581L12.1874 19.8848H14.9175L14.9295 20.0166L15.5468 26.5975Z"
      fill="#878C98"
    />
    <path
      d="M16.6915 26.9661C16.3289 26.9392 15.9453 26.9182 15.5467 26.9002H15.5437C15.0493 26.8792 14.5338 26.8672 14.0004 26.8612C13.8566 26.8612 13.7097 26.8612 13.5629 26.8612C13.3081 26.8612 13.0564 26.8612 12.8107 26.8672C12.2623 26.8762 11.7348 26.8942 11.2344 26.9182C10.9527 26.9332 10.68 26.9482 10.4163 26.9661L10.5122 26.5975H16.5956L16.6196 26.6964L16.6915 26.9661Z"
      fill="url(#paint8_linear_3052_7531)"
    />
    <path
      d="M20.5304 27.8052H6.57739V29.0639H20.5304V27.8052Z"
      fill="url(#paint9_linear_3052_7531)"
    />
    <path
      d="M20.5304 27.8052H6.57739C6.67629 27.4396 8.21363 27.1219 10.4163 26.9661C11.1475 26.9152 11.9506 26.8792 12.8017 26.8642C13.0504 26.8612 13.3052 26.8582 13.5629 26.8582C14.6867 26.8582 15.7505 26.8942 16.6915 26.9631C17.7943 27.041 18.7293 27.1579 19.4006 27.3017C19.6283 27.3497 19.8261 27.4006 19.9879 27.4546C20.3086 27.5625 20.4974 27.6793 20.5304 27.7992V27.8052Z"
      fill="#878C98"
    />
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M15.8045 16.1867C15.8045 16.1867 15.7566 16.2257 15.6817 16.2916C14.8516 16.3036 13.6888 16.2407 13.2812 16.2347C13.518 16.2257 13.7457 16.2017 13.9585 16.1688C14.6927 16.0519 15.2801 15.8181 15.6427 15.6863L15.7566 16.0459L15.7955 16.1688L15.8015 16.1867H15.8045Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M14.9294 20.0166C14.4379 19.9837 13.0684 19.8848 13.0684 19.8848L14.8724 19.7589L14.9144 19.8848L14.9264 20.0166H14.9294Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M16.6197 26.6964L15.7327 26.5975H16.5957L16.6197 26.6964Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M21.0819 0.936157L20.968 1.30776L9.84399 0.936157H21.0819Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M13.3323 18.9407L13.1045 19.8847H12.1875L12.4542 18.9407H13.3323Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M13.1044 19.8848L12.7688 26.5975H12.6999H11.5581L12.1874 19.8848H13.1044Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M12.5651 1.98503H6.34079L6.02612 0.936157H8.69924C8.41155 1.12795 7.57845 1.89213 12.5651 1.98503Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M7.24281 1.98503H6.34079L6.02612 0.936157H6.78431L7.24281 1.98503Z"
        fill="#777777"
      />
    </g>
    <path
      style={{ mixBlendMode: 'soft-light' }}
      opacity="0.8"
      d="M11.4322 9.41102C11.4322 9.41102 10.0177 6.14454 10.2935 2.99792H8.99585C8.99585 2.99792 9.32849 7.2863 11.4322 9.41102Z"
      fill="url(#paint10_linear_3052_7531)"
    />
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M12.8078 26.8642C12.2593 26.8732 11.7319 26.8912 11.2314 26.9152L11.5581 26.5975H12.7688L12.8078 26.8642Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M11.2314 26.9182C11.2314 26.9182 9.20264 27.4726 9.20264 27.8052H11.7019C11.7019 27.8052 12.1245 26.9691 12.8107 26.8672L11.2344 26.9182H11.2314Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.3">
      <path
        d="M11.6959 27.8052H9.20264V29.0639H11.6959V27.8052Z"
        fill="#777777"
      />
    </g>
    <path
      style={{ mixBlendMode: 'soft-light' }}
      opacity="0.8"
      d="M15.9424 11.0233C15.9424 11.0233 15.9514 10.9843 15.9544 10.9214C16.0113 9.99839 15.9424 2.99493 15.9424 2.99493H19.6524C19.6524 2.99493 19.7183 10.4299 15.9424 11.0203V11.0233Z"
      fill="url(#paint11_linear_3052_7531)"
    />
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M15.8106 11.1401C15.8675 10.2171 15.7986 3.21368 15.7986 3.21368H17.273C17.267 4.84393 17.1292 9.19524 15.8106 11.1401Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M16.3499 0.936157H13.554V1.98503H16.3499V0.936157Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M17.7645 0.936157H17.0303V1.98503H17.7645V0.936157Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M24.2434 1.85016H23.3264V2.63831H24.2434V1.85016Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M24.2434 6.74689V8.15837C23.9587 8.53297 23.6531 8.88658 23.3264 9.21922V8.05049C23.6621 7.64592 23.9707 7.21139 24.2434 6.74988V6.74689Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M25.1875 1.85016H24.741V2.63831H25.1875V1.85016Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M25.1875 4.64917V6.666C25.0466 6.92971 24.8998 7.18744 24.741 7.43617V5.79693C24.9118 5.42833 25.0616 5.04175 25.1875 4.64917Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M3.29003 1.85016H2.26514V2.63831H3.29003V1.85016Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M3.28686 7.26834V8.57494C2.90927 8.12242 2.56464 7.63695 2.26196 7.1215V5.34741C2.53767 6.03068 2.88229 6.67498 3.28686 7.27134V7.26834Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M4.32397 1.85016H3.81152V2.63831H4.32397V1.85016Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M4.32397 8.55097V9.64779C4.14716 9.48897 3.97635 9.32414 3.81152 9.15632V7.9726C3.97335 8.17338 4.14417 8.36817 4.32397 8.55397V8.55097Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M19.2837 14.9071C19.2058 14.9221 19.1308 14.9311 19.0529 14.9311C18.936 14.9311 18.8222 14.9131 18.7113 14.8741C18.6304 14.4905 18.6034 14.08 18.5974 13.7383C18.6454 13.8252 18.6993 13.9062 18.7562 13.9721L19.2867 14.9041L19.2837 14.9071Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M15.2022 26.5975H14.0005L14.2912 21.3531L15.2022 26.5975Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.7">
      <path
        d="M13.6619 26.5975H13.4761V21.3531L13.6619 26.5975Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path
        d="M15.5468 26.9002H15.5438C15.0494 26.8792 14.5339 26.8672 14.0005 26.8612V26.5975H15.2022L15.5468 26.9002Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path
        d="M13.6619 26.5975L13.7638 26.8612H13.4221L13.4761 26.5975H13.6619Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.4">
      <path
        d="M15.5468 26.9002C15.5468 26.9002 17.0602 27.2239 17.4887 27.8053H15.0404C15.0404 27.8053 14.6987 27.128 14.0005 26.8613L15.5468 26.9002Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path
        d="M13.7638 26.8613C13.7638 26.8613 14.3841 27.1819 14.42 27.8202C14.42 27.8053 13.8686 27.8053 13.8686 27.8053C13.8686 27.8053 13.8806 27.2988 13.4221 26.8613H13.7638Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path
        d="M14.4201 27.8052H13.8687V29.0639H14.4201V27.8052Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path
        d="M17.4916 27.8052H15.0403V29.0639H17.4916V27.8052Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'soft-light' }} opacity="0.3">
      <path
        d="M20.5304 27.8052V28.5784C20.4136 28.1019 18.8402 27.8052 18.7024 27.8052C19.3707 27.7543 19.7662 27.5954 19.988 27.4606C20.3087 27.5685 20.4975 27.6853 20.5304 27.8052Z"
        fill="#E8E8E8"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M20.5304 29.0639H6.57739V27.8052H10.3054C10.3054 27.8052 6.68528 29.0639 20.5334 29.0639H20.5304Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M20.5304 27.8052H6.57739C6.67629 27.4396 8.21363 27.122 10.4163 26.9661C11.1475 26.9152 11.9506 26.8792 12.8017 26.8643C12.7537 27.104 13.2602 27.7933 20.5304 27.8022V27.8052Z"
        fill="#777777"
      />
    </g>
    <g style={{ mixBlendMode: 'color-burn' }} opacity="0.2">
      <path
        d="M13.7576 16.9839C14.1322 17.1757 14.8934 18.0088 14.1502 18.8599C14.0363 18.8928 13.9075 18.9228 13.7576 18.9408C13.8535 18.9408 14.2701 18.9408 14.5788 19.0966C14.6297 19.1206 14.6806 19.1505 14.7226 19.1835L14.6567 18.9408C14.6657 18.9228 14.6716 18.9018 14.6806 18.8838C14.6926 18.8569 14.7016 18.8269 14.7136 18.7999C14.7256 18.767 14.7406 18.734 14.7526 18.698C14.7795 18.6231 14.8065 18.5482 14.8275 18.4673C14.9384 18.0867 15.0013 17.6522 15.0013 17.1907C15.0013 17.0738 15.0882 16.9239 15.2051 16.7771C14.8215 16.891 14.309 16.9959 13.7546 16.9869L13.7576 16.9839Z"
        fill="#777777"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3052_7531"
        x1="8.68718"
        y1="7.98154"
        x2="-3.31489"
        y2="9.30012"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#717A80" />
        <stop offset="1" stopColor="#878C98" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3052_7531"
        x1="6.76919"
        y1="8.81765"
        x2="-0.668801"
        y2="-2.24044"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" stopColor="#828A93" />
        <stop offset="1" stopColor="#878998" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3052_7531"
        x1="17.2729"
        y1="8.45805"
        x2="26.1793"
        y2="8.45805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#717A80" />
        <stop offset="1" stopColor="#878C98" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3052_7531"
        x1="17.5846"
        y1="8.39212"
        x2="26.491"
        y2="8.39212"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" stopColor="#828A93" />
        <stop offset="1" stopColor="#878998" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3052_7531"
        x1="6.86523"
        y1="8.52698"
        x2="20.2428"
        y2="8.52698"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#949BA4" />
        <stop offset="0.44" stopColor="#878C98" />
        <stop offset="1" stopColor="#878998" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3052_7531"
        x1="6.02612"
        y1="1.46059"
        x2="21.0819"
        y2="1.46059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#949BA4" />
        <stop offset="0.44" stopColor="#878C98" />
        <stop offset="1" stopColor="#878998" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3052_7531"
        x1="11.3064"
        y1="15.6773"
        x2="15.7986"
        y2="15.6773"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#899199" />
        <stop offset="0.44" stopColor="#878C98" />
        <stop offset="1" stopColor="#878998" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_3052_7531"
        x1="12.2954"
        y1="19.4322"
        x2="16.335"
        y2="19.3783"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8E969E" />
        <stop offset="0.44" stopColor="#878C98" />
        <stop offset="1" stopColor="#878998" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_3052_7531"
        x1="10.4163"
        y1="26.7833"
        x2="16.6915"
        y2="26.7833"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#878E98" />
        <stop offset="0.44" stopColor="#878C98" />
        <stop offset="1" stopColor="#878998" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_3052_7531"
        x1="6.57739"
        y1="28.4346"
        x2="20.5304"
        y2="28.4346"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8E969E" />
        <stop offset="0.44" stopColor="#878C98" />
        <stop offset="1" stopColor="#878998" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_3052_7531"
        x1="8.99585"
        y1="6.20447"
        x2="11.4322"
        y2="6.20447"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E8E8E8" />
        <stop offset="1" stopColor="#E8E8E8" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_3052_7531"
        x1="15.9424"
        y1="7.01061"
        x2="19.6524"
        y2="7.01061"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E8E8E8" />
        <stop offset="1" stopColor="#E8E8E8" />
      </linearGradient>
    </defs>
  </svg>
);

export default TrophySilver;
