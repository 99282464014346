import { Avatar, Box, Button, Link, Stack, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// types
import { getImageURL } from '~utils/awsUtils';
import { TextEllipsis } from '~components/atoms';
import { BlockedUser } from '~types/artist';

type Props = {
  item: BlockedUser;
  processing: boolean;
  onUnblock: (event: React.MouseEvent<HTMLElement>) => void;
};

const ArtistBlockedUserItem = ({ item, processing, onUnblock }: Props) => {
  return (
    <Stack
      sx={{
        background: '#151515',
        height: 100,
      }}
      p={{ xs: 1, md: 0 }}
      direction={'row'}
      alignItems="center"
    >
      <Box>
        <Avatar
          variant="square"
          sx={{ width: { xs: 84, md: 100 }, height: { xs: 84, md: 100 } }}
          src={getImageURL(item.imageUrl)}
        />
      </Box>
      <Stack
        width={1}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        spacing={{ md: 2 }}
        justifyContent={'space-between'}
        px={2}
      >
        <Stack
          flex={0.5}
          width={{ xs: 1, md: 'unset' }}
          spacing={0.5}
          direction={{ xs: 'row', md: 'column' }}
          justifyContent={{ xs: 'space-between', md: 'flex-start' }}
          alignItems={{ xs: 'center', md: 'flex-start' }}
        >
          <Link href={`/${item.username}`}>
            <TextEllipsis
              text={item.username}
              variant="body1"
              color="warning.main"
              fontWeight="500"
              sx={{
                maxWidth: 160,
                textTransform: 'unset',
                cursor: 'inherit',
              }}
            />
          </Link>
        </Stack>
        <Typography variant="body1" flex={1} align="center">
          {item.email}
        </Typography>
        <Stack
          width={{ xs: 1, md: 'unset' }}
          direction="row"
          alignItems="center"
          justifyContent={{ xs: 'space-between', md: 'flex-start' }}
          spacing={1.5}
        >
          <Box flexGrow={{ xs: 1, md: 0 }}>
            <Button
              fullWidth
              disabled={processing}
              onClick={onUnblock}
              variant="contained"
              color="warning"
              size="small"
              sx={{ textTransform: 'capitalize', minWidth: 152 }}
              startIcon={
                <Box sx={{ position: 'relative' }}>
                  <PersonIcon fontSize="small" />
                  <Box
                    bgcolor="warning.main"
                    sx={{
                      position: 'absolute',
                      right: 0,
                      bottom: 0,
                      borderRadius: '50%',
                      width: 8,
                    }}
                  >
                    <CheckCircleIcon
                      sx={{ width: 8, height: 8, display: 'block' }}
                    />
                  </Box>
                </Box>
              }
            >
              {item.isBlocked ? 'Unblock' : 'Block'}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ArtistBlockedUserItem;
