import { memo } from 'react';
import Reaptcha from 'reaptcha';

const Captcha = ({ forwardRef, onVerify, onExpire }) => {
  return (
    <Reaptcha
      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
      onVerify={onVerify}
      onExpire={onExpire}
      ref={forwardRef}
    />
  );
};

export default memo(Captcha);
