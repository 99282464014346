import { FormProvider, RHFTextField } from '~components/molecules';
import CountryPhoneCodeSelect from './phone-code-dropdown';
import { Button, Stack, styled, Typography } from '@mui/material';
import * as yup from 'yup';
import { messages } from '~language/en';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getNonce, verifyPhoneNumber } from '~api/user';
import { useState } from 'react';
import { isValidPhone } from '~utils/validateUtils';
import { useGlobalState } from '~utils/container';
import { ON_BOARDING_INFO_STEP } from '~utils/constants';
type UpdatePhoneNumberInputs = {
  phoneNumber: string;
};

export const FillPhoneNumberStep = ({
  onDoneRequestVerifying,
  withGift,
}: any) => {
  const [dialCode, setDialCode] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [isSubmitting, setSubmitting] = useState(false);
  const phoneNumberSchema = yup.object({
    phoneNumber: yup
      .string()
      .required('Please enter your phone number')
      .test(
        'IS_VALID_PHONE_NUMBER',
        messages.VALIDATIONS.INVALID_PHONE_NUMBER,
        (value) => {
          console.log(`phone ${dialCode}${value}`);
          return isValidPhone(`${dialCode}${value}`);
        },
      ),
  });
  const methods = useForm<UpdatePhoneNumberInputs>({
    defaultValues: {
      phoneNumber: '',
    },
    resolver: yupResolver(phoneNumberSchema),
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = async (values: UpdatePhoneNumberInputs) => {
    try {
      const nonce: string = (await getNonce()).data.nonce;
      const phoneNumber = `${dialCode}${values.phoneNumber}`;
      setSubmitting(true);
      const response = await verifyPhoneNumber(phoneNumber, nonce);
      if (response && response.status === 200) {
        onDoneRequestVerifying(countryCode, phoneNumber);
      }
    } catch (err) {
      reset();
      setSubmitting(false);
    }
  };
  const globalState = useGlobalState();
  const { user } = globalState;
  const isDoneOnBoarding =
    user?.onBoardingInfoStep === ON_BOARDING_INFO_STEP.DONE;
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack mb={3} spacing={1}>
        <PopupTitle textTransform={'uppercase'}>
          {`ENTER YOUR${isDoneOnBoarding ? ' NEW' : ''} PHONE NUMBER`}
        </PopupTitle>
        {withGift && (
          <Description>
            {' '}
            And earn 50 lc to use for tipping other artists{' '}
          </Description>
        )}
      </Stack>

      <Stack height={'100%'} spacing={3}>
        <Stack spacing={1} direction={'row'}>
          <CountryPhoneCodeSelect
            onSelected={(mDialCode: any) => {
              setDialCode(mDialCode.dialCode);
              setCountryCode(mDialCode.countryCode);
            }}
          />
          <RHFTextField
            name={'phoneNumber'}
            type="number"
            placeholder="Phone number (required)"
          />
        </Stack>
        <Button
          type="submit"
          color="warning"
          variant="contained"
          disabled={isSubmitting}
          sx={{ textTransform: 'capitalize' }}
        >
          Next
        </Button>
      </Stack>
    </FormProvider>
  );
};

const PopupTitle = styled(Typography)(({ theme }) => ({
  color: theme.typography.h3.color,
  font: theme.typography.h3.font,
  fontSize: 13,
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h3.fontSize,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.typography.subtitle2.color,
  fontSize: theme.typography.subtitle2.fontSize,
  font: theme.typography.subtitle2.font,
  fontWeight: 400,
  display: 'inline',
}));
