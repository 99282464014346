import { SVGProps } from 'react';

const BadgeBronze = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 10L18.3667 17.9667C18.0778 18.3667 17.7333 18.6945 17.3333 18.95C16.9333 19.2056 16.4889 19.3334 16 19.3334H2C1.44444 19.3334 0.972222 19.1389 0.583333 18.75C0.194444 18.3611 0 17.8889 0 17.3334V2.66669C0 2.11113 0.194444 1.63891 0.583333 1.25002C0.972222 0.861131 1.44444 0.666687 2 0.666687H16C16.4889 0.666687 16.9333 0.794465 17.3333 1.05002C17.7333 1.30558 18.0778 1.63335 18.3667 2.03335L24 10Z"
      fill="url(#paint0_linear_795_9816)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_795_9816"
        x1="-1.11988e-07"
        y1="4"
        x2="20.5"
        y2="10"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="#C27239" />
        <stop offset="1" stopColor="#FFA462" />
      </linearGradient>
    </defs>
  </svg>
);

export default BadgeBronze;
