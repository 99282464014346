import { Suspense, lazy, useEffect, useCallback, useContext } from 'react';
// @mui
import { Box } from '@mui/material';
// routes
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import {
  NotFoundPage,
  UserPage,
  UserLoginPage,
  ResetPasswordPage,
  ActivateAccountPage,
  CollectiblesPage,
  ContactPage,
  HomePage,
  EventPage,
  ArtistUnblockUsersPage,
} from '../pages';
// components
import { LoadingScreen } from '~components/atoms';
// types
import { RoutesPages } from './types';
import ConfirmUpdateEmail from '../pages/confirm-update-email';
import { ManageProfileProvider } from '~providers/ManageProfileProvider';
import { changeMetaUserInfo, pageView } from '~utils/metaPixelEvents';
import { GA4_PAGE_VIEW_CUSTOM_FIELD, useGlobalState } from '~utils';
import ReactGA from 'react-ga4';
import { useGlobalDispatch } from '~utils/container';
import { WebSocketEvent } from '~socket/types';
import { AuthContext } from '~providers/AuthProvider';
import useGa4EventSender from '~hooks/useGa4EventSender';
import { UTMContext } from '~providers/UtmProvider';
// ----------------------------------------------------------------------

//
const FaqPage = lazy(() => import('../pages/faq'));
const AboutPage = lazy(() => import('../pages/about'));
const PrivacyPage = lazy(() => import('../pages/privacy'));
const FormatIdeasPage = lazy(() => import('../pages/format-ideas'));
const AccessDeniedPage = lazy(() => import('../pages/access-denied'));
// PROMOTION
const PromotionTipPage = lazy(() => import('../pages/promotion/tip'));
const PromotionArtistPage = lazy(() => import('../pages/promotion/artist'));
const PromotionThemePage = lazy(() => import('../pages/promotion/theme'));
// ARTIST
const ForArtistsPage = lazy(() => import('../pages/artist/for-artists'));
const FollowingArtistPage = lazy(
  () => import('../pages/artist/artist-following'),
);
const PastEventPage = lazy(() => import('../pages/past-event'));
const ManageEmailPreferencesPage = lazy(
  () => import('../pages/manage-email-preferences'),
);
const UnsubscribePage = lazy(() => import('../pages/unsubscribe'));

export enum CONTENT_NAME {
  ARTIST_PAGE = 'artist_page',
}
const ArtistPageRouteWrapper = () => {
  const location = useLocation();
  const { user } = useGlobalState();
  const { phone, email } = user;
  useEffect(() => {
    changeMetaUserInfo({
      ph: phone,
      em: email,
      client_user_agent: navigator.userAgent,
    });
    pageView({ content_name: CONTENT_NAME.ARTIST_PAGE });
  }, [user, location, phone, email]);
  return <Outlet />;
};

const NonArtistPageRouteWrapper = () => {
  const location = useLocation();
  const { user } = useGlobalState();
  const { phone, email } = user;
  useEffect(() => {
    changeMetaUserInfo({
      ph: phone,
      em: email,
      client_user_agent: navigator.userAgent,
    });
    pageView();
  }, [user, email, location, phone]);
  return <Outlet />;
};

const Router = () => {
  const globalState = useGlobalState();
  const {
    user: { type, id, username },
  } = globalState as Record<string, any>;
  const location = useLocation();
  const ga4Sender = useGa4EventSender();
  const { setUtmParams } = useContext(UTMContext);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmCampaign = params.get('utm_campaign');

    setUtmParams({
      source: utmSource,
      medium: utmMedium,
      campaign: utmCampaign,
    });
  }, [setUtmParams]);

  useEffect(() => {
    // Send user Id to GA4
    if (id) {
      ReactGA.set({ user_id: id });
    }
    ReactGA.send({
      hitType: 'pageview',
      [GA4_PAGE_VIEW_CUSTOM_FIELD.ACCOUNT_TYPE]: type,
    });
  }, [type, location, id]);

  const { logout } = useContext(AuthContext);
  const dispatch = useGlobalDispatch();
  const userLogout = async () => {
    await logout();
    dispatch({ type: 'reset' });
  };
  const {
    user,
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = globalState as Record<string, any>;

  const getProcessEventFunction = useCallback(
    (event: string, username: string) => {
      const map: { [key: string]: (data: any) => Promise<void> | void } = {
        [WebSocketEvent.FORCE_LOGOUT]: async (data: any) => {
          if (username == data.username) {
            await userLogout();
          }
        },
        [WebSocketEvent.SEND_GA4_WATCHING_DATA_AFTER_LIVE_CAST]: async (
          data: any,
        ) => {
          await sendPostLiveCastDataToGa4(data);
        },
      };
      return map[event];
    },
    [],
  );
  const sendPostLiveCastDataToGa4 = async (data: any) => {
    ga4Sender.sendMetricWhenLiveCastEnd({
      livecastId: data.live_cast_id,
      totalView: data.total_view,
      averageTimeWatched: data.average_time_watching,
      totalHoursWatched: data.total_time_watching,
      artistId: username,
      duration: data.duration,
    });
  };
  const { socket_event } = globalState as Record<string, any>;
  useEffect(() => {
    if (socket_event && user.username) {
      const { event } = socket_event;
      const processEventFunction = getProcessEventFunction(
        event,
        user.username,
      );
      processEventFunction && processEventFunction(socket_event);
    }
  }, [getProcessEventFunction, socket_event, user]);

  return (
    <Box
      component="main"
      sx={{ display: 'flex', minHeight: '100vh', position: 'relative' }}
    >
      <Suspense fallback={<LoadingScreen />}>
        <ManageProfileProvider>
          <Routes>
            <Route element={<ArtistPageRouteWrapper />}>
              <Route
                path={RoutesPages.ARTIST_PAGE}
                loader={() => ({ test: 1 })}
                element={<UserPage />}
              />
            </Route>
            <Route element={<NonArtistPageRouteWrapper />}>
              <Route path={RoutesPages.HOME} element={<HomePage />} />
              <Route
                path={RoutesPages.FOR_ARTISTS}
                element={<ForArtistsPage />}
              />
              <Route
                path={RoutesPages.COLLECTIBLES}
                element={<CollectiblesPage />}
              />
              <Route path={RoutesPages.ABOUT} element={<AboutPage />} />
              <Route path={RoutesPages.CONTACT} element={<ContactPage />} />
              <Route path={RoutesPages.FAQ} element={<FaqPage />} />
              <Route
                path={RoutesPages.FORMAT_IDEAS}
                element={<FormatIdeasPage />}
              />
              <Route
                path={RoutesPages.PROMOTION_ARTIST}
                element={<PromotionArtistPage />}
              />
              <Route
                path={RoutesPages.PROMOTION_TIP}
                element={<PromotionTipPage />}
              />
              <Route
                path={RoutesPages.PROMOTION_THEME}
                element={<PromotionThemePage />}
              />
              <Route
                path={RoutesPages.FOLLOWING_ARTIST}
                element={<FollowingArtistPage />}
              />
              <Route
                path={RoutesPages.UNBLOCK_USERS}
                element={<ArtistUnblockUsersPage />}
              />
              <Route
                path={RoutesPages.PRIVACY_POLICY}
                element={<PrivacyPage />}
              />

              <Route
                path="/activate_account/:key"
                element={<ActivateAccountPage />}
              />
              <Route
                path={RoutesPages.CONFIRM_UPDATE_EMAIL}
                element={<ConfirmUpdateEmail />}
              />
              <Route
                path="/reset_password/:key"
                element={<ResetPasswordPage />}
              />
              <Route
                path="/:username/:featureName?/:extraData?/:eventId?"
                element={<UserPage />}
              />
              <Route
                path="/manage_email_preferences/:sendgridUnsubscribeContactId"
                element={<ManageEmailPreferencesPage />}
              />
              <Route
                path="/unsubscribe/:suppressionGroupId/:sendgridUnsubscribeContactId"
                element={<UnsubscribePage />}
              />
              <Route path="/:username/login" element={<UserLoginPage />} />
              <Route path="/event/:eventId" element={<EventPage />} />
              <Route path="/past_event/:eventId" element={<PastEventPage />} />
              <Route path={RoutesPages.NOT_FOUND} element={<NotFoundPage />} />
              <Route
                path={RoutesPages.ACCESS_DENIED}
                element={<AccessDeniedPage />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </ManageProfileProvider>
      </Suspense>
    </Box>
  );
};
export default Router;
