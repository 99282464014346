import { createModel } from '@rematch/core';
import type { RootModel } from '.';
import { UserInfo } from '~stores';
import { getUserInfoByName } from '~api/user';
import { USER_TYPE } from '~utils/constants';

export interface RoomInfoState {
  isFetchedArtistInfo: boolean;
  artistInfo: UserInfo;
}

const initState = {
  isFetchedArtistInfo: false,
  artistInfo: {},
} as RoomInfoState;

export const roomInfo = createModel<RootModel>()({
  state: initState,
  selectors: (slice) => ({
    getArtistId() {
      return slice((roomInfo: RoomInfoState) => roomInfo.artistInfo.id);
    },
    getArtistInfo() {
      return slice((roomInfo: RoomInfoState) => roomInfo.artistInfo);
    },
    isFetchedArtistInfo() {
      return slice((roomInfo: RoomInfoState) => roomInfo.isFetchedArtistInfo);
    },
  }),
  reducers: {
    setArtistInfo: (state, payload: UserInfo) => {
      state.artistInfo = payload;
    },
    setIsFetchedArtistInfo: (state, isFetchedArtistInfo: boolean) => {
      state.isFetchedArtistInfo = isFetchedArtistInfo;
    },
  },
  effects: (dispatch) => ({
    async fetchArtistInfo(artistName: string) {
      try {
        const artistInfo = await getUserInfoByName(artistName);
        // Todo: Ask BE team to create separated API for artist only
        if (artistInfo.type !== USER_TYPE.ARTIST) {
          throw new Error("Artist doesn't exist");
        }
        dispatch.roomInfo.setArtistInfo(artistInfo);
      } catch (e) {
        throw e;
      } finally {
        dispatch.roomInfo.setIsFetchedArtistInfo(true);
      }
    },
  }),
});
