import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { Box, Stack, alpha, styled } from '@mui/material';
// routes
import { useNavigate, useParams } from 'react-router-dom';
// services
import { deleteQuestions } from '~api/artist';
import {
  addNewEvent,
  endAllCurrentEvent,
  endEvent as endEventApi,
  getUserEvents,
  startEvent,
  trackingEvent,
} from '~api/event';
import { getRewardPageData } from '~api/reward';
import { getTipsHistory, getTipsList } from '~api';
import { ChatType, createGroupChat } from '~api/chats';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import {
  getCurrentAttendees,
  getQuestionsByArtistId,
  getTopTipList,
} from '~components/action';
// auth
import { AuthContext } from '~providers/AuthProvider';
// hooks
import { useRematchDispatch } from '~components/custom-hook/useRematchDispatch';
import useArtistEvent from '~components/custom-hook/useArtistEvent';
import useAddAvatar from '~hooks/useAddAvatar';
import { useBoolean } from '~hooks/useBoolean';
// store
import { RematchRootState } from '@rematch/core';
import { RootModel } from '~stores/models';
import { FullModel, UserInfo } from '~stores';
import {
  INPUT_LEVEL_CONFIG_DEFAULT_VALUE,
  SettingState,
} from '~stores/models/settings';
import { BringToStageContext } from '~providers/BringToStageProvider';
// utils
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  fetchToken,
  getHistoryMessages,
  loginToAgoraVideo,
} from '~utils/agoraFunctions';
import { showErrorToaster } from '~utils/toasterNotification';
import {
  generateChannelName,
  mappingChatData,
  mappingTipData,
  strictValidArrayWithLength,
  validObjectWithParameterKeys,
} from '~utils/commonUtils';
import { FEATURE_FLAGS_TYPE, POPUP_TYPE, USER_TYPE } from '~utils/constants';
import {
  AgoraVideoContext,
  SocketContext,
  useGlobalDispatch,
  useGlobalState,
} from '~utils/container';
import { fetchChatUserDetail } from '~utils/chatUtils';
// components
import { ArtistName, Logo } from '~components/atoms';
import Account from '~components/organisms/account';
import LiveCastOptions from '~components/organisms/livecast-options';
import { EndLiveModal } from '~components/organisms';
// types
import { CampaignStartedResponse, WebSocketEvent } from '~socket/types';
import {
  TransactionHistoryResponse,
  TransactionTipView,
} from '~types/transaction';
import { ReportView } from '~types/event';
import { Tipper } from '~types/artist';
import { Chat } from '~types/chats';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import { RoutesPages } from '../../../router/types';
import { EmojiType } from '~components/molecules/emoji-reaction';

const StyledHeader = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2.5)} ${theme.spacing(3)}`,
  backgroundColor: alpha(theme.palette.common.black, 0.5),
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up('lg')]: {
    backgroundColor: 'transparent',
    marginBottom: 0,
  },
  [theme.breakpoints.up(1440)]: {
    padding: `${theme.spacing(2.5)} 0`,
  },
}));

export interface ChatEvent {
  id: string;
  user_id: string;
  username: string;
  user_image_url?: string;
  group_id: string;
  message: string;
  created_at: string;
}

interface ArtistHeaderProps {
  artistName?: string;
  hideLogin?: boolean;
  emitEmoji?: (emojiType: EmojiType, text?: string) => void;
}
const ArtistHeader = ({
  artistName: artistNameProp,
  hideLogin = false,
  emitEmoji = () => undefined,
}: ArtistHeaderProps) => {
  const { isEnable } = useFeatureFlag();

  const isEnableBlockUser = isEnable(FEATURE_FLAGS_TYPE.BLOCKED_USER);
  const isEnableEmojiReaction = isEnable(
    FEATURE_FLAGS_TYPE.ACTIVE_EMOJI_REACTION,
  );
  const isEnableArchivedVideo = isEnable(
    FEATURE_FLAGS_TYPE.ARCHIVED_VIDEO_FLOW,
  );

  const queryClient = useQueryClient();

  const rematchDispatch = useDispatch();

  const dispatch = useGlobalDispatch();

  const globalState = useGlobalState();

  const navigate = useNavigate();

  const { username: artistName } = useParams();

  const socket = useContext(SocketContext);

  const artistInfo = useSelector<
    RematchRootState<RootModel, FullModel>,
    UserInfo
  >((state) => state.roomInfo?.artistInfo);
  const { id: artist } = artistInfo || {};

  const { fetchUpComingEvent } = useArtistEvent();
  const { updateAvatar } = useAddAvatar();
  const { logout } = useContext(AuthContext);
  const agoraVideo = useContext(AgoraVideoContext);
  const { enableBringToStage } = useContext(BringToStageContext);

  const {
    user: { type, imageUrl, username, id: userId, isPartner },
    artist: {
      name,
      currentEvent,
      id: artistId,
      displayUsername,
      username: artistUsername,
    },
    app: { chatList, stageFanUsername },
    config: {
      isArtistLive,
      chatGroupId,
      removeFromStage,
      remoteUsers,
      resumeArtistSession,
      localVideoTrack,
      localAudioTrack,
    },
    socket_event,
    // TODO need to check state data properties, remove as any to see detail error.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = globalState as Record<string, any>;
  const { fetchMoreAvatars } = useRematchDispatch(
    (dispatch) => dispatch.common,
  );

  const intervalRef = useRef<NodeJS.Timer>();

  const [isConnecting, setIsConnecting] = useState(false);

  const [reportData, setReportData] = useState<ReportView | undefined>();

  const popupEndLiveCast = useBoolean(false);

  const popupStartLiveCast = useBoolean(false);

  const {
    selectedCameraConfig,
    selectedMicrophoneConfig,
    inputLevelConfig = INPUT_LEVEL_CONFIG_DEFAULT_VALUE,
    enableAECConfig,
    selectedScreenRatio,
  } = useSelector<RematchRootState<RootModel, FullModel>, SettingState>(
    (state) => state.settings,
  );

  const { refetch: refetchReward } = useQuery({
    queryKey: [QUERY_KEYS.ARTIST_REWARDS],
    queryFn: () => getRewardPageData(artistId),
    enabled: false,
    onSuccess: (data) => {
      dispatch({
        type: 'app',
        payload: {
          activeCampaign: data.activeCampaign,
          upcomingCampaigns: data.upcomingCampaigns ?? [],
          previousCampaigns: data.previousCampaigns ?? [],
          totalPreviousCampaign: data.totalPreviousCampaign,
          totalUpcomingCampaign: data.totalUpcomingCampaign,
        },
      });
    },
  });

  const { refetch: refetchTips } = useQuery({
    queryKey: [QUERY_KEYS.ARTIST_TIPS],
    queryFn: () => getTipsList(artistId, currentEvent),
    enabled: false,
    onSuccess: (data: { transactions: Tipper[]; totalEarned: number }) => {
      dispatch({
        type: 'artist',
        payload: {
          top_tips: data.transactions,
          total_earned_in_event: data.totalEarned,
        },
      });
    },
  });

  const { refetch: refetchTipHistory } = useQuery({
    queryKey: [QUERY_KEYS.ARTIST_TIP_HISTORY],
    queryFn: () => getTipsHistory(artistId),
    enabled: false,
    onSuccess: ({ transactions }: TransactionHistoryResponse) => {
      const tips = (transactions as TransactionTipView[])?.map((tip) =>
        mappingTipData(tip),
      );
      dispatch({
        type: 'artist',
        payload: { tips_chronologically: tips },
      });
    },
  });

  useEffect(() => {
    if (socket_event) {
      const { event } = socket_event;
      const processEventFunction = getProcessEventFunction(event);
      processEventFunction && processEventFunction(socket_event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket_event]);

  useEffect(() => {
    if (removeFromStage && localAudioTrack && type === USER_TYPE.FAN) {
      (async () => {
        const { localTracks: agoraVideo_localTracks } = agoraVideo || {};
        // TODO need to define data type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let payload = { removeFromStage: false } as any;
        if (strictValidArrayWithLength(agoraVideo_localTracks)) {
          await agoraVideo?.unpublish();
          localAudioTrack && (await localAudioTrack.close());
          localVideoTrack && (await localVideoTrack.close());
          const cameraConfig = {
            encoderConfig: selectedScreenRatio,
            cameraId: selectedCameraConfig?.deviceId,
          };
          const cameraTrack = await createLocalVideoTrack(cameraConfig);
          await agoraVideo?.publish(cameraTrack);
          payload = {
            localAudioTrack: null,
            localVideoTrack: cameraTrack,
          };
        }
        payload.removeFromStage = false;
        dispatch({
          type: 'config',
          payload,
        });
      })();
    } else {
      dispatch({
        type: 'config',
        payload: { removeFromStage: false },
      });
    }
  }, [
    agoraVideo,
    dispatch,
    localAudioTrack,
    localVideoTrack,
    removeFromStage,
    selectedCameraConfig?.deviceId,
    selectedScreenRatio,
    type,
  ]);

  const handleTokenExpire = useCallback(async () => {
    const channel = generateChannelName(displayUsername, currentEvent);
    await loginToAgoraVideo(agoraVideo, channel, username);
  }, [agoraVideo, currentEvent, displayUsername, username]);

  const handleUserPublished = useCallback(
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (user: any, mediaType: any) => {
      await agoraVideo?.subscribe(user, mediaType);

      const remote = Array.from(agoraVideo?.remoteUsers || []).filter(
        ({ hasVideo }) => hasVideo,
      );
      dispatch({
        type: 'config',
        payload: {
          remoteUsers: remote,
        },
      });
    },
    [agoraVideo, dispatch],
  );

  const handleUserUnpublished = useCallback(
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (user: any, mediaType: any) => {
      const remote = Array.from(agoraVideo?.remoteUsers || []).filter(
        ({ hasVideo }) => hasVideo,
      );
      await agoraVideo?.unsubscribe(user, mediaType);
      dispatch({
        type: 'config',
        payload: {
          remoteUsers: remote,
        },
      });
    },
    [agoraVideo, dispatch],
  );

  useEffect(() => {
    if (!agoraVideo) return;

    const channel = `${displayUsername}_${currentEvent}`;
    // add event listener to play remote tracks when remote user publishes.
    agoraVideo.on('user-published', handleUserPublished);
    agoraVideo.on('user-unpublished', handleUserUnpublished);
    // When token-privilege-will-expire occurs, fetch a new token from the server and call renewToken to renew the token.
    agoraVideo.on('token-privilege-will-expire', async function () {
      const token = await fetchToken(channel, username);
      await agoraVideo.renewToken(token || '');
    });

    // When token-privilege-did-expire occurs, fetch a new token from the server and call join to rejoin the channel.
    agoraVideo.on('token-privilege-did-expire', handleTokenExpire);
    // agoraVideo.on('stream-type-changed', onStreamTypeChanged);
    // agoraVideo.on('user-info-updated', onUserInfoChanged);
    return () => {
      agoraVideo?.off('user-published', handleUserPublished);
      // agoraVideo?.off('stream-type-changed', onStreamTypeChanged);
      agoraVideo?.off('user-unpublished', handleUserUnpublished);
      agoraVideo?.off('token-privilege-did-expire', handleTokenExpire);
      // agoraVideo?.off('user-info-updated', onUserInfoChanged);
    };
  }, [
    agoraVideo,
    currentEvent,
    displayUsername,
    username,
    chatGroupId,
    type,
    handleTokenExpire,
    handleUserPublished,
    handleUserUnpublished,
  ]);

  const getRemoteUsersDetails = useCallback(async () => {
    let upDatedFans = [];
    if (strictValidArrayWithLength(remoteUsers)) {
      // TODO need to define data type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      upDatedFans = remoteUsers.map(async (fan: any) => {
        const { uid } = fan;
        return fetchChatUserDetail(uid, chatGroupId);
      });
      upDatedFans = await Promise.all(upDatedFans);
    }
    dispatch({
      type: 'config',
      payload: { remoteUsersDetails: upDatedFans },
    });
  }, [chatGroupId, dispatch, remoteUsers]);

  useEffect(() => {
    if (type === USER_TYPE.ARTIST) getRemoteUsersDetails();
  }, [getRemoteUsersDetails, remoteUsers, type]);

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_question_add = async (data: any) => {
    const { image_url, username } = data;
    await updateAvatar(username, image_url);
    await getQuestionsByArtistId(artistId, dispatch);
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_question_list = async (data: any) => {
    if (data?.artist_id === artistId) {
      await getQuestionsByArtistId(artistId, dispatch);
    }
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const startChatting = async (chat_id: string) => {
    if (!userId || !chat_id) return;
    if (chat_id) {
      await getHistoryMessages(dispatch, chat_id, fetchMoreAvatars);
    }
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_start_event = (data: any) => {
    const { event_id: event, username, attendees } = data;
    const isValidArtist = username === artistUsername;

    if (isValidArtist && event) {
      dispatch({
        type: 'config',
        payload: {
          isArtistLive: true,
        },
      });
      dispatch({
        type: 'app',
        payload: { popup: POPUP_TYPE.NONE },
      });
      dispatch({
        type: 'artist',
        payload: { currentEvent: event },
      });
      dispatch({
        type: 'config',
        payload: { attendies: attendees },
      });
      startChatting(chatGroupId);
    }
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_welcome = () => {
    //
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_end_event = async (data: any) => {
    if (data?.username === artistUsername) {
      await endEvent();
    }
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_events_changed = async (data: any) => {
    const { event } = data;
    if (!event) {
      return;
    }
    try {
      const res = await getUserEvents(artist);
      const { upcoming: events } = res;
      dispatch({
        type: 'artist',
        payload: {
          events,
        },
      });
    } catch (err) {
      showErrorToaster(err);
    }
  };

  const endEvent = async () => {
    if (type === USER_TYPE.FAN || (!type && !userId)) {
      const { connectionState: agoraVideo_connectionState } = agoraVideo || {};
      if (agoraVideo_connectionState === 'CONNECTED') await agoraVideo?.leave();
      localAudioTrack && (await localAudioTrack.close());
      localVideoTrack && (await localVideoTrack.close());
      dispatch({
        type: 'config',
        payload: {
          isArtistLive: false,
          isFanLive: false,
          remoteUsers: [],
          localAudioTrack: null,
          localVideoTrack: null,
          current_attendies: 0,
          startLiveAt: null,
        },
      });
      popupEndLiveCast.onTrue();
      dispatch({
        type: 'artist',
        payload: { currentEvent: '' },
      });
    }
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_block_user = async (data: any) => {
    const {
      blocked_user_id,
      fan_id,
      artist_id: artistIdFromSocketEvent,
    } = data;
    let isCurrentUser = fan_id === userId;

    if (isEnableBlockUser) {
      isCurrentUser = blocked_user_id === userId;

      await queryClient.refetchQueries({
        queryKey: [
          QUERY_KEYS.ARTIST_BLOCKED_USERS_LIST,
          artistIdFromSocketEvent,
        ],
        type: 'active',
      });
    }

    if (
      type === USER_TYPE.FAN &&
      isCurrentUser &&
      artistIdFromSocketEvent === artistId
    ) {
      const { connectionState: agoraVideo_connectionState } = agoraVideo || {};
      if (agoraVideo_connectionState === 'CONNECTED') {
        if (localAudioTrack) await localAudioTrack.close();
        if (localVideoTrack) await localVideoTrack.close();
        await agoraVideo?.leave();
      }
      const payload = {
        remoteUsers: [],
        localAudioTrack: null,
        localVideoTrack: null,
        // TODO need to define data type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;
      payload.isArtistLive = false;
      payload.isFanLive = false;
      dispatch({
        type: 'config',
        payload,
      });
      socket?.close();
      if (isEnableBlockUser) {
        navigate(RoutesPages.ACCESS_DENIED);
      }
      showErrorToaster('Blocked by Artist');
    }
  };

  const on_unblock_user = async (data: any) => {
    const { artistIdFromSocketEvent } = data;

    if (artistIdFromSocketEvent === artistId) {
      await queryClient.refetchQueries({
        queryKey: [
          QUERY_KEYS.ARTIST_BLOCKED_USERS_LIST,
          artistIdFromSocketEvent,
        ],
        type: 'active',
      });
    }
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_stage_invitation = (info: any) => {
    const fanId = info?.fan_id;
    if (
      type === USER_TYPE.FAN &&
      fanId === userId &&
      info?.artist_id === artistId
    )
      dispatch({ type: 'app', payload: { popup: 'stage-invite' } });
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_chat_id_added = (info: any) => {
    if (info?.username !== artistUsername) {
      return;
    }
    if (type !== USER_TYPE.ARTIST) {
      const { chat_id } = info;
      dispatch({ type: 'config', payload: { chatGroupId: chat_id } });
    }
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_delete_event = async (info: any) => {
    if (info?.username === artistUsername && type !== USER_TYPE.ARTIST) {
      rematchDispatch.artist.fetchArtistEvents(userId);
      await fetchUpComingEvent();
    }
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_remove_from_stage = async (info: any) => {
    const targetUserName = info?.username;
    const eventId = info?.event_id;

    if (targetUserName !== username || eventId !== currentEvent) {
      return;
    }
    if (type === USER_TYPE.FAN) {
      dispatch({
        type: 'config',
        payload: { removeFromStage: true },
      });

      // Cleanup when fan removed from stage
      dispatch({
        type: 'app',
        payload: { stageFanId: '', stageFanUsername: '' },
      });
    }
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_accept_decline_stage_invite = async (info: any) => {
    const accept = info?.accept;
    const targetUserName = info?.username;
    const fanUsername = info?.fan_username;
    const fanId = info?.fan_id;
    const eventId = info?.event_id;
    enableBringToStage();
    if (targetUserName !== username || eventId !== currentEvent) {
      return;
    }

    if (type === USER_TYPE.ARTIST) {
      if (accept) {
        dispatch({
          type: 'app',
          payload: {
            stageFanId: fanId,
            stageFanUsername: fanUsername,
          },
        });
      } else {
        await updateAvatar(stageFanUsername, info?.profile_pic);
        dispatch({
          type: 'app',
          payload: {
            fanDeclined: true,
            stageFanProfilePic: info?.profile_pic,
          },
        });
      }
    }
  };

  // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const on_allow_on_stage = async (info: any) => {
    const allowOnStage = info?.allow_on_stage;
    const targetUserName = info?.username;
    const eventId = info?.event_id;
    const displayUsername = info?.display_username;
    const fanUsername = info?.fan_username;

    if (targetUserName !== username || eventId !== currentEvent) {
      return;
    }

    if (type === USER_TYPE.ARTIST && allowOnStage) {
      await updateAvatar(fanUsername, info?.profile_pic);
      dispatch({
        type: 'app',
        payload: {
          popup: 'allowOnStage',
          fan_data_allow_on_stage: {
            imageUrl: info?.profile_pic,
            displayUsername,
            username: fanUsername,
          },
        },
      });
    }
  };

  const on_tip_send = async (info: {
    artist_id: string;
    image_url: string;
    created_at: string;
  }) => {
    if (info?.artist_id === artistId) {
      dispatch({
        type: 'tip_rtn',
        payload: {
          ...info,
          imageUrl: info.image_url,
          createdAt: info.created_at,
        },
      });
    }
    await refetchReward();
  };

  const on_attendees_updated = (info: {
    artist_id: string;
    attendees: string;
  }) => {
    if (info?.artist_id !== artistId) {
      return;
    }
    if (validObjectWithParameterKeys(info, ['attendees'])) {
      dispatch({
        type: 'config',
        payload: {
          current_attendies: parseInt(info.attendees),
        },
      });
    }
  };

  const on_show_attendees_state_updated = (info: {
    artist_id: string;
    unique_attendees: string;
    current_attendees: string;
    is_showed: boolean;
  }) => {
    if (info?.artist_id !== artistId) {
      return;
    }

    if (info?.current_attendees) {
      dispatch({
        type: 'config',
        payload: {
          current_attendies: parseInt(info.current_attendees),
        },
      });
    }

    if (info?.unique_attendees) {
      dispatch({
        type: 'config',
        payload: {
          uniqueAttendees: parseInt(info.unique_attendees),
        },
      });
    }

    if (info?.is_showed != undefined) {
      dispatch({
        type: 'config',
        payload: {
          isShowed: info.is_showed,
        },
      });
    }
  };

  const on_chat_added = async ({
    username,
    chat,
  }: {
    username: string;
    chat: ChatEvent;
  }) => {
    const message: Chat = mappingChatData(chat);
    if (chatGroupId !== message.groupId) return;
    if (message.userId === userId && message.type !== ChatType.SYSTEM) return;
    const updatedChatList = [...chatList, message];
    await updateAvatar(username, message.userImageUrl || '');
    dispatch({ type: 'app', payload: { chatList: updatedChatList } });
  };

  const onCampaignStarted = async ({ username }: CampaignStartedResponse) => {
    if (username === artistUsername) {
      await refetchReward();
      await refetchTips();
      await refetchTipHistory();
    }
  };

  const emitEmojiReaction = ({
    event_id,
    type,
    sender_name,
  }: {
    username: string;
    sender_id: string;
    sender_name: string;
    event_id: string;
    type: EmojiType;
    times: string;
  }) => {
    if (!isEnableEmojiReaction) {
      return;
    }
    if (event_id === currentEvent) {
      emitEmoji(type, sender_name);
    }
  };
  const getProcessEventFunction = (event: string) => {
    // TODO need to define data type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const map: { [key: string]: (data: any) => Promise<void> | void } = {
      [WebSocketEvent.WELCOME]: on_welcome,
      [WebSocketEvent.START_EVENT]: on_start_event,
      [WebSocketEvent.END_EVENT]: on_end_event,
      [WebSocketEvent.CREATE_EVENT]: on_events_changed,
      [WebSocketEvent.UPDATE_EVENT]: on_events_changed,
      [WebSocketEvent.BLOCK_USER]: on_block_user,
      [WebSocketEvent.UNBLOCK_USER]: on_unblock_user,
      [WebSocketEvent.BLOCK_FAN]: on_block_user,
      [WebSocketEvent.JOIN_EVENT]: on_attendees_updated,
      [WebSocketEvent.LEAVE_EVENT]: on_attendees_updated,
      [WebSocketEvent.SHOW_ATTENDEES_STATE]: on_show_attendees_state_updated,
      [WebSocketEvent.QUESTION_ADD]: on_question_add,
      [WebSocketEvent.QUESTION_LIST]: on_question_list,
      [WebSocketEvent.CHAT_ID_ADDED]: on_chat_id_added,
      [WebSocketEvent.TIP_SEND]: on_tip_send,
      [WebSocketEvent.STAGE_INVITATION]: on_stage_invitation,
      [WebSocketEvent.ACCEPT_DECLINE_STAGE_INVITE]:
        on_accept_decline_stage_invite,
      [WebSocketEvent.DELETE_EVENT]: on_delete_event,
      [WebSocketEvent.ALLOW_ON_STAGE]: on_allow_on_stage,
      [WebSocketEvent.REMOVE_FROM_STAGE_RESPONSE]: on_remove_from_stage,
      [WebSocketEvent.CHAT_ADDED]: on_chat_added,
      [WebSocketEvent.CAMPAIGN_STARTED]: onCampaignStarted,
      [WebSocketEvent.SEND_REACTION]: emitEmojiReaction,
    };
    return map[event];
  };

  const disconnect = async () => {
    const { connectionState: agoraVideo_connectionState } = agoraVideo || {};

    if (agoraVideo_connectionState === 'CONNECTED') {
      if (localAudioTrack) await localAudioTrack.close();
      if (localVideoTrack) await localVideoTrack.close();
      await agoraVideo?.leave();
    }

    const payload = {
      remoteUsers: [],
      localAudioTrack: null,
      localVideoTrack: null,
      // TODO need to define data type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
    if (type === USER_TYPE.ARTIST) payload.isArtistLive = false;
    if (type === USER_TYPE.FAN) payload.isFanLive = false;
    dispatch({
      type: 'config',
      payload,
    });
  };

  const startTrackingEvent = (eventId: string) => {
    const interval = setInterval(async () => {
      if (eventId) {
        await trackingEvent(eventId);
      }
    }, 60000);
    intervalRef.current = interval;
  };

  const endTrackingEvent = () => {
    if (intervalRef?.current) {
      clearInterval(intervalRef?.current);
    }
  };

  const handleLeaveEvent = async (): Promise<ReportView | undefined> => {
    if (!currentEvent) return;
    try {
      setIsConnecting(true);

      if (localAudioTrack) await localAudioTrack.close();
      if (localVideoTrack) await localVideoTrack.close();
      await agoraVideo?.leave();

      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        data: { report },
      } = await endEventApi(currentEvent);

      setReportData(report);

      const payload = {
        remoteUsers: [],
        localAudioTrack: null,
        localVideoTrack: null,
        isArtistLive: false,
        startLiveAt: null,
      } as Record<string, any>;
      dispatch({
        type: 'config',
        payload,
      });
      dispatch({
        type: 'artist',
        payload: { currentEvent: '' },
      });
      endTrackingEvent();
      popupEndLiveCast.onTrue();
      return undefined;
    } catch (error) {
      showErrorToaster(error);
    } finally {
      setIsConnecting(false);
    }
  };

  const prepareLiveCastDevice = useCallback(async () => {
    let cameraTrack;
    let microphoneTrack;
    try {
      setIsConnecting(true);
      // create local audio and video tracks
      let microphoneConfig: {
        AEC: boolean;
        microphoneId?: string;
      } = {
        AEC: enableAECConfig,
      };
      let cameraConfig = {
        encoderConfig: selectedScreenRatio,
        // TODO need to define data type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;

      if (selectedMicrophoneConfig) {
        microphoneConfig = {
          ...microphoneConfig,
          microphoneId: selectedMicrophoneConfig.deviceId,
        };
      }

      if (selectedCameraConfig) {
        cameraConfig = {
          ...cameraConfig,
          cameraId: selectedCameraConfig.deviceId,
        };
      }

      microphoneTrack = await createLocalAudioTrack(microphoneConfig);
      microphoneTrack.setVolume(inputLevelConfig);
      cameraTrack = await createLocalVideoTrack(cameraConfig);

      return {
        microphoneTrack,
        cameraTrack,
      };
    } catch (e) {
      if (cameraTrack) {
        cameraTrack.close();
      }
      if (microphoneTrack) {
        microphoneTrack.close();
      }
      if (e instanceof Error && e.message) {
        throw e.message;
      }

      throw 'Unknown error when setting camera and microphone';
    } finally {
      setIsConnecting(false);
    }
  }, [
    enableAECConfig,
    inputLevelConfig,
    selectedCameraConfig,
    selectedMicrophoneConfig,
    selectedScreenRatio,
  ]);

  const startLiveCast = useCallback(
    async (isNotifyFollowers: boolean, thumbnail?: string) => {
      popupStartLiveCast.onFalse();
      const { microphoneTrack, cameraTrack } = await prepareLiveCastDevice();

      await endAllCurrentEvent();
      dispatch({
        type: 'config',
        payload: { current_attendies: 0 },
      });

      const {
        data: { event },
      } = await addNewEvent(undefined, thumbnail);
      const currentEvent = event.id;
      await getQuestionsByArtistId(artistId, dispatch);
      await agoraVideo?.setClientRole('host');
      const channel = generateChannelName(displayUsername, currentEvent);
      // TODO need to define data type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payload = {} as any;
      const callback = async (token: string, channel: string, uid: number) => {
        let recordInfo;
        if (isEnableArchivedVideo) {
          recordInfo = {
            cname: channel,
            uid: uid,
            rtcToken: token,
          };
        } else if (isPartner) {
          recordInfo = {
            cname: channel,
            uid: uid,
            rtcToken: token,
          };
        }
        await startEvent(
          currentEvent,
          socket?.currentSession() || '',
          isNotifyFollowers,
          recordInfo,
        );
      };
      const isConnectedInToVideoServer = await loginToAgoraVideo(
        agoraVideo,
        channel,
        username,
        callback,
      );
      if (!isConnectedInToVideoServer) {
        return;
      }

      // create local audio and video tracks
      if (cameraTrack) {
        await agoraVideo?.publish(cameraTrack);
      }
      if (microphoneTrack) {
        await agoraVideo?.publish(microphoneTrack);
      }
      payload.localVideoTrack = cameraTrack;
      payload.localAudioTrack = microphoneTrack;
      payload.isArtistLive = true;
      payload.startLiveAt = event?.createdAt;

      dispatch({
        type: 'config',
        payload,
      });
      dispatch({
        type: 'artist',
        payload: { currentEvent },
      });
      startTrackingEvent(currentEvent);
    },
    [
      agoraVideo,
      artistId,
      dispatch,
      displayUsername,
      isPartner,
      popupStartLiveCast,
      prepareLiveCastDevice,
      socket,
      username,
    ],
  );

  const handleClearChatTab = useCallback(async () => {
    const channel = artistName?.toLocaleLowerCase();
    if (!channel) {
      return;
    }
    const {
      data: { group },
    } = await createGroupChat();
    const chatGroupId = group.id;
    if (chatGroupId) {
      await getHistoryMessages(dispatch, chatGroupId, fetchMoreAvatars);
      // await updateAllowOnStage(chatGroupId, false);
      dispatch({
        type: 'config',
        payload: { chatGroupId: chatGroupId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    artistId,
    artistUsername,
    displayUsername,
    imageUrl,
    artistName,
    fetchMoreAvatars,
  ]);

  const handleStartLiveCast = useCallback(
    async (
      isClearChat = false,
      isClearQuestion = false,
      isNotifyFollowers: boolean,
      thumbnail?: string,
    ) => {
      try {
        setIsConnecting(true);
        if (isClearChat) {
          await handleClearChatTab();
        }
        if (isClearQuestion) {
          await deleteQuestions();
        }
        await startLiveCast(isNotifyFollowers, thumbnail);
      } catch (err: any) {
        if (err.includes('read properties of null'))
          showErrorToaster('Error when setting camera and microphone');
        else {
          showErrorToaster(err);
        }
      } finally {
        setIsConnecting(false);
      }
    },
    [handleClearChatTab, startLiveCast],
  );

  const getInitialValues = async () => {
    await getQuestionsByArtistId(artistId, dispatch);
    await getTopTipList(dispatch, globalState);
  };

  const onClearOptionOnEndLiveCast = async (
    isClearChat: boolean,
    isClearQuestion: boolean,
    callback: () => void,
  ) => {
    try {
      if (isClearChat) {
        await handleClearChatTab();
      }
      if (isClearQuestion) {
        await deleteQuestions();
      }
      callback && callback();
    } catch (err) {
      showErrorToaster(err);
    }
  };

  const resumeLiveCast = async () => {
    try {
      const { microphoneTrack, cameraTrack } = await prepareLiveCastDevice();
      await getInitialValues();

      agoraVideo?.setClientRole('host');
      const channel = generateChannelName(displayUsername, currentEvent);
      // TODO need to define data type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payload = {} as any;
      const isConnectedInToVideoServer = await loginToAgoraVideo(
        agoraVideo,
        channel,
        username,
      );
      if (!isConnectedInToVideoServer) {
        setIsConnecting(false);
        return;
      }

      await agoraVideo?.publish(cameraTrack);
      await agoraVideo?.publish(microphoneTrack);
      payload.localVideoTrack = cameraTrack;
      payload.localAudioTrack = microphoneTrack;
      payload.isArtistLive = true;
      payload.resumeArtistSession = false;

      dispatch({
        type: 'config',
        payload,
      });

      startTrackingEvent(currentEvent);
      setIsConnecting(false);
    } catch (err) {
      showErrorToaster(err);
      setIsConnecting(false);
    }
  };

  const startGuestMode = async () => {
    try {
      const res = await getUserEvents(artist);
      const { upcoming: events, ongoing } = res;
      const { chatId = '' } = artistInfo;
      // TODO need to define data type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataToSave = {} as any;
      dataToSave.isArtistLive = false;
      dataToSave.chatGroupId = chatId;
      dataToSave.startLiveAt = ongoing?.event?.createdAt;

      const artist_data = { events, ...artistInfo };
      const currentEvent = ongoing?.event?.id;
      // TODO need to define data type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payload = artist_data as any;
      payload.currentEvent = '';
      if (currentEvent) {
        payload.currentEvent = currentEvent;
        if (type !== USER_TYPE.ARTIST) dataToSave.isArtistLive = true;
      }
      dispatch({ type: 'config', payload: dataToSave });
      dispatch({ type: 'artist', payload });
    } catch (err) {
      showErrorToaster(err);
    }
  };

  const userLogout = async () => {
    if (type === USER_TYPE.FAN) {
      await disconnect();
    }
    if (type === USER_TYPE.ARTIST) {
      if (isArtistLive) await handleLeaveEvent();
      navigate(`/${artistName}`);
    }
    await logout();
    dispatch({ type: 'reset' });
  };

  const handleLogout = async () => {
    await userLogout();
    await startGuestMode();
  };

  useEffect(() => {
    if (
      type === USER_TYPE.ARTIST &&
      !isArtistLive &&
      userId &&
      agoraVideo &&
      resumeArtistSession
    ) {
      (async () => {
        await resumeLiveCast();
        await getCurrentAttendees(currentEvent, dispatch);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agoraVideo,
    userId,
    currentEvent,
    resumeArtistSession,
    type,
    isArtistLive,
  ]);

  return (
    <StyledHeader>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'nowrap'}
        spacing={2}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={4}
          overflow={'hidden'}
          flex={{ xs: 1, lg: 2 }}
        >
          <Logo />
          <ArtistName
            variant="h2"
            sx={{
              fontSize: { lg: '1.75rem', xs: 12 },
              '&.MuiTypography-root': {
                marginLeft: '10px',
              },
              color: { lg: '#1B202B', xs: 'white' },
              maxWidth: 'unset',
              display: 'flex',
              textShadow: 'none',
            }}
          >
            LIVE WITH {artistNameProp || name || artistName}
          </ArtistName>
        </Stack>
        {!hideLogin && (
          <Box flex={{ xs: 'unset', lg: 1 }} overflow={'hidden'}>
            <Account
              disabledLiveBtn={Boolean(
                isConnecting || !chatGroupId || !agoraVideo,
              )}
              onLeaveEvent={handleLeaveEvent}
              onStartLive={popupStartLiveCast.onTrue}
              onLogout={handleLogout}
            />
          </Box>
        )}
      </Stack>
      {popupStartLiveCast.value && (
        <LiveCastOptions
          isPartnerShip={isPartner}
          onClose={popupStartLiveCast.onFalse}
          onStartLiveCast={handleStartLiveCast}
        />
      )}

      {popupEndLiveCast.value && (
        <EndLiveModal
          reportData={reportData}
          onClose={popupEndLiveCast.onFalse}
          onClear={onClearOptionOnEndLiveCast}
        />
      )}
    </StyledHeader>
  );
};

export default ArtistHeader;
