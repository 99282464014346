import React, { useEffect, useState } from 'react';
import { FEATURE_FLAGS_TYPE, useGlobalDispatch, useGlobalState } from '~utils';

import {
  alpha,
  Box,
  Button,
  ButtonProps,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { tipsForArtist } from '~api';
import { getFanDetails } from '~components/action';
import { showErrorToaster } from '~utils/toasterNotification';
import { LoadingButton } from '~components/atoms';
import { FormProvider, RHFTextField, TipOptions } from '~components/molecules';
import { useForm } from 'react-hook-form';
import { TipInputs, tipSchema } from './validation-schemes';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatMoney } from '~utils/formatters';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import useAccountBalances from '~hooks/useAccountBalances';

const FormRow = styled(Stack)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));
FormRow.defaultProps = {
  direction: 'row',
  alignItems: 'center',
};

const Wrapper = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const LCLabel = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.common.white, 0.3),
  marginLeft: theme.spacing(1),
}));

const AmountLabel = styled(Typography)(({ theme }) => ({
  backgroundColor: 'black',
  fontWeight: '500',
  color: alpha(theme.palette.common.white, 0.3),
  flex: 1,
  textAlign: 'center',
}));
const TitleLabel = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    width: 100,
    minWidth: 100,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 12,
  },
  whiteSpace: 'nowrap',
}));

const BuyButton = styled(Button)(({ theme }) => ({
  fontSize: 14,
  textTransform: 'none',
  color: theme.palette.grey[900],
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.5),
  },
}));

type Props = {
  onSuccess?: () => void;
  onError?: (error: string) => void;
  submitBtnText?: string;
  submitBtnProps?: ButtonProps;
  showTipPopupAfterBuyLiveCoin?: boolean;
};

const TipForm = ({
  submitBtnText,
  submitBtnProps,
  onSuccess,
  onError,
  showTipPopupAfterBuyLiveCoin = true,
}: Props) => {
  const dispatch = useGlobalDispatch();
  const { showBuyLiveCoinPopup } = useDispatchPopup();
  const [loading, setLoading] = useState(false);
  const globalState = useGlobalState();
  const {
    artist: { id, currentEvent } = {},
    config: { chatGroupId },
  } = globalState;
  const { walletBalance, walletBalanceInUsd } = useAccountBalances();

  const { isEnable } = useFeatureFlag();
  const isEnableSystemChat = isEnable(
    FEATURE_FLAGS_TYPE.SYSTEM_CHAT_ON_TIP_QUESTION_FOLLOW,
  );

  const methods = useForm<TipInputs>({
    defaultValues: {
      amount: '',
      maxAmount: 0,
    },
    resolver: yupResolver(tipSchema),
  });

  const { watch, setValue, handleSubmit } = methods;

  const amount = watch('amount');

  const handleAmountChange = (value: string) => {
    setValue('amount', value);
  };

  const handleBuyMore = () => {
    showBuyLiveCoinPopup(showTipPopupAfterBuyLiveCoin);
  };

  const onSubmit = async (values: TipInputs) => {
    if (!id) return;
    setLoading(true);

    try {
      await tipsForArtist(
        parseInt(values.amount),
        id,
        currentEvent,
        isEnableSystemChat ? chatGroupId : undefined,
      );
      await getFanDetails(dispatch, globalState);
      onSuccess && onSuccess();
    } catch (err) {
      showErrorToaster(err);
      onError && onError(err as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    setValue('maxAmount', walletBalance);
  }, [setValue, walletBalance]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Box>
          <TipOptions
            selected={amount}
            availableAmount={walletBalance}
            onChange={handleAmountChange}
          />
          <Wrapper>
            <FormRow>
              <TitleLabel variant="body2">Enter your tip</TitleLabel>
              <RHFTextField
                type="number"
                name="amount"
                sx={{ textAlign: 'center' }}
              />
              <LCLabel>LC</LCLabel>
            </FormRow>
            <FormRow>
              <TitleLabel variant="body2">Available</TitleLabel>
              <AmountLabel>
                {formatMoney(walletBalanceInUsd)} (or {walletBalance} LC)
              </AmountLabel>
              <BuyButton sx={{ ml: 1 }} onClick={handleBuyMore}>
                Buy Livecoin
              </BuyButton>
            </FormRow>
          </Wrapper>
        </Box>

        <LoadingButton loading={loading} type="submit" {...submitBtnProps}>
          {submitBtnText ?? 'SUBMIT'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default TipForm;
