import React from 'react';

import MuiPhoneNumber from 'material-ui-phone-number';
import BasePopup from '~components/organisms/popup/base-popup';
import { Button, Stack, Typography, styled } from '@mui/material';
const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 600,
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  onNext: (countryCode: string) => void;
}

const DEFAULT_COUNTRY_CODE = 'us';
const SelectDefaultCountryCodeModal = ({
  open,
  onClose,
  onBack,
  onNext,
}: Props) => {
  const [countryCode, setCountryCode] =
    React.useState<string>(DEFAULT_COUNTRY_CODE);
  const handleCLickOnNext = () => {
    onNext(countryCode);
  };
  const onCountryCodeChange = (
    value: any,
    allDetails: { countryCode: string },
  ) => {
    setCountryCode(allDetails.countryCode);
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      keepMounted={false}
      title="Missing country code"
      hideBackButton={false}
      onBack={onBack}
    >
      <Stack spacing={3}>
        <Typography
          pt={3}
          fontWeight={'500'}
          fontSize={{ xs: '16px', sm: '20px' }}
          variant="subtitle1"
          lineHeight={{ xs: '24px', sm: '30px' }}
          align="left"
        >
          Some selected contacts do not include the country code. Please select
          the country code
        </Typography>

        <MuiPhoneNumber
          disabled={true}
          disableAreaCodes={true}
          defaultCountry={DEFAULT_COUNTRY_CODE}
          //eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={onCountryCodeChange}
        />

        <Button
          color="warning"
          onClick={handleCLickOnNext}
          sx={{ color: 'white', textTransform: 'none', cursor: 'pointer' }}
        >
          Next
        </Button>
      </Stack>
    </StyledModal>
  );
};

export default SelectDefaultCountryCodeModal;
