import * as React from 'react';
import { SVGProps } from 'react';

const VenmoSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path fill="url(#a)" d="M0 0h16v16H0z" />
    <defs>
      <pattern
        id="a"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#b" transform="scale(.00362)" />
      </pattern>
      <image
        id="b"
        width={276}
        height={276}
        xlinkHref={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARQAA
        AEUCAMAAADdrjlLAAAAt1BMVEUAAAAAh/8Aiv8Ai/8Ajf8Ai/8Ajf8AjP8AjP8A
        jP8AjP8Ajf8AjP8Ai/8Ajf8Aiv8AgP8Ajf8Ajf8Aif8Ajf8AjP8AjP8AjP8Aj/8A
        jP8AjP8Ai/8Ai/8Aiv8AjP8Qk/9QsP8wov9Aqf9wvv+v2//f8f////+f1P8gmv/P
        6v/v+P9gt/+Axf+Pzf9AqP+/4v8fmv+Qzf+f0/+Axv+/4/+QzP+g1P8wof+w2/8gm/
        9/xv/P6f9vvv8M6gpUAAAAHnRSTlMAIDBAYICPn6+/z99QcO9gEJ9/UK/vX28QkB+w
        oJA9833aAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAB3RJTUUH5ggHAjsWaoyvQAAACIx
        JREFUeNrtnWtb4kgQhTuEWxJuo+uszowdIAQTwoC6ysxe/v/vWlaYBZMoVlJFdyd1vh
        vJS1Wd05dHhSgqq2E3m61Wu9PpOo7jejtJJdr/8u3ncLqdTrvVavbshiXOJ6vXb3ddRW
        8P08B12n27Qcyj2XYH0jw57SENmVGzYyKPQ4d1h8j9NOo7sgJyhiM0JI32QFZEg0845WI
        7slJCwGJVDAkCltGFrKQuSmC59GRF5Q2LlslvssIq1kO2Jystrwdn0paV1wWbTo5cUAtZ
        nqyFPAAVeyBroiv7o0w+yxrp88eY/C5rpT4zKUbls6ydTnaQLWuoE9PWGtQRypXF+QSYV1
        xZUzlvM7mQtVX7ze0TWWPZPFByxsp1LpRPstbq5jEZypqrx83zoQa6kLVXK1MozERKi6fsy
        QjHhZITVrhQsqXChbJXgwslq5sDk+srxrHfWbnmMJvVYcPWYRiZUctj9kjX3D1Z/bq40mUU
        B3X2UDxGceQ/+xuhTOJYX3ikvDVUbhhENtRySnkl9wUKc8hOWp6zOZPWZgyvdbmF0mcM2TUh
        m0+O/bD5pPS1ztcv3vNk3nVLyeOYkiPeYcoNKpzdMmpwdsvKFj2GkN08aDKELJQWQ0irxVDyo
        PDSJ2fx840hpNVhKHlQjD8J88cT9GWyyTsH/jSYhfN5iPxYx1QoWx53Wx47BdWAEk3GxX94Etz
        F82MtfIOhRJMkWd7N4pevuNCLRMn3eDHPaIn6MV3603U/WiXB97t1mHqZeziPdQ6PXamgfmKPC
        koUjZNlDoqDAP0Tjd/msdNKYyjbUXHojxNKIAZzSmtNoSThR1Ac9AAymFOaaAplDtPj+497gCFe
        6gllAoQSv/+4P2BPC/WE4s9RXwP6uBUmFLxnLYCvceJxT3Nl/YMIBfgWp4IKcEat9YTyjFvwPrDy
        fC2hBEAoCW7lrbSEMp3jTgEg5EBLKD5uUIF6/KOWUKD2s8Z9XqgnlBg5b8GGykJPKBvcoAK1s3st
        oQS4QQWaVH5oCWU8x32LCa7Fq4ESIQcVvwpQoPYT4D5vqSeUGDlZhFWAArSfn7ie/KAnlAA5qGwURV
        pUKED7WdQCCtR+Tq32n6sABWo/p4LKsgozBWo/Y1QomroP1H6CWkAB2s8DKpRAUyhj3KCyRG1GVVCA
        9hOjQllpCkXiBpUNqpcpgxKiBhVYzJe6QgHazz0ilIW2UJaoQQWUBdfaQgHuICaIm0wP2kKZYAaVqaK
        Ygg0FuIP4iJh6VtpCAdpPjLhI9vWFMkPcZnrCDIIqoQAPsPDM56fGUBK8oDJRNWfRoUzwgkqiKOTjQ0
        E8wLpTlGfxoSCe1YSK8iwBlBlWUFE3UvChwOxnreNIwYeSYAWVJ7zAoxzKBCuogB5zpzcUHymojNGsXQ
        MoQPv5gWLIc6k5lBlOUAlVZXwSKM8oQWWKlgG1gJKgBBVY9/i6Q4HZz08Nu4cAio8RVMYqu4cACmwbZK
        HbYpAIylP5cfCnwuRGA+W5fFCBDeuVAVCC8vNA4bqHCApsSAaluycxAQrsPsZD6ZASmQCl/C58qHTM0kA
        BXQeMtRuzNFA2JUNGrHbM0kAJygWVqeIxSwMFZj/3pcZsGBkCBWY/Y73SLBUUmP0EmvkxFZS4RFDxQ+WFQ
        gMFZD+PmvkxFZSgRFBRd1hKDGVcPGgkpYa0zlCi4kElVB3cyKDA7Oe+cKEkRkGJi/aAFoVCBGVT8PvWo1CI
        oIBuoy91KxQiKEmxoKJJoRBBiYqFDU0KhQiKLPR2uhQKFRTQd65boVBB2RQIKtoUChUUkP2s9Fn1kEJJ4F+6
        DstjWigTcFD5U4N9FGIoPjio/KV+w40aCmhA/NRjZ5Yeygzoruq38M8ABXIfYwEtlKU0E0oCCyp/65LbSKGA7O
        eHPrmNFArIfv4B2XEsTYUCsp9YIzsmhQK7ja6PHZNCeSZiEkYGQ0mIoCTSYCgTokKRJkPxaaBMjIYC/UtE2kxZU
        igzQ6csKZRnQ6csKZTA0ClLCmWKDyUyHgq+/Syl8VDA/xlKl+YhhfKEDGVaBSgbEyMKNZTAxIhCDWVsYkShhhIZ2
        jykUDDt55zNQwslNrN5aKFszGweWiiBmc1DC2VsZvPQQonMbB5aKEj2c+7mIYYSG9k8xFA2RjYPMZTAyOYhhoJhP1
        NZMSgI9vMgqwalvP2EsnpQSttPpAaKp7P9LJUw8WihLMsxWcsqQkmMc+MzQIkMi7JngSKNc+NzQClxHyP0qwplZpob7
        6C4pM9/NsyNX+QIh/T5iUFr47NBmRjmxmeB4hu0Nj6C0qX9BcGyiBKVTORX8U2yUuowlDwoNwwhrRvRYghptRhKHpQmQ
        0hryFCyuhQ2Q0jLFg2GkFZDWAwhrS9CMIS0hCDeZTJQV1soLmN4LXcLpcsY0jsHghc/2aWPEH3G8Fr9LZQeY0hnN8FBJZ
        vdtrpiDumYwp6cYz5sPznmI3jz4LWGL1B40mbnLE/aV/J2TPiU41hf91B4qGRGihDXjOIgaw+F+JDdwJQieE2Y1z3b/mH/
        2evq+n8oHGp/qXNgwuccmTHLoza3UASfE+YUCpdKTqHwqjCvUATfU/nvXkpa17U/KfQyhcK7+kdh9kg1Pyrs5DGpeQPlNU/
        tw8qleEM1XgK1xJuqbYRz32YiLI8HSg6VWu6sDN5lUtNha4sTGnJqy1HtNmz7QjCVIkxq1kFD8UHZtfGggS0+rLrkFc8SAFm1
        uN7kgJjUYyfuRoDVq3gLebYoIKvS91a6I1FMw8oWi3cpCsuq6GRpjUQZVbGHwKZTfSyOLTBkfRtUJsHeNgSWRsNKbFQ6/ZFAlT
        XsGu1Fg04Tmcivuz3DWxMrZuDeNi1Bqobdv3VcI6aM53Zv+z1iHq/7qWH3mq3WbafTdbbydlL09ju528/R7XRuW61m024Uh/EvzV
        7GS7EOUUgAAAAASUVORK5CYII=`}
      />
    </defs>
  </svg>
);

export default VenmoSvg;
