import React, {
  createContext,
  useState,
  ReactNode,
  FC,
  useContext,
} from 'react';

interface UTMParams {
  source: string | null;
  medium: string | null;
  campaign: string | null;
}

interface UTMContextProps {
  utmParams: UTMParams;
  setUtmParams: React.Dispatch<React.SetStateAction<UTMParams>>;
}

const defaultUTMParams: UTMParams = {
  source: null,
  medium: null,
  campaign: null,
};

export const UTMContext = createContext<UTMContextProps>({
  utmParams: defaultUTMParams,
  setUtmParams: () => {
    //Eslint bypass
  },
});

export const useUtmState = () => {
  const { utmParams } = useContext(UTMContext);
  return utmParams;
};

interface UTMProviderProps {
  children: ReactNode;
}

export const UTMProvider: FC<UTMProviderProps> = ({ children }) => {
  const [utmParams, setUtmParams] = useState<UTMParams>(defaultUTMParams);

  return (
    <UTMContext.Provider value={{ utmParams, setUtmParams }}>
      {children}
    </UTMContext.Provider>
  );
};
