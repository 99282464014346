import { useState, useEffect } from 'react';
import { useGlobalState } from '~utils/container';
import { strictValidArrayWithLength } from '~utils/commonUtils';
import { USER_TYPE } from '~utils/constants';
import useFollowing from '~components/custom-hook/useFollowing';
import { Box, Typography } from '@mui/material';

const ArtistSpotlightMessage = () => {
  const globalState = useGlobalState();
  const {
    config: {
      remoteUsers,
      isArtistLive,
      localVideoTrack,
      isFanspotLightOpen = true,
    },
    user: { type, username },
    artist: { username: artistUsername },
  }: Record<string, any> = globalState;

  const [fansLength, setFansLength] = useState(0);
  const { fetchFollowingStatus } = useFollowing();

  useEffect(() => {
    if (artistUsername && username && username !== artistUsername) {
      fetchFollowingStatus();
    }
  }, [artistUsername, fetchFollowingStatus, username]);

  const removeVideoWithAudio = (user: { uid: string }) => {
    return user.uid !== artistUsername;
  };

  useEffect(() => {
    let length = 0;
    if (strictValidArrayWithLength(remoteUsers)) {
      const onlyFans = remoteUsers.filter(removeVideoWithAudio);
      if (strictValidArrayWithLength(onlyFans)) {
        length = onlyFans.length;
      }
    }
    if (type === USER_TYPE.FAN && localVideoTrack) {
      length = length + 1;
    }

    setFansLength(length);
  }, [remoteUsers, localVideoTrack, type]);

  return (
    <Box>
      {isFanspotLightOpen && (
        <Typography
          variant="body2"
          fontWeight={400}
          fontSize={{ lg: 12 }}
          lineHeight={'14px'}
          color={(theme) => theme.palette.grey[100]}
        >
          {type !== USER_TYPE.ARTIST && isArtistLive && (
            <>
              Join the Fan Spotlight to be seen below by the artist and other
              fans.
            </>
          )}
          {type === USER_TYPE.ARTIST && !isArtistLive && (
            <>
              When the livecast starts you&apos;ll be able to see the fans that
              join the spotlight here.
            </>
          )}
          {type !== USER_TYPE.ARTIST && !isArtistLive && (
            <>
              When the livecast starts you&apos;ll be able to join the Fan
              Spotlight to be seen below by the artist and other fans.
            </>
          )}
          {type === USER_TYPE.ARTIST && isArtistLive && fansLength === 0 && (
            <>No fans have joined the Fan Spotlight</>
          )}
          {type === USER_TYPE.ARTIST && fansLength > 0 && (
            <>
              Tap each fan’s menu for more options. Fans with a green light next
              to their name can be pulled on stage.
            </>
          )}
        </Typography>
      )}
    </Box>
  );
};

export default ArtistSpotlightMessage;
