import { Container } from '~components/atoms';
import { ArtistHeader, ResetPasswordModal } from '~components/organisms';

const ResetPasswordPage = () => {
  return (
    <Container>
      <ArtistHeader />
      <ResetPasswordModal />
    </Container>
  );
};
export default ResetPasswordPage;
