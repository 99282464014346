export enum LandingPages {
  HOME = 'Home',
  FOR_ARTISTS = 'For Artists',
  COLLECTIBLES = 'Collectibles',
  CONTACT = 'Contact',
  FAQ = 'FAQ',
  ABOUT = 'About',
}

export type TabsValue = (typeof LandingPages)[keyof typeof LandingPages];
