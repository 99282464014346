import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import React, { useState } from 'react';
import PartnershipProgramModal from '~components/organisms/popup/partnership-program-modal';
import ArtistSettingDashboardModal from '~components/organisms/popup/setting-dashboard-modal/artist-setting-dashboard-modal';
import { useGlobalState } from '~utils';

const enum POPUP_NAME {
  PARTNER_SHIP,
  SETTING_DASHBOARD,
}

const BecomePartnershipModal = () => {
  const globalState = useGlobalState();
  const {
    user: { isPartner },
  } = globalState as Record<string, any>;
  const { hidePopup } = useDispatchPopup();
  const [popup, setPopup] = useState<POPUP_NAME>(POPUP_NAME.PARTNER_SHIP);

  const openSettingDashboardModal = () => {
    setPopup(POPUP_NAME.SETTING_DASHBOARD);
  };

  return (
    <>
      {popup === POPUP_NAME.PARTNER_SHIP && (
        <PartnershipProgramModal
          onClose={hidePopup}
          open={true}
          onOpenSettingDashboardPopup={openSettingDashboardModal}
        />
      )}
      {popup === POPUP_NAME.SETTING_DASHBOARD && (
        <ArtistSettingDashboardModal
          isPartnerShip={isPartner}
          onClose={hidePopup}
          open={true}
          hideSaveButton={true}
          title="APPLY TO BECOME A PARTNER"
        />
      )}
    </>
  );
};

export default BecomePartnershipModal;
