import { Typography, TypographyProps, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: 'none',
  },
}));

type Props = DialogProps & {
  title: string;
  titleProps?: DialogTitleProps;
  message: string;
  messageProps?: TypographyProps;
  actionProps?: DialogActionsProps;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
};

const DialogConfirm = ({
  title,
  titleProps,
  message,
  messageProps,
  actionProps,
  onCancel,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  ...rest
}: Props) => {
  return (
    <StyledDialog
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      {...rest}
    >
      <DialogTitle
        id="confirm-dialog-title"
        fontSize={18}
        fontWeight={700}
        {...titleProps}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" fontSize={{ lg: 18 }} {...messageProps}>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2 }} {...actionProps}>
        <Button
          onClick={onCancel}
          variant="text"
          sx={{ color: 'text.disabled' }}
        >
          {cancelText}
        </Button>
        <Button onClick={onConfirm} color="warning" variant="contained">
          {confirmText}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default DialogConfirm;
