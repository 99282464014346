import { useState, useEffect } from 'react';

const useMediaRecorderSupport = () => {
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    const checkMediaRecorderSupport = () => {
      if (
        typeof MediaRecorder !== 'undefined' &&
        typeof navigator.mediaDevices.getUserMedia === 'function'
      ) {
        setIsSupported(true);
      } else {
        setIsSupported(false);
      }
    };

    checkMediaRecorderSupport();
  }, []);

  return isSupported;
};

export default useMediaRecorderSupport;
