import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Button } from '~components/atoms';
import { useUserInfoState } from '~utils/container';

interface AccountTypesSelectionProps {
  nextStepFunc: (accountType: string) => void;
}

const AccountTypesSelection = (props: AccountTypesSelectionProps) => {
  const { nextStepFunc } = props;
  const [accountType, setAccountType] = useState<string | null>(null);
  const { isFanUser } = useUserInfoState();
  React.useEffect(() => {
    if (isFanUser) {
      nextStepFunc('ARTIST');
    }
  }, [isFanUser]);

  return (
    <Grid container sx={{ mt: '20px' }}>
      <GridItem item>
        <RadioFormControl fullWidth>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
          >
            <FormControlLabel
              value="ARTIST"
              control={<CustomRadio />}
              label="ARTIST"
              labelPlacement="start"
            />
            <FormControlLabel
              value="FAN"
              control={<CustomRadio />}
              label="FAN"
              labelPlacement="start"
            />
          </RadioGroup>
        </RadioFormControl>
      </GridItem>
      <GridItem item>
        <NextButton
          disabled={!accountType}
          onClick={() => nextStepFunc(accountType as string)}
        >
          Next
        </NextButton>
      </GridItem>
    </Grid>
  );
};

const RadioFormControl = styled(FormControl)(() => ({
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '8px',
  '& label': {
    margin: 0,
    width: '100%',
    position: 'relative',
  },
  '& label:first-child': {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  '& label .MuiTypography-root': {
    position: 'absolute',
    left: '15px',
    fontSize: '16px',
    lineHeight: '19px',
  },
}));

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.7)',
  '&.Mui-checked': {
    color: theme.palette.action.active,
  },
}));

const NextButton = styled(Button)(({ theme }) => ({
  margin: '0 auto',
  fontSize: theme.typography.fontSize,
  padding: '7px 30px',
  color: theme.palette.text.secondary,
  width: '100%',
  marginTop: '30px',

  '&.Mui-disabled': {
    opacity: 0.2,
  },
}));

const GridItem = styled(Grid)(() => ({
  width: '100%',
}));

export default AccountTypesSelection;
