import { showErrorToaster } from '~utils/toasterNotification';
import { strictValidArrayWithLength, strictValidString } from './commonUtils';
import { getAgoraVideoToken } from '~api';
import { getChatHistory } from '~api/chats';

async function fetchToken(channel, username = '') {
  try {
    const { accessToken } = await getAgoraVideoToken(channel, username);
    return accessToken;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function createLocalTracks(audioConfig, videoConfig) {
  const AgoraClient = await import('agora-rtc-sdk-ng');
  const [microphoneTrack, cameraTrack] =
    await AgoraClient.createMicrophoneAndCameraTracks(audioConfig, videoConfig);
  return [microphoneTrack, cameraTrack];
}

async function createLocalVideoTrack(videoConfig) {
  try {
    const AgoraClient = await import('agora-rtc-sdk-ng');
    const videoTrack = await AgoraClient.createCameraVideoTrack(videoConfig);
    return videoTrack;
  } catch (error) {
    console.log('createLocalVideoTrack error', error);
    const err = 'Failed to load. Please grant the access to devices';
    showErrorToaster(err);
    return null;
  }
}

async function createLocalAudioTrack(audioConfig) {
  try {
    const AgoraClient = await import('agora-rtc-sdk-ng');
    const deviceID = await getDefaultMicrophoneDeviceID(AgoraClient);
    const microphoneTrack = await AgoraClient.createMicrophoneAudioTrack({
      encoderConfig: 'high_quality_stereo',
      AEC: false,
      bypassWebAudio: false,
      ...audioConfig,
    });
    // There isn't pre-select microphone, set default microphone
    if (!audioConfig.microphoneId && deviceID) {
      await microphoneTrack.setDevice(deviceID);
    }
    return microphoneTrack;
  } catch (error) {
    console.log('createLocalAudioTrack error', error);
    const err = 'Failed to load. Please grant the access to devices';
    showErrorToaster(err);
    // Fix for issue: https://c0x12c.slack.com/archives/C05JP0QAUKE/p1717538327520339
    return null;
  }
}

async function getHistoryMessages(dispatch, groupId, fetchAvatar) {
  dispatch({
    type: 'app',
    payload: { chatList: [], hasMoreChat: false, latestMessageId: undefined },
  });
  try {
    const { chats, hasNextData } = await getChatHistory(groupId);
    dispatch({
      type: 'app',
      payload: {
        chatList: chats,
        hasMoreChat: hasNextData,
        latestMessageId: chats?.length > 0 ? chats[0].id : undefined,
      },
    });
    const fetchingAvatarInputMap = {};

    chats?.forEach((message) => {
      fetchingAvatarInputMap[message.username] = message.username;
    });

    if (fetchAvatar) {
      const fetchingAvatarList = Object.keys(fetchingAvatarInputMap);
      fetchAvatar(fetchingAvatarList);
    }
  } catch (error) {
    console.log('getHistoryMessages error', error);
    const err = 'Could not connect to Chat server';
    showErrorToaster(err);
    return false;
  }
}

const loginToAgoraVideo = async (agoraVideo, channel, username, callback) => {
  try {
    const {
      channelName: agoraVideo_channelName,
      connectionState: agoraVideo_connectionState,
      uid: agoraVideo_uid,
    } = agoraVideo || {};
    if (agoraVideo_connectionState === 'CONNECTING') {
      return true;
    }
    if (
      strictValidString(agoraVideo_channelName) &&
      channel === agoraVideo_channelName &&
      strictValidString(agoraVideo_uid) &&
      agoraVideo_uid === username &&
      agoraVideo_connectionState === 'CONNECTED'
    ) {
      return true;
    }

    if (agoraVideo_connectionState === 'CONNECTED') {
      await agoraVideo.leave();
    }

    const token = await fetchToken(channel, username);
    // join the channel
    await agoraVideo.join(
      process.env.REACT_APP_AGORA_APP_ID,
      channel,
      token,
      username,
    );
    callback && (await callback(token, channel, agoraVideo?._joinInfo?.uid));
    return true;
  } catch (error) {
    console.log('loginToAgoraVideo error', error);
    return false;
  }
};

const publishTrack = async (agoraVideo, localTrack) => {
  try {
    await agoraVideo.publish(localTrack);
    return true;
  } catch (error) {
    console.log('publishTrack error', error);
    const err = 'Error in publishing Track';
    showErrorToaster(err);
    return false;
  }
};

const getPublicGroupID = async (agoraChat, defaultCursor = 0) => {
  try {
    let option = {
      limit: 10,
      cursor: defaultCursor,
    };
    const publicGroups = await agoraChat.getPublicGroups(option);
    const { cursor, count, data } = publicGroups || {};
    const group =
      strictValidArrayWithLength(data) &&
      // eslint-disable-next-line no-undef
      data.find((val) => val.groupname === user);
    if (group) return group;
    if (parseInt(count) === option.limit) getPublicGroupID(agoraChat, cursor);
    return;
  } catch (error) {
    showErrorToaster(error);
    return;
  }
};

const getDefaultMicrophoneDeviceID = async (AgoraClient) => {
  const microphoneDevices = await AgoraClient.getMicrophones();

  let SelectDevice = {};

  const defaultDevice = microphoneDevices.find((deviceInfo) =>
    deviceInfo.label.toLowerCase().includes('communications'),
  );
  const removeDevice = (deviceInfo) => {
    if (deviceInfo && deviceInfo.label) {
      const isDefaultAbsent = !deviceInfo.label
        .toLowerCase()
        .includes('default');
      const isCommunicationsAbsent = !deviceInfo.label
        .toLowerCase()
        .includes('communications');
      const isVirtualAbsent = !deviceInfo.label
        .toLowerCase()
        .includes('virtual');
      return isDefaultAbsent && isCommunicationsAbsent && isVirtualAbsent;
    }
    return false;
  };

  if (defaultDevice) {
    SelectDevice = microphoneDevices.filter(removeDevice).find((deviceInfo) => {
      return defaultDevice.groupId === deviceInfo.groupId;
    });
  }

  if (!(SelectDevice && SelectDevice.deviceId)) {
    SelectDevice = strictValidArrayWithLength(
      microphoneDevices.filter(removeDevice),
    )
      ? microphoneDevices.filter(removeDevice)[0]
      : '';
  }
  return SelectDevice && SelectDevice.deviceId;
};

export {
  fetchToken,
  createLocalTracks,
  createLocalVideoTrack,
  createLocalAudioTrack,
  loginToAgoraVideo,
  publishTrack,
  getPublicGroupID,
  getHistoryMessages,
  getDefaultMicrophoneDeviceID,
};
