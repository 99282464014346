import { Pagination, styled } from '@mui/material';
import { useState } from 'react';
import { getPreviousRewardCampaigns, setRewardDelivered } from '~api/reward';
import { WrapperBlock } from '~components/manage-profile/components';
import CampaignItem from '~components/manage-profile/tabs/rewards/components/campaign-item';
import CampaignTitle from '~components/manage-profile/tabs/rewards/components/campaign-title';
import { WrapperCampaign } from '~components/manage-profile/tabs/rewards/components/common';
import ConfirmationModal from '~components/organisms/popup/confirmation-modal';
import { RewardCampaign, RewardWinner } from '~types/reward';

const DEFAULT_PAGE_SIZE = 3;

interface PreviousCampaignsProps {
  campaigns: RewardCampaign[];
  total: number;
}

const PreviousCampaigns = (props: PreviousCampaignsProps) => {
  const [page, setPage] = useState(1);
  const [delivered, setDelivered] = useState<{
    campaignId: string;
    orderNumber: number;
  } | null>(null);
  const [paginationCampaigns, setPaginationCampaigns] = useState<{
    [key: number]: RewardCampaign[];
  }>({
    [page]: props.campaigns,
  });

  const loadCampaignsInPage = async (page: number) => {
    const newPaginationCampaigns = {
      ...paginationCampaigns,
    };
    const { data } = await getPreviousRewardCampaigns(
      DEFAULT_PAGE_SIZE,
      (page - 1) * DEFAULT_PAGE_SIZE,
    );
    newPaginationCampaigns[page] = data;
    setPaginationCampaigns(newPaginationCampaigns);

    setPage(page);
  };

  const submitDelivered = async () => {
    const { campaignId, orderNumber } = delivered || {};
    const campaigns = JSON.parse(JSON.stringify(paginationCampaigns[page]));
    const campaign = campaigns.find(
      (item: RewardCampaign) => item.id === campaignId,
    );

    if (campaign) {
      const reward = campaign.rewards.find(
        (item: RewardWinner) => item.orderNumber === orderNumber,
      );
      if (reward) {
        await setRewardDelivered(reward.id);
        reward.deliveredAt = new Date();
        setPaginationCampaigns((prevState) => ({
          ...prevState,
          [page]: campaigns,
        }));
      }
    }
    setDelivered(null);
  };

  return (
    <WrapperBlock title={'Previous Campaigns'}>
      {paginationCampaigns[page].map((campaign, index) => {
        return (
          <WrapperCampaign key={index}>
            <CampaignTitle
              title={`Campaign ${campaign.orderNumber}`}
              startAt={new Date(campaign.startedAt as string)}
              endAt={new Date(campaign.endedAt as string)}
            />
            <CampaignItem
              isActive={false}
              rewards={campaign.rewards}
              markAsDelivered={async (orderNumber) => {
                setDelivered({
                  campaignId: campaign.id as string,
                  orderNumber,
                });
              }}
            />
          </WrapperCampaign>
        );
      })}
      <CustomPagination
        page={page}
        count={Math.ceil(props.total / DEFAULT_PAGE_SIZE)}
        onChange={(_, page) => loadCampaignsInPage(page)}
      />
      <ConfirmationModal
        open={!!delivered}
        onClose={() => setDelivered(null)}
        title={'Are you sure you want to mark as delivered?'}
        confirmAction={submitDelivered}
      />
    </WrapperBlock>
  );
};

export default PreviousCampaigns;

const CustomPagination = styled(Pagination)(({ theme }) => ({
  margin: theme.spacing(2.5),
  justifyContent: 'center',
  display: 'flex',
}));
