import { useState } from 'react';

import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';

// ----------------------------------------------------------------------

const StyledTypography = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'lowercase',
}));

type IProps = TypographyProps & {
  text: string;
  maxWidth?: number | string;
  disabled?: boolean;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
};

const TextEllipsis = (props: IProps) => {
  const {
    text,
    maxWidth = 200,
    disabled = false,
    tooltipProps,
    textTransform = 'lowercase',
    ...rest
  } = props;
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (event.currentTarget.scrollWidth > event.currentTarget.clientWidth) {
      setTooltipEnabled(true);
      return;
    }
    setTooltipEnabled(false);
  };

  const hideTooltip = () => {
    if (tooltipEnabled) {
      setTooltipEnabled(false);
    }
  };

  if (!text) return null;

  return (
    <Tooltip
      onMouseEnter={handleShouldShow}
      onMouseLeave={hideTooltip}
      open={disabled ? false : tooltipEnabled}
      title={text}
      placement="top"
      arrow
      {...tooltipProps}
    >
      <StyledTypography
        color={'text.primary'}
        sx={{
          maxWidth,
          textTransform,
        }}
        {...rest}
      >
        {text}
      </StyledTypography>
    </Tooltip>
  );
};

export default TextEllipsis;
