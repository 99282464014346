import { SearchArtist } from '~types/landing-page';
import {
  alpha,
  Box,
  BoxProps,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  SlideWrapper,
  SlideRatioContainer,
  SlideBorder,
} from '~components/pages/home/styles';
import { ThumbNail } from '~components/pages/home/ThumbNail';
import { getImageURL } from '~utils/awsUtils';

type Props = {
  isDragging: boolean;
} & SearchArtist &
  BoxProps;

export const ItemInSlide = ({
  username,
  name,
  displayUsername,
  imageUrl,
  genre,
  isDragging,
  ...boxProps
}: Props) => {
  const handleClick = () => {
    if (!isDragging) {
      window.location.href = `/${username}`;
    }
  };
  return (
    <SlideWrapper onClick={handleClick} {...boxProps}>
      <SlideBorderCustom>
        <SlideRatioContainer sx={{ paddingTop: '100%' }}>
          <ThumbNail src={getImageURL(imageUrl)} />
        </SlideRatioContainer>
        <SliderContent>
          <Stack width={'100%'} display={'flex'}>
            <Tooltip
              enterDelay={2000}
              title={name || displayUsername || username}
              placement="bottom-start"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            >
              <ArtistName>{name || displayUsername || username}</ArtistName>
            </Tooltip>
            {genre ? (
              <Tooltip
                enterDelay={2000}
                title={genre?.title || ''}
                placement="bottom-start"
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -10],
                        },
                      },
                    ],
                  },
                }}
              >
                <Genre>{genre?.title}</Genre>
              </Tooltip>
            ) : (
              <Genre sx={{ cursor: 'default' }}>&nbsp;</Genre>
            )}
          </Stack>
        </SliderContent>
      </SlideBorderCustom>
    </SlideWrapper>
  );
};

const SliderContent = styled(Box)(() => ({
  background: '#151515',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '4px 16px',
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
}));

const Genre = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.text.primary, 0.6),
  fontSize: '14px !important',
  lineHeight: '15px',
  position: 'relative',
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
}));

const ArtistName = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
  color: theme.palette.action.active,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const SlideBorderCustom = styled(SlideBorder)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '6px 6px',
  },
}));
