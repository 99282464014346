import { MetaSubmitEventRequest } from '~types/meta';
import { submitEvent } from '~api/meta';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export enum MetaPixelEvents {
  PageView = 'ViewContent',
  TappedSignUp = 'Lead',
  Activated = 'CompleteRegistration',
}
export const getPixelMetadata = () => {
  const facebookClickId = Cookies.get('_fbc');
  const facebookBrowserId = Cookies.get('_fbp');

  return {
    facebookClickId,
    facebookBrowserId,
    eventId: uuidv4(),
    eventSourceUrl: window.location.href,
  };
};
export const changeMetaUserInfo = async (
  userInfo: { [key: string]: string } = {},
) => {
  const metaPixelId = process.env.REACT_APP_META_PIXEL_ID;
  if (!metaPixelId) return;
  window.fbq && window.fbq('init', metaPixelId, userInfo);
};

export const pageView = async (customData: { [key: string]: string } = {}) => {
  const metaData = getPixelMetadata();
  window.fbq &&
    window.fbq(
      'track',
      MetaPixelEvents.PageView,
      { ...customData },
      { eventID: metaData.eventId },
    );
  await submitConversionEvent({
    ...metaData,
    ...customData,
    eventName: MetaPixelEvents.PageView,
  });
};

export const startSignUpPixelEvent = async () => {
  const metaData = getPixelMetadata();
  window.fbq &&
    window.fbq(
      'track',
      MetaPixelEvents.TappedSignUp,
      {},
      { eventID: metaData.eventId },
    );
  await submitConversionEvent({
    ...metaData,
    eventName: MetaPixelEvents.TappedSignUp,
  });
};

export const activateAccountSuccessPixelEvent = async () => {
  const metaData = getPixelMetadata();
  window.fbq &&
    window.fbq(
      'track',
      MetaPixelEvents.Activated,
      {},
      { eventID: metaData.eventId },
    );
  await submitConversionEvent({
    ...metaData,
    eventName: MetaPixelEvents.Activated,
  });
};

export const submitConversionEvent = async (data: MetaSubmitEventRequest) => {
  if (process.env.REACT_APP_DISABLE_CONVERSATION_EVENT === 'true') {
    return;
  }
  await submitEvent(data).catch();
};
