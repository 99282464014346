import React from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface PartnershipProgramModalProps {
  onClose: () => void;
  open: boolean;
  onSubmit: () => Promise<void>;
}

const PartnershipProgramWindow = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('lg')]: {
    width: 900,
  },
}));

const SubmitPartnershipProgramModal = ({
  onClose,
  open,
  onSubmit,
}: PartnershipProgramModalProps) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const submit = async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
    onClose();
  };
  return (
    <PartnershipProgramWindow
      open={open}
      onClose={onClose}
      title="Partnership Program"
    >
      <Stack>
        <Content>
          <BigNote>BECOME A PARTNER</BigNote>
          <Note>
            Apply here to become an Ursa Live Partner! ALL Ursa Live Partners
            receive the following benefits:
          </Note>
          <Note>
            • You'll appear on our home page's "Artist Live Board" whenever you
            go live.
          </Note>
          <Note>
            • You'll appear on our home page's Featured Upcoming Events section
            when you schedule a livecast.
          </Note>
        </Content>
        <Stack flexDirection="row" sx={{ margin: 2 }} justifyContent="center">
          <SubmitVideoButton
            variant="text"
            startIcon={
              isSubmitting ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                <SubmitVideoIcon />
              )
            }
            onClick={submit}
            disabled={isSubmitting}
          >
            SUBMIT VIDEO
          </SubmitVideoButton>
        </Stack>
      </Stack>
    </PartnershipProgramWindow>
  );
};

export default SubmitPartnershipProgramModal;

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `2px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
  padding: 16,
  marginTop: 16,
  marginBottom: 16,
}));
const Note = styled(Typography)(() => ({
  marginTop: 16,
  textAlign: 'center',
}));
const BigNote = styled(Note)(() => ({
  fontSize: 20,
}));

const SubmitVideoButton = styled(Button)(() => ({
  fontSize: 16,
  color: 'white',
  fontWeight: 'normal',
  backgroundColor: '#27AE60',
  marginLeft: 8,
  padding: `10px 20px`,
  ':hover': {
    backgroundColor: '#27AE60',
    opacity: 0.7,
  },
}));

const SubmitVideoIcon = styled(CheckCircleIcon)(() => ({
  fontSize: 8,
  color: 'white',
}));
