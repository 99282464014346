import * as React from 'react';
import Popover from '@mui/material/Popover';
import { CardActions, CardContent, styled, Typography } from '@mui/material';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import { ThinCrossSVG } from '~components/icons';

const REWARDS_DO_BEST = [
  'Song Serenade and Thank You Video on Instagram (tagging winner)',
  "Personalized Song - (Share your memories and I'll write you a custom song!)",
  'Signed Merch Bundle (please specify the exact items included, i.e. Vinyl, T-shirt, Stickers. *NOTE we do not recommend CDs)',
  'Gift from YOU! (examples: poetry, pottery, drawing, painting, sculpture, yoga lesson, sound bath journey, etc.)',
  'Brief Virtual Hang w/or without cocktails',
  'Thank You Video Message on Instagram (tagging winner)',
];
const ADDITIONAL_REWARDS_IDEAS = [
  'Personalized Video Messages',
  'Virtual Music or Songwriting Lessons (15-30 min)',
  'Personalized Performance Videos',
  'Merchandise, i.e. vinyl, t-shirts, etc.',
  'Signed Merchandise',
  'Unreleased Song Demos',
  'One-on-one Meet & Greets',
  'Shout outs on social media ',
];

interface TextPopoverProps extends Partial<PopoverOrigin> {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

const HerePopover = ({
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'center' },
}: TextPopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'text-popover' : undefined;

  return (
    <>
      <HereBtn component={'span'} aria-describedby={id} onClick={handleClick}>
        HERE.
      </HereBtn>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        sx={(theme) => ({
          '& > .MuiPopover-paper': {
            width: 'min-content',
            borderRadius: '20px',
            backgroundColor: '#484B54',

            [theme.breakpoints.up('xl')]: {
              minWidth: '398px',
            },
          },
        })}
      >
        <CardContent>
          <TipItem sx={{ opacity: 0.4 }}>These Rewards do best:</TipItem>
          {REWARDS_DO_BEST.map((idea, index) => {
            return <TipItem key={index}>{idea}</TipItem>;
          })}
          <TipItem sx={{ opacity: 0.4 }}>
            Here are some additional Rewards ideas:
          </TipItem>
          {ADDITIONAL_REWARDS_IDEAS.map((idea, index) => {
            return <TipItem key={index}>{idea}</TipItem>;
          })}
          <TipNote>
            *Note: Please bear in mind that you are solely responsible for
            fulfilling your rewards, including any physical rewards that require
            shipping. We will share the email addresses of your winners with you
            after each livecast. It's your responsibility to email them for
            their physical addresses if needed.
          </TipNote>
        </CardContent>
        <CardActions sx={{ justifyContent: 'right' }}>
          <TipCloseBtn component={'span'} onClick={handleClose}>
            Close <ThinCrossSVG width={10} />
          </TipCloseBtn>
        </CardActions>
      </Popover>
    </>
  );
};

export default HerePopover;

const HereBtn = styled(Typography)<{ component: React.ElementType }>(
  ({ theme }) => ({
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.action.active,
    marginLeft: '4px',
  }),
);

const TipItem = styled(Typography)(({ theme }) => ({
  margin: '12px 8px',
  fontSize: theme.typography.subtitle2.fontSize,

  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
}));

const TipNote = styled(Typography)(({ theme }) => ({
  margin: '12px 8px',
  marginBottom: 0,
  opacity: 0.3,
  fontSize: theme.typography.subtitle2.fontSize,
  textAlign: 'justify',

  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
}));

const TipCloseBtn = styled(Typography)<{ component: React.ElementType }>(
  ({ theme }) => ({
    cursor: 'pointer',
    marginRight: '20px',
    marginBottom: '20px',
    fontSize: theme.typography.subtitle2.fontSize,

    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.subtitle2.fontSize,
    },
  }),
);
