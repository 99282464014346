import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
} from 'react';

import ReactConfetti from 'react-confetti';
import styles from './confetti.module.scss';
import { useTriggerTimeout } from '~components/custom-hook';

interface ConfettiProps {
  onConfettiComplete?: () => void;
}

export type ConfettiRefProps = {
  start: () => void;
};

const Confetti: ForwardRefRenderFunction<ConfettiRefProps, ConfettiProps> = (
  { onConfettiComplete },
  ref,
) => {
  const [key, setKey] = React.useState(0);
  const [recycle, setRecycle] = React.useState(false);
  const { startTimeout } = useTriggerTimeout(() => setRecycle(false), 10000);

  const start = useCallback(() => {
    setRecycle(true);
    setKey((prevKey) => prevKey + 1);
    startTimeout();
  }, [startTimeout]);

  useImperativeHandle(
    ref,
    () => {
      return {
        start,
      };
    },
    [start],
  );

  if (key === 0) {
    return <></>;
  }
  return (
    <ReactConfetti
      numberOfPieces={200}
      recycle={recycle}
      key={key}
      onConfettiComplete={onConfettiComplete}
      className={styles.confettiContainer}
    />
  );
};

export default forwardRef(Confetti);
