import { Helmet } from 'react-helmet-async';

type Props = {
  title: string;
  description: string;
  imgUrl: string;
  imgAlt: string;
  url: string;
};

export default function SEO({
  title,
  description,
  url,
  imgUrl,
  imgAlt,
}: Props) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image" content={imgUrl} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:image:alt" content={imgAlt} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:card" content={imgUrl} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imgUrl} />
      {/* End Twitter tags */}
    </Helmet>
  );
}
