import {
  alpha,
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import BasePopup from '../base-popup';
import CloseIcon from '@mui/icons-material/Close';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { updateOnBoardingStep } from '~api/user';
import { ON_BOARDING_INFO_STEP } from '~utils/constants';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import { useNavigate } from 'react-router-dom';

const StyledGettingStartedModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],

  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  },
  [theme.breakpoints.up('sm')]: {
    width: 750,
  },
}));

const StyledCloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: -12,
  top: -12,
}));

const GettingStartedModal = () => {
  const { hidePopup, showNewWelcomeNotificationPopup } = useDispatchPopup();
  const dispatch = useGlobalDispatch();
  const { user } = useGlobalState();
  const navigate = useNavigate();
  const updateOnboarding = async () => {
    try {
      // Reservation wating for feedback:
      // const latestProfile = await getUserInfo();
      // if (
      //   latestProfile.phone &&
      //   latestProfile.genre &&
      //   latestProfile.imageUrl
      // ) {
      //   await updateOnBoardingStep(ON_BOARDING_INFO_STEP.DONE);
      //   dispatch({
      //     type: 'user',
      //     payload: { onBoardingInfoStep: ON_BOARDING_INFO_STEP.DONE },
      //   });
      // }
      await updateOnBoardingStep(ON_BOARDING_INFO_STEP.DONE);
      dispatch({
        type: 'user',
        payload: { onBoardingInfoStep: ON_BOARDING_INFO_STEP.DONE },
      });
    } catch (e) {
      // Do nothing;
    }
  };
  const onCloseModal = () => {
    hidePopup();
    updateOnboarding();
    showNewWelcomeNotificationPopup();
  };
  const onNavigateAddLiveCast = () => {
    updateOnboarding();
    hidePopup();
    setTimeout(() => {
      navigate(`/${user.username}`);
      showNewWelcomeNotificationPopup();
    }, 500);
  };
  return (
    <StyledGettingStartedModal
      onClose={hidePopup}
      closeBtn={
        <StyledCloseButton onClick={onCloseModal}>
          <CloseIcon
            sx={{
              color: 'white',
            }}
          />
        </StyledCloseButton>
      }
      open={true}
    >
      <Stack paddingX={5} spacing={3} alignItems={'center'}>
        <PopupTitle textTransform={'uppercase'}>
          GETTING STARTED ON URSA LIVE
        </PopupTitle>
        <SubTitle>As an Artist on Ursa Live, you can..</SubTitle>
        <Stack alignItems={'left'} spacing={1}>
          <Stack direction={'row'} spacing={2}>
            <TickBox>✓</TickBox>
            <Description>
              Go live whenever you want, from wherever you want 🌎
            </Description>
          </Stack>
          <Stack direction={'row'} spacing={2}>
            <TickBox>✓</TickBox>
            <Description>
              Earn unlimited tips from Fans during each livestream-just remember
              to ADD your livecast, promote it on your social accounts, and
              invite your friends and family for the best results! 🤑
            </Description>
          </Stack>
          <Stack direction={'row'} spacing={2}>
            <TickBox>✓</TickBox>
            <Stack direction={'row'}>
              <Description>
                Easily set up your audio using this{' '}
                <GradientText
                  href="https://ursalive.notion.site/Audio-Setup-Guides-0654811c239d4b2094dc770047c06912?pvs=4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  guide
                </GradientText>{' '}
                or book complimentary session with a producer{' '}
                <GradientText
                  href="https://calendly.com/ursa-producers/ursa-live-stream-setup"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </GradientText>{' '}
                🎙️
              </Description>
            </Stack>
          </Stack>
        </Stack>
        <Button
          sx={{ height: 50 }}
          fullWidth
          type="submit"
          color="warning"
          variant="contained"
          onClick={onNavigateAddLiveCast}
        >
          Add a livecast
        </Button>
      </Stack>
    </StyledGettingStartedModal>
  );
};

export default GettingStartedModal;

const TickBox = styled(Box)(() => {
  const tickSize = 25;
  return {
    background: 'linear-gradient(180deg, #CE7CAC 0%, #7473B6 100%)',
    textAlign: 'center',
    width: tickSize,
    height: tickSize,
    maxWidth: tickSize,
    maxHeight: tickSize,
    minWidth: tickSize,
    minHeight: tickSize,
    borderRadius: 5,
    color: 'white',
    fontWeight: 400,
    fontFamily: 'Helvetica Neue',
  };
});

const Description = styled(Typography)(({ theme }) => ({
  color: theme.typography.subtitle2.color,
  fontSize: theme.typography.subtitle2.fontSize,
  font: theme.typography.subtitle2.font,
  fontWeight: 400,
  display: 'inline',
}));

const PopupTitle = styled(Typography)(({ theme }) => ({
  color: theme.typography.h2.color,
  font: theme.typography.h2.font,
  fontSize: 13,
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h2.fontSize,
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.typography.h4.color,
  font: theme.typography.h4.font,
  fontWeight: 600,
  fontSize: 13,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h4.fontSize,
  },
}));

const GradientText = styled(Link)(({ theme }) => ({
  fontSize: `${theme.typography.subtitle2.fontSize} px !important`,
  background: 'linear-gradient(180deg, #CE7CAC 0%, #7473B6 100%)',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  color: alpha(theme.palette.text.primary, 0.9),
  fontWeight: 600,
  display: 'inline',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: -2,
    height: '1px',
    backgroundColor: '#CE7CAC',
  },
}));
