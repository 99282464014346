export const messages = {
  // Validations
  VALIDATIONS: {
    INVALID_EMAIL_ADDRESS: 'Please provide a valid email address',
    EMAIL_ADDRESS_NOT_VALID: 'Your email address is not valid',
    EMAIL_ALREADY_EXISTS: 'Email address is already taken',
    EMAIL_REQUIRED_FOR_FORGOT_PASSWORD:
      'Please enter your registered email address',
    INVALID_PHONE_NUMBER: 'Please enter a valid phone number',
    INVALID_MOBILE_NUMBER: 'Please enter a valid mobile number',
    INVALID_PASSWORD:
      'Please enter your password as per the password requirements',
    INVALID_CONFIRM_PASSWORD: 'Password and confirm password does not match',
    INVALID_CONFIRM_PASSWORD_NOT_MATCH:
      'The passwords you entered do not match',
    INVALID_ZIP_CODE: 'Please enter a valid zip code',
    INVALID_FIRST_NAME: 'Please enter first name',
    INVALID_NAME: 'Please enter name',
    STRICT_VALID_NAME: 'Please enter a valid name',
    INVALID_LAST_NAME: 'Please enter last name',
    INVALID_VALUE: 'Please enter valid value',
    INVALID_USERNAME:
      'Only letters (a-z or A-Z), Numbers (0-9), Dots (.), Underscores (_) allowed',
  },
};
