import { postRequest, getRequest, Response } from '~utils';
import {
  Transaction,
  TransactionHistoryResponse,
  TransactionSendResponse,
  TransactionTipResponse,
} from '~types/transaction';
import { PaymentType, PaymentMethod } from '~types';
import { EventTipResponse } from '~types/event';

export const purchaseLivecoin = async (
  amount: number,
  referenceId: string,
  paymentType: PaymentType,
  liveCoinProduct: string,
  paymentMethod: PaymentMethod,
  cardId?: string,
): Promise<Response<Transaction>> => {
  return await postRequest({
    path: 'transactions/purchase-coins',
    data: {
      amount,
      reference_id: referenceId,
      payment_gateway: paymentType,
      live_coin_product: liveCoinProduct,
      card_id: cardId,
      payment_method: paymentMethod,
    },
  });
};

export const tipsForArtist = async (
  amount: number,
  artistId: string,
  eventId = '',
  chatGroupId?: string,
): Promise<Response<TransactionSendResponse>> => {
  return await postRequest({
    path: 'transactions/tip',
    data: {
      amount,
      artist_id: artistId,
      event_id: eventId,
      chatGroupId: chatGroupId,
    },
  });
};

export const getTipsList = async (
  artistId: string,
  eventId: string,
): Promise<TransactionTipResponse> => {
  const params = eventId
    ? {
        event_id: eventId,
        artist_id: artistId,
      }
    : {
        artist_id: artistId,
      };
  return await getRequest({
    path: 'transactions/tips',
    config: {
      params,
    },
  });
};

export const getTipsHistory = async (
  artistId: string,
): Promise<Response<TransactionHistoryResponse>> => {
  return await getRequest({
    path: 'transactions/history',
    config: {
      params: { artist_id: artistId },
    },
  });
};

export const checkTipInEvent = async (
  eventId: string,
  tipperId: string,
): Promise<EventTipResponse> => {
  const params = {
    event_id: eventId,
    tipper_id: tipperId,
  };
  return await getRequest({
    path: 'transactions/tipper',
    config: {
      params,
    },
  });
};

export const purchaseLivecoinWithTax = async (
  amount: number,
  referenceId: string,
  paymentType: PaymentType,
  liveCoinProduct: string,
  paymentMethod: PaymentMethod,
  cardId?: string,
  tax?: string,
): Promise<Response<Transaction>> => {
  return await postRequest({
    path: 'transactions/purchase-coins',
    data: {
      amount,
      reference_id: referenceId,
      payment_gateway: paymentType,
      live_coin_product: liveCoinProduct,
      card_id: cardId,
      payment_method: paymentMethod,
      tax_amount: tax,
    },
  });
};

export const getPurchaseHistory = async (
  fanId: string,
  limit?: number,
): Promise<Response<TransactionHistoryResponse>> => {
  return await getRequest({
    path: 'transactions/purchase-history',
    config: {
      params: {
        fanId: fanId,
        limit: limit,
      },
    },
  });
};
