import React from 'react';
import { styled, Box, Stack, alpha } from '@mui/material';
import { MicrophoneSliderImage } from '~images';
import { Button } from '~components/atoms';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';

interface Props {
  title: React.ReactNode;
  subTitle: React.ReactNode;
  showSignUpButton?: boolean;
}

const ArtistEventHeader = ({
  title,
  subTitle,
  showSignUpButton = false,
}: Props) => {
  const { showSignUpPopup } = useDispatchPopup();
  return (
    <Stack
      sx={{ background: 'black' }}
      direction={'column'}
      alignItems={'center'}
    >
      <Wrapper>
        <SlidePicture src={MicrophoneSliderImage} alt="Microphone" />
        <Texts>
          {title}
          {subTitle}
          {showSignUpButton && (
            <CustomStack
              flex="1"
              alignItems="start"
              justifyContent="start"
              padding={1}
            >
              <CustomButton onClick={showSignUpPopup}>SIGN UP NOW</CustomButton>
            </CustomStack>
          )}
        </Texts>
      </Wrapper>
    </Stack>
  );
};

export default ArtistEventHeader;

const CustomStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 222,
  width: '100%',
  img: {
    padding: 0,
  },
  [theme.breakpoints.up('md')]: {
    maxHeight: '250px',
    img: {
      padding: 0,
    },
  },
}));

const Texts = styled(Stack)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: alpha(theme.palette.primary.dark, 0.73),
  position: 'absolute',
  left: 0,
  top: 0,
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    padding: '20px 80px',
  },
}));

const SlidePicture = styled('img')(() => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
}));

const CustomButton = styled(Button)(({ theme }) => ({
  background: theme.palette.action.active,
  color: theme.palette.text.secondary,
  fontWeight: 700,
  textTransform: 'uppercase',
  zIndex: 2,
}));
