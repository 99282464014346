import React from 'react';

import { styled, Radio as RadioMUI, RadioProps } from '@mui/material';

const Radio = (props: RadioProps) => {
  return <CustomRadio checked={false} {...props} />;
};

export default Radio;

const CustomRadio = styled(RadioMUI)<RadioProps>(({ theme }) => ({
  height: 15,
  width: 15,
  color: theme.palette.text.disabled,
  '&.Mui-checked': {
    color: theme.palette.action.active,
  },
}));
