import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';
//
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { IAgoraRTCClient, IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
// @mui
import { Box, Stack, Typography, alpha, styled } from '@mui/material';
// hooks
import useAddAvatar from '~hooks/useAddAvatar';
// utils
import {
  useGlobalState,
  useGlobalDispatch,
  AgoraVideoContext,
} from '~utils/container';
import {
  FEATURE_FLAGS_TYPE,
  USER_TYPE,
  strictValidArrayWithLength,
} from '~utils';
import { showErrorToaster } from '~utils/toasterNotification';
import { strictValidArray } from '~utils';
import { fetchChatUserDetail } from '~utils/chatUtils';
// api
import { blockUser } from '~api/artist';
import { blockFan } from '~api/event';
import { blockChat } from '~api/chats';
import { BringToStageContext } from '~providers/BringToStageProvider';
// components
import { MoreMenu } from '~components/molecules';
import { ConfirmationModal } from '~components/organisms';
// types
import { ChatRetrieveActivityResponse } from '~types/chats';
//
import FanName from './fan-name';
import { useFeatureFlag } from '~hooks/useFeatureFlag';

const StyledRoot = styled(Stack)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  height: 142,
  maxWidth: 262,
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.primary.light,
}));

const StyledFanVideo = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  '& > img, video': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    verticalAlign: 'middle',
    borderRadius: theme.spacing(1.5),
  },
}));

const OnStageLabel = () => (
  <Stack
    position={'absolute'}
    top={0}
    right={0}
    left={0}
    bottom={0}
    alignItems={'center'}
    justifyContent={'center'}
    sx={{
      backgroundColor: (theme) => theme.palette.primary.dark,
    }}
  >
    <Typography>ON STAGE</Typography>
  </Stack>
);

type Props = {
  fan: IAgoraRTCRemoteUser;
};

const FanCarouselItem = ({ fan }: Props) => {
  const { hasAudio, uid, videoTrack } = fan;

  const { isEnable } = useFeatureFlag();

  const isEnableBlockUser = isEnable(FEATURE_FLAGS_TYPE.BLOCKED_USER);

  const client = useQueryClient();

  const dispatch = useGlobalDispatch();

  const agoraVideo = useContext(AgoraVideoContext);

  const container = useRef(null);

  const [fanData, setFanData] = useState<
    ChatRetrieveActivityResponse | Record<string, any>
  >({});
  const { updateAvatar } = useAddAvatar();
  const {
    user: { type, username, id: userId },
    config: { chatGroupId, remoteUsers, localAudioTrack } = {},
    artist: { currentEvent, id: artist_id } = {},
  }: // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { user: any; config: any; artist: any } = useGlobalState();

  const { bringToStage, removeFromStage, isAllowBringToStage } =
    useContext(BringToStageContext);

  const [isAnyFanOnStage, setIsAnyFanOnStage] = useState(false);

  const [isFanOnStage, setFanOnStage] = useState(false);

  const [fistChecking, setFistChecking] = useState(false);

  const [openBlockConfirmationModal, setOpenBlockConfirmationModal] =
    useState(false);

  const { mutate: addUserToBlockListMutate } = useMutation({
    mutationFn: (id: string) => blockUser(id),
    onSuccess: () => {
      client.refetchQueries({
        queryKey: [QUERY_KEYS.ARTIST_BLOCKED_USERS_LIST, userId],
        type: 'active',
      });
    },
  });

  const checkIfFanOnStage = useCallback(() => {
    let onStage = false;
    if ((fan && hasAudio) || (uid === username && localAudioTrack)) {
      onStage = true;
    }
    if (onStage) {
      dispatch({
        type: 'config',
        payload: {
          isFanOnStage: true,
        },
      });
    }
    setFanOnStage(onStage);
    setFistChecking(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fan, hasAudio, localAudioTrack, uid, username]);

  const getFanDetails = useCallback(
    async (fan_uid: string, chatGroupId: string) => {
      const res = await fetchChatUserDetail(fan_uid, chatGroupId);
      const { imageUrl } = res as { imageUrl: string };
      await updateAvatar(fan_uid, imageUrl);
      if (res) setFanData(res as ChatRetrieveActivityResponse);
    },
    [updateAvatar],
  );

  const handleBlock = useCallback(async () => {
    try {
      const userVideo =
        strictValidArrayWithLength(remoteUsers) &&
        remoteUsers.find(
          ({ uid }: { uid: string }) => uid === fanData.username,
        );

      if (userVideo) {
        await (agoraVideo as IAgoraRTCClient).unsubscribe(userVideo);
      }
      if (isEnableBlockUser) {
        addUserToBlockListMutate(fanData.userId);
      } else {
        await blockFan(userId, currentEvent);
        await blockChat(username, chatGroupId);
      }
    } catch (error) {
      showErrorToaster(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteUsers, fanData.userId, fanData.username, agoraVideo]);

  const checkIfAnyFanOnStage = useCallback(() => {
    let isAnyOnStage = false;
    if (strictValidArray(remoteUsers)) {
      isAnyOnStage = remoteUsers.some(
        (user: { audioTrack: boolean }) => user.audioTrack,
      );
    }
    setIsAnyFanOnStage(isAnyOnStage);
  }, [remoteUsers]);

  const handleRemoveFromStage = () => {
    removeFromStage(uid as string, currentEvent, artist_id);
  };

  const handleBringToStage = () => {
    bringToStage(uid as string, currentEvent, fanData.userId, artist_id);
  };

  useEffect(() => {
    if (uid && chatGroupId) getFanDetails(uid as string, chatGroupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, chatGroupId]);

  useEffect(() => {
    checkIfAnyFanOnStage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIfAnyFanOnStage]);

  useEffect(() => {
    checkIfFanOnStage();
  }, [checkIfFanOnStage]);

  useEffect(() => {
    if (!container.current) return;
    if (isFanOnStage) return;
    if (!fistChecking) return;
    videoTrack?.play(container.current, {
      mirror: true,
    });
    return () => {
      videoTrack?.stop();
    };
  }, [container, fistChecking, isFanOnStage, videoTrack]);

  return (
    <StyledRoot>
      <StyledFanVideo>
        <div ref={container} style={{ width: '100%', height: '100%' }} />
      </StyledFanVideo>

      {isFanOnStage && <OnStageLabel />}
      <Stack
        width={'100%'}
        position={'absolute'}
        left={0}
        bottom={0}
        direction={'row'}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
        pl={1.5}
        pr={1}
        pb={0.75}
      >
        <FanName
          type={type}
          name={String(uid) || String(fanData?.displayUsername)}
          allowOnStage={Boolean(fanData?.allowOnStage)}
        />
        <Box>
          <MoreMenu
            hidden={type === USER_TYPE.FAN}
            sx={{
              backgroundColor: alpha('#000', 0.5),
              '&:active, &.Mui-focusVisible': alpha('#000', 0.5),
              '&:hover': {
                background: alpha('#000', 0.3),
              },
            }}
            options={[
              {
                label: 'Bring to stage',
                onClick: handleBringToStage,
                visible: fanData?.allowOnStage && !isAnyFanOnStage,
                disabled: !isAllowBringToStage,
              },
              {
                label: 'Remove from stage',
                onClick: handleRemoveFromStage,
                visible: isFanOnStage,
              },
              {
                label: 'Block',
                onClick: () => {
                  isEnableBlockUser
                    ? setOpenBlockConfirmationModal(true)
                    : handleBlock;
                },
              },
            ]}
          />
        </Box>
        <ConfirmationModal
          open={openBlockConfirmationModal}
          onClose={() => setOpenBlockConfirmationModal(false)}
          title={'Are you sure you want to block this user?'}
          confirmAction={handleBlock}
        />
      </Stack>
    </StyledRoot>
  );
};

export default FanCarouselItem;
