import React, { useState, useCallback } from 'react';

import Cropper from 'react-easy-crop';
import canvasSize from 'canvas-size';
import { useGlobalState, useGlobalDispatch } from '~utils/container';
import { showErrorToaster } from '~utils/toasterNotification';
import { strictValidString } from '~utils/commonUtils';
import { getImageURL, uploadImage } from '~utils/awsUtils';
import { updateAvatar } from '~api/user';
import { useDispatch } from 'react-redux';
import { POPUP_TYPE, USER_TYPE } from '~utils';
import {
  Box,
  Button,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import BasePopup from '~components/organisms/popup/base-popup';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const initialState = {
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 1,
  rotation: 0,
  imageSrc: '',
  fileName: '',
};

const UploadPopup = () => {
  const [state, setState] = useState(initialState);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [initialCroppedAreaPixels] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const { crop, zoom, aspect, rotation, imageSrc, fileName } = state;
  const globalState = useGlobalState();
  const rematchDispatch = useDispatch();
  const {
    app: { popup },
    user = {},
  } = globalState;
  const { imageUrl, username, firstLogin, showLiveCoinPopup, type } = user;
  const dispatch = useGlobalDispatch();

  const onCropChange = (value) => {
    setState((prevState) => ({ ...prevState, crop: value }));
  };

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onZoomChange = (value) => {
    setState((prevState) => ({ ...prevState, zoom: value }));
  };

  const handleChangeFile = (event) => {
    event.stopPropagation() && event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      const [file] = event.target.files;
      let reader = new FileReader();
      reader.onload = (e) => {
        setState((prevState) => ({
          ...prevState,
          imageSrc: e.target.result,
          crop: { x: 0, y: 0 },
          fileName: file.name,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'same-origin'); // need
      image.src = url;
    });

  const getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
  };

  const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await createImage(imageSrc);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    let safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    let maxHeight = null;

    if (window.innerWidth <= 900) {
      maxHeight = 4096;
    } else {
      canvasSize.maxArea({
        onError: () => console.log('Error on Canvas Test'),
        onSuccess(_width, height) {
          maxHeight = height;
        },
      });
    }

    if (maxHeight > 0 && safeArea > maxHeight) {
      safeArea *= maxHeight / safeArea;
    }

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        if (!file) {
          reject(new Error('Canvas is empty'));
          return;
        }
        file.name = fileName;
        file.lastModifiedDate = new Date();
        resolve(file);
      }, 'image/jpeg');
    });
  };

  const navigateToNextPopup = () => {
    let nextPopup = popup === 'edit-profile' ? '' : 'log-in';
    if (showLiveCoinPopup && type === USER_TYPE.FAN) {
      nextPopup = POPUP_TYPE.WELCOME_POPUP;
    }

    dispatch({
      type: 'app',
      payload: { popup: nextPopup },
    });
  };

  const onSaveChanges = async () => {
    setIsSubmitting(true);
    try {
      const blobImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
      );

      let imageUrl;
      try {
        imageUrl = await uploadImage(blobImage);
      } catch (err) {
        setIsSubmitting(false);
        console.log('err upload file', err);
        const ee = strictValidString(err) ? err : 'Error in uploading file';
        showErrorToaster(ee);
        return;
      }

      await updateAvatar(imageUrl);
      rematchDispatch.common.addAvatars({ [username]: imageUrl });
      setSuccessMsg('Success');

      if (popup === 'edit-profile') {
        navigateToNextPopup();
        dispatch({
          type: 'user',
          payload: { imageUrl },
        });
      } else {
        setTimeout(() => {
          dispatch({ type: 'app', payload: { popup: 'log-in' } });
        }, 3000);
      }
    } catch (error) {
      setError(error);
      setIsSubmitting(false);
    }
  };

  return (
    <UploadPopupWindow
      open={true}
      hideCloseButton={true}
      title={popup === 'edit-profile' && !firstLogin ? 'Edit' : 'Add a'}
      subTitle="profile photo"
    >
      {!!error && <ErrorBox>{error}</ErrorBox>}
      {!error && !!successMsg && <SuccessBox>{successMsg}</SuccessBox>}
      <Stack
        sx={{
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        {imageSrc ? (
          <Box
            sx={{
              width: '100%',
              height: '120px',
              position: 'relative',
            }}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={aspect}
              cropShape="round"
              showGrid
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
              {...initialCroppedAreaPixels}
            />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                maxWidth: '120px',
                maxHeight: '120px',
                width: '120px',
                height: '120px',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Label htmlFor="avtar">
                <Input
                  accept="image/*"
                  id="avtar"
                  type="file"
                  onChange={handleChangeFile}
                />
                {imageUrl ? (
                  <>
                    <img
                      src={getImageURL(imageUrl)}
                      width="100%"
                      height="100%"
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 5,
                        right: 5,
                        width: 30,
                        height: 30,
                      }}
                    >
                      <IconButton
                        component="span"
                        sx={{
                          color: 'hsla(0,0%,100%,.5)',
                          borderRadius: '50%',
                          border: '1px solid hsla(0,0%,100%,.5)',
                          padding: '5px',
                        }}
                      >
                        <ModeEditIcon sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <Stack
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      backgroundColor: 'rgb(72, 72, 72)',
                      color: 'rgb(128, 128, 128)',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CameraAltIcon sx={{ fontSize: 18 }} />
                    <UploadText>Upload a picture</UploadText>
                    <UploadText>(optional)</UploadText>
                  </Stack>
                )}
              </Label>
            </Box>
          </>
        )}
        <Box sx={{ width: '100%', marginTop: '10px' }}>
          {firstLogin ? (
            <Stack direction="row" spacing={1} justifyContent="center">
              <Button
                size="large"
                color="warning"
                onClick={navigateToNextPopup}
                variant="contained"
              >
                Skip
              </Button>
              <Button
                variant="contained"
                size="large"
                color="warning"
                disabled={
                  isSubmitting ||
                  !strictValidString(fileName) ||
                  !strictValidString(imageSrc)
                }
                onClick={onSaveChanges}
              >
                Submit
              </Button>
            </Stack>
          ) : (
            <Stack>
              <Stack direction="row" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="warning"
                  disabled={
                    isSubmitting ||
                    !strictValidString(fileName) ||
                    !strictValidString(imageSrc)
                  }
                  onClick={onSaveChanges}
                >
                  Submit
                </Button>
              </Stack>
              <Stack direction="row" justifyContent="start">
                <Button
                  type="text"
                  onClick={navigateToNextPopup}
                  sx={{
                    color: 'white',
                    fontSize: '10px',
                    textDecoration: 'underline',
                  }}
                >
                  close
                </Button>
              </Stack>
            </Stack>
          )}
        </Box>
      </Stack>
    </UploadPopupWindow>
  );
};

export default UploadPopup;

const UploadPopupWindow = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  [theme.breakpoints.up('xs')]: {
    width: '90%',
    height: 'fit-content',
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(4),
    width: 620,
  },
}));

const ErrorBox = styled(Typography)(() => ({
  color: 'red',
}));

const SuccessBox = styled(Typography)(() => ({
  color: '#56BA3C',
}));

const Input = styled('input')({
  display: 'none',
});

const UploadText = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  color: 'rgb(128, 128, 128)',
  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 10,
  },
}));

const Label = styled('label')(() => ({
  width: '100%',
  height: '100%',
  '&:hover': {
    cursor: 'pointer',
  },
}));
