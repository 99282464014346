export const SLIDES_IN_ROW_IN_RESPONSE = {
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 3,
};
export const AMOUNT_ROWS = 4;

export const ENHANCED_SLIDES_IN_ROW_IN_RESPONSE = {
  xs: 2,
  sm: 4,
  md: 4,
  lg: 6,
  xl: 6,
};
export const ENHANCED_AMOUNT_ROWS = 3;

export const NEW_SLIDES_IN_ROW_IN_RESPONSE = {
  xs: 2,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 3,
};
export const NEW_AMOUNT_ROWS = 3;

export const RESPONSIVE = [
  {
    breakpoint: 576,
    settings: {
      arrows: false,
      slidesPerRow: SLIDES_IN_ROW_IN_RESPONSE.xs,
    },
  },
  {
    breakpoint: 768,
    settings: {
      arrows: false,
      slidesPerRow: SLIDES_IN_ROW_IN_RESPONSE.md,
    },
  },
  {
    breakpoint: 992,
    settings: {
      dots: true,
      slidesPerRow: SLIDES_IN_ROW_IN_RESPONSE.md,
    },
  },
];
