import React, { useMemo } from 'react';
import { Box, alpha, styled } from '@mui/material';
import { InternalIndentBlock } from '~components//manage-profile/components';
import Line from '~components/manage-profile/tabs/reports/line';
import { ReportView } from '~types/event';

interface ReportProps extends Omit<ReportView, 'id'> {
  showBottomLine?: boolean;
}

const SECONDS_PER_MINUTE = 1000 * 60;
const MILLISECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;

const Report = ({
  startedAt,
  endedAt,
  tipEarned,
  totalAttendees,
  totalFollowers,
  showBottomLine = true,
}: ReportProps) => {
  const formatTimeDifference = useMemo(() => {
    const startTime = new Date(startedAt);
    const endTime = new Date(endedAt);

    const diffInMilliseconds = Math.abs(
      endTime.getTime() - startTime.getTime(),
    );

    const hours = Math.floor(diffInMilliseconds / MILLISECONDS_PER_HOUR);
    const minutes = Math.floor(
      (diffInMilliseconds % MILLISECONDS_PER_HOUR) / SECONDS_PER_MINUTE,
    );
    const seconds = Math.floor(
      (diffInMilliseconds % SECONDS_PER_MINUTE) / 1000,
    );

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return '00:00:00';
    }
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }, [startedAt, endedAt]);

  return (
    <Container showBottomLine={showBottomLine}>
      <WrapperLines>
        <Line
          title={'Session Length'}
          value={formatTimeDifference}
          measurement={''}
        />
        <Line
          title={'Tips earned'}
          value={`${tipEarned} lc (or $${tipEarned / 10})`}
          measurement={''}
        />
        <Line
          title={'Total Attendees'}
          value={totalAttendees}
          measurement={''}
        />
        <Line
          title={'Total Followers'}
          caption="(at completion of livecast)"
          value={totalFollowers ?? 'N/A'}
          measurement={''}
          hideBorder={true}
        />
      </WrapperLines>
    </Container>
  );
};

export default Report;

type ContainerProps = {
  showBottomLine?: boolean;
};
const Container = styled(InternalIndentBlock)<ContainerProps>(
  ({ theme, showBottomLine }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderBottom: showBottomLine
      ? `1px solid ${theme.palette.secondary.light}`
      : '0px',
    padding: 20,
  }),
);

const WrapperLines = styled(Box)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
  borderRadius: theme.spacing(1),
}));
