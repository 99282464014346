import React, { useState } from 'react';

import { Button, Stack, Typography, styled } from '@mui/material';

import { TipIcon } from '~components/icons';
import BasePopup from '~components/organisms/popup/base-popup';
import EndLiveAvatar from '../end-live-avatar';
import EndLiveArtistName from '../end-live-title';
import { TextEllipsis } from '~components/atoms';
import { TipForm } from '~components/molecules';
import useAccount from '~hooks/useAccount';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 472,
  },
}));

interface Props {
  artistName: string;
  artistUserName: string;
  open: boolean;
  onClose: () => void;
  onNext: () => void;
}

const enum TipEndLiveStep {
  WELCOME,
  TIP,
  THANK,
}

const TipEndLiveModal = ({
  artistName,
  artistUserName,
  open,
  onNext,
  onClose,
}: Props) => {
  const [step, setStep] = useState<TipEndLiveStep>(TipEndLiveStep.WELCOME);
  const { isLogin } = useAccount();
  const { showLoginPopup } = useDispatchPopup();

  const goToTip = () => {
    if (!isLogin) {
      onClose();
      showLoginPopup();
      return;
    }
    setStep(TipEndLiveStep.TIP);
  };
  const goToThank = () => {
    setStep(TipEndLiveStep.THANK);
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      closeBtn={true}
      keepMounted={false}
    >
      <Stack spacing={3}>
        <EndLiveArtistName
          title={
            step === TipEndLiveStep.THANK ? 'THANK YOU FOR SUPPORTING' : 'TIP'
          }
          direction={step === TipEndLiveStep.THANK ? 'column' : 'row'}
          success={step === TipEndLiveStep.THANK}
          text={artistName}
        />
        {step !== TipEndLiveStep.TIP && (
          <Stack flex={1} alignItems="center">
            <EndLiveAvatar username={artistUserName} />
          </Stack>
        )}
        {step === TipEndLiveStep.WELCOME && (
          <>
            <Stack flex={1} alignItems="center">
              <Button
                variant="outlined"
                color="warning"
                onClick={goToTip}
                sx={{ flexGrow: '0', width: '60%' }}
                startIcon={<TipIcon />}
                size="small"
              >
                TIP
              </Button>
            </Stack>
            <Stack
              direction={'row'}
              mt={2}
              spacing={0.5}
              justifyContent={'center'}
            >
              <Typography component={'span'}>Show</Typography>
              <TextEllipsis color={'warning.main'} text={artistName} />
              <Typography component={'span'}>some support</Typography>
            </Stack>

            <Button
              variant="contained"
              color="warning"
              onClick={onNext}
              fullWidth
            >
              NEXT
            </Button>
          </>
        )}

        {step === TipEndLiveStep.TIP && (
          <Stack flex={1} spacing={2}>
            <TipForm
              submitBtnText="TIP"
              submitBtnProps={{ fullWidth: true, sx: { minHeight: 32 } }}
              onSuccess={() => {
                goToThank();
              }}
              showTipPopupAfterBuyLiveCoin={false}
            />
            <Button
              variant="outlined"
              color="warning"
              onClick={onNext}
              fullWidth
            >
              NEXT
            </Button>
          </Stack>
        )}
        {step === TipEndLiveStep.THANK && (
          <Button
            variant="contained"
            color="warning"
            onClick={onNext}
            fullWidth
          >
            NEXT
          </Button>
        )}
      </Stack>
    </StyledModal>
  );
};

export default TipEndLiveModal;
