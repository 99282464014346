import { postRequest, Response } from '~utils/clientUtils';
import { ReactionResponse } from '~types/reactions';
import { EmojiType } from '~components/molecules/emoji-reaction';

export const emitEmojiReaction = async (
  eventId: string,
  type: EmojiType,
): Promise<Response<ReactionResponse>> => {
  return await postRequest({
    path: 'reactions/send',
    data: {
      event_id: eventId,
      type,
    },
  });
};
