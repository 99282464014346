import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  styled,
  AccordionDetails,
  Accordion,
  AccordionSummaryProps,
  AccordionSummary,
} from '@mui/material';
import { WrapperBlock } from '../../../components';
import { UnionPayoutMethods, PayoutMethods } from './types';
import {
  Radio,
  NotificationModalWindow,
  ErrorModalWindow,
} from '~components/atoms';
import BankAccount, { BankAccountRefProps } from './bank-account';
import Venmo, { VenmoRefProps } from './venmo';
import PayPal, { PayPalRefProps } from './pay-pal';
import { PayoutInfoResponse } from '~types/payment';
import MethodLabel from '~components/manage-profile/tabs/payout/payout-method/method-label';

const relationBtwTypesAndLabel = {
  [PayoutMethods.STRIPE]: 'Bank Account',
  [PayoutMethods.PAYPAL]: 'PayPal',
  [PayoutMethods.VENMO]: 'Venmo',
};

interface PayoutMethodProps {
  data: PayoutInfoResponse['metadata'] | undefined;
  changePayoutId: (id: string | null) => void;
  activeMethod: UnionPayoutMethods;
  changePayoutMethod: (method: UnionPayoutMethods) => void;
  changeStatusIsDataSaved: (status: boolean) => void;
}

export type PayoutMethodRefProps = {
  save: () => void;
};

export type ModalWindowTypes = 'success' | 'error' | null;

const PayoutMethod: ForwardRefRenderFunction<
  PayoutMethodRefProps,
  PayoutMethodProps
> = (
  {
    data,
    changePayoutId,
    activeMethod,
    changePayoutMethod,
    changeStatusIsDataSaved,
  },
  ref,
) => {
  const [modalWindowTypes, setModalWindowTypes] =
    useState<ModalWindowTypes>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const bankAccountRef = useRef<BankAccountRefProps>(null);
  const payPalRef = useRef<PayPalRefProps>(null);
  const venmoRef = useRef<VenmoRefProps>(null);

  const handleChange = (payout: UnionPayoutMethods) => () => {
    setErrorMessage('');
    changePayoutMethod(payout);
    if (!data || payout === false) return;
    switch (payout) {
      case PayoutMethods.STRIPE:
        changePayoutId(data.stripe?.payoutId ?? null);
        break;
      case PayoutMethods.PAYPAL:
        changePayoutId(data?.paypal?.email?.payoutId ?? null);
        break;
      case PayoutMethods.VENMO:
        changePayoutId(data?.venmo?.email?.payoutId ?? null);
        break;
    }
  };
  const save = useCallback(() => {
    if (!activeMethod) {
      setErrorMessage('Please select any payout method to finish');
    } else {
      setErrorMessage('');
      switch (activeMethod) {
        case PayoutMethods.STRIPE:
          bankAccountRef?.current?.save && bankAccountRef?.current?.save();
          break;
        case PayoutMethods.PAYPAL:
          payPalRef?.current?.save && payPalRef?.current?.save();
          break;
        case PayoutMethods.VENMO:
          venmoRef?.current?.save && venmoRef?.current?.save();
          break;
      }
    }
  }, [activeMethod]);

  useImperativeHandle(
    ref,
    () => {
      return {
        save: save,
      };
    },
    [save],
  );

  const toggleModalWindow = (type: ModalWindowTypes) =>
    setModalWindowTypes(type);

  const accordions = useMemo(
    () => [
      {
        payoutType: PayoutMethods.STRIPE,
        content: (
          <BankAccount
            ref={bankAccountRef}
            data={data?.stripe}
            openModalWindow={toggleModalWindow}
            changePayoutId={changePayoutId}
            changeStatusIsDataSaved={changeStatusIsDataSaved}
          />
        ),
      },
      {
        payoutType: PayoutMethods.PAYPAL,
        content: (
          <PayPal
            ref={payPalRef}
            data={data?.paypal}
            openModalWindow={toggleModalWindow}
            changePayoutId={changePayoutId}
            changeStatusIsDataSaved={changeStatusIsDataSaved}
          />
        ),
      },
      {
        payoutType: PayoutMethods.VENMO,
        content: (
          <Venmo
            ref={venmoRef}
            data={data?.venmo}
            openModalWindow={toggleModalWindow}
            changePayoutId={changePayoutId}
            changeStatusIsDataSaved={changeStatusIsDataSaved}
          />
        ),
      },
    ],
    [data, changePayoutId, changeStatusIsDataSaved],
  );

  return (
    <>
      <NotificationModalWindow
        open={modalWindowTypes === 'success'}
        onClose={() => toggleModalWindow(null)}
        title={`${
          activeMethod ? relationBtwTypesAndLabel[activeMethod] : 'Current'
        } method is updated!`}
        autoHideDuration={5000}
      />
      <ErrorModalWindow
        open={modalWindowTypes === 'error'}
        onClose={() => toggleModalWindow(null)}
        title={'An error occurred with payout'}
        description={
          activeMethod === PayoutMethods.STRIPE
            ? 'Routing number and Account number should be valid'
            : "Sorry, Your payout details weren't saved."
        }
      />
      <WrapperBlock title={'Payout Method'} errorMessage={errorMessage}>
        <AccordionWrapper>
          {accordions.map(({ payoutType, content }) => (
            <CustomAccordion
              key={payoutType}
              expanded={activeMethod === payoutType}
              onChange={handleChange(payoutType)}
            >
              <AccordionWrapperTitle checked={activeMethod === payoutType}>
                <MethodLabel methodType={payoutType} />
              </AccordionWrapperTitle>
              <CustomAccordionDetails>{content}</CustomAccordionDetails>
            </CustomAccordion>
          ))}
        </AccordionWrapper>
      </WrapperBlock>
    </>
  );
};

export default forwardRef(PayoutMethod);

const AccordionWrapper = styled(Box)(() => ({
  width: '100%',
  padding: '5px 0',
}));

interface AccordionProps extends AccordionSummaryProps {
  checked: boolean;
}

const AccordionWrapperTitle = styled((props: AccordionProps) => (
  <AccordionSummary expandIcon={<Radio checked={props.checked} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  flexDirection: 'row-reverse',
  gap: 20,
  padding: '0 15px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    alignItems: 'center',
  },
  '.MuiAccordionSummary-content': {
    alignItems: 'center',
    gap: 8,
  },
  [theme.breakpoints.up('md')]: {
    padding: '0 20px',
  },
}));

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  '&:before': {
    backgroundColor: theme.palette.secondary.light,
  },
  '&.MuiAccordion-root': {
    backgroundColor: 'transparent',
    margin: 0,
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  '&.MuiAccordionDetails-root': {
    background: theme.palette.background.default,
  },
}));
