import React from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import { Button, Stack, Typography, styled } from '@mui/material';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 400,
  },
}));

interface Props {
  open: boolean;
  onSkip: () => void;
  onAddLivecast: () => void;
}

const AddLivecastModal = ({ open, onAddLivecast, onSkip }: Props) => {
  const handleAddingLivecast = () => {
    onAddLivecast();
  };

  return (
    <>
      <StyledModal open={open} closeBtn={true} keepMounted={false}>
        <Stack spacing={3}>
          <Typography
            fontWeight={'700'}
            fontSize={{ xs: 16, sm: 20 }}
            variant="subtitle1"
            lineHeight={'27px'}
            align="center"
          >
            Would you like to add another livecast now?
          </Typography>
          <Button
            variant="contained"
            color="warning"
            onClick={handleAddingLivecast}
            sx={{ flexGrow: 1 }}
          >
            ADD LIVECAST
          </Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={onSkip}
            sx={{ flexGrow: 1, textTransform: 'none' }}
          >
            Skip
          </Button>
        </Stack>
      </StyledModal>
    </>
  );
};

export default AddLivecastModal;
