// @mui
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material';
// utils
import { DateTime, getCurrentTime, isSameOrAfter } from '~utils/dateTimeUtils';
// types
import { RewardCampaign } from '~types/reward';
//
import RewardCountDown from './reward-countdown';
import RewardItem from './reward-item';

const StyledRewardsWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  border: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
  borderRadius: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledListWrapper = styled(Box)(({ theme }) => ({
  overflowX: 'auto',
  overflowY: 'hidden',
  '&::-webkit-scrollbar': {
    width: 4,
    height: 4,
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
    borderRadius: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    WebkitBoxShadow: `inset 0 0 6px rgba(0,0,0,0.1))`,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.warning.main,
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  minHeight: 240,
  width: 'calc(16 / 9 * 100vw)',
  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}));

const StyledListItem = styled(ListItem)(() => ({
  minHeight: 40,
  paddingLeft: 0,
  paddingRight: 0,
  '& .MuiTypography-root': {
    fontWeight: '500',
  },
}));

const StyledListItemHeader = styled(ListItemText)(({ theme }) => ({
  color: alpha(theme.palette.common.white, 0.3),
  '& .MuiTypography-root': {
    fontWeight: '500',
  },
}));

type Props = {
  data: RewardCampaign | null;
};

const RewardList = ({ data }: Props) => {
  const ended = !isSameOrAfter(
    DateTime.fromISO(data?.endedAt as string),
    getCurrentTime(),
  );
  const rewardsLength = (data?.rewards ?? []).length;

  const sortedRewards = (data?.rewards ?? []).sort(
    (a, b) => a.orderNumber - b.orderNumber,
  );

  return (
    <StyledRewardsWrapper spacing={{ xs: 1, sm: 1.5 }} divider={<Divider />}>
      <RewardCountDown
        endedAt={data?.endedAt as string}
        orderNumber={data?.orderNumber as number}
      />
      <StyledListWrapper>
        <StyledList dense>
          <StyledListItem>
            <StyledListItemHeader sx={{ flex: 1 }} primary="Place" />
            <StyledListItemHeader sx={{ flex: 2, mr: 2 }} primary="Reward" />
            <StyledListItemHeader
              sx={{ flex: 2 }}
              primary={ended ? 'Winners' : 'Current winners'}
            />
          </StyledListItem>
          {!data && (
            <Typography textAlign={'center'} fontWeight={'500'}>
              No data
            </Typography>
          )}
          <>
            {sortedRewards.map((item, index) => (
              <RewardItem
                key={item.id}
                item={item}
                index={index}
                rewardsLength={rewardsLength}
                ended={ended}
              />
            ))}
          </>
        </StyledList>
      </StyledListWrapper>
    </StyledRewardsWrapper>
  );
};

export default RewardList;
