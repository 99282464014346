import React, { useState } from 'react';

import { Box, Button, Stack, styled, Typography } from '@mui/material';

import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import BasePopup from '~components/organisms/popup/base-popup';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  DialogConfirm,
  FormProvider,
  RHFTextField,
} from '~components/molecules';
import * as yup from 'yup';
import { messages } from '~language/en';
import { CircleSuccessTickSVG } from '~components/icons';
import { isUserNameValid } from '~utils/validateUtils';
import { useMutation } from '@tanstack/react-query';
import { changeUsername, ChangUsernameErrorCode } from '~api';
import { showErrorToaster } from '~utils/toasterNotification';
import { UserActivateResponse } from '~types/user';
import { inMemoryJWTService, useGlobalState } from '~utils';
import { useParams } from 'react-router-dom';
import { axiosRequest } from '~api/singleton-axios';
import { useBoolean } from '~hooks/useBoolean';

const enum FIELDS_NAME {
  USERNAME = 'username',
}

export type ChangeUsernameInputs = {
  [key in FIELDS_NAME]: string;
};

const schema = yup.object({
  username: yup
    .string()
    .required('Please enter username')
    .test('username', messages.VALIDATIONS.INVALID_USERNAME, isUserNameValid),
});

const ChangeUsernameModal = () => {
  const { username: usernameParam } = useParams();
  const globalState = useGlobalState();
  const {
    user: { username: oldUsername },
  } = globalState;

  const { hidePopup } = useDispatchPopup();
  const [successMsg, setSuccessMsg] = useState('');

  const dialogConfirm = useBoolean();

  const methods = useForm<ChangeUsernameInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
    },
  });
  const { handleSubmit, setError, trigger } = methods;

  const { isLoading, mutate: handleChangeEmail } = useMutation({
    mutationFn: (data: ChangeUsernameInputs) => changeUsername(data.username),
    onSuccess: ({ data }: { data: UserActivateResponse }) => {
      const {
        accessToken,
        refreshToken,
        expiresIn,
        user: { username },
      } = data;
      inMemoryJWTService.setToken(accessToken, expiresIn);
      inMemoryJWTService.setRefreshToken(refreshToken);
      setSuccessMsg('Your username has been updated!');

      setTimeout(() => {
        hidePopup();
        if (oldUsername === usernameParam) {
          window.location.href = `/${username}`;
        } else {
          window.location.reload();
        }
      }, 3000);
    },
    onError: (error) => {
      let errorCode = '';
      let errorMessage = '';
      if (axiosRequest.isAxiosError(error)) {
        errorCode = error?.response?.data?.code;
        errorMessage = error?.response?.data?.message;
      }
      switch (errorCode) {
        case ChangUsernameErrorCode.ERR_CHANGE_USERNAME_ALREADY_TAKEN:
          setError(FIELDS_NAME.USERNAME, {
            type: 'custom',
            message: errorMessage,
          });
          break;
        default:
          showErrorToaster(error);
          return;
      }
    },
  });

  const onSubmit = async (data: ChangeUsernameInputs) => {
    dialogConfirm.onFalse();
    await handleChangeEmail(data);
  };

  const onOpenConfirmDialog = async () => {
    const isValid = await trigger();
    if (isValid) {
      dialogConfirm.onTrue();
    }
  };

  return (
    <ChangeUsernameModalWindow
      open={true}
      onClose={hidePopup}
      title="Change Username"
      hideCloseButton={!!successMsg}
    >
      <Box sx={{ marginTop: 2 }}>
        <FormProvider methods={methods}>
          {successMsg ? (
            <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
              <CircleSuccessTickSVG />
              <Typography variant="body1">{successMsg}</Typography>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <RHFTextField
                labelColor="white"
                label="Username"
                name="username"
                type="text"
                placeholder="Enter username"
                required={true}
              />
              <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                <Button
                  fullWidth
                  type="button"
                  color="warning"
                  variant="contained"
                  disabled={isLoading}
                  onClick={onOpenConfirmDialog}
                  sx={{ padding: 1.5, marginTop: 2 }}
                >
                  SAVE
                </Button>
              </Stack>
            </Stack>
          )}
        </FormProvider>
      </Box>
      <DialogConfirm
        open={dialogConfirm.value}
        title="Confirmation"
        message="Are you sure you want to change your username?"
        onClose={dialogConfirm.onFalse}
        onCancel={dialogConfirm.onFalse}
        onConfirm={handleSubmit(onSubmit)}
      />
    </ChangeUsernameModalWindow>
  );
};

export default ChangeUsernameModal;

const ChangeUsernameModalWindow = styled(BasePopup)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '90%',
    height: 'fit-content',
  },
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(7.5)}`,
    width: 650,
  },
}));
