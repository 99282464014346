import { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { inMemoryJWTService } from '~utils/jwtUtils';
import { axiosRequest } from '~api/singleton-axios';

export type { AxiosResponse as Response } from 'axios';

export const axiosClient = applyCaseMiddleware(
  axiosRequest.create({ baseURL: process.env.REACT_APP_API_HOST }),
);

export const axiosClientWithoutCaseConvert = axiosRequest.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

type GetParams = {
  path: string;
  config?: AxiosRequestConfig;
  customHeader?: RawAxiosRequestHeaders;
  applyMiddleWare?: boolean;
};

type PostParams = GetParams & {
  data?: Record<string, unknown>;
};

export const getRequest = ({ path, config, customHeader }: GetParams) => {
  const token = inMemoryJWTService.getToken();
  return (
    axiosClient
      .get(`/${path}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          ...customHeader,
        },
        ...config,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => response.data)
  );
};

export const postRequest = ({
  path,
  config,
  customHeader,
  data = {},
  applyMiddleWare = true,
}: PostParams) => {
  const token = inMemoryJWTService.getToken();
  const client = applyMiddleWare ? axiosClient : axiosClientWithoutCaseConvert;
  return client.post(`/${path}`, data, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...customHeader,
    },
    ...config,
  });
};

export const putRequest = ({
  path,
  config,
  customHeader,
  data,
}: PostParams) => {
  const token = inMemoryJWTService.getToken();
  return axiosClient.put(`/${path}`, data, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...customHeader,
    },
    ...config,
  });
};

export const patchRequest = ({
  path,
  config,
  customHeader,
  data,
}: PostParams) => {
  const token = inMemoryJWTService.getToken();
  return axiosClient.patch(`/${path}`, data, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...customHeader,
    },
    ...config,
  });
};

export const delRequest = ({ path, config, customHeader }: GetParams) => {
  const token = inMemoryJWTService.getToken();
  return axiosClient.delete(`/${path}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...customHeader,
    },
    ...config,
  });
};

export const rawGetRequest = ({ path, config, customHeader }: GetParams) => {
  return (
    axiosClient
      .get(path, {
        headers: {
          Accept: 'application/json',
          ...customHeader,
        },
        ...config,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => response.data)
  );
};
