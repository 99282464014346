import Report from '~components/manage-profile/tabs/reports/report';
import React from 'react';
import { ReportView } from '~types/event';
import { ROWS_PER_PAGE } from './list-of-reports';
import { styled, Typography } from '@mui/material';

interface ReportsProps {
  data: ReportView[] | undefined;
  page: number;
  totalReports: number;
}

export const Reports = ({ data, totalReports, page }: ReportsProps) => {
  if (!totalReports) {
    return <NoData>NO DATA</NoData>;
  }
  return (
    <>
      {data?.map(({ id, ...rest }, index) => (
        <Report
          key={id}
          number={totalReports - index - (page - 1) * ROWS_PER_PAGE}
          {...rest}
        />
      ))}
    </>
  );
};

const NoData = styled(Typography)(() => ({
  paddingTop: 15,
  textAlign: 'center',
}));
