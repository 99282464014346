import { postRequest, putRequest, getRequest, Response } from '~utils';
import {
  FanFollowResponse,
  FanFollowingResponse,
  FanResponse,
  FanUnfollowResponse,
  FanUpdateLiveCoinPopupResponse,
} from '~types/fan';
import { NotificationType } from '~types';

export const updateLivecoinPopup = async (
  show: boolean,
): Promise<Response<FanUpdateLiveCoinPopupResponse>> => {
  return await putRequest({
    path: 'fans/update-live-coin-popup',
    data: {
      show: show,
    },
  });
};

export const convertToArtist = async (): Promise<
  Response<{
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    user: FanResponse;
  }>
> => {
  return await putRequest({
    path: 'fans/convert-to-artist',
  });
};

export const getFollowing = async (
  artistUserName: string,
): Promise<FanFollowingResponse> => {
  return await getRequest({
    path: `fans/following?username=${artistUserName}`,
  });
};

export const getArtistFollowing = async (
  params: Record<string, string | number>,
): Promise<Response<{ data: FanResponse[]; count: number }>> => {
  return await postRequest({
    path: `fans/following/list`,
    data: params,
  });
};

export const updateArtistNotificationAll = async (data: {
  notificationType: NotificationType;
}): Promise<
  Response<{ data: FanResponse[]; count: number; success?: boolean }>
> => {
  return await putRequest({
    path: `fans/following/notification-type/update-all`,
    data,
  });
};

export const updateArtistNotification = async (data: {
  notificationType: NotificationType;
  artistId: string;
}): Promise<
  Response<{ data: FanResponse[]; count: number; success?: boolean }>
> => {
  return await putRequest({
    path: `fans/following/notification-type/update-one`,
    data: { artistId: data.artistId, notificationType: data.notificationType },
  });
};

export const followArtist = async (
  artistId: string,
  notificationType: string,
  chatGroupId?: string,
  currentEvent?: string,
): Promise<Response<FanFollowResponse>> => {
  return await postRequest({
    path: 'fans/follow',
    data: currentEvent
      ? {
          artist_id: artistId,
          notification_type: notificationType,
          chatGroupId: chatGroupId,
          event_id: currentEvent,
        }
      : {
          artist_id: artistId,
          notification_type: notificationType,
          chatGroupId: chatGroupId,
        },
  });
};

export const unfollowArtist = async (
  artistId: string,
): Promise<Response<FanUnfollowResponse>> => {
  return await postRequest({
    path: 'fans/unfollow',
    data: {
      artist_id: artistId,
    },
  });
};

export const getFanDetail = async (
  id: string,
): Promise<Response<FanResponse>> => {
  return await getRequest({
    path: 'fans/by-id',
    config: {
      params: {
        id,
      },
    },
  });
};
