import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { IconButton } from '@mui/material';
import { ReactNode } from 'react';
import { PopoverOrigin } from '@mui/material/Popover/Popover';

interface IconPopoverProps extends Partial<PopoverOrigin> {
  children: ReactNode;
  text: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

const IconPopover = ({
  text,
  children,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'center' },
}: IconPopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton
        onClick={handleClick}
        size="small"
        disableFocusRipple={true}
        disableRipple={true}
      >
        {children}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Typography align="center" sx={{ p: 2 }}>
          {text}
        </Typography>
      </Popover>
    </div>
  );
};

export default IconPopover;
