import { Box, IconButton, styled, Typography, Stack } from '@mui/material';
import React from 'react';
import { DateTime } from 'luxon';
import { TrashIcon } from '~components/icons';

interface CampaignTitleProps {
  title: string;
  startAt: Date;
  endAt: Date;
  hasActive?: boolean;
  handleDelete?: () => void;
}

const CampaignTitle = (props: CampaignTitleProps) => {
  const { title, startAt, endAt, hasActive, handleDelete } = props;
  const hasDelete = handleDelete !== undefined;

  const formatDateTime = (dateTime: Date) => {
    return DateTime.fromJSDate(dateTime).toFormat(`DDD 'at' t`);
  };

  return (
    <WrapperTitle>
      <Title>{title}</Title>
      <WrapDateTime component={'span'}>
        {formatDateTime(startAt)}
        <ConnectWord>To</ConnectWord>
        {formatDateTime(endAt)}
      </WrapDateTime>
      <Stack flexDirection="row" justifyContent="end" flex="1">
        {hasActive && <ActiveLabel>Active</ActiveLabel>}
        {hasDelete && (
          <IconButton
            sx={{ mr: '10px' }}
            aria-label="delete campaign"
            edge="end"
            onClick={() => handleDelete()}
          >
            <TrashIcon />
          </IconButton>
        )}
      </Stack>
    </WrapperTitle>
  );
};

export default CampaignTitle;

const WrapperTitle = styled(Box)(() => ({
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  paddingBottom: '12px',
  display: 'inline-flex',
  width: '100%',
  marginBottom: '2px',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginRight: '12px',
  color: theme.palette.action.active,
  fontSize: theme.typography.h5.fontSize,

  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.h4.fontSize,
  },
}));

const WrapDateTime = styled(Typography)<{ component: React.ElementType }>(
  ({ theme }) => ({
    fontSize: theme.typography.subtitle2.fontSize,
    display: 'flex',
    alignItems: 'center',
  }),
);

const ConnectWord = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkText.main,
  margin: '0 12px',
}));

const ActiveLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.success.lighter,
  fontSize: theme.typography.h5.fontSize,

  [theme.breakpoints.up('xl')]: {
    fontSize: theme.typography.h4.fontSize,
  },
}));
