import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Box, styled } from '@mui/material';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import {
  strictValidArrayWithLength,
  validObjectWithParameterKeys,
} from '~utils/commonUtils';
import { useWindowDimensions } from '~components/custom-hook';
import { useGlobalState, useGlobalDispatch } from '~utils/container';
import Carousel from 'react-slick';
import { CarouselDots } from '~components/molecules';
import FanCarouselItem from './fan-carousel-item';
import {
  ArrowContainer,
  NextArrowButtons,
  PrevArrowButtons,
} from '~components/pages/home/styles';

const StyledRoot = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: 'auto',
  '& .slick-slider': {
    [theme.breakpoints.up('md')]: {
      margin: 'auto 30px',
    },
  },
  '& .slick-list .slick-track': {
    marginLeft: '0 !important',
  },
  '& .slick-slide': {
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
}));

const DEFAULT_BREAKPOINT = { slidesToShow: 8, slidesToScroll: 8, rows: 1 };

type Props = {
  fans: IAgoraRTCRemoteUser[];
};

const FanCarousel = ({ fans }: Props) => {
  const dispatch = useGlobalDispatch();
  const [state] = useState({
    oldSlide: 0,
    nextSlide: 0,
    activeSlide: 0,
  });
  const carouselRef = useRef<Carousel | null>(null);
  const [activeBreakpoint, setActiveBreakpoint] = useState(DEFAULT_BREAKPOINT);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const globalState = useGlobalState();
  const {
    config: { isFanspotLightOpen, remoteUsers },
    app: { shuffle = false },
  } = globalState as Record<string, any>;

  const resolution = useWindowDimensions();

  const fanLength = fans.length;

  const play = () => {
    carouselRef && carouselRef.current && carouselRef.current.slickPlay();
  };
  const pause = () => {
    carouselRef && carouselRef.current && carouselRef.current.slickPause();
  };

  const goTo = () => {
    const id = setTimeout(() => {
      if (carouselRef && carouselRef?.current) {
        carouselRef?.current.slickGoTo(0, true);
      }
      play();
    }, 3100);
    if (timeoutId) clearTimeout(timeoutId);
    setTimeoutId(id);
  };

  const handleChangeBreakPoint = useCallback(() => {
    let show = false;
    if (
      strictValidArrayWithLength(fans) &&
      validObjectWithParameterKeys(activeBreakpoint, ['slidesToShow'])
    ) {
      const { rows = 1, slidesToShow } = activeBreakpoint;
      show = slidesToShow * rows < fanLength;
    }

    dispatch({ type: 'config', payload: { showShuffleButton: show } });
    if (!show) {
      dispatch({
        type: 'app',
        payload: { shuffle: false },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteUsers, activeBreakpoint]);

  const getCurrentBreakPoint = (width: number) => {
    let settings = DEFAULT_BREAKPOINT;
    switch (true) {
      case 1520 < width:
        settings = {
          slidesToShow: 8,
          slidesToScroll: 8,
          rows: 1,
        };

        break;
      case 1520 >= width && 1366 < width:
        settings = {
          slidesToShow: 6,
          slidesToScroll: 6,
          rows: 1,
        };

        break;
      case 1366 >= width && 1024 < width:
        settings = {
          slidesToShow: 5,
          slidesToScroll: 5,
          rows: 1,
        };

        break;
      case 1024 >= width && 767 < width:
        settings = {
          slidesToShow: 4,
          slidesToScroll: 4,
          rows: 1,
        };

        break;

      case 767 >= width && 480 < width:
        settings = {
          slidesToShow: 3,
          slidesToScroll: 3,
          rows: 2,
        };

        break;
      case 480 >= width:
        settings = {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 1,
        };
        break;

      default:
        break;
    }

    setActiveBreakpoint(settings);
  };

  const onMouseEvent = (isEntered: boolean) => {
    if (!shuffle) return;
    isEntered ? pause() : play();
  };

  useEffect(() => {
    const { nextSlide, activeSlide } = state;
    const { slidesToShow, rows = 1 } = activeBreakpoint;
    const totalSwiped = activeSlide * rows + slidesToShow * rows;
    if (
      strictValidArrayWithLength(fans) &&
      shuffle &&
      nextSlide === activeSlide &&
      totalSwiped >= fanLength
    ) {
      pause();
      goTo();
    }
  }, [state, shuffle, activeBreakpoint]);

  useEffect(() => {
    if (shuffle) {
      play();
    } else {
      if (timeoutId) clearTimeout(timeoutId);
      pause();
    }
  }, [shuffle]);

  useEffect(() => {
    handleChangeBreakPoint();
  }, [handleChangeBreakPoint]);

  useEffect(() => {
    getCurrentBreakPoint(resolution.width);
  }, [resolution]);

  useEffect(() => {
    dispatch({
      type: 'app',
      payload: { shuffle: false },
    });
  }, [activeBreakpoint]);

  const settings = useMemo(
    () => ({
      dots: true,
      infinite: false,
      speed: 2000,
      slidesToShow: 8,
      slidesToScroll: 8,
      rows: 1,
      autoplay: false,
      autoplaySpeed: 4000,
      arrows: true,
      prevArrow: (
        <ArrowContainer>
          <PrevArrowButtons />
        </ArrowContainer>
      ),
      nextArrow: (
        <ArrowContainer>
          <NextArrowButtons />
        </ArrowContainer>
      ),
      initialSlide: 0,
      centerMode: false,
      responsive: [
        {
          breakpoint: 1520,
          settings: {
            arrows: true,
            slidesToShow: 6,
            slidesToScroll: 6,
            rows: 1,
          },
        },
        {
          breakpoint: 1366,
          settings: {
            arrows: true,
            slidesToShow: 5,
            slidesToScroll: 5,
            rows: 1,
            speed: 1000,
            autoplaySpeed: 3000,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            rows: 1,
            speed: 1000,
            autoplaySpeed: 3000,
          },
        },
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            rows: 1,
            autoplaySpeed: 2000,
            speed: 500,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            rows: 1,
            speed: 500,
            autoplaySpeed: 1500,
          },
        },
      ],
      className: 'slider',
      pauseOnHover: false,
      cssEase: 'linear',
      ...CarouselDots({
        sx: {
          bottom: -40,
          position: 'absolute',
          width: '100%',
        },
      }),
    }),
    [],
  );

  return (
    <StyledRoot
      onMouseEnter={() => onMouseEvent(true)}
      onMouseLeave={() => onMouseEvent(false)}
      onTouchStart={() => onMouseEvent(true)}
      onTouchEnd={() => onMouseEvent(false)}
      display={{
        xs: isFanspotLightOpen ? 'block' : 'none',
        md: 'block',
      }}
    >
      <Carousel {...settings} ref={carouselRef}>
        {fans.map((fan: IAgoraRTCRemoteUser) => (
          <Box component={'div'} key={fan.uid}>
            <FanCarouselItem fan={fan} />
          </Box>
        ))}
      </Carousel>
    </StyledRoot>
  );
};

export default FanCarousel;
