import { useCallback, useRef, useState } from 'react';

// mui
import { Container, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
// service
import { QUERY_KEYS } from '~constants/query-keys';
import { blockUser, getBlockedUsers, unblockUser } from '~api/artist';
// lodash
import { get } from 'lodash';
// components
import { ArtistUnblockUsersToolbar } from '~components/organisms';
// utils
import { showErrorToaster } from '~utils/toasterNotification';
// types
import { BlockedUser } from '~types/artist';
import ArtistBlockedUserList from '~components/organisms/artist/artist-blocked-user-list';
import { useGlobalState } from '~utils';

const UnblockUsers = () => {
  const globalState = useGlobalState();

  const {
    user: { id },
  } = globalState;

  const cachedBlockedUsers = useRef<BlockedUser[]>([]);

  const [blockedUsers, setBlockedUsers] = useState<{
    data: BlockedUser[];
    count: number;
  }>({
    data: [],
    count: 0,
  });

  const [currentUser, setCurrentUser] = useState<BlockedUser | null>(null);

  const [params, setParams] = useState<{
    search?: string;
    page: number;
    pageSize: number;
  }>({
    page: 1,
    pageSize: 8,
  });

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: [
      QUERY_KEYS.ARTIST_BLOCKED_USERS_LIST,
      params.page,
      params.search,
      id,
    ],
    queryFn: () => getBlockedUsers({ ...params, artistId: id }),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      //
      cachedBlockedUsers.current = [...cachedBlockedUsers.current].concat(
        get(data, 'data.data', []).map((item) => ({
          ...item,
          isBlocked: true,
        })),
      );

      setBlockedUsers({
        data: cachedBlockedUsers.current,
        count: data.data.count,
      });
    },
  });

  const handleLoadMore = useCallback(() => {
    setParams((prev) => ({ ...prev, page: prev.page + 1 }));
  }, []);

  const { mutate: unblockUserMutate, isLoading: isUnblocking } = useMutation({
    mutationFn: (id: string) => unblockUser(id),
    onSuccess: async (_, id) => {
      setCurrentUser(null);
      const nextList = [...(cachedBlockedUsers.current as BlockedUser[])].map(
        (item) => ({
          ...item,
          isBlocked: item.id === id ? false : item.isBlocked,
        }),
      );
      cachedBlockedUsers.current = nextList;
      setBlockedUsers({
        data: nextList,
        count: blockedUsers.count,
      });
    },
  });

  const { mutate: blockUserMutate, isLoading: isBlocking } = useMutation({
    mutationFn: (id: string) => blockUser(id),
    onSuccess: async (_, id) => {
      const nextList = [...(cachedBlockedUsers.current as BlockedUser[])].map(
        (item) => ({
          ...item,
          isBlocked: item.id === id ? true : item.isBlocked,
        }),
      );
      setCurrentUser(null);
      cachedBlockedUsers.current = nextList;
      setBlockedUsers({
        data: nextList,
        count: blockedUsers.count,
      });
    },
  });

  const handleUnblock = useCallback(async (blockedUser: BlockedUser) => {
    try {
      setCurrentUser(blockedUser);
      if (blockedUser.isBlocked) {
        await unblockUserMutate(blockedUser.id);
        return;
      }
      await blockUserMutate(blockedUser.id);
    } catch (error) {
      showErrorToaster(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="lg" sx={{ pt: 5, pb: '100px' }}>
      <ArtistUnblockUsersToolbar
        onSearch={(value) => {
          if (params.search === value) return;
          setParams({
            page: 1,
            pageSize: 8,
            ...(value && value !== '-1' && { search: value }),
          });
          if (value || value === '-1') {
            setBlockedUsers({
              data: [],
              count: 0,
            });
            cachedBlockedUsers.current = [];
            refetch();
          }
        }}
      />
      <Typography variant="body1" sx={{ mb: 3 }}>
        Blocked users will not be able to enter messages in your profile's chat
        tab, see questions in your profile's question tab, or leave tips whether
        you are live or not.
      </Typography>
      <ArtistBlockedUserList
        hasMore={blockedUsers.data.length < (blockedUsers.count as number)}
        isEmpty={!(isLoading || isFetching) && !blockedUsers.data.length}
        loading={isLoading || isFetching}
        processing={isUnblocking || isBlocking}
        list={blockedUsers?.data as BlockedUser[]}
        currentUser={currentUser}
        onLoadMore={handleLoadMore}
        onUnblock={handleUnblock}
      />
    </Container>
  );
};

export default UnblockUsers;
