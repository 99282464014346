import React from 'react';
import { Link as LinkMUI, LinkProps as LinkPropsMUI } from '@mui/material';

interface LinkProps extends LinkPropsMUI {
  inNewTab?: boolean;
}

const Link = ({
  children,
  inNewTab = false,
  ...props
}: React.PropsWithChildren<LinkProps>) => {
  return (
    <LinkMUI
      rel={'noopener noreferrer'}
      target={inNewTab ? '_blank' : '_self'}
      {...props}
    >
      {children}
    </LinkMUI>
  );
};

export default Link;
