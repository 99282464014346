import { useDispatch, useSelector } from 'react-redux';
import { RootState, select } from '~stores';
import { useCallback } from 'react';
import { useRematchDispatch } from '~components/custom-hook/useRematchDispatch';

const useAddAvatar = () => {
  const dispatch = useDispatch();
  const { fetchMoreAvatars } = useRematchDispatch(
    (dispatch) => dispatch.common,
  );
  const avatars = useSelector((state: RootState) =>
    select.common.getAvatars(state),
  );
  const addAvatar = useCallback(
    async (username: string, avatar: string) => {
      if (!avatars[username]) {
        dispatch.common.addAvatars({ [username]: avatar });
      }
    },
    [avatars, dispatch.common],
  );

  const updateAvatar = useCallback(
    async (username: string, avatar: string) => {
      dispatch.common.addAvatars({ [username]: avatar });
    },
    [dispatch.common],
  );

  const fetchAvatar = useCallback(
    async (username: string) => {
      if (!avatars[username]) {
        fetchMoreAvatars([username]);
      }
    },
    [avatars, fetchMoreAvatars],
  );
  return { addAvatar, fetchAvatar, updateAvatar };
};
export default useAddAvatar;
