import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconProps } from '~components/icons';

const Fullscreen = (props: IconProps) => (
  <FontAwesomeIcon
    {...props}
    icon={faUpRightAndDownLeftFromCenter as IconProp}
  />
);
export default Fullscreen;
