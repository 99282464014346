import { FEATURE_FLAGS_TYPE, useGlobalState } from '~utils';
import { useMemo } from 'react';
import { useFeatureFlag } from '~hooks/useFeatureFlag';

const useAccountBalances = () => {
  const { isEnable } = useFeatureFlag();
  const isEnableNewLiveCoinSystem = isEnable(
    FEATURE_FLAGS_TYPE.SEPARATE_LIVE_COINS,
  );

  const {
    user: {
      walletBalanceFan = 0,
      walletBalanceInUsd: userWalletBalanceInUsd = 0,
      cashIn = 0,
      cashOut = 0,
    },
  } = useGlobalState();

  const walletBalance = useMemo(() => {
    return isEnableNewLiveCoinSystem ? cashIn : walletBalanceFan;
  }, [cashIn, isEnableNewLiveCoinSystem, walletBalanceFan]);

  const walletBalanceInUsd = useMemo(() => {
    return isEnableNewLiveCoinSystem ? cashIn / 10 : userWalletBalanceInUsd;
  }, [cashIn, isEnableNewLiveCoinSystem, userWalletBalanceInUsd]);

  const availableEarnedCoin = useMemo(() => {
    return isEnableNewLiveCoinSystem ? cashOut : 0;
  }, [cashOut, isEnableNewLiveCoinSystem]);

  const availableEarnedCoinInUsd = useMemo(() => {
    return isEnableNewLiveCoinSystem ? cashOut / 10 : 0;
  }, [cashOut, isEnableNewLiveCoinSystem]);

  return {
    walletBalance,
    walletBalanceInUsd,
    availableEarnedCoin,
    availableEarnedCoinInUsd,
  };
};
export default useAccountBalances;
