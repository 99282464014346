// providers
import { ManageProfileProvider } from '~providers/ManageProfileProvider';
// components
import { Container } from '~components/atoms';
import SEO from '~components/atoms/seo';
import LandingPageWrapper from '~components/wrapppers/landing-page-wrapper';
import ArtistFollowingView from '~components/pages/artist-following';

const ArtistFollowingPage = () => {
  return (
    <>
      <SEO
        title="Ursa Live | Artist Following"
        imgUrl="https://ursalive.com/assets/images/logo-bg.png"
        imgAlt="Ursa Live"
        url="https://ursalive.com/following-artist"
        description="Easy To Set Up, Highly Interactive, Revenue-Producing Livecasts. From Your Home, Direct To Your Fans."
      />
      <Container>
        <ManageProfileProvider>
          <LandingPageWrapper hideContact={true}>
            <ArtistFollowingView />
          </LandingPageWrapper>
        </ManageProfileProvider>
      </Container>
    </>
  );
};
export default ArtistFollowingPage;
