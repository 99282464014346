import { postRequest, getRequest, putRequest, Response } from '~utils';
import {
  UserForgotPasswordResponse,
  UserRequestNonceResponse,
  UserRequestTotpResponse,
  CheckExistsResponse,
  UserSignUpResponse,
  UserSignUpRequest,
  UserGeneratedImageUrlResponse,
  UserAvatarResponse,
  UserFirstLoginResponse,
  UserVerifyAccountResponse,
  UserActivationEmailResponse,
  UserActivateResponse,
  EmailPreferencesResponse,
  UnsubscribeResponse,
} from '~types/user';

import { UserInfo } from '~stores';

export enum UserStatus {
  UNVERIFIED = 'UNVERIFIED',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum SignUpErrorCode {
  ERR_SIGNUP_USERNAME_UNAVAILABLE = 'ERR_SIGNUP_003',
  ERR_SIGNUP_EMAIL_UNAVAILABLE = 'ERR_SIGNUP_004',
}

export enum ChangPasswordErrorCode {
  ERR_CHANGE_PASSWORD_INCORRECT_CURRENT_PASSWORD = 'ERR_CHANGE_PASSWORD_001',
}

export enum ChangUsernameErrorCode {
  ERR_CHANGE_USERNAME_ALREADY_TAKEN = 'ERR_CHANGE_USERNAME_001',
}

export enum ChangEmailErrorCode {
  ERR_CHANGE_EMAIL_INVALID = 'ERR_CHANGE_EMAIL_001',
  ERR_CHANGE_EMAIL_ALREADY_TAKEN = 'ERR_CHANGE_EMAIL_002',
}

export const checkEmailExist = async (
  email: string,
): Promise<CheckExistsResponse> => {
  return await getRequest({
    path: 'users/email/exists',
    config: {
      params: {
        email,
      },
    },
  });
};

export const checkUsernameExist = async (
  username: string,
): Promise<CheckExistsResponse> => {
  return await getRequest({
    path: 'users/username/exists',
    config: {
      params: {
        username,
      },
    },
  });
};

export const signUp = async (
  data: UserSignUpRequest,
): Promise<Response<UserSignUpResponse>> => {
  return await postRequest({
    path: 'users/signup',
    data: {
      ...data,
    },
  });
};

export const getUserInfoByName = async (
  username: string,
): Promise<UserInfo> => {
  return getRequest({
    path: `users/by-username/${username}`,
  });
};

export const getUserInfo = async (): Promise<UserInfo> => {
  return await getRequest({
    path: 'users/me',
  });
};

export const getNonce = async (): Promise<
  Response<UserRequestNonceResponse>
> => {
  return await postRequest({
    path: 'users/request-nonce',
    data: {
      platform: 'WEB',
    },
  });
};

export const requestSignIn = async (
  identity: string,
  password: string,
): Promise<Response<UserFirstLoginResponse>> => {
  return await postRequest({
    path: 'users/first-login',
    data: {
      identity,
      password,
    },
  });
};

export const verifyAccount = async (
  identity: string,
  password: string,
): Promise<Response<UserVerifyAccountResponse>> => {
  return await postRequest({
    path: 'users/verify-account',
    data: {
      identity,
      password,
    },
  });
};

export const resendActivationEmail = async (
  email: string,
  redirectUrl: string,
): Promise<Response<UserActivationEmailResponse>> => {
  return await postRequest({
    path: 'users/resend-activation-email',
    data: {
      email,
      redirectUrl,
    },
  });
};

export const requestAuthPhone = async (
  phone: string,
  nonce: string,
): Promise<Response<UserRequestTotpResponse>> => {
  return await postRequest({
    path: 'users/request-auth-phone',
    data: {
      phone,
      nonce,
    },
  });
};

export const verifyAuthPhone = async (
  totp: string,
  phone: string,
  countryCode: string,
): Promise<Response<any>> => {
  return await putRequest({
    path: 'users/me/phone',
    data: {
      totp,
      phone,
      countryCode,
    },
  });
};

export const requestSignUp = async (
  username: string,
  phone: string,
  nonce: string,
): Promise<Response<UserRequestTotpResponse>> => {
  return await postRequest({
    path: 'users/request-sign-up',
    data: {
      username,
      phone,
      nonce,
    },
  });
};

export const updateProfile = async (
  user: Pick<
    UserInfo,
    'name' | 'genre' | 'description' | 'imageUrl' | 'outsideLinks'
  >,
): Promise<Response<UserInfo>> => {
  return await putRequest({
    path: 'users/me',
    data: {
      name: user.name,
      genreId: user.genre?.id || null,
      description: user.description,
      imageUrl: user.imageUrl,
      outsideLinks: user.outsideLinks,
    },
  });
};

export const updateCustomerId = async (
  customerId: string,
): Promise<Response<UserInfo>> => {
  return await putRequest({
    path: 'users/me',
    data: {
      customer_id: customerId,
    },
  });
};

export const updateAvatar = async (
  avatar: string,
): Promise<Response<UserInfo>> => {
  return await putRequest({
    path: 'users/me',
    data: {
      image_url: avatar,
    },
  });
};

export const updateOnBoardingStep = async (
  onBoardingInfoStep: string,
): Promise<Response<UserInfo>> => {
  return await putRequest({
    path: 'users/me',
    data: {
      onboarding_info_step: onBoardingInfoStep,
    },
  });
};

export const forgotPassword = async (
  email: string,
  redirectUrl: string,
): Promise<Response<UserForgotPasswordResponse>> => {
  return await putRequest({
    path: 'users/forgot-password',
    data: {
      email,
      redirectUrl,
    },
  });
};

export const resetPassword = async (
  passwordResetKey: string,
  password: string,
): Promise<Response<UserInfo>> => {
  return await putRequest({
    path: `users/reset-password/${passwordResetKey}`,
    data: {
      password,
    },
  });
};

export const activateAccount = async (
  email: string,
): Promise<UserActivateResponse> => {
  return await getRequest({
    path: `users/activate/${email}`,
  });
};

export const getPreSignedUrl = async (
  fileType: string,
): Promise<Response<UserGeneratedImageUrlResponse>> => {
  return await postRequest({
    path: `users/generate-image-url?fileType=${fileType}`,
  });
};

export const getAvatars = async (
  usernames: string[],
): Promise<Response<UserAvatarResponse>> => {
  return await postRequest({
    path: 'users/avatars',
    data: {
      usernames,
    },
    applyMiddleWare: false,
  });
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<Response<UserRequestTotpResponse>> => {
  return await putRequest({
    path: 'users/change-password',
    data: {
      oldPassword,
      newPassword,
    },
  });
};

export const changeEmail = async (
  email: string,
): Promise<Response<UserRequestTotpResponse>> => {
  return await putRequest({
    path: 'users/change-email',
    data: {
      email,
    },
  });
};

export const confirmChangeEmail = async (
  token: string,
): Promise<Response<UserInfo>> => {
  return await putRequest({
    path: 'users/confirm-change-email',
    data: {
      email: token,
    },
  });
};

export const changeUsername = async (
  username: string,
): Promise<Response<UserActivateResponse>> => {
  return await putRequest({
    path: 'users/change-username',
    data: {
      username,
    },
  });
};

export const getPreSignedVideoUrl = async (
  fileType: string,
): Promise<Response<UserGeneratedImageUrlResponse>> => {
  return await postRequest({
    path: `users/generate-video-url?fileType=${fileType}`,
  });
};

export const getSuppressionGroups = async (
  sendgridUnsubscribeContactId: string,
): Promise<EmailPreferencesResponse> => {
  return await getRequest({
    path: `emails/suppression-groups?sendgrid_unsubscribe_contact_id=${sendgridUnsubscribeContactId}`,
  });
};

export const unsubscribeSuppressionGroups = async (
  sendgridUnsubscribeContactId: string,
  suppressionGroups: {
    id: number;
    selected: boolean;
  }[],
): Promise<Response<UnsubscribeResponse>> => {
  return await postRequest({
    path: `emails/unsubscribe`,
    data: {
      sendgridUnsubscribeContactId: sendgridUnsubscribeContactId,
      suppressionGroups: suppressionGroups,
    },
  });
};

export const verifyPhoneNumber = async (
  phoneNumber: string,
  nonce: string,
): Promise<Response<UserInfo>> => {
  return await postRequest({
    path: `users/request-auth-phone`,
    data: {
      phone: phoneNumber,
      nonce: nonce,
      onboard: true,
    },
  });
};

export const updateMusicGenre = async (
  genreId: string,
): Promise<Response<UserInfo>> => {
  return await putRequest({
    path: `users/me/music-genre`,
    data: {
      genreId: genreId,
      onboard: true,
    },
  });
};

export const updateUserAvatar = async (
  imageUrl: string,
): Promise<Response<UserInfo>> => {
  return await putRequest({
    path: `users/me/avatar`,
    data: {
      imageUrl: imageUrl,
      onboard: true,
    },
  });
};
