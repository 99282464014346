export enum WebSocketEvent {
  WELCOME = 'WELCOME',
  START_EVENT = 'START_EVENT',
  END_EVENT = 'END_EVENT',
  CREATE_EVENT = 'CREATE_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT',
  SEND_STAGE_INVITE = 'SEND_STAGE_INVITE',
  STAGE_INVITATION = 'STAGE_INVITATION',
  ACCEPT_DECLINE_INVITE = 'ACCEPT_DECLINE_INVITE',
  ALLOW_ON_STAGE_INVITE = 'ALLOW_ON_STAGE_INVITE',
  ALLOW_ON_STAGE = 'ALLOW_ON_STAGE',
  ACCEPT_DECLINE_STAGE_INVITE = 'ACCEPT_DECLINE_STAGE_INVITE',
  REMOVE_FROM_STAGE_REQUEST = 'REMOVE_FROM_STAGE_REQUEST',
  REMOVE_FROM_STAGE_RESPONSE = 'REMOVE_FROM_STAGE_RESPONSE',
  ATTENDEE_UPDATED = 'ATTENDEE_UPDATED',
  TIP_SEND = 'TIP_SEND',
  BLOCK_FAN = 'BLOCK_FAN',
  BLOCK_USER = 'BLOCK_USER',
  UNBLOCK_USER = 'UNBLOCK_USER',
  QUESTION_ADD = 'QUESTION_ADD',
  QUESTION_LIST = 'QUESTION_LIST',
  CHAT_ID_ADDED = 'CHAT_ID_ADDED',
  JOIN_EVENT = 'JOIN_EVENT',
  LEAVE_EVENT = 'LEAVE_EVENT',
  JOIN_STAGE_EVENT = 'JOIN_STAGE_EVENT',
  PING = 'PING',
  DELETE_EVENT = 'DELETE_EVENT',
  CHAT_ADDED = 'CHAT_ADDED',
  ADDED_EVENTS = 'ADDED_EVENTS',
  CAMPAIGN_STARTED = 'CAMPAIGN_STARTED',
  SHOW_ATTENDEES_STATE = 'SHOW_ATTENDEES_STATE',
  SUCCESS_PAYMENT = 'SUCCESS_PAYMENT',
  SEND_REACTION = 'SEND_REACTION',
  FORCE_LOGOUT = 'FORCE_LOGOUT',
  SEND_GA4_WATCHING_DATA_AFTER_LIVE_CAST = 'SEND_GA4_WATCHING_DATA_AFTER_LIVE_CAST',
}

export interface IEventHandler {
  processMessage: (message: unknown) => void;
}

export interface CampaignStartedResponse {
  username: string;
  campaignId: string;
  startTime: string;
}
