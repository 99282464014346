// components
import { Container } from '~components/atoms';
import SEO from '~components/atoms/seo';
import AboutView from '~components/pages/about';
import LandingPageWrapper from '~components/wrapppers/landing-page-wrapper';

const AboutPage = () => {
  return (
    <>
      <SEO
        title="Ursa Live | About "
        imgUrl="https://ursalive.com/assets/images/logo-bg.png"
        imgAlt="Ursa Live"
        url="https://ursalive.com/about"
        description="Ursa Live is a music livestreaming platform for emerging artists and the fans that love them. Artists can go live whenever they want (from their home, studio, or even from backstage before or after a show) and offer a unique experience, totally different than a real life performance. Artists tell stories about the songs they perform, they take questions between songs and invite fans “onstage” to interact. An Ursa Live livecast FEELS like a VIP event, but everyone’s invited and it’s almost always free to get in!"
      />
      <Container>
        <LandingPageWrapper>
          <AboutView />
        </LandingPageWrapper>
      </Container>
    </>
  );
};

export default AboutPage;
