import { Button, Stack, styled, Typography, Box } from '@mui/material';

import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { useGlobalDispatch, useGlobalState, USER_TYPE } from '~utils/index';
import { ManageProfileContext } from '~providers/ManageProfileProvider';
import BasePopup from '../base-popup';
import RewardList from './reward-list';
import { TextEllipsis } from '~components/atoms';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { getRewardPageData } from '~api/reward';
import { useContext, useMemo } from 'react';
import { TABS } from '~components/manage-profile/types';

const StyledRewardModal = styled(BasePopup)<{ hasReward: boolean }>(
  ({ theme, hasReward }) => ({
    backgroundColor: theme.palette.grey[900],
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 32px)',
      padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
    },
    [theme.breakpoints.up('md')]: {
      width: hasReward ? 878 : 450,
    },
  }),
);

const StyledArtistName = styled(TextEllipsis)(() => ({
  textTransform: 'capitalize',
  fontWeight: '700',
  textAlign: 'center',
}));

const RewardModal = () => {
  const { hidePopup } = useDispatchPopup();

  const globalState = useGlobalState();
  const {
    artist: { name: artistName, id: artistId },
    user: { type },
    app: { activeCampaign, previousCampaigns, upcomingCampaigns },
  } = globalState;
  const dispatch = useGlobalDispatch();
  const { openManageProfile } = useContext(ManageProfileContext);

  useQuery({
    queryKey: [QUERY_KEYS.ARTIST_REWARDS],
    queryFn: () => getRewardPageData(artistId),
    enabled: !!artistId,
    onSuccess: (data) => {
      dispatch({
        type: 'app',
        payload: {
          activeCampaign: data.activeCampaign,
          upcomingCampaigns: data.upcomingCampaigns ?? [],
          previousCampaigns: data.previousCampaigns ?? [],
          totalPreviousCampaign: data.totalPreviousCampaign,
          totalUpcomingCampaign: data.totalUpcomingCampaign,
        },
      });
    },
  });

  const data = useMemo(() => {
    return activeCampaign || previousCampaigns?.[0] || upcomingCampaigns?.[0];
  }, [activeCampaign, previousCampaigns, upcomingCampaigns]);

  const handleClose = () => {
    hidePopup();
  };

  const handleClickAddReward = () => {
    openManageProfile({ initialTab: TABS.REWARDS });
    hidePopup();
  };

  return (
    <StyledRewardModal
      open={true}
      onClose={handleClose}
      closeBtn={true}
      hasReward={!!data}
    >
      <Stack spacing={2.5}>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={1}
          justifyContent={'center'}
          sx={{
            padding: { sm: '0px 50px' },
          }}
        >
          <Box overflow={'hidden'}>
            <StyledArtistName
              maxWidth={'auto'}
              fontSize={{ xs: 20, sm: 28 }}
              text={artistName}
            />
          </Box>
          <Typography
            align={'center'}
            fontSize={{ xs: 20, sm: 28 }}
            fontWeight={'700'}
          >
            Rewards
          </Typography>
        </Stack>

        {data ? (
          <>
            <Box position={'relative'}>
              <RewardList data={data} />
            </Box>
            <Button
              size="large"
              color="warning"
              variant="contained"
              fullWidth
              sx={{ textTransform: 'capitalize', py: 2, fontSize: 12 }}
              onClick={handleClose}
            >
              Close
            </Button>
          </>
        ) : type === USER_TYPE.ARTIST ? (
          <>
            <Typography textAlign={'center'} fontWeight={'500'}>
              There is no active Rewards Campaign. Would you like to add one
              now?
            </Typography>
            <Stack
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                size="large"
                color="warning"
                variant="outlined"
                onClick={handleClose}
                sx={{ margin: 1 }}
              >
                CANCEL
              </Button>
              <Button
                size="large"
                color="warning"
                variant="contained"
                onClick={handleClickAddReward}
                sx={{ margin: 1 }}
              >
                ADD REWARDS
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Typography textAlign={'center'} fontWeight={'500'}>
              This artist has no active rewards campaign at the moment. Please
              check again at a later time.
            </Typography>
            <Button
              size="large"
              color="warning"
              variant="contained"
              fullWidth
              sx={{ textTransform: 'capitalize', fontSize: 12 }}
              onClick={handleClose}
            >
              Close
            </Button>
          </>
        )}
      </Stack>
    </StyledRewardModal>
  );
};

export default RewardModal;
