import * as React from 'react';
import { SVGProps } from 'react';

const Survey = ({ fill = '#FAC8A3', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width="130"
    height="131"
    viewBox="0 0 130 131"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle cx="65.3856" cy="65.5" r="62.5833" stroke={fill} strokeWidth="4" />
    <path
      d={`M43.3487 88.165C42.2723 88.165 41.3529 87.7838 40.5904 87.0213C39.828 86.2589 39.4468 85.3395 39.4468 
      84.2631V46.3204C39.4468 45.244 39.828 44.3246 40.5904 43.5621C41.3529 42.7997 42.2723 42.4185 43.3487 
      42.4185H87.4806C88.557 42.4185 89.4765 42.7997 90.2389 43.5621C91.0013 44.3246 91.3826 45.244 91.3826 
      46.3204V84.2631C91.3826 85.3395 91.0013 86.2589 90.2389 87.0213C89.4765 87.7838 88.557 88.165 87.4806 
      88.165H43.3487ZM43.3487 85.1377H87.4806C87.7049 85.1377 87.8955 85.048 88.0525 84.8686C88.2094 84.6892 
      88.2879 84.4873 88.2879 84.2631V46.3204C88.2879 46.0961 88.2094 45.8943 88.0525 45.7149C87.8955 45.5355 
      87.7049 45.4458 87.4806 45.4458H43.3487C43.1244 45.4458 42.9338 45.5355 42.7769 45.7149C42.6199 45.8943 
      42.5414 46.0961 42.5414 46.3204V84.2631C42.5414 84.4873 42.6199 84.6892 42.7769 84.8686C42.9338 85.048 
      43.1244 85.1377 43.3487 85.1377ZM47.2506 78.0738H59.36V74.0374H47.2506V78.0738ZM72.2766 72.4228L84.6551 
      60.0443L81.7623 57.1515L72.2766 66.7045L68.442 62.8699L65.6165 65.7626L72.2766 72.4228ZM47.2506 
      67.31H59.36V63.2735H47.2506V67.31ZM47.2506 56.5461H59.36V52.5096H47.2506V56.5461ZM42.5414 85.1377V45.4458V85.1377Z`}
      fill={fill}
    />
  </svg>
);

export default Survey;
