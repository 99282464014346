import { Dispatch } from 'react';
import { getFanDetails, saveTransaction } from '~components/action';
import { OrderResponseBody } from '@paypal/paypal-js/types/apis/orders';
import { LiveCoinOptionType, PaymentType, PaymentMethod } from '~types';

const handlePostPaypalPayment = async (
  orderResponseBody: OrderResponseBody,
  selectedLiveCoin: LiveCoinOptionType,
  dispatch: Dispatch<object>,
  user: object,
  callback?: () => void,
) => {
  await saveTransaction(
    orderResponseBody.id,
    selectedLiveCoin?.live_coins || 0,
    PaymentType.PAYPAL,
    selectedLiveCoin?.stripeProductId,
    PaymentMethod.PAYPAL,
    undefined,
  );
  await getFanDetails(dispatch, { user });
  !!callback && (await callback());
};

export { handlePostPaypalPayment };
