import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import packageJson from '../../package.json';

const activateDataDog = process.env.NODE_ENV === 'production';

export const initLog = () => {
  if (activateDataDog) {
    const applicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID || '';
    const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '';
    const site = process.env.REACT_APP_DATADOG_SITE || '';
    const service = process.env.REACT_APP_DATADOG_SERVICE_NAME || '';
    const version = packageJson.version;
    datadogRum.init({
      applicationId,
      clientToken,
      site,
      service,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.startSessionReplayRecording();
    datadogLogs.init({
      beforeSend: (log) => {
        // discard event message from AGORA
        const pattern = /^https?:\/\/[^/]+\.(agora\.io|sd-rtn\.com)/;
        if (log?.http?.url && pattern.test(log?.http?.url)) {
          return false;
        }
      },
      clientToken: clientToken,
      site,
      service,
      version,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['error'],
      sessionSampleRate: 100,
    });
  }
};
