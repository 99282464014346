function getFanFeedbackUrl() {
  return process.env.REACT_APP_FAN_FEEDBACK_URL;
}
function getArtistFeedbackUrl() {
  return process.env.REACT_APP_ARTIST_FEEDBACK_URL;
}

function getBookingProducerLink() {
  return 'https://calendly.com/ursa-producers/ursa-live-stream-setup';
}

export { getFanFeedbackUrl, getArtistFeedbackUrl, getBookingProducerLink };
