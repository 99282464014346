// @mui
import { Stack, Typography } from '@mui/material';
// components
import { LoadingButton } from '~components/atoms';
//
import ArtistFollowingItem from './artist-following-item';
import { ArtistFollowingItemSkeleton } from './artist-following-skeleton';
import { FanResponse } from '~types/fan';

type Props = {
  currentArtist: FanResponse | null;
  list: FanResponse[];
  loading: boolean;
  processing: boolean;
  hasMore: boolean;
  isEmpty: boolean;
  onLoadMore: VoidFunction;
  onFollow: (artist: FanResponse) => void;
  onNotify: (event: React.MouseEvent<HTMLElement>, artist: FanResponse) => void;
};

const ArtistFollowingList = ({
  currentArtist,
  loading,
  processing,
  list,
  hasMore,
  isEmpty,
  onLoadMore,
  onFollow,
  onNotify,
}: Props) => {
  const renderSkeleton = loading && !hasMore && (
    <Stack width={1} spacing={2.5}>
      {[...Array(8)].map(
        (
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          _,
          index,
        ) => (
          <ArtistFollowingItemSkeleton key={`artist-following-item-${index}`} />
        ),
      )}
    </Stack>
  );

  const renderEmpty = isEmpty && (
    <Stack width={1} alignItems="center" minHeight={320} spacing={2.5}>
      <Typography>No Data</Typography>
    </Stack>
  );

  const renderList = !loading && list && (
    <Stack width={1} spacing={2.5}>
      {list.map((item) => (
        <ArtistFollowingItem
          key={item.id}
          item={item}
          processing={
            processing && Boolean(currentArtist && currentArtist.id === item.id)
          }
          onFollow={() => onFollow(item)}
          onNotify={(event) => onNotify(event, item)}
        />
      ))}
    </Stack>
  );

  const renderLoadMore = hasMore && (
    <LoadingButton
      type={'submit'}
      variant="contained"
      color="warning"
      loading={loading}
      sx={{ minWidth: 168, height: 56, textTransform: 'unset' }}
      onClick={onLoadMore}
    >
      Show more
    </LoadingButton>
  );

  return (
    <Stack spacing={5} alignItems={'center'}>
      {renderSkeleton}

      {renderList}

      {renderEmpty}

      {renderLoadMore}
    </Stack>
  );
};

export default ArtistFollowingList;
