import React from 'react';
import BgVideos from '../../../images/decorations-landing-page/bg-videos.png';
import { Stack, styled } from '@mui/material';
import { Title } from './title';
import { Description } from './description';

const TITLE = 'ABOUT URSA LIVE';

const AboutUrsa = () => {
  return (
    <Container>
      <Title sx={{ display: { md: 'none' } }}>{TITLE}</Title>
      <Image src={BgVideos} alt="bg" />
      <TextWrapper>
        <CustomTitle
          sx={{
            display: { xs: 'none', md: 'block' },
            textAlign: 'left',
          }}
        >
          {TITLE}
        </CustomTitle>
        <CustomDescription>
          Ursa Live is a music livestreaming platform for emerging artists and
          the fans that love them.
        </CustomDescription>
        <ul style={{ margin: '10px 0' }}>
          <ListItem>
            Artists can go live whenever they want (from their home, studio, or
            even from backstage before or after a show) and offer a unique
            experience, totally different than a real life performance.
          </ListItem>
          <ListItem>
            Artists tell stories about the songs they perform, they take
            questions between songs and invite fans “onstage” to interact.
          </ListItem>
        </ul>
        <CustomDescription>
          An Ursa Live livecast FEELS like a VIP event, but everyone’s invited
          and it’s almost always free to get in!
        </CustomDescription>
      </TextWrapper>
    </Container>
  );
};

export default AboutUrsa;

const Container = styled(Stack)(({ theme }) => ({
  background: theme.palette.grey[900],
  position: 'relative',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '40px 20px 10px',
  [theme.breakpoints.up('md')]: {
    padding: '40px 20px',
  },
}));

const TextWrapper = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    right: 10,
    width: '35%',
  },
}));

const CustomTitle = styled(Title)(({ theme }) => ({
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.h4.fontSize,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 32,
  },
}));

const CustomDescription = styled(Description)(({ theme }) => ({
  textAlign: 'left',
  margin: 0,
  padding: 0,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.subtitle1.fontSize,
    padding: 0,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.h4.fontSize,
    padding: 0,
  },
}));

const ListItem = styled('li')(({ theme }) => ({
  listStyleType: 'disc',
  marginLeft: 20,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.h4.fontSize,
  },
}));

const Image = styled('img')(({ theme }) => ({
  width: '160%',
  margin: '20px 0',
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
}));
