import React, { useState } from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import { styled } from '@mui/material';
import ShareProfile from '~components/organisms/share-profile';
import ConfirmShareProfileStartingLiveModal from '~components/organisms/popup/confirm-share-profile-starting-live-modal';
import { useBoolean } from 'react-use';
import SelectDefaultCountryCodeModal from '~components/organisms/popup/select-default-country-code-modal';
import { addCountryCode, isValidPhone } from '~utils/validateUtils';
import { some } from 'lodash';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 600,
  },
}));

interface Props {
  onClose: () => void;
  isArtist?: boolean;
  artistName: string;
  onLive: (contact: IContact[]) => void;
  onBack: () => void;
}

const ShareProfileStartLiveModal = ({
  onClose,
  artistName,
  onLive,
  onBack,
}: Props) => {
  const [showShareProfileModal, setShowShareProfileModal] = useBoolean(true);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [
    showConfirmShareProfileStartingLiveModal,
    setShowConfirmShareProfileStartingLiveModal,
  ] = useBoolean(false);

  const [showSelectCountryCodeModal, setShowSelectCountryCodeModal] =
    useBoolean(false);

  const link = window.location.href;
  const handelClickContact = (contacts: IContact[]) => {
    setContacts(contacts);
    setShowShareProfileModal(false);
    const hasPhoneWithoutCountryCode = some(contacts, (contact) => {
      return !isValidPhone(contact.tel[0]);
    });

    if (hasPhoneWithoutCountryCode) {
      setShowSelectCountryCodeModal(true);
    } else {
      setShowConfirmShareProfileStartingLiveModal(true);
    }
  };

  const handleOnLive = () => {
    onLive(contacts);
  };

  const onSelectDefaultCountryCode = (countryCode: string) => {
    const newContacts = contacts.map((contact) => {
      const phone = addCountryCode(contact.tel[0], countryCode);
      return { tel: [phone] };
    });
    setContacts(newContacts);
    setShowSelectCountryCodeModal(false);
    setShowConfirmShareProfileStartingLiveModal(true);
  };

  const handelClickBackConfirmModal = () => {
    setShowShareProfileModal(true);
    setShowConfirmShareProfileStartingLiveModal(false);
    setShowSelectCountryCodeModal(false);
  };

  return (
    <>
      <StyledModal
        open={showShareProfileModal}
        onClose={onClose}
        keepMounted={false}
        title="NOTIFY!"
        onBack={onBack}
        hideBackButton={false}
      >
        <ShareProfile
          onClose={onClose}
          artistName={artistName}
          sectionTitle="SHARE TO"
          onClickContact={handelClickContact}
        />
      </StyledModal>
      <ConfirmShareProfileStartingLiveModal
        artistName={artistName}
        link={link}
        open={showConfirmShareProfileStartingLiveModal}
        onClose={onClose}
        onLive={handleOnLive}
        onBack={handelClickBackConfirmModal}
      />
      <SelectDefaultCountryCodeModal
        open={showSelectCountryCodeModal}
        onClose={onClose}
        onBack={handelClickBackConfirmModal}
        onNext={onSelectDefaultCountryCode}
      />
    </>
  );
};

export default ShareProfileStartLiveModal;
