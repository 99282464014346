import * as yup from 'yup';
import { VALID_EMAIL } from '~utils/regex';
import { messages } from '~language/en';

export type ForgotPasswordInputs = {
  email: string;
};

// Bank Account
export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .required(messages.VALIDATIONS.INVALID_EMAIL_ADDRESS)
    .matches(
      VALID_EMAIL,
      messages.VALIDATIONS.EMAIL_REQUIRED_FOR_FORGOT_PASSWORD,
    ),
});
