import React, { useState, useRef, useCallback } from 'react';
import PayoutMethod, { PayoutMethodRefProps } from './payout-method';
import { default as PayoutBlock } from './payout';
import { Box, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getPayoutInfo } from '~api';
import { useGlobalState } from '~utils';
import { QUERY_KEYS } from '~constants/query-keys';
import {
  PayoutMethods,
  UnionPayoutMethods,
} from '../payout/payout-method/types';
import { CurrentPaymentGateway } from '~types/payment';

interface PayoutProps {
  changeStatusIsDataSaved: (status: boolean) => void;
}

const Payout = ({ changeStatusIsDataSaved }: PayoutProps) => {
  const [activeMethod, setActiveMethod] = useState<UnionPayoutMethods>(
    PayoutMethods.NONE,
  );
  const payoutMethodRef = useRef<PayoutMethodRefProps>(null);
  const [activePayoutId, setActivePayoutId] = useState<string | null>(null);
  const { user } = useGlobalState();
  const { data, refetch } = useQuery({
    queryKey: [QUERY_KEYS.ARTIST_PAYOUT_INFO],
    queryFn: () => getPayoutInfo(user.id),
    enabled: !!user.id,
    refetchInterval: 1000 * 60, // 1 minute
    onSuccess: (data) => {
      const methods: Record<CurrentPaymentGateway, UnionPayoutMethods> = {
        STRIPE: PayoutMethods.STRIPE,
        VENMO: PayoutMethods.VENMO,
        PAYPAL: PayoutMethods.PAYPAL,
      };
      setActiveMethod(methods[data.currentPaymentGateway]);
      setActivePayoutId(data.currentPayoutId);
    },
  });
  const changePayoutId = (id: string | null) => setActivePayoutId(id);
  const changePayoutMethod = (method: UnionPayoutMethods) => {
    setActiveMethod(method);
  };

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <Container>
      <PayoutMethod
        ref={payoutMethodRef}
        data={data?.metadata}
        changePayoutId={changePayoutId}
        activeMethod={activeMethod}
        changePayoutMethod={changePayoutMethod}
        changeStatusIsDataSaved={changeStatusIsDataSaved}
      />
      <PayoutBlock
        amountPaidToDate={data?.amountPaidToDate ?? 0}
        availableEarnings={data?.availableEarnings ?? 0}
        fee={data?.appFeeInPercent ?? 0}
        activePayoutId={activePayoutId}
        activeMethod={activeMethod}
        onSuccessPayout={handleRefetch}
      />
    </Container>
  );
};

export default Payout;

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,

  [theme.breakpoints.up('md')]: {
    gap: 40,
  },
}));
