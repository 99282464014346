import React, { useState } from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import { Button, Stack, Typography, styled } from '@mui/material';

import { FollowIcon } from '~components/icons';
import FollowModal from '../../follow-modal';
import EndLiveAvatar from '../end-live-avatar';
import EndLiveArtistName from '../end-live-title';
import useAccount from '~hooks/useAccount';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 472,
  },
}));

interface Props {
  artistName: string;
  artistUserName: string;
  open: boolean;
  onClose: () => void;
  onFollow: (callback: () => void) => void;
  onNext: () => void;
}

const enum TipEndLiveStep {
  WELCOME,
  THANK,
}

const FollowEndLiveModal = ({
  artistName,
  artistUserName,
  open,
  onNext,
  onClose,
}: Props) => {
  const { isLogin } = useAccount();
  const { showLoginPopup } = useDispatchPopup();
  const [step, setStep] = useState<TipEndLiveStep>(TipEndLiveStep.WELCOME);
  const [showFollowModal, setShowFollowModal] = useState(false);

  const onCloseFollowModal = () => {
    setShowFollowModal(false);
  };
  const onOpenFollowModal = () => {
    if (!isLogin) {
      onClose();
      showLoginPopup();
      return;
    }
    setShowFollowModal(true);
  };

  const goToThank = () => {
    setStep(TipEndLiveStep.THANK);
  };

  return (
    <>
      <StyledModal
        open={open}
        onClose={onClose}
        closeBtn={true}
        keepMounted={false}
      >
        <Stack spacing={3}>
          <EndLiveArtistName
            title={
              step === TipEndLiveStep.THANK
                ? 'THANK YOU FOR FOLLOWING'
                : 'FOLLOW'
            }
            direction={step === TipEndLiveStep.THANK ? 'column' : 'row'}
            success={step === TipEndLiveStep.THANK}
            text={artistName}
          />
          <Stack flex={1} alignItems="center">
            <EndLiveAvatar username={artistUserName} />
          </Stack>
          {step === TipEndLiveStep.WELCOME && (
            <>
              <Stack flex={1} alignItems="center">
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={onOpenFollowModal}
                  sx={{ flexGrow: '0', width: '60%', textTransform: 'none' }}
                  startIcon={<FollowIcon />}
                  size="small"
                >
                  Follow
                </Button>
              </Stack>
              <Typography align="center" marginTop="2">
                Tap Follow to receive notifications when this artist goes live
                or schedules a livecast
              </Typography>

              <Button
                variant="contained"
                color="warning"
                onClick={onNext}
                sx={{ flexGrow: 1 }}
              >
                NEXT
              </Button>
            </>
          )}

          {step === TipEndLiveStep.THANK && (
            <Button
              variant="contained"
              color="warning"
              onClick={onNext}
              sx={{ flexGrow: 1 }}
            >
              NEXT
            </Button>
          )}
        </Stack>
      </StyledModal>

      {showFollowModal && (
        <FollowModal
          onClose={onCloseFollowModal}
          onFollowCallback={goToThank}
        />
      )}
    </>
  );
};

export default FollowEndLiveModal;
