import { createModel } from '@rematch/core';
import type { RootModel } from '.';
import { ScreenRatioEnum } from '~types';

export const INPUT_LEVEL_CONFIG_DEFAULT_VALUE = 100;
export type SettingState = {
  isLightThemeOn: boolean;
  cameraConfigs: InputDeviceInfo[];
  microphoneConfigs: InputDeviceInfo[];
  selectedCameraConfig: InputDeviceInfo | null;
  selectedMicrophoneConfig: InputDeviceInfo | null;
  inputLevelConfig: number;
  enableAECConfig: boolean;
  selectedScreenRatio: ScreenRatioEnum;
};

const initState: SettingState = {
  isLightThemeOn: false,
  cameraConfigs: [],
  microphoneConfigs: [],
  selectedCameraConfig: null,
  selectedMicrophoneConfig: null,
  inputLevelConfig: INPUT_LEVEL_CONFIG_DEFAULT_VALUE,
  enableAECConfig: false,
  selectedScreenRatio: ScreenRatioEnum.RATIO_4_3,
};

export const settings = createModel<RootModel>()({
  state: initState,
  selectors: (slice) => ({
    getCameraConfigs: () =>
      slice((settings: SettingState) => settings.cameraConfigs),
    getMicrophoneConfigs: () =>
      slice((settings: SettingState) => settings.microphoneConfigs),
    getSelectedCameraConfig: () =>
      slice((settings: SettingState) => settings.selectedCameraConfig),
    getSelectedMicrophoneConfig: () =>
      slice((settings: SettingState) => settings.selectedMicrophoneConfig),
  }),
  reducers: {
    setCameraConfigs: (state, payload: InputDeviceInfo[]) => {
      state.cameraConfigs = payload;
    },
    setMicrophoneConfigs: (state, payload: InputDeviceInfo[]) => {
      state.microphoneConfigs = payload;
    },
    setSelectedCameraConfig: (state, payload: InputDeviceInfo) => {
      state.selectedCameraConfig = payload;
    },
    setSelectedMicrophoneConfig: (state, payload: InputDeviceInfo) => {
      state.selectedMicrophoneConfig = payload;
    },
    setInputLevelConfig: (state, payload: number) => {
      state.inputLevelConfig = payload;
    },
    setEnableAECConfig: (state, payload: boolean) => {
      state.enableAECConfig = payload;
    },
    setSelectedScreenRatio: (state, payload: ScreenRatioEnum) => {
      state.selectedScreenRatio = payload;
    },
  },
});
