import React, { useState } from 'react';

import {
  AccordionProps,
  alpha,
  Box,
  Stack,
  styled,
  Typography,
  BoxProps,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { MinusSVG, PlusSVG } from '~components/icons';

interface Props {
  title: string;
  accordions: { question: string; answer: JSX.Element | string }[];
  sx?: BoxProps['sx'];
}

const Accordions = ({ title, accordions, sx }: Props) => {
  const [openQuestion, setOpenQuestion] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setOpenQuestion(newExpanded ? panel : false);
    };

  return (
    <Container sx={sx}>
      <Title>{title}</Title>
      <AccordionContainer>
        {accordions.map(({ question, answer }) => (
          <Accordion
            key={question + answer}
            expanded={openQuestion === question}
            onChange={handleChange(question)}
          >
            <AccordionSummary
              expandIcon={
                openQuestion === question ? <MinusSVG /> : <PlusSVG />
              }
              sx={{
                borderRadius: question === openQuestion ? '8px 8px 0 0' : '8px',
              }}
            >
              <Typography>{question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionContainer>
    </Container>
  );
};

export default Accordions;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px 20px',
  [theme.breakpoints.up('md')]: {
    padding: '0 80px 80px',
  },
}));

const AccordionContainer = styled(Stack)(({ theme }) => ({
  gap: 12,
  [theme.breakpoints.up('md')]: {
    gap: 26,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  lineHeight: '39px',
  color: theme.palette.text.primary,
  position: 'relative',
  marginBottom: 30,
  ':after': {
    content: '""',
    height: 4,
    width: 100,
    position: 'absolute',
    bottom: -8,
    left: 0,
    background: theme.palette.action.active,
  },
  [theme.breakpoints.up('sm')]: {
    marginBottom: 40,
    fontSize: theme.typography.h2.fontSize,
  },
}));

const Accordion = styled(({ children, ...props }: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props}>
    {children}
  </MuiAccordion>
))(({ theme }) => ({
  background: theme.palette.background.default,
  border: `2px solid transparent`,
  backgroundImage: `linear-gradient(180deg, #CE7CAC 0%, #7473B6 100%)`,
  borderRadius: 8,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  background: theme.palette.background.default,
  padding: '10px 20px',
  margin: 0,
  p: {
    fontSize: theme.typography.fontSize,
    paddingRight: 15,
  },
  '.MuiAccordionSummary-content': {
    margin: 0,
  },
  [theme.breakpoints.up('md')]: {
    padding: '22px 16px',
    p: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  background: theme.palette.background.default,
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10,
  p: {
    whiteSpace: 'pre-wrap',
    color: alpha(theme.palette.text.primary, 0.8),
  },
}));
