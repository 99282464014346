import * as React from 'react';
import { SVGProps } from 'react';

const UnionPaySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2500"
    height="1597"
    viewBox="0.347 0.422 295.482 188.766"
    {...props}
  >
    <path
      d={`M281.252.478L208.488.459h-.02c-.057 0-.111.01-.166.01-9.996.306-22.447 8.324-24.714 18.21l-34.411 
      152.263c-2.268 9.979 3.9 18.098 13.824 18.247h76.443c9.771-.483 19.269-8.409 21.497-18.183l34.413-152.264C297.658 
      8.663 291.342.478 281.252.478`}
      fill="#01798a"
    />
    <path
      d={`M149.178 170.941l34.411-152.263C185.856 8.792 198.307.774 208.303.468L179.371.45 127.246.44c-10.024.205-22.636 
      8.279-24.903 18.239L67.922 170.941c-2.276 9.979 3.902 18.098 13.819 
      18.247h81.261c-9.924-.149-16.092-8.268-13.824-18.247`}
      fill="#024381"
    />
    <path
      d={`M67.922 170.941l34.421-152.263C104.61 8.718 117.222.644 127.246.439L60.471.422c-10.08 0-23.004 8.167-25.308 
      18.256L.742 170.941c-.209.929-.326 1.84-.395 2.73v2.824c.674 7.274 6.229 12.571 14.214 
      12.692h67.18c-9.917-.148-16.095-8.267-13.819-18.246`}
      fill="#dd0228"
    />
    <path
      d={`M128.654 111.389h1.264c1.161 0 1.942-.391 2.309-1.162l3.284-4.914h8.793l-1.833 3.232h10.543l-1.338 
      4.953H139.13c-1.445 2.174-3.224 3.195-5.362 3.074h-6.535l1.421-5.183m-1.444 7.097h23.101l-1.473 
      5.38h-9.291l-1.417 5.192h9.04l-1.472 5.38h-9.04l-2.1 7.673c-.52 1.283.164 1.859 2.04 1.729h7.367l-1.365 5h-14.145c-2.681 
      0-3.601-1.534-2.76-4.609l2.685-9.792h-5.778l1.468-5.38h5.779l1.417-5.192h-5.524l1.468-5.381zm36.87-13.211l-.363 3.15s4.357-3.271 
      8.314-3.271h14.623l-5.592 20.244c-.464 2.314-2.453 3.465-5.965 3.465h-16.575l-3.882 14.215c-.225.762.092 1.152.928 
      1.152h3.262l-1.199 4.413h-8.291c-3.182 0-4.506-.957-3.981-2.88l10.971-40.488h7.75zm12.384 5.723h-13.053l-1.562 
      5.463s2.174-1.569 5.807-1.625c3.623-.057 7.758 0 7.758 0l1.05-3.838zm-4.73 12.672c.965.131 1.506-.25 
      1.57-1.152l.799-2.879h-13.072l-1.096 4.031h11.799zm-8.816 6.541h7.535l-.141 3.261h2.006c1.014 0 
      1.517-.325 1.517-.966l.594-2.109h6.263l-.836 3.075c-.708 2.564-2.583 3.902-5.631 4.033h-4.014l-.019 5.573c-.073.893.733 
      1.348 2.396 1.348h3.772l-1.217 4.414h-9.049c-2.537.12-3.781-1.088-3.754-3.652l.578-14.977M71.61 78.305c-1.022 
      5.008-3.391 8.854-7.066 11.585-3.642 2.685-8.338 4.032-14.089 4.032-5.412 0-9.379-1.375-11.911-4.134-1.756-1.96-2.629-4.45-2.629-7.46 
      0-1.245.149-2.583.446-4.022l6.127-29.543h9.253L45.699 77.97a9.396 9.396 0 0 0-.25 2.239c-.01 1.496.362 2.722 
      1.114 3.679 1.096 1.422 2.875 2.127 5.352 2.127 2.847 0 5.193-.696 7.014-2.1 1.821-1.394 3.01-3.372 3.544-5.946l6.062-29.209h9.207L71.61 
      78.305M110.472 66.682h7.247l-5.677 26.367h-7.233l5.663-26.367m2.281-9.606h7.312l-1.366 6.4h-7.311l1.365-6.4M124.134 
      91.042c-1.896-1.812-2.853-4.255-2.862-7.358 0-.53.032-1.133.103-1.793.07-.669.158-1.319.283-1.923.859-4.283 2.69-7.683 
      5.509-10.192 2.815-2.518 6.211-3.781 10.188-3.781 3.256 0 5.839.911 7.73 2.731 1.89 1.831 2.837 4.301 2.837 7.442 
      0 .538-.041 1.161-.111 1.83a33.767 33.767 0 0 1-.302 1.97c-.84 4.218-2.665 7.581-5.485 10.043-2.82 2.48-6.207 
      3.715-10.155 3.715-3.27.001-5.844-.891-7.735-2.684m13.811-5.221c1.278-1.384 2.192-3.484 
      2.75-6.28.084-.437.158-.892.204-1.347.046-.446.065-.864.065-1.245 0-1.626-.414-2.889-1.245-3.781-.826-.901-2.001-1.347-3.52-1.347-2.007 
      0-3.643.705-4.924 2.118-1.292 1.413-2.208 3.549-2.784 6.392-.079.437-.144.874-.204 1.301-.046.437-.06.845-.051 
      1.217 0 1.617.414 2.862 1.245 3.744.827.883 1.997 1.319 3.535 1.319 2.017-.001 3.651-.698 4.929-2.091`}
      fill="#fff"
    />
    <path
      d="M208.469.459L179.371.45l28.932.019c.054 0 .109-.01.166-.01"
      fill="#e02f41"
    />
    <path
      d="M179.371.45L127.646.422c-.13 0-.265.009-.4.018l52.125.01"
      fill="#2e4f7d"
    />
    <path
      d={`M194.942 111.611l1.747-6.15h8.836l-.381 2.258s4.514-2.258 7.766-2.258h10.925l-1.736 6.15h-1.719l-8.241 
      29.006h1.719l-1.635 5.76h-1.719l-.715 2.499h-8.557l.714-2.499h-16.88l1.645-5.76h1.691l8.249-29.006h-1.709m9.533 
      0l-2.248 7.85s3.848-1.477 7.164-1.895c.732-2.74 1.689-5.955 1.689-5.955h-6.605zm-3.289 11.53l-2.257 
      8.223s4.264-2.1 7.19-2.276c.845-3.178 1.691-5.946 1.691-5.946h-6.624zm1.653 17.476l1.69-5.965h-6.594l-1.701 
      5.965h6.605zm21.368-35.537h8.307l.353 3.065c-.056.78.409 1.153 1.394 1.153h1.468l-1.485 
      5.192h-6.105c-2.33.12-3.529-.771-3.641-2.694l-.291-6.716zm-2.434 11.14h26.905l-1.579 5.575h-8.566l-1.469 
      5.183h8.559l-1.59 5.565h-9.531l-2.156 3.262h4.666l1.077 6.531c.128.65.704.966 1.69.966h1.448l-1.522 
      5.378h-5.129c-2.658.131-4.031-.761-4.144-2.684l-1.236-5.965-4.245 6.346c-1.004 1.793-2.547 2.629-4.627 
      2.499h-7.832l1.523-5.38h2.444c1.004 0 1.839-.445 2.591-1.347l6.645-9.606h-8.566l1.588-5.565h9.291l1.477-5.183h-9.299l1.587-5.575M80.134 
      66.673h6.536l-.748 3.809.938-1.087c2.119-2.267 4.692-3.391 7.73-3.391 2.75 0 4.733.799 5.979 2.406 1.226 1.607 
      1.561 3.828.98 6.68l-3.601 17.968h-6.717l3.251-16.286c.335-1.682.242-2.936-.274-3.745-.51-.808-1.486-1.207-2.894-1.207-1.728 0-3.182.538-4.367 
      1.607-1.189 1.078-1.974 2.574-2.36 4.478l-2.996 15.153H74.86l5.274-26.385M155.085 66.673h6.542l-.743 3.809.927-1.087c2.121-2.267 
      4.703-3.391 7.732-3.391 2.75 0 4.736.799 5.973 2.406 1.219 1.607 1.57 3.828.976 6.68l-3.587 
      17.968h-6.726l3.253-16.286c.334-1.682.24-2.936-.271-3.745-.53-.808-1.487-1.207-2.889-1.207-1.729 
      0-3.177.538-4.376 1.607-1.189 1.078-1.979 2.574-2.35 4.478l-3.011 15.153h-6.722l5.272-26.385M187.418 50.321h18.99c3.65 
      0 6.475.827 8.416 2.452 1.932 1.646 2.899 4.005 2.899 7.08v.093c0 .585-.038 1.245-.094 1.96a29.41 29.41 0 0 1-.371 
      2.165c-.837 4.069-2.778 7.34-5.778 9.82-3.012 2.471-6.578 3.716-10.684 3.716h-10.184l-3.149 
      15.45h-8.817l8.772-42.736m4.746 19.855h8.445c2.201 0 3.948-.512 5.223-1.524 1.264-1.022 2.1-2.583 
      2.562-4.701.074-.391.121-.744.177-1.069.028-.306.065-.614.065-.91 0-1.515-.538-2.611-1.617-3.299-1.077-.698-2.768-1.031-5.109-1.031h-7.172l-2.574 12.534M257.189 
      98.159c-2.788 5.927-5.444 9.382-7.004 10.989-1.562 1.59-4.656 5.287-12.107 5.008l.643-4.523c6.27-1.933 
      9.66-10.639 11.594-14.494l-2.305-28.392 4.85-.065h4.069l.438 17.81 7.626-17.81h7.722l-15.526 31.477M235.599 68.828l-3.067 
      2.109c-3.205-2.508-6.131-4.06-11.779-1.44-7.693 3.567-14.122 30.929 7.061 21.917l1.207 1.431 8.334.214 5.473-24.862-7.229.631m-4.74 
      13.592c-1.338 3.949-4.328 6.559-6.67 5.816-2.341-.725-3.178-4.534-1.82-8.492 1.338-3.958 4.348-6.559 6.67-5.816 
      2.341.725 3.186 4.534 1.82 8.492`}
      fill="#fff"
    />
  </svg>
);

export default UnionPaySVG;
