import Cookies from 'js-cookie';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { ON_BOARDING_INFO_STEP, POPUP_TYPE, USER_TYPE } from './constants';

import { defaultState, reducer } from './store';
import { AgoraClient } from '~types/agora';
import AgoraRTC, { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import { useRematchDispatch } from '~components/custom-hook/useRematchDispatch';
import { ASocketConnection } from '~socket/types/SocketConnection';

export const DEFAULT_STATE_CONTEXT_VALUE = {
  app: {
    amount: 0,
    popup: POPUP_TYPE.NONE,
    popupData: {},
    questionsList: [],
    chatList: [],
    hasMoreChat: false,
    latestMessageId: undefined,
    latestCampaign: null,
    activeCampaign: null,
    upcomingCampaigns: [],
    previousCampaigns: [],
    tippedEvents: [],
    totalPreviousCampaign: 0,
    blockedUsername: [],
  },
  user: {
    id: '',
    type: '',
    originalType: '',
    username: '',
    wallet_balance_fan: 0,
    walletBalanceFan: 0,
    walletBalanceInUsd: 0,
    cashIn: 0,
    cashOut: 0,
    displayUsername: '',
    imageUrl: '',
    outsideLinks: {},
    phone: '',
    email: '',
    is_following: false,
    onBoardingInfoStep: ON_BOARDING_INFO_STEP.DONE,
    name: '',
    genre: '',
    showAddVideoToProfilePopup: true,
  },
  artist: {
    id: '',
    username: '',
    name: '',
    rewardsContent: '',
    currentEvent: '',
    displayUsername: '',
    imageUrl: '',
    tips_chronologically: [],
    top_tips: [],
    total_earned_in_event: 0,
  },
  config: {
    isArtistLive: false,
    chatGroupId: '',
    startLiveAt: null,
  },
};

const AGORA_LOG_LEVEL_NONE = 4;
if (process.env.NODE_ENV === 'production') {
  AgoraRTC.setLogLevel(AGORA_LOG_LEVEL_NONE);
}

export const SocketContext = createContext<ASocketConnection | null>(null);
const StateContext = createContext(DEFAULT_STATE_CONTEXT_VALUE);
const DispatchContext = createContext((data: unknown) => data);
export const AgoraVideoContext = createContext<IAgoraRTCClient | null>(null);
export const AutoplayTrackingContext = createContext<boolean>(true);

type Props = {
  children: React.ReactNode;
};
const ContainerProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [agoraVideo, setAgoraVideo] = useState<IAgoraRTCClient | null>(null);
  const [isAllowAutoplay, setIsAllowAutoplay] = useState<boolean>(true);

  const { fetchFeature } = useRematchDispatch((dispatch) => dispatch.common);

  useEffect(() => {
    AgoraRTC.onAutoplayFailed = () => {
      setIsAllowAutoplay(false);
      const handler = () => {
        setIsAllowAutoplay(true);
        document.removeEventListener('click', handler);
      };
      document.addEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const AgoraClient = (await import(
        'agora-rtc-sdk-ng'
      )) as unknown as AgoraClient;
      // setParameter is a private method with no public doc
      // Adding this setting will fix for audio crackling. This is a suggestion from Agora team
      // Adding ts-ignore here because setParameter is private method
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      AgoraClient.setParameter('"DISABLE_WEBAUDIO": "true"');

      setAgoraVideo(
        AgoraClient.createClient({
          codec: 'h264',
          mode: 'live',
        }),
      );
    })();
  }, []);

  useEffect(() => {
    Cookies.set('user', JSON.stringify(state.user));
    Cookies.set('artist', JSON.stringify(state.artist));
  }, [state]);

  useEffect(() => {
    fetchFeature();
  }, [fetchFeature, state.user.id]);

  return (
    <AgoraVideoContext.Provider value={agoraVideo}>
      <DispatchContext.Provider value={dispatch}>
        <StateContext.Provider value={state}>
          <AutoplayTrackingContext.Provider value={isAllowAutoplay}>
            {children}
          </AutoplayTrackingContext.Provider>
        </StateContext.Provider>
      </DispatchContext.Provider>
    </AgoraVideoContext.Provider>
  );
};

export default ContainerProvider;

export function useGlobalState() {
  return useContext(StateContext);
}

export function useUserInfoState() {
  const globalState = useContext(StateContext);

  const {
    user: { originalType, type, username },
  } = globalState;

  // isViewer is true if a user is in other artist page
  const isViewer = type === USER_TYPE.FAN;

  // isLoginViewer is true if a user is in other artist page and logged in
  const isLoginViewer = type === USER_TYPE.FAN && username;

  // isArtist is true if a user is in their own artist page
  const isHost = type === USER_TYPE.ARTIST;

  // isArtistUser is true if user's type is artist
  const isArtistUser = originalType === USER_TYPE.ARTIST;

  // isFanUser is true if user's type is fan
  const isFanUser = originalType === USER_TYPE.FAN;

  const isAnonymousUser = !username;

  return {
    isViewer,
    isLoginViewer,
    isHost,
    isArtistUser,
    isFanUser,
    isAnonymousUser,
  };
}

export function useGlobalDispatch() {
  return useContext(DispatchContext);
}
