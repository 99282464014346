import {
  useRef,
  CSSProperties,
  useState,
  useEffect,
  useCallback,
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material';
import Collapse from '@mui/material/Collapse';

export enum EmojiTypes {
  HEART = '❤️',
  GRINNING = '😀',
  CLAP = '👏',
  HORNS = '🤘',
  THUMBS_UP = '👍',
  FIRE = '🔥',
}

export type EmojiType = keyof typeof EmojiTypes;

type EmojiReactionProps = {
  containerStyles?: CSSProperties;
  emojiContainerStyles?: CSSProperties;
  onClick: (emojiType: EmojiType) => void;
  hideEmoji?: boolean;
};

export type EmojiReactionRefProps = {
  emitEmoji: (emojiType: EmojiType, text?: string) => void;
};
const StyledButton = styled(IconButton)(({ theme }) => ({
  fontSize: 20,
  border: 'none',

  // outlineColor: 'rgba(255, 255, 255, 0.1)',
  minWidth: 'unset',
  width: 30,
  height: 30,
  margin: 2,
  [theme.breakpoints.down('md')]: {
    fontSize: 18,
    width: 26,
    height: 26,
  },
}));

type EmojiReactionInfo = {
  emojiType: EmojiType;
  x: number;
  y: number;
  text?: string;
};
const FPS = 60;
const INTERVAL = 1000 / FPS;
const SPEED = 3;

const EmojiReaction: ForwardRefRenderFunction<
  EmojiReactionRefProps,
  EmojiReactionProps
> = (
  { hideEmoji = false, onClick, containerStyles, emojiContainerStyles },
  ref,
) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const emojiList = useRef<EmojiReactionInfo[]>([]);
  const lastUpdate = useRef(Date.now());

  const requestAnimationFrameId = useRef(0);
  const [open, setOpen] = useState(false);
  const containerWrapperStyles: CSSProperties = {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    ...containerStyles,
  };

  const canvasStyles: CSSProperties = {
    position: 'absolute',
    pointerEvents: 'none',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
  };

  const firstEmojiKey = Object.keys(EmojiTypes)[0] as EmojiType;
  const emojiKeys = Object.keys(EmojiTypes) as [EmojiType];

  const emojiWithoutFirstEmoji = emojiKeys.filter(
    (key) => key !== firstEmojiKey,
  );

  const emitEmoji = useCallback((emojiType: EmojiType, text?: string) => {
    if (canvas.current) {
      const x = Math.floor(Math.random() * (canvas.current.width - 50) + 10);
      const y = Math.floor(Math.random() * canvas.current.height + 5);
      emojiList.current.push({
        emojiType,
        x,
        y: y,
        text,
      });
    }
  }, []);

  const handleEmojiClick = (emojiType: EmojiType) => () => {
    if (open) {
      onClick(emojiType);
    }
  };

  const draw = useCallback(() => {
    const now = Date.now();
    const delta = now - lastUpdate.current;

    if (delta > INTERVAL && canvas.current) {
      lastUpdate.current = now;
      const ctx = canvas.current.getContext('2d');
      //render by fps

      if (ctx) {
        const width = canvas.current.width;
        const height = canvas.current.height;
        ctx.clearRect(0, 0, width, height);
        for (let i = 0; i < emojiList.current.length; i++) {
          const emoji = emojiList.current[i];
          ctx.textAlign = 'center';
          ctx.font = '20px Arial';
          ctx.fillText(EmojiTypes[emoji.emojiType], emoji.x, emoji.y);
          if (emoji.text) {
            ctx.font = `12px Arial`;
            ctx.fillStyle = 'white';
            ctx.fillText(emoji.text, emoji.x, emoji.y + 20);
          }

          // this will make every emoji move in a random speed, so it looks more realistic
          emoji.y -= SPEED;
        }
        emojiList.current = emojiList.current.filter((item) => item.y > 0);
      }
    }
    requestAnimationFrameId.current = window.requestAnimationFrame(draw);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        emitEmoji,
      };
    },
    [emitEmoji],
  );
  useEffect(() => {
    requestAnimationFrameId.current = window.requestAnimationFrame(draw);
    return () => {
      window.cancelAnimationFrame(requestAnimationFrameId.current);
    };
  }, [draw]);

  useEffect(() => {
    if (canvas.current) {
      canvas.current.width = container.current?.offsetWidth || 0;
      canvas.current.height = container.current?.offsetHeight || 0;
    }
  });

  return (
    <Box sx={containerWrapperStyles} ref={container}>
      <canvas ref={canvas} style={canvasStyles} />
      {!hideEmoji && (
        <Stack
          sx={{
            position: 'absolute',
            bottom: 15,
            right: 10,
            padding: '5px',
            borderRadius: '100vmax',
            backgroundColor: 'black',
            ...emojiContainerStyles,
          }}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          onClick={() => setOpen(true)}
        >
          <Collapse in={open}>
            <Stack direction="column-reverse">
              {emojiWithoutFirstEmoji.map((key: EmojiType) => (
                <StyledButton onClick={handleEmojiClick(key)}>
                  {EmojiTypes[key]}
                </StyledButton>
              ))}
            </Stack>
          </Collapse>
          <StyledButton onClick={handleEmojiClick(firstEmojiKey)}>
            {EmojiTypes[firstEmojiKey]}
          </StyledButton>
        </Stack>
      )}
    </Box>
  );
};
export default forwardRef(EmojiReaction);
