import { ITrack } from 'agora-rtc-sdk-ng';

export class StreamRecorder {
  private mediaTracks: ITrack[];
  private recordedBlob: Blob[];
  private mediaRecorder: MediaRecorder | null;
  private callback: () => void = () => undefined;

  constructor(mediaTracks: ITrack[]) {
    this.mediaTracks = mediaTracks;
    this.recordedBlob = [];
    this.mediaRecorder = null;
  }

  public start(): void {
    this.recordedBlob = [];
    const combinedStream = new MediaStream();
    this.mediaTracks.forEach((mediaTrack) => {
      combinedStream.addTrack(mediaTrack.getMediaStreamTrack());
    });

    this.mediaRecorder = new MediaRecorder(combinedStream);
    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        this.recordedBlob.push(event.data);
        this.callback && this.callback();
      }
    };
    this.mediaRecorder.addEventListener('error', (event) => {
      console.error(`error recording stream: ${event}`);
    });

    this.mediaRecorder.start();
  }

  public stop(): void {
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
    }
  }

  public getBlob(): Blob | null {
    return new Blob(this.recordedBlob, { type: 'video/mp4' });
  }

  public addCallback(callback: () => void): void {
    this.callback = callback;
  }
}
