import * as React from 'react';
import { SVGProps } from 'react';

const PlaySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="46"
    height="44"
    viewBox="0 0 46 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor={'pointer'}
    {...props}
  >
    <path
      d="M17.8447 30.6805L32.5957 21.9999L17.8447 13.3194V30.6805ZM23.2207 43.0638C20.0967 43.0638 17.1487 42.5148 14.3767
       41.4166C11.6067 40.3166 9.18968 38.812 7.12568 36.9027C5.06368 34.9916 3.43868 32.7536 2.25068 30.1888C1.06468
        27.6222 0.47168 24.8925 0.47168 21.9999C0.47168 19.0685 1.06468 16.3296 2.25068 13.7833C3.43868 11.237 5.06368
         9.00827 7.12568 7.09716C9.18968 5.1879 11.6067 3.68327 14.3767 2.58327C17.1487 1.48512 20.0967 0.936043
          23.2207 0.936043C26.3867 0.936043 29.3447 1.48512 32.0947 2.58327C34.8447 3.68327 37.2517 5.1879 39.3157
           7.09716C41.3777 9.00827 43.0027 11.2462 44.1907 13.811C45.3767 16.3777 45.9697 19.1073 45.9697 21.9999C45.9697
            24.8925 45.3767 27.6222 44.1907 30.1888C43.0027 32.7536 41.3777 34.9916 39.3157 36.9027C37.2517 38.812 34.8347
             40.3166 32.0647 41.4166C29.2927 42.5148 26.3447 43.0638 23.2207 43.0638V43.0638Z"
      fill="white"
      fillOpacity="0.7"
    />
  </svg>
);

export default PlaySVG;
