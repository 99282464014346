import { createContext, ReactNode, RefObject, useState } from 'react';

import ManageProfile from '~components/manage-profile';
import { styled } from '@mui/material';
import { ModalWindow } from '~components/atoms';
import { TABS, TabsValue } from '~components/manage-profile/types';

interface OpenManageProfileInput {
  initialTab?: TabsValue;
  isOnBoarding?: boolean;
}

export type ManageProfileProviderType = {
  openManageProfile: (option?: OpenManageProfileInput) => void;
  closeManageProfile: () => void;
};

const defaultProvider: ManageProfileProviderType = {
  openManageProfile: () => undefined,
  closeManageProfile: () => undefined,
};

export const ManageProfileContext =
  createContext<ManageProfileProviderType>(defaultProvider);

interface ManageProfileProviderProps {
  children: ReactNode;
  container?: RefObject<HTMLElement>;
}

const StyledManageProfileModalWindow = styled(ModalWindow)(({ theme }) => ({
  margin: '16px auto 0',
  width: '96%',
  padding: '20px 16px',

  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  [theme.breakpoints.up('sm')]: {
    margin: '34px auto 0',
    padding: 32,
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: '90%',
  },
}));

const initialActiveTab = TABS.PROFILE;

export const ManageProfileProvider = ({
  children,
  container,
}: ManageProfileProviderProps) => {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<TabsValue>(initialActiveTab);
  const [isOnBoarding, setIsOnBoarding] = useState<boolean>(false);
  const changeActiveTab = (tab: TabsValue) => setActiveTab(tab);
  const openManageProfile = ({
    initialTab,
    isOnBoarding = false,
  }: OpenManageProfileInput = {}) => {
    setOpen(true);
    changeActiveTab(initialTab ?? initialActiveTab);
    setIsOnBoarding(isOnBoarding);
  };
  const closeManageProfile = () => {
    setOpen(false);
  };
  return (
    <ManageProfileContext.Provider
      value={{
        openManageProfile,
        closeManageProfile,
      }}
    >
      {children}
      {open && (
        <StyledManageProfileModalWindow
          open={true}
          onClose={isOnBoarding ? () => undefined : closeManageProfile}
          container={container?.current}
        >
          <ManageProfile
            isOnBoarding={isOnBoarding}
            activeTab={activeTab}
            changeActiveTab={changeActiveTab}
            onClose={closeManageProfile}
          />
        </StyledManageProfileModalWindow>
      )}
    </ManageProfileContext.Provider>
  );
};
