import { styled, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  background: 'linear-gradient(180deg, #CE7CAC 0%, #7473B6 100%)',
  backgroundClip: 'text',
  textAlign: 'center',
  textFillColor: 'transparent',
  color: alpha(theme.palette.text.primary, 0.9),
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: 32,
  },
}));
