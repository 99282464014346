import React, { useMemo } from 'react';

import {
  FormControl,
  FormLabel,
  SelectChangeEvent,
  Slider,
  SliderThumb,
  Stack,
  styled,
} from '@mui/material';

import { Select } from '~components/atoms';
import { SelectItem } from '~components/atoms/select';

const VUMeterSlider = styled(Slider)(({ theme }) => ({
  padding: `0 !important`,
  color: theme.palette.warning.main,
  height: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    display: 'none',
  },
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.common.white,
    opacity: 0.2,
  },
}));
const InputLevelSlider = styled(Slider)(({ theme }) => ({
  padding: `0 !important`,
  height: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  '& .MuiSlider-thumb': {
    width: 28,
    height: 20,
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      boxShadow: 'none',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'white',
      marginLeft: 2,
      marginRight: 2,
    },
  },
  '& .MuiSlider-track': {
    backgroundColor: 'white',
    border: 'none',
  },
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.common.white,
    opacity: 0.2,
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '.MuiInputBase-root': {
    backgroundColor: theme.palette.primary.main,
  },
  '.MuiSelect-select': {
    fontSize: 12,
  },
}));

StyledSelect.defaultProps = {
  MenuProps: {
    sx: {
      width: 0,
      '& .MuiMenuItem-root': {
        whiteSpace: 'normal',
      },
    },
  },
};

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: 'white',
  fontWeight: '700',
  '&.Mui-focused': {
    color: 'white',
  },
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.error.main,
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: theme.spacing(1.5),
  },
}));

const StyledPercentLabel = styled(StyledFormLabel)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(0.5),
  bottom: 0,
  margin: 0,
}));

const StackContainer = styled(Stack)(() => ({
  marginTop: `10px !important`,
}));

type AudioThumbComponentProps = React.HTMLAttributes<unknown>;

function AudioThumbComponent(props: AudioThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

interface SettingDeviceSectionProps {
  setSelectedMicrophoneConfig: (
    microphoneConfig: InputDeviceInfo | null,
  ) => void;
  setSelectedCameraConfig: (CameraConfig: InputDeviceInfo | null) => void;
  selectedCameraConfig: InputDeviceInfo | null;
  selectedMicrophoneConfig: InputDeviceInfo | null;
  cameraConfigs: InputDeviceInfo[];
  microphoneConfigs: InputDeviceInfo[];
  setInputLeve?: (inputLeve: number) => void;
  vUMeter?: number;
  inputLevel?: number;
  turnOffVUMeter?: boolean;
  hideVolumeSection?: boolean;
  children?: React.ReactNode;
}
const INPUT_LEVEL_RATE = 10;

const SettingDeviceSection = ({
  setSelectedMicrophoneConfig,
  setSelectedCameraConfig,
  selectedCameraConfig,
  selectedMicrophoneConfig,
  cameraConfigs,
  microphoneConfigs,
  setInputLeve = () => undefined,
  vUMeter = 0,
  inputLevel = 0,
  turnOffVUMeter = true,
  hideVolumeSection = false,
  children,
}: SettingDeviceSectionProps) => {
  const cameraConfigItems: SelectItem[] = useMemo(() => {
    return cameraConfigs.map((item) => ({
      value: item.deviceId,
      label: item.label,
    }));
  }, [cameraConfigs]);

  const microphoneConfigItems: SelectItem[] = useMemo(() => {
    return microphoneConfigs.map((item) => ({
      value: item.deviceId,
      label: item.label,
    }));
  }, [microphoneConfigs]);

  const onChangeCameraDevice = (event: SelectChangeEvent<unknown>) => {
    const id = event.target.value;
    const selectedCameraConfig =
      cameraConfigs.find((cameraConfig) => cameraConfig.deviceId === id) ||
      null;
    setSelectedCameraConfig(selectedCameraConfig);
  };

  const onChangeMicrophoneDevice = (event: SelectChangeEvent<unknown>) => {
    const id = event.target.value;
    const selectedMicrophoneConfig =
      microphoneConfigs.find(
        (microphoneConfig) => microphoneConfig.deviceId === id,
      ) || null;
    setSelectedMicrophoneConfig(selectedMicrophoneConfig);
  };

  const onChangeInputLeve = (event: Event, newValue: number | number[]) => {
    setInputLeve((newValue as number) * INPUT_LEVEL_RATE);
  };

  return (
    <Stack spacing={{ xs: 2, md: 3 }} sx={{ marginTop: 0 }}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <FormControl fullWidth>
          <StyledFormLabel required>Camera source</StyledFormLabel>
          <StyledSelect
            value={selectedCameraConfig?.deviceId}
            onChange={onChangeCameraDevice}
            items={cameraConfigItems}
            required
            fullWidth={false}
            // error={!!errors[typeMethod]}
          />
        </FormControl>
        <FormControl fullWidth>
          <StyledFormLabel required>Audio input source</StyledFormLabel>
          <StyledSelect
            value={selectedMicrophoneConfig?.deviceId}
            onChange={onChangeMicrophoneDevice}
            items={microphoneConfigItems}
            fullWidth={false}
            required
            // error={!!errors[typeMethod]}
          />
        </FormControl>
      </Stack>
      {children}
      {!hideVolumeSection && (
        <StackContainer direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl fullWidth>
            <StyledFormLabel>VU meter</StyledFormLabel>
            <VUMeterSlider
              max={100}
              value={turnOffVUMeter ? 0 : vUMeter}
              defaultValue={vUMeter}
            />
            <StyledPercentLabel>
              {Math.floor(turnOffVUMeter ? 0 : vUMeter)} %
            </StyledPercentLabel>
          </FormControl>
          <FormControl fullWidth>
            <StyledFormLabel>Input level</StyledFormLabel>
            <InputLevelSlider
              onChange={onChangeInputLeve}
              min={1}
              max={100}
              valueLabelDisplay="auto"
              value={inputLevel / INPUT_LEVEL_RATE}
              defaultValue={inputLevel / INPUT_LEVEL_RATE}
              slots={{ thumb: AudioThumbComponent }}
            />
          </FormControl>
        </StackContainer>
      )}
    </Stack>
  );
};

export default SettingDeviceSection;
