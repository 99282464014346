import { strictValidArray } from '../utils/commonUtils';
import { USER_TYPE } from '~utils/constants';
const defaultState = {
  // web sdk params
  config: {
    uid: '',
    host: true,
    channelName: '',
    token: '',
    resolution: '480p',
    localVideoTrack: null,
    localAudioTrack: null,
    client: null,
    remoteUsers: [],
    isArtistLive: false,
    isFanLive: false,
    socket: null,
    chatClient: null,
    chatGroupId: '',
    isFanspotLightOpen: true,
    current_attendies: 0,
  },
  app: {
    popup: '',
    questionsList: [],
    chatList: [],
    blockedUsername: [],
    tippedEvents: [],
    hasMoreChat: false,
    latestMessageId: undefined,
  },
  user: {
    id: null,
    type: USER_TYPE.FAN,
    username: '',
    displayUsername: '',
    imageUrl: null,
  },
  artist: {
    name: '',
    imageUrl: null,
    events: [],
    currentEvent: '',
    displayUsername: '',
    top_tips: [],
    tips_chronologically: [],
    id: '',
  },
  rtn_tip: {},
  rtn_tip_list: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'socket_event_received': {
      return { ...state, socket_event: action.payload };
    }
    case 'config': {
      return { ...state, config: { ...state.config, ...action.payload } };
    }
    case 'app': {
      return { ...state, app: { ...state.app, ...action.payload } };
    }
    case 'user': {
      return { ...state, user: { ...state.user, ...action.payload } };
    }
    case 'artist': {
      return { ...state, artist: { ...state.artist, ...action.payload } };
    }
    case 'tip_rtn': {
      return {
        ...state,
        rtn_tip: action.payload,
        rtn_tip_list: [...state.rtn_tip_list, action.payload],
      };
    }
    case 'add_chat_message': {
      const chatList = strictValidArray(state.app.chatList)
        ? state.app.chatList
        : [];
      const added_message = strictValidArray(action.payload)
        ? action.payload
        : [];
      return {
        ...state,
        app: { ...state.app, chatList: [...chatList, ...added_message] },
      };
    }
    case 'add_tipped_event': {
      return {
        ...state,
        app: {
          ...state.app,
          tippedEvents: [...state.app.tippedEvents, action.payload],
        },
      };
    }
    case 'reset': {
      const resetArtist = {
        ...state.artist,
        top_tips: [],
        currentEvent: '',
        rtn_tip: {},
      };
      const resetApp = {
        chatList: state.app?.chatList || [],
        questionsList: state.app?.questionsList || [],
        hasMoreChat: state.app?.hasMoreChat || false,
        latestMessageId: state.app?.latestMessageId || undefined,
      };
      // Cookies.set('user', JSON.stringify(defaultState.user));
      // Cookies.set('artist', JSON.stringify(resetArtist));
      return {
        ...defaultState,
        artist: resetArtist,
        app: resetApp,
      };
    }
    default:
      throw new Error('mutation type not defined');
  }
};

export { reducer, defaultState };
