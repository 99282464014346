import * as React from 'react';
import { SVGProps } from 'react';

const GoLiveSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="37"
    height="34"
    viewBox="0 0 37 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.0001 22.625L25.7917 15.125L14.0001 7.58334V22.625ZM11.7084 33.0833V29.2917H4.70841C3.62508 29.2917 2.70147 28.9028 1.93758 28.125C1.17369 27.3472 0.791748 26.4167 0.791748 25.3333V4.87501C0.791748 3.79167 1.17369 2.86112 1.93758 2.08334C2.70147 1.30556 3.62508 0.916672 4.70841 0.916672H32.2917C33.3751 0.916672 34.3056 1.30556 35.0834 2.08334C35.8612 2.86112 36.2501 3.79167 36.2501 4.87501V25.3333C36.2501 26.4167 35.8612 27.3472 35.0834 28.125C34.3056 28.9028 33.3751 29.2917 32.2917 29.2917H25.3334V33.0833H11.7084Z"
      fill="white"
    />
  </svg>
);

export default GoLiveSVG;
