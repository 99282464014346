import React from 'react';
import { Box, styled } from '@mui/material';
import useBreakpoints from '~hooks/useBreakpoints';
import ArtistTabsMenu from './artist-tabs-menu';
import ArtistTabs from './artist-tabs';
import ArtistSpotlightActions from './artist-spotlight-actions';

const StyledAside = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(2.5)} ${theme.spacing(1)}`,
  position: 'relative',
  height: '44vh',
  borderRadius: 0,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2.5),
    minHeight: '50vh',
    height: '50vh',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: theme.spacing(2.5),
  },
}));

const ArtistSidebar = () => {
  const { isMd } = useBreakpoints();

  return (
    <>
      {!isMd && <ArtistSpotlightActions />}
      <StyledAside>{isMd ? <ArtistTabs /> : <ArtistTabsMenu />}</StyledAside>
    </>
  );
};

export default ArtistSidebar;
