import { IconButton, Stack, styled, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import BasePopup from './base-popup';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { VideoPlayer } from '../VideoPlayer';

const StyledAddVideoToProfileModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],

  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  },
  [theme.breakpoints.up('sm')]: {
    width: 638,
  },
}));

const StyledCloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: -12,
  top: -12,
}));

const PreviewArchivedVideoModal = ({ url }: any) => {
  const { hidePopup } = useDispatchPopup();
  const onCloseModal = () => {
    hidePopup();
  };

  return (
    <StyledAddVideoToProfileModal
      onClose={onCloseModal}
      closeBtn={
        <StyledCloseButton onClick={onCloseModal}>
          <CloseIcon
            sx={{
              color: 'white',
            }}
          />
        </StyledCloseButton>
      }
      open={true}
    >
      <Container
        spacing={3}
        alignItems={'center'}
        sx={{ width: '100%', height: '100%' }}
      >
        <PopupTitle textTransform={'uppercase'}> PREVIEW VIDEO </PopupTitle>
        <VideoPlayer autoPlay={true} url={url} />
      </Container>
    </StyledAddVideoToProfileModal>
  );
};

export default PreviewArchivedVideoModal;

const PopupTitle = styled(Typography)(({ theme }) => ({
  color: theme.typography.h2.color,
  font: theme.typography.h2.font,
  fontSize: 13,
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h2.fontSize,
  },
}));

const Container = styled(Stack)(({ theme }) => ({
  paddingX: 1,
  [theme.breakpoints.up('sm')]: {
    paddingX: 5,
  },
}));
