import React, { useState } from 'react';

import { Stack, styled, Typography } from '@mui/material';
import { resendActivationEmail } from '~api';
import { showErrorToaster } from '~utils/toasterNotification';
import PrimaryButton from '~components/atoms/primary-button';
import { CircleSuccessTickSVG } from '~components/icons';

const ResendEmail = ({ username }: { username: string }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const resendEmail = async () => {
    try {
      setLoading(true);
      await resendActivationEmail(username, `/${username}`);
      setIsSuccess(true);
    } catch (err) {
      showErrorToaster('Something went wrong. Try Again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container spacing={2}>
      {isSuccess ? (
        <>
          <CircleSuccessTickSVG />
          <Description>
            Please check your email for a new activation link.
          </Description>
        </>
      ) : (
        <>
          <Typography variant="h4">YOUR ACTIVATION LINK HAS EXPIRED</Typography>
          <PrimaryButton
            onClick={resendEmail}
            sx={{ paddingLeft: 5, paddingRight: 5 }}
            disabled={isLoading}
          >
            RESEND ACTIVATION LINK EMAIL
          </PrimaryButton>
        </>
      )}
    </Container>
  );
};

export default ResendEmail;

const Container = styled(Stack)(() => ({
  width: '100%',
  maxWidth: 400,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Description = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.subtitle1.fontSize,
  textAlign: 'center',
  color: theme.palette.text.primary,
  whiteSpace: 'pre-line',
}));
