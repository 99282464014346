import { Modal, Box } from '@mui/material';
import popupStyles from '~components/popup/popupStyles';

interface PrivacyPolicyModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const styles = {
  popup: [
    popupStyles.popup,
    {
      width: { xs: '90%', sm: '650px' },
      overflow: 'auto',
      '& *': { all: 'revert' },
    },
  ],
};

const RefundPolicyModal = ({ show, onClose }: PrivacyPolicyModalProps) => {
  return (
    <Modal open={show} onClose={onClose}>
      <Box sx={styles.popup}>
        <div>
          <div>
            We're happy to issue a refund for any amount of livecoin currency
            that you currently have in your Ursa Live wallet. Please contact{' '}
            <a href="mailto:support@ursalive.com">support@ursalive.com</a> to
            make this request along with the amount you'd like to be refunded.
            We cannot issue refunds for any livecoin that you've already tipped
            to an artist or host.
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RefundPolicyModal;
