import { FEATURE_FLAGS_TYPE, postRequest } from '~utils';
import { FeatureFlag } from '~types/feature-flag';
import { Response } from '~utils/clientUtils';

const FEATURE_FLAG_KEYS: string[] = Object.values(FEATURE_FLAGS_TYPE);
export const getFeatureFlags = async (): Promise<
  Response<FeatureFlag> | undefined
> => {
  if (FEATURE_FLAG_KEYS.length === 0) {
    return;
  }
  return await postRequest({
    path: 'feature-flag/by-keys',
    data: {
      feature_flag_Keys: FEATURE_FLAG_KEYS,
    },
  });
};
