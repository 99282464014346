import { createModel } from '@rematch/core';
import type { RootModel } from '.';
import { getAvatars } from '~api';
import { FeatureFlag } from '~types/feature-flag';
import { getFeatureFlags } from '~api/feature-flag';
import { keyBy } from 'lodash';

export interface CommonState {
  avatars: { [key: string]: string };
  inviteToStageTimestamp: number;
  featureFlags: { [key: string]: FeatureFlag };
  loadedFeatureFlags: boolean;
}

const initState: CommonState = {
  avatars: {},
  inviteToStageTimestamp: 0,
  featureFlags: {},
  loadedFeatureFlags: false,
};

export const common = createModel<RootModel>()({
  state: initState,
  selectors: (slice) => ({
    getAvatars: () => {
      return slice((common: CommonState) => {
        return common.avatars;
      });
    },
  }),
  reducers: {
    addAvatars: (state, payload: object, isReplaceExistItem = true) => {
      state.avatars = isReplaceExistItem
        ? { ...state.avatars, ...payload }
        : { ...payload, ...state.avatars };
    },
    setInviteToStageTimestamp: (state, payload: number) => {
      state.inviteToStageTimestamp = payload;
    },
    setFeatureFlags: (state, payload: { [key: string]: FeatureFlag }) => {
      state.featureFlags = payload;
      state.loadedFeatureFlags = true;
    },
  },

  effects: (dispatch) => ({
    async fetchMoreAvatars(usernames: string[]) {
      const {
        data: { urls },
      } = await getAvatars(usernames);
      dispatch.common.addAvatars(urls);
    },

    async fetchFeature() {
      const response = await getFeatureFlags();
      if (!response) {
        return;
      }
      const { data } = response;
      const featureFlags = keyBy(data, 'key');
      dispatch.common.setFeatureFlags(featureFlags);
    },
  }),
});
