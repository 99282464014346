import { useState } from 'react';
import { Button, IconButton, Stack, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { forgotPassword } from '~api/user';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { CircleSuccessTickSVG } from '~components/icons';
import { FormProvider, RHFTextField } from '~components/molecules';
import BasePopup from '../base-popup';
import {
  ForgotPasswordInputs,
  forgotPasswordSchema,
} from './validation-schemes';
import { POPUP_TYPE, useGlobalDispatch, useGlobalState } from '~utils/index';

const StyledForgotPasswordModal = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
    padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  },
  [theme.breakpoints.up('sm')]: {
    width: 518,
  },
}));

const StyledCloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: -12,
  top: -8,
}));

const ForgotPasswordModal = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { hidePopup } = useDispatchPopup();
  const dispatch = useGlobalDispatch();
  const {
    artist: { username },
  } = useGlobalState();
  const [successMsg, setSuccessMsg] = useState('');
  const [error, setError] = useState('');

  const methods = useForm<ForgotPasswordInputs>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(forgotPasswordSchema),
  });
  const { handleSubmit } = methods;

  const handleClose = () => {
    hidePopup();
  };

  const onSubmit = async (values: ForgotPasswordInputs) => {
    try {
      setError('');
      setSubmitting(true);
      const {
        data: { message },
      } = await forgotPassword(values.email, `/${username}`);
      setSuccessMsg(message);
      setSubmitting(false);
      setTimeout(() => {
        dispatch({ type: 'app', payload: { popup: POPUP_TYPE.LOGIN } });
      }, 5000);
    } catch (err) {
      setSubmitting(false);
      setError(
        "We didn't find any account registered for this email or username.",
      );
    }
  };

  return (
    <StyledForgotPasswordModal
      open={true}
      onClose={handleClose}
      closeBtn={
        <StyledCloseButton onClick={handleClose}>
          <CloseIcon
            sx={{
              color: 'white',
            }}
          />
        </StyledCloseButton>
      }
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography
          textTransform={'uppercase'}
          mb={3}
          fontWeight={'700'}
          fontSize={{ xs: 20 }}
        >
          Reset password
        </Typography>
        <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
          {successMsg && <CircleSuccessTickSVG />}
          <Typography variant="body1" color={error ? 'error.main' : undefined}>
            {error || successMsg}
          </Typography>
        </Stack>
        {!successMsg && (
          <Stack spacing={3}>
            <RHFTextField
              label="Email address"
              name={'email'}
              placeholder="Enter your email"
            />
            <Stack direction={'row'} spacing={2} justifyContent={'center'}>
              <Button
                color="warning"
                variant="outlined"
                disabled={isSubmitting}
                onClick={handleClose}
                sx={{ textTransform: 'capitalize' }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="warning"
                variant="contained"
                disabled={isSubmitting}
                sx={{ textTransform: 'capitalize' }}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        )}
      </FormProvider>
    </StyledForgotPasswordModal>
  );
};

export default ForgotPasswordModal;
