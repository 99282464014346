import { SelectItem } from '~components/atoms/select';
import { BankAccountField, PaypalVenmoType } from './types';

export const payPalMethods: SelectItem[] = [
  { value: PaypalVenmoType.EMAIL, label: 'Email' },
  { value: PaypalVenmoType.PHONE, label: 'Phone number' },
];
export const venmoMethods: SelectItem[] = [
  { value: PaypalVenmoType.EMAIL, label: 'Email' },
  { value: PaypalVenmoType.PHONE, label: 'Phone number' },
  { value: PaypalVenmoType.USERNAME, label: 'Username' },
];

export const STRIPE_ERROR_CODE = {
  ROUTING_NUMBER_INVALID: 'routing_number_invalid',
  ACCOUNT_NUMBER_INVALID: 'account_number_invalid',
};

export const fields: BankAccountField[] = [
  {
    type: 'firstName',
    label:
      'First Name (Enter your name exactly as it is recorded with the IRS.)',
    placeholder: 'Ex: John',
  },
  {
    type: 'lastName',
    label:
      'Last Name (Enter your last name exactly as it is recorded with the IRS.)',
    placeholder: 'Ex: Wick',
  },
  {
    type: 'routingNumber',
    label: 'Routing number',
    placeholder: 'Ex: 110000000',
  },
  {
    type: 'accountNumber',
    label: 'Account number',
    placeholder: 'Ex: 000123456789',
  },
];
