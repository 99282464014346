import { Stack, styled, alpha, Box, Typography } from '@mui/material';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import {
  VisaCardIcon,
  CalendarSVG,
  CreditCardIcon,
  MasterCardIcon,
  DiscoverCardIcon,
  DinersClubCardIcon,
  JBCCardIcon,
  UnionPayCardIcon,
  AmericanExpressIcon,
} from '~components/icons';
import { RHFCheckbox } from '~components/molecules';
import { strictValidString } from '~utils/commonUtils';
import { useFormContext } from 'react-hook-form';
import { useMemo, useState } from 'react';

const Root = styled(Stack)(({ theme }) => ({
  padding: '20px',
  paddingTop: 0,
  backgroundColor: theme.palette.primary.dark,
  border: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
  borderTop: 0,
  borderBottomLeftRadius: theme.spacing(1),
  borderBottomRightRadius: theme.spacing(1),

  "& div[class*='StripeElement-']": {
    backgroundColor: theme.palette.primary.main,
    padding: 10,
    height: 38,
    borderRadius: 5,
  },
}));

const IconWrapper = styled(Stack)(() => ({
  position: 'absolute',
  right: 0,
  top: 0,
  width: 48,
  height: '100%',
}));

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#fff',
      width: '100%',
      backgroundColor: 'transparent',
      caretColor: '#fff',
      lineHeight: '18px',
      fontSize: '14px',
      padding: '10px',
      letterSpacing: 'normal',
      borderRadius: '5px',
      fontFamily: 'Helvetica',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const PaymentCardForm = () => {
  const { watch, setValue } = useFormContext();
  const [branchIcon, setBranchIcon] = useState<
    | 'visa'
    | 'mastercard'
    | 'amex'
    | 'discover'
    | 'diners'
    | 'jcb'
    | 'unionpay'
    | 'unknown'
  >('unknown');

  const stripeError = watch('stripeError');

  const PAYMENT_FIELDS = useMemo(
    () => [
      {
        id: 'card-number-payment',
        label: 'Card number',
        Component: CardNumberElement,
        icons: {
          visa: <VisaCardIcon width={32} />,
          mastercard: <MasterCardIcon width={32} />,
          amex: <AmericanExpressIcon width={32} />,
          discover: <DiscoverCardIcon size="1x" />,
          diners: <DinersClubCardIcon size="1x" />,
          jcb: <JBCCardIcon size="1x" />,
          unionpay: <UnionPayCardIcon width={32} />,
        },
        handleCardChange: (event: any) => {
          if (event.brand) {
            setBranchIcon(event.brand);
          }
        },
      },
      {
        id: 'card-expire-payment',
        label: 'Expire Date',
        Component: CardExpiryElement,
        icon: <CalendarSVG width={32} />,
      },

      {
        id: 'card-cvc-payment',
        label: 'CVC',
        Component: CardCvcElement,
        icon: <CreditCardIcon />,
      },
    ],
    [],
  );

  const handleError = (error: string) => {
    let message = 'Something went wrong. Try Again.';
    if (strictValidString(error)) {
      message = error;
    }
    setValue('stripeError', message);
  };

  const handleCardChange = (event: any) => {
    if (event.error) {
      handleError(event.error.message);
    }
    if (stripeError) {
      setValue('stripeError', null);
    }
  };

  return (
    <Root>
      <Typography color={'error.light'}>{stripeError}</Typography>
      <Stack spacing={2.5} alignItems="center">
        {PAYMENT_FIELDS.map((field) => (
          <Box width={'100%'} key={field.id}>
            <Typography
              component={'label'}
              fontSize={12}
              fontWeight={'500'}
              gutterBottom
              variant="caption"
            >
              {field.label}
            </Typography>
            <Box position={'relative'}>
              <field.Component
                id={field.id}
                options={CARD_ELEMENT_OPTIONS}
                onChange={field.handleCardChange ?? handleCardChange}
              />
              {field.icon && (
                <IconWrapper justifyContent={'center'} alignItems={'center'}>
                  {field.icon}
                </IconWrapper>
              )}
              {field.icons &&
                (field.icons as { [key: string]: any })[branchIcon] && (
                  <IconWrapper justifyContent={'center'} alignItems={'center'}>
                    {(field.icons as { [key: string]: any })[branchIcon]}
                  </IconWrapper>
                )}
            </Box>
          </Box>
        ))}
        <Box width={'100%'}>
          <RHFCheckbox name="isSaved" label="Save card for future use." />
        </Box>
      </Stack>
    </Root>
  );
};

export default PaymentCardForm;
