import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime, SecondNumbers } from 'luxon';

import { FULL_DATE_TIME_WITH_TIME_ZONE } from './constants';
import { Dayjs } from 'dayjs';

export { AdapterLuxon as DateAdapter };
export { DateTime as DateTime };

export const isSameOrBefore = (
  queryDate: DateTime,
  referenceDate: DateTime,
) => {
  return queryDate <= referenceDate;
};

export const isSameOrAfter = (queryDate: DateTime, referenceDate: DateTime) => {
  return queryDate >= referenceDate;
};

export const addMinutes = (dateString: string, minutes: number) => {
  const dateTime = DateTime.fromISO(dateString);
  if (!dateTime.isValid) {
    return '';
  }
  return dateTime.plus({ minutes }).toISO();
};

export const getSecond = (time: DateTime): SecondNumbers => {
  return time.second;
};

export const getCurrentTime = (): DateTime => {
  return DateTime.now();
};

export const getCurrentDate = (dateString: string): DateTime => {
  return DateTime.fromISO(dateString);
};

export const plusMinutes = (time: DateTime, minutes: number) => {
  return time.plus({ minutes });
};

export const getLocalTimeZone = () => {
  return DateTime.local().zoneName;
};

export const formatDateTime = (
  time: string,
  format = FULL_DATE_TIME_WITH_TIME_ZONE,
) => {
  const dateTime = DateTime.fromISO(time);
  if (!dateTime.isValid) {
    return '';
  }
  return dateTime.setZone(getLocalTimeZone()).toFormat(format);
};

export const dateStringToRelativeTime = (dateString: string) => {
  const dateTime = DateTime.fromISO(dateString);
  if (!dateTime.isValid) {
    return '';
  }
  return dateTime.toRelative();
};

export const milisecondsToRelativeTime = (miliseconds: number) => {
  const dateTime = DateTime.fromMillis(miliseconds);
  if (!dateTime.isValid) {
    return '';
  }
  return dateTime.toRelative();
};

export const toUnixInteger = () => {
  return DateTime.utc().toUnixInteger();
};

export const currentTimeInISO = () => {
  return DateTime.now().toISO();
};

export const toISO = (date: DateTime) => {
  return date.toISO();
};

export const getDateAndTime = (dateTimeString: string) => {
  const date = DateTime.fromISO(dateTimeString);
  if (!date.isValid) {
    return {};
  }
  return {
    date: date.toISODate(), // YYYY-MM-DD
    time: date
      .toISOTime({
        includeOffset: false,
        suppressMilliseconds: true,
        suppressSeconds: false,
      })
      .slice(0, 5), // HH:mm
  };
};

export const combineDateAndTime = (date: Dayjs, time: Dayjs) => {
  const hour = time.hour();
  const minute = time.minute();
  return date.hour(hour).minute(minute);
};

export const formatDateToLocaleString = (date: Date) =>
  date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

export const convertSecondsToMS = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${minutes
    .toString()
    .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;

  return formattedTime;
};
