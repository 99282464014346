import { RewardCampaign, RewardWinner } from '~types/reward';

const CAMPAIGN_FIELD_NAME = {
  STARTED_AT: 'startedAt',
  ENDED_AT: 'endedAt',
  REWARDS: 'rewards',
};

function validateRewards(rewards: RewardWinner[]) {
  if (!rewards || rewards.length === 0) {
    return 'Rewards could not be empty';
  }

  let error = null;
  const emptyRewards = rewards.filter((item) => !item.rewardName.trim());
  if (emptyRewards.length > 0) {
    const orderNumbers = emptyRewards.map((item) => item.orderNumber);
    error = `Reward number ${orderNumbers.join(', ')} could not be empty`;
  }

  return error;
}

function validateCampaignTime(startedAt?: Date, endedAt?: Date) {
  const errorFields: string[] = [];
  if (
    startedAt &&
    new Date(startedAt) > new Date() &&
    endedAt &&
    new Date(startedAt) < new Date(endedAt)
  ) {
    // Valid campaign datetime
    return { errorFields, error: null };
  }

  const error = 'Please select valid start and end dates/times';
  if (!startedAt) {
    errorFields.push(CAMPAIGN_FIELD_NAME.STARTED_AT);
  }
  if (!endedAt) {
    errorFields.push(CAMPAIGN_FIELD_NAME.ENDED_AT);
  }

  return { errorFields, error };
}

function validateEndTime(endedAt?: Date) {
  if (endedAt && new Date(endedAt) > new Date()) {
    // Valid campaign datetime
    return { errorFields: [], error: null };
  } else {
    return {
      errorFields: [CAMPAIGN_FIELD_NAME.ENDED_AT],
      error: 'Please select valid end dates/times',
    };
  }
}

export function validateActiveRewardCampaign(campaign: RewardCampaign) {
  const { errorFields, error } = validateEndTime(campaign.endedAt as Date);
  const errors = error ? [error] : [];
  return { errorFields, errors };
}

export function validateRewardCampaign(campaign: RewardCampaign) {
  const { errorFields, error } = validateCampaignTime(
    campaign.startedAt as Date,
    campaign.endedAt as Date,
  );
  const errors = error ? [error] : [];
  const rewardsError = validateRewards(campaign.rewards);
  if (rewardsError) errors.push(rewardsError);

  return { errorFields, errors };
}
