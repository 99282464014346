import React from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  alpha,
  styled,
  Box,
} from '@mui/material';
import {
  RadioButtonCheckedRounded,
  RadioButtonUncheckedRounded,
} from '@mui/icons-material';
import { useGlobalState } from '~utils';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 600,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  padding: 10,
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
  '&:last-child': {
    borderBottom: 0,
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  color: alpha(theme.palette.common.white, 0.3),
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
}));

interface Props {
  open: boolean;
  onClose: () => void;
  setIsClearChat: (isClearChat: boolean) => void;
  setIsClearQuestion: (isClearQuestion: boolean) => void;
  isClearChat: boolean;
  isClearQuestion: boolean;
  onClear: () => void;
  onSkip: () => void;
}

const StartingLiveModal = ({
  open,
  onClear,
  onClose,
  onSkip,
  isClearChat,
  isClearQuestion,
  setIsClearChat,
  setIsClearQuestion,
}: Props) => {
  const {
    app: { questionsList, chatList },
  } = useGlobalState();

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      closeBtn={true}
      keepMounted={false}
    >
      <Stack spacing={3}>
        <Typography
          fontWeight={'700'}
          fontSize={{ xs: 16, sm: 28 }}
          variant="subtitle1"
          lineHeight={'27px'}
          align="center"
        >
          Would you like to clear your Chat and/or Questions tabs?
        </Typography>
        <Wrapper>
          <FormGroup>
            <StyledFormControlLabel
              labelPlacement="start"
              disabled={!chatList?.length}
              control={
                <StyledCheckbox
                  icon={<RadioButtonUncheckedRounded />}
                  checkedIcon={<RadioButtonCheckedRounded />}
                  size="small"
                  color="warning"
                  disableRipple
                  checked={isClearChat}
                  onChange={(e, checked) => setIsClearChat(checked)}
                />
              }
              label={
                <Typography
                  variant="body1"
                  fontWeight={'500'}
                  lineHeight={'14px'}
                  ml={1.5}
                  flex={1}
                >
                  Clear Chat tab
                </Typography>
              }
            />
            <StyledFormControlLabel
              labelPlacement="start"
              disabled={!questionsList?.length}
              control={
                <StyledCheckbox
                  icon={<RadioButtonUncheckedRounded />}
                  checkedIcon={<RadioButtonCheckedRounded />}
                  size="small"
                  color="warning"
                  disableRipple
                  checked={isClearQuestion}
                  onChange={(e, checked) => setIsClearQuestion(checked)}
                />
              }
              label={
                <Typography
                  variant="body1"
                  fontWeight={'500'}
                  lineHeight={'14px'}
                  ml={1.5}
                  flex={1}
                >
                  Clear Question tab
                </Typography>
              }
            />
          </FormGroup>
        </Wrapper>
        <Stack direction="column" spacing={1.5} flex={1}>
          <Button
            variant="contained"
            color="warning"
            onClick={onClear}
            sx={{ flexGrow: 1 }}
            disabled={!isClearChat && !isClearQuestion}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={onSkip}
            sx={{ flexGrow: 1, textTransform: 'none' }}
          >
            Skip
          </Button>
        </Stack>
      </Stack>
    </StyledModal>
  );
};

export default StartingLiveModal;
