import { Stack, Box, Typography, styled } from '@mui/material';

interface PreviewSectionProps {
  url: string;
  title?: string;
  subTitle?: string;
  titleFont?: string;
  titleFontSize?: number;
  subTitleFont?: string;
  subTitleFontSize?: number;
  textPosition?: string;
  videoRef: (el: any) => void;
  show: boolean;
  keyIndex: number;
}

const getResponsiveFontSize = ({
  baseSize,
  minMultiplier,
  maxMultiplier,
  minVW = '4vw',
  maxVW = '2vw',
}: any) => ({
  xs: `clamp(${
    baseSize * minMultiplier
  }px, ${minVW}, ${baseSize}px) !important`,
  sm: `clamp(${
    baseSize * (minMultiplier + 0.1)
  }px, ${minVW}, ${baseSize}px) !important`,
  md: `clamp(${
    baseSize * (minMultiplier + 0.2)
  }px, ${maxVW}, ${baseSize}px) !important`,
  lg: `clamp(${baseSize * (minMultiplier + 0.5)}px, ${maxVW}, ${
    baseSize * maxMultiplier
  }px) !important`,
});

export const MediaPlayer: React.FC<PreviewSectionProps> = ({
  url,
  title,
  subTitle,
  titleFont = 'Arial',
  titleFontSize = 24,
  subTitleFont = 'Arial',
  subTitleFontSize = 16,
  textPosition = 'center',
  show,
  videoRef,
  keyIndex,
}) => {
  const videoExtensions = ['.mp4', '.webm', '.ogg'];
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];

  function determineFileType(url: any): 'video' | 'image' | 'unknown' {
    if (!url) return 'unknown';
    const lowerCaseUrl = url.toLowerCase();
    for (const ext of videoExtensions) {
      if (lowerCaseUrl.endsWith(ext)) {
        return 'video';
      }
    }
    for (const ext of imageExtensions) {
      if (lowerCaseUrl.endsWith(ext)) {
        return 'image';
      }
    }
    return 'unknown';
  }

  return (
    <Stack
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
    >
      {determineFileType(url) === 'video' ? (
        <Video
          key={keyIndex}
          src={url}
          style={{ display: show ? 'block' : 'none' }}
          autoPlay
          ref={(el) => videoRef(el)}
          loop
          muted
          playsInline
        />
      ) : (
        show && (
          <Box
            component="img"
            src={url}
            sx={{
              maxWidth: '100%',
              height: '100%',
              minHeight: 300,
              objectFit: 'cover',
            }}
          />
        )
      )}
      {(title || subTitle) && show && (
        <CustomBox textPosition={textPosition}>
          {title && (
            <Typography
              sx={{
                fontFamily: titleFont,
                fontWeight: 'bold',
                fontSize: getResponsiveFontSize({
                  baseSize: titleFontSize,
                  minMultiplier: 0.3,
                  maxMultiplier: 1.2,
                }),
              }}
            >
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography
              sx={{
                fontFamily: subTitleFont,
                fontSize: getResponsiveFontSize({
                  baseSize: subTitleFontSize,
                  minMultiplier: 0.3,
                  maxMultiplier: 1.2,
                }),
              }}
            >
              {subTitle}
            </Typography>
          )}
        </CustomBox>
      )}
    </Stack>
  );
};

export default MediaPlayer;

const Video = styled('video')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));
interface CustomBoxProps {
  textPosition: string;
}
const spacing = 25;
const CustomBox = styled(Box)<CustomBoxProps>(({ theme, textPosition }) => ({
  position: 'absolute',
  padding: '8px',
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '4px',

  ...(textPosition === 'bottom-left' && {
    bottom: spacing,
    left: spacing,
    textAlign: 'left',
  }),
  ...(textPosition === 'bottom-right' && {
    bottom: spacing,
    right: spacing,
    textAlign: 'right',
  }),
  ...(textPosition === 'top-left' && {
    top: spacing,
    left: spacing,
    textAlign: 'left',
  }),
  ...(textPosition === 'top-right' && {
    top: spacing,
    right: spacing,
    textAlign: 'right',
  }),
  ...(textPosition === 'center' && {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  }),

  // Responsive styles
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    padding: '6px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
    padding: '8px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.25rem',
    padding: '10px',
  },
}));
