export const VALID_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i;

export const VALID_TEN_DIGIT_PHONE = /^\d{10}$/;

export const VALID_NAME = /([a-zA-Z ]){2,30}$/;

export const VALID_USERNAME = /^[A-Za-z0-9_.]+$/;

export const ALPHA_NUMERIC = /^[a-z0-9]+$/i;

export const SPECIAL_CHARACTERS = /[ &/\\#, +()$~%.'":*?<>{}]/g;

export const SPACE_REGEX = /^\S*$/;

export const AT_LEAST_ONE_UPPER_CASE = /(?=.*?[A-Z])/;

export const AT_LEAST_ONE_LOWER_CASE = /(?=.*?[a-z])/;

export const AT_LEAST_ONE_DIGIT = /(?=.*?[0-9])/;

export const AT_LEAST_ONE_CHARACTER = /(?=.*?[#?!@$%^&*-])/;

/**
 * At least one lowercase letter, one upper case letter and one number with optional special characters
 */
export const VALID_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8}/;
