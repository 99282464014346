import {
  Box,
  Divider,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import get from 'lodash/get';
import { numberFormat } from '~utils/commonUtils';

const StyledRoot = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
  backgroundColor: theme.palette.primary.dark,
}));

const Title = styled(Typography)(({ theme }) => ({
  padding: `${theme.spacing(1.75)} 0`,
  backgroundColor: 'black',
  textTransform: 'uppercase',
}));

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  },
}));

const LabelText = styled(Typography)<{ fontSize?: number }>(
  ({ fontSize = 14, theme }) => ({
    [theme.breakpoints.up('lg')]: {
      fontSize: fontSize,
    },
    lineHeight: '22px',
    letterSpacing: 0.4,
    fontWeight: 300,
  }),
);

const DashedDivider = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 1,
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.light,
    top: 0,
    transform: 'translateY(-50%)',
  },
  '&::before': {
    left: -40,
  },
  '&::after': {
    right: -40,
  },
}));

const USER_KEYS: { label: string; value: string }[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Date',
    value: 'date',
  },
];

const ITEM_KEYS: { label: string; value: string }[] = [
  {
    label: 'Item',
    value: 'item',
  },
  {
    label: 'Price',
    value: 'price',
  },
  {
    label: 'Tax',
    value: 'tax',
  },
  {
    label: 'Fee',
    value: 'fee',
  },
];

type Props = StackProps & {
  data: {
    name: string;
    date: string;
    item: string;
    price: number;
    tax: number;
    fee: number;
    total: string;
  };
  title: string;
  titleProps?: TypographyProps;
};

const PaymentReceipt = ({ data, title, titleProps, ...rest }: Props) => {
  const renderKeys = (
    keys: { label: string; value: string }[],
    valueProps?: TypographyProps,
  ) => {
    return keys.map((item) => {
      const value = get(data, item.value, '');
      let displayValue = value;

      if (typeof value === 'number') {
        displayValue = `$${numberFormat(value || 0)}`;
      }

      return (
        <Stack
          key={item.value}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <LabelText fontSize={14}>{item.label}</LabelText>
          <Typography
            fontSize={{ lg: 14 }}
            lineHeight={'21px'}
            fontWeight={700}
            {...valueProps}
          >
            {displayValue}
          </Typography>
        </Stack>
      );
    });
  };

  return (
    <StyledRoot {...rest}>
      <Title variant="subtitle1" textAlign={'center'} {...titleProps}>
        {title}
      </Title>
      <Content>
        <Stack spacing={1.5}>{renderKeys(USER_KEYS)}</Stack>
        <DashedDivider sx={{ my: 3.5 }} />
        <Stack spacing={1.5}>
          {renderKeys(ITEM_KEYS, { fontWeight: '400' })}
        </Stack>
        <Divider sx={{ my: 1.5 }} />
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <LabelText fontSize={18}>TOTAL</LabelText>
          <Typography
            fontSize={{ lg: 20 }}
            lineHeight={'30px'}
            fontWeight={700}
          >
            ${numberFormat(data['total'] || 0)}
          </Typography>
        </Stack>
      </Content>
    </StyledRoot>
  );
};

export default PaymentReceipt;
