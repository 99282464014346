import React from 'react';
import { Box, ModalProps, styled, Typography } from '@mui/material';
import { ModalWindow } from '~components/atoms';
import PrimaryButton from '~components/atoms/primary-button';
import { ThinCrossSVG } from '~components/icons';

interface ResendEmailModalrops extends Omit<ModalProps, 'children'> {
  onClose: () => void;
  resendEmail: () => void;
}

const ResendEmailModal = ({
  resendEmail,
  open,
  onClose,
}: ResendEmailModalrops) => {
  return (
    <ModalWindow open={open} onClose={onClose}>
      <Wrapper>
        <WrapperCross>
          <ThinCrossSVG onClick={onClose} />
        </WrapperCross>
        <Title>Account Activation Required</Title>
        <SubTitle>You have not yet activated your account</SubTitle>
        <SubTitle>
          If you haven't received a confirmation email or need to resend a new
          one, please click on button below.
        </SubTitle>
        <PrimaryButton onClick={resendEmail} sx={{ marginTop: 1 }}>
          Resend your activation email
        </PrimaryButton>
      </Wrapper>
    </ModalWindow>
  );
};

export default ResendEmailModal;

const Wrapper = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 0',
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textAlign: 'center',
  textTransform: 'uppercase',
  whiteSpace: 'pre-line',
  marginBottom: 8,
  marginTop: 20,
  '&.MuiTypography-root': {
    fontSize: 16,
    fontWeight: 900,
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textAlign: 'center',
  textTransform: 'none',
  whiteSpace: 'pre-line',
  marginBottom: 8,
  '&.MuiTypography-root': {
    fontSize: 14,
  },
}));

const WrapperCross = styled(Box)(() => ({
  position: 'absolute',
  top: 16,
  right: 16,
}));
