import React from 'react';
import HeroLandingPage from '~components/organisms/hero-landing-page';
import { styled, Typography } from '@mui/material';
import AboutUrsa from './about-ursa';
import TechSupport from './tech-support';
import Community from './community';
import Rewards from './rewards';

const About = () => {
  return (
    <div>
      <HeroLandingPage
        title={<Title>URSA LIVE</Title>}
        subTitle={<SubTitle>ARTISTS, UNSCRIPTED</SubTitle>}
      />
      <AboutUrsa />
      <TechSupport />
      <Community />
      <Rewards />
    </div>
  );
};

export default About;

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  color: theme.palette.text.primary,
  fontWeight: 700,
  lineHeight: 1,
  [theme.breakpoints.up('sm')]: {
    fontSize: 40,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 60,
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  color: 'transparent',
  fontWeight: 700,
  WebkitTextStroke: `1px ${theme.palette.text.primary}`,
  lineHeight: 1,
  [theme.breakpoints.up('sm')]: {
    fontSize: 40,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 60,
  },
}));
