import React from 'react';
import { styled, Tab as TabMUI, TabProps } from '@mui/material';

const Tab = (props: TabProps) => {
  return <CustomTab {...props} />;
};

export default Tab;

const CustomTab = styled(TabMUI)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.subtitle2.fontSize,
  textTransform: 'uppercase',
  color: theme.palette.text.disabled,
  margin: '0 10px',
  padding: 0,
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',

  '&.Mui-selected': {
    color: theme.palette.text.primary,
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '14px',
    fontWeight: '900',
    margin: '0 20px',
    minWidth: 0,
  },
}));
