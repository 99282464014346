import { Dispatch } from 'react';
import { PaymentIntent } from '@stripe/stripe-js';
import { POPUP_TYPE } from '~utils';
import { getFanDetails, saveTransaction } from '~components';
import { LiveCoinOptionType, PaymentType, PaymentMethod } from '~types';
import { createIntent } from '~api';

const createPaymentIntent = async (productId: string) => {
  const response = await createIntent('card', productId);
  return response.data;
};

const handlePostPayment = async (
  paymentIntent: PaymentIntent,
  selectedLiveCoin: LiveCoinOptionType,
  dispatch: Dispatch<object>,
  user: object,
  paymentMethod: PaymentMethod,
  cardId?: string,
) => {
  await saveTransaction(
    paymentIntent.id,
    selectedLiveCoin.live_coins,
    PaymentType.STRIPE,
    selectedLiveCoin?.stripeProductId,
    paymentMethod,
    cardId,
  );
  await getFanDetails(dispatch, { user });
  dispatch({
    type: 'app',
    payload: { popup: POPUP_TYPE.THANK_POPUP },
  });
};

export { createPaymentIntent, handlePostPayment };
