import { LandingPageEvent } from '~types/landing-page';

export const generateArrayOfInitialSlides = (
  amount: number,
): LandingPageEvent[] => {
  return new Array(amount).fill('Loading').map((slide, index) => ({
    id: index.toString(),
    name: slide,
    artist_id: slide,
    artistUserName: slide,
    artistDisplayUserName: slide,
    userName: slide,
    imageUrl: '',
    genre: slide,
    scheduledAt: slide,
    status: slide,
    cancellationReason: slide,
    createdAt: slide,
    transactionAmount: slide,
    tipperQuantity: slide,
    allowedAttendees: slide,
    uniqueAttendees: slide,
    currentAttendees: slide,
    thumbnail: '',
    isFakeData: true,
  }));
};
