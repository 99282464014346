import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Stack, Typography, styled } from '@mui/material';
import { RootDispatch } from '~stores/store';
import { useLiveCoinOptions } from '~utils/paymentUtils';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { LiveCoinOptionType } from '~types';

const StyledLiveCoinItem = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
  borderRadius: theme.spacing(1),

  cursor: 'pointer',
  '&> .MuiTypography-root': {
    cursor: 'pointer',
  },
}));

const LiveCoinList = () => {
  const dispatch = useDispatch<RootDispatch>();
  const { showPaymentMethodsPopup } = useDispatchPopup();

  const handleSelectBuyCoin = (option: LiveCoinOptionType) => {
    dispatch.payment.setSelectedLiveCoin(option);
    showPaymentMethodsPopup();
  };

  const LIVECOIN_OPTIONS = useLiveCoinOptions();

  return (
    <Grid container spacing={1.75}>
      {LIVECOIN_OPTIONS.map((option) => (
        <Grid item xs={6} md={3} key={option.stripeProductId}>
          <StyledLiveCoinItem
            justifyContent={'center'}
            alignItems={'center'}
            onClick={() => handleSelectBuyCoin(option)}
          >
            <Typography
              color={'primary.main'}
              fontWeight={'700'}
              variant="body1"
              lineHeight={'18px'}
            >
              ${option.amount}
            </Typography>
            <Typography color={'primary.main'} variant="caption">
              {option.live_coins} lc
            </Typography>
          </StyledLiveCoinItem>
        </Grid>
      ))}
    </Grid>
  );
};

export default LiveCoinList;
