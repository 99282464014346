import React, { useState } from 'react';

import {
  Box,
  Stack,
  styled,
  Typography,
  Button,
  Grid,
  Divider,
  TextField,
} from '@mui/material';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  XIcon,
} from 'react-share';

import { isMobile } from 'react-device-detect';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import LinkIcon from '@mui/icons-material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import { CircleSuccessTickSVG, ShareProfileIcon, SMS } from '~components/icons';

import { showErrorToaster } from '~utils/toasterNotification';

const Title = styled(Typography)(() => ({
  textAlign: 'center',
  fontWeight: 600,
}));

const Description = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.subtitle1.fontSize,
  textAlign: 'center',
  color: theme.palette.text.primary,
  whiteSpace: 'pre-line',
}));

interface Props {
  onClose: () => void;
  isArtist?: boolean;
  artistName: string;
  showHeader?: boolean;
  onClickContact?: (contacts: IContact[]) => void;
  sectionTitle?: string;
}

const itemStyle = {
  backgroundColor: '#181C27',
  padding: '2px',
  borderRadius: '10px',
};

const buttonStyle = {
  color: 'white',
  textTransform: 'none',
  cursor: 'pointer',
};

const ShareProfile = ({
  onClose,
  artistName,
  isArtist = true,
  showHeader = false,
  onClickContact,
  sectionTitle = 'SHARE PROFILE',
}: Props) => {
  const [copied, setCopied] = useState(false);
  const supported = 'contacts' in navigator && 'ContactsManager' in window;

  const link = window.location.href;
  const title = isArtist
    ? 'Check me out and FOLLOW ME on Ursa Live!'
    : `Check out ${artistName} on Ursa Live!`;
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  const handleClickContacts = async () => {
    try {
      const props = ['tel'];
      const opts = { multiple: true };
      const navigator: ExtendedNavigator =
        window.navigator as ExtendedNavigator;
      const contacts: IContact[] = await navigator.contacts?.select(
        props,
        opts,
      );
      if (!contacts?.length) {
        return;
      }

      if (onClickContact) {
        onClickContact(contacts);
        return;
      } else {
        const contactsString = contacts.map((contact) => contact.tel).join(';');
        window.open(`sms:${contactsString}?&body=${title} ${link}`);
      }
    } catch (ex) {
      showErrorToaster();
    }
  };

  const onSMS = () => {
    window.open(`sms:?&body=${title} ${link}`);
  };

  const onMessengerMobile = () => {
    window.open(`fb-messenger://share?link=${link}`);
  };

  return (
    <>
      {showHeader && (
        <Stack alignItems="center" sx={{ marginTop: 3 }}>
          <CircleSuccessTickSVG />
          <Title sx={{ pt: 1.5, fontSize: { xs: 18, sm: 24 } }}>SUCCESS!</Title>
          <Description>Welcome to Ursa Live!</Description>
        </Stack>
      )}
      <Stack spacing={3}>
        <Box
          mt={showHeader ? 2 : 4}
          sx={{
            backgroundColor: '#151820',
            padding: '20px',
            borderRadius: '10px',
          }}
        >
          <Typography
            fontWeight={'600'}
            fontSize={{ xs: 16, sm: 22 }}
            variant="subtitle1"
            lineHeight={'27px'}
            align="center"
            width="100%"
            mb={2}
          >
            {sectionTitle}
          </Typography>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={6} sm={4}>
              <Box sx={itemStyle}>
                <EmailShareButton url={link} subject={title} body={title}>
                  <Button
                    sx={buttonStyle}
                    startIcon={<EmailIcon size={32} round />}
                  >
                    Email
                  </Button>
                </EmailShareButton>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box sx={itemStyle}>
                <FacebookShareButton url={link} title={title}>
                  <Button
                    sx={buttonStyle}
                    startIcon={<FacebookIcon size={32} round />}
                  >
                    Facebook
                  </Button>
                </FacebookShareButton>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box sx={itemStyle}>
                <TwitterShareButton url={link} title={title}>
                  <Button
                    sx={buttonStyle}
                    startIcon={<XIcon size={32} round />}
                  >
                    X/Twitter
                  </Button>
                </TwitterShareButton>
              </Box>
            </Grid>
            {isMobile && (
              <Grid item xs={6} sm={4}>
                <Box sx={itemStyle}>
                  <Button sx={buttonStyle} startIcon={<SMS />} onClick={onSMS}>
                    SMS
                  </Button>
                </Box>
              </Grid>
            )}

            <Grid item xs={6} sm={4}>
              <Box sx={itemStyle}>
                {isMobile ? (
                  <Button
                    sx={buttonStyle}
                    startIcon={<FacebookMessengerIcon size={32} round />}
                    onClick={onMessengerMobile}
                  >
                    Messenger
                  </Button>
                ) : (
                  <FacebookMessengerShareButton
                    url={link}
                    title={title}
                    appId="1880961495654658"
                  >
                    <Button
                      sx={buttonStyle}
                      startIcon={<FacebookMessengerIcon size={32} round />}
                    >
                      Messenger
                    </Button>
                  </FacebookMessengerShareButton>
                )}
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box sx={itemStyle}>
                <WhatsappShareButton url={link} title={title}>
                  <Button
                    sx={buttonStyle}
                    startIcon={<WhatsappIcon size={32} round />}
                  >
                    Whatsapp
                  </Button>
                </WhatsappShareButton>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box sx={itemStyle}>
                <LinkedinShareButton url={link} title={title}>
                  <Button
                    sx={buttonStyle}
                    startIcon={<LinkedinIcon size={32} round />}
                  >
                    Linkedin
                  </Button>
                </LinkedinShareButton>
              </Box>
            </Grid>
            {supported && (
              <Grid item xs={6} sm={4}>
                <Box sx={itemStyle}>
                  <Button
                    onClick={handleClickContacts}
                    sx={buttonStyle}
                    startIcon={<ShareProfileIcon />}
                  >
                    Contacts
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>

        <Divider>OR</Divider>

        <Box
          mt={5}
          sx={{
            backgroundColor: '#151820',
            padding: '20px',
            borderRadius: '10px',
          }}
        >
          <Typography
            fontWeight={'600'}
            fontSize={{ xs: 16, sm: 22 }}
            variant="subtitle1"
            lineHeight={'27px'}
            align="center"
            width="100%"
            mb={2}
          >
            COPY URL
          </Typography>
          <Grid container direction="row" spacing={3} alignItems="center">
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                value={link}
                fullWidth
                sx={{
                  '& .MuiInputBase-input': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        startIcon={<FileCopyOutlinedIcon />}
                        onClick={copyToClipboard}
                        sx={{ flexGrow: 1, textTransform: 'none' }}
                        variant="outlined"
                        color="warning"
                      >
                        {copied ? 'Copied!' : 'Copy'}
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Button color="warning" onClick={onClose} sx={buttonStyle}>
          Skip
        </Button>
      </Stack>
    </>
  );
};

export default ShareProfile;
