import { WrapperBlock } from '~components/manage-profile/components';
import { WrapperCampaign } from './components/common';
import CampaignItem from './components/campaign-item';
import CampaignTitle from './components/campaign-title';
import { RewardCampaign, RewardWinner } from '~types/reward';
import CampaignForm from '~components/manage-profile/tabs/rewards/components/campaign-form';
import React, { useEffect, useMemo, useState } from 'react';
import { styled, Typography, useTheme } from '@mui/material';
import { Button, DeleteModalWindow } from '~components/atoms';
import { isEqualWith, some } from 'lodash';
import { deleteRewardCampaign, updateRewardCampaign } from '~api/reward';
import { axiosRequest } from '~api/singleton-axios';
import { validateActiveRewardCampaign } from '~components/manage-profile/tabs/rewards/campaign-validation';
import { NotificationModalData } from '~components/manage-profile/tabs/rewards/type';
import { TrashIcon } from '~components/icons';

interface ActiveCampaignProps {
  campaign: RewardCampaign;
  refetchReward: () => void;
  showNotificationModal: (data: NotificationModalData) => void;
}

const ActiveCampaign = (props: ActiveCampaignProps) => {
  const theme = useTheme();
  const { refetchReward, showNotificationModal } = props;
  const [campaign, setCampaign] = useState<RewardCampaign>(
    props.campaign as RewardCampaign,
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [showDeleteCampaign, setShowDeleteCampaign] = useState(false);
  const isEditAble = useMemo(() => {
    return !some(props.campaign?.rewards, (reward) => {
      return !!reward.winnerId;
    });
  }, [props.campaign]);

  const isEdit = useMemo(() => {
    return !isEqualWith(
      campaign as RewardCampaign,
      props.campaign as RewardCampaign,
      (objValue, othValue) => {
        return (
          new Date(objValue.endedAt).getTime() ===
          new Date(othValue.endedAt).getTime()
        );
      },
    );
  }, [campaign, props.campaign]);

  const handlePropertyChange =
    (field: string) => async (value: string | Date) => {
      const newCampaign = { ...campaign, [field]: value };
      setCampaign(newCampaign);
    };

  const isValidCampaign = (campaign: RewardCampaign) => {
    const { errorFields, errors } = validateActiveRewardCampaign(campaign);

    setErrorFields(errorFields);
    setErrorMessages(errors);
    return errors.length === 0;
  };

  const saveCampaign = async () => {
    if (campaign && isValidCampaign(campaign)) {
      try {
        await updateRewardCampaign(campaign);
        await refetchReward();
        showNotificationModal({
          icon: undefined,
          open: true,
          title: 'Changes saved!',
          description: '',
        });
      } catch (err: unknown) {
        setErrorMessages([
          (axiosRequest.isAxiosError(err) && err.response?.data?.message) ||
            'Something went wrong. Try Again.',
        ]);
        await refetchReward();
      }
    }
  };

  const deleteCampaign = async () => {
    if (campaign?.id) {
      try {
        await deleteRewardCampaign(campaign.id);
        setShowDeleteCampaign(false);
        showNotificationModal({
          icon: (
            <TrashIcon
              fill={theme.palette.success.main}
              width={'56'}
              height={'56'}
            />
          ),
          open: true,
          title: 'Campaign Deleted',
          description: '',
        });
        await refetchReward();
      } catch (err: unknown) {
        setShowDeleteCampaign(false);
        setErrorMessages([
          (axiosRequest.isAxiosError(err) && err.response?.data?.message) ||
            'Something went wrong. Try Again.',
        ]);
        await refetchReward();
      }
    }
  };

  useEffect(() => {
    setCampaign(props.campaign);
  }, [props.campaign]);

  return (
    <WrapperBlock title={'Active Campaign'}>
      <WrapperCampaign sx={{ borderBottom: 'none' }}>
        <CampaignTitle
          title={`Campaign ${props.campaign.orderNumber}`}
          startAt={new Date(props.campaign.startedAt as string)}
          endAt={new Date(props.campaign.endedAt as string)}
          hasActive={true}
          handleDelete={
            isEditAble ? () => setShowDeleteCampaign(true) : undefined
          }
        />
        {isEditAble && (
          <>
            <CampaignForm
              disableStartTime={true}
              allowEditRewardContent={false}
              campaign={campaign}
              errorFields={errorFields || []}
              handleChange={(field) => handlePropertyChange(field)}
            />

            {errorMessages &&
              errorMessages.map((message, index) => {
                return <ErrorBox key={index}>{message}</ErrorBox>;
              })}
          </>
        )}
        <CampaignItem
          isActive={true}
          rewards={props.campaign.rewards as RewardWinner[]}
        />
        {isEditAble && isEdit && (
          <Button
            sx={{ display: 'block', marginTop: '4px' }}
            typeStyles={'big'}
            onClick={saveCampaign}
          >
            Save
          </Button>
        )}
      </WrapperCampaign>
      <DeleteModalWindow
        open={showDeleteCampaign}
        onClose={() => setShowDeleteCampaign(false)}
        title={'Are you sure you want to\ndelete this rewards campaign?'}
        onDelete={deleteCampaign}
      />
    </WrapperBlock>
  );
};

export default ActiveCampaign;

const ErrorBox = styled(Typography)(() => ({
  color: 'red',
}));
