import React from 'react';
import { Stack, Typography, alpha, styled } from '@mui/material';
import ListOfReports from './list-of-reports';
import InformationData from './information-data';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { getReportsData } from '~api';

const Reports = () => {
  const { data } = useQuery({
    queryKey: [QUERY_KEYS.REPORTS_DATA],
    queryFn: getReportsData,
  });

  return (
    <Stack spacing={2} width={'100%'}>
      <Stack spacing={2} direction={'row'} alignItems={'baseline'}>
        <Title>Reports</Title>
        <Note>Note: 10 lc = $1.00 USD</Note>
      </Stack>
      <InformationData
        attendeesPerEvent={data?.attendeesPerEvent ?? 0}
        revenuePerEvent={data?.revenuePerEvent ?? 0}
        totalAttendees={data?.totalAttendees ?? 0}
        totalRevenue={data?.totalRevenue ?? 0}
        totalFollowers={data?.totalFollowers}
      />
      <ListOfReports totalReports={data?.totalReports ?? 0} />
    </Stack>
  );
};

export default Reports;

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

Title.defaultProps = {
  variant: 'subtitle1',
  fontSize: {
    xs: 20,
  },
};

const Note = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.common.white, 0.4),
  fontWeight: '300',
}));

Note.defaultProps = {
  variant: 'caption',
};
