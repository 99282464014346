import {
  Title,
  Wrapper,
  WrapperTitle,
  FormRow,
  CustomInput,
  CustomTextarea,
} from './components';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import React, { ChangeEvent, useMemo } from 'react';
import { CopyIcon } from '~components/icons';
import { Genre } from '~types/artist';
import Select from '../../../atoms/select';
import { UserInfo } from '~stores';

interface ProfileInfoProps {
  profile: UserInfo;
  activeGenres: Genre[];
  setGenre: (genre: Genre | null) => void;
  changeStatusIsDataSaved: (status: boolean) => void;
  changeProperty: (
    name: string,
  ) => (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const DEFAULT_GENRE_SELECTION = {
  value: 'none',
  label: 'Choose Your Music Genre',
};

const ProfileInformation = (props: ProfileInfoProps) => {
  const {
    profile,
    activeGenres,
    setGenre,
    changeProperty,
    changeStatusIsDataSaved,
  } = props;

  const streamLink = `${window.location.protocol}//${window.location.host}/${profile.username}`;
  const selectedGenre = profile.genre?.id || 'none';

  const genreSelectList = useMemo(() => {
    const list = activeGenres.map((item) => {
      return { value: item.id, label: item.title };
    });
    list.unshift(DEFAULT_GENRE_SELECTION);
    return list;
  }, [activeGenres]);

  const onChangeGenre = (event: SelectChangeEvent<unknown>) => {
    changeStatusIsDataSaved(true);
    const genre = activeGenres.find((item) => item.id === event.target.value);
    setGenre(genre ?? null);
  };

  return (
    <Box>
      <WrapperTitle>
        <Title>Profile Information</Title>
        <Note>Change identifying details for your account</Note>
      </WrapperTitle>
      <Wrapper>
        <Grid container>
          <FormRow container item>
            <RequiredLabel item xs={12} sm={2}>
              Artist Name
            </RequiredLabel>
            <Grid item xs={12} sm={10}>
              <CustomInput
                size="small"
                fullWidth
                id="artist-name-input"
                value={profile.name}
                onChange={changeProperty('name')}
              />
            </Grid>
          </FormRow>
          <FormRow container item>
            <Grid item xs={12} sm={2}>
              Bio
            </Grid>
            <Grid item xs={12} sm={10}>
              <CustomTextarea
                minRows="3"
                maxRows="10"
                id="artist-name-input"
                value={profile.description}
                onChange={changeProperty('description')}
                placeholder="Enter Your Description..."
              />
            </Grid>
          </FormRow>
          <FormRow container item>
            <RequiredLabel item xs={12} sm={2}>
              Genre
            </RequiredLabel>
            <Grid item xs={12} sm={10}>
              <SelectGenre
                items={genreSelectList}
                value={selectedGenre}
                onChange={onChangeGenre}
              />
            </Grid>
          </FormRow>
          <FormRow container item sx={{ border: 'none' }}>
            <Grid item xs={12} sm={2}>
              Stream Link
            </Grid>
            <Grid item xs={12} sm={10}>
              <CustomInput
                size="small"
                fullWidth
                id="artist-name-input"
                value={streamLink}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ mr: 0 }}
                      aria-label="toggle copy link"
                      edge="end"
                      onClick={() => {
                        navigator.clipboard.writeText(streamLink);
                      }}
                    >
                      {<CopyIcon width={'14'} />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </FormRow>
        </Grid>
      </Wrapper>
    </Box>
  );
};

export default ProfileInformation;

const Note = styled(Box)(() => ({
  marginTop: '8px',
  fontSize: '12px',
  lineHeight: '20px',
}));

const SelectGenre = styled(Select)(({ theme }) => ({
  '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.action.active}`,
  },
}));

const RequiredLabel = styled(Grid)(() => ({
  '&:after': {
    content: '"*"',
    padding: '0 5px',
    color: 'red',
  },
}));
