import { useEffect, useState } from 'react';
import { ToastContent, toast } from 'react-toastify';
// @mui
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
// utils
import { strictValidString } from '~utils/commonUtils';
import {
  updateArtistNotification,
  updateArtistNotificationAll,
} from '~api/fan';
import { showErrorToaster } from '~utils/toasterNotification';
// components
import { CustomRadioGroup } from '~components/molecules/custom-input';
import CustomPopover from '~components/atoms/custom-popover';
// types
import { NotificationType } from '~types';
import { FanResponse } from '~types/fan';
//
import VerifyPhoneModal from './verify-otp-modal';
import { TextEllipsis } from '~components/atoms';

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  backgroundColor: theme.palette.grey[900],
  [theme.breakpoints.up('sm')]: {
    width: 360,
    margin: 'auto',
  },
}));

interface FollowingOption {
  label: string;
  name: string;
  icon?: React.ReactNode;
  value: NotificationType;
}

interface Props {
  open: HTMLElement | null;
  currentArtist: FanResponse | null;
  onClose: VoidFunction;
  onFollowCallback?: (artist: FanResponse) => void;
}

const FollowPopover = ({
  open,
  onClose,
  currentArtist,
  onFollowCallback,
}: Props) => {
  const [value, setValue] = useState<NotificationType>(NotificationType.SMS);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);

  const followingOptions: FollowingOption[] = [
    {
      label: 'SMS/Text',
      name: 'sms',
      icon: (
        <TextsmsOutlinedIcon
          sx={{
            width: 24,
            height: 24,
            color: (theme) => alpha(theme.palette.common.white, 0.7),
          }}
        />
      ),
      value: NotificationType.SMS,
    },
    {
      label: 'Email',
      name: 'email',
      icon: (
        <EmailOutlinedIcon
          sx={{
            width: 24,
            height: 24,
            color: (theme) => alpha(theme.palette.common.white, 0.7),
          }}
        />
      ),
      value: NotificationType.EMAIL,
    },
    // { label: 'No, thanks', name: 'nothing', value: NotificationType.NO_THANKS },
  ];

  const closeVerifyPhone = () => setShowVerifyPhone(false);

  const handleClose = () => {
    onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError('');
    setValue(event.target.value as NotificationType);
  };

  const handleFollow = async () => {
    try {
      setLoading(true);
      let res;
      if (currentArtist) {
        res = await updateArtistNotification({
          artistId: currentArtist?.id as string,
          notificationType: value,
        });
      } else {
        res = await updateArtistNotificationAll({ notificationType: value });
      }
      if (res.data.success) {
        toast(
          `Set notification for ${
            currentArtist
              ? `artist ${currentArtist.displayUsername}`
              : 'all artists'
          } successfully`,
          {
            type: 'success',
          },
        );
        setLoading(false);
        handleClose();
        onFollowCallback &&
          onFollowCallback({
            ...currentArtist,
            notificationType: value,
          } as FanResponse);
      } else {
        throw 'Set notification failed';
      }
    } catch (err) {
      setLoading(false);
      showErrorToaster(err as ToastContent<unknown>);
      handleClose();
    }
  };

  const handleSubmit = async () => {
    if (
      value === NotificationType.SMS &&
      currentArtist &&
      !currentArtist.phone
    ) {
      setShowVerifyPhone(true);
    } else {
      await handleFollow();
    }
  };

  useEffect(() => {
    if (currentArtist?.notificationType && open) {
      setValue(currentArtist?.notificationType);
    }
  }, [currentArtist?.notificationType, open]);

  return (
    <>
      <CustomPopover open={open} onClose={onClose}>
        <Wrapper>
          <Stack spacing={2.5}>
            {strictValidString(error) && (
              <Typography color="error.primary">{error}</Typography>
            )}

            <Box>
              <Typography
                fontWeight={'700'}
                fontSize={{ xs: 20 }}
                lineHeight={'30px'}
              >
                Notification Preferences for{' '}
                <TextEllipsis
                  text={
                    currentArtist
                      ? currentArtist.displayUsername
                      : 'all Artists'
                  }
                  fontWeight={'700'}
                  fontSize={{ xs: 20 }}
                  lineHeight={'30px'}
                  maxWidth={'unset'}
                />
              </Typography>
              <Typography
                lineHeight={'18px'}
                variant="caption"
                fontWeight={'200'}
                fontStyle={'italic'}
                fontSize={12}
                color={(theme) => alpha(theme.palette.common.white, 0.7)}
              >
                How would you like to be notified when an artist goes live?
              </Typography>
            </Box>
            <FormControl>
              <CustomRadioGroup
                name="follow-radio-group"
                value={value}
                onChange={handleChange}
                color="warning"
                options={followingOptions}
              />
            </FormControl>
            {!currentArtist && (
              <Box>
                <Divider />
                <Typography
                  fontWeight={'500'}
                  variant="body1"
                  align="center"
                  mt={1.25}
                >
                  Are you sure?
                </Typography>
              </Box>
            )}
            <Stack direction={'row'} spacing={1}>
              <Button
                fullWidth
                variant="outlined"
                color="warning"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="warning"
                onClick={handleSubmit}
                disabled={loading}
              >
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Wrapper>
      </CustomPopover>

      {showVerifyPhone && (
        <VerifyPhoneModal
          onFollow={handleFollow}
          onClose={closeVerifyPhone}
          open={true}
        />
      )}
    </>
  );
};

export default FollowPopover;
