import { useEffect, useState } from 'react';

const useInAppBrowserChecker = () => {
  const [isInApp, setIsInApp] = useState(false);

  useEffect(() => {
    const checkInAppBrowser = () => {
      const userAgent = navigator.userAgent;

      const inAppConditions = [
        /Instagram/.test(userAgent), // Instagram
        /FBAN|FBAV/.test(userAgent), // Facebook
        /Line/.test(userAgent), // Line
        /Wechat/.test(userAgent), // WeChat
        /AliApp/.test(userAgent), // Alipay
        /Snapchat/.test(userAgent), // Snapchat
        /TikTok/.test(userAgent), // TikTok
        /Telegram/.test(userAgent), // Telegram
        /Twitter|X11/.test(userAgent), // Twitter (X)
        /Shopee/.test(userAgent), // Shopee
        /Lazada/.test(userAgent), // Lazada
        /Pinterest/.test(userAgent), // Pinterest
        /Reddit/.test(userAgent), // Reddit
        /Discord/.test(userAgent), // Discord
        /Amazon/.test(userAgent), // Amazon
        /LinkedIn/.test(userAgent), // LinkedIn
        /Quora/.test(userAgent), // Quora
        /WhatsApp/.test(userAgent), // WhatsApp
      ];

      return inAppConditions.some((condition) => condition);
    };

    setIsInApp(checkInAppBrowser());
  }, []);

  return isInApp;
};

export default useInAppBrowserChecker;
