import React, { useState } from 'react';
import isUrl from 'is-url';
import { Stack, styled } from '@mui/material';
import { NoPhoto } from '~components/icons';
interface Props {
  src: string;
}

const Container = styled(Stack)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  justifyContent: 'center',
  alignItems: 'center',
  background: '#434343',
}));
export const ThumbNail = ({ src }: Props) => {
  const [error, setError] = useState(false);
  return (
    <Container>
      {isUrl(src) && !error ? (
        <img
          style={{ objectFit: 'cover' }}
          width="100%"
          height="100%"
          src={src}
          alt="photo"
          onError={() => setError(true)}
        />
      ) : (
        <NoPhoto />
      )}
    </Container>
  );
};
