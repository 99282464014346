import { postRequest } from '~utils/clientUtils';
import { inMemoryJWTService, parseJwt } from '~utils';
import { AuthProviderType } from '~types';

const checkAuthLogic = (token: string) => {
  if (!token) {
    return Promise.reject();
  }

  const decodedJwt = parseJwt(token);

  if (!decodedJwt || decodedJwt.exp * 1000 < Date.now()) {
    inMemoryJWTService.eraseToken();
    return Promise.reject();
  }

  return Promise.resolve();
};

export const authProvider: AuthProviderType = {
  login: async (username: string, password: string, otp?: string) => {
    try {
      const loginResponse = await postRequest({
        path: 'login',
        data: {
          username,
          password,
          totp: otp,
        },
      });
      const { accessToken, refreshToken, expiresIn } = loginResponse.data;
      inMemoryJWTService.setToken(accessToken, expiresIn);
      inMemoryJWTService.setRefreshToken(refreshToken);

      return Promise.resolve();
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message || 'Something went wrong. Try Again.';
      return Promise.reject(errorMessage);
    }
  },
  logout: () => {
    inMemoryJWTService.eraseToken();
    return Promise.resolve();
  },

  checkAuth: () => {
    const token = inMemoryJWTService.getToken();

    if (!token) {
      return inMemoryJWTService.getNewAccessToken().then(() => {
        const token = inMemoryJWTService.getToken();

        return checkAuthLogic(token as string);
      });
    }

    return checkAuthLogic(token);
  },
  getToken: () => inMemoryJWTService.getToken(),
};
