import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useUtmState } from '~providers/UtmProvider';

export interface TiktokEventContent {
  // string. ID of the product. Example: "1077218".
  content_id: string;
  // string. Either product or product_group.
  content_type?: string;
  // string. The name of the page or product. Example: "shirt".
  content_name: string;
}

export interface TiktokEventProperties {
  contents: TiktokEventContent[];
  // number. Value of the order or items sold. Example: 100.
  value?: number;
  // string. The 4217 currency code. Example: "USD".
  currency?: string;
  // string. The word or phrase used to search. Example: "SAVE10COUPON".
  query?: string;
}

const useTikTokEvent = () => {
  const w = window as any;
  const location = useLocation();
  const utmParams = useUtmState();

  const trackClickEvent = useCallback((clickEventName: string) => {
    const pagePath = location.pathname;
    const eventProperties: TiktokEventProperties = {
      contents: [
        {
          content_id: clickEventName,
          content_name: pagePath,
          content_type: `
            ${utmParams.source || 'no_source'} - ${
            utmParams.campaign || 'no_campaign'
          }
          `.trim(),
        },
      ],
    };

    if (w.ttq) {
      w.ttq.track('ClickButton', eventProperties);
    } else {
      console.warn('TikTok Pixel has not been loaded yet.');
    }
  }, []);

  const trackRegistrationEvent = useCallback(() => {
    const pagePath = location.pathname;

    if (utmParams.source === 'tiktok') {
      const eventProperties: TiktokEventProperties = {
        contents: [
          {
            content_id: 'Create Account',
            content_name: pagePath,
            content_type: `
              ${utmParams.source || 'no_source'} - ${
              utmParams.campaign || 'no_campaign'
            }
            `.trim(),
          },
        ],
      };
      if (w.ttq) {
        w.ttq.track('CompleteRegistration', eventProperties);
      } else {
        console.warn('TikTok Pixel has not been loaded yet.');
      }
    }
  }, []);

  return { trackClickEvent, trackRegistrationEvent };
};

export default useTikTokEvent;
