import { Dispatch } from 'react';
import { Buffer } from 'buffer';

import { IMessageEvent, w3cwebsocket } from 'websocket';
import { MessagingClient } from '~socket/messaging-client';
import { inMemoryJWTService } from '~utils/jwtUtils';
import { WEB_SOCKET_PING_INTERVAL } from '~utils';
import { WebSocketEvent } from '../types';
import { ASocketConnection } from '~socket/types/SocketConnection';

export class SocketConnection extends ASocketConnection {
  dispatch: Dispatch<unknown>;
  client: w3cwebsocket | null = null;
  session = '';
  messagingClient: MessagingClient | null = null;
  keepAliveFunc: NodeJS.Timer | null = null;
  userId: string | null;

  static createSocket(
    dispatch: Dispatch<unknown>,
    userId: string | null,
    callback: (socket: SocketConnection) => void = () => undefined,
  ) {
    const socket = new SocketConnection(dispatch, userId);
    socket.init(callback);
    return socket;
  }

  private constructor(dispatch: Dispatch<unknown>, userId: string | null) {
    super();
    this.userId = userId;
    this.dispatch = dispatch;
  }
  private init(callback: (socket: SocketConnection) => void) {
    const onReady = () => callback(this);
    this.messagingClient = new MessagingClient(this.dispatch);
    const token = inMemoryJWTService.getToken() || '';
    this.client = new w3cwebsocket(
      `${process.env.REACT_APP_SOCKET_API}/ws/${
        token ? Buffer.from(token).toString('base64') : 'anonymous'
      }`,
    );

    this.keepAliveFunc = setInterval(
      () => this.ping(),
      WEB_SOCKET_PING_INTERVAL,
    );

    this.client.onopen = () => {
      console.log('Socket: Websocket Client Opened!');
    };

    this.client.onmessage = (message: IMessageEvent) => {
      console.log('Socket: Message received: ', message);
      const data = JSON.parse(message.data.toString());
      this.messagingClient?.processMessage(data);
      if (data['event'] == 'WELCOME') {
        this.session = data['session'];
        onReady();
        console.log('Socket: set session: ', this.session);
      }
    };

    this.client.onclose = (event) => {
      console.log(
        `Socket: Closed connection to Socket Server. ${JSON.stringify(event)}`,
      );
      if (this.keepAliveFunc != null) {
        clearInterval(this.keepAliveFunc);
      }
    };

    this.client.onerror = (error) => {
      console.log('Socket: Error connecting to Socket Server.');
      const errorMessage = error?.message;
      if (errorMessage) {
        console.error(errorMessage);
      }
    };
  }

  sendPayload(payload: Record<string, unknown>) {
    console.log(this.client?.readyState);
    this.client?.send(JSON.stringify(payload));
  }

  currentSession(): string {
    return this.session;
  }

  getUserId() {
    return this.userId;
  }

  isClose() {
    if (!this.client) return true;
    return (
      this.client?.readyState === this.client?.CLOSED ||
      this.client?.readyState === this.client?.CLOSING
    );
  }

  close() {
    console.log('Socket: Close connection');
    this.client?.close();
  }

  public ping() {
    this.client?.send(
      JSON.stringify({
        event: WebSocketEvent.PING,
        username: 'ping',
      }),
    );
  }
  isConnecting(): boolean {
    throw new Error('Method not implemented.');
  }
}
