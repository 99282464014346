import React, { useState, useCallback } from 'react';
import {
  Box,
  ModalProps,
  OutlinedInput,
  Stack,
  styled,
  Typography,
  Button,
} from '@mui/material';
import { messages } from '~language/en';
import { Button as CustomButton, ModalWindow } from '~components/atoms';
import MuiPhoneNumber from 'material-ui-phone-number';

import { ThinCrossSVG } from '~components/icons';
import { useGetOTP } from '~components/custom-hook/useGetOTP';
import { isValidPhone } from '~utils/validateUtils';
import { verifyAuthPhone } from '~api';
import { showErrorToaster } from '~utils/toasterNotification';
import { isAxiosError } from 'axios';

const styles = {
  input: {
    backgroundColor: 'white',
    borderRadius: 2,
    height: 38,
    fontSize: 12,
    color: 'black',
    '.MuiInputBase-input:-webkit-autofill': {
      '-webkit-text-fill-color': 'black',
      '-webkit-box-shadow': '0 0 0 9999px white inset',
    },
    '.MuiInputBase-input:-webkit-autofill::selection': {
      '-webkit-text-fill-color': 'white',
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: 'black',
      fontWeight: 'normal',
    },
  },
};

interface VerifyPhoneModalProps extends Omit<ModalProps, 'children'> {
  onClose: () => void;
  onFollow: (phone: string) => void;
}

const VerifyPhoneModal = ({
  open,
  onClose,
  onFollow,
}: VerifyPhoneModalProps) => {
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [otp, setOtp] = useState('');
  const {
    resendOTPCountDown,
    showOTPInput,
    handleGetOTP: getOTP,
    intervalRef,
    RESEND_OTP_DELAY,
  } = useGetOTP();

  const handleGetOTP = async () => {
    if (!isValidPhone(phone)) {
      setError(messages.VALIDATIONS.INVALID_PHONE_NUMBER);
      return;
    }
    setError('');
    await getOTP(phone);
  };

  const handleClose = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    onClose();
  };

  const handlePhoneNumberChange = (
    phone: string,
    countryData: {
      countryCode: string;
    },
  ) => {
    setPhone(phone);
    setCountryCode(countryData.countryCode);
  };

  const handleChangeOTP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(event.target.value);
  };

  const handleSubmit = useCallback(async () => {
    if (!isValidPhone(phone)) {
      setError(messages.VALIDATIONS.INVALID_PHONE_NUMBER);
      return;
    }
    setError('');
    setIsSubmitting(true);

    try {
      await verifyAuthPhone(otp, phone, countryCode);
      setIsSubmitting(false);
      await onFollow(phone);
      onClose();
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        setError(err?.response?.data?.message);
        setIsSubmitting(false);
      } else {
        showErrorToaster();
        onClose();
      }
    }
  }, [countryCode, onClose, onFollow, otp, phone]);

  return (
    <ModalWindow open={open} onClose={handleClose}>
      <Wrapper>
        <WrapperCross>
          <ThinCrossSVG onClick={handleClose} />
        </WrapperCross>
        <Title>Verify Phone Number</Title>
        <Box display="flex" flexDirection="column" gap={1.5}>
          {error && <Typography color="red">{error}</Typography>}
          <MuiPhoneNumber
            sx={{
              ...styles.input,
              '.MuiInputBase-root': {
                backgroundColor: 'white',
                borderRadius: 8,
              },
              '.MuiInputBase-input': {
                color: 'black !important',
              },
              '&.MuiTextField-root': {
                height: '100%',
              },
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              '& svg': { height: '20px' },
            }}
            defaultCountry={'us'}
            preferredCountries={['us', 'vn']}
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={handlePhoneNumberChange}
            variant="outlined"
            fullWidth
            size="small"
            disableAreaCodes={true}
          />
          {showOTPInput && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems={'center'}
              gap={2}
            >
              <Box display={'flex'} flex={1}>
                <OutlinedInput
                  sx={styles.input}
                  size="small"
                  fullWidth
                  placeholder="Enter mobile verification code"
                  onChange={handleChangeOTP}
                  type="number"
                  value={otp}
                />
              </Box>
              <Button
                variant="outlined"
                sx={(theme) => ({
                  borderRadius: 2,
                  textTransform: 'capitalize',
                  background: 'white',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    background: '#d5e2ed',
                  },
                  '&.Mui-disabled': {
                    color: theme.palette.text.secondary,
                    opacity: 0.7,
                  },
                })}
                onClick={handleGetOTP}
                disabled={resendOTPCountDown !== RESEND_OTP_DELAY}
              >
                {resendOTPCountDown === RESEND_OTP_DELAY
                  ? 'Resend'
                  : `Resend (${resendOTPCountDown})`}
              </Button>
            </Box>
          )}
          <Stack direction="row" justifyContent="center">
            <SignUpButton
              disabled={isSubmitting}
              onClick={showOTPInput ? handleSubmit : handleGetOTP}
            >
              {showOTPInput ? 'NEXT' : 'GET OTP'}
            </SignUpButton>
          </Stack>
        </Box>
      </Wrapper>
    </ModalWindow>
  );
};

export default VerifyPhoneModal;

const Wrapper = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 0',
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textAlign: 'center',
  textTransform: 'uppercase',
  whiteSpace: 'pre-line',
  marginBottom: 8,
  marginTop: 20,
  '&.MuiTypography-root': {
    fontSize: 16,
    fontWeight: 900,
  },
}));

const WrapperCross = styled(Box)(() => ({
  position: 'absolute',
  top: 16,
  right: 16,
}));

const SignUpButton = styled(CustomButton)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
  padding: '7px 30px',
}));
