import { LiveCast } from './type';
import dayjs from 'dayjs';
import { errorTextEvents } from './constants';

export const createInitialLiveCast = (): LiveCast => ({
  id: new Date().getTime().toString(),
  date: null,
  time: null,
  scheduledAt: null,
  isEdited: false,
  isNew: true,
  errorMessageDate: null,
  errorMessageTime: null,
});

const hasError = (liveCasts: LiveCast[]) => {
  return liveCasts.some(
    ({ errorMessageDate, errorMessageTime }) =>
      errorMessageTime || errorMessageDate,
  );
};

export const setErrorIfDataBefore = (liveCasts: LiveCast[]) => {
  const newLiveCasts = liveCasts.map((liveCast) => {
    const isPast = dayjs().isBefore(dayjs(liveCast.scheduledAt));
    return {
      ...liveCast,
      errorMessageDate: isPast ? null : errorTextEvents.expired.date,
      errorMessageTime: isPast ? null : errorTextEvents.expired.time,
    };
  });
  return { newLiveCasts, hasError: hasError(newLiveCasts) };
};

export const setErrorIfEventsFilled = (liveCasts: LiveCast[]) => {
  const newLiveCasts = liveCasts.map((liveCast) => ({
    ...liveCast,
    errorMessageDate: liveCast.date ? null : errorTextEvents.empty.date,
    errorMessageTime: liveCast.time ? null : errorTextEvents.empty.time,
  }));
  return { newLiveCasts, hasError: hasError(newLiveCasts) };
};

export const setErrorIfRepeated = (liveCasts: LiveCast[]) => {
  const repeatingDates = liveCasts.reduce<Record<string, number>>(
    (acc, { scheduledAt }) => {
      if (!scheduledAt) return acc;
      acc[scheduledAt] = (acc[scheduledAt] || 0) + 1;
      return acc;
    },
    {},
  );

  const newLiveCasts = liveCasts.map((liveCast) => {
    const isRepeated =
      repeatingDates[dayjs(liveCast.scheduledAt).toISOString()] === 1;
    return {
      ...liveCast,
      errorMessageDate: isRepeated ? null : errorTextEvents.repeated.date,
      errorMessageTime: isRepeated ? null : errorTextEvents.repeated.time,
    };
  });
  return { newLiveCasts, hasError: hasError(newLiveCasts) };
};
