import * as yup from 'yup';
import {
  AT_LEAST_ONE_DIGIT,
  AT_LEAST_ONE_LOWER_CASE,
  AT_LEAST_ONE_UPPER_CASE,
  SPACE_REGEX,
  VALID_EMAIL,
  VALID_USERNAME,
} from '~utils/regex';

export type SignUpInputs = {
  artistName: string;
  username: string;
  email: string;
  password: string;
};

const baseSchema = yup.object({
  username: yup
    .string()
    .required('This is a required field!')
    .matches(SPACE_REGEX, 'Username cannot contain spaces')
    .matches(
      VALID_USERNAME,
      'Username can only contain letters (a-z or A-Z), numbers (0-9), dots (.), and underscores (_).',
    ),
  email: yup
    .string()
    .required('This is a required field!')
    .matches(VALID_EMAIL, 'Your email address is not valid'),
  password: yup
    .string()
    .required('This is a required field!')
    .matches(
      AT_LEAST_ONE_UPPER_CASE,
      'Your password must contain at least one uppercase letter',
    )
    .matches(
      AT_LEAST_ONE_LOWER_CASE,
      'Your password must contain at least one lowercase letter',
    )
    .matches(
      AT_LEAST_ONE_DIGIT,
      'Your password must contain at least one number',
    )
    .min(8, 'Your password must be at least 8 characters'),
});

// Sign Up
export const signUpSchema = (type: 'ARTIST' | 'FAN') =>
  type === 'FAN'
    ? baseSchema
    : baseSchema.concat(
        yup.object().shape({
          artistName: yup.string().required('This is a required field!'),
        }),
      );
