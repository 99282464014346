import { useGlobalState, USER_TYPE } from '~utils';

const useAccount = () => {
  const {
    user: { id, username, type, originalType },
  } = useGlobalState();
  const isLogin = !!id;
  const isViewer = type === USER_TYPE.FAN;
  const isHost = type === USER_TYPE.ARTIST;
  const isArtistAccount = originalType === USER_TYPE.ARTIST;
  const isFanAccount = originalType === USER_TYPE.FAN;
  return {
    isLogin,
    username,
    isViewer,
    isHost,
    type,
    userId: id,
    isArtistAccount,
    isFanAccount,
    originalType,
  };
};
export default useAccount;
