import { ManageProfileProvider } from '~providers/ManageProfileProvider';
// components
import { Container } from '~components/atoms';
import LandingPageWrapper from '~components/wrapppers/landing-page-wrapper';
import UnblockUsersView from '~components/pages/unblock-users';

const ArtistUnblockUsersPage = () => {
  return (
    <Container>
      <ManageProfileProvider>
        <LandingPageWrapper hideContact={true}>
          <UnblockUsersView />
        </LandingPageWrapper>
      </ManageProfileProvider>
    </Container>
  );
};
export default ArtistUnblockUsersPage;
