import { Stack, Typography, styled } from '@mui/material';

type InfoBlockProps = {
  title: string;
  amount: string;
};

const InfoBlock = ({ title, amount }: InfoBlockProps) => {
  return (
    <Container alignItems="center" justifyContent="center">
      <Title>{title}</Title>
      <Amount>{amount}</Amount>
    </Container>
  );
};

export default InfoBlock;

const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  background: '#343B50',
  borderRadius: 4,
  padding: 4,

  [theme.breakpoints.up('sm')]: {
    padding: 10,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Helvetica Neue',
  fontSize: `${theme.typography.subtitle1.fontSize} !important`,
  color: '#FFFFFF',
  fontWeight: 500,
}));

const Amount = styled(Typography)(({ theme }) => ({
  fontFamily: 'Helvetica Neue',
  fontSize: theme.typography.fontSize,
  color: '#FFFFFF',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h3.fontSize,
  },
}));
