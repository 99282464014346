import { ToastOptions, toast } from 'react-toastify';
import { toastConfig } from './constants';
import { isAxiosError } from 'axios';

export type ToastErrorOptions = {
  [K in keyof ToastOptions]?: ToastOptions[K];
} & ToastOptions;

export const showErrorToaster = (
  error: unknown = 'Something went wrong. Try Again.',
  config = toastConfig as ToastErrorOptions,
) => {
  if (typeof error === 'string') {
    toast(error, { type: 'error', ...config });
    return;
  }
  if (isAxiosError(error)) {
    const message = error.response?.data.message;
    toast(message, { type: 'error', ...config });
    return;
  }
};
