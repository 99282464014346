import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  styled,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { DatePicker, TimePicker } from '~components/atoms';
import { RewardCampaign } from '~types/reward';
import { TrashIcon } from '~components/icons';
import dayjs, { Dayjs } from 'dayjs';
import { combineDateAndTime } from '~utils/dateTimeUtils';

const DEFAULT_LABEL_LIST = [
  {
    placeName: '1st Place',
    placeholder: 'Highest Tipper',
  },
  {
    placeName: '2nd Place',
    placeholder: 'Second Highest Tipper',
  },
  {
    placeName: '3rd Place',
    placeholder: 'Third Highest Tipper',
  },
  {
    placeName: 'Random',
    placeholder: 'One Random Tipper',
  },
];

const DATE_TIME_ERROR_MESSAGES = {
  START_TIME: 'Start time is required',
  START_DATE: 'Start date is required',
  END_TIME: 'End time is required',
  END_DATE: 'End date is required',
};

function getCampaignTime(campaign: RewardCampaign) {
  return {
    startedAt: {
      time: campaign.startedAt ? dayjs(campaign.startedAt) : null,
      date: campaign.startedAt ? dayjs(campaign.startedAt) : null,
    },
    endedAt: {
      time: campaign.endedAt ? dayjs(campaign.endedAt) : null,
      date: campaign.endedAt ? dayjs(campaign.endedAt) : null,
    },
  } as CampaignTime;
}

interface DateTime {
  date: Dayjs | null;
  time: Dayjs | null;
}

interface CampaignTime {
  startedAt: DateTime;
  endedAt: DateTime;
  [key: string]: DateTime;
}

interface CampaignFormProps {
  campaign: RewardCampaign;
  errorFields: string[];
  handleChange?: (
    field: string,
    index?: number,
  ) => (value: string | Date) => void;
  deleteReward?: (index: number) => void;
  allowEditRewardContent?: boolean;
  disableStartTime?: boolean;
}

const CampaignForm = (props: CampaignFormProps) => {
  const {
    allowEditRewardContent = true,
    disableStartTime = false,
    campaign,
    errorFields,
    handleChange,
    deleteReward = () => undefined,
  } = props;
  const [campaignTime, setCampaignTime] = useState<CampaignTime>(
    getCampaignTime(campaign),
  );

  useEffect(() => {
    if (campaign) {
      // Reset campaign time whenever campaign is reset
      setCampaignTime(getCampaignTime(campaign));
    }
  }, [campaign.startedAt, campaign.endedAt, campaign]);

  const onChangeDateTime = (
    field: string,
    date: Dayjs | null,
    time: Dayjs | null,
  ) => {
    const currentValue = campaignTime[field];
    currentValue.date = date;
    currentValue.time = time;
    setCampaignTime((prevState) => ({
      ...prevState,
      [field]: currentValue,
    }));
    if (date && time) {
      const newDateTime = combineDateAndTime(date, time).toDate();
      handleChange && handleChange(field)(newDateTime);
    }
  };

  const onChangeReward =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      handleChange && handleChange('rewards', index)(event.target.value);
    };

  return (
    <Wrapper>
      <Grid container>
        <FormRow container item>
          <Grid item xs={12} sm={1}>
            <CustomLabel>Time</CustomLabel>
          </Grid>
          <Grid container item xs={12} sm={11} spacing={1}>
            <DateTimeWrapper item xs={12} sm={6}>
              <CustomLabel
                sx={{ mr: '8px', color: 'rgba(255, 255, 255, 0.6)' }}
              >
                From
              </CustomLabel>
              <DatePicker
                disabled={disableStartTime}
                value={campaignTime.startedAt.date}
                onChange={(value) => {
                  onChangeDateTime(
                    'startedAt',
                    value,
                    campaignTime.startedAt.time,
                  );
                }}
                errorMessage={
                  errorFields.includes('startedAt')
                    ? DATE_TIME_ERROR_MESSAGES.START_DATE
                    : ''
                }
                disablePast
                placeholder="Select Start Date"
              />
              <TimePicker
                disabled={disableStartTime}
                value={campaignTime.startedAt.time}
                onChange={(value) => {
                  onChangeDateTime(
                    'startedAt',
                    campaignTime.startedAt.date,
                    value,
                  );
                }}
                errorMessage={
                  errorFields.includes('startedAt')
                    ? DATE_TIME_ERROR_MESSAGES.START_TIME
                    : ''
                }
                placeholder="Select Start Time"
              />
            </DateTimeWrapper>
            <DateTimeWrapper item xs={12} sm={6}>
              <CustomLabel
                sx={{ mr: '8px', color: 'rgba(255, 255, 255, 0.6)' }}
              >
                To
              </CustomLabel>
              <DatePicker
                value={campaignTime.endedAt.date}
                onChange={(value) => {
                  onChangeDateTime('endedAt', value, campaignTime.endedAt.time);
                }}
                errorMessage={
                  errorFields.includes('endedAt')
                    ? DATE_TIME_ERROR_MESSAGES.END_DATE
                    : ''
                }
                disablePast
                placeholder="Select End Date"
              />
              <TimePicker
                value={campaignTime.endedAt.time}
                onChange={(value) => {
                  onChangeDateTime('endedAt', campaignTime.endedAt.date, value);
                }}
                errorMessage={
                  errorFields.includes('endedAt')
                    ? DATE_TIME_ERROR_MESSAGES.END_TIME
                    : ''
                }
                placeholder="Select End Time"
              />
            </DateTimeWrapper>
          </Grid>
        </FormRow>

        {allowEditRewardContent &&
          campaign.rewards?.map((reward, index) => {
            return (
              <FormRow key={index} container item>
                <Grid item xs={12} sm={1}>
                  <CustomLabel>
                    {reward.isRandom
                      ? DEFAULT_LABEL_LIST[3].placeName
                      : DEFAULT_LABEL_LIST[index].placeName}
                  </CustomLabel>
                </Grid>
                <Grid item xs={12} sm={11}>
                  <CustomInput
                    size="small"
                    fullWidth
                    value={reward.rewardName}
                    onChange={onChangeReward(index)}
                    placeholder={
                      reward.isRandom
                        ? DEFAULT_LABEL_LIST[3].placeholder
                        : DEFAULT_LABEL_LIST[index].placeholder
                    }
                    endAdornment={
                      campaign.rewards.length > 1 ? (
                        <InputAdornment position="end">
                          <IconButton
                            sx={{ mr: '10px' }}
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() => deleteReward(index + 1)}
                          >
                            <TrashIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : undefined
                    }
                  />
                </Grid>
              </FormRow>
            );
          })}
      </Grid>
    </Wrapper>
  );
};

export default CampaignForm;

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 2,
}));

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '4px',
  height: 40,
  fontSize: theme.typography.subtitle1.fontSize,
  padding: '5px 0px',

  color: 'white',
  '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.action.active,
  },
}));

const FormRow = styled(Grid)(() => ({
  padding: '8px 0',
}));

const DateTimeWrapper = styled(Grid)(() => ({
  display: 'inline-flex',

  '& > .MuiBox-root:nth-of-type(1)': {
    marginRight: '8px',
  },
}));

const CustomLabel = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));
