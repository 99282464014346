import React from 'react';
import { useEffect, useState } from 'react';

import { useGlobalDispatch } from '~utils/container';
import { confirmChangeEmail } from '~api/user';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Stack, Typography } from '@mui/material';
import { USER_TYPE } from '~utils';

const ConfirmUpdateEmail = () => {
  const { key } = useParams();
  const navigate = useNavigate();
  const dispatch = useGlobalDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    confirmChangeEmail(key)
      .then(({ data: user }) => {
        const { type, username } = user;

        setIsSuccess(true);
        setTimeout(() => {
          if (type === USER_TYPE.ARTIST) {
            window.location.href = `/${username}`;
          } else {
            window.location.href = '/';
          }
        }, 3000);
      })
      .catch((e) => {
        const errorMessage =
          e?.response?.data?.message ?? 'Something went wrong. Try Again.';
        setErrorMessage(errorMessage);
      });
  }, [dispatch, key, navigate]);

  if (errorMessage) {
    return (
      <Container>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%' }}
        >
          <Typography variant="h4">{errorMessage}</Typography>
        </Stack>
      </Container>
    );
  }

  return (
    <Container>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        {isSuccess && (
          <Typography variant="h4"> Changed email successfully.</Typography>
        )}
      </Stack>
    </Container>
  );
};

export default ConfirmUpdateEmail;
