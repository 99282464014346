import React, { useEffect, useMemo, useState } from 'react';
import { Box, styled } from '@mui/material';
import { Button } from '~components/atoms';
import { ThinCrossSVG } from '~components/icons';
import { WrapperBlock, InternalIndentBlock } from '../../components';
import { LiveCast, ModalWindowType } from './type';
import ModalWindowsLiveCasts from './modal-windows';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteEvent, getUserEvents, saveEvents } from '~api';
import LiveCastRow from './livecast-row';
import { combineDateAndTime } from '~utils/dateTimeUtils';
import { useGlobalState } from '~utils';
import { EditEventParameter } from '~types/event';
import dayjs, { Dayjs } from 'dayjs';
import { QUERY_KEYS } from '~constants/query-keys';
import { AMOUNT_OF_POSSIBLE_LIVE_CASTS_IN_ONE_TIME } from './constants';
import {
  createInitialLiveCast,
  setErrorIfDataBefore,
  setErrorIfEventsFilled,
  setErrorIfRepeated,
} from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState, select } from '~stores';
import useArtistEvent from '~components/custom-hook/useArtistEvent';

interface AddLiveCastsProps {
  changeStatusIsDataSaved: (status: boolean) => void;
}

const AddLiveCasts = ({ changeStatusIsDataSaved }: AddLiveCastsProps) => {
  const { user } = useGlobalState();
  const client = useQueryClient();
  const [activeLiveCast, setActiveLiveCast] = useState<LiveCast | null>(null);
  const [liveCasts, setLiveCasts] = useState<LiveCast[]>([]);
  const [windowType, setWindowType] = useState<ModalWindowType>(null);
  const dispatch = useDispatch<RootDispatch>();
  const { fetchUpComingEvent } = useArtistEvent();
  const userId = useSelector((state: RootState) =>
    select.roomInfo.getArtistId(state),
  );
  const { status } = useQuery({
    queryKey: [QUERY_KEYS.ARTIST_EVENTS],
    queryFn: () => getUserEvents(user.id),
    enabled: !!user.id,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const addedLiveCasts = liveCasts.filter(({ isNew }) => isNew);
      const newLiveCasts = data.upcoming.map(({ event }) => ({
        id: event.id,
        scheduledAt: dayjs(event.scheduledAt).toISOString(),
        time: dayjs(event.scheduledAt),
        date: dayjs(event.scheduledAt),
        isEdited: false,
        isNew: false,
        errorMessageDate: null,
        errorMessageTime: null,
      }));
      setLiveCasts([...newLiveCasts, ...addedLiveCasts]);
    },
  });

  const { mutate: deleteEventRequest, isLoading: isLoadingDelete } =
    useMutation({
      mutationFn: (id: string) => deleteEvent(id),
      onSuccess: async () => {
        client.invalidateQueries({ queryKey: [QUERY_KEYS.ARTIST_EVENTS] });
        if (userId) {
          dispatch.artist.fetchArtistEvents(userId);
          await fetchUpComingEvent();
        }
        openModalWindow('delete');
      },
    });

  const { mutate: saveEventsRequest } = useMutation({
    mutationFn: ({
      newEvents,
      updatedEvents,
    }: {
      newEvents: { scheduled_at: string }[];
      updatedEvents: EditEventParameter[];
    }) => saveEvents(newEvents, updatedEvents),
    onSuccess: async () => {
      client.invalidateQueries({ queryKey: [QUERY_KEYS.ARTIST_EVENTS] });
      if (userId) {
        dispatch.artist.fetchArtistEvents(userId);
        await fetchUpComingEvent();
      }
      openModalWindow('save');
    },
  });

  const hasEdit = useMemo(() => {
    return liveCasts.some(({ isNew, isEdited }) => isNew || isEdited);
  }, [liveCasts]);

  const openModalWindow = (type: ModalWindowType) => setWindowType(type);
  const closeModalWindow = () => setWindowType(null);

  const changeLiveCast = (
    date: Dayjs | null,
    time: Dayjs | null,
    id: string,
  ) => {
    const scheduledAt =
      date && time ? combineDateAndTime(date, time).toISOString() : null;
    setLiveCasts(
      liveCasts.map((liveCast) =>
        id === liveCast.id
          ? {
              ...liveCast,
              scheduledAt,
              isEdited: !liveCast.isNew,
              date,
              time,
            }
          : liveCast,
      ),
    );
  };

  const deleteLiveCast = () => {
    if (!activeLiveCast) return;
    if (activeLiveCast.isNew) {
      setLiveCasts(
        liveCasts.filter((liveCast) => liveCast.id !== activeLiveCast.id),
      );
      openModalWindow('delete');
      return;
    }
    activeLiveCast && deleteEventRequest(activeLiveCast.id);
  };
  const addLiveCast = () =>
    setLiveCasts([...liveCasts, createInitialLiveCast()]);

  const saveActiveLiveCastOpenWindow = (liveCast: LiveCast) => {
    setActiveLiveCast(liveCast);
    openModalWindow('deleteQuestion');
  };

  const checkIfEventsFilled = () => {
    const { newLiveCasts, hasError } = setErrorIfEventsFilled(liveCasts);
    setLiveCasts(newLiveCasts);
    return hasError;
  };

  const checkIfRepeated = () => {
    const { newLiveCasts, hasError } = setErrorIfRepeated(liveCasts);
    setLiveCasts(newLiveCasts);
    return hasError;
  };

  const checkIfDataBefore = () => {
    const { newLiveCasts, hasError } = setErrorIfDataBefore(liveCasts);
    setLiveCasts(newLiveCasts);
    return hasError;
  };

  const saveLiveCasts = async () => {
    if (!hasEdit) return;

    if (checkIfEventsFilled()) return;
    if (checkIfRepeated()) return;
    if (checkIfDataBefore()) return;

    const updatedEvents = liveCasts
      .filter(({ isEdited }) => isEdited)
      .map(({ id, scheduledAt }) => ({
        event_id: id,
        scheduled_at: scheduledAt as string,
      }));
    const newEvents = liveCasts
      .filter(({ isNew }) => isNew)
      .map(({ scheduledAt }) => ({
        scheduled_at: scheduledAt as string,
      }));
    setLiveCasts(liveCasts.map((liveCast) => ({ ...liveCast, isNew: false })));
    saveEventsRequest({ newEvents, updatedEvents });
  };
  useEffect(() => {
    const isChanged = liveCasts.some(
      ({ isNew, isEdited }) => isNew || isEdited,
    );
    changeStatusIsDataSaved(isChanged);
  }, [changeStatusIsDataSaved, liveCasts]);

  return (
    <Container>
      <ModalWindowsLiveCasts
        windowType={windowType}
        closeModalWindow={closeModalWindow}
        deleteLivecast={deleteLiveCast}
        disabledActionButton={isLoadingDelete}
      />
      <WrapperBlock
        title={'Upcoming Livecasts'}
        description={
          'Enter your upcoming livecasts so that your fans can download calendar invites.'
        }
      >
        {status === 'loading' && (
          <InternalIndentBlock>Loading...</InternalIndentBlock>
        )}
        {status === 'error' && <InternalIndentBlock>Error</InternalIndentBlock>}
        {status === 'success' && (
          <>
            {liveCasts.map(({ id, ...rest }, index) => (
              <LiveCastRow
                key={id}
                index={index}
                id={id}
                {...rest}
                deleteLiveCast={() =>
                  saveActiveLiveCastOpenWindow({
                    id,
                    ...rest,
                  })
                }
                changeLiveCast={(date, time) => changeLiveCast(date, time, id)}
              />
            ))}
            <AddLiveCastButton
              onClick={addLiveCast}
              startIcon={<ThinCrossSVG />}
              disabled={
                liveCasts.length >= AMOUNT_OF_POSSIBLE_LIVE_CASTS_IN_ONE_TIME
              }
            >
              Add Livecast
            </AddLiveCastButton>
          </>
        )}
      </WrapperBlock>
      <Button
        sx={{ textTransform: 'uppercase' }}
        typeStyles={'big'}
        onClick={saveLiveCasts}
      >
        Save
      </Button>
    </Container>
  );
};

export default AddLiveCasts;

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  [theme.breakpoints.up('sm')]: {
    gap: 40,
  },
}));

const AddLiveCastButton = styled(Button)(({ theme }) => ({
  margin: '20px auto',
  gap: 4,
  svg: {
    width: 10,
    transform: ' rotate(45deg)',
    path: {
      fill: theme.palette.background.default,
    },
  },
  padding: '8px 32px',
  [theme.breakpoints.up('md')]: {
    padding: 'auto',
  },
}));
