import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGlobalState } from '~utils/container';
import {
  getCurrentDate,
  getCurrentTime,
  isSameOrBefore,
  plusMinutes,
} from '~utils/dateTimeUtils';
import { select } from '~stores';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideClicked = (ref, callBack) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
/**
 * Hook that alerts clicks outside of the passed ref
 */
export const getWindowDimensions = () => {
  const { innerWidth: width = 0, innerHeight: height = 0 } = window || {};
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

/**
 * Hook that alerts clicks outside of the passed ref
 */

export const useCaptcha = () => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const onCaptchaVerified = (token) => {
    setCaptchaVerified(true);
    setCaptchaToken(token);
  };

  const onCaptchaExpired = () => {
    setCaptchaToken(null);
    setCaptchaVerified(false);
  };

  const captchaRef = useRef();

  return {
    captchaVerified,
    onCaptchaVerified,
    onCaptchaExpired,
    setCaptchaVerified,
    captchaRef,
    captchaToken,
    setCaptchaToken,
  };
};

const EVENT_FILTER_START_TIME_MINUTES = -15;
const REFRESH_INTERVAL_IN_MINUTES = 60000;

export const useFilterUpcomingLiveCast = (
  filterStartTimeInMinutes = EVENT_FILTER_START_TIME_MINUTES,
) => {
  const state = useGlobalState();
  const {
    artist: { events },
  } = state;
  const [filterEvents, setFilterEvents] = useState(events);

  const filterUpcomingEvent = useCallback(() => {
    const filterDate = plusMinutes(getCurrentTime(), filterStartTimeInMinutes);
    setFilterEvents(
      events.filter((event) =>
        isSameOrBefore(filterDate, getCurrentDate(event.event.scheduledAt)),
      ),
    );
  }, [events, filterStartTimeInMinutes]);

  useEffect(() => {
    filterUpcomingEvent();
    const interval = setInterval(() => {
      filterUpcomingEvent();
    }, REFRESH_INTERVAL_IN_MINUTES);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [filterUpcomingEvent]);

  return filterEvents;
};

export const useEventLiveCast = (
  filterStartTimeInMinutes = EVENT_FILTER_START_TIME_MINUTES,
) => {
  const events = useSelector((state) => select.artist.getEvents(state));
  const [filteredEvents, setFilteredEvents] = useState(events);

  const filterUpcomingEvent = useCallback(() => {
    const filterDate = plusMinutes(getCurrentTime(), filterStartTimeInMinutes);
    setFilteredEvents(
      events.filter((event) =>
        isSameOrBefore(filterDate, getCurrentDate(event.event.scheduledAt)),
      ),
    );
  }, [events, filterStartTimeInMinutes]);

  useEffect(() => {
    filterUpcomingEvent();
    const interval = setInterval(() => {
      filterUpcomingEvent();
    }, REFRESH_INTERVAL_IN_MINUTES);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [filterUpcomingEvent]);

  return filteredEvents;
};

export const useTriggerTimeout = (callback, delay) => {
  const savedCallback = useRef(callback);
  const timeoutRef = useRef(0);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const startTimeout = useCallback(() => {
    clearTimeout(timeoutRef.current);
    const id = setTimeout(() => savedCallback.current(), delay);
    timeoutRef.current = id;
  }, [delay]);

  const stopTimeout = useCallback(() => {
    clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  return {
    startTimeout,
    stopTimeout,
  };
};
