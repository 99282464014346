import { useFormContext, Controller } from 'react-hook-form';
import {
  Box,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
  alpha,
} from '@mui/material';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  labelColor?: string;
  labelSx?: SxProps;
};
const Asterisk = () => {
  return (
    <Box component={'span'} sx={{ color: 'error.light' }}>
      {' '}
      *
    </Box>
  );
};

export default function RHFTextField({
  required,
  label,
  labelColor,
  labelSx,
  name,
  helperText,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Box width={'100%'}>
          <Typography
            gutterBottom
            component={'label'}
            variant="caption"
            sx={{
              fontSize: 12,
              fontWeight: '500',
              marginBottom: '10px',
              color: (theme) =>
                labelColor ?? alpha(theme.palette.common.white, 0.5),
              ...labelSx,
            }}
          >
            {label}
            {required && <Asterisk />}
          </Typography>
          <TextField
            {...field}
            fullWidth
            size="small"
            value={
              typeof field.value === 'number' && field.value === 0
                ? ''
                : field.value
            }
            inputRef={ref}
            error={!!error}
            helperText={error ? error?.message : helperText}
            {...other}
            sx={(theme) => ({
              '& .MuiOutlinedInput-root.Mui-focused fieldset': {
                borderColor: theme.palette.action.active,
              },
              '& .MuiFormHelperText-root.Mui-error': {
                fontSize: 14,
              },
            })}
          />
        </Box>
      )}
    />
  );
}
