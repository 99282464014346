import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Tabs(theme: Theme) {
  return {
    MuiTabs: {
      defaultProps: {
        textColor: 'inherit',
        allowScrollButtonsMobile: true,
        variant: 'scrollable',
      } as const,
      styleOverrides: {
        root: {
          minHeight: 33,
        },
        scrollButtons: {
          width: 48,
          borderRadius: '50%',
        },
        indicator: {
          height: 4,
          bottom: 0,
          backgroundColor: theme.palette.action.active,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        iconPosition: 'start',
      } as const,
      styleOverrides: {
        root: () => ({
          padding: 0,
          opacity: 1,
          minWidth: 48,
          minHeight: 33,
          alignItems: 'flex-start',
          fontWeight: 500,
          textTransform: 'capitalize' as const,
          '&:not(.Mui-selected)': {
            color: '#FFFFFF66',
          },
        }),
      },
    },
  };
}
