import React, { useContext, useState } from 'react';
// utils
import { FEATURE_FLAGS_TYPE, useGlobalState } from '~utils';
import { getArtistFeedbackUrl } from '~utils/configUtils';
// types
import { ReportView } from '~types/event';
//
import SurveyEndLiveModal from '../survey-end-live-modal';
import ReportLiveModal from './report-live-modal';
import ClearOptionModal from './clear-option-modal';
import AddLivecastModal from '~components/organisms/popup/end-live-modal/artist-end-live-modal/add-livecast-modal';
import { ManageProfileContext } from '~providers/ManageProfileProvider';
import { TABS } from '~components/manage-profile/types';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import AddVideoToProfileModal from './add-video-to-profile-modal';

const enum ArtistEndLiveStep {
  END_LIVE,
  REPORT,
  CLEAR_OPTIONS,
  SURVEY,
  ADD_LIVECAST,
  ADD_VIDEO_TO_PROFILE,
}

interface Props {
  reportData?: ReportView;
  onClose: () => void;
  onClear: (
    isClearChat: boolean,
    isClearQuestion: boolean,
    callback: () => void,
  ) => Promise<void>;
}

const REPORT_DEFAULT_DATA: ReportView = {
  id: '',
  name: '',
  startedAt: '',
  endedAt: '',
  tipEarned: 0,
  totalAttendees: 0,
  totalFollowers: 0,
};
const ArtistEndLiveModal = ({ reportData, onClose, onClear }: Props) => {
  const { isEnable } = useFeatureFlag();
  const isEnableArchivedVideo = isEnable(
    FEATURE_FLAGS_TYPE.ARCHIVED_VIDEO_FLOW,
  );
  const globalState = useGlobalState();
  const { openManageProfile } = useContext(ManageProfileContext);

  const {
    app: { questionsList, chatList },
    user: { showAddVideoToProfilePopup },
  } = globalState;

  const [step, setStep] = useState<ArtistEndLiveStep>(ArtistEndLiveStep.REPORT);

  const goToClearOption = () => setStep(ArtistEndLiveStep.CLEAR_OPTIONS);
  const goFanSurvey = () => setStep(ArtistEndLiveStep.SURVEY);
  const goToAddLivecast = () => setStep(ArtistEndLiveStep.ADD_LIVECAST);
  const goToAddVideo = () => setStep(ArtistEndLiveStep.ADD_VIDEO_TO_PROFILE);

  const handleReportNext = () => {
    if (!chatList?.length && !questionsList?.length) {
      if (isEnableArchivedVideo) {
        goToAddVideo();
      } else {
        goFanSurvey();
      }
    } else {
      if (isEnableArchivedVideo) {
        goToAddVideo();
      } else {
        goToClearOption();
      }
    }
  };

  const handleAddVideoNext = () => {
    if (!chatList?.length && !questionsList?.length) {
      goFanSurvey();
    } else {
      goToClearOption();
    }
  };

  const handleClear = async (
    isClearChat = false,
    isClearQuestion = false,
  ): Promise<void> => {
    await onClear(isClearChat, isClearQuestion, goFanSurvey);
  };

  const onSurvey = async () => {
    const feedbackUrl = getArtistFeedbackUrl();
    window.open(feedbackUrl, '_blank');
    goToAddLivecast();
  };

  const onAddLivecast = async () => {
    openManageProfile({ initialTab: TABS.ADD_LIVECASTS });
    onClose();
  };

  return (
    <>
      {step === ArtistEndLiveStep.REPORT && (
        <ReportLiveModal
          open={true}
          onClose={() => undefined}
          reportData={reportData ?? REPORT_DEFAULT_DATA}
          onNext={handleReportNext}
        />
      )}
      {step === ArtistEndLiveStep.CLEAR_OPTIONS && (
        <ClearOptionModal
          open={true}
          onClose={() => undefined}
          onClear={handleClear}
          onSkip={goFanSurvey}
        />
      )}
      {step === ArtistEndLiveStep.SURVEY && (
        <SurveyEndLiveModal
          title={'ARTIST SURVEY'}
          open={true}
          onSkip={goToAddLivecast}
          onSurvey={onSurvey}
        />
      )}
      {step === ArtistEndLiveStep.ADD_LIVECAST && (
        <AddLivecastModal
          open={true}
          onSkip={onClose}
          onAddLivecast={onAddLivecast}
        />
      )}
      {step === ArtistEndLiveStep.ADD_VIDEO_TO_PROFILE && (
        <AddVideoToProfileModal
          eventId={reportData?.id}
          onNext={handleAddVideoNext}
          showAddVideoToProfilePopup={showAddVideoToProfilePopup}
        />
      )}
    </>
  );
};

export default ArtistEndLiveModal;
