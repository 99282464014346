import React from 'react';
import { QUERY_KEYS } from '~constants/query-keys';
import { useQuery } from '@tanstack/react-query';

import { getBannerEventInfo } from '~api/event-banners';
import { useParams } from 'react-router-dom';
import { Box, Stack, styled, Typography, Grid } from '@mui/material';

import banner from '~images/event-page/banner.png';
import bannerMobile from '~images/event-page/banner-mobile.png';
import ursaIcon from '~images/event-page/ursaicon.png';
import { EventItem } from '~components/pages/event/EventItem';
import DarkSphere from '~images/decorations-landing-page/dark-sphere.png';
import useBreakpoints from '~hooks/useBreakpoints';
import { getImageURL } from '~utils/awsUtils';

const Event = () => {
  const { isSm } = useBreakpoints();
  const { eventId } = useParams();
  const { data: event } = useQuery({
    queryKey: [QUERY_KEYS.EVENT_PAGE_EVENT],
    enabled: !!eventId,
    queryFn: () => getBannerEventInfo(eventId as string),
    refetchOnWindowFocus: false,
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <Container>
        <SphereContainer>
          <SphereContainerLeft>
            <SphereRight src={DarkSphere} alt="sphere" />
          </SphereContainerLeft>
          <SphereContainerRight>
            <SphereLeft src={DarkSphere} alt="sphere" />
          </SphereContainerRight>
        </SphereContainer>
        <BannerImage src={isSm ? banner : bannerMobile} />
        <ContainContainer>
          <URSALogo src={ursaIcon} />

          <HeaderContainer>
            <HeaderText shadowText={event?.eventBanner?.header || ''}>
              {event?.eventBanner?.header || ''}
            </HeaderText>
            {event?.eventBanner?.subHeader && (
              <SubHeaderText>
                {event?.eventBanner.subHeader || ''}
              </SubHeaderText>
            )}
            {event?.eventBanner?.timeInfo && (
              <DateTimeText>{event?.eventBanner.timeInfo || ''}</DateTimeText>
            )}
            {event?.eventBanner?.description && (
              <DescriptionText>
                {event?.eventBanner.description || ''}
              </DescriptionText>
            )}
          </HeaderContainer>
          {!!event?.eventBanner?.upcomingEvents?.length && (
            <CardContainer container>
              {event.eventBanner.upcomingEvents.map(
                ({
                  id,
                  artistUserName,
                  userName,
                  artistDisplayUserName,
                  imageUrl,
                  scheduledAt,
                  genre,
                }) => (
                  <EventItem
                    key={id}
                    username={artistUserName}
                    artistName={
                      userName || artistDisplayUserName || artistUserName
                    }
                    imageUrl={getImageURL(imageUrl)}
                    genre={genre}
                    scheduledAt={scheduledAt}
                  />
                ),
              )}
            </CardContainer>
          )}
        </ContainContainer>
      </Container>
    </Box>
  );
};

export default Event;

const Container = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  position: 'relative',
  '&::before': {
    width: 0,
    content: '""',
    paddingTop: '76%',
  },
  [theme.breakpoints.down('md')]: {
    '&::before': {
      paddingTop: '200%',
    },
  },
}));

const ContainContainer = styled(Box)(({ theme }) => ({
  marginTop: 100,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  maxWidth: 950,
  [theme.breakpoints.down('md')]: {
    margin: '0 20px',
    marginTop: 50,
  },
}));

const CardContainer = styled(Grid)(({ theme }) => ({
  marginTop: 20,
  position: 'relative',
  padding: '0 10px',
  marginBottom: 100,
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: 20,
    padding: 1,
    background: 'linear-gradient(180deg, #CE7CAC 0%, #7473B6 100%)',
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box,  linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
  },
  [theme.breakpoints.down('md')]: {
    padding: 10,
  },
}));
interface HeaderTextProps {
  shadowText: string;
}
const HeaderContainer = styled(Box)(() => ({
  zIndex: 1,
  position: 'relative',
}));
const URSALogo = styled('img')(() => ({
  zIndex: 1,
}));

const BannerImage = styled('img')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
}));

const HeaderText = styled(Typography)<HeaderTextProps>(
  ({ theme, shadowText }) => ({
    marginTop: 20,
    fontFamily: 'Helvetica Neue',
    background: 'linear-gradient(180deg, #CE7CAC 0%, #7473B6 100%)',
    backgroundClip: 'text',
    color: 'transparent',
    position: 'relative',
    textTransform: 'uppercase',
    wordBreak: 'normal',
    textAlign: 'center',
    lineHeight: 1,
    '&::after': {
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'translate(5px, 5px)',
      zIndex: -1,
      content: `"${shadowText}"`,
      color: 'transparent',
      WebkitTextStrokeWidth: 1,
      WebkitTextStrokeColor: 'rgba(250, 200, 163, 1)',
      textTransform: 'uppercase',
    },
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: 50,
      '&::after': {
        transform: 'translate(3px, 3px)',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 130,
    },
  }),
);

const SubHeaderText = styled(Typography)(({ theme }) => ({
  marginTop: 20,
  textAlign: 'center',
  fontSize: 20,
  fontWeight: 400,
  [theme.breakpoints.up('md')]: {
    fontSize: 32,
  },
}));

const DateTimeText = styled(Typography)(({ theme }) => ({
  marginTop: 20,
  textAlign: 'center',
  fontSize: 20,
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    fontSize: 32,
  },
}));
const DescriptionText = styled(Typography)(({ theme }) => ({
  marginTop: 20,
  textAlign: 'center',
  fontSize: 16,
  fontWeight: 500,
  [theme.breakpoints.up('md')]: {
    fontSize: 24,
  },
}));

const SphereContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
}));
const SphereContainerLeft = styled(Box)(() => ({
  paddingLeft: '50%',
}));
const SphereContainerRight = styled(Box)(() => ({
  paddingRight: '50%',
}));

const SphereRight = styled('img')(() => ({
  transform: 'rotate(180deg)',
  objectFit: 'contain',
}));

const SphereLeft = styled('img')(() => ({
  width: '100%',
  objectFit: 'contain',
}));
