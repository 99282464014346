import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';
import { AxiosError } from 'axios';
// @mui
import { styled, Box } from '@mui/material';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// services
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveBankAccountInfo } from '~api';
import { QUERY_KEYS } from '~constants/query-keys';
import { Button, Input } from '~components/atoms';
// types
import { BankAccountInfoParams, PayoutInfoResponse } from '~types/payment';
import { fields } from './constants';
import { BankAccountFormData } from './types';
import { ModalWindowTypes } from './index';
//
import { bankSchema } from './validation-schemes';
import { STRIPE_ERROR_CODE } from './constants';

export type BankAccountRefProps = {
  save: VoidFunction;
};

type BankAccountProps = {
  hideSaveButton?: boolean;
  data: PayoutInfoResponse['metadata']['stripe'] | undefined;
  openModalWindow: (type: ModalWindowTypes) => void;
  changePayoutId: (id: string | null) => void;
  changeStatusIsDataSaved: (status: boolean) => void;
  onNext?: VoidFunction;
};

const BankAccount: ForwardRefRenderFunction<
  BankAccountRefProps,
  BankAccountProps
> = (
  {
    data,
    openModalWindow,
    changePayoutId,
    changeStatusIsDataSaved,
    hideSaveButton = false,
    onNext = () => undefined,
  },
  ref,
) => {
  const client = useQueryClient();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    reset,
  } = useForm<BankAccountFormData>({
    resolver: yupResolver(bankSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      routingNumber: '',
      accountNumber: '',
    },
  });

  const { mutate: savePayoutInfo } = useMutation({
    mutationFn: (data: BankAccountInfoParams) => saveBankAccountInfo(data),
    onSuccess: (data) => {
      changePayoutId(data.data.payoutId);
      if (hideSaveButton) {
        onNext();
      } else {
        openModalWindow('success');
      }
      client.invalidateQueries({ queryKey: [QUERY_KEYS.ARTIST_PAYOUT_INFO] });
      reset();
    },
    onError: (error: AxiosError<{ message: string; code: number }>) => {
      const message = error.response?.data.message;
      const isRoutingNumberInvalid = String(message).includes(
        STRIPE_ERROR_CODE.ROUTING_NUMBER_INVALID,
      );

      const isAccountNumberInvalid = String(message).includes(
        STRIPE_ERROR_CODE.ACCOUNT_NUMBER_INVALID,
      );

      if (isRoutingNumberInvalid) {
        setError('routingNumber', {
          message: 'Please input valid Routing Number',
          type: 'validate',
        });
        return;
      }

      if (isAccountNumberInvalid) {
        setError('accountNumber', {
          message: 'Please input valid bank account',
          type: 'validate',
        });
        return;
      }
      openModalWindow('error');
    },
  });

  const savePayments = useCallback(
    (data: BankAccountFormData) => savePayoutInfo(data),
    [savePayoutInfo],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        save: handleSubmit(savePayments),
      };
    },
    [handleSubmit, savePayments],
  );

  useEffect(() => {
    if (data) {
      reset({
        firstName: data?.firstName ?? '',
        lastName: data?.lastName ?? '',
        routingNumber: data?.routingNumber ?? '',
        accountNumber: data?.accountNumber ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    changeStatusIsDataSaved(isDirty);
  }, [changeStatusIsDataSaved, data, isDirty]);

  return (
    <Form onSubmit={handleSubmit(savePayments)}>
      <Fields>
        {fields.map(({ type, label, placeholder }) => (
          <Controller
            key={type}
            control={control}
            name={type}
            render={({ field: { value, onChange } }) => (
              <CustomInput
                value={value}
                onChange={onChange}
                label={label}
                placeholder={placeholder}
                required
                error={!!errors[type]}
                errorMessage={errors[type]?.message}
              />
            )}
          />
        ))}
      </Fields>
      {!hideSaveButton && isDirty && (
        <Button type={'submit'} sx={{ px: 6, mt: 2, py: 1.5, mx: 'auto' }}>
          Save
        </Button>
      )}
    </Form>
  );
};

export default forwardRef(BankAccount);

const Fields = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 16,
  alignItems: 'flex-start',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    alignItems: 'normal',
    flexDirection: 'row',
  },
}));

const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

const CustomInput = styled(Input)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'calc(50% - 8px)',
  },
}));
