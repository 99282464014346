import { postRequest, Response } from '~utils';
import { NotifyStartLivestreamBySmsResponse } from '~types/notifications';

export const sendSmsNotifyStartLivestream = async (
  phoneNumbers: string[],
): Promise<Response<NotifyStartLivestreamBySmsResponse>> => {
  return await postRequest({
    path: 'notifications/sms/start-livestream',
    data: {
      phoneNumbers,
    },
  });
};
