import { SVGProps } from 'react';

const Trash = ({ fill, ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor={'pointer'}
    {...rest}
  >
    <path
      d="M2.333 13.062C1.95834 13.062 1.64234 12.9337 1.385 12.677C1.12834 12.4197 0.999998 12.1037 0.999998 11.729V1.979H0V0.896004H3.583V0H7.583V0.896004H11.166V1.979H10.166V11.708C10.166 12.0967 10.0377 12.4197 9.78101 12.677C9.52367 12.9337 9.20767 13.062 8.833 13.062H2.333ZM9.083 1.979H2.083V11.729C2.083 11.7843 2.11067 11.84 2.166 11.896C2.222 11.9513 2.27767 11.979 2.333 11.979H8.833C8.88834 11.979 8.944 11.9513 9 11.896C9.05534 11.84 9.083 11.7843 9.083 11.729V1.979ZM3.916 10.479H5V3.479H3.916V10.479ZM6.166 10.479H7.25V3.479H6.166V10.479ZM2.083 1.979V11.979V11.729V1.979Z"
      fill={fill || 'white'}
    />
  </svg>
);

export default Trash;
