export const formatMoney = (num: number) => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(num);
};

export const formatLcAndUsd = (num: number) => {
  const usd = !Number.isNaN(num) ? num / 10 : 0;

  const lcText = `${num} LC`;
  const usdText = formatMoney(usd);

  return {
    lcText,
    usdText,
    fullText: `${lcText} (or ${usdText}))`,
  };
};
