import { Box, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '~components/atoms';
import { ArtistHeader } from '~components/organisms';
import LoginPopup from '~components/popup/login';
import { useGlobalState } from '~utils/index';

const Background = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
}));

const UserLogin = () => {
  const navigate = useNavigate();
  const { username: user } = useParams();
  const globalState = useGlobalState();
  const {
    user: { id: userId },
    // TODO need to check state data properties, remove as any to see detail error.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = globalState as any;

  if (userId) {
    navigate(`/${user}`);
    return null;
  }

  return (
    <Background>
      <Container sx={{ backgroundColor: 'inherit' }}>
        <ArtistHeader />
        <LoginPopup />
      </Container>
    </Background>
  );
};

export default UserLogin;
