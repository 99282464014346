import { postRequest, Response } from '~utils';
import { UserSignUpResponse } from '~types/user';
import { MetaSubmitEventRequest } from '~types/meta';

export const submitEvent = async (
  data: MetaSubmitEventRequest,
): Promise<Response<UserSignUpResponse>> => {
  return await postRequest({
    path: 'meta/submit-event',
    data: {
      ...data,
    },
  });
};
