import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Button,
  Input,
  ModalWindow,
  MuiPhoneNumber,
  Select,
} from '~components/atoms';
import { Box, Stack, styled, Typography } from '@mui/material';
import { PayoutMethods, PayPalVenmoFormData, PaypalVenmoType } from './types';
import {
  emailSchema,
  phoneHandleSchema,
  usernameSchema,
} from './validation-schemes';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { payPalMethods, venmoMethods } from './constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { savePayPalVenmoInfo } from '~api';
import { QUERY_KEYS } from '~constants/query-keys';
import { ModalWindowTypes } from './index';
import { PayPalVenmoInfoParams, PayPalVenmoType } from '~types/payment';
import { BankAccountRefProps } from '~components/manage-profile/tabs/payout/payout-method/bank-account';

const relationsBtwTypeMethodAndSchema = {
  [PaypalVenmoType.EMAIL]: emailSchema,
  [PaypalVenmoType.PHONE]: phoneHandleSchema,
  [PaypalVenmoType.USERNAME]: usernameSchema,
};

export type PaypalVenmoFormRefProps = {
  save: () => void;
};
interface Props {
  email: PayPalVenmoType<PaypalVenmoType.EMAIL> | undefined;
  phone: PayPalVenmoType<PaypalVenmoType.PHONE> | undefined;
  username: PayPalVenmoType<PaypalVenmoType.USERNAME> | undefined;
  methodType: typeof PayoutMethods.PAYPAL | typeof PayoutMethods.VENMO;
  openModalWindow: (type: ModalWindowTypes) => void;
  changePayoutId: (id: string | null) => void;
  changeStatusIsDataSaved: (status: boolean) => void;
  hideSaveButton?: boolean;
  onNext?: () => void;
}

const PaypalVenmoForm: ForwardRefRenderFunction<BankAccountRefProps, Props> = (
  {
    email,
    phone,
    username,
    methodType,
    openModalWindow,
    changePayoutId,
    changeStatusIsDataSaved,
    hideSaveButton = false,
    onNext = () => undefined,
  },
  ref,
) => {
  // If the initial value of typeMethod state is to be changed,
  // it's important to change methodTypesId in handleChange
  // in src/components/manage-profile/tabs/payout/payout-method/index.tsx
  const [typeMethod, setTypeMethod] = useState<PaypalVenmoType>(
    PaypalVenmoType.EMAIL,
  );
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [payPalVenmoData, setPayPalVenmoData] =
    useState<PayPalVenmoFormData | null>(null);
  const client = useQueryClient();
  const { mutate: savePayPalInfo } = useMutation({
    mutationFn: (data: PayPalVenmoInfoParams) => savePayPalVenmoInfo(data),
    onSuccess: (data) => {
      changePayoutId(data.data.payoutId);
      client.invalidateQueries({ queryKey: [QUERY_KEYS.ARTIST_PAYOUT_INFO] });
      if (hideSaveButton) {
        onNext();
      } else {
        openModalWindow('success');
      }
      reset();
    },
    onError: () => openModalWindow('error'),
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    reset,
  } = useForm<PayPalVenmoFormData>({
    resolver: yupResolver(relationsBtwTypeMethodAndSchema[typeMethod]),
    defaultValues: {
      [PaypalVenmoType.EMAIL]: '',
      [PaypalVenmoType.PHONE]: '',
      [PaypalVenmoType.USERNAME]: '',
    },
  });

  useEffect(() => {
    setValue(PaypalVenmoType.EMAIL, email?.value ?? '');
    setValue(PaypalVenmoType.USERNAME, username?.value ?? '');
    // phone from BE is clean (without spaces, -, +, ())
    // plus before phone is necessary to find correct code in a selector
    const phoneValue = phone?.value ?? '';
    setValue(
      PaypalVenmoType.PHONE,
      phoneValue.includes('+') ? phoneValue : '+' + phoneValue,
    );
  }, [setValue, email, phone, username]);

  const changeTypeOfMethod = (type: PaypalVenmoType) => {
    setTypeMethod(type);
    switch (type) {
      case PaypalVenmoType.EMAIL:
        changePayoutId(email?.payoutId ?? null);
        break;
      case PaypalVenmoType.PHONE:
        changePayoutId(phone?.payoutId ?? null);
        break;
      case PaypalVenmoType.USERNAME:
        changePayoutId(username?.payoutId ?? null);
        break;
    }
  };
  const saveData = () => {
    if (!payPalVenmoData) return;
    savePayPalInfo({
      receive_type: typeMethod,
      value: payPalVenmoData[typeMethod],
      method: methodType,
    });
  };

  const onSubmit = useCallback((data: PayPalVenmoFormData) => {
    setPayPalVenmoData(data);
    setIsOpenWarningModal(true);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        save: handleSubmit(onSubmit),
      };
    },
    [handleSubmit, onSubmit],
  );

  useEffect(() => {
    changeStatusIsDataSaved(isDirty);
  }, [changeStatusIsDataSaved, email, phone, username, isDirty]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ModalWindow
        open={isOpenWarningModal}
        onClick={() => setIsOpenWarningModal(false)}
      >
        <Stack>
          <Typography padding={'10px 10px 30px'} textAlign={'center'}>
            By adding your payment info, you agree that Ursa Live is in no way
            responsible for lost funds if the info was not entered correctly.
          </Typography>
          <Stack direction={'row'} justifyContent={'space-evenly'}>
            <Button onClick={() => setIsOpenWarningModal(false)}>Cancel</Button>
            <Button onClick={saveData}>OKAY</Button>
          </Stack>
        </Stack>
      </ModalWindow>
      <Fields>
        <StyledSelect
          value={typeMethod}
          onChange={(event) =>
            changeTypeOfMethod(event.target.value as PaypalVenmoType)
          }
          items={
            methodType === PayoutMethods.PAYPAL ? payPalMethods : venmoMethods
          }
          label={
            methodType === PayoutMethods.PAYPAL
              ? 'Email/Phone'
              : 'Email/Phone/Username'
          }
          required
          error={!!errors[typeMethod]}
        />
        {typeMethod === PaypalVenmoType.PHONE && (
          <Controller
            control={control}
            name={typeMethod}
            render={({ field: { value, onChange } }) => (
              <CustomMuiPhoneNumber
                value={value}
                onChange={onChange}
                error={!!errors[typeMethod]}
                errorMessage={errors[typeMethod]?.message}
              />
            )}
          />
        )}
        {typeMethod === PaypalVenmoType.EMAIL && (
          <Controller
            control={control}
            name={PaypalVenmoType.EMAIL}
            render={({ field: { value, onChange } }) => (
              <CustomInput
                value={value}
                onChange={onChange}
                placeholder={'Enter your email'}
                required
                error={!!errors[typeMethod]}
                errorMessage={errors[typeMethod]?.message}
              />
            )}
          />
        )}
        {typeMethod === PaypalVenmoType.USERNAME && (
          <Controller
            control={control}
            name={PaypalVenmoType.USERNAME}
            render={({ field: { value, onChange } }) => (
              <CustomInput
                value={value}
                onChange={onChange}
                placeholder={'Enter your username'}
                required
                error={!!errors[typeMethod]}
                errorMessage={errors[typeMethod]?.message}
              />
            )}
          />
        )}
      </Fields>
      {!hideSaveButton && (
        <Button type={'submit'} sx={{ px: 6, mt: 2, py: 1.5, mx: 'auto' }}>
          Save
        </Button>
      )}
    </Form>
  );
};

export default forwardRef(PaypalVenmoForm);

const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

const StyledSelect = styled(Select)<{ error: boolean }>(({ theme, error }) => ({
  marginBottom: 0,
  width: '100%',
  '.MuiInputBase-root': {
    borderRadius: 4,
    borderRight: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    width: 200,
    marginBottom: error ? 30 : 0,
    '.MuiInputBase-root': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      borderRight: `1px solid ${theme.palette.secondary.light}`,
    },
  },
}));

const CustomMuiPhoneNumber = styled(MuiPhoneNumber)(({ theme }) => ({
  '.MuiFormControl-root': {
    borderRadius: 4,
  },
  '.MuiInputBase-input': {
    borderRadius: 4,
  },
  [theme.breakpoints.up('md')]: {
    '.MuiFormControl-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '.MuiInputBase-input': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
}));

const Fields = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  gap: 16,
  [theme.breakpoints.up('sm')]: {
    gap: 'normal',
    flexDirection: 'row',
  },
}));

const CustomInput = styled(Input)(({ theme }) => ({
  '.MuiInputBase-root': {
    borderRadius: 4,
    [theme.breakpoints.up('sm')]: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));
