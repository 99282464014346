import { ReactNode } from 'react';
import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';

const StyledButton = styled(Button)(() => ({
  textTransform: 'none',
  fontSize: 12,
  minHeight: 40,
  lineHeight: '14px',
  '&.Mui-disabled': {
    cursor: 'none',
  },
}));

type Props = Omit<ButtonProps, 'disabled'> & {
  children: ReactNode;
  loading?: boolean;
  disabled?: boolean;
};

const LoadingButton = ({ children, loading, disabled, ...rest }: Props) => (
  <StyledButton
    color="warning"
    variant="contained"
    size="large"
    disabled={loading || disabled}
    {...rest}
  >
    {loading ? (
      <CircularProgress size={24} sx={{ color: 'white' }} />
    ) : (
      children
    )}
  </StyledButton>
);

export default LoadingButton;
