import React from 'react';
import {
  POPUP_TYPE,
  PopupTypeValues,
  useGlobalDispatch,
  useGlobalState,
} from '~utils';

import { IconButton, Stack, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BasePopup from './base-popup';
import { TipForm } from '~components/molecules';

const StyledTipModal = styled(BasePopup)(({ theme }) => ({
  padding: '32px 14px',
  [theme.breakpoints.up('md')]: {
    width: 620,
    padding: 24,
  },
}));

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: -14,
  top: -32,

  [theme.breakpoints.up('md')]: {
    top: -24,
    right: -20,
  },
  [theme.breakpoints.between('xl', 1536)]: {
    top: -20,
  },
}));

const TipModal = () => {
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const {
    app: { tippedEvents },
    artist: { currentEvent },
  } = globalState;

  const handleTogglePopup = (popup: PopupTypeValues) => {
    dispatch({
      type: 'app',
      payload: { popup },
    });
  };

  const handleClose = () => {
    handleTogglePopup(POPUP_TYPE.NONE);
  };

  const handleAddEvent = (event: string) => {
    dispatch({
      type: 'app',
      payload: {
        tippedEvents: [...tippedEvents, event],
      },
    });
  };

  return (
    <StyledTipModal
      open={true}
      onClose={handleClose}
      closeBtn={
        <StyledCloseButton disableRipple onClick={handleClose}>
          <CloseIcon
            sx={{
              color: 'white',
            }}
          />
        </StyledCloseButton>
      }
    >
      <Stack
        flex={1}
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
        spacing={{ xs: 2.5, md: 0 }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={'center'}
          textAlign={'center'}
          spacing={{ xs: 0, md: 1.5 }}
          mb={1}
        >
          <Typography fontSize={{ lg: 20 }} fontWeight={'700'}>
            TIP AMOUNT
          </Typography>
          <Typography variant="body2" fontWeight={'300'}>
            10 livecoins (lc) = 1 dollar
          </Typography>
        </Stack>
        <TipForm
          onSuccess={() => {
            handleAddEvent(currentEvent);
            handleClose();
          }}
          onError={() => {
            handleClose();
          }}
          submitBtnProps={{
            sx: {
              alignSelf: 'center',
              width: {
                xs: '100%',
                md: 120,
              },
            },
          }}
        />
      </Stack>
    </StyledTipModal>
  );
};

export default TipModal;
