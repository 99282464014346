import React from 'react';
import { styled, Box, alpha } from '@mui/material';
import { Pagination } from '~components/atoms';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { getMoreReports } from '~api';
import { InitialReportsForLoading } from './initial-reports';
import { Reports } from './reports';
export const ROWS_PER_PAGE = 3;

const ListOfReports = ({ totalReports }: { totalReports: number }) => {
  const [page, setPage] = React.useState(1);
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.REPORTS_MORE, page],
    enabled: !!totalReports,
    queryFn: () =>
      getMoreReports({
        limit: ROWS_PER_PAGE,
        offset: (page - 1) * ROWS_PER_PAGE,
      }),
  });
  const amountOfPages = () => {
    if (!isLoading && !data?.length) return 1;
    return Math.floor(Math.ceil(totalReports / ROWS_PER_PAGE));
  };

  return (
    <Wrapper>
      {!!totalReports && isLoading ? (
        <InitialReportsForLoading />
      ) : (
        <Reports data={data} page={page} totalReports={totalReports} />
      )}
      <CustomPagination
        count={amountOfPages()}
        page={page}
        onChange={(event, page) => setPage(page)}
      />
    </Wrapper>
  );
};

export default ListOfReports;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
  borderRadius: 8,
  marginTop: 16,
}));

const CustomPagination = styled(Pagination)(() => ({
  margin: '15px auto',
}));
