import React, { ReactNode } from 'react';
import { Box, ModalProps, Stack, styled, Typography } from '@mui/material';
import crossImage from '~images/cross.svg';
import backImage from '~images/back.svg';
import { ModalWindow } from '~components/atoms';

interface BasePopupProps extends Omit<ModalProps, 'children'> {
  onClose?: () => void;
  onBack?: () => void;
  children: React.ReactNode;
  closeBtn?: ReactNode;
  backBtn?: ReactNode;
  hideCloseButton?: boolean;
  hideBackButton?: boolean;
  subTitle?: string;
  containerHeight?: string;
}

const BasePopup = ({
  open,
  onClose = () => undefined,
  onBack = () => undefined,
  title,
  subTitle,
  children,
  className,
  closeBtn,
  backBtn,
  hideCloseButton = false,
  hideBackButton = true,
  containerHeight,
  ...rest
}: BasePopupProps) => {
  const handleClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose();
  };

  return (
    <ModalWindow
      className={className}
      open={open}
      onClose={handleClose}
      {...rest}
    >
      <Container sx={{ height: containerHeight }}>
        <Header>
          {!hideBackButton &&
            (backBtn || (
              <BackWrapper onClick={onBack}>
                <img src={backImage} className="img-fluid" alt="Back" />
                <HeaderActionText>Back</HeaderActionText>
              </BackWrapper>
            ))}
          {title && <Title>{title}</Title>}
          {subTitle && <SubTitle>{subTitle}</SubTitle>}

          {!hideCloseButton &&
            (closeBtn || (
              <CloseWrapper onClick={onClose}>
                <HeaderActionText>Close</HeaderActionText>
                <img src={crossImage} className="img-fluid" alt="Cross" />
              </CloseWrapper>
            ))}
        </Header>
        {children}
      </Container>
    </ModalWindow>
  );
};

export default BasePopup;

const Container = styled(Stack)(() => ({
  width: '100%',
  height: 'inherit',
}));
const Header = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  flexDirection: 'column',
}));
const Title = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  color: theme.palette.text.primary,
  textTransform: 'uppercase',
  marginTop: 4,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h2.fontSize,
  },
}));
const SubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  color: theme.palette.text.primary,
  textTransform: 'uppercase',
  lineHeight: 1,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.h2.fontSize,
  },
}));
const CloseWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 12,
  right: 0,
  cursor: 'pointer',
  gap: 4,
}));

const BackWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 12,
  left: 0,
  cursor: 'pointer',
  gap: 4,
}));
const HeaderActionText = styled(Typography)(({ theme }) => ({
  margin: 0,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.subtitle2.fontSize,
  color: '#FFFFFF',
  cursor: 'pointer',
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));
