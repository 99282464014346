import { Typography, styled } from '@mui/material';

const ArtistName = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'block',
    textShadow:
      '-2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff',
  },
  letterSpacing: '1px',
  fontWeight: '900',
  maxWidth: '32vw',
  display: 'none',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontFamily: 'Work Sans',
  color: theme.palette.primary.light,
  textTransform: 'uppercase',
  textShadow:
    '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
}));

export default ArtistName;
