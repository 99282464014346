import { Theme, alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': {
              color: theme.palette.text.disabled,
            },
          },
        },
        input: {
          '&::placeholder': {
            ...theme.typography.subtitle1,
            opacity: 0.5,
            fontWeight: 500,
            color: 'rgba(255, 255, 255, 1) !important',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            color: theme.palette.text.primary,
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.text.primary,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.palette.primary.main,
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: theme.palette.text.primary,
            },
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
        input: {
          paddingLeft: 20,
          '&::placeholder': {
            color: alpha(theme.palette.text.primary, 0.3),
          },
        },
      },
    },
  };
}
