import * as React from 'react';
import { SVGProps } from 'react';

const Tip = ({ fill = '#FAC8A3', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d={`M5.059 13.9976V8.22659H7.65533C8.75576 8.42354 9.85619 9.02065 10.9566 9.71311H12.973C13.8859 9.76782 14.3642 
      10.6932 13.4764 11.3012C12.7698 11.8202 11.8366 11.7905 10.88 11.7045C10.2204 11.6717 10.1923 12.558 10.88 
      12.5611C11.1192 12.5799 11.3787 12.5236 11.6053 12.5236C12.7995 12.522 13.7812 12.2938 14.383 11.3513L14.6846 
      10.6463L17.6842 9.15977C19.1848 8.66582 20.2509 10.2352 19.1458 11.3278C16.973 12.9081 14.7456 14.2086 12.4666 
      15.259C10.8112 16.2657 9.15591 16.2313 7.50214 15.259L5.059 13.9976ZM12.2884 1.21917C12.8558 0.626747 13.2528 
      0.11561 14.1282 0.0155708C15.7694 -0.173566 17.2794 1.50834 16.4509 3.16368C16.2149 3.63573 15.735 4.19533 15.2036 
      4.74554C14.6205 5.3489 13.975 5.94132 13.5217 6.38994L12.2884 7.61385L11.2692 6.63222C10.0438 5.45207 8.04454 3.96555 
      7.97889 2.12421C7.932 0.834641 8.95114 0.00775525 10.1204 0.0233864C11.1676 0.0358912 11.6069 0.556407 12.2884 
      1.21917ZM0.39624 7.67169H4.27744V14.5775H0.39624V7.67169Z`}
      fill={fill}
    />
  </svg>
);

export default Tip;
