import React, { useEffect } from 'react';
import { CircleSuccessTickSVG, ThinCrossSVG } from '~components/icons';
import { Button, ModalWindow } from '~components/atoms';
import { styled, ModalProps, Typography, Box } from '@mui/material';
import { useTriggerTimeout } from '~components/custom-hook';

interface Props extends Omit<ModalProps, 'children'> {
  title?: string;
  titleOverImage?: string;
  titleUnderImage?: string;
  description?: React.ReactNode;
  redirectDescription?: string;
  onClose: () => void;
  onRedirect?: () => void;
  icon?: React.ReactNode;
  autoHideDuration?: number;
}

const NotificationModalWindow = ({
  open,
  onClose,
  onRedirect,
  title,
  titleOverImage,
  titleUnderImage,
  description,
  redirectDescription,
  icon,
  className,
  autoHideDuration = 0,
  ...rest
}: Props) => {
  const { startTimeout, stopTimeout } = useTriggerTimeout(
    onClose,
    autoHideDuration,
  );

  const handleClose = () => {
    onClose();
    stopTimeout();
  };

  useEffect(() => {
    if (open && autoHideDuration > 0) {
      startTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, autoHideDuration]);

  return (
    <ModalWindow open={open} onClose={handleClose} {...rest}>
      <ModalContent className={className}>
        <WrapperCross>
          <ThinCrossSVG onClick={handleClose} />
        </WrapperCross>
        {titleOverImage && <Title sx={{ pb: 2.5 }}>{titleOverImage}</Title>}
        {icon ?? <CircleSuccessTickSVG />}
        {titleUnderImage && <Title sx={{ pt: 1.5 }}>{titleUnderImage}</Title>}
        {title && <Title sx={{ pt: titleUnderImage ? 0 : 2.5 }}>{title}</Title>}
        {description && <Description>{description}</Description>}
        {redirectDescription && (
          <Button sx={{ mt: 4 }} onClick={onRedirect}>
            {redirectDescription}
          </Button>
        )}
      </ModalContent>
    </ModalWindow>
  );
};

export default NotificationModalWindow;

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '40px 0',
  [theme.breakpoints.up('sm')]: {
    padding: 40,
  },
}));

const Title = styled(Typography)(() => ({
  textAlign: 'center',
}));

Title.defaultProps = {
  variant: 'h4',
};

const Description = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.subtitle1.fontSize,
  textAlign: 'center',
  color: theme.palette.text.primary,
  paddingTop: 16,
  whiteSpace: 'pre-line',
}));

const WrapperCross = styled(Box)(() => ({
  position: 'absolute',
  top: 16,
  right: 16,
}));
