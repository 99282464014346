import { getRequest, postRequest } from '~utils';

import {
  EventBannerResponse,
  EventBannersResponse,
} from '~types/banner-events';

export const getBannerEvents = async (): Promise<EventBannersResponse> => {
  return await getRequest({
    path: 'event-banners/all',
  });
};

export const getBannerEventInfo = async (
  eventId: string,
): Promise<EventBannerResponse> => {
  return await getRequest({
    path: `event-banners/${eventId}`,
  });
};

export const getHomePageBanner = async (): Promise<any> => {
  return await postRequest({
    path: 'settings/ui',
    data: {
      settings: ['UI_SETTINGS_HOME_PAGE_BANNER'],
    },
  });
};
