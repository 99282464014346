import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

export const isValidPhone = (phoneNumber: string) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(phoneNumber);
    return phoneUtil.isValidNumber(parsedPhoneNumber);
  } catch (err) {
    return false;
  }
};

export const addCountryCode = (phoneNumber: string, countryCode: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(
    phoneNumber,
    countryCode,
  );
  return phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164);
};

export const isUserNameValid = (username: string) => {
  /*
    Usernames can only have:
    - Letters (a-z or A-Z) A and a is same . It is case insenstive
    - Numbers (0-9)
    - Dots (.)
    - Underscores (_)
  */
  const res = /^[a-zA-Z0-9_.]+$/.exec(username);
  const valid = !!res;
  return valid;
};

export const isUserOnlyNumeric = (username: string) => {
  /*
    Usernames can only have:
    - Letters (a-z or A-Z) A and a is same . It is case insenstive
    - Numbers (0-9)
    - Dots (.)
    - Underscores (_)
  */
  const res = /^[0-9.]*$/.exec(username);
  const valid = !!res;
  return valid;
};

export const isValidUrl = (url: string) => {
  /*
    Valid url can only have:
    - Protocol
    - Port and path
    - Domain name OR IP(v4) address 
    - Query string 
  */
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(url);
};
