import { LandingPageEvent } from '~types/landing-page';
import { alpha, Box, BoxProps, Stack, styled, Typography } from '@mui/material';
import { formatDateTime } from '~utils/dateTimeUtils';
import React from 'react';
import { SHORT_DATE_TIME_WITH_TIME_ZONE } from '~utils/constants';
import { ThumbNail } from '~components/pages/home/ThumbNail';
import {
  SlideBorder,
  SlideRatioContainer,
  SlideWrapper,
} from '~components/pages/home/styles';

type Props = {
  isDragging: boolean;
} & Pick<
  LandingPageEvent,
  'imageUrl' | 'artistUserName' | 'genre' | 'scheduledAt' | 'userName'
> &
  BoxProps;

export const ItemInSlide = ({
  imageUrl,
  artistUserName,
  userName,
  genre,
  scheduledAt,
  isDragging,
  ...boxProps
}: Props) => {
  const handleClick = async () => {
    if (!isDragging) {
      window.location.href = `/${artistUserName}`;
    }
  };
  return (
    <SlideWrapper onClick={handleClick} {...boxProps}>
      <SlideBorder>
        <SlideRatioContainer sx={{ paddingTop: '100%' }}>
          <ThumbNail src={imageUrl} />
        </SlideRatioContainer>
        <SliderContent>
          <Stack
            width={'100%'}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginTop={'auto'}
          >
            <ArtistName>{userName || artistUserName}</ArtistName>
            {genre && <Genre>{genre}</Genre>}
          </Stack>
          <Date>
            {formatDateTime(scheduledAt, SHORT_DATE_TIME_WITH_TIME_ZONE)}
          </Date>
        </SliderContent>
      </SlideBorder>
    </SlideWrapper>
  );
};

const SliderContent = styled(Box)(() => ({
  background: '#151515',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '8px 16px',
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
}));

const Genre = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.text.primary, 0.6),
  fontSize: theme.typography.fontSize,
  lineHeight: '15px',
  position: 'relative',
  paddingLeft: 16,
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ':before': {
    content: '""',
    height: 4,
    width: 4,
    backgroundColor: alpha(theme.palette.text.primary, 0.4),
    borderRadius: '50%',
    position: 'absolute',
    top: 'calc(50% - 2px)',
    left: 7,
  },
}));

const ArtistName = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
  color: theme.palette.action.active,
  lineHeight: '39px',
  maxWidth: '65%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Date = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: '20px',
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
}));
