import React from 'react';
import { InputLabel, styled } from '@mui/material';

interface LabelProps {
  required?: boolean;
}

const Label = ({ required, children }: React.PropsWithChildren<LabelProps>) => {
  return <CustomLabel required={required}>{children}</CustomLabel>;
};

export default Label;

const CustomLabel = styled(InputLabel)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.subtitle2.fontSize,
  color: theme.palette.text.primary,
  textOverflow: 'ellipsis',
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  '.MuiInputLabel-asterisk': {
    color: theme.palette.error.main,
    paddingLeft: 5,
  },
}));
