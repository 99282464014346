import * as React from 'react';
import { SVGProps } from 'react';

const ThinCrossSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor={'pointer'}
    {...props}
  >
    <path
      d={`M9.76221 7.99997L15.7444 2.01753C15.9089 1.85285 15.9997 1.63314 16 1.39886C16 1.16446 15.9092 0.944488
      15.7444 0.780065L15.2202 0.255968C15.0553 0.0908948 14.8356 0.000488281 14.6011 0.000488281C14.367 0.000488281
      14.1473 0.0908948 13.9824 0.255968L8.00026 6.23802L2.01782 0.255968C1.85327 0.0908948 1.63343 0.000488281 1.39902
      0.000488281C1.16488 0.000488281 0.945041 0.0908948 0.780488 0.255968L0.256 0.780065C-0.0853333 1.1214 -0.0853333
      1.67659 0.256 2.01753L6.23831 7.99997L0.256 13.9821C0.0913171 14.1471 0.000650406 14.3668 0.000650406 14.6011C0.000650406
      14.8354 0.0913171 15.0551 0.256 15.2199L0.780358 15.744C0.944911 15.9089 1.16488 15.9994 1.39889 15.9994C1.6333 15.9994
      1.85314 15.9089 2.01769 15.744L8.00013 9.76179L13.9823 15.744C14.1471 15.9089 14.3668 15.9994 14.601 15.9994H14.6012C14.8355
      15.9994 15.0552 15.9089 15.22 15.744L15.7443 15.2199C15.9088 15.0552 15.9996 14.8354 15.9996 14.6011C15.9996 14.3668 15.9088 
      14.1471 15.7443 13.9823L9.76221 7.99997Z`}
      fill="white"
    />
  </svg>
);

export default ThinCrossSVG;
