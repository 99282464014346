import React from 'react';
import {
  Pagination as MUIPagination,
  PaginationProps,
  styled,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

const Pagination = (props: PaginationProps) => {
  return <CustomPagination {...props} />;
};

export default Pagination;

const CustomPagination = styled(MUIPagination)(({ theme }) => ({
  '.MuiPaginationItem-root': {
    color: alpha(theme.palette.text.primary, 0.3),
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
    },
  },
}));
