import { Box, Stack, StackProps, Typography, styled } from '@mui/material';

const StyledRoot = styled(Stack)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'black',
  minWidth: 120,
  minHeight: 32,
}));

type Props = StackProps & {
  title: string;
  text: string;
};

const HighlightText = ({ title, text, ...rest }: Props) => {
  return (
    <Box>
      <StyledRoot
        direction={'row'}
        alignItems={'center'}
        py={0.5}
        px={2}
        spacing={1.5}
        {...rest}
      >
        <Typography variant="caption" fontSize={12}>
          {title}{' '}
        </Typography>
        <Typography
          component={'span'}
          color={(theme) => theme.palette.warning.main}
          variant="h4"
          fontWeight={400}
          lineHeight={'24px'}
        >
          {text}
        </Typography>
      </StyledRoot>
    </Box>
  );
};

export default HighlightText;
