import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useGlobalDispatch } from '~utils/container';
import { RootState, select } from '~stores';
import { getFollowing } from '~api/fan';

const useFollowing = () => {
  const dispatch = useGlobalDispatch();
  const artistInfo = useSelector((state: RootState) =>
    select.roomInfo.getArtistInfo(state),
  );

  const fetchFollowingStatus = useCallback(async () => {
    if (artistInfo.username) {
      const { following } = await getFollowing(artistInfo.username);
      dispatch({ type: 'user', payload: { is_following: following } });
    }
  }, [artistInfo.username, dispatch]);

  return { fetchFollowingStatus };
};

export default useFollowing;
