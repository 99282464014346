import React, { useEffect, useState, FC } from 'react';
// @mui
import {
  Badge,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material';
// hooks
import useIsBlocked from '~hooks/useIsBlocked';
// components
import Avatar from '~components/organisms/avatar';
import { BadgeGold, BadgeBronze, BadgeSilver } from '~components/icons';
import { TextEllipsis } from '~components/atoms';
// utils
import {
  getEmojiAssociateToAmount,
  strictValidArrayWithLength,
} from '~utils/commonUtils';
import { useGlobalState } from '~utils/container';
import { FEATURE_FLAGS_TYPE, USER_TYPE } from '~utils/constants';
import { dateStringToRelativeTime } from '~utils/dateTimeUtils';
import { pxToRem } from '~styles/theme/typography';
// types
import { Tipper } from '~types/artist';
//
import ArtistMoreActions from './artist-more-menu';
import BlockIcon from '@mui/icons-material/Block';
import { useFeatureFlag } from '~hooks/useFeatureFlag';

const StyledUsername = styled(Typography)(() => ({
  width: 24,
  position: 'absolute',
  left: 6,
  fontSize: pxToRem(12),
  lineHeight: '12px',
  fontWeight: '700',
}));

const TOP3_BADGES = [<BadgeGold />, <BadgeSilver />, <BadgeBronze />];

type Props = {
  item: Tipper;
  index: number;
  isTopTip?: boolean;
};

const ArtistTipItem: FC<Props> = ({ item, index, isTopTip = false }) => {
  const { isEnable } = useFeatureFlag();
  const globalState = useGlobalState();

  const { blocked } = useIsBlocked(item.username);

  const isEnableBlockUser = isEnable(FEATURE_FLAGS_TYPE.BLOCKED_USER);

  const {
    artist: { currentEvent },
    config: { remoteUsersDetails },
    user: { type },
  }: // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, any> = globalState;
  const [showAllowOnStage, setAllowOnStage] = useState(false);
  const { amount, createdAt, fanId, displayUsername, username = '' } = item;

  useEffect(() => {
    if (strictValidArrayWithLength(remoteUsersDetails)) {
      const detail = remoteUsersDetails.find(
        ({ userId: remoteUserId }: { userId: string }) =>
          remoteUserId === fanId,
      );
      if (detail) {
        const { allowOnStage = false } = detail;
        setAllowOnStage(allowOnStage);
      }
    } else {
      setAllowOnStage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteUsersDetails]);

  const showDotMenu = isEnableBlockUser
    ? type === USER_TYPE.ARTIST
    : type === USER_TYPE.ARTIST && currentEvent && !blocked;

  return (
    <ListItem disablePadding alignItems="flex-start">
      <ListItemAvatar sx={{ minWidth: 36, mt: isTopTip ? 0 : 1 }}>
        <Badge
          invisible={!(type === USER_TYPE.ARTIST && showAllowOnStage)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          color="success"
          variant="dot"
          overlap="circular"
        >
          <Stack
            my={isTopTip ? 0.5 : 0}
            direction={'row'}
            alignItems={'center'}
            spacing={1}
            mr={1}
          >
            {isTopTip && (
              <Box position={'relative'} width={24}>
                {index < 3 ? TOP3_BADGES[index] : null}
                <StyledUsername
                  variant="caption"
                  color={index < 3 ? 'black' : 'white'}
                  top={index < 3 ? 6 : -6}
                >
                  {`${index + 1}${index < 3 ? '' : '.'}`}
                </StyledUsername>
              </Box>
            )}
            <Box sx={{ width: 24, height: 24 }}>
              <Avatar username={username} />
            </Box>
          </Stack>
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack
            spacing={0.5}
            py={0.5}
            direction={isTopTip ? 'row' : 'column'}
            alignItems={isTopTip ? 'center' : 'flex-start'}
            position={'relative'}
          >
            <Stack direction="row" alignItems="center">
              <TextEllipsis
                text={displayUsername}
                variant="body1"
                fontSize={{ xs: 14 }}
                fontWeight={'700'}
                maxWidth={180}
                textTransform={blocked ? 'initial' : 'lowercase'}
              />
              {isEnableBlockUser && blocked && (
                <BlockIcon
                  sx={{
                    color: 'red',
                    fontSize: 14,
                    marginLeft: '4px',
                  }}
                />
              )}
            </Stack>

            {!isTopTip && (
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => alpha(theme.palette.text.primary, 0.6),
                }}
              >
                {dateStringToRelativeTime(createdAt as string)}
              </Typography>
            )}
          </Stack>
        }
      />
      <ListItemText
        primary={
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            spacing={1.5}
          >
            <Typography
              variant="caption"
              fontWeight={'500'}
              fontSize={pxToRem(12)}
            >
              {amount} lc
            </Typography>
            <Box width={25} height={25}>
              <Box
                sx={{ maxWidth: '100%' }}
                component={'img'}
                src={
                  isTopTip
                    ? '/assets/images/trophy-icon.png'
                    : getEmojiAssociateToAmount(amount)
                }
              />
            </Box>
          </Stack>
        }
      />
      {showDotMenu && (
        <Box mt={0.5} ml={1}>
          <ArtistMoreActions
            userId={item.fanId}
            username={item.username}
            isBlocked={blocked}
          />
        </Box>
      )}
    </ListItem>
  );
};

export default ArtistTipItem;
