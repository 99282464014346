import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Box, Stack, styled } from '@mui/material';
import SliderContent from './slider-content';
import { Dot, NextArrowButtons, PrevArrowButtons } from '../styles';
import { EventBanner } from '~types/banner-events';
import { QUERY_KEYS } from '~constants/query-keys';
import { useQuery } from '@tanstack/react-query';
import { getHomePageBanner } from '~api/event-banners';
import MediaPlayer from './MediaPlayer';
import { FEATURE_FLAGS_TYPE } from '~utils/constants';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import { getImageURL } from '~utils/awsUtils';

interface HeroSectionProps {
  eventBanners: EventBanner[];
  allowClickOnBanner?: boolean;
}

interface BannerSettings {
  header: string;
  subHeader: string;
  headerFont: string;
  headerFontSize: number;
  subHeaderFont: string;
  subHeaderFontSize: number;
  alignment: string;
  file: string;
}

const HeroSection = ({
  eventBanners,
  allowClickOnBanner,
}: HeroSectionProps) => {
  const { isEnable } = useFeatureFlag();
  const isEnableBannerEnhance = isEnable(
    FEATURE_FLAGS_TYPE.BANNER_EVENT_ENHANCE,
  );
  const LANDING_PAGE_HEADER_VIDEO = 'web_videos/live-in-the-moment.mp4';
  const videoRefs = useRef<HTMLVideoElement[]>([]);
  const [bannerSettings, setBannerSettings] = useState<BannerSettings>();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  useQuery({
    queryKey: [QUERY_KEYS.BANNER_UI],
    queryFn: getHomePageBanner,
    onSuccess: (resp: any) => {
      const setting = resp.data.find(
        (v: any) => v.key === 'UI_SETTINGS_HOME_PAGE_BANNER',
      );
      const bannerSettings = JSON.parse(setting.value);
      if (bannerSettings) {
        setBannerSettings(bannerSettings);
      }
    },
    refetchOnWindowFocus: false,
  });

  const settings = {
    pauseOnFocus: true,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    autoplay: true,
    autoplaySpeed: 10000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrowButtons />,
    nextArrow: <NextArrowButtons />,
    customPaging: () => <Dot />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (videoRefs.current[currentVideoIndex]) {
      videoRefs.current[currentVideoIndex].play();
    }
  }, [currentVideoIndex]);

  const handleClick = (eventId: string) => {
    if (!allowClickOnBanner) {
      window.location.href = `/event/${eventId}`;
    }
  };

  const handleBeforeChange = (current: number, next: number) => {
    if (isEnableBannerEnhance) {
      if (videoRefs.current[current]) {
        videoRefs.current[current].pause();
      }
      setCurrentVideoIndex(next);
    }
  };

  const renderEventMedia = (url: any, index: any, data: any) => {
    return (
      <MediaPlayer
        url={`${process.env.REACT_APP_AWS_S3_IMAGE_BUCKET_ENDPOINT}/${url}`}
        videoRef={(el) => (videoRefs.current[index] = el!)}
        show={index === currentVideoIndex}
        keyIndex={index}
        title={data.overlayTextHeader}
        titleFont={data.headerFont}
        titleFontSize={data.headerFontSize}
        subTitle={data.overlayTextSubHeader}
        subTitleFont={data.subHeaderFont}
        subTitleFontSize={data.subHeaderFontSize}
        textPosition={data.alignment}
      />
    );
  };

  const renderHomeDefaultMedia = (show: boolean) => {
    return (
      <MediaPlayer
        url={`${process.env.REACT_APP_AWS_S3_IMAGE_BUCKET_ENDPOINT}/${bannerSettings?.file}`}
        videoRef={(el) => (videoRefs.current[99] = el!)}
        show={show}
        keyIndex={99}
        title={bannerSettings?.header}
        titleFont={bannerSettings?.headerFont}
        titleFontSize={bannerSettings?.headerFontSize}
        subTitle={bannerSettings?.subHeader}
        subTitleFont={bannerSettings?.subHeaderFont}
        subTitleFontSize={bannerSettings?.subHeaderFontSize}
        textPosition={bannerSettings?.alignment}
      />
    );
  };

  return (
    <Wrapper>
      {!isEnableBannerEnhance ? (
        <Video
          src={getImageURL(LANDING_PAGE_HEADER_VIDEO)}
          autoPlay
          loop
          muted={true}
          playsInline
        />
      ) : (
        <Box sx={{ width: '100%', height: '100%' }}>
          {renderHomeDefaultMedia(eventBanners.length === 0)}
          {eventBanners.map((banner, index) => {
            return banner.videoUrl
              ? renderEventMedia(banner.videoUrl, index, banner)
              : renderHomeDefaultMedia(index === currentVideoIndex);
          })}
        </Box>
      )}
      <SliderContainer>
        <CustomSlider {...settings} beforeChange={handleBeforeChange}>
          {eventBanners.map((eventBanner) => (
            <SlideWrapper
              key={eventBanner.id}
              onClick={() => handleClick(eventBanner.id)}
            >
              <SliderContent eventBanner={eventBanner} />
            </SlideWrapper>
          ))}
        </CustomSlider>
      </SliderContainer>
    </Wrapper>
  );
};

export default HeroSection;

const Wrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex !important',
  justifyContent: 'center !important',
  position: 'relative',
}));
const Video = styled('video')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

const SliderContainer = styled(Stack)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  justifyContent: 'center',
}));

const CustomSlider = styled(Slider)(({ theme }) => ({
  '.slick-dots': {
    display: 'flex',
    alignItems: 'center',
    bottom: 5,
    [theme.breakpoints.up('md')]: {
      bottom: -27,
    },
    '& li': {
      width: 'auto',
      height: 'auto',
    },
    '.slick-active div': {
      backgroundColor: theme.palette.text.primary,
    },
  },
  '.slick-arrow': { zIndex: '1' },
}));

const SlideWrapper = styled(Box)(({ theme }) => ({
  height: 222,
  width: '100%',
  display: 'flex !important',
  justifyContent: 'center !important',
  position: 'relative',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    height: 500,
  },
}));
