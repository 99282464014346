import isUrl from 'is-url';
import { axiosClientWithoutCaseConvert as axiosClient } from '~utils/clientUtils';
import { getPreSignedUrl, getPreSignedVideoUrl } from '~api/user';

export const uploadImage = async (image: any): Promise<string> => {
  const {
    data: { fields, url },
  } = await getPreSignedUrl(image.type);

  const fieldsJson = JSON.parse(fields);
  const formData = new FormData();

  for (const key in fieldsJson) {
    formData.append(key, fieldsJson[key]);
  }

  formData.append('Content-Type', image.type);
  formData.append('file', image);

  await axiosClient.post(url, formData);
  return fieldsJson.key;
};

export const uploadPartnerVideo = async (video: any): Promise<string> => {
  const {
    data: { fields, url },
  } = await getPreSignedVideoUrl(video.type);

  const fieldsJson = JSON.parse(fields);
  const formData = new FormData();

  for (const key in fieldsJson) {
    formData.append(key, fieldsJson[key]);
  }

  formData.append('Content-Type', video.type);
  formData.append('file', video);

  await axiosClient.post(url, formData);
  return fieldsJson.key;
};

export const getImageURL = (imagePath: string | undefined | null): string => {
  if (!imagePath) return '';
  return isUrl(imagePath)
    ? imagePath
    : `${process.env.REACT_APP_AWS_S3_IMAGE_BUCKET_ENDPOINT}/${imagePath}`;
};

export const getCdnURL = (path: string | undefined | null): string => {
  if (!path) return '';
  return isUrl(path)
    ? path
    : `${process.env.REACT_APP_AWS_S3_IMAGE_BUCKET_ENDPOINT}/${path}`;
};
