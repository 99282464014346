import React from 'react';
import { CircleExclamationMarkSVG, ThinCrossSVG } from '~components/icons';
import { Button, ModalWindow } from '~components/atoms';
import { styled, ModalProps, Typography, Box } from '@mui/material';

interface Props extends Omit<ModalProps, 'children'> {
  title?: string;
  description?: string;
  redirectDescription?: string;
  onClose: () => void;
  onRedirect?: () => void;
  icon?: React.ReactNode;
}

const ErrorModalWindow = ({
  open,
  onClose,
  onRedirect,
  title,
  description,
  redirectDescription,
  className,
  ...rest
}: Props) => {
  return (
    <CustomModalWindow open={open} onClose={onClose} {...rest}>
      <ModalContent className={className}>
        <WrapperCross>
          <ThinCrossSVG onClick={onClose} />
        </WrapperCross>
        <CircleExclamationMarkSVG />
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
        {redirectDescription ? (
          <Button sx={{ mt: 4 }} onClick={onRedirect}>
            {redirectDescription}
          </Button>
        ) : (
          <Button sx={{ mt: 4 }} onClick={onClose}>
            OK
          </Button>
        )}
      </ModalContent>
    </CustomModalWindow>
  );
};

export default ErrorModalWindow;

const CustomModalWindow = styled(ModalWindow)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: 456,
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '20px 0',
  [theme.breakpoints.up('sm')]: {
    padding: '24px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(2.5),
}));

Title.defaultProps = {
  variant: 'h4',
};

const Description = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.primary,
  paddingTop: theme.spacing(1),
}));

Description.defaultProps = {
  fontSize: {
    lg: 14,
  },
  fontWeight: 400,
};

const WrapperCross = styled(Box)(() => ({
  position: 'absolute',
  top: 16,
  right: 16,
}));
