import { createModel } from '@rematch/core';
import type { RootModel } from '.';
import { IAgoraRTCClient } from 'agora-rtc-sdk-ng';

export interface LiveBoardState {
  agoraClients: {
    [key: string]: IAgoraRTCClient;
  };
  currentPlayEventId: string;
  videoTracks: {
    [key: string]: any;
  };
}

const initState: LiveBoardState = {
  agoraClients: {},
  currentPlayEventId: '',
  videoTracks: {},
};

export const liveBoard = createModel<RootModel>()({
  state: initState,
  selectors: (slice) => ({
    getAgoraClients: () => {
      return slice((liveBoard: LiveBoardState) => {
        return liveBoard.agoraClients;
      });
    },
    getCurrentPlayEventId: () =>
      slice((liveBoard: LiveBoardState) => liveBoard.currentPlayEventId),
    getVideoTracks: () =>
      slice((liveBoard: LiveBoardState) => liveBoard.videoTracks),
  }),
  reducers: {
    addAgoraClient: (
      state,
      payload: {
        [key: string]: IAgoraRTCClient;
      },
    ) => {
      state.agoraClients = { ...state.agoraClients, ...payload };
    },
    addVideoTrack: (
      state,
      payload: {
        [key: string]: any;
      },
    ) => {
      state.videoTracks = { ...state.videoTracks, ...payload };
    },
    setCurrentPlayEventId: (state, payload: string) => {
      state.currentPlayEventId = payload;
    },
  },
});
