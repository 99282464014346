import {
  Dispatch,
  useEffect,
  useRef,
  useState,
  useSyncExternalStore,
} from 'react';
import { SocketConnection as OldSocketConnection } from '~socket/socket-connection';
import { SocketManagement } from '~socket/socket-management';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import { FEATURE_FLAGS_TYPE } from '~utils';
import { ASocketConnection } from '~socket/types/SocketConnection';

export const useSocket = (userId: string, dispatch: Dispatch<unknown>) => {
  const { isEnable } = useFeatureFlag();
  const isEnableNewSocket = isEnable(
    FEATURE_FLAGS_TYPE.ACTIVE_NEW_SOCKET_MANAGEMENT,
  );
  const [socket, setSocket] = useState<ASocketConnection | null>();

  const [preSocket, setPreSocket] = useState<ASocketConnection | null>();
  const socketManagement = useRef<SocketManagement>(new SocketManagement());
  const newSocket = useSyncExternalStore(
    socketManagement.current.subscribe,
    socketManagement.current.getSnapShot,
  );

  useEffect(() => {
    if (!isEnableNewSocket) {
      if (!preSocket || preSocket.getUserId() !== userId) {
        preSocket?.close();
        const newSocket = OldSocketConnection.createSocket(
          dispatch,
          userId,
          setSocket,
        );
        setPreSocket(newSocket);
      }
    }
  }, [dispatch, isEnableNewSocket, preSocket, userId]);

  useEffect(() => {
    if (!isEnableNewSocket) {
      const interval = setInterval(() => {
        if (socket?.isClose()) {
          socket?.close();
          console.log(`Socket: Attempt reconnecting to Socket Server...`);
          const newSocket = OldSocketConnection.createSocket(
            dispatch,
            userId,
            setSocket,
          );
          setPreSocket(newSocket);
        }
      }, 1000);
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [dispatch, isEnableNewSocket, socket, userId]);

  useEffect(() => {
    if (isEnableNewSocket) {
      socketManagement.current.createNewSocket(dispatch, userId);
    }
  }, [dispatch, isEnableNewSocket, userId]);

  useEffect(() => {
    if (isEnableNewSocket) {
      setSocket(newSocket);
    }
  }, [isEnableNewSocket, newSocket]);

  return socket;
};
