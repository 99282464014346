import React, { useMemo, useState } from 'react';
import { alpha, Box, styled, Typography } from '@mui/material';
import {
  InstagramSVG,
  FacebookSVG,
  LinkedInSVG,
  TikTokSVG,
  TwitterSVG,
} from '~components/icons';
import { PagesPath, RoutesPages } from '../../../router/types';
import { Link } from '~components/atoms';
import { socialNetworkLinks } from '~constants/social-network-links';
import { useLocation } from 'react-router-dom';
import TermsServiceModal from '~components/organisms/popup/terms-service-modal';
import PrivacyPolicyModal from '~components/organisms/popup/privacy-policy-modal';
import RefundPolicyModal from '~components/organisms/popup/refund-policy-modal';
import useTikTokEvent from '~hooks/useTiktokPixel';

interface LinksProps {
  redirectToPage: (path: PagesPath) => void;
}

const MODAL_TYPE = {
  NONE: 'NONE',
  TERMS_SERVICE: 'TERMS_SERVICE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  REFUND_POLICY: 'REFUND_POLICY',
};

const Links = ({ redirectToPage }: LinksProps) => {
  const location = useLocation();
  const [modalType, setModalType] = useState(MODAL_TYPE.NONE);

  const openTermsServiceModal = () => {
    setModalType(MODAL_TYPE.TERMS_SERVICE);
  };

  const openRefundPolicy = () => {
    setModalType(MODAL_TYPE.REFUND_POLICY);
  };

  const closeModal = () => {
    setModalType(MODAL_TYPE.NONE);
  };

  const linksInfo = useMemo(
    () => [
      {
        name: 'ABOUT',
        path: RoutesPages.ABOUT,
      },
      {
        name: 'TIPS FOR PROMOTING',
        path: RoutesPages.PROMOTION_TIP,
      },
      {
        name: 'FAQ',
        path: RoutesPages.FAQ,
      },
      // {
      //   name: "COLLECTIBLE FAQ'S",
      //   path: RoutesPages.COLLECTIBLES,
      // },
      {
        name: 'TERMS OF SERVICE',
        onClick: openTermsServiceModal,
      },
      {
        name: 'PRIVACY POLICY',
        path: RoutesPages.PRIVACY_POLICY,
      },
      {
        name: 'REFUND POLICY',
        onClick: openRefundPolicy,
      },
      {
        name: 'CONTACT US',
        onClick: () => (window.location.href = 'mailto:support@ursalive.com'),
      },
    ],
    [],
  );
  const tiktokPixel = useTikTokEvent();
  const sendClickEvent = (socialNetwork: string) => {
    tiktokPixel.trackClickEvent(`click_footer_${socialNetwork}_social_btn`);
  };
  return (
    <>
      <Wrapper>
        <Box>
          <Artists>ARTISTS,</Artists>
          <Unscripted>UNSCRIPTED</Unscripted>
          <SocialNetworks>
            <Link
              onClick={() => sendClickEvent('instagram')}
              inNewTab
              href={socialNetworkLinks.instagram}
            >
              <InstagramSVG />
            </Link>
            <Link
              onClick={() => sendClickEvent('facebook')}
              inNewTab
              href={socialNetworkLinks.facebook}
            >
              <FacebookSVG />
            </Link>
            <Link
              onClick={() => sendClickEvent('tiktok')}
              inNewTab
              href={socialNetworkLinks.tikTok}
            >
              <TikTokSVG />
            </Link>
            <Link
              onClick={() => sendClickEvent('linkedin')}
              inNewTab
              href={socialNetworkLinks.linkedIn}
            >
              <LinkedInSVG />
            </Link>
            <Link
              onClick={() => sendClickEvent('twitter')}
              inNewTab
              href={socialNetworkLinks.twitter}
            >
              <TwitterSVG />
            </Link>
          </SocialNetworks>
        </Box>
        <NavLinks>
          {linksInfo.map(({ path, name, onClick }) => (
            <RedirectTo
              key={name}
              sx={(theme) => ({
                color:
                  path === location.pathname
                    ? theme.palette.text.primary
                    : alpha(theme.palette.text.primary, 0.75),
              })}
              onClick={() => {
                if (onClick) {
                  onClick();
                  return;
                }
                path && redirectToPage(path);
              }}
            >
              {name}
            </RedirectTo>
          ))}
        </NavLinks>
      </Wrapper>
      <TermsServiceModal
        show={modalType === MODAL_TYPE.TERMS_SERVICE}
        onClose={closeModal}
      />
      <PrivacyPolicyModal
        show={modalType === MODAL_TYPE.PRIVACY_POLICY}
        onClose={closeModal}
      />
      <RefundPolicyModal
        show={modalType === MODAL_TYPE.REFUND_POLICY}
        onClose={closeModal}
      />
    </>
  );
};

export default Links;

const Wrapper = styled(Box)(({ theme }) => ({
  minHeight: 430,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 20px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundImage:
    'url("assets/images/decorations-landing-page/social-networks-bg.jpg")',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.up('md')]: {
    padding: '24px 40px',
  },
  [theme.breakpoints.up('sm')]: {
    padding: '24px 80px',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Artists = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  fontWeight: 600,
  lineHeight: '47px',
  [theme.breakpoints.up('md')]: {
    fontSize: 40,
  },
}));

const Unscripted = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  color: 'transparent',
  fontWeight: 700,
  marginTop: -5,
  WebkitTextStroke: `0.7px ${theme.palette.text.primary}`,
  [theme.breakpoints.up('md')]: {
    fontSize: 40,
    marginTop: -15,
  },
}));

const SocialNetworks = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 20,
  paddingTop: 24,
  [theme.breakpoints.up('md')]: {
    paddingTop: 40,
  },
}));

const NavLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  paddingTop: 40,
  paddingLeft: 10,
  [theme.breakpoints.up('md')]: {
    paddingTop: 'auto',
  },
}));

const RedirectTo = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  cursor: 'pointer',
  fontWeight: 700,
  fontSize: `${theme.typography.h5.fontSize} !important`,
}));
