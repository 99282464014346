export const accordionsForArtists: {
  question: string;
  answer: JSX.Element | string;
}[] = [
  {
    question: `Is my show in person, or virtual?`,
    answer: `All events on Ursa Live are virtual and can be done from any quiet location with good internet upload speed, including your own home or studio.`,
  },
  {
    question: `What are the technical requirements to perform on Ursa Live?`,
    answer: `You will need a high-speed internet connection with a minimum upload speed of 10 mbps (go to www.speedtest.com to test), a quiet location, and at the bare minimum a laptop with a camera and built-in microphone.
    
 For an Acoustic Set:
    • Laptop or desktop computer with a working camera, or a smart phone
    • Internet upload speed of 10 mb per second or higher
    • Optional: Microphone(s)
    • Optional: Headphones
    • Optional: USB mixing console
    • Optional: Some kind of input device for the mic
 Artists w/ backing tracks or beats will need:
    • Laptop or desktop computer with a working camera - no older than 10 years
    • Internet upload speed of 10 mb per second or higher
    • Microphone
    • Headphones
    • DAW to play the tracks
    • Input device for the mic
    • Optional: USB mixing console`,
  },
  {
    question: `How can I get my fans to attend my livecast?`,
    answer: ` • The best way to let your fans know about your livecast is to promote directly to them via your social media channels, whether that’s Instagram, Facebook, Twitter, TikTok, Discord, or all of them.
  • The most effective posts are videos where you speak directly to your fans and tell them why they should come, and what makes this event special. Be sure to include your rewards!
  • Texts to friends and family and DM’s to special fans are highly effective. If you have a mailing list, be sure to send out an email.
  • If you have a Bands In Town or SongKick Account, we have seen success in driving your fans to your livecast from there as well.`,
  },
  {
    question: `Does Ursa Live promote the livecast?`,
    answer: `All Ursa Live Artist Partners will appear on our homepage when they add an upcoming event. Some artist partners are featured in our weekly mailing list and promoted on our social media channels. To apply to be an artist partner record a video in your Audio/Video Dashboard (under your profile's drop down menu) or Book a FREE Producer (also under your profile's drop down menu).`,
  },
  {
    question: `Why am I required to promote the show?`,
    answer: `In most cases (with the exception of Series Nights), Ursa Live does not provide an audience for your livecast. The best way to let your fans know about your event is to promote it directly to them on your social media channels, text your friends and family, and send an email to your mailing list.`,
  },
  {
    question: `How can I make money?`,
    answer: `Artists make 75% of all tips generated during a livecast.`,
  },
  {
    question: `How does Payment work?`,
    answer: `You can initiate payout from your MANAGE PROFILE dashboard as long as you have at least $20.00 or 200 live coin in your wallet. Payouts are made via Venmo, Paypal, or Bank transfer whenever you want. You will receive 75% of all tips generated by your profile. Ursa Live takes a 25% fee (the lowest of all music live-streaming platforms).`,
  },
  {
    question: `How long is each livecast?`,
    answer: `There's no set rule but most livecast are somewhere between 30 minutes and a few hours. We encourage you to go as long as you want so that you can include a lot of conversation and interaction, answer fan questions, share stories about the songs and what inspires you, give shout-outs, and acknowledge your tippers. The longer you stream, the more tips you will generate. For Series Nights we like to see roughly 60 minutes.`,
  },
  {
    question: `What kind of rewards should I offer my top tippers?`,
    answer: `You can offer many different incentives to your fans to encourage tipping. Some ideas include autographed merch, a guest list spot to an in person show, a song request on your next livecast, a one-on-one meet and greet, a music lesson, an unreleased demo/song, or a personalized thank you video.`,
  },
  {
    question: 'When do I have to add the promo link to my bio?',
    answer:
      'At least 48 hours prior to your event you should start promoting. This gives your fans enough time to see your posts multiple times before the event and leads to good attendance.',
  },
  {
    question: 'WHEN and WHAT do I need to post for the Instagram grid post?',
    answer:
      'You can post a photo or a video at least 2 days before your event. We recommend a video of yourself speaking directly to your fans about your upcoming livecast and why they should come.  Mention you’ll be sharing stories, taking questions, and performing. Talk about the rewards you’re offering and don’t be shy about saying you would appreciate their support! Don’t forget to include the date and time and that there’s a calendar invite at the link so they don’t forget. Tag Ursa Live!',
  },
  {
    question: 'What do I post for the video on the day of the event?',
    answer:
      'We recommend a video of yourself speaking directly to your fans about your upcoming livecast and why they should come. Mention you’ll be sharing stories, taking questions, and performing. Talk about the rewards you’re offering and don’t be shy about saying you would really appreciate their support! Don’t forget to include the date and time and that there’s a calendar invite at the link so they don’t forget. And tag Ursa Live!',
  },
  {
    question: 'What format or “Run of Show” do you recommend for my event?',
    answer:
      'We encourage you to tell a story about the song you’re about to play, play that song, then take a question. Give a shout out to your tippers. Mention your rewards again (for any new comers) and REPEAT! Interactive livecasts where artists take questions and interact with fans between songs generate the most tips. ',
  },
  {
    question: 'What platform is used to payout the tips?',
    answer: 'Payments are made via Venmo, PayPal, or bank transfer.',
  },
  {
    question: 'Do I need to sign any contracts?',
    answer:
      'No, Ursa Live events are non-exclusive and contracts are not required. Like all platforms, you will need to agree to our Terms and Conditions and Privacy Policy when making an account.',
  },
  {
    question:
      'Do you have any archived livecasts you could share with us so we can better understand the experience?',
    answer: 'Not at this time, but please check back soon!',
  },
  {
    question:
      'Is Ursa Live only for original artists or are cover artists eligible?',
    answer:
      'All artists are welcome whether you play originals, covers, or the didgeridoo!',
  },
  {
    question: "I don't speak English, can I still perform on Ursa Live?",
    answer:
      'Yes! We work with artists from all over the world and welcome any and all artists! ',
  },
  {
    question:
      'Can I receive a stipend to cover the cost of a venue or equipment for my show?',
    answer:
      'Unfortunately, we do not pay for venues or equipment, but we do offer one-on-one support to help you get set up.',
  },
  {
    question: 'Can I use my phone to stream on Ursa?',
    answer:
      'Yes! There are many ways to approach streaming with a phone. If your livecast is going to be acoustic in nature, you can simply use the mic on your phone. There are also mics that plug directly into your phone. Additionally, we recommend Streamlabs as an option and our producers will walk you through the setup process if you book a one-on-one.',
  },
  {
    question:
      'How do I get paid if Paypal or Venmo is not available in my country?',
    answer:
      'You can enter your bank account information and we can send payment directly to your account.',
  },
  {
    question: 'Who owns the rights to the songs performed?',
    answer:
      'As far as the ownership of the songs, artists retain all rights to their original music.',
  },
  {
    question: 'Who owns the rights to the livecast?',
    answer:
      'Ursa Live does not archive video footage from livecasts at the moment. ',
  },
  {
    question: 'Does Ursa Live provide an audience?',
    // eslint-disable-next-line max-len
    answer: `Ursa Live does not provide an audience for most events (with the exception of Series Nights), but we support artists by confirming their audio and video are set up properly. In addition, we provide the opportunity for artists to book free one-on-one sessions to help artists improve their promotional efforts. Artists are encouraged to promote their livecasts to their own fan bases as Ursa Live does not guarantee an audience for any events. What makes Series Nights so special is that all participating artists perform back to back with the goal of sharing with fans, which ultimately gives you a chance to perform for new audiences around the world. Additionally, all artists are required to tag each other in social media posts promoting the night, which leads to extra exposure for everyone involved! Please contact support@ursalive.com if you're interested in participating in a Series Night.`,
  },
  {
    question: 'Are payments made in U.S Dollars?',
    answer:
      'Ursa Live makes all payments in USD via your preferred payment method (PayPal, Venmo, or bank transfer) which may automatically convert the payment into your local currency.',
  },
  {
    question: 'What percentage does Ursa Live take of my tips?',
    answer: `Ursa Live takes a 25% fee on all tips paid out. We're proud to say the this percentage is the lowest commision of all music live streaming platforms.`,
  },
  {
    question: 'Does Ursa Live help promote my livecast?',
    answer:
      'Ursa Live does select certain artists to promote on our social channels and via our mailing list, but we can’t guarantee this to all artists.',
  },
  {
    question: 'Do fans need to sign up to view my livecast?',
    answer:
      'No, but they’ll need to sign up to interact in the chat, join the fan spotlight, and tip, so we suggest to all artists that they encourage their attendees to sign up.',
  },
  {
    question:
      'Is there any fee for fans to attend the livescast or are they just encouraged to tip during the show?',
    answer:
      'Fans can attend livecast for free and are not required to tip. Fans are inspired to tip because of the rewards the artist is offering. Often fans tip just because the artist mentions how much they would appreciate the support.',
  },
];

export const accordionsForFans = [
  {
    question: `How do I collect my reward?`,
    answer: `All rewards winners’ information will be shared directly with the artist who is then responsible for fulfilling any rewards.`,
  },
  {
    question: `Why is there an error message when I tap on the event link?`,
    answer: `If you are seeing an error message, please try refreshing your browser in order to access the livecast.`,
  },
];
