import { Fragment, ReactNode } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Stack,
  SxProps,
  Theme,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const StyledFormControlLabel = styled(FormControlLabel)<{ row?: boolean }>(
  ({ theme, row }) => ({
    marginLeft: 0,
    '&:last-child': row ? {} : { marginBottom: 0 },
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
  }),
);

export interface CustomRadioOption {
  name: string;
  label: string;
  value: string;
  icon?: ReactNode;
  extend?: ReactNode;
}

type Props = RadioGroupProps & {
  disabled?: boolean;
  options: CustomRadioOption[];
  sxLabel?: (value: string) => SxProps<Theme>;
  sxOptionLabel?: SxProps<Theme>;
};

export const CustomRadioButton = (props: RadioProps) => (
  <Radio
    disableRipple
    size="small"
    color="warning"
    sx={{
      p: 0,
      color: (theme) => alpha(theme.palette.common.white, 0.7),
    }}
    icon={<RadioButtonUncheckedIcon sx={{ width: 16 }} />}
    checkedIcon={<RadioButtonCheckedIcon sx={{ width: 16 }} />}
    {...props}
  />
);

const CustomRadioGroup = ({
  disabled,
  options,
  sxLabel,
  sxOptionLabel,
  name,
  row,
  ...rest
}: Props) => (
  <RadioGroup
    aria-labelledby="radio-buttons-group-label"
    color="warning"
    name={name}
    row={row}
    {...rest}
  >
    {options.map(({ label, value, icon, extend }) => (
      <Fragment key={value}>
        <StyledFormControlLabel
          disabled={disabled}
          row={row}
          sx={sxLabel && { ...sxLabel(value) }}
          value={value}
          control={<CustomRadioButton />}
          label={
            <Stack direction={'row'} alignItems={'center'} spacing={1} ml={1.5}>
              {icon || null}
              <Typography
                variant="body1"
                fontWeight={'500'}
                lineHeight={'14px'}
                sx={sxOptionLabel && { ...sxOptionLabel }}
              >
                {label}
              </Typography>
            </Stack>
          }
        />
        {extend || null}
      </Fragment>
    ))}
  </RadioGroup>
);

export default CustomRadioGroup;
