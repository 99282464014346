import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import Contact from './contact';
import Links from './links';
import { PagesPath } from '../../../router/types';
import { useNavigate } from 'react-router-dom';

const Footer = ({
  hideContact,
  showApplySeriesNightButton = false,
}: {
  hideContact: boolean;
  showApplySeriesNightButton?: boolean;
}) => {
  const navigate = useNavigate();
  const redirectToPage = (path: PagesPath) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  };

  return (
    <Wrapper>
      {!hideContact && (
        <Contact showApplySeriesNightButton={showApplySeriesNightButton} />
      )}
      <Links redirectToPage={redirectToPage} />
      <Block>
        <Corp>
          Copyright © {new Date().getFullYear()} by URSA All Right Reserved{' '}
        </Corp>
      </Block>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Block = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.footer.main,
  padding: '35px 20px',
}));

const Corp = styled(Typography)(({ theme }) => ({
  color: theme.palette.footer.light,
  fontSize: theme.typography.subtitle2.fontSize,
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },
}));
