import { RoutesPages } from '../../../router/types';
import { LandingPages } from '../header-landing-page/types';

export const NAV_ITEMS = [
  {
    name: LandingPages.HOME,
    route: RoutesPages.HOME,
  },
  {
    name: LandingPages.FOR_ARTISTS,
    route: RoutesPages.FOR_ARTISTS,
  },
  // {
  //   name: LandingPages.COLLECTIBLES,
  //   route: RoutesPages.COLLECTIBLES,
  // },
  // {
  //   name: LandingPages.CONTACT,
  //   route: RoutesPages.CONTACT,
  // },
  {
    name: LandingPages.FAQ,
    route: RoutesPages.FAQ,
  },
  {
    name: LandingPages.ABOUT,
    route: RoutesPages.ABOUT,
  },
];
