// helmet
// components
import { Container } from '~components/atoms';
import LandingPageWrapper from '~components/wrapppers/landing-page-wrapper';
import HomeView from '~components/pages/home';
import SEO from '~components/atoms/seo';
// providers
import { ManageProfileProvider } from '~providers/ManageProfileProvider';

const HomePage = () => {
  return (
    <>
      <SEO
        title="Ursa Live Homepage"
        imgUrl="https://ursalive.com/assets/images/logo-bg.png"
        imgAlt="Ursa Live"
        url="https://ursalive.com/"
        description="Experience unscripted events with your favorite artists, in an intimate virtual setting"
      />
      <Container>
        <ManageProfileProvider>
          <LandingPageWrapper>
            <HomeView />
          </LandingPageWrapper>
        </ManageProfileProvider>
      </Container>
    </>
  );
};

export default HomePage;
