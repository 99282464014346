import cookie from 'cookie';

/**
 * Checks if a valid string;
 * @param val: number/string/object/array != (undefined or null)
 */
export const validValue = (val) =>
  typeof val !== 'undefined' &&
  val !== undefined &&
  val !== null &&
  val !== 'undefined';

/**
 * Get window width and height
 */
export const getWindowDimensions = () => {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;
  return {
    width: x,
    height: y,
  };
};

/**
 * get element offset (width & height)
 * @param el: object
 */
export const getElementOffset = (el) => {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
};

/**
 * Serailize json to query string
 * @param {*} object
 */
export const jsonToQueryString = (obj) =>
  strictValidObject(obj) &&
  Object.keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');

/**
 * Checks if a valid string
 * @param str: string
 */
export const strictValidString = (str) => !!str && typeof str === 'string';

/**
 * Checks if a valid string and validate with min length.
 * @param str: string
 */
export const strictValidStringWithMinLength = (str, length = 1) =>
  !!str && typeof str === 'string' && str.length >= length;

/**
 * Checks if a valid string which when split with a delimeter will give an array with specified minimum length
 * @param str: string
 * @param delimeter: string
 * @param minLength: integer
 */
export const strictValidSplittableStringWithMinLength = (
  str,
  delimeter,
  minLength,
) =>
  strictValidString(str) &&
  strictValidArrayWithMinLength(str.split(delimeter), minLength);

/**
 * Typecast or converts forcefully a string, an object or an array to string else returns null
 * @param str: string
 */
export const typeCastToString = (str) =>
  (!!str &&
    ((strictValidString(str) && str) ||
      str.toString() ||
      JSON.stringify(str))) ||
  '';

/**
 * Capitalizes the first letter of every word in string
 * @param str: string
 */
export const capitalizeFirstLetter = (str) =>
  (strictValidString(str) &&
    // eslint-disable-next-line no-undef
    str.replace(FIRST_CHARACTER_IN_WORDS, (l) => l.toUpperCase())) ||
  null;

/**
 * Capitalizes the first letter of every word in string but not word after apostrophe
 * @param str: string
 */
export const titleCase = (str = '') => {
  if (!strictValidString(str)) return null;
  const strArr = str.toLowerCase().split(' ');
  for (let i = 0; i < strArr.length; i++) {
    strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
  }
  return strArr.join(' ');
};

/**
 * Get name & extension from fileName
 * @param fileName: string
 */
export const getFileNameAndExtension = (fileName) =>
  (strictValidString(fileName) &&
    strictValidSplittableStringWithMinLength(fileName, '.', 2) && {
      name: fileName.split('.')[0],
      ext: fileName.split('.')[1],
    }) ||
  {};

/**
 * Checks if given value is strictly a number
 * @param num: number
 */
export const strictValidNumber = (num) =>
  validValue(num) && typeof num === 'number' && !isNaN(num);
/**
 * Checks if given value is function
 * @param fn: function
 */
export const isFunction = (fn) => validValue(fn) && typeof fn === 'function';

/**
 * Checks if a valid array
 * @param arr: array
 */
export const strictValidArray = (arr) => arr && Array.isArray(arr);

/**
 * Checks if a valid array with minimum specified length
 * @param arr: array
 * @param minLength: integer
 */
export const strictValidArrayWithMinLength = (arr, minLength) =>
  strictValidArray(arr) && arr.length >= minLength;

/**
 * Checks if a valid array with length
 * @param arr: array
 */
export const strictValidArrayWithLength = (arr) =>
  strictValidArray(arr) && !!arr.length;

/**
 * Checks if a valid object
 * @param obj: object
 */
export const strictValidObject = (obj) =>
  obj &&
  obj === Object(obj) &&
  Object.prototype.toString.call(obj) !== '[object Array]';

/**
 * Checks if a valid object with keys
 * @param obj: object
 */
export const strictValidObjectWithKeys = (obj) =>
  strictValidObject(obj) && !!Object.keys(obj).length;

/**
 * Checks if a valid object with specified keys
 * @param obj: object
 * @param parameterKeys: array
 */
export const validObjectWithParameterKeys = (obj, parameterKeys = []) =>
  strictValidObjectWithKeys(obj) &&
  strictValidArrayWithLength(parameterKeys) &&
  Object.keys(obj).filter((k) => parameterKeys.indexOf(k) > -1).length ===
    parameterKeys.length;

/**
 * Generates a regular expression from a given list of regular expressions
 * @param regExpList: array of regular expression strings
 */
export const concatenateRegularExpressions = (regExpList = []) => {
  let regExp = new RegExp();
  if (strictValidArrayWithLength(regExpList)) {
    try {
      regExp = new RegExp(regExpList.join(''));
    } catch (error) {
      // Do nothing
    }
  }
  return regExp;
};

/**
 * Gets file extension
 * @param fileName: string
 */
export const getFileExtension = (fileName) =>
  (strictValidString(fileName) &&
    fileName.substring(fileName.lastIndexOf('.'), fileName.length)) ||
  '';

/**
 * Typecasts a key value pair (k, v) to string and appends it to or appends a specified string value to it
 * based on appendAfter boolean variable
 * @param k: string
 * @param v: string
 * @param appendString: string
 * @param appendAfter: boolean
 */
export const addKeyValuePairAsString = (
  k,
  v,
  appendString = '',
  appendAfter = true,
) => {
  let str = '';
  if (!appendAfter) {
    str += typeCastToString(appendString);
  }
  if (validValue(v)) {
    if (['string', 'number', 'boolean'].indexOf(typeof v) > -1) {
      str = `${k}: ${typeCastToString(v)}`;
    } else if (strictValidArrayWithLength(v)) {
      str = `${k}: [${v.join(', ')}]`;
    } else {
      str = `${k}: [${JSON.stringify(v)}]`;
    }
  } else {
    str = `${k}: `;
  }
  if (appendAfter) {
    str += typeCastToString(appendString);
  }
  return str;
};

/**
 * Imports all files in a directory
 * @param importedObj: object
 */
export const importImagesFromImageDirectory = (importedObj) => {
  let filesObj = {};
  importedObj.keys().forEach((file) => {
    filesObj[file.replace('./', '')] = importedObj(file).default;
  });
  return filesObj;
};

/**
 * Remove null or undefined key value pairs from an object
 * @param obj: object
 * @param removeEmptyArray: bool
 */
export const removeInValidKeyValuePairs = (obj, removeEmptyArray) => {
  const res = {};
  if (strictValidObjectWithKeys(obj)) {
    Object.values(obj).forEach((v, k) => {
      if (
        (validValue(v) && !strictValidArray(v)) ||
        strictValidArrayWithLength(v) ||
        (removeEmptyArray && strictValidArrayWithLength(v))
      ) {
        res[Object.keys(obj)[k]] = v;
      }
    });
  }
  return res;
};

/**
 * Formatting number for thousand seperator
 */
export const formatNumber = (num) =>
  // eslint-disable-next-line no-undef
  strictValidNumber(num) && num.toString().replace(VALID_LARGE_NUMBER, '$1,');

/**
 * Formatting number for thousand seperator
 *
 */
export const formatNumberWithCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

/**
 * Converts integer to double digit example 9 -> 09, 12 -> 12, 992 -> 992
 * @param integer: string
 */
export const integerToDoubleDigit = (integer) =>
  (strictValidNumber(integer) && ('0' + integer).slice(-2)) || '00';

/**
 * Add http to the url;
 * @param integer: string
 */
export const addhttp = (url) => {
  if (strictValidString(url) && !/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'http://' + url;
  }
  return url;
};

/**
 * Extract file name from aws s3 path
 * @param url: string
 */
export const extractFileName = (url) => {
  const delimeter = '_';
  let response = (strictValidString(url) && url.split('/').pop()) || '';
  let fileNameArr = [];
  if (strictValidSplittableStringWithMinLength(response, delimeter, 1)) {
    fileNameArr = response.split(delimeter);
    fileNameArr.shift();
    response = fileNameArr.join(delimeter);
  }
  return response;
};

export const getHours = (hours) => {
  return hours > 1 ? `${hours} Hrs` : `${hours} Hr`;
};

export const getIntegerValue = (val) => Math.round(val);

/**
 * Round off an Integer to 2 decimal places
 * @param value: Integer
 */
export const roundOffTo2Decimals = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

/**
 * Normalize a phone number
 * @param value: String
 */
export const normalizePhone = (value) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 7)
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10,
  )}`;
};

export const deNormalizePhone = (value) =>
  value ? value.replace(/[^\d]/g, '').slice(0, 10) : value;

/**
 * Note - This will return number with upto one decimal without round Off
 *
 * function('34.567') = '34.5'
 *
 * @param {value}: Number or(string) eg 8 or '8'
 */

export const uptoOneDecimal = (value = '') => {
  // eslint-disable-next-line no-undef
  if (!NUMBER_UPTO_ONE_DECIMAL.test(value) && strictValidString(value)) {
    const index = value.indexOf('.');
    return value.substring(0, index + 2);
  }
  return value ? parseFloat(value) : value;
};

export const uptoTwoDecimal = (value = '') => {
  let val = value;
  // eslint-disable-next-line no-undef
  if (!NUMBER_UPTO_TWO_DECIMAL.test(value) && strictValidString(value)) {
    const index = value.indexOf('.');
    val = value.substring(0, index + 3);
  }
  return val ? parseFloat(val).toFixed(2) : val;
};

/**
 * This function convert Mins To Hrs (Hrs:Mins) even given parameter is nagative.
 * e.g (m = -210) -> (-3:30)
 * @param min: Number
 */
export const convertMinsToHrsMins = (min) => {
  let hrs = Math.floor(min / 60);
  hrs += hrs < 0 ? 1 : 0;
  let min2 = Math.round(Math.abs(min % 60));
  min2 = min2 < 10 ? '0' + min2 : min2;
  return hrs + ':' + min2;
};

export const decimalNoToHours = (value) => {
  if (!value) return '0:00';
  return convertMinsToHrsMins(value * 60);
};

export const decimalNoToDollers = (value) => {
  return `$${uptoTwoDecimal(value)}`;
};

/**
 * Convert minutes to hours and minutes
 */
export function toHoursAndMinutes(mins) {
  if (isNaN(mins) || mins === 0) return '-';

  const hours = Math.floor(mins / 60);
  const minutes = Math.floor(mins % 60);

  let hoursText;
  let minutesText;

  if (hours === 0) {
    hoursText = '';
  } else if (hours === 1) {
    hoursText = `${hours} hour`;
  } else {
    hoursText = `${hours} hours`;
  }

  if (minutes === 0) {
    minutesText = '';
  } else if (minutes === 1) {
    minutesText = `${minutes} minute`;
  } else {
    minutesText = `${minutes} minutes`;
  }

  if (hours === 0 && minutes > 0) {
    return minutesText;
  }

  if (hours > 0 && minutes === 0) {
    return hoursText;
  }

  return `${hoursText} and ${minutesText}`;
}

export const getFirstName = (name) =>
  strictValidString(name) ? name.trim().split(' ')[0] : '';

/**
 * Checks if 2 arrays are same
 * @param {*} arr1
 * @param {*} arr2
 */
export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  for (var i = arr1.length; i--; ) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};

export const normalizeDate = (value) => {
  let year = value && new Date(value).getFullYear().toString().substr(0, 4);
  return new Date(value.setFullYear(year));
};

/**
 * convert Experience into months and years i.e 1.5 converted to 1 year 6 months
 * @param exp: Number
 */

export const convertExperinceToMonthYear = (exp) => {
  let years = 0;
  let months = 0;
  const experience = parseFloat(exp);
  if (strictValidNumber(experience) && !isNaN(experience)) {
    let totalMonths = Math.trunc(experience * 12);
    years = Math.trunc(experience);
    months = totalMonths - 12 * years;
  }
  return {
    years,
    months,
  };
};

/**
 * Get Text from html string
 * @param value: String
 */

export const extractContentFromHtml = (value) => {
  const div = document.createElement('div');
  div.innerHTML = value;
  const text = div.textContent;
  return text && text.trim();
};

export const downloadURI = (uri, name) => {
  const link = document.createElement('a');
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// deformat currency string to proper number
export const formatStrictNumber = (number) =>
  Number(number.replace(/[^0-9.-]+/g, ''));

// parse cookies from server/client
export const parseCookies = (req) =>
  cookie.parse(req ? req.headers.cookie || '' : document.cookie);

// format name
export const formatName = (name) => {
  if (!strictValidStringWithMinLength(name)) return '';
  const nameArr = name.split(' ').filter(strictValidStringWithMinLength);
  if (nameArr.length === 1) return nameArr[0];
  return `${nameArr[0]} ${nameArr[1][0]}.`;
};

export const clipText = (text, reqLength) => {
  let length = reqLength;
  if (!strictValidNumber(length)) {
    length = text && text.length;
  }
  if (strictValidString(text) && text.length > length) {
    const cliped = text.slice(0, length);
    const dot = '...';
    return cliped.concat('', dot);
  }
  return text;
};

// NOTE: Add any key here which you do not want to retain after logout
export const keysToDelete = [
  'top_tips',
  'tips_chronologically',
  'currentEvent',
];

// NOTE: Delete any not-required to retain
export const deleteExtraValues = (values) => {
  const valuesToSend = { ...values };
  keysToDelete.forEach((key) => {
    valuesToSend[key] = undefined;
  });
  return valuesToSend;
};
export const mapEmojsToAmount = [
  { src: '/assets/images/applause-icon.png', amount: 10 },
  { src: '/assets/images/rockon-icon.png', amount: 20 },
  { src: '/assets/images/fire-icon.png', amount: 50 },
  { src: '/assets/images/disk-icon.png', amount: 100 },
  { src: '/assets/images/trophy-icon.png', amount: 200 },
];

// Note- computed_amountIncluded PayPal fee (US - Domestic ) - 3.49% + 49¢ ,unit is dollar
export const mapAmountToPaypalAmount = [
  {
    computed_amount: 5.69,
    amount: 5,
    live_coins: 50,
  },
  {
    computed_amount: 10.87,
    amount: 10,
    live_coins: 100,
  },
  {
    computed_amount: 52.32,
    amount: 50,
    live_coins: 500,
  },
  {
    computed_amount: 104.12,
    amount: 100,
    live_coins: 1000,
  },
];

// Note- computed_amountIncluded stripe fee - 2.9% + 30¢ ,unit is cent
export const mapAmountToStripeAmount = [
  {
    computed_amount: 546,
    amount: 5,
    live_coins: 50,
  },
  {
    computed_amount: 1061,
    amount: 10,
    live_coins: 100,
  },
  {
    computed_amount: 5180,
    amount: 50,
    live_coins: 500,
  },
  {
    computed_amount: 10330,
    amount: 100,
    live_coins: 1000,
  },
];

/**
 * Get emoji ralted to amount
 * @param value: Number
 */
export const getEmojiAssociateToAmount = (val) => {
  let value = 0;
  switch (true) {
    case val > 0 && val < 11:
      value = 10;
      break;
    case val > 10 && val < 21:
      value = 20;
      break;
    case val > 20 && val < 51:
      value = 50;
      break;
    case val > 50 && val < 101:
      value = 100;
      break;
    case val > 100:
      value = 200;
      break;

    default:
      break;
  }
  let src = '/assets/images/ursa-coin.png';
  const emoj = mapEmojsToAmount.find(({ amount }) => amount === value);
  if (validObjectWithParameterKeys(emoj, ['src'])) {
    src = emoj.src;
  }
  return src;
};

export const isTwoStringSame = (first, second) => {
  let isSame = false;
  if (strictValidString(first) && strictValidString(second)) {
    const First = first.toLowerCase();
    const Second = second.toLowerCase();
    isSame = First === Second;
  }
  return isSame;
};

export const delay = (duration) =>
  new Promise((resolve) => setTimeout(resolve, duration));

export const numberFormat = (number, digit = 2) => {
  return parseFloat(number).toFixed(digit);
};

export const isEmptyMessage = (message) => {
  if (!message) {
    return true;
  }
  let isEmptyMessage = true;
  const listText = message.split('\n');
  for (const text of listText) {
    const tmpText = text.trim();
    if (tmpText) {
      isEmptyMessage = false;
      break;
    }
  }
  return isEmptyMessage;
};

export const mappingTipData = (tip) => ({
  ...tip,
  fan_id: tip.fanId,
  display_username: tip.displayUsername,
  image_url: tip.imageUrl,
});

export const mappingChatData = (chat) => ({
  id: chat.id,
  userId: chat.user_id,
  username: chat.username,
  userImageUrl: chat.user_image_url,
  groupId: chat.group_id,
  message: chat.message,
  type: chat.type,
  createdAt: chat.created_at,
});

const MAXIMUM_CHANNEL_NAME = 64;
export const generateChannelName = (username = '', eventId = '') => {
  const channelName = `${username}_${eventId}`;
  if (channelName.length <= MAXIMUM_CHANNEL_NAME) {
    return channelName;
  } else {
    return `${username.substring(
      0,
      MAXIMUM_CHANNEL_NAME - eventId.length - 1,
    )}_${eventId}`;
  }
};
