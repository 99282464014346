import React from 'react';
import { Title } from '../about/title';
import { Description } from '../about/description';
import { Stack, styled } from '@mui/material';

import RewardsImage from '../../../images/decorations-landing-page/rewards.png';

const TITLE = 'Rewards';

const Rewards = () => {
  return (
    <Container>
      <Title sx={{ display: { md: 'none' } }}>{TITLE}</Title>
      <Image src={RewardsImage} alt="Rewards" />
      <TextWrapper>
        <Title
          sx={{
            display: { xs: 'none', md: 'block' },
            textAlign: { md: 'left' },
          }}
        >
          {TITLE}
        </Title>
        <Description
          sx={{
            textAlign: { md: 'left' },
          }}
        >
          Artists choose their rewards and top tippers and one random tipper get
          something in return for their support, (examples include a signed
          piece of merch, a shout out on social media, or a one-on-one
          facetime).
        </Description>
      </TextWrapper>
    </Container>
  );
};

export default Rewards;

const Container = styled(Stack)(() => ({
  position: 'relative',
  padding: '28px 20px',
  background: '#151820',
}));

const TextWrapper = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '40%',
    position: 'absolute',
    top: 40,
    left: 70,
  },
  [theme.breakpoints.up('lg')]: {
    width: '30%',
    top: 45,
    left: '15%',
  },
}));

const Image = styled('img')(({ theme }) => ({
  margin: '15px 0',
  width: '100%',
  height: 'auto',
  [theme.breakpoints.up('md')]: {
    margin: 0,
    // transform: 'scale(0.8)',
  },
}));
