import React from 'react';
import { Box, styled } from '@mui/material';
import { Title, WrapperTitle } from './components';
import { Button } from '~components/atoms';
import { getImageURL } from '~utils/awsUtils';
import { POPUP_TYPE, useGlobalDispatch } from '~utils';
import { TrashIcon } from '~components/icons';
import AvatarMui from '@mui/material/Avatar';

interface ProfilePictureProps {
  imageUrl: string | null;
  setAvatar: (imageUrl: string) => void;
}

const ProfilePicture = (props: ProfilePictureProps) => {
  const { imageUrl, setAvatar } = props;
  const dispatch = useGlobalDispatch();

  const openUploadImage = () => {
    dispatch({
      type: 'app',
      payload: { popup: POPUP_TYPE.EDIT_PROFILE_POPUP },
    });
  };

  return (
    <Box>
      <WrapperTitle>
        <Title>Profile Picture</Title>
      </WrapperTitle>
      <Wrapper>
        <AvatarMui
          src={imageUrl ? getImageURL(imageUrl) : ''}
          sx={{
            width: 96,
            height: 96,
          }}
        />
        <Box
          sx={(theme) => ({
            m: 'auto 20px',
            [theme.breakpoints.up('xs')]: {
              mr: 0,
            },
          })}
        >
          {imageUrl ? (
            <>
              <UpdatePictureBtn onClick={openUploadImage}>
                Edit
              </UpdatePictureBtn>
              <WrapperTrash onClick={() => setAvatar('')}>
                <TrashIcon width={'16'} height={'16'} />
              </WrapperTrash>
            </>
          ) : (
            <UpdatePictureBtn onClick={openUploadImage}>
              Add Profile Picture
            </UpdatePictureBtn>
          )}
          <Note>Must be JPEG, PNG, or GIF and cannot exceed 10MB.</Note>
        </Box>
      </Wrapper>
    </Box>
  );
};

export default ProfilePicture;

const WrapperTrash = styled(Box)(() => ({
  display: 'inline-flex',
  padding: '9px',
  marginLeft: '4px',
  verticalAlign: 'middle',
  height: '38px',
  width: '38px',
  alignItems: 'center',
  justifyContent: 'center',
  background: `rgba(242, 242, 242, 0.2)`,
  borderRadius: '4px',
  cursor: 'pointer',
}));

const UpdatePictureBtn = styled(Button)(({ theme }) => ({
  margin: '0 auto',
  fontSize: theme.typography.fontSize,
  padding: '7px 30px',
  color: theme.palette.text.secondary,
  backgroundColor: '#ffffff',

  [theme.breakpoints.up('xs')]: {
    width: '32px',
  },

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  border: `2px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
  marginTop: 16,
  padding: '20px',
}));

const Note = styled(Box)(() => ({
  marginTop: 8,
  fontSize: '12px',
  lineHeight: '20px',
}));
