import React from 'react';
import { styled, Tabs as TabsMUI, TabsProps } from '@mui/material';

const Tabs = ({ children, ...props }: React.PropsWithChildren<TabsProps>) => {
  return (
    <CustomTabs
      variant="scrollable"
      scrollButtons={'auto'}
      allowScrollButtonsMobile
      {...props}
    >
      {children}
    </CustomTabs>
  );
};

export default Tabs;

const CustomTabs = styled(TabsMUI)(({ theme }) => ({
  maxWidth: '100%',
  '.MuiTabs-indicator': {
    height: 4,
    backgroundColor: theme.palette.action.active,
  },
  '.MuiTabs-scrollButtons': {
    width: 25,
  },
  '.Mui-disabled': {
    opacity: '1 !important',
  },

  padding: '20px 0',
  [theme.breakpoints.up('sm')]: {
    padding: 'auto',
  },
}));
