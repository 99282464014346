import React, { useState, useRef, useMemo, useCallback } from 'react';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import { showErrorToaster } from '~utils/toasterNotification';
import { isEmptyMessage } from '~utils/commonUtils';
import SendIcon from '@mui/icons-material/Send';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { getChatHistory, sendMessage } from '~api/chats';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import {
  IconButton,
  InputAdornment,
  List,
  Stack,
  TextField,
  Typography,
  alpha,
  styled,
  CircularProgress,
  Box,
} from '@mui/material';
import { ListProps } from '@mui/material/List/List';
// lodash
import { isEmpty } from 'lodash';
//
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
//
import ArtistChatItem from './artist-chat-item';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { ChatGroupResponse } from '~types/chats';
import { FEATURE_FLAGS_TYPE } from '~utils/constants';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledTextField = styled(TextField)(({ theme }) => ({
  position: 'absolute',
  bottom: 30,
  [theme.breakpoints.down('md')]: {
    '.MuiOutlinedInput-root': {
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

const ListUI = React.forwardRef<HTMLDivElement, ListProps>(
  ({ children, style }, listRef) => {
    return (
      <List style={{ ...style }} component="div" ref={listRef} dense>
        {children}
      </List>
    );
  },
);

const Loading = () => {
  return (
    <Stack alignItems="center" justifyContent="center">
      <CircularProgress size={14} sx={{ color: 'white' }} />
    </Stack>
  );
};

const NoItem = () => {
  return (
    <Stack alignItems="center" justifyContent="center">
      no more items
    </Stack>
  );
};

const TOTAL_ITEM_PAGE = 10;
const ArtistChat = () => {
  const theme = useTheme();
  const { isEnable } = useFeatureFlag();

  const isEnableBlockUser = isEnable(FEATURE_FLAGS_TYPE.BLOCKED_USER);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const isLaptopOrTabletScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useGlobalDispatch();
  const [msg, setMsg] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const chatElement = useRef<VirtuosoHandle>(null);
  const {
    config: { chatGroupId },
    app: { chatList, blockedUsername, hasMoreChat, latestMessageId },
    user: { id: userId },
  }: // TODO need to define data type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, any> = useGlobalState();

  const [firstItemIndex, setFirstItemIndex] = useState(100000);

  const { isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.CHAT_MESSAGE_HISTORY, chatGroupId, latestMessageId],
    queryFn: () =>
      getChatHistory(chatGroupId, latestMessageId, TOTAL_ITEM_PAGE),
    onSuccess: ({ chats, hasNextData }: ChatGroupResponse) => {
      setFirstItemIndex((pre) => pre - TOTAL_ITEM_PAGE);
      dispatch({
        type: 'app',
        payload: {
          chatList: [...chats, ...chatList],
          hasMoreChat: hasNextData,
          latestMessageId: chats?.length > 0 ? chats[0].id : undefined,
        },
      });
    },
    enabled: false,
  });

  const filteredChatList = useMemo(() => {
    if (userId && !isEmpty(blockedUsername) && isEnableBlockUser) {
      return chatList.filter(
        (record: { username: string }) =>
          blockedUsername.indexOf(record.username) < 0,
      );
    }
    return chatList;
  }, [blockedUsername, isEnableBlockUser, userId, chatList]);

  const chatListLength = useMemo(
    () => filteredChatList.length,
    [filteredChatList],
  );

  const getMoreChat = useCallback(async () => {
    // if (!atTop) return;
    if (isFetching) return;
    if (!hasMoreChat) return;
    refetch();
  }, [hasMoreChat, isFetching, refetch]);

  const handleSubmit = async () => {
    setShowEmojiPicker(false);
    if (!userId) {
      dispatch({
        type: 'app',
        payload: { popup: 'log-in' },
      });
      return;
    }
    if (isEmptyMessage(msg) || !chatGroupId) return;
    setMsg('');
    try {
      const {
        data: { chat },
      } = await sendMessage(msg, chatGroupId);
      const updatedChatList = [...chatList, chat];
      dispatch({ type: 'app', payload: { chatList: updatedChatList } });
    } catch (e) {
      // console.log('Send private text error', e);
      showErrorToaster("Couldn't send message");
    }

    return true;
  };

  const handleKeyDown = async (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault();
      const success = await handleSubmit();
      if (success) {
        setMsg('');
      }
    }
  };

  const handleEmojiClick = (emojiObject: EmojiClickData) => {
    const cursorStart = inputRef?.current?.selectionStart;
    const cursorEnd = inputRef?.current?.selectionEnd;
    setMsg(
      (message) =>
        message.slice(0, cursorStart ?? 0) +
        emojiObject.emoji +
        message.slice(cursorEnd ?? 0),
    );

    const newCursor = (cursorStart ?? 0) + emojiObject.emoji.length;
    setTimeout(() => {
      inputRef?.current?.setSelectionRange(newCursor, newCursor);
    }, 20);
  };

  return (
    <Stack height={'100%'} position={'relative'}>
      {chatListLength > 0 ? (
        <Virtuoso
          followOutput={(isAtBottom: boolean) => {
            if (isAtBottom) {
              return 'smooth'; // can be 'auto' or false to avoid scrolling
            } else {
              return false;
            }
          }}
          firstItemIndex={firstItemIndex}
          initialTopMostItemIndex={chatListLength - 1}
          startReached={getMoreChat}
          // atTopStateChange={getMoreChat}
          overscan={200}
          ref={chatElement}
          style={{ height: 'calc(100% - 78px)' }}
          components={{
            Header: isFetching ? Loading : hasMoreChat ? undefined : NoItem,
            List: ListUI,
          }}
          data={filteredChatList}
          itemContent={(index, item) => (
            <ArtistChatItem key={item.id} item={item} />
          )}
        />
      ) : (
        <Typography variant="subtitle2" mt={1}>
          Please enter a message below.
        </Typography>
      )}
      <Box sx={{ position: 'absolute', bottom: 80, right: 20 }}>
        <EmojiPicker
          onEmojiClick={handleEmojiClick}
          theme={Theme.DARK}
          lazyLoadEmojis={true}
          open={showEmojiPicker}
          previewConfig={{ showPreview: false }}
          width={250}
          height={300}
        />
      </Box>
      <StyledTextField
        inputRef={inputRef}
        fullWidth
        margin="dense"
        size="small"
        variant="outlined"
        autoComplete="false"
        placeholder="Send a message"
        onChange={(e) => setMsg(e.target.value)}
        value={msg}
        onKeyDown={handleKeyDown}
        onFocus={() => setShowEmojiPicker(false)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isLaptopOrTabletScreen && (
                <IconButton
                  size="small"
                  onClick={() =>
                    setShowEmojiPicker((showEmojiPicker) => !showEmojiPicker)
                  }
                >
                  <InsertEmoticonIcon
                    sx={{
                      width: (theme) => theme.spacing(2.5),
                      height: (theme) => theme.spacing(2.5),
                      color: (theme) => alpha(theme.palette.text.primary, 0.3),
                    }}
                  />
                </IconButton>
              )}
              <IconButton size="small" onClick={handleSubmit}>
                <SendIcon
                  sx={{
                    width: (theme) => theme.spacing(2.5),
                    height: (theme) => theme.spacing(2.5),
                    color: (theme) => alpha(theme.palette.text.primary, 0.3),
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default ArtistChat;
