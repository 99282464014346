import { Container } from '~components/atoms';
import EventView from '~components/pages/event';
import LandingPageWrapper from '~components/wrapppers/landing-page-wrapper';
import { ManageProfileProvider } from '~providers/ManageProfileProvider';

const EventPage = () => {
  return (
    <Container>
      <ManageProfileProvider>
        <LandingPageWrapper showApplySeriesNightButton={true}>
          <EventView />
        </LandingPageWrapper>
      </ManageProfileProvider>
    </Container>
  );
};
export default EventPage;
