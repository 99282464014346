// @mui
import { Stack, Typography } from '@mui/material';
// components
import { LoadingButton } from '~components/atoms';
//
import { BlockedUser } from '~types/artist';
import { ArtistBlockedUserItemSkeleton } from './artist-unblock-users-skeleton';
import ArtistBlockedUserItem from './artist-blocked-user-item';

type Props = {
  currentUser: BlockedUser | null;
  list: BlockedUser[];
  loading: boolean;
  processing: boolean;
  hasMore: boolean;
  isEmpty: boolean;
  onLoadMore: VoidFunction;
  onUnblock: (user: BlockedUser) => void;
};

const ArtistBlockedUserList = ({
  currentUser,
  loading,
  processing,
  list,
  hasMore,
  isEmpty,
  onLoadMore,
  onUnblock,
}: Props) => {
  const renderSkeleton = loading && !hasMore && (
    <Stack width={1} spacing={2.5}>
      {[...Array(8)].map((_, index) => (
        <ArtistBlockedUserItemSkeleton
          key={`artist-blocked-user-item-${index}`}
        />
      ))}
    </Stack>
  );

  const renderEmpty = isEmpty && (
    <Stack width={1} alignItems="center" minHeight={320} spacing={2.5}>
      <Typography>No Data</Typography>
    </Stack>
  );

  const renderList = !loading && list && (
    <Stack width={1} spacing={2.5}>
      {list.map((item) => (
        <ArtistBlockedUserItem
          key={item.id}
          item={item}
          processing={
            processing && Boolean(currentUser && currentUser.id === item.id)
          }
          onUnblock={() => onUnblock(item)}
        />
      ))}
    </Stack>
  );

  const renderLoadMore = hasMore && (
    <LoadingButton
      type={'submit'}
      variant="contained"
      color="warning"
      loading={loading}
      sx={{ minWidth: 168, height: 56, textTransform: 'unset' }}
      onClick={onLoadMore}
    >
      Show more
    </LoadingButton>
  );

  return (
    <Stack spacing={5} alignItems={'center'}>
      {renderSkeleton}

      {renderList}

      {renderEmpty}

      {renderLoadMore}
    </Stack>
  );
};

export default ArtistBlockedUserList;
