import React, { useEffect, useRef, useState } from 'react';
import {
  MenuItem,
  Select,
  ListItemText,
  Typography,
  Stack,
  styled,
} from '@mui/material';
import { countries } from './countries';

const CountryPhoneCodeSelect: React.FC<any> = ({ onSelected }: any) => {
  const [selectedValue, setSelectedValue] = useState<string>(countries[0].code);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  useEffect(() => {
    const country = countries.find((c) => c.code === selectedValue);
    onSelected({
      dialCode: country?.dial_code,
      countryCode: country?.code,
    });
  }, [selectedValue]);
  const handleKeyDown = (event: React.KeyboardEvent) => {
    const pressedKey = event.key.toLowerCase();

    const countryIndex = countries.findIndex((country) =>
      country.name.toLowerCase().startsWith(pressedKey),
    );

    if (countryIndex !== -1 && selectRef.current) {
      const menuItems =
        selectRef.current.querySelectorAll<HTMLElement>('[role="option"]');
      const targetItem = menuItems[countryIndex];
      targetItem?.scrollIntoView({ block: 'nearest' });
    }
  };
  return (
    <DialSelect
      ref={selectRef}
      sx={{ height: 41 }}
      value={selectedValue}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      renderValue={(value: string) => {
        const country = countries.find((c) => c.code === value);
        return (
          <Stack direction={'row'} spacing={1}>
            <img
              src={`https://flagcdn.com/w40/${country?.code.toLowerCase()}.png`}
              style={{
                minWidth: 25,
                maxWidth: 35,
              }}
            />
            <Typography>{country?.dial_code}</Typography>
          </Stack>
        );
      }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 368,
            overflowY: 'auto',
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {countries.map((country) => (
        <MenuItem key={country.code} value={country.code}>
          <ListItemText
            primary={`${country.emoji} ${country.name} (${country.dial_code})`}
          />
        </MenuItem>
      ))}
    </DialSelect>
  );
};

export default CountryPhoneCodeSelect;

const DialSelect: any = styled(Select)(({ theme }) => ({
  width: 120,
  [theme.breakpoints.up('sm')]: {
    width: 150,
  },
}));
