import React from 'react';
import { alpha, Box, Grid, Stack, styled, Typography } from '@mui/material';
import { formatDateTime } from '~utils/dateTimeUtils';
import {
  SHORT_DATE,
  SHORT_TIME_WITH_TIME_ZONE,
  SHORT_DATE_TIME_WITH_TIME_ZONE,
} from '~utils/constants';
import { ThumbNail } from '~components/pages/event/ThumbNail';
import useBreakpoints from '~hooks/useBreakpoints';

type Props = {
  username: string;
  artistName: string;
  imageUrl: string;
  genre: string;
  scheduledAt: string;
};

export const EventItem = ({
  username,
  artistName,
  imageUrl,
  genre,
  scheduledAt,
}: Props) => {
  const { isSm } = useBreakpoints();
  const handleClick = () => {
    window.location.href = `/${username}`;
  };
  return (
    <SlideWrapper item onClick={handleClick} xs={12} sm={4}>
      <SlideBorder>
        <ImageWrapper>
          <SlideRatioContainer>
            <ThumbNail src={imageUrl} />
          </SlideRatioContainer>
        </ImageWrapper>
        <SliderContent>
          <InfoContainer>
            {!isSm ? (
              <>
                <ArtistName>{artistName}</ArtistName>
                {genre && <GenreBase>{genre}</GenreBase>}
              </>
            ) : (
              <>
                <ArtistName>{artistName}</ArtistName>
                {genre && <Genre>{genre}</Genre>}
              </>
            )}
          </InfoContainer>
          {!isSm ? (
            <DateContainer>
              <Date pr="2px">{formatDateTime(scheduledAt, SHORT_DATE)}</Date>
              <Date>
                {formatDateTime(scheduledAt, SHORT_TIME_WITH_TIME_ZONE)}
              </Date>
            </DateContainer>
          ) : (
            <Date pr="2px">
              {formatDateTime(scheduledAt, SHORT_DATE_TIME_WITH_TIME_ZONE)}
            </Date>
          )}
        </SliderContent>
      </SlideBorder>
    </SlideWrapper>
  );
};

const DateContainer = styled(Stack)(() => ({
  flexDirection: 'column',
}));

const SliderContent = styled(Box)(({ theme }) => ({
  background: '#151515',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '8px 16px',
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    maxWidth: '50%',
  },
}));

const InfoContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
  },
}));

const GenreBase = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.text.primary, 0.8),
  fontSize: theme.typography.fontSize,
  lineHeight: '15px',
  position: 'relative',
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 300,
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
    maxWidth: '100%',
    marginTop: 10,
  },
}));

const Genre = styled(GenreBase)(({ theme }) => ({
  paddingLeft: 16,
  ':before': {
    content: '""',
    height: 4,
    width: 4,
    backgroundColor: alpha(theme.palette.text.primary, 0.4),
    borderRadius: '50%',
    position: 'absolute',
    top: 'calc(50% - 2px)',
    left: 7,
  },
}));

const ArtistName = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(180deg, #CE7CAC 0%, #7473B6 100%)',
  backgroundClip: 'text',
  fontSize: theme.typography.fontSize,
  color: 'transparent',
  lineHeight: '39px',
  maxWidth: '65%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 500,
  [theme.breakpoints.down('sm')]: {
    whiteSpace: 'wrap',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    lineHeight: 1,
    width: '100%',
    maxWidth: '100%',
  },
}));

const Date = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: '20px',
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
}));

export const SlideWrapper = styled(Grid)(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',

  [theme.breakpoints.down('sm')]: {
    minWidth: '50%',
    width: '50%',
    flex: 1,
  },
}));

export const SlideRatioContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const SlideBorder = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  margin: '6px',
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    margin: '20px 6px',
  },
}));
