import * as React from 'react';
import { SVGProps } from 'react';

const CircleSuccessTickSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="59"
    height="58"
    viewBox="0 0 59 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={`M29.4997 57.6666C21.8968 57.6666 14.6053 54.6464 9.22928 49.2703C3.85324 43.8943 0.833008 36.6028 0.833008
       29C0.833008 21.3971 3.85324 14.1056 9.22928 8.72956C14.6053 3.35351 21.8968 0.333284 29.4997 0.333284C33.9671 
       0.318495 38.3735 1.36983 42.353 3.39995C42.5876 3.51902 42.7965 3.68313 42.9677 3.88292C43.1389 4.08271 43.2691 
       4.31426 43.3508 4.56436C43.4325 4.81446 43.4642 5.07821 43.444 5.34054C43.4238 5.60287 43.3521 5.85866 43.233 
       6.09328C43.1139 6.32791 42.9498 6.53679 42.75 6.708C42.5502 6.8792 42.3187 7.00938 42.0686 7.09109C41.8185 7.17281 
       41.5547 7.20446 41.2924 7.18425C41.0301 7.16403 40.7743 7.09235 40.5397 6.97328C37.126 5.21391 33.34 4.29942 29.4997 
       4.30662C24.6222 4.30662 19.8542 5.75262 15.7984 8.46185C11.7426 11.1711 8.58095 15.0219 6.71321 19.5276C4.84547 24.0333 
       4.35543 28.9916 5.30504 33.7757C6.25465 38.5598 8.60127 42.9551 12.0483 46.4058C15.4953 49.8566 19.888 52.208 24.6711 
       53.1627C29.4542 54.1175 34.413 53.6328 38.9207 51.77C43.4284 49.9071 47.2827 46.7496 49.9963 42.6967C52.7099 38.6438 
       54.1611 33.8774 54.1663 29C54.1802 28.7779 54.1802 28.5553 54.1663 28.3333C54.1168 27.8029 54.2801 27.2745 54.6201 
       26.8644C54.9602 26.4543 55.4492 26.1961 55.9797 26.1466C56.5101 26.0971 57.0385 26.2603 57.4486 26.6004C57.8586 26.9405 
       58.1168 27.4295 58.1663 27.9599V29C58.1593 36.6007 55.1368 43.888 49.7623 49.2625C44.3878 54.6371 37.1004 57.6596 29.4997 
       57.6666Z`}
      fill="#20BF55"
    />
    <path
      d={`M29.0195 38.0932C28.7603 38.0971 28.5032 38.0469 28.2644 37.9459C28.0257 37.8449 27.8106 37.6953 27.6329 37.5066L14.7529
       24.8666C14.3783 24.4916 14.168 23.9832 14.168 23.4532C14.168 22.9232 14.3783 22.4149 14.7529 22.0399C15.1357 21.67 15.6472
        21.4633 16.1795 21.4633C16.7119 21.4633 17.2234 21.67 17.6062 22.0399L29.0462 33.3199L54.7795 8.01323C55.1624 7.64337 55.6739
         7.43665 56.2062 7.43665C56.7385 7.43665 57.25 7.64337 57.6329 8.01323C58.0074 8.38823 58.2178 8.89656 58.2178 9.42656C58.2178
          9.95656 58.0074 10.4649 57.6329 10.8399L30.4862 37.5066C30.2953 37.7001 30.0665 37.8521 29.8141 37.9531C29.5618
           38.054 29.2912 38.1017 29.0195 38.0932Z`}
      fill="#20BF55"
    />
  </svg>
);

export default CircleSuccessTickSVG;
