import {
  CustomInput,
  FormRow,
  Title,
  Wrapper,
  WrapperTitle,
} from './components';
import { Box, Grid } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { SocialLinks } from '~types/artist';

interface OutsideLinksProps {
  links: SocialLinks | { [key: string]: string };
  setOutsideLinks: (links: SocialLinks) => void;
  changeStatusIsDataSaved: (status: boolean) => void;
}

const OutsideLinks = (props: OutsideLinksProps) => {
  const { links, setOutsideLinks, changeStatusIsDataSaved } = props;

  const handleOnChange =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      changeStatusIsDataSaved(true);
      links[prop] = event.target.value.trim();
      setOutsideLinks(links as SocialLinks);
    };

  return (
    <Box>
      <WrapperTitle>
        <Title>Outside Links</Title>
      </WrapperTitle>
      <Wrapper>
        <Grid container>
          <FormRow container item>
            <Grid item xs={12} sm={2}>
              Facebook
            </Grid>
            <Grid item xs={12} sm={10}>
              <CustomInput
                size="small"
                fullWidth
                value={links.facebook}
                placeholder="www.facebook.com/marcoj"
                onChange={handleOnChange('facebook')}
              />
            </Grid>
          </FormRow>
          <FormRow container item>
            <Grid item xs={12} sm={2}>
              Instagram
            </Grid>
            <Grid item xs={12} sm={10}>
              <CustomInput
                size="small"
                fullWidth
                value={links.instagram}
                placeholder="www.instagram.com/marcoj"
                onChange={handleOnChange('instagram')}
              />
            </Grid>
          </FormRow>
          <FormRow container item>
            <Grid item xs={12} sm={2}>
              Youtube
            </Grid>
            <Grid item xs={12} sm={10}>
              <CustomInput
                size="small"
                fullWidth
                value={links.youtube}
                placeholder="www.youtube.com/@marcoj"
                onChange={handleOnChange('youtube')}
              />
            </Grid>
          </FormRow>
          <FormRow container item>
            <Grid item xs={12} sm={2}>
              Twitter
            </Grid>
            <Grid item xs={12} sm={10}>
              <CustomInput
                size="small"
                fullWidth
                value={links.twitter}
                placeholder="www.twitter.com/marcoj"
                onChange={handleOnChange('twitter')}
              />
            </Grid>
          </FormRow>
          <FormRow container item>
            <Grid item xs={12} sm={2}>
              Tiktok
            </Grid>
            <Grid item xs={12} sm={10}>
              <CustomInput
                size="small"
                fullWidth
                value={links.tiktok}
                placeholder="www.tiktok.com/marcoj"
                onChange={handleOnChange('tiktok')}
              />
            </Grid>
          </FormRow>
          <FormRow container item>
            <Grid item xs={12} sm={2}>
              Spotify
            </Grid>
            <Grid item xs={12} sm={10}>
              <CustomInput
                size="small"
                fullWidth
                value={links.spotify}
                placeholder="please copy from Spotify"
                onChange={handleOnChange('spotify')}
              />
            </Grid>
          </FormRow>
          <FormRow container item>
            <Grid item xs={12} sm={2}>
              Website
            </Grid>
            <Grid item xs={12} sm={10}>
              <CustomInput
                size="small"
                fullWidth
                value={links.website}
                placeholder="www.marcoj.com"
                onChange={handleOnChange('website')}
              />
            </Grid>
          </FormRow>
        </Grid>
      </Wrapper>
    </Box>
  );
};

export default OutsideLinks;
