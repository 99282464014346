import * as yup from 'yup';

export type TipInputs = {
  amount: string;
  maxAmount: number;
};

// Bank Account
export const tipSchema = yup.object({
  amount: yup
    .string()
    .required('Please select or enter your amount')
    .when('maxAmount', (maxAmount: any, schema: any) => {
      return schema.test({
        test: (amount: string) => {
          if (!amount) return true;
          return parseInt(amount) <= maxAmount;
        },
        message: 'Amount cant greater than available amount',
      });
    }),
  maxAmount: yup.number(),
});
