import React, { useContext } from 'react';
import { Box, Button, Stack, Typography, alpha, styled } from '@mui/material';
import { ScrollBar } from '~components/atoms';
import { useFilterUpcomingLiveCast } from '~components/custom-hook';
import { strictValidArrayWithLength } from '~utils/commonUtils';
import { USER_TYPE } from '~utils/constants';
import { useGlobalState } from '~utils/container';
import { EventCardList } from '~components/molecules';
import { ManageProfileContext } from '~providers/ManageProfileProvider';
import AccountAvatar from '../account/account-avatar';
import { useNavigate } from 'react-router-dom';
import { RoutesPages } from '../../../router/types';

const StyledScrollBar = styled(ScrollBar)(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  overflowY: 'auto',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const StyledEmpty = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: theme.typography.body2.fontSize,
  },
  color: alpha(theme.palette.common.white, 0.7),
  textAlign: 'center',
}));

const YellowLabel = styled('span')(({ theme }) => ({
  color: theme.palette.action.active,
  '&:hover': {
    cursor: 'pointer',
  },
}));

const StreamArtistDetail = () => {
  const navigate = useNavigate();
  const { user, artist, config } = useGlobalState();
  const { openManageProfile } = useContext(ManageProfileContext);
  // TODO need to check state data properties, remove as any to see detail error.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const {
    name: artistName,
    events,
    username: artistUsername,
    displayUsername: userDisplayName,
  } = artist as any;
  const { type } = user;
  const { isArtistLive } = config;

  const filterEvents = useFilterUpcomingLiveCast();
  const isEmpty = !strictValidArrayWithLength(filterEvents);

  const isArtist = type === USER_TYPE.ARTIST;
  const isFan = type === USER_TYPE.FAN;

  const navigateToArtist = () => {
    navigate(RoutesPages.PROMOTION_TIP);
  };
  return (
    <StyledScrollBar>
      <Stack flex={1} px={2} justifyContent={'center'} alignItems={'center'}>
        <Box mt={isArtist && isEmpty ? 'auto' : 0} textAlign={'center'}>
          <AccountAvatar
            avatarSize={48}
            avatarKey={artistUsername}
            userName={artistName || userDisplayName}
            mb={{ xs: 1, md: 2 }}
            justifyContent={'center'}
            maxWidth={220}
          />
          {isArtist && (
            <Stack
              spacing={{ xs: 1, md: 2 }}
              alignItems={'center'}
              textAlign={'center'}
            >
              <Typography
                variant="body2"
                fontWeight={300}
                color={(theme) => alpha(theme.palette.common.white, 0.6)}
                maxWidth={{ xs: 180, md: 246 }}
              >
                Tap the{' '}
                <Typography
                  variant="body2"
                  fontWeight={300}
                  component={'span'}
                  color={'error'}
                >
                  GO LIVE{' '}
                </Typography>
                button above to begin streaming
              </Typography>
              <Stack spacing={{ xs: 2.5, md: 2 }} alignItems={'center'}>
                <Box>
                  <Button
                    variant="contained"
                    color="warning"
                    size="medium"
                    onClick={() => openManageProfile()}
                  >
                    Manage Profile
                  </Button>
                </Box>

                {isEmpty && (
                  <Typography
                    variant="body2"
                    fontWeight={'300'}
                    mt={1}
                    fontSize={{ lg: 12 }}
                    color={(theme) => alpha(theme.palette.common.white, 0.6)}
                    maxWidth={{ xs: 180, md: 250 }}
                  >
                    Tap MANAGE PROFILE to add upcoming livecasts and calendar
                    invitations.
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}

          {isFan && !isArtistLive && !strictValidArrayWithLength(events) && (
            <StyledEmpty>This Artist has not started a livecast.</StyledEmpty>
          )}
        </Box>
        {isArtist && isEmpty && (
          <Typography
            variant="body2"
            fontWeight={500}
            lineHeight={'17px'}
            color={(theme) => theme.palette.common.white}
            fontSize={{ lg: 16 }}
            maxWidth={{ xs: 280, md: 500 }}
            my={{ xs: 2, md: 'auto' }}
            textAlign={'center'}
          >
            Add an upcoming Livecast then promote your livecast on your socials.
            You can review promo guidelines{' '}
            <YellowLabel onClick={navigateToArtist}>HERE</YellowLabel>.
          </Typography>
        )}
      </Stack>
      {!isEmpty && (
        <Box>
          <Typography
            variant="body1"
            textTransform={'uppercase'}
            fontWeight={500}
            textAlign={'center'}
            lineHeight={'20px'}
            mt={2}
            mb={1}
          >
            Upcoming livecasts
          </Typography>
          <EventCardList events={filterEvents} />
        </Box>
      )}
    </StyledScrollBar>
  );
};
export default StreamArtistDetail;
