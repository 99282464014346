import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Button } from '~components/atoms';
import sphere from '~images/decorations-landing-page/sphere.png';
import useTikTokEvent from '~hooks/useTiktokPixel';

const Contact = ({
  showApplySeriesNightButton = false,
}: {
  showApplySeriesNightButton?: boolean;
}) => {
  const tiktokPixel = useTikTokEvent();
  return (
    <Wrapper>
      <Title>SEND US A MESSAGE</Title>
      <CustomButton
        onClick={() => {
          tiktokPixel.trackClickEvent('click_homepage_contact_btn');
          return (window.location.href = 'mailto:support@ursalive.com');
        }}
      >
        contact us
      </CustomButton>
      {showApplySeriesNightButton && (
        <CustomButton
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_APPLY_SERIES_NIGHT_FORM_URL}`)
          }
        >
          Apply to participate in this Series
        </CustomButton>
      )}
      <SphereImage src={sphere} alt="Sphere" />
    </Wrapper>
  );
};

export default Contact;

const Wrapper = styled(Box)(({ theme }) => ({
  background:
    'linear-gradient(94.44deg, #F85711 -15.89%, #E877AE 55.16%, #539C43 112.81%)',
  padding: '54px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    padding: '180px 0',
    gap: 40,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    fontSize: 40,
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  background: theme.palette.text.primary,
  color: theme.palette.text.secondary,
  fontWeight: 700,
  textTransform: 'uppercase',
}));

const SphereImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  right: -230,
  top: -130,
  transform: 'scale(0.5)',
  [theme.breakpoints.up('sm')]: {
    right: -100,
    transform: 'scale(1)',
    top: 22,
  },
}));
