import { createModel } from '@rematch/core';
import type { RootModel } from '.';
import { LiveCoinOptionType } from '~types';

export interface PaymentState {
  selectedLiveCoin?: LiveCoinOptionType;
  showTipPopup: boolean;
}

const initState = {
  selectedLiveCoin: undefined,
  showTipPopup: true,
} as PaymentState;

export const payment = createModel<RootModel>()({
  state: initState,
  selectors: (slice) => ({
    getSelectedLiveCoin: () => {
      return slice((payment: PaymentState) => payment.selectedLiveCoin);
    },
    getShowTipPopup: () => {
      return slice((payment: PaymentState) => payment.showTipPopup);
    },
  }),
  reducers: {
    setSelectedLiveCoin: (state, payload: LiveCoinOptionType) => {
      state.selectedLiveCoin = payload;
    },
    setShowTipPopup: (state, payload: boolean) => {
      state.showTipPopup = payload;
    },
  },
});
