export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

/**
 * * For future responsive
 */
export function responsiveFontSizes({
  sm,
  md,
  lg,
}: {
  sm: number;
  md: number;
  lg: number;
}) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const typography = {
  fontFamily: `Helvetica Neue, Helvetica, Helvetica, Arial, sans-serif, Monoist, Anton`,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontSize: 16,
  h1: {
    fontWeight: 900,
    lineHeight: 46 / 46,
    fontSize: pxToRem(40),
  },
  h2: {
    fontWeight: 800,
    lineHeight: 64 / 48,
    fontSize: pxToRem(28),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 16,
    fontSize: pxToRem(12),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
  },
  body2: {
    lineHeight: '14px',
    fontSize: pxToRem(12),
    ...responsiveFontSizes({ sm: 14, md: 14, lg: 14 }),
  },
  caption: {
    lineHeight: 22 / 18,
    fontSize: pxToRem(10),
  },
  button: {
    fontWeight: 500,
    lineHeight: 24 / 16,
    fontSize: pxToRem(14),
    textTransform: 'uppercase',
  },
} as const;

export default typography;
