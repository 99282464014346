import * as yup from 'yup';
import { VALID_PASSWORD } from '~utils/regex';
import { messages } from '~language/en';

export type ResetPasswordInputs = {
  password: string;
  confirm_password: string;
};

// Bank Account
export const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .required('Please enter your password')
    .matches(VALID_PASSWORD, messages.VALIDATIONS.INVALID_PASSWORD),
  confirm_password: yup
    .string()
    .required('Please confirm your new password')
    .oneOf(
      [yup.ref('password')],
      messages.VALIDATIONS.INVALID_CONFIRM_PASSWORD,
    ),
});
