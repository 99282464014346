import {
  cloneElement,
  ReactNode,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import { RootState, select } from '~stores';
import { useSelector } from 'react-redux';
import { useGlobalState } from '~utils';
import { getImageURL } from '~utils/awsUtils';

type WithAvatarProps = {
  children: ReactNode;
  username: string;
  avatar?: string;
};

const WithAvatar = ({ children, username }: WithAvatarProps) => {
  const globalState = useGlobalState();
  const {
    user: { username: accountName, imageUrl },
    artist: { username: artistUsername, imageUrl: artistImageUrl } = {},
  } = globalState;
  const avatars = useSelector((state: RootState) =>
    select.common.getAvatars(state),
  );
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    setAvatar(
      getImageURL(
        accountName === username
          ? imageUrl
          : artistUsername === username
          ? artistImageUrl
          : avatars[username],
      ),
    );
  }, [
    accountName,
    artistImageUrl,
    artistUsername,
    avatars,
    imageUrl,
    username,
  ]);

  return (
    <>
      {cloneElement(children as ReactElement, {
        src: avatar,
      })}
    </>
  );
};

export default WithAvatar;
