import { createModel } from '@rematch/core';
import type { RootModel } from '.';
import { getUserEvents } from '~api/event';
import { ArtistEvent } from '~types/event';

export interface ArtistState {
  events: ArtistEvent[];
}

const initState: ArtistState = {
  events: [],
};

export const artist = createModel<RootModel>()({
  state: initState,
  selectors: (slice) => ({
    getEvents: () => {
      return slice((artist: ArtistState) => {
        return artist.events;
      });
    },
  }),
  reducers: {
    setEvent: (state, payload: ArtistEvent[]) => {
      state.events = payload;
    },
  },
  effects: (dispatch) => ({
    async fetchArtistEvents(username): Promise<ArtistEvent[]> {
      const { upcoming: events } = await getUserEvents(username, 100);
      dispatch.artist.setEvent(events);
      return events;
    },
  }),
});
