import React, { useState, useCallback, useRef } from 'react';

import Cropper from 'react-easy-crop';
import canvasSize from 'canvas-size';
import { useGlobalState, useGlobalDispatch } from '~utils/container';
import { showErrorToaster } from '~utils/toasterNotification';
import { strictValidString } from '~utils/commonUtils';
import { uploadImage } from '~utils/awsUtils';
import { updateUserAvatar } from '~api/user';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import BasePopup from '~components/organisms/popup/base-popup';
import { Image as ImageIcon, Replay } from '@mui/icons-material';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';

const initialState = {
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 1,
  rotation: 0,
  imageSrc: '',
  fileName: '',
};

const UploadAvatarPopup = () => {
  const [state, setState] = useState(initialState);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [initialCroppedAreaPixels] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const { crop, zoom, aspect, rotation, imageSrc, fileName } = state;
  const globalState = useGlobalState();
  const { showGettingStartedPopup } = useDispatchPopup();
  const rematchDispatch = useDispatch();
  const inputFileRef = useRef(null);
  const {
    app: { popup },
    user = {},
  } = globalState;
  const { username, firstLogin } = user;
  const dispatch = useGlobalDispatch();
  const handleAddFileClick = () => {
    inputFileRef.current.click();
  };
  const onCropChange = (value) => {
    setState((prevState) => ({ ...prevState, crop: value }));
  };

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onZoomChange = (value) => {
    setState((prevState) => ({ ...prevState, zoom: value }));
  };

  const handleChangeFile = (event) => {
    event.stopPropagation() && event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      const [file] = event.target.files;
      let reader = new FileReader();
      reader.onload = (e) => {
        setState((prevState) => ({
          ...prevState,
          imageSrc: e.target.result,
          crop: { x: 0, y: 0 },
          fileName: file.name,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'same-origin'); // need
      image.src = url;
    });

  const getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
  };

  const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    let safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    let maxHeight = null;

    if (window.innerWidth <= 900) {
      maxHeight = 4096;
    } else {
      canvasSize.maxArea({
        onError: () => console.log('Error on Canvas Test'),
        onSuccess(_width, height) {
          maxHeight = height;
        },
      });
    }

    if (maxHeight > 0 && safeArea > maxHeight) {
      safeArea *= maxHeight / safeArea;
    }

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        if (!file) {
          reject(new Error('Canvas is empty'));
          return;
        }
        file.name = fileName;
        file.lastModifiedDate = new Date();
        resolve(file);
      }, 'image/jpeg');
    });
  };

  const navigateToNextPopup = () => {
    showGettingStartedPopup();
  };

  const onSaveChanges = async () => {
    setIsSubmitting(true);
    try {
      const blobImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
      );
      let imageUrl;
      try {
        imageUrl = await uploadImage(blobImage);
      } catch (err) {
        setIsSubmitting(false);
        const ee = strictValidString(err) ? err : 'Error in uploading file';
        showErrorToaster(ee);
        return;
      }

      await updateUserAvatar(imageUrl);
      rematchDispatch.common.addAvatars({ [username]: imageUrl });
      setSuccessMsg('Success');
      navigateToNextPopup();
      dispatch({
        type: 'user',
        payload: { imageUrl },
      });
    } catch (error) {
      setError(error);
      setIsSubmitting(false);
    }
  };

  return (
    <UploadPopupWindow
      open={true}
      hideCloseButton={true}
      title={
        popup === 'edit-profile' && !firstLogin ? 'Edit' : 'UPLOAD A PHOTO'
      }
    >
      {!!error && <ErrorBox>{error}</ErrorBox>}
      {!error && !!successMsg && <SuccessBox>{successMsg}</SuccessBox>}
      <Stack
        sx={{
          alignItems: 'center',
          marginTop: '20px',
          width: '100%',
          paddingX: 2,
        }}
        spacing={3}
      >
        <Box
          sx={{
            height: 400,
            width: '100%',
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            display: 'flex',
            background: 'rgba(29, 33, 45, 1)',
            padding: 0.5,
          }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {imageSrc ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: 2,
                position: 'relative',
              }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={aspect}
                cropShape="round"
                showGrid
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                {...initialCroppedAreaPixels}
              />
              <IconButton
                variant="contained"
                color="warning"
                sx={{
                  textTransform: 'capitalize',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
                onClick={() => setState({ ...state, imageSrc: '' })}
              >
                <Replay />
              </IconButton>
            </Box>
          ) : (
            <Stack spacing={3} alignItems={'center'}>
              <Box
                sx={{
                  width: 200,
                  height: 200,
                  aspectRatio: 1,
                  position: 'relative',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Label htmlFor="avtar">
                  <Input
                    ref={inputFileRef}
                    accept="image/*"
                    id="avtar"
                    type="file"
                    onChange={handleChangeFile}
                  />
                  <Stack
                    sx={{
                      width: '100%',
                      height: '100%',
                      border: 1,
                      borderColor: 'gray',
                      borderRadius: 3,
                      backgroundColor: 'rgba(255, 255, 255, 0.5)',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ImageIcon sx={{ fontSize: 30 }} />
                  </Stack>
                </Label>
              </Box>
              <Button
                variant="contained"
                color="warning"
                sx={{ textTransform: 'capitalize', background: 'white' }}
                onClick={handleAddFileClick}
              >
                Add Profile Picture (optional)
              </Button>
              <Typography textAlign={'center'}>
                Must be JPEG, PNG, or GIF and cannot exceed 10MB.
              </Typography>
            </Stack>
          )}
        </Box>
        <Footer direction={'row'} spacing={3}>
          <Button
            type="submit"
            color="warning"
            variant="outlined"
            sx={{ width: '30%', textTransform: 'capitalize', height: 60 }}
            onClick={navigateToNextPopup}
          >
            Skip
          </Button>
          <Button
            fullWidth
            type="submit"
            color="warning"
            variant="contained"
            sx={{ width: '70%', textTransform: 'capitalize', height: 60 }}
            disabled={
              isSubmitting ||
              !strictValidString(fileName) ||
              !strictValidString(imageSrc)
            }
            onClick={onSaveChanges}
          >
            Save
          </Button>
        </Footer>
      </Stack>
    </UploadPopupWindow>
  );
};

export default UploadAvatarPopup;

const UploadPopupWindow = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  [theme.breakpoints.up('xs')]: {
    width: '90%',
    height: 'fit-content',
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(4),
    maxWidth: 900,
  },
}));

const ErrorBox = styled(Typography)(() => ({
  color: 'red',
}));

const SuccessBox = styled(Typography)(() => ({
  color: '#56BA3C',
}));

const Input = styled('input')({
  display: 'none',
});
const Label = styled('label')(() => ({
  width: '100%',
  height: '100%',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const Footer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
}));
