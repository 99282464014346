import React from 'react';
import { TrashIcon } from '~components/icons';
import { DatePicker, TimePicker } from '~components/atoms';
import { Box, styled, Typography } from '@mui/material';
import { InternalIndentBlock } from '~components/manage-profile/components';
import { Dayjs } from 'dayjs';

interface LiveCastRowProps {
  id?: string;
  date: Dayjs | null;
  time: Dayjs | null;
  deleteLiveCast: () => void;
  changeLiveCast: (date: Dayjs | null, time: Dayjs | null) => void;
  index: number;
  errorMessageTime: null | string;
  errorMessageDate: null | string;
}

const LiveCastRow = ({
  id = '',
  date,
  time,
  deleteLiveCast,
  changeLiveCast,
  index,
  errorMessageTime,
  errorMessageDate,
}: LiveCastRowProps) => {
  return (
    <Row key={id}>
      <TopRow>
        <Title>{`Livecast ${index + 1}`}</Title>
        <TrashIcon onClick={deleteLiveCast} />
      </TopRow>
      <BottomRow>
        <DatePicker
          value={date}
          onChange={(value) => changeLiveCast(value, time)}
          disablePast
          label={'Date'}
          errorMessage={errorMessageDate}
        />
        <TimePicker
          value={time}
          onChange={(value) => changeLiveCast(date, value)}
          label={'Time'}
          errorMessage={errorMessageTime}
          showTimeZone
        />
      </BottomRow>
    </Row>
  );
};

export default LiveCastRow;

const Row = styled(InternalIndentBlock)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '&:not(:last-child)': {
    borderBottom: `2px solid ${theme.palette.secondary.light}`,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.fontSize,
}));

const TopRow = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const BottomRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 16,
  marginTop: 20,
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));
