import React, { useContext } from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import { alpha, Button, Stack, styled, Typography } from '@mui/material';
import {
  CameraIcon,
  GoLiveIcon,
  ShakeHandIcon,
  StarIcon,
} from '~components/icons';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { getRewardPageData } from '~api/reward';
import { FEATURE_FLAGS_TYPE, useGlobalDispatch, useGlobalState } from '~utils';
import { ManageProfileContext } from '~providers/ManageProfileProvider';
import { TABS } from '~components/manage-profile/types';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useFeatureFlag } from '~hooks/useFeatureFlag';

const LiveCastOptionsWindow = styled(BasePopup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  [theme.breakpoints.up('xs')]: {
    width: '90%',
    height: 'fit-content',
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(4),
    width: 620,
  },
}));

const Content = styled(Stack)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
  borderRadius: 8,
  padding: 14,
}));
Content.defaultProps = {
  spacing: 2,
};

const BigButtonIcon = styled(Button)(({ theme }) => ({
  border: `2px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
  paddingTop: 12,
  paddingBottom: 12,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    border: `2px solid ${theme.palette.primary.light}`,
  },

  [theme.breakpoints.up('xs')]: {
    flexWrap: 'wrap',
  },
}));

const GoLiveButton = styled(Button)(({ theme }) => ({
  border: `2px solid ${theme.palette.secondary.light}`,
  borderRadius: 8,
  paddingTop: 12,
  paddingBottom: 12,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.error.main,
}));
const CircularIcon = styled(Stack)(() => ({
  borderRadius: '50%',
  backgroundColor: '#464952',
  width: 40,
  height: 40,
  alignItems: 'center',
  justifyContent: 'center',
}));

const Note = styled(Typography)(() => ({
  textAlign: 'center',
  fontWeight: '500',
  lineHeight: '19.5px',
}));
Note.defaultProps = {
  variant: 'body2',
};

interface LiveCastOptionsModalProps {
  onClose: () => void;
  onGoLive: () => void;
  onOpenPartnershipModal: () => void;
  onOpenShareProfile: () => void;
  onOpenSettingDashboardPopup: () => void;
  open: boolean;
  isPartnerShip: boolean;
}

const LiveCastOptionsModal = ({
  onClose,
  open,
  onGoLive,
  onOpenPartnershipModal,
  onOpenShareProfile,
  onOpenSettingDashboardPopup,
  isPartnerShip,
}: LiveCastOptionsModalProps) => {
  const { openManageProfile } = useContext(ManageProfileContext);
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();
  const { isEnable } = useFeatureFlag();
  const isActivateShareProfile = isEnable(
    FEATURE_FLAGS_TYPE.ACTIVE_SHARE_PROFILE_ON_START_LIVE,
  );

  const {
    artist: { id: artistId },
    app: { activeCampaign },
  } = globalState as {
    app: Record<string, any>;
    artist: { id: string; name: string };
  };
  useQuery({
    queryKey: [QUERY_KEYS.ARTIST_REWARDS],
    queryFn: () => getRewardPageData(artistId),
    enabled: !!artistId,
    onSuccess: (data) => {
      dispatch({
        type: 'app',
        payload: {
          activeCampaign: data.activeCampaign,
          upcomingCampaigns: data.upcomingCampaigns ?? [],
          previousCampaigns: data.previousCampaigns ?? [],
          totalPreviousCampaign: data.totalPreviousCampaign,
          totalUpcomingCampaign: data.totalUpcomingCampaign,
        },
      });
    },
  });

  const handleClickAddReward = () => {
    openManageProfile({ initialTab: TABS.REWARDS });
    onClose();
  };

  return (
    <LiveCastOptionsWindow
      open={open}
      onClose={onClose}
      title="LIVECAST OPTIONS"
    >
      <Stack spacing={{ xs: 2, md: 2 }} mt={{ xs: 2, md: 2 }}>
        <Content>
          <BigButtonIcon
            variant="outlined"
            startIcon={
              <CircularIcon>
                <CameraIcon />
              </CircularIcon>
            }
            onClick={onOpenSettingDashboardPopup}
          >
            SOUND CHECK
          </BigButtonIcon>
          <Note>
            Open your Audio/Video Dashboard and record a 60 second SOUND CHECK
            video for your review
          </Note>
        </Content>
        {!isPartnerShip && (
          <Content>
            <BigButtonIcon
              variant="outlined"
              startIcon={
                <CircularIcon>
                  <ShakeHandIcon />
                </CircularIcon>
              }
              onClick={onOpenPartnershipModal}
            >
              BECOME A PARTNER
            </BigButtonIcon>
            <Note>
              <Typography
                component={'a'}
                variant="body2"
                fontWeight={'500'}
                color="text.primary"
                sx={{ textDecoration: 'underline' }}
              >
                Learn more
              </Typography>{' '}
              about the benefits our partners receive
            </Note>
          </Content>
        )}
        {!activeCampaign && (
          <Content>
            <BigButtonIcon
              onClick={handleClickAddReward}
              variant="outlined"
              startIcon={
                <CircularIcon>
                  <StarIcon />
                </CircularIcon>
              }
            >
              ADD REWARDS
            </BigButtonIcon>
            <Note>
              Your attendees are more likely to tip if you offer rewards
            </Note>
          </Content>
        )}

        {isActivateShareProfile && (
          <Content>
            <BigButtonIcon
              onClick={onOpenShareProfile}
              variant="outlined"
              startIcon={
                <CircularIcon>
                  <PeopleAltIcon />
                </CircularIcon>
              }
            >
              SHARE LIVECAST
            </BigButtonIcon>
          </Content>
        )}
        <GoLiveButton onClick={onGoLive} startIcon={<GoLiveIcon />}>
          GO LIVE
        </GoLiveButton>
      </Stack>
    </LiveCastOptionsWindow>
  );
};

export default LiveCastOptionsModal;
