import { Box, styled } from '@mui/material';

const ScrollBar = styled(Box)(({ theme }) => ({
  '*::-webkit-scrollbar': {
    width: 4,
    backgroundColor: 'transparent',
  },
  '*::-webkit-scrollbar-track': {
    WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
    borderRadius: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  '*::-webkit-scrollbar-thumb': {
    WebkitBoxShadow: `inset 0 0 6px rgba(0,0,0,0.1))`,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.warning.main,
  },
}));

export default ScrollBar;
