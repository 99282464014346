import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import Router from './router';

const Index = () => {
  return (
    <>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </>
  );
};
export default Index;
