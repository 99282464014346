import {
  createLocalAudioTrack,
  createLocalVideoTrack,
} from '~utils/agoraFunctions';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '~stores';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';
import { strictValidArray, useGlobalState } from '~utils';
import { useRematchDispatch } from '~components/custom-hook/useRematchDispatch';
import { useMount, useUpdateEffect } from 'react-use';
import { AgoraClient } from '~types/agora';
import { INPUT_LEVEL_CONFIG_DEFAULT_VALUE } from '~stores/models/settings';
import { ScreenRatioEnum } from '~types';

export const useCameraTrack = (autoClose = false) => {
  const autoCloseRef = useRef(autoClose);

  const {
    selectedCameraConfig,
    selectedScreenRatio: selectedScreenRatioCurrent,
  } = useSelector((state: RootState) => state.settings);
  const [selectedScreenRatio, setSelectedScreenRatio] = useState(
    selectedScreenRatioCurrent,
  );

  const [cameraTrack, setCameraTrack] = useState<ICameraVideoTrack | null>(
    null,
  );

  const onChangeSelectedScreenRatio = (
    newSelectedScreenRatio: ScreenRatioEnum,
  ) => {
    setSelectedScreenRatio(newSelectedScreenRatio);
  };
  const createVideoTrack = useCallback(async () => {
    const cameraConfig = {
      encoderConfig: selectedScreenRatio,
      cameraId: selectedCameraConfig?.deviceId,
    };
    const localVideoTrack = await createLocalVideoTrack(cameraConfig);
    setCameraTrack((preState) => {
      preState?.close();
      return localVideoTrack;
    });
  }, [selectedCameraConfig?.deviceId, selectedScreenRatio]);

  useEffect(() => {
    createVideoTrack();
  }, [createVideoTrack]);

  useEffect(() => {
    if (autoCloseRef.current) {
      return () => {
        // Close camera for case unmonut if needed
        cameraTrack?.close();
      };
    }
  }, [cameraTrack]);

  return { cameraTrack, onChangeSelectedScreenRatio };
};

export const useMicrophoneTrack = (enableAEC = false, autoClose = false) => {
  const autoCloseRef = useRef(autoClose);
  const [microphoneTrack, setMicrophoneTrack] =
    useState<IMicrophoneAudioTrack | null>(null);

  const createMicrophoneTrack = async (
    enableAEC: boolean,
  ): Promise<IMicrophoneAudioTrack> => {
    let microphoneConfig: {
      AEC?: boolean;
    } = {};
    microphoneConfig = {
      ...microphoneConfig,
      AEC: enableAEC,
    };

    return await createLocalAudioTrack(microphoneConfig);
  };

  useEffect(() => {
    const createNewMicrophoneTrack = async () => {
      const newMicrophoneTrack = await createMicrophoneTrack(enableAEC);
      setMicrophoneTrack(newMicrophoneTrack);
    };
    createNewMicrophoneTrack();
  }, [enableAEC]);

  useEffect(() => {
    if (autoCloseRef.current) {
      return () => {
        // Close microphone for case unmonut if needed
        microphoneTrack?.close();
      };
    }
  }, [microphoneTrack]);

  return { microphoneTrack };
};

export const useUpdateCameraAndMicrophone = (
  enableAEC = false,
  enableAudiSetting = true,
) => {
  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config: { localVideoTrack, localAudioTrack },
  } = useGlobalState();
  const [newEnableAEC, setNewEnableAEC] = useState(enableAEC);
  const [playAudio, setPlayAudio] = useState(false);
  const { cameraTrack, onChangeSelectedScreenRatio } = useCameraTrack(true);
  const { microphoneTrack } = useMicrophoneTrack(newEnableAEC, true);
  const videoContainer = useRef(null);
  const {
    inputLevelConfig = INPUT_LEVEL_CONFIG_DEFAULT_VALUE,
    cameraConfigs,
    microphoneConfigs,
    selectedCameraConfig,
    selectedMicrophoneConfig,
    selectedScreenRatio,
  } = useSelector((state: RootState) => state.settings);
  const {
    setCameraConfigs,
    setMicrophoneConfigs,
    setSelectedMicrophoneConfig,
    setSelectedCameraConfig,
  } = useRematchDispatch((dispatch: RootDispatch) => dispatch.settings);

  const [newSelectedMicrophoneConfig, setNewSelectedMicrophoneConfig] =
    useState(selectedMicrophoneConfig);

  const [newSelectedCameraConfig, setNewSelectedCameraConfig] =
    useState(selectedCameraConfig);
  const [vUMeterConfig, setVUMeterConfig] = useState(0);
  const [newInputLevelConfig, setNewInputLevelConfig] = useState(
    inputLevelConfig || INPUT_LEVEL_CONFIG_DEFAULT_VALUE,
  );
  const [newSelectedScreenRatio, setNewSelectedScreenRatio] =
    useState(selectedScreenRatio);

  const onChangeNewSelectedScreenRatio = (
    newSelectedScreenRatio: ScreenRatioEnum,
  ) => {
    setNewSelectedScreenRatio(newSelectedScreenRatio);
    onChangeSelectedScreenRatio(newSelectedScreenRatio);
  };

  const getDevicesDetail = useCallback(async () => {
    const AgoraClient = (await import(
      'agora-rtc-sdk-ng'
    )) as unknown as AgoraClient;

    const allCameras = await AgoraClient.getCameras();
    if (strictValidArray(allCameras)) {
      setCameraConfigs(allCameras);
    }
    const allMicrophones = await AgoraClient.getMicrophones();
    if (strictValidArray(allCameras)) {
      setMicrophoneConfigs(allMicrophones);
    }
  }, [setMicrophoneConfigs, setCameraConfigs]);

  useUpdateEffect(() => {
    // set selected camera when starting livecast
    if (localVideoTrack) {
      const defaultSelectedCameraLabel = localVideoTrack.getTrackLabel();
      const defaultSelectedCameraConfig = cameraConfigs.find(
        (config) => config.label === defaultSelectedCameraLabel,
      );

      if (
        defaultSelectedCameraConfig &&
        defaultSelectedCameraConfig.deviceId !== selectedCameraConfig?.deviceId
      ) {
        setSelectedCameraConfig(defaultSelectedCameraConfig);
        setNewSelectedCameraConfig(defaultSelectedCameraConfig);
      }
    }
  }, [cameraConfigs, localVideoTrack]);

  useUpdateEffect(() => {
    // set selected microphone when starting livecast
    if (localAudioTrack) {
      const defaultSelectedMicrophoneLabel = localAudioTrack.getTrackLabel();
      const defaultSelectedMicrophoneConfig = microphoneConfigs.find(
        (config) => config.label === defaultSelectedMicrophoneLabel,
      );

      if (
        defaultSelectedMicrophoneConfig &&
        defaultSelectedMicrophoneConfig.deviceId !==
          selectedMicrophoneConfig?.deviceId
      ) {
        setSelectedMicrophoneConfig(defaultSelectedMicrophoneConfig);
        setNewSelectedMicrophoneConfig(defaultSelectedMicrophoneConfig);
      }
    }
  }, [localAudioTrack, microphoneConfigs]);

  useUpdateEffect(() => {
    if (!videoContainer.current) return;
    cameraTrack?.play(videoContainer.current, {
      mirror: true,
    });
    return () => {
      cameraTrack?.stop();
    };
  }, [videoContainer, cameraTrack]);

  useUpdateEffect(() => {
    if (playAudio) {
      microphoneTrack?.play();
      return () => {
        microphoneTrack?.stop();
      };
    }
  }, [microphoneTrack, playAudio]);

  useMount(() => {
    getDevicesDetail();
  });

  useEffect(() => {
    if (newSelectedMicrophoneConfig && microphoneTrack) {
      microphoneTrack.setDevice(newSelectedMicrophoneConfig.deviceId);
    }
  }, [newSelectedMicrophoneConfig, microphoneTrack]);

  useEffect(() => {
    if (newSelectedCameraConfig && cameraTrack) {
      cameraTrack.setDevice(newSelectedCameraConfig.deviceId);
    }
  }, [newSelectedCameraConfig, cameraTrack]);

  useEffect(() => {
    if (microphoneTrack && enableAudiSetting) {
      microphoneTrack.setVolume(newInputLevelConfig);
      const interval = setInterval(() => {
        setVUMeterConfig(microphoneTrack.getVolumeLevel() * 100);
      }, 200);
      return () => {
        clearInterval(interval);
      };
    }
  }, [enableAudiSetting, microphoneTrack, newInputLevelConfig]);

  useEffect(() => {
    if (microphoneTrack && enableAudiSetting) {
      microphoneTrack.setVolume(newInputLevelConfig);
    }
  }, [enableAudiSetting, microphoneTrack, newInputLevelConfig]);

  return {
    videoContainer,
    cameraTrack,
    microphoneTrack,
    newSelectedMicrophoneConfig,
    newSelectedCameraConfig,
    newEnableAEC,
    vUMeterConfig,
    newInputLevelConfig,
    newSelectedScreenRatio,
    setNewSelectedMicrophoneConfig,
    setNewSelectedCameraConfig,
    setNewInputLevelConfig,
    setNewEnableAEC,
    onChangeNewSelectedScreenRatio,
    playAudio,
    setPlayAudio,
  };
};
