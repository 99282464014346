import { useState, useEffect, useMemo, useRef } from 'react';
import { ToastContent, ToastOptions, toast } from 'react-toastify';
// @mui
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Hidden,
  MenuItem,
  Popover,
  Stack,
  Typography,
  styled,
} from '@mui/material';
// api
import { unfollowArtist } from '~api/fan';
// components
import useFollowing from '~components/custom-hook/useFollowing';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import { HighlightText } from '~components/molecules';
// utils
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import {
  FEATURE_FLAGS_TYPE,
  POPUP_TYPE,
  PopupTypeValues,
  USER_TYPE,
  toastConfig,
} from '~utils/constants';
import { isOverflown } from '~utils/cssStyles';
import { useBoolean } from 'react-use';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ShareProfileModal from '../popup/share-profile-modal';
import { IOSSwitch } from '../popup/setting-dashboard-modal/artist-setting-dashboard-modal';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import { getAttendeesStats } from '~components/index';
import useBreakpoints from '~hooks/useBreakpoints';

const StyledRoot = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  backgroundColor: theme.palette.primary.light,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const StyledButtonGroup = styled(Stack)(({ theme }) => ({
  width: '100%',
  overflowX: 'scroll',
  overflowY: 'hidden',
  paddingBottom: theme.spacing(1),
  '&::-webkit-scrollbar': {
    width: 16,
    height: 8,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.primary.main,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.warning.main,
    borderRadius: theme.spacing(1),
  },
}));

const StyledLabelSwitch = styled(IOSSwitch)(() => ({
  width: 50,
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      transform: 'translateX(24px)',
    },
  },
  '&:after, &:before': {
    color: 'white',
    fontSize: '8px',
    position: 'absolute',
    top: '6px',
  },
  '&:after': {
    content: "'ON'",
    left: '8px',
  },
  '&:before': {
    content: "'OFF'",
    right: '7px',
  },
}));

type Props = {
  title: string;
  text: string;
};

const AttendeeDropdownItem = ({ title, text }: Props) => {
  return (
    <Stack direction="row" width="100%">
      <Typography
        variant="body2"
        fontSize={{ lg: 14 }}
        fontWeight={300}
        lineHeight={'24px'}
      >
        {title}:
      </Typography>
      <Typography
        component={'span'}
        variant="h4"
        fontWeight={400}
        lineHeight={'24px'}
        marginLeft={'auto'}
      >
        {text}
      </Typography>
    </Stack>
  );
};

const ArtistSpotlightActions = () => {
  const { isEnable } = useFeatureFlag();

  const isEnableArchivedVideos = isEnable(
    FEATURE_FLAGS_TYPE.ARCHIVED_VIDEO_FLOW,
  );
  const isEnableShowingAttendeeStats = isEnable(
    FEATURE_FLAGS_TYPE.SHOW_ATTENDEES_STATS,
  );

  const buttonGroupRef = useRef<HTMLDivElement | null>(null);
  const globalState = useGlobalState();
  const {
    config: {
      remoteUsers,
      isArtistLive,
      localVideoTrack,
      current_attendies: currentAttendees = 0,
      uniqueAttendees = 0,
      isShowed: isAttendeeStatShowed = true,
    },
    user: { id: userId, type, is_following, username, walletBalanceFan },
    artist: { currentEvent, id: artistId, username: artistUsername, name },
  }: Record<string, any> = globalState;

  const isFan = type === USER_TYPE.FAN;
  const isArtist = type === USER_TYPE.ARTIST;

  const dispatch = useGlobalDispatch();
  const { showBuyLiveCoinPopup } = useDispatchPopup();
  const [isConnecting, setIsConnecting] = useState(false);
  const [isAttendeeDropdownOpened, setIsAttendeeDropdownOpened] =
    useState(false);
  const [showShareProfileModal, setShowShareProfileModal] = useBoolean(false);
  const { fetchFollowingStatus } = useFollowing();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isDisplayCurrentAttendee = useMemo(() => {
    if (isArtist) {
      return !!localVideoTrack;
    } else {
      const artistAudioVideo = remoteUsers.find(
        (user: { uid: string }) => user.uid === artistUsername,
      );
      return !!artistAudioVideo?.hasVideo;
    }
  }, [artistUsername, localVideoTrack, remoteUsers, isArtist]);

  const handleTogglePopup = (popup: PopupTypeValues) => {
    dispatch({
      type: 'app',
      payload: { popup },
    });
  };

  const handleToggleAttendeeStatDisplayed = async () => {
    await getAttendeesStats(currentEvent, !isAttendeeStatShowed, dispatch);
  };

  const handleReward = () => {
    handleTogglePopup(POPUP_TYPE.REWARDS);
  };

  const handleFollow = async () => {
    if (!userId) {
      handleTogglePopup(POPUP_TYPE.LOGIN);
      return;
    }
    setIsConnecting(true);
    if (is_following) {
      try {
        await unfollowArtist(artistId);
        dispatch({ type: 'user', payload: { is_following: !is_following } });
      } catch (err) {
        // TODO need to define data type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        toast(err as ToastContent<unknown>, toastConfig as ToastOptions);
      }
    } else {
      handleTogglePopup(POPUP_TYPE.FOLLOW);
    }
    setIsConnecting(false);
  };

  const handleTip = async () => {
    if (!userId) {
      handleTogglePopup(POPUP_TYPE.LOGIN);
      return;
    }

    if (parseInt(walletBalanceFan, 10) > 0) {
      handleTogglePopup(POPUP_TYPE.TIP);
    } else {
      showBuyLiveCoinPopup();
    }
  };

  useEffect(() => {
    if (artistUsername && username && username !== artistUsername) {
      fetchFollowingStatus();
    }
  }, [artistUsername, fetchFollowingStatus, username]);

  const { isSm, isXs } = useBreakpoints();

  return (
    <StyledRoot>
      <StyledButtonGroup
        ref={buttonGroupRef}
        spacing={isSm || isXs ? 1 : 2}
        direction={'row'}
        justifyContent={{
          xs: isOverflown(buttonGroupRef.current) ? 'flex-start' : 'center',
          sm: 'center',
        }}
      >
        {(!userId || isFan) && (
          <>
            <Box>
              <Button
                fullWidth
                variant="contained"
                color="warning"
                onClick={handleReward}
              >
                REWARDS
              </Button>
            </Box>

            <Box>
              <Button
                fullWidth
                variant="contained"
                color="warning"
                onClick={handleTip}
              >
                TIP
              </Button>
            </Box>
            <Box>
              <Button
                fullWidth
                variant="contained"
                color="warning"
                disabled={isConnecting}
                onClick={handleFollow}
              >
                {is_following ? 'FOLLOWING' : 'FOLLOW'}
              </Button>
            </Box>
            <Box>
              <Hidden smUp>
                <Button
                  sx={{ minWidth: 30 }}
                  variant="outlined"
                  color="warning"
                  onClick={() => setShowShareProfileModal(true)}
                >
                  <ShortcutIcon sx={{ width: 20, height: 22 }} />
                </Button>
              </Hidden>
            </Box>
          </>
        )}
      </StyledButtonGroup>

      <Stack mx={4} my={1} flexDirection="row" justifyContent="center">
        {isEnableShowingAttendeeStats ? (
          <Box
            pt={{
              xs: 0,
              md: 1,
            }}
            pl={{
              xs: 0,
              md: 2,
            }}
            flex={1}
            marginRight={4}
            hidden={!isArtistLive}
            sx={{ zIndex: 999 }}
          >
            {isArtist ? (
              <HighlightText
                title={
                  isAttendeeStatShowed
                    ? 'Current Attendees:'
                    : 'Attendees Stats'
                }
                display={{
                  xs: 'inline-flex',
                  sm: 'flex',
                }}
                text={
                  isAttendeeStatShowed
                    ? isArtistLive && isDisplayCurrentAttendee
                      ? currentAttendees
                      : 0
                    : ''
                }
                isDropdownOpened={isAttendeeDropdownOpened}
                onDropdownClicked={(event: React.MouseEvent<HTMLElement>) => {
                  setIsAttendeeDropdownOpened(!isAttendeeDropdownOpened);
                  setAnchorEl(event.currentTarget);
                }}
              />
            ) : (
              <HighlightText
                title={'Current Attendees:'}
                display={{
                  xs: 'inline-flex',
                  sm: 'flex',
                }}
                text={currentAttendees}
              />
            )}
            <Popover
              open={isAttendeeDropdownOpened}
              anchorEl={anchorEl}
              onClose={() => setIsAttendeeDropdownOpened(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Stack
                maxWidth={210}
                sx={{
                  borderRadius: '4px',
                  border: '1px solid  rgba(255, 255, 255, 0.1)',
                  backgroundColor: '#1B202B',
                }}
                divider={<Divider orientation="horizontal" flexItem />}
              >
                <MenuItem value="option1">
                  <AttendeeDropdownItem
                    title="Current Attendees"
                    text={
                      isArtistLive && isDisplayCurrentAttendee
                        ? currentAttendees
                        : 0
                    }
                  />
                </MenuItem>
                <MenuItem value="option2">
                  <AttendeeDropdownItem
                    title="Total Attendees"
                    text={
                      isArtistLive && isDisplayCurrentAttendee
                        ? uniqueAttendees
                        : 0
                    }
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    control={
                      <StyledLabelSwitch
                        checked={isAttendeeStatShowed}
                        onChange={handleToggleAttendeeStatDisplayed}
                        sx={{ m: 1 }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        fontSize={{ lg: 14 }}
                        fontWeight={300}
                        lineHeight={'24px'}
                        sx={{ marginRight: 'auto' }}
                      >
                        Show current attendees stat while streaming
                      </Typography>
                    }
                    sx={{ whiteSpace: 'normal' }}
                  />
                </MenuItem>
              </Stack>
            </Popover>
          </Box>
        ) : (
          isArtistLive && (
            <Box flex={1} marginRight={4}>
              <HighlightText
                justifyContent={'center'}
                title="Current Attendees:"
                text={
                  isArtistLive && isDisplayCurrentAttendee
                    ? currentAttendees
                    : 0
                }
              />
            </Box>
          )
        )}

        {!isEnableArchivedVideos && (
          <Box>
            <Hidden smDown={isFan}>
              <Button
                sx={{ minWidth: 120 }}
                variant="outlined"
                color="warning"
                onClick={() => setShowShareProfileModal(true)}
                endIcon={<ShortcutIcon />}
              >
                SHARE
              </Button>
            </Hidden>
          </Box>
        )}
      </Stack>

      <ShareProfileModal
        open={showShareProfileModal}
        onClose={() => setShowShareProfileModal(false)}
        isArtist={isArtist}
        artistName={name || ''}
      />
    </StyledRoot>
  );
};

export default ArtistSpotlightActions;
