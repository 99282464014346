import React from 'react';

import BasePopup from '~components/organisms/popup/base-popup';
import { Button, Stack, Typography, styled } from '@mui/material';

import { SurveyIcon } from '~components/icons';

const StyledModal = styled(BasePopup)(({ theme }) => ({
  width: '90%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 400,
  },
}));

interface Props {
  title: string;
  open: boolean;
  onSkip: () => void;
  onSurvey: () => void;
}

const SurveyEndLiveModal = ({ open, onSurvey, onSkip, title }: Props) => {
  const handleSurvey = () => {
    onSurvey();
  };

  return (
    <>
      <StyledModal open={open} closeBtn={true} keepMounted={false}>
        <Stack spacing={3}>
          <Typography
            fontWeight={'700'}
            fontSize={{ xs: 16, sm: 20 }}
            variant="subtitle1"
            lineHeight={'27px'}
            align="center"
          >
            {title}
          </Typography>
          <Stack flex={1} alignItems="center">
            <SurveyIcon />
          </Stack>
          <Typography
            align="center"
            marginTop="2"
            sx={{ textTransform: 'none' }}
          >
            Fill out a quick survey to give us your feedback and make
            suggestions.
          </Typography>
          <Button
            variant="contained"
            color="warning"
            onClick={handleSurvey}
            sx={{ flexGrow: 1 }}
          >
            SURVEY
          </Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={onSkip}
            sx={{ flexGrow: 1, textTransform: 'none' }}
          >
            Skip
          </Button>
        </Stack>
      </StyledModal>
    </>
  );
};

export default SurveyEndLiveModal;
