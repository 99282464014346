import {
  getRequest,
  putRequest,
  postRequest,
  //   delRequest,
  Response,
  delRequest,
} from '~utils';
import { Event } from '~types/event';

import { VideoListResponse } from '~types/video';

export const getArchivedVideosByArtistId = async (
  artistId: string,
  limit: number,
  offset: number,
): Promise<VideoListResponse> => {
  return await getRequest({
    path: 'archived-video/by-artist-id',
    config: {
      params: {
        artist_id: artistId,
        limit,
        offset,
      },
    },
  });
};

export const getArchivedVideoById = async (eventId: string): Promise<Event> => {
  return await getRequest({
    path: 'archived-video/by-id',
    config: {
      params: {
        event_id: eventId,
      },
    },
  });
};

export const updateVideoTitle = async (
  title: string,
  eventId: string,
): Promise<Response<Event>> => {
  return await putRequest({
    path: 'archived-video/update-title',
    data: {
      event_id: eventId,
      title: title,
    },
  });
};

export const getOwnArchivedVideoRequest = async (
  limit: number,
  offset: number,
  sort: string,
  startTime: string,
  endTime: string,
): Promise<Response<VideoListResponse>> => {
  return await postRequest({
    path: 'archived-video/filter',
    data: {
      limit,
      offset,
      sort,
      startTime: startTime,
      endTime: endTime,
    },
  });
};

export const addOrRemoveArchivedVideoProfile = async (
  eventId: string,
  isProfile: boolean,
): Promise<Response<Event>> => {
  return await putRequest({
    path: 'archived-video/set-profile',
    data: {
      event_id: eventId,
      is_profile: isProfile,
    },
  });
};

export const setOrUnsetArchivedVideoAsDefault = async (
  eventId: string,
  isDefault: boolean,
): Promise<Response<VideoListResponse>> => {
  return await putRequest({
    path: 'archived-video/set-default',
    data: {
      event_id: eventId,
      is_profile: isDefault,
    },
  });
};

export const deleteArchivedVideoAsDefault = async (
  eventId: string,
): Promise<Response<VideoListResponse>> => {
  return await delRequest({
    path: `archived-video/${eventId}`,
  });
};

export const setDefaultShownUp = async (
  defaultPopup: boolean,
): Promise<boolean> => {
  return await getRequest({
    path: `users/archived-video-setting?default_popup=${defaultPopup}`,
  });
};
