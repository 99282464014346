import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '~components/molecules';
// @mui
import { Box, Link, Stack, Typography, styled } from '@mui/material';

import InfoBlock from './info-block';
import { LoadingButton } from '~components/atoms';
import { WrapperBlock, InternalIndentBlock } from '../../../components';
import { FormData, ModalWindowType, PAYOUT_MODAL_WINDOWS } from './types';
import ModalWindows from './modal-windows';
import { formatMoney } from '~utils/formatters';

import {
  MINIMAL_VALUE_TO_WITHDRAWAL,
  withdrawalAmountSchema,
} from './validation-schemes';
import { useMutation } from '@tanstack/react-query';
import { makePayoutMoney } from '~api';
import { MakePayoutMoneyParams } from '~types/payment';
import { UnionPayoutMethods } from '../payout-method/types';
import { AxiosError } from 'axios';
import { useGlobalDispatch, useGlobalState } from '~utils/container';
import { getFanDetails } from '~components/action';

interface PayoutProps {
  availableEarnings: number;
  amountPaidToDate: number;
  fee: number;
  activePayoutId: string | null;
  activeMethod: UnionPayoutMethods;
  onSuccessPayout: () => void;
}

const Payout = ({
  availableEarnings,
  amountPaidToDate,
  fee,
  activePayoutId,
  activeMethod,
  onSuccessPayout,
}: PayoutProps) => {
  const [windowType, setWindowType] = useState<ModalWindowType>(null);
  const dispatch = useGlobalDispatch();
  const globalState = useGlobalState();

  const refreshUserData = async () => {
    await getFanDetails(dispatch, globalState);
  };
  const {
    mutate: makePayoutRequest,
    isLoading,
    error,
  } = useMutation({
    mutationFn: (data: MakePayoutMoneyParams) => makePayoutMoney(data),
    onSuccess: () => {
      openModalWindow(PAYOUT_MODAL_WINDOWS.SUCCESS_PAYMENT);
      reset();
      onSuccessPayout();
      refreshUserData();
    },
    onError: () => openModalWindow(PAYOUT_MODAL_WINDOWS.ERROR_PAYMENT),
  });

  const methods = useForm<FormData>({
    resolver: yupResolver(withdrawalAmountSchema),
    defaultValues: {
      withdrawalAmount: '',
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    reset,
  } = methods;

  const openModalWindow = (type: ModalWindowType) => setWindowType(type);
  const closeModalWindow = () => setWindowType(null);
  const onSubmit = () => {
    const withdrawalValue = +getValues('withdrawalAmount');
    if (availableEarnings < MINIMAL_VALUE_TO_WITHDRAWAL) {
      setError('withdrawalAmount', {
        message: `Please payout until the "Available Earning" is at least $${MINIMAL_VALUE_TO_WITHDRAWAL}`,
        types: { max: availableEarnings < MINIMAL_VALUE_TO_WITHDRAWAL },
      });
      return;
    }
    if (withdrawalValue < MINIMAL_VALUE_TO_WITHDRAWAL) {
      setError('withdrawalAmount', {
        message: `Please enter at least $${MINIMAL_VALUE_TO_WITHDRAWAL}`,
        types: { max: withdrawalValue < MINIMAL_VALUE_TO_WITHDRAWAL },
        type: 'min',
      });
      return;
    }
    if (withdrawalValue > availableEarnings) {
      setError('withdrawalAmount', {
        message: `Please enter no more than $${availableEarnings}`,
        types: { max: withdrawalValue > availableEarnings },
        type: 'max',
      });
      return;
    }
    if (!activePayoutId) {
      setError('withdrawalAmount', {
        message: `Please select payout method and method's type`,
      });
      return;
    }
    openModalWindow(PAYOUT_MODAL_WINDOWS.SUMMARY);
  };

  const makePayout = () => {
    const withdrawalValue = getValues('withdrawalAmount');
    if (!activePayoutId) return;
    makePayoutRequest({
      payoutId: activePayoutId,
      withdrawalAmount: +withdrawalValue,
    });
  };

  useEffect(() => {
    if (activePayoutId) clearErrors('withdrawalAmount');
  }, [clearErrors, activePayoutId]);

  return (
    <Container>
      <ModalWindows
        fee={fee}
        windowType={windowType}
        onOpen={openModalWindow}
        onClose={closeModalWindow}
        withdrawalAmount={+getValues('withdrawalAmount')}
        makePayment={makePayout}
        statusOfWithdrawal={'success'}
        activeMethod={activeMethod}
        loading={isLoading}
        error={error as AxiosError}
      />
      <WrapperBlock title={'Payout'}>
        <WrapperContent>
          <WrapperInfoBlock>
            <InfoBlock
              title={'Available Earnings'}
              amount={formatMoney(availableEarnings)}
            />
            <InfoBlock
              title={'Amount Paid To Date'}
              amount={formatMoney(amountPaidToDate)}
            />
          </WrapperInfoBlock>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              alignItems={{
                xs: 'flex-start',
                sm: 'flex-end',
              }}
              spacing={2.5}
            >
              <Box sx={{ flex: 1, flexGrow: 1 }}>
                <RHFTextField
                  required
                  label="Withdrawal Amount"
                  name="withdrawalAmount"
                  labelColor="white"
                  labelSx={{
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  flexGrow: 1,
                  pb: errors['withdrawalAmount'] ? 2.5 : 0,
                }}
              >
                <LoadingButton
                  type={'submit'}
                  loading={isLoading}
                  disabled={Boolean(errors['withdrawalAmount'])}
                  sx={{ minHeight: 40, minWidth: 160 }}
                >
                  Request Payout
                </LoadingButton>
              </Box>
            </Stack>
          </FormProvider>
          <Box>
            <Typography
              variant="body2"
              fontSize={{ md: 12 }}
              gutterBottom
              sx={{ mt: 0.5 }}
            >
              {`$${MINIMAL_VALUE_TO_WITHDRAWAL} min - Ursa Live takes a ${fee}% fee`}
            </Typography>
            <Typography variant="body2" fontSize={{ md: 12 }}>
              If you’d like to take advantage of our 30 days commission-free
              live streaming offer, please contact us at{' '}
              <Link
                color="info.main"
                target="_blank"
                href="mailto:support@ursalive.com"
              >
                support@ursalive.com
              </Link>{' '}
              with your PayPal or Venmo handle.
            </Typography>
          </Box>
        </WrapperContent>
      </WrapperBlock>
    </Container>
  );
};

export default Payout;

const Container = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const WrapperContent = styled(InternalIndentBlock)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));
const WrapperInfoBlock = styled(Box)(({ theme }) => ({
  width: 'auto',
  flexDirection: 'column',
  display: 'flex',
  gap: '4px 20px',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    width: '100%',
  },
}));
