import { alpha, Box, styled, Typography } from '@mui/material';
import { ThinTriangleArrowSVG } from '~components/icons';
import Slider from 'react-slick';

export const Dot = styled(Box)(({ theme }) => ({
  height: 10,
  width: 10,
  backgroundColor: alpha(theme.palette.text.primary, 0.35),
  borderRadius: '50%',
  marginTop: 40,
}));

export const ArrowContainer = styled(Box)(() => ({
  '&::before': {
    content: "''",
  },
  '&.slick-disabled': {
    opacity: 0.1,
    cursor: 'auto',
  },
  height: 'auto',
  width: 'auto',
}));
const ArrowButtons = styled(ThinTriangleArrowSVG)(() => ({
  height: 35,
  padding: '0 !important',
  margin: 0,
}));

export const PrevArrowButtons = styled(ArrowButtons)(({ theme }) => ({
  transform: 'rotate(180deg) !important',
  left: '0 !important',
  [theme.breakpoints.up('lg')]: {
    left: '20px !important',
  },
}));

export const NextArrowButtons = styled(ArrowButtons)(({ theme }) => ({
  transform: 'rotate(0deg) !important',
  right: '0 !important',
  [theme.breakpoints.up('lg')]: {
    right: '20px !important',
  },
}));
export const CustomSlider = styled(Slider)(({ theme }) => ({
  '.slick-dots': {
    paddingTop: 20,
    li: {
      margin: 0,
      paddingTop: 40,
    },
    div: {
      margin: 'auto',
    },
    '.slick-active div': {
      backgroundColor: theme.palette.text.primary,
    },
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px',
  marginBottom: 40,
  position: 'relative',
  minHeight: 220,
  [theme.breakpoints.up('md')]: {
    padding: '0 80px',
    '.slick-list': {
      margin: '0 60px',
    },
    '.slick-next': {
      right: 0,
      top: '45%',
      width: 'auto',
    },
    '.slick-prev': {
      left: 0,
      top: '47%',
      width: 'auto',
    },
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 120px',
    ':after': {
      left: 'calc(-100% + 180px)',
    },
  },
  [theme.breakpoints.up('xl')]: {
    padding: '0 170px',
    ':after': {
      left: 'calc(-100% + 230px)',
    },
  },
}));

export const NoPaddingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px',
  marginBottom: 40,
  position: 'relative',
  minHeight: 220,
  [theme.breakpoints.up('md')]: {
    '.slick-list': {
      margin: '0 60px',
    },
    '.slick-next': {
      right: 0,
      top: '45%',
      width: 'auto',
    },
    '.slick-prev': {
      left: 0,
      top: '47%',
      width: 'auto',
    },
  },
  [theme.breakpoints.up('lg')]: {
    ':after': {
      left: 'calc(-100% + 180px)',
    },
  },
  [theme.breakpoints.up('xl')]: {
    ':after': {
      left: 'calc(-100% + 230px)',
    },
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  position: 'relative',
  textAlign: 'center',
  ':after': {
    content: "''",
    position: 'absolute',
    bottom: -8,
    left: 'calc(50% - 50px)',
    height: 4,
    width: 100,
    backgroundColor: theme.palette.action.active,
    [theme.breakpoints.up('md')]: {
      left: 0,
      bottom: -14,
      height: 8,
    },
  },
  [theme.breakpoints.up('xs')]: {
    fontSize: theme.typography.h3.fontSize,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.h3.fontSize,
    textAlign: 'left',
    margin: '0 70px',
  },
}));

export const CenteredText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h4.fontSize,
  marginTop: 20,
  textAlign: 'center',
}));

export const SlideWrapper = styled(Box)(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const SlideRatioContainer = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
}));

export const SlideBorder = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  margin: '6px',
  borderRadius: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    margin: '20px 6px',
  },
}));
