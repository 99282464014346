import { toast } from 'react-toastify';
import { showErrorToaster } from '~utils/toasterNotification';
import { toastConfig } from '~utils/constants';
import { getArtistInfo } from '~api/artist';
import { getFanDetail, updateLivecoinPopup } from '~api/fan';
import { updateCustomerId } from '~api/user';
import {
  getEventAttendees,
  getListQuestions,
  getUserEvents,
  showAttendeeStats,
} from '~api/event';
import {
  purchaseLivecoin,
  getTipsList,
  purchaseLivecoinWithTax,
} from '~api/transaction';

export const getArtistDetails = async (dispatch, state) => {
  const { artist: { id } = {} } = state;
  try {
    const { artist, genre } = await getArtistInfo(id);
    dispatch({ type: 'artist', payload: { ...artist, genre } });
    return false;
  } catch (error) {
    toast(error, toastConfig);
    return false;
  }
};

export const getFanDetails = async (dispatch, state) => {
  const { user: { id } = {} } = state;
  try {
    const res = await getFanDetail(id);
    const { walletBalance = 0, cashIn = 0, cashOut = 0, imageUrl } = res;
    dispatch({
      type: 'user',
      payload: {
        walletBalanceFan: walletBalance,
        walletBalanceInUsd: walletBalance / 10,
        cashIn,
        cashOut,
        imageUrl,
      },
    });
    return true;
  } catch (error) {
    toast(error, toastConfig);
    return false;
  }
};

export const getTopTipList = async (dispatch, state) => {
  const { artist: { currentEvent, id } = {} } = state;
  try {
    const { transactions, totalEarned } = await getTipsList(id, currentEvent);
    dispatch({
      type: 'artist',
      payload: {
        top_tips: transactions,
        total_earned_in_event: totalEarned,
      },
    });
    return transactions;
  } catch (error) {
    toast(error, toastConfig);
    return false;
  }
};

export const saveTransaction = async (
  paymentIntentId,
  purchasedCoins,
  paymentType,
  liveCoinProduct,
  paymentMethod,
  cardId,
) => {
  try {
    return await purchaseLivecoin(
      purchasedCoins,
      paymentIntentId,
      paymentType,
      liveCoinProduct,
      paymentMethod,
      cardId,
    );
  } catch (error) {
    toast(error, toastConfig);
    return false;
  }
};

export const getArtistAndEventDetails = async (
  userId,
  dispatch,
  artistData,
) => {
  try {
    const res = await getUserEvents(userId);
    const { upcoming: events, ongoing = {} } = res;
    const artist_data = { events, ...artistData };
    const currentEvent = ongoing?.event?.id;
    const startLiveAt = ongoing?.event?.createdAt;
    let payload = artist_data;
    if (currentEvent) {
      payload.currentEvent = currentEvent;
      dispatch({
        type: 'config',
        payload: { isArtistLive: true, startLiveAt },
      });
    }
    dispatch({ type: 'artist', payload });
  } catch (err) {
    showErrorToaster(err);
  }
};

export const updateLivecoinPopupFlag = async (onSuccess) => {
  try {
    await updateLivecoinPopup(false);
    !!onSuccess && onSuccess();
    return true;
  } catch (err) {
    showErrorToaster(err);
    return false;
  }
};

export const getQuestionsByArtistId = async (artistId, dispatch) => {
  try {
    const { questions } = await getListQuestions(artistId);
    dispatch({ type: 'app', payload: { questionsList: questions } });
    return true;
  } catch (err) {
    showErrorToaster(err);
    return false;
  }
};

export const updateStripeCustomerId = async (customerId) => {
  try {
    await updateCustomerId(customerId);

    return true;
  } catch (err) {
    showErrorToaster(err);
    return false;
  }
};

export const getCurrentAttendees = async (eventId, dispatch) => {
  try {
    const { count } = await getEventAttendees(eventId);
    dispatch({ type: 'config', payload: { current_attendies: count } });
    return true;
  } catch (err) {
    showErrorToaster(err);
    return false;
  }
};

export const getAttendeesStats = async (eventId, showed, dispatch) => {
  try {
    const { data } = await showAttendeeStats(eventId, showed);
    const { uniqueAttendees } = data;
    dispatch({
      type: 'config',
      payload: {
        uniqueAttendees: uniqueAttendees,
      },
    });
    return true;
  } catch (err) {
    showErrorToaster(err);
    return false;
  }
};

export const saveTransactionWithTax = async (
  paymentIntentId,
  purchasedCoins,
  paymentType,
  liveCoinProduct,
  paymentMethod,
  cardId,
  tax,
) => {
  try {
    return await purchaseLivecoinWithTax(
      purchasedCoins,
      paymentIntentId,
      paymentType,
      liveCoinProduct,
      paymentMethod,
      cardId,
      tax,
    );
  } catch (error) {
    toast(error, toastConfig);
    return false;
  }
};
