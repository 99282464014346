import { ReactNode } from 'react';
// @mui
import {
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  styled,
  Avatar,
} from '@mui/material';
// utils
import { getImageURL } from '~utils/awsUtils';
// components
import { TrophyBronze, TrophyGold, TrophySilver } from '~components/icons';
import { TextEllipsis } from '~components/atoms';
// types
import { RewardWinner } from '~types/reward';
import useIsBlocked from '~hooks/useIsBlocked';

const StyledListItem = styled(ListItem)(() => ({
  minHeight: 40,
  paddingLeft: 0,
  paddingRight: 0,
  '& .MuiTypography-root': {
    fontWeight: '500',
  },
}));

const TROPHY_BY_ORDER: Record<string, { label: string; icon: ReactNode }> = {
  '1': {
    label: '1st',
    icon: <TrophyGold />,
  },
  '2': { label: '2nd', icon: <TrophySilver /> },
  '3': { label: '3rd', icon: <TrophyBronze /> },
};

type Props = {
  index: number;
  rewardsLength: number;
  ended: boolean;
  item: RewardWinner;
};

const RewardItem = ({ index, item, ended, rewardsLength }: Props) => {
  const { blocked } = useIsBlocked(String(item.winnerName));

  const random = item.isRandom
    ? 'Chosen at the end of the campaign'
    : 'Please tip to qualify for this reward';

  const winnerName = item.winnerName || (ended ? 'N/A' : random);
  const rewardPlace = item.isRandom
    ? 'Random'
    : TROPHY_BY_ORDER[`${index + 1}`].label;

  return (
    <StyledListItem divider={index + 1 < rewardsLength}>
      <ListItemIcon sx={{ flex: 1, alignItems: 'center' }}>
        {index < 3 ? TROPHY_BY_ORDER[`${index + 1}`].icon : <TrophyBronze />}
        <ListItemText
          sx={{ ml: 1, color: 'white', fontWeight: '500' }}
          primary={rewardPlace}
        />
      </ListItemIcon>
      <ListItemText
        sx={{ flex: 2, mr: 2 }}
        primary={<TextEllipsis maxWidth={'auto'} text={item.rewardName} />}
      />
      <ListItemIcon sx={{ flex: 2, py: 1, alignItems: 'center' }}>
        {item.winnerName && (
          <Box sx={{ width: 40, height: 40 }}>
            <Avatar
              sx={{
                maxWidth: '100%',
              }}
              src={blocked ? '' : getImageURL(item.winnerImageUrl)}
            />
          </Box>
        )}

        <ListItemText
          sx={{ ml: 1, color: 'white', fontWeight: '500' }}
          primary={
            <TextEllipsis
              textTransform="none"
              maxWidth={'auto'}
              text={blocked ? 'Blocked user' : winnerName}
            />
          }
        />
      </ListItemIcon>
    </StyledListItem>
  );
};

export default RewardItem;
