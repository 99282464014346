import { useEffect, useState } from 'react';

const useTimeCountdown = (targetDate: string) => {
  const countDownDate = new Date(targetDate).getTime();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime(),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const formatValue = (value: number) => value.toString().padStart(2, '0');

const getReturnValues = (countDown: number): string[] => {
  if (!countDown) return Array.from({ length: 4 }, () => formatValue(0));
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds].map(formatValue);
};

export default useTimeCountdown;
