import { withdrawalAmountSchema } from './validation-schemes';
import * as yup from 'yup';

export interface ModalWindowFunctions {
  windowType: ModalWindowType;
  onClose: () => void;
  onOpen: (type: ModalWindowType) => void;
}

export enum PAYOUT_MODAL_WINDOWS {
  SUMMARY = 'summary',
  CONFIRMATION = 'confirmation',
  SUCCESS_PAYMENT = 'success-payment',
  ERROR_PAYMENT = 'error-payment',
}

export type ModalWindowType =
  | (typeof PAYOUT_MODAL_WINDOWS)[keyof typeof PAYOUT_MODAL_WINDOWS]
  | null;

export type FormData = yup.InferType<typeof withdrawalAmountSchema>;
