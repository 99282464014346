import React, { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';

import { VirtuosoGrid } from 'react-virtuoso';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';

import { ItemInSlide } from './item-in-slide';
import { SearchArtist } from '~types/landing-page';
import { searchArist } from '~api';
import BasePopup from '~components/organisms/popup/base-popup';

export const generateArrayOfInitialSlides = (
  amount: number,
): SearchArtist[] => {
  return new Array(amount).fill('Loading').map((slide, index) => ({
    id: index.toString(),
    username: slide,
    name: slide,
    displayUsername: slide,
    isFakeData: true,
  }));
};
interface Props {
  search: string;
  show: boolean;
  onClose: () => void;
}

const List = forwardRef(
  ({ style, children, ...props }: any, ref: ForwardedRef<HTMLDivElement>) => (
    <Box
      ref={ref}
      {...props}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        ...style,
      }}
    >
      {children}
    </Box>
  ),
);
const Item = ({ children, ...props }: any) => (
  <Box
    {...props}
    sx={(theme) => ({
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '25%',
      },
    })}
  >
    {children}
  </Box>
);

const LIMIT_ITEM = 12;
const SearchArtistModal = ({ search: inputSearch, show, onClose }: Props) => {
  const [search] = useState(inputSearch);
  const [data, setData] = useState<SearchArtist[]>([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);

  const { isLoading, refetch } = useQuery({
    queryKey: [QUERY_KEYS.LANDING_EVENTS_ONGOING, offset],
    queryFn: () => searchArist(search, offset, LIMIT_ITEM),
    keepPreviousData: true,
    onSuccess: (response) => {
      setData([...data, ...response.data]);
      setTotal(response.total);
    },
    refetchOnWindowFocus: false,
  });

  const loadMore = useCallback(async () => {
    if (total <= offset + LIMIT_ITEM) {
      return;
    }
    setOffset(offset + LIMIT_ITEM);
    await refetch();
  }, [offset, refetch, total]);

  if (isLoading) {
    return null;
  }

  return (
    <StyledModal open={show} onClose={onClose} containerHeight="100%">
      <Container alignItems="center" justifyContent="center">
        <Stack justifyContent="start" direction="row" width="100%" p={1}>
          <Typography align="left">
            {`Found ${total} number of results for "${search}"`}
          </Typography>
        </Stack>

        <VirtuosoGrid
          style={{ height: '100%', width: '100%' }}
          data={data}
          components={{
            List,
            Item,
            Footer: () =>
              total > offset + LIMIT_ITEM ? <Box>Loading...</Box> : null,
          }}
          endReached={loadMore}
          itemContent={(
            index,
            { id, username, name, displayUsername, genre, imageUrl },
          ) => (
            <ItemInSlide
              key={id}
              id={id}
              username={username}
              name={name}
              imageUrl={imageUrl}
              displayUsername={displayUsername}
              genre={genre}
              isDragging={false}
            />
          )}
        />
      </Container>
    </StyledModal>
  );
};

export default SearchArtistModal;

const StyledModal = styled(BasePopup)(({ theme }) => ({
  height: '80vh',
  width: '90%',
  mathWidth: '80%',
  padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
  [theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: 900,
    height: '80vh',
  },
}));

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: '#181C27',
  borderRadius: 10,
  padding: 10,
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    padding: '25px 0px 0px',
  },
}));
