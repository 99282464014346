import React, { useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import { Box, styled } from '@mui/material';

import DarkSphere from '~images/decorations-landing-page/dark-sphere.png';
import {
  NextArrowButtons,
  PrevArrowButtons,
  ArrowContainer,
  SlideWrapper,
} from '../styles';
import {
  AMOUNT_ROWS,
  RESPONSIVE,
  SLIDES_IN_ROW_IN_RESPONSE,
} from '../constants';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '~constants/query-keys';
import { getUpcomingEvents } from '~api/event';
import useBreakpoints from '../../../../hooks/useBreakpoints';
import { generateArrayOfInitialSlides } from '../utils';
import { ItemInSlide } from './item-in-slide';
import { getImageURL } from '~utils/awsUtils';
import { CarouselDots } from '~components/molecules';

const FeaturedUpcomingEvents = ({ total }: { total: number }) => {
  const breakpoints = useBreakpoints();

  const [limit, setLimit] = useState(
    SLIDES_IN_ROW_IN_RESPONSE[breakpoints.active] * AMOUNT_ROWS,
  );
  const [offset, setOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const { data: upcomingEvents, refetch } = useQuery({
    queryKey: [QUERY_KEYS.LANDING_EVENTS_UPCOMING, limit, offset],
    queryFn: () => getUpcomingEvents({ limit, offset, isPartner: true }),
    refetchOnWindowFocus: false,
  });

  const renderData = useMemo(() => {
    const emptySlides = generateArrayOfInitialSlides(total);
    if (!upcomingEvents) return emptySlides;
    return [
      ...emptySlides.slice(0, offset),
      ...upcomingEvents,
      ...emptySlides.slice(offset + limit),
    ];
  }, [limit, offset, upcomingEvents, total]);

  useEffect(() => {
    setLimit(SLIDES_IN_ROW_IN_RESPONSE[breakpoints.active] * AMOUNT_ROWS);
  }, [breakpoints.active]);

  useEffect(() => {
    if (total < offset) {
      setOffset(Math.ceil(total / limit) * limit - limit);
    }
    refetch();
  }, [limit, offset, refetch, total]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: SLIDES_IN_ROW_IN_RESPONSE.xl,
    rows: AMOUNT_ROWS,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setIsDragging(true);
      setTimeout(() => {
        setIsDragging(false);
      }, 500);
      setOffset(newIndex * limit);
    },
    prevArrow: (
      <ArrowContainer>
        <PrevArrowButtons />
      </ArrowContainer>
    ),
    nextArrow: (
      <ArrowContainer>
        <NextArrowButtons />
      </ArrowContainer>
    ),
    responsive: RESPONSIVE,
    ...CarouselDots({
      sx: {
        mt: 1,
        color: 'white',
      },
    }),
  };

  return (
    <>
      <SphereContainer>
        <Sphere src={DarkSphere} alt="sphere" />
      </SphereContainer>
      <Box sx={{ mt: 4 }}>
        <CustomSlider
          // initialSlide={offset / SLIDES_IN_ROW_IN_RESPONSE.xl}
          {...settings}
        >
          {renderData.map(
            (
              { id, imageUrl, artistUserName, userName, genre, scheduledAt },
              index,
            ) =>
              offset > index || index >= offset + limit ? (
                <SlideWrapper key={id} />
              ) : (
                <ItemInSlide
                  key={id}
                  imageUrl={getImageURL(imageUrl)}
                  artistUserName={artistUserName}
                  userName={userName}
                  genre={genre}
                  scheduledAt={scheduledAt}
                  isDragging={isDragging}
                />
              ),
          )}
        </CustomSlider>
      </Box>
    </>
  );
};

export default FeaturedUpcomingEvents;

const CustomSlider = styled(Slider)(({ theme }) => ({
  '.slick-dots': {
    paddingTop: 20,
    li: {
      margin: 0,
      paddingTop: 10,
    },
    div: {
      margin: 'auto',
    },
    '.slick-active div': {
      backgroundColor: theme.palette.text.primary,
    },
  },
}));

const SphereContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  overflow: 'hidden',
}));

const Sphere = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 50,
  left: '-30%',
  width: '100%',
  height: '800px',
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));
